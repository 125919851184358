export const chartDropdownButtons = [
    {
      id: 0,
      index: 0,
      text: "Day",
      unit: "day",
      selected: false,
    },
    {
      id: 1,
      index: 1,
      text: "Week",
      unit: "week",
      selected: false,
    },
    {
      id: 2,
      index: 2,
      text: "Month",
      unit: "month",
      selected: true,
    },
    {
      id: 3,
      index: 3,
      text: "Year",
      unit: "year",
      selected: false,
    },
  ];