import React from "react";
import styled from "@emotion/styled";
import {CONTACTS_LIST_TYPES} from "consts";
import SegmentsPanel from "./SegmentsPanel";
import AudiencesPanel from "./AudiencesPanel";
import WifiLoginPanel from "./WifiLoginPanel";
import Divider from "@mui/material/Divider";

const ContactsListPanelsWrapper = styled.div`
  // grid-row: 1/3;
  // grid-column: 1/4;
  // height: 100%;
  // border-radius: 6px;
  // overflow-x: hidden;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  //
  // > * {
  //   margin: 6px 0;
  // }
  
  width: 330px;
  height: 1020px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const ContactsListPanels = (props) => {
  return (
      <ContactsListPanelsWrapper>
        <SegmentsPanel listTypes={CONTACTS_LIST_TYPES} {...props}/>
        <br/>
        <Divider sx={{margin: 2, borderBottomWidth: 2}}/>
        <AudiencesPanel
            {...props}
            listTypes={CONTACTS_LIST_TYPES}
        />
        <br/>
        <Divider sx={{margin: 2, borderBottomWidth: 2}}/>
        <WifiLoginPanel
            {...props}
            listTypes={CONTACTS_LIST_TYPES}
        />
      </ContactsListPanelsWrapper>
  );
};

export default ContactsListPanels;
