import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

const OPACITY = 0.4;

const customPagination = () => {
  return {
    "& .MuiSelect-select.MuiSelect-select": {
      border: 0,
      borderRadius: 0,
    },
    "& .MuiMenu-root": {
      padding: "12px",
    },
  };
};

const customCheckbox = () => {
  return {
    "& .MuiCheckbox-root.Mui-checked": {
      color: "#ff9933",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  };
};

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-footerContainer": {
    paddingTop: 0,
    marginRight: 50
  },
  "& .MuiTypography-root": {
    fontSize: 0,
  },
  "&.MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiChip-root": {
    paddingTop: "3px",
  },
  "&.MuiDataGrid-main": {
    marginTop: "0px",
  },
  ".MuiDataGrid-colCellTitle": {
    display: "block",
    textAlign: "center",
    width: "100%",
  },
  "& .MuiDataGrid-columnHeaders": {
    position: "sticky !important",
    zIndex: theme.zIndex.mobileStepper - 1,
    backgroundColor: theme.palette.background.paper,
  },
  "& .MuiDataGrid-virtualScroller": {
    marginTop: "0 !important",
  },
  "& .MuiDataGrid-main": {
    overflow: "visible",
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#FFFFFF",
    "&:hover, &.Mui-hovered": {
      backgroundColor: "#e9eaec",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiTableRow-root.Mui-selected": {
      backgroundColor: "#FF99330A",
    },
    "&.Mui-selected": {
      backgroundColor: alpha("#FF9933", 0.04),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha("#000000", 0.04),
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#FFFFFF",
    "&.Mui-selected": {
      backgroundColor: alpha("#FF9933", 0.04),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha("#000000", 0),
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  ...customCheckbox(theme),
  ...customPagination(theme),
}));

export default CustomDataGrid;
