import {call, put, takeLatest} from "redux-saga/effects";

import {
  apiCreateIdentity,
  apiDeleteIdentity,
  apiGetDomainRecords,
  apiGetIdentities,
  apiResendIdentity,
} from "./api";

import {toast} from "react-toastify";

import {
  CREATE_IDENTITY_REQUEST,
  createIdentityFailure,
  createIdentitySuccess,
  DELETE_IDENTITY_REQUEST,
  deleteIdentityFailure,
  deleteIdentitySuccess,
  GET_DOMAIN_RECORDS_REQUEST,
  GET_IDENTITIES_REQUEST,
  getDomainRecordsFailure,
  getDomainRecordsSuccess,
  getIdentitiesFailure,
  getIdentitiesSuccess,
  RESEND_IDENTITY_REQUEST,
  resendIdentityFailure,
  resendIdentitySuccess,
  SELECT_IDENTITY_REQUEST,
  selectIdentityFailure,
  selectIdentityRequest,
  selectIdentitySuccess,
} from "./actions";

function* createIdentityWorker(action) {
  try {
    const { email, type } = action.payload.emailIdentityData;

    if (type === "domain") {
      action.payload.emailIdentityData.identity = email.substring(
        email.lastIndexOf("@") + 1
      );
    }

    const apiResult = yield call(apiCreateIdentity, action.payload);

    yield toast.success(
      `${action.payload.emailIdentityData.identity} successfully Added to contacts!`
    );

    yield put(createIdentitySuccess(apiResult.data));

    yield put(selectIdentityRequest(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);

    yield toast.error(error.message);
    yield put(createIdentityFailure(errorMessage));
  }
}

function* getIdentitiesWorker(action) {
  try {
    const apiResult = yield call(apiGetIdentities, action.payload);

    yield put(getIdentitiesSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getIdentitiesFailure(errorMessage));
  }
}

function* deleteIdentityWorker(action) {
  try {
    const apiResult = yield call(apiDeleteIdentity, action.payload);

    yield put(deleteIdentitySuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(deleteIdentityFailure(errorMessage));
  }
}

function* getDomainRecordsWorker(action) {
  try {
    const apiResult = yield call(apiGetDomainRecords, action.payload);

    yield put(getDomainRecordsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getDomainRecordsFailure(errorMessage));
  }
}

function* resendIdentityWorker(action) {
  try {
    const apiResult = yield call(apiResendIdentity, action.payload);

    yield put(resendIdentitySuccess(apiResult.data));

    yield toast.success(`verification email was sent successfully`);
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(resendIdentityFailure(errorMessage));
    yield toast.error(error.message);
  }
}

function* selectIdentityWorker(action) {
  try {
    yield put(selectIdentitySuccess(action.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(selectIdentityFailure(errorMessage));
  }
}

export default function* watcher() {
  yield takeLatest(GET_IDENTITIES_REQUEST, getIdentitiesWorker);
  yield takeLatest(CREATE_IDENTITY_REQUEST, createIdentityWorker);
  yield takeLatest(DELETE_IDENTITY_REQUEST, deleteIdentityWorker);
  yield takeLatest(GET_DOMAIN_RECORDS_REQUEST, getDomainRecordsWorker);
  yield takeLatest(RESEND_IDENTITY_REQUEST, resendIdentityWorker);
  yield takeLatest(SELECT_IDENTITY_REQUEST, selectIdentityWorker);
}
