import React from "react";
import {Backdrop, Fade, Modal} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ButtonsPanel from "./ButtonsPanel";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "25px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
}));

const ConfirmDeleteCustomerModal = (props) => {
  const {
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
    selectedNetwork,
    setSelectedNetwork,
    deleteNetworkOrganisationRequest,
    isNetworkDeletedSuccessfully,
  } = props;

  const classes = useStyles();
  //   const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    // setOpen(true);
    setShowConfirmDeleteModal(true);
  };

  const handleClose = () => {
    // setOpen(false);
    setShowConfirmDeleteModal(false);
    setSelectedNetwork(null);
  };

  const [hasAlreadyBeenDeleted, setHasAlreadyBeenDeleted] =
      React.useState(false);

  return (
      <div>
        <div onClick={handleOpen}>
          {/*react-transition-group*/}
          <img alt="delete" src={require("assets/images/DeleteA.png").default}/>
          Delete
        </div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={showConfirmDeleteModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
          <Fade in={showConfirmDeleteModal}>
            <div className={classes.paper}>
              <img
                  alt="close"
                  onClick={handleClose}
                  className={"closeImg"}
                  src={require("assets/images/Group41.png")}
              />
              <div className={"audienceBlock"}>
                {hasAlreadyBeenDeleted && isNetworkDeletedSuccessfully ? (
                    <p>
                  <span style={{fontWeight: 700}}>
                    {selectedNetwork?.name}
                  </span>{" "}
                      deleted successfully
                    </p>
                ) : (
                    <div className={"successfulImport"}>
                      <img alt="confirmation" src={require("assets/images/areyousure.png").default}/>
                      <h2 id="simple-modal-title">
                        Are You Sure you want to delete the organisation{" "}
                        <span style={{fontWeight: 700}}>
                      {selectedNetwork?.name}?
                    </span>
                      </h2>
                      <p className={"areYouSure"} style={{color: "red"}}>
                        This cannot be undone.
                      </p>

                      <ButtonsPanel
                          handleClose={handleClose}
                          deleteNetworkOrganisationRequest={
                            deleteNetworkOrganisationRequest
                          }
                          selectedNetwork={selectedNetwork}
                          setHasAlreadyBeenDeleted={setHasAlreadyBeenDeleted}
                          hasAlreadyBeenDeleted={hasAlreadyBeenDeleted}
                      ></ButtonsPanel>
                    </div>
                )}
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
  );
};

export default ConfirmDeleteCustomerModal;
