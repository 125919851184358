import React from "react";
import styled from "@emotion/styled";
import {compose} from "recompose";

import SuperAdminDashboardContainer from "containers/SuperAdminDashboard";

import DashboardTop from "./components/DashboardTop";
import DashboardStats from "./components/DashboardStats";
import DashboardMap from "./components/DashboardMap";
import DashboardChartNew from "./components/DashboardChartNew";

import useWindowSize from "utils/hooks/useWindowSize";

import DIMENSIONS from "consts/dimensions";

const DashboardWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 98%; // change on mediaqueries?
  margin: 0 auto;
  height: 100%;
  /* overflow-x: hidden; */
  display: grid;
  grid-template-rows: ${(props) =>
    `minmax(auto, 40px) minmax(auto,${props.windowHeight / 2}px) auto`};

  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  padding: 0 20px;

  max-height: calc(100vh - ${DIMENSIONS.topBarHeight}px - 20px);

  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 1200px) {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    /* grid-template-rows: auto minmax(auto, 420px) auto; */
    grid-template-rows: ${(props) =>
      `minmax(auto, 40px) minmax(auto,${props.windowHeight / 2}px) auto`};
  }

  @media (max-width: 900px) {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto auto auto;
  }
`;

const SuperAdminDashboard = (props) => {
  const windowSize = useWindowSize();
  const {
    // organisationId,
    //SUPER ADMIN DASHBOARD CONT
    getYearVisitsRequest,
    getMonthVisitsRequest,
    getWeekVisitsRequest,
    getDayVisitsRequest,
    yearVisits,
    monthVisits,
    weekVisits,
    dayVisits,
    // chartMaxVal,
    userOrganisation,
    user,
  } = props;

  // const [chartTouched, setChartTouched] = React.useState(false);
  const [currentChartData, setCurrentChartData] = React.useState(null);

  const [currentChartTimeUnit, setCurrentChartTimeUnit] =
    React.useState("month");

  React.useEffect(() => {
    function getAllVisits() {
      getYearVisitsRequest(userOrganisation.externalId);
      getMonthVisitsRequest(userOrganisation.externalId);
      getWeekVisitsRequest(userOrganisation.externalId);
      getDayVisitsRequest(userOrganisation.externalId);
    }

    userOrganisation && userOrganisation.externalId && getAllVisits();
  }, [userOrganisation]);

  React.useEffect(() => {
    if (!currentChartData) {
      setCurrentChartData(monthVisits);
      return;
    }

    switch (currentChartTimeUnit) {
      case "year":
        setCurrentChartData(yearVisits);
        break;
      case "month":
        setCurrentChartData(monthVisits);
        break;
      case "week":
        setCurrentChartData(weekVisits);
        break;
      case "day":
        setCurrentChartData(dayVisits);
        break;
    }
  }, [currentChartTimeUnit, monthVisits]);

  return (
    user &&
    user.isSuperAdmin === true &&
    windowSize && (
      <DashboardWrapper windowHeight={windowSize.height}>
        <DashboardTop admin={user.isSuperAdmin} />
        <DashboardStats organisationId={userOrganisation?.externalId} />
        <DashboardChartNew organisationId={userOrganisation?.externalId} />
        {/* <DashboardChart
          withIcon={true}
          chartTitle="Visits"
          organisationId={userOrganisation?.externalId}
          setCurrentTimeUnit={setCurrentChartTimeUnit}
          currentTimeUnit={currentChartTimeUnit}
          // data={superAdminState[currentChartTimeUnit]}
          data={currentChartData}
          setChartTouched={setChartTouched}
          chartMaxVal={chartMaxVal}
        /> */}

        <DashboardMap admin={true} userOrganisation={userOrganisation} />
      </DashboardWrapper>
    )
  );
};

export default compose(SuperAdminDashboardContainer)(SuperAdminDashboard);
