import React from 'react'

import '../assets/css/signups.scss'
import positionIcon from "../assets/images/position-icon.svg"

export const Signups = ({ statistics }) => {

    const getSignupStats = () => {
        if (statistics) {
            delete statistics.signups['sms_added']
            delete statistics.signups['email_added']
            const total = Object.entries(statistics.signups).map(([key, value]) => value.total).reduce((c, t) => t += c)
            return Object.entries(statistics.signups).map(([key, value]) => {
                return { name: key, value: value.total, percentage: total === 0 ? 0 : Math.round(100 * value.total / total), icon: require(`../assets/images/${key}-icon.svg`) }
            });
        }

        return []
    }

    return (
        <div className='signups'>
            <div className='signups-top'>
                <div className='signups-logo'>
                    <img src={positionIcon} alt=''/>
                </div>
                <label className='card-title'>Customer logins</label>
            </div>
            <hr />
            <div className='sign-up-stats-container'>

                {getSignupStats().map(stats =>
                    <div key={stats.name} className='signups-stats'>
                        <div className='name'>
                            <img alt="stats icon" src={stats.icon.default} />
                            <label>{stats.name}</label>
                        </div>
                        <div className='stats'>
                            <label>{stats.value}</label>
                            <label>{`${stats.percentage}%`}</label>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
