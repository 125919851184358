export const SET_USER_REQUEST = "SET_USER_REQUEST";
export const SET_USER_FAILURE = "SET_USER_FAILURE";
export const SET_USER_SUCCESS = "SET_USER_SUCCESS";

// --- SETUSER

export const setUserRequest = (user) => ({
  type: SET_USER_REQUEST,
  payload: user,
});

export const setUserFailure = (errorMessage) => ({
  type: SET_USER_FAILURE,
  payload: errorMessage,
});

export const setUserSuccess = (user) => ({
  type: SET_USER_SUCCESS,
  payload: user,
});
