import React from "react";
import ConnectedTick from "assets/images/connectedTick.svg";
import "assets/css/design/screens/text-redirect-screen.scss";

import LogoContainer from "../../components/LogoContainer";

const TextRedirectScreen = (props) => {
  const {settings} = props;
  return (
      <div
          className="text-redirect"
          style={{
            backgroundColor: settings?.redirectPage?.backgroundColour
                ? settings?.redirectPage?.backgroundColour
                : "white",
          }}
      >
        <LogoContainer mt={50} logo={settings?.logoImage?.url}/>

        <img alt="" className="text-redirect__tick" src={ConnectedTick}/>
        <label
            style={{
              color: settings?.redirectPage?.titleTextColour
                  ? settings?.redirectPage?.titleTextColour
                  : "#ffcc33",
            }}
            className="text-redirect__subtitle"
        >
          {settings?.redirectPage?.titleText
              ? settings?.redirectPage?.titleText
              : "You are now connected"}
        </label>
        <label
            style={{
              color: settings?.redirectPage?.subTitleTextColour
                  ? settings?.redirectPage?.subTitleTextColour
                  : "black",
            }}
            className="text-redirect__description"
        >
          {settings?.redirectPage?.subTitleText
              ? settings?.redirectPage?.subTitleText
              : "Continue to our website for the latest offers and deals!"}
        </label>
        <button
            style={{
              color: settings?.redirectPage?.buttonTextColour
                  ? settings?.redirectPage?.buttonTextColour
                  : "white",
              backgroundColor: settings?.redirectPage?.buttonColour
                  ? settings?.redirectPage?.buttonColour
                  : "#a41c4a",
            }}
            className="text-redirect__button"
        >
          {settings?.redirectPage?.buttonText
              ? settings?.redirectPage?.buttonText
              : "Visit Our Website"}
        </button>
      </div>
  );
};

export default TextRedirectScreen;
