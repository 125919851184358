import styled from "@emotion/styled";

import DIMENSIONS from "consts/dimensions";

const TopBarWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  // padding: 20px 40px 20px 20px;
  padding: 0 40px;
  height: ${DIMENSIONS.topBarHeight}px;

  align-items: center;
  background-color: white;
  justify-content: space-between;
  z-index: 99999987;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);
  width: 97%;

  .dropdown-container {
    .options {
      z-index: 4;
    }
  }

  .top-bar-right {
    display: flex;
    flex-direction: row;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    .exit-organistion {
      border-radius: 45px;

      align-self: center;
      background-color: white;
      padding: 10px 25px;
      margin-right: 20px;
      outline: 0;
      user-select: none;
      cursor: pointer;

      border: 1px solid #e85558;
      color: #e85558;

      label {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        font-family: Roboto;
      }
    }

    .venue-name {
      align-self: center;
      align-self: center;
      background: #f7f7f7;
      border-radius: 32px;
      padding: 12px 20px;
      outline: 0;
      margin-left: 20px;
      user-select: none;

      color: #5b5b5b;
      font-size: 14px;
      font-weight: 400;
      font-family: Roboto;
    }
  }

  .support-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 110px;
    user-select: none;

    label {
      font-family: Roboto Bold;
      margin-left: 10px;
      font-weight: 500;
      font-size: 14px;
      color: #3c4858;
    }
  }

  .edit-mode {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;

    label {
      font-family: Roboto Bold;
      margin-right: 15px;
      font-weight: 500;
      font-size: 14px;
      color: #3c4858;
      cursor: pointer;
    }

    .switch-icon {
      height: 25px;
      width: 50px;
      cursor: pointer;
    }
  }

  .email-creator {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      font-size: 23px;
      font-weight: normal;
      font-size: 24px;
      display: flex;
      margin-left: 10px;
      margin-top: 10px;
      font-weight: 400;
    }
  }
  /* 
  .top-bar-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  } */
/* 
  .top-bar-right .exit-organistion {
    min-width: 120px;
    border-radius: 25px;
    border-color: #e85558;
    color: #e85558;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    background-color: white;
    padding: 10px;
    margin-left: 20px;
    outline: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  } */
`;

export default TopBarWrapper;
