import {call, put, takeEvery, takeLatest} from "redux-saga/effects";

import {toast} from "react-toastify";

import history from "utils/history";

import {
  apiCreateCurrentCampaign,
  apiCreateTemplate,
  apiDeleteTemplate,
  apiGetFeaturedTemplates,
  apiGetSavedTemplates,
  apiSendTestEmail,
} from "./api";

import actions from "./actions";

const {
  GET_FEATURED_TEMPLATES_REQUEST,
  GET_SAVED_TEMPLATES_REQUEST,
  UPDATE_CURRENT_CAMPAIGN_REQUEST,
  SEND_VERIFICATION_CODE_REQUEST,
  CREATE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_REQUEST,
  CREATE_FEATURED_TEMPLATE_REQUEST,
  SEND_TEST_EMAIL_REQUEST,
  CREATE_CURRENT_CAMPAIGN_REQUEST,
  UPDATE_CURRENT_STEP_REQUEST,
  RESET_ALL_VALUES_REQUEST,
  getFeaturedTemplatesRequest,
  getFeaturedTemplatesFailure,
  getFeaturedTemplatesSuccess,
  getSavedTemplatesRequest,
  getSavedTemplatesFailure,
  getSavedTemplatesSuccess,
  updateCurrentCampaignFailure,
  updateCurrentCampaignSuccess,
  sendVerificationCodeFailure,
  sendVerificationCodeSuccess,
  createTemplateSuccess,
  createTemplateFailure,
  deleteTemplateSuccess,
  deleteTemplateFailure,
  createFeaturedTemplateSuccess,
  createFeaturedTemplateFailure,
  sendTestEmailFailure,
  sendTestEmailSuccess,
  createCurrentCampaignFailure,
  createCurrentCampaignSuccess,
  updateCurrentStepRequest,
  updateCurrentStepFailure,
  updateCurrentStepSuccess,
  resetAllValuesFailure,
  resetAllValuesSuccess,
} = actions;

function* getFeaturedTemplatesWorker(action) {
  try {
    const apiResult = yield call(apiGetFeaturedTemplates, action.payload);

    yield put(getFeaturedTemplatesSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getFeaturedTemplatesFailure(errorMessage));
  }
}

function* getSavedTemplatesWorker(action) {
  try {
    const apiResult = yield call(apiGetSavedTemplates, action.payload);

    yield put(getSavedTemplatesSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getSavedTemplatesFailure(errorMessage));
  }
}

function* updateCurrentCampaignWorker(action) {
  try {
    yield put(updateCurrentCampaignSuccess(action.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(updateCurrentCampaignFailure(errorMessage));
  }
}

function* sendVerificationCodeWorker(action) {
  try {
    yield put(sendVerificationCodeSuccess(action.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(sendVerificationCodeFailure(errorMessage));
  }
}

function* createTemplateWorker(action) {
  try {
    const apiResult = yield call(apiCreateTemplate, action.payload);

    yield put(createTemplateSuccess(apiResult.data));
  } catch (error) {
    console.log(error);
    yield put(createTemplateFailure(error));
  }
}

function* deleteTemplateWorker(action) {
  try {
    const { organisationId, templateType } = action.payload;
    const apiResult = yield call(apiDeleteTemplate, action.payload);

    yield put(deleteTemplateSuccess(apiResult.data));

    if (templateType === "featured") {
      yield put(getFeaturedTemplatesRequest());
    } else {
      yield put(getSavedTemplatesRequest(organisationId, 0));
    }


    yield toast.success('Template deleted');
  } catch (error) {
    console.log(error);
    yield put(deleteTemplateFailure(error));
  }
}

function* createFeaturedTemplateWorker(action) {
  try {
    yield put(createFeaturedTemplateSuccess(action.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(createFeaturedTemplateFailure(errorMessage));
  }
}

function* sendTestEmailWorker(action) {
  try {
    const apiResult = yield call(apiSendTestEmail, action.payload);

    yield put(sendTestEmailSuccess(apiResult));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(sendTestEmailFailure(errorMessage));
  }
}

function* createCurrentCampaignWorker(action) {
  try {
    const apiResult = yield call(apiCreateCurrentCampaign, action.payload);

    yield put(createCurrentCampaignSuccess(apiResult.data));
    yield put(updateCurrentStepRequest(4));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(createCurrentCampaignFailure(errorMessage));
  }
}

function* updateCurrentStepWorker(action) {
  try {
    yield put(updateCurrentStepSuccess(action.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(updateCurrentStepFailure(errorMessage));
  }
}

function* resetAllValuesWorker(action) {
  try {
    const redirectTo = yield action.payload ? action.payload : "/dashboard";
    yield history.push(redirectTo);
    // yield put("/dashboard");
    yield put(resetAllValuesSuccess());
    // yield put("/dashboard");
  } catch (error) {
    const errorMessage = yield error.toJSON();
    console.log(errorMessage);
    yield put(resetAllValuesFailure(errorMessage));
  }
}

export default function* watcher() {
  yield takeLatest(GET_FEATURED_TEMPLATES_REQUEST, getFeaturedTemplatesWorker);
  yield takeLatest(GET_SAVED_TEMPLATES_REQUEST, getSavedTemplatesWorker);
  yield takeLatest(
    UPDATE_CURRENT_CAMPAIGN_REQUEST,
    updateCurrentCampaignWorker
  );
  yield takeLatest(SEND_VERIFICATION_CODE_REQUEST, sendVerificationCodeWorker);
  yield takeLatest(CREATE_TEMPLATE_REQUEST, createTemplateWorker);
  yield takeLatest(DELETE_TEMPLATE_REQUEST, deleteTemplateWorker);
  yield takeLatest(
    CREATE_FEATURED_TEMPLATE_REQUEST,
    createFeaturedTemplateWorker
  );
  yield takeLatest(SEND_TEST_EMAIL_REQUEST, sendTestEmailWorker);

  yield takeLatest(
    CREATE_CURRENT_CAMPAIGN_REQUEST,
    createCurrentCampaignWorker
  );

  yield takeEvery(UPDATE_CURRENT_STEP_REQUEST, updateCurrentStepWorker);
  yield takeEvery(RESET_ALL_VALUES_REQUEST, resetAllValuesWorker);
}
