import React, { useState } from "react";

import EditVenuePopup from "components/venue/editVenuePopup";
import UsersPopup from "components/venue/usersPopup";
import api from "utils/api";

import { ReactComponent as DeleteIcon } from "assets/images/org-delete.svg";
import { ReactComponent as DashboardIcon } from "assets/images/org-dashboard.svg";
import { ReactComponent as SuspendIcon } from "assets/images/org-suspend.svg";
import { ReactComponent as UsersIcon } from "assets/images/users-icon.svg";
import { ReactComponent as EditIcon } from "assets/images/edit-icon.svg";

import "assets/css/network-item.scss";

const NetworkItem = (props) => {
  const {
    network,
    setNetwork,
    setSelectedNetwork,
    // selectNetwork,
    deleteNetwork,
    setShowPopup,
    selectOrganisationRequest,
    //Network props
    deleteNetworkOrganisationRequest,
    setShowConfirmDeleteModal,
  } = props;

  const [showEdit, setShowEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [showUsers, setShowUsers] = useState(false);

  const getAddress = (network) => {
    if (network && network.location) return network.location.fullAddress;
    return "";
  };

  const showAdminUsers = async () => {
    const { data } = await api.get(
      `/organisations/${network.externalId}/users`
    );
    setUsers(data);
    setShowUsers(true);
  };

  const handleDeleteButton = () => {

    setSelectedNetwork(network);
    setShowConfirmDeleteModal(true);
  };

  return (
    <div className="network-item">
      <div className="network-item-top">
        <label className="title">{network.name}</label>
        <div className="network-item-actions">
          {network.isSuspended && (
            <SuspendIcon className="network-item-action dashboard" />
          )}
          <UsersIcon
            className="network-item-action users"
            onClick={showAdminUsers}
          />
          <EditIcon
            className="network-item-action dashboard"
            onClick={() => setShowEdit(true)}
          />
          <DeleteIcon
            className="network-item-action dashboard"
            // onClick={() => deleteNetwork(network)}
            // onClick={() => deleteNetworkOrganisationRequest(network.externalId)}
            onClick={handleDeleteButton}
          />
          <DashboardIcon
            onClick={() => {
              // selectNetwork(network);

              selectOrganisationRequest(network);
            }}
            className="network-item-action dashboard"
          />
        </div>
      </div>
      <hr />

      <div className="network-item-bottom ">
        <div className="location-container">
          <img src={require("assets/images/position-icon-grey.svg").default} alt="" />
          <label>{getAddress(network)}</label>
        </div>
        <label className="venue">
          {network.venues} Venue{network.venues > 1 ? "s" : ""}
        </label>
      </div>
      {showEdit && (
        <EditVenuePopup
          venue={network}
          setVenue={setNetwork}
          close={() => setShowEdit(false)}
        />
      )}
      {showUsers && (
        <UsersPopup
          organisationId={network.externalId}
          users={users}
          setUsers={setUsers}
          close={() => setShowUsers(false)}
          setShowPopup={setShowPopup}
        />
      )}
    </div>
  );
};

export default NetworkItem;
