import React from "react";

import "assets/css/design/screens/custom-image-redirect-screen.scss";

const CustomImageRedirectScreen = (props) => {
  const {settings} = props;
  return (
      <div
          className="custom-image-redirect"
          style={{
            backgroundColor: "#FFFFFF",
            backgroundImage: `url(${settings?.customImage?.url}`,
          }}
      >
        <button
            style={{
              backgroundColor: settings?.redirectPage?.customImageButtonColor
                  ? settings?.redirectPage?.customImageButtonColor
                  : "#A41C4A",
              color: settings?.redirectPage?.customImageButtonTextColor
                  ? settings?.redirectPage?.customImageButtonTextColor
                  : "#FFFFFF",
            }}
            className="custom-image-redirect__button"
        >
          {settings?.redirectPage?.customImageButtonText
              ? settings?.redirectPage?.customImageButtonText
              : "Visit Our Website"}
        </button>
      </div>
  );
};
export default CustomImageRedirectScreen;
