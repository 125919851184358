import {
  GET_ORGANISATION_CAMPAIGNS_REQUEST,
  GET_ORGANISATION_CAMPAIGNS_FAILURE,
  GET_ORGANISATION_CAMPAIGNS_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  errorMessage: null,
  organisationCampaigns: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANISATION_CAMPAIGNS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        organisationCampaigns: null,
      };
    }

    case GET_ORGANISATION_CAMPAIGNS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        organisationCampaigns: null,
      };
    }

    case GET_ORGANISATION_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        organisationCampaigns: action.payload,
      };
    }

    default:
      return state;
  }
}
