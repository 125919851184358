import React from "react";

import { compose } from "recompose";
import SendSettingsContainer from "containers/Send/SendSettings";

import PageTitle from "components/PageTitle";
import SendSettingsWrapper from "./SendSettingsWrapper";

import NoDomainsMessage from "./NoDomainsMessage";
import AddDomainPopup from "./AddDomainPopup";
import DomainsList from "./DomainsList";
import { ampli } from "containers/Amplitude/ampli";

// import "assets/css/sendSettings/settings.scss";

const SendSettings = (props) => {
  const {
    selectedEntity,
    getIdentitiesRequest,
    createdIdentity,
    deletedIdentity,
    organisationIdentities,
    getDomainRecordsRequest,
    currentIdentity,
    selectIdentityRequest,
  } = props;

  const organisationId = selectedEntity.externalId;

  const [hasModalBeenOpened, setHasModalBeenOpened] = React.useState(false);

  const [showAddDomainPopup, setShowAddDomainPopup] = React.useState(false);

  const [currentVerificationType, setCurrentVerificationType] =
    React.useState(null);

  React.useEffect(() => {
    getIdentitiesRequest(organisationId);
  }, [createdIdentity, deletedIdentity, organisationId]);

  React.useEffect(() => {
    currentIdentity && getDomainRecordsRequest(currentIdentity);
  }, [currentIdentity]);

  const handleCloseModal = () => {
    setShowAddDomainPopup(false);
    setCurrentVerificationType(null);
    setHasModalBeenOpened(false);
  };

  ampli.unaroPageVisitEventWithProperties({
    emailAddress: JSON.parse(localStorage.getItem("user")).email,
    organisationName:
      JSON.parse(localStorage.getItem("user")).organisations[0] != null
        ? JSON.parse(localStorage.getItem("user")).organisations[0].name
        : "",
        eventType: "page visit: Send -> Settings",
  });

  return (
    <SendSettingsWrapper>
      <PageTitle
        title={"Email Settings"}
        style={{ paddingTop: "15px", paddingLeft: "25px" }}
      />
      <div className="ss-content">
        <label className="ssc-text">
          Here you can confirm the domains you want to use as “from” addresses
          to send email campaigns and email automations. After that,
          authenticate your domains to improve deliverability.
        </label>

        {organisationIdentities && organisationIdentities.length > 0 ? (
          <>
            <button
              className="ssc-btn"
              onClick={() => setShowAddDomainPopup(true)}
            >
              Verify Email Domain
            </button>

            <DomainsList
              {...props}
              organisationIdentities={organisationIdentities}
              setShowAddDomainPopup={setShowAddDomainPopup}
              setCurrentVerificationType={setCurrentVerificationType}
            />
          </>
        ) : (
          <>
            <NoDomainsMessage />
            <button
              className="ssc-btn"
              onClick={() => setShowAddDomainPopup(true)}
            >
              Verify Email Domain
            </button>
          </>
        )}
      </div>
      {showAddDomainPopup && (
        <AddDomainPopup
          {...props}
          selectedEntity={selectedEntity}
          close={() => handleCloseModal()}
          hasModalBeenOpened={hasModalBeenOpened}
          currentVerificationType={currentVerificationType}
          setCurrentVerificationType={setCurrentVerificationType}
          setHasModalBeenOpened={setHasModalBeenOpened}
          selectIdentityRequest={selectIdentityRequest}
        />
      )}
    </SendSettingsWrapper>
  );
};

export default compose(SendSettingsContainer)(SendSettings);
