import React from "react";
import {RadioButton} from "components/widget/radioButton";
import type {Input} from "../../types";

const StandardInput = (props) => {
  const {inputs, setInput, inputKey} = props;
  const input: Input = inputs[inputKey];
  return (
      <div
          className="edit-landing-page-switch"
          role="button"
          tabIndex="0"
          onClick={() => setInput(inputKey)}
      >
        <RadioButton selected={input?.selected}/>
        <p className="text-switch">{input?.text}</p>
      </div>
  );
};

export default StandardInput;
