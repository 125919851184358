import actions from "../actions";

const {
  GET_AUDIENCES_REQUEST,
  GET_AUDIENCES_FAILURE,
  GET_AUDIENCES_SUCCESS,
  SELECT_AUDIENCE_REQUEST,
  SELECT_AUDIENCE_FAILURE,
  SELECT_AUDIENCE_SUCCESS,
  GET_AUDIENCE_CONTACTS_REQUEST,
  GET_AUDIENCE_CONTACTS_FAILURE,
  GET_AUDIENCE_CONTACTS_SUCCESS,
  TOGGLE_AUDIENCE_CONTACT_REQUEST,
  TOGGLE_AUDIENCE_CONTACT_FAILURE,
  TOGGLE_ALL_AUDIENCE_CONTACTS_SUCCESS,
  TOGGLE_ALL_AUDIENCE_CONTACTS_REQUEST,
  TOGGLE_ALL_AUDIENCE_CONTACTS_FAILURE,
  TOGGLE_AUDIENCE_CONTACT_SUCCESS,
  CREATE_AUDIENCE_REQUEST,
  CREATE_AUDIENCE_FAILURE,
  CREATE_AUDIENCE_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  currentPagination: null,
  //
  allAudiences: null,
  allAudiencesContacts: null,
  //
  selectedAudience: null,
  selectedAudienceContacts: null,
  //
  createdAudience: null,
};

export default function audiencesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AUDIENCES_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
        allAudiences: null,
      };
    }

    case GET_AUDIENCES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        allAudiences: null,
      };
    }

    case GET_AUDIENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allAudiences: action.payload,
      };
    }

    ///---
    case SELECT_AUDIENCE_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case SELECT_AUDIENCE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SELECT_AUDIENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedAudience: action.payload.selectedAudience,
      };
    }

    ///---
    case CREATE_AUDIENCE_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case CREATE_AUDIENCE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case CREATE_AUDIENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        createdAudience: action.payload.createdAudience,
      };
    }

    ///---
    case GET_AUDIENCE_CONTACTS_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case GET_AUDIENCE_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_AUDIENCE_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedAudienceContacts: action.payload.audienceContacts,
        currentPagination: action.payload.currentPagination,
      };
    }

    ///---
    case TOGGLE_AUDIENCE_CONTACT_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        // selectedAudienceContacts: null,
      };
    }

    case TOGGLE_AUDIENCE_CONTACT_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        selectedAudienceContacts: null,
      };
    }

    case TOGGLE_AUDIENCE_CONTACT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,

        selectedAudienceContacts: {
          ...state.selectedAudienceContacts,
          [action.payload.contactId]: {
            ...state.selectedAudienceContacts[action.payload.contactId],
            selected:
              !state.selectedAudienceContacts[action.payload.contactId]
                .selected,
          },
        },
      };
    }

    //---

    case TOGGLE_ALL_AUDIENCE_CONTACTS_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case TOGGLE_ALL_AUDIENCE_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case TOGGLE_ALL_AUDIENCE_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedAudienceContacts: {
          ...action.payload.updatedAudienceSelectedContacts,
        },
      };
    }

    default:
      return state;
  }
}
