export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const TOASTS_TIMEOUT = 2000;

export const CONTACTS_LIST_TYPES = {
  ALL: "all",
  SEGMENTS: "segments",
  AUDIENCES: "audiences",
};

export const UNLAYER_API_KEY = btoa(
  "tBaUi7NYcCiOE9Hy7Ozrxz0Vv3DhiJjGXr3Ns2rFpFbOF9RZw2d2RpwCE5n8kCwK"
);

export const COUNTRY_PHONE_CODES = [
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    code: "+44",
  },
  // {
  //   name: "United States",
  //   flag: "🇺🇸",
  //   code: "+1",
  // },
  // {
  //   name: "Germany",
  //   flag: "🇩🇪",
  //   code: "+49",
  // },
  // {
  //   name: "Spain",
  //   flag: "🇪🇸",
  //   code: "+34",
  // },
  // {
  //   name: "France",
  //   flag: "🇫🇷",
  //   code: "+33",
  // },
];
