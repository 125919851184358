import React from "react";
import styled from "@emotion/styled";

const ButtonsPanelWrapper = styled.div``;

const StatusPlaceholder = styled.div`
  min-height: 75px;
  position: relative;

  p {
    position: absolute;
    font-family: Roboto-Bold;
    font-size: 14px;
    opacity: 0.85;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const ErrorMessage = styled.label`
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 75px;
  color: #ea5454;
  font-size: 12px;
`;

const ButtonsPanel = (props) => {
  const {
    handleClose,
    deleteNetworkOrganisationRequest,
    selectedNetwork,
    // hasAlreadyBeenDeleted,
    setHasAlreadyBeenDeleted,
  } = props;

  const handleDelete = () => {
    setHasAlreadyBeenDeleted(true);
    deleteNetworkOrganisationRequest(selectedNetwork.externalId);
  };

  // const [isLoading, setIsLoading] = React.useState(false);

  return (
    <ButtonsPanelWrapper>
      {/* <StatusPlaceholder>
        {isLoading && (
          <>
            <p>Deleting...</p>
            <LoadingCirclesAnim />
          </>
        )}
      </StatusPlaceholder> */}
      <div className="exportButton">
        <div className="exportCSV" onClick={handleDelete}>
          Yes, Delete
        </div>
        <div className="cancelCSV" onClick={handleClose}>
          Cancel
        </div>
      </div>
    </ButtonsPanelWrapper>
  );
};

export default ButtonsPanel;
