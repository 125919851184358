import React, {useEffect, useState} from "react";
import {from, map, Observable} from "rxjs";
import {AxiosResponse} from "axios";
import api from "../../../../../utils/api";
import {Param, ParamType, Template} from "../../../types";
import TemplateSelect from "./TemplateSelect";
import uuid from "react-uuid";

export const getTemplates = (organisationId: string): Observable<Template[]> => {
  return from(api.get(`/email/organisation/${organisationId}/template?size=1000`))
  .pipe(map((response: AxiosResponse) => response.data.content));
}

type Props = {
  organisationId: string
  templateId: string
  setParam: React.Dispatch<React.SetStateAction<Param>>
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const TemplateParam = ({organisationId, templateId, setParam, setDisabled}: Props) => {

  const [value, setValue] = useState<string>("")
  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    templateId && onChange(templateId);
  }, [templateId]);

  useEffect(() => {
    setDisabled(true);
    getTemplates(organisationId)
    .subscribe((response: Template[]) => {
      if (response && response.length > 0) {
        setTemplates(response);
        if (templateId) {
          const template = response.find((t: Template) => t.externalId === templateId);
          if (template) {
            setValue(templateId);
            setParam(getParam(templateId, template.name));
          }
        } else {
          setValue(response[0].externalId);
          setParam(getParam(response[0].externalId, response[0].name))
        }
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    });
    // eslint-disable-next-line
  }, [organisationId]);

  const onChange = (id: string) => {
    const template = templates.find((t: Template) => t.externalId === id);
    if (template) {
      setValue(id);
      setParam(getParam(id, template.name));
    }
  }

  const getParam = (value: string, name: string): Param => {
    return {
      id: uuid(),
      paramType: ParamType.TEMPLATE_ID,
      paramValue: value,
      label: name
    }
  }

  return (
      <TemplateSelect
          templates={templates}
          value={value}
          onChange={onChange}
      />
  );
}
export default TemplateParam;
