import React from 'react'
import {useHistory} from "react-router-dom";
import {ReactComponent as DropdownLogo} from 'assets/images/dropdown-icon.svg';
import 'assets/css/menu-item.scss'

const NavBarItem = ({
                      item,
                      onClick,
                      smallMenu,
                      onSubmenuClick,
                      smallSidebar
                    }) => {
  const history = useHistory();

  const onPress = () => {
    if (item.subItems && item.subItems.length > 0 && item.active) {
      onSubmenuClick(item.id, item.subItems[0].id)
      history.push(item.subItems[0].redirect)
    } else if (item.active) {
      history.push(item.redirect)
      onClick()
    }
  }

  const onSubItemClick = subItem => {
    if (subItem.active) {
      history.push(subItem.redirect)
      onSubmenuClick(item.id, subItem.id)
    }
  }

  const getClass = () => {
    if (item.selected) {
      return `menu-item-selected ${smallMenu
      && 'small-menu-item-selected'} ${!item.active && 'inactive-item'}`
    } else {
      return `menu-item ${smallMenu && 'small-menu-item'} ${!item.active
      && 'inactive-item'}`
    }
  }

  const getLogo = () => {
    if (React.isValidElement(item.logo)) {
      return item.active ? item.logo : item.inactiveLogo
    }
  }

  return (
      <div className={getClass()}>
        <div className='item' onClick={() => onPress()}>
          <div className='left'>
            <div>
              {getLogo()}
            </div>
            <label>{item.text}</label>
          </div>
          {item.subItems && item.subItems.length > 0 && <DropdownLogo
              className={`arrow-icon ${item.showSubItems
              && 'arrow-icon-down'}`}/>}
        </div>
        {item.subItems && item.subItems.length > 0 && item.showSubItems && <div
            className='subItems'>
          {item.subItems.map(subItem =>
              <div key={subItem.id} className={`subItem ${subItem.selected
              && 'subItem-selected'}`} onClick={() => onSubItemClick(subItem)}>
                <div>
                  <subItem.Icon className={`subItem-logo ${subItem.selected
                  && 'subItem-logo-selected'} 
                            ${!subItem.active && 'subItem-logo-inactive'} 
                            ${subItem.selected && (subItem.text === 'Contacts'
                      || subItem.text === 'Customers') && 'subItem-logo-person'}  
                            ${subItem.selected && subItem.text === 'Reports'
                  && 'subItem-logo-reports'}`}/>
                </div>
                <label>{subItem.text}</label>
              </div>
          )}
        </div>
        }
      </div>
  )
}

export default NavBarItem