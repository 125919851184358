import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
// import { push } from "connected-react-router";
import {REDUCER_NAME} from "./consts";

import {toast} from "react-toastify";
import {TOASTS_TIMEOUT} from "consts/index";

import {
  apiCreateAudience,
  apiCreateContact,
  apiCreateSegment,
  apiDeleteContacts,
  apiDeleteSegment,
  apiGetAllContacts,
  apiGetAudienceContacts,
  apiGetAudiences,
  apiGetSegmentContacts,
  apiGetSegments,
  apiImportContacts,
  apiLinkContactsToAudiences,
} from "./api";

import actions from "./actions";
import {Pagination} from "./types";

const {
  GET_ALL_CONTACTS_REQUEST,
  SELECT_LIST_TYPE_REQUEST,
  GET_SEGMENTS_REQUEST,
  TOGGLE_CONTACT_REQUEST,
  TOGGLE_SEGMENT_CONTACT_REQUEST,
  TOGGLE_ALL_CONTACTS_REQUEST,
  TOGGLE_ALL_AUDIENCE_CONTACTS_REQUEST,
  TOGGLE_ALL_SEGMENT_CONTACTS_REQUEST,
  SELECT_SEGMENT_REQUEST,
  GET_SEGMENT_CONTACTS_REQUEST,
  GET_AUDIENCES_REQUEST,
  SELECT_AUDIENCE_REQUEST,
  CREATE_AUDIENCE_REQUEST,
  GET_AUDIENCE_CONTACTS_REQUEST,
  TOGGLE_AUDIENCE_CONTACT_REQUEST,
  SET_CURRENT_PAGINATION_REQUEST,
  ADD_FILTER_REQUEST,
  REMOVE_FILTER_REQUEST,
  CREATE_SEGMENT_REQUEST,
  DELETE_SEGMENT_REQUEST,
  CREATE_CONTACT_REQUEST,
  IMPORT_CONTACTS_REQUEST,
  DELETE_CONTACTS_REQUEST,
  IMPORT_CONTACTS_LIST_REQUEST,
  PARSE_EXCEL_REQUEST,
  DELETE_IMPORTED_LIST_COLUMN_REQUEST,
  EDIT_IMPORTED_LIST_COLUMN_REQUEST,
  getAllContactsFailure,
  getAllContactsSuccess,
  selectListTypeFailure,
  selectListTypeSuccess,
  getSegmentsFailure,
  getSegmentsSuccess,
  getSegmentsRequest,
  toggleContactFailure,
  toggleContactSuccess,
  toggleSegmentContactFailure,
  toggleSegmentContactSuccess,
  selectSegmentFailure,
  selectSegmentSuccess,
  getSegmentContactsFailure,
  getSegmentContactsSuccess,
  getAudiencesFailure,
  getAudiencesSuccess,
  createAudienceFailure,
  createAudienceSuccess,
  selectAudienceFailure,
  selectAudienceSuccess,
  getAudienceContactsFailure,
  getAudienceContactsSuccess,
  toggleAudienceContactFailure,
  toggleAudienceContactSuccess,
  setCurrentPaginationFailure,
  setCurrentPaginationSuccess,
  addFilterFailure,
  addFilterSuccess,
  removeFilterFailure,
  removeFilterSuccess,
  deleteSegmentFailure,
  deleteSegmentSuccess,
  createSegmentFailure,
  createSegmentSuccess,
  createContactFailure,
  createContactSuccess,
  toggleAllContactsFailure,
  toggleAllContactsSuccess,
  toggleAllAudienceContactsFailure,
  toggleAllAudienceContactsSuccess,
  toggleAllSegmentContactsFailure,
  toggleAllSegmentContactsSuccess,
  importContactsFailure,
  importContactsSuccess,
  importContactsListFailure,
  importContactsListSuccess,
  // parcelExcelSuccess,
  parcelExcelFailure,
  deleteImportedListColumnFailure,
  // deleteImportedListColumnSuccess,
  editImportedListColumnFailure,
  // editImportedListColumnSuccess,
  deleteContactsFailure,
  deleteContactsSuccess,
} = actions;

const getCurrentPagination = data => {
  return {
    page: data.number,
    total: data.total,
    numberOfPages: data.totalPages
  }
}

const mapContacts = contacts => {
  let contactMap = {};
  if (contacts) {
    contacts.forEach((contact) => {
      contactMap[contact.id] = {
        id: contact.id,
        email: contact.email,
        allowEmail: contact.allowEmail,
        allowSms: contact.allowSms,
        audiences: contact.audiences,
        forename: contact.forename,
        surname: contact.surname,
        phones: contact.phones,
        noOfVisits: contact.noOfVisits,
        lastVisit: contact.lastVisit,
        avatarUrl: contact.avatarUrl,
        selected: true,
      };
    });
  }

  return contactMap;
}

//CONTACTS
function* getAllContactsWorker(action) {
  try {
    const apiResult = yield call(apiGetAllContacts, action.payload);

    const currentPagination = yield getCurrentPagination(apiResult.data);

    let allContactsObj = yield mapContacts(apiResult?.data?.content);

    yield put(getAllContactsSuccess(allContactsObj, currentPagination));

    yield put({
      type: SET_CURRENT_PAGINATION_REQUEST,
      payload: currentPagination,
    });
  } catch (error) {
    const errorMessage = yield JSON.stringify(
        error,
        Object.getOwnPropertyNames(error)
    );

    yield put(getAllContactsFailure(errorMessage));
  }
}

//MISC
function* selectListTypeWorker(action) {
  try {
    const listType = action.payload;

    yield put(selectListTypeSuccess(listType));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(selectListTypeFailure(errorMessage));
  }
}

//SEGMENTS
function* getSegmentsWorker(action) {
  try {
    const apiResult = yield call(apiGetSegments, action.payload);
    const allSegments = yield apiResult.data;

    const allSegmentsObj = yield {};

    yield allSegments.forEach((segment) => {
      allSegmentsObj[segment.externalId] = {
        id: segment.externalId,
        name: segment.name,
        iconName: segment.iconName,
        organisationId: segment.organisationId,
        criteria: segment.criteria,
      };
    });

    yield put(getSegmentsSuccess(allSegmentsObj));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getSegmentsFailure(errorMessage));
  }
}

function* selectSegmentWorker(action) {
  try {
    const {segmentId} = yield action.payload;

    const selectedSegment = yield select(
        (state) => state[REDUCER_NAME]["segments"].allSegments[segmentId]
    );

    yield put(selectSegmentSuccess(selectedSegment));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(selectSegmentFailure(errorMessage));
  }
}

function* getSegmentContactsWorker(action) {
  try {
    const apiResult = yield call(apiGetSegmentContacts, action.payload);

    const currentPagination = yield getCurrentPagination(apiResult.data);

    let allSegmentContacts = yield mapContacts(apiResult?.data?.content);

    yield put(getSegmentContactsSuccess(allSegmentContacts, currentPagination));

    yield put({
      type: SET_CURRENT_PAGINATION_REQUEST,
      payload: currentPagination,
    });
  } catch (error) {
    console.log(error);
    yield put(getSegmentContactsFailure(error));
  }
}

function* createSegmentWorker(action) {
  const {segmentName} = action.payload.newSegmentData;

  try {
    const apiResult = yield call(apiCreateSegment, action.payload);

    yield put(createSegmentSuccess(apiResult.data));

    yield put(getSegmentsRequest(action.payload.newSegmentData.organisationId));

    yield toast.success(`${segmentName} segment successfully created!`);
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(createSegmentFailure(errorMessage));

    yield toast.error(
        `Could not create segment ${segmentName} (${error.message})`
    );
  }
}

function* deleteSegmentWorker(action) {
  try {
    const apiResult = yield call(apiDeleteSegment, action.payload);
    const {segmentId} = action.payload.segmentToDelete;
    yield put(deleteSegmentSuccess(segmentId));
  } catch (error) {
    // const errorMessage = yield error.message;
    console.log(error);
    yield put(deleteSegmentFailure(error));
  }
}

//

function* toggleContactWorker(action) {
  try {
    const {contactId} = yield action.payload;

    yield put(toggleContactSuccess(contactId));
  } catch (error) {
    console.log(error);
    yield put(toggleContactFailure(error));
  }
}

function* toggleAllContactsWorker(action) {
  try {
    const {contacts, shouldToggleOn} = action.payload;

    const updatedContacts = contacts;

    Object.keys(updatedContacts).forEach((contactId) => {
      updatedContacts[contactId].selected = !shouldToggleOn;
    });

    yield put(toggleAllContactsSuccess(updatedContacts));
  } catch (error) {
    console.log(error);
    yield put(toggleAllContactsFailure(error));
  }
}

function* createContactWorker(action) {
  //TODO Deprecated and now using rxjs
  try {
    const {firstName, lastName} = action.payload.newContactData;

    const apiCreatedContactResult = yield call(
        apiCreateContact,
        action.payload
    );

    const {audiences, organisationId} = yield action.payload
        .newContactData;

    const contactIds = yield [apiCreatedContactResult.data.externalId];

    const payload = {
      organisationId,
      audiences,
      contactIds,
    };

    if (audiences.length > 0) {
      const apiLinkToAudiencesResult = yield call(
          apiLinkContactsToAudiences,
          payload
      );
    }

    yield toast.success(
        `${firstName} ${lastName} Successfully Added to contacts!`
    );

    yield put(createContactSuccess(action.payload));
  } catch (error) {
    console.log(error);

    yield toast.error(error.message);

    yield put(createContactFailure(error));
  }
}

function* toggleSegmentContactWorker(action) {
  try {
    const {contactId} = yield action.payload;

    yield put(toggleSegmentContactSuccess(contactId));
  } catch (error) {
    console.log(error);
    yield put(toggleSegmentContactFailure(error));
  }
}

function* toggleAudienceContactWorker(action) {
  try {
    const {contactId} = yield action.payload;

    yield put(toggleAudienceContactSuccess(contactId));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(toggleAudienceContactFailure(errorMessage));
  }
}

function* toggleAllAudienceContactsWorker(action) {
  try {
    const {audienceContacts, shouldToggleOn} = action.payload;

    const updatedAudienceContacts = audienceContacts;

    Object.keys(updatedAudienceContacts).forEach((contactId) => {
      updatedAudienceContacts[contactId].selected = !shouldToggleOn;
    });

    yield put(toggleAllAudienceContactsSuccess(updatedAudienceContacts));
  } catch (error) {
    console.log(error);
    yield put(toggleAllAudienceContactsFailure(error));
  }
}

function* toggleAllSegmentContactsWorker(action) {
  try {
    const {segmentContacts, shouldToggleOn} = action.payload;

    const updatedSegmentContacts = segmentContacts;

    Object.keys(updatedSegmentContacts).forEach((contactId) => {
      updatedSegmentContacts[contactId].selected = !shouldToggleOn;
    });

    yield put(toggleAllSegmentContactsSuccess(updatedSegmentContacts));
  } catch (error) {
    console.log(error);
    yield put(toggleAllSegmentContactsFailure(error));
  }
}

//---

function* getAudiencesWorker(action) {
  try {
    const apiResult = yield call(apiGetAudiences, action.payload);
    const allAudiences = yield apiResult.data;

    const allAudiencesObj = yield {};

    yield allAudiences.forEach((audience) => {
      allAudiencesObj[audience.id] = {
        id: audience.id,
        color: audience.color,
        name: audience.name,
        organisationId: audience.organisationId,
      };
    });

    yield put(getAudiencesSuccess(allAudiencesObj));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getAudiencesFailure(errorMessage));
  }
}

function* selectAudienceWorker(action) {
  try {
    const {segmentId} = yield action.payload;

    const selectedAudience = yield select(
        (state) => state[REDUCER_NAME]["audiences"].allAudiences[segmentId]
    );

    yield put(selectAudienceSuccess(selectedAudience));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(selectAudienceFailure(errorMessage));
  }
}


// AUDIENCES

function* getAudienceContactsWorker(action) {
  try {
  
    const apiResult = yield call(apiGetAudienceContacts, action.payload);

    const currentPagination = yield getCurrentPagination(apiResult.data);

    let allAudienceContacts = yield mapContacts(apiResult?.data?.content);

    yield put(getAudienceContactsSuccess(allAudienceContacts, currentPagination));

    yield put({
      type: SET_CURRENT_PAGINATION_REQUEST,
      payload: currentPagination,
    });
  } catch (error) {
    //const errorMessage = yield error.message;
    const errorMessage = yield error.message;
    console.log(error);
    yield put(getAudienceContactsFailure(errorMessage));
  }
}

function* createAudienceWorker(action) {
  try {
    const apiResult = yield call(apiCreateAudience, action.payload);

    yield put(createAudienceSuccess(apiResult.data));
  } catch (error) {
    console.log(error);
    yield put(createAudienceFailure(error));
  }
}

function* setCurrentPaginationWorker(action) {
  try {
    yield put(setCurrentPaginationSuccess(action.payload));
  } catch (error) {
    console.log(error);
    yield put(setCurrentPaginationFailure(error));
  }
}

//FILTERS

function* addFilterWorker(action) {
  try {
    yield put(addFilterSuccess(action.payload));
  } catch (error) {
    console.log(error);
    yield put(addFilterFailure(error));
  }
}

function* removeFilterWorker(action) {
  try {
    // const indexToDelete = action.payload;

    // const selectedCurrentFilters = yield select(
    //   (state) => state[REDUCER_NAME]["filters"].currentFilters
    // );

    yield put(removeFilterSuccess(action.payload));
  } catch (error) {
    console.log(error);
    yield put(removeFilterFailure(error));
  }
}

function* importContactsWorker(action) {
  try {
    const apiResult = yield call(apiImportContacts, action.payload);

    yield put(importContactsSuccess(apiResult));
  } catch (error) {
    console.log(error);
    yield put(importContactsFailure(error));
  }
}

function* deleteContactsWorker(action) {
  try {
    const {contactsIds, organisationId} = action.payload;

    const apiResult = yield call(apiDeleteContacts, action.payload);

    contactsIds.length === 1
        ? yield toast.success("1 Contact Deleted!")
        : yield toast.success(`${contactsIds.length} Contacts Deleted!`);

    yield delay(2000);

    yield put(deleteContactsSuccess(apiResult));

    // yield put({
    //   type: GET_ALL_CONTACTS_REQUEST,
    //   payload: {
    //     organisationId,
    //   },
    // });
  } catch (error) {
    yield toast.error("Oops.. something went wrong!");
    yield delay(TOASTS_TIMEOUT);

    console.log(error);
    yield put(deleteContactsFailure(error));
  }
}

//IMPORT LIST

function* importContactsListWorker(action) {
  try {
    const apiResult = yield call(apiImportContacts, action.payload);

    yield put(importContactsListSuccess(apiResult));
  } catch (error) {
    console.log(error);
    yield put(importContactsListFailure(error));
  }
}

function* parseExcelWorker(action) {
  try {
    // const apiResult = yield call(apiImportContacts, action.payload);
    // yield put(parcelExcelSuccess(apiResult));
  } catch (error) {
    console.log(error);
    yield put(parcelExcelFailure(error));
  }
}

function* editImportedListColumnWorker(action) {
  try {
    // const apiResult = yield call(apiImportContacts, action.payload);
    // yield put(editImportedListColumnSuccess(apiResult));
  } catch (error) {
    console.log(error);
    yield put(editImportedListColumnFailure(error));
  }
}

function* deleteImportedListColumnWorker(action) {
  try {
    // const apiResult = yield call(apiImportContacts, action.payload);
    // yield put(deleteImportedListColumnSuccess(apiResult));
  } catch (error) {
    console.log(error);
    yield put(deleteImportedListColumnFailure(error));
  }
}

export default function* watcher() {
  yield takeLatest(GET_ALL_CONTACTS_REQUEST, getAllContactsWorker);

  yield takeLatest(SELECT_LIST_TYPE_REQUEST, selectListTypeWorker);

  yield takeLatest(GET_SEGMENTS_REQUEST, getSegmentsWorker);
  yield takeLatest(SELECT_SEGMENT_REQUEST, selectSegmentWorker);
  yield takeLatest(GET_SEGMENT_CONTACTS_REQUEST, getSegmentContactsWorker);
  yield takeLatest(TOGGLE_CONTACT_REQUEST, toggleContactWorker);
  yield takeLatest(TOGGLE_ALL_CONTACTS_REQUEST, toggleAllContactsWorker);
  yield takeLatest(
      TOGGLE_ALL_AUDIENCE_CONTACTS_REQUEST,
      toggleAllAudienceContactsWorker
  );

  yield takeLatest(
      TOGGLE_ALL_SEGMENT_CONTACTS_REQUEST,
      toggleAllSegmentContactsWorker
  );

  yield takeLatest(CREATE_CONTACT_REQUEST, createContactWorker);
  yield takeLatest(TOGGLE_SEGMENT_CONTACT_REQUEST, toggleSegmentContactWorker);
  yield takeLatest(CREATE_SEGMENT_REQUEST, createSegmentWorker);
  yield takeLatest(DELETE_SEGMENT_REQUEST, deleteSegmentWorker);
  //
  yield takeLatest(
      TOGGLE_AUDIENCE_CONTACT_REQUEST,
      toggleAudienceContactWorker
  );
  yield takeLatest(GET_AUDIENCES_REQUEST, getAudiencesWorker);
  yield takeLatest(SELECT_AUDIENCE_REQUEST, selectAudienceWorker);
  yield takeLatest(CREATE_AUDIENCE_REQUEST, createAudienceWorker);
  yield takeLatest(GET_AUDIENCE_CONTACTS_REQUEST, getAudienceContactsWorker);
  yield takeEvery(SET_CURRENT_PAGINATION_REQUEST, setCurrentPaginationWorker);
  yield takeEvery(ADD_FILTER_REQUEST, addFilterWorker);
  yield takeEvery(REMOVE_FILTER_REQUEST, removeFilterWorker);
  yield takeEvery(IMPORT_CONTACTS_REQUEST, importContactsWorker);
  yield takeEvery(DELETE_CONTACTS_REQUEST, deleteContactsWorker);

  //IMPORTS LIST
  yield takeEvery(IMPORT_CONTACTS_LIST_REQUEST, importContactsListWorker);
  yield takeEvery(PARSE_EXCEL_REQUEST, parseExcelWorker);
  yield takeEvery(
      DELETE_IMPORTED_LIST_COLUMN_REQUEST,
      deleteImportedListColumnWorker
  );
  yield takeEvery(
      EDIT_IMPORTED_LIST_COLUMN_REQUEST,
      editImportedListColumnWorker
  );
}
