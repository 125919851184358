import React, {useEffect, useState} from "react";
import SelectInputsPanel from "./SelectInputsPanel";
import FieldOrderPanel from "./FieldOrderPanel";
import ColorPickers from "./ColorPickers";
import EditLandingPageSettingsWrapper from "./EditLandingPageSettingsWrapper";
import {defaultInputs, Draggable, Input, Inputs} from "./types";
import type {EmailInput} from "../../../../../../containers/Connect/types";
import "assets/css/design/edit-landing-page-settings.scss";

const findSelectedInOrder = (inputs: Inputs): Input[] => {
  return Object.values(inputs)
  .filter((input: Input) => input?.selected)
  .sort((i1: Input, i2: Input) => i1.index - i2.index);
}

const mapToDraggableItems = (inputs: Inputs): Draggable[] => {
  return findSelectedInOrder(inputs)
  .map((i: Input): Draggable => {
    return {
      id: i.index,
      key: i.key,
      text: i.text
    }
  });
}

const mapToLandingPageSettings = (inputs: Inputs): EmailInput[] => {
  return findSelectedInOrder(inputs)
  .map((i: Input): Draggable => {
    return {
      key: i.key,
      index: i.index,
      text: i.text
    }
  });
}

const mapSettingsToInputs = (emailInputs: EmailInput[]): Inputs => {
  let inputs: Inputs = {};
  if (emailInputs) {
    emailInputs
    .forEach((i: EmailInput) => {
      inputs = {
        ...inputs,
        ...{
          [i.key]: {
            key: i.key,
            index: i.index,
            selected: true,
            text: i.text
          }
        }
      };
    });
    Object.values(defaultInputs)
    .filter((input: Input) => !inputs[input.key])
    .forEach((i: Input) => {
      inputs = {
        ...inputs,
        ...{
          [i.key]: {
            key: i.key,
            selected: false,
            text: i.text
          }
        }
      };
    })
  } else {
    inputs = defaultInputs;
  }
  return inputs;
}

const EditLandingPageSettings = (props: any) => {
  const {settings, updateSettings} = props;

  const [inputs: Inputs, setInputs] = useState({});
  const [draggableItems: Draggable[], setDraggableItems] = useState([]);

  const updateInputs = (newInputs: Inputs) => {
    setInputs(newInputs);
    setDraggableItems(mapToDraggableItems(newInputs));
    updateSettings('emailInputs', mapToLandingPageSettings(newInputs));
  };

  const setInput = (key: string) => {
    const input: Input = inputs[key];

    // email is always required
    input.selected = key === 'email' || !input.selected;

    if (input.selected) {
      input.index = findSelectedInOrder(inputs).length - 1;
    } else {
      delete input.index;
    }

    updateInputs({...inputs, ...input});
  };

  const setInputText = (key: string, text: string) => {
    const input: Input = inputs[key];
    input.text = text;
    updateInputs({...inputs, ...input})
  };

  useEffect(() => {
    updateInputs(mapSettingsToInputs(settings?.emailInputs));
  }, []);

  return (
      <EditLandingPageSettingsWrapper>
        <label className="title">
          Select the options you would like on the welcome form
        </label>

        <SelectInputsPanel
            inputs={inputs}
            setInput={setInput}
            setInputText={setInputText}
        />

        <div className="edit-landing-page-settings-bottom">
          <label className="title">Field Order</label>

          <FieldOrderPanel
              draggableItems={draggableItems}
              findSelectedInOrder={findSelectedInOrder}
              inputs={inputs}
              updateInputs={updateInputs}
          />

          <ColorPickers
              settings={settings}
              updateSettings={updateSettings}
          />
        </div>
      </EditLandingPageSettingsWrapper>
  );
};

export default EditLandingPageSettings;
