import React from 'react';
import {Grid} from "@mui/material";
import EmailSenderParam from "../Params/EmailSenderParam";
import TemplateParam from "../Params/TemplateParam";
import {Param, ParamType} from "../../../types";

type Props = {
  organisationId: string
  params: Param[]
  setParam: React.Dispatch<React.SetStateAction<Param>>
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const EmailAction = ({params, organisationId, setParam, setDisabled}: Props) => {
  const sender: Param = params.find((p: Param) => p.paramType === ParamType.SENDER);
  const templateId: Param = params.find((p: Param) => p.paramType === ParamType.TEMPLATE_ID);
  return (
      <Grid container direction='column'>
        <EmailSenderParam
            organisationId={organisationId}
            sender={sender?.paramValue}
            setParam={setParam}
            setDisabled={setDisabled}
        />
        <TemplateParam
            organisationId={organisationId}
            templateId={templateId?.paramValue}
            setParam={setParam}
            setDisabled={setDisabled}
        />
      </Grid>
  )
}

export default EmailAction;
