import {call, put, takeLatest} from "redux-saga/effects";
import uuid from "react-uuid";

import {defaultLandingPage, defaultPoster} from "../consts";

import {
  apiActivateLandingPage,
  apiCreateLandingPage,
  apiCreateMedia,
  apiCreatePoster,
  apiDeleteLandingPage,
  apiGetLandingPage,
  apiGetLandingPages,
  apiGetMediaSignature,
  apiSaveLandingPage,
  apiUploadMediaToHost,
} from "../api";

import history from "utils/history";

import actions from "../actions";
import {LandingPage} from "../types";

const {
  START_LANDING_PAGE_REQUEST,
  UPDATE_LANDING_PAGE_REQUEST,
  GET_LANDING_PAGE_REQUEST,
  GET_LANDING_PAGES_REQUEST,
  DELETE_LANDING_PAGE_REQUEST,
  ACTIVATE_LANDING_PAGE_REQUEST,
  UPDATE_LANDING_PAGE_STEP_REQUEST,
  SAVE_LANDING_PAGE_REQUEST,
  UPLOAD_MEDIA_TO_HOST_REQUEST,
  startLandingPageFailure,
  startLandingPageSuccess,
  // updateLandingPageRequest,
  updateLandingPageFailure,
  updateLandingPageSuccess,
  updateLandingPageStepFailure,
  updateLandingPageStepSuccess,
  getLandingPageFailure,
  getLandingPageSuccess,
  getLandingPagesFailure,
  getLandingPagesSuccess,
  getLandingPagesRequest,
  deleteLandingPageFailure,
  deleteLandingPageSuccess,
  activateLandingPageFailure,
  // activateLandingPageSuccess,
  saveLandingPageFailure,
  // saveLandingPageSuccess,
  uploadMediaToHostFailure,
  uploadMediaToHostSuccess,
} = actions;

function* startLandingPageWorker(action) {
  try {
    const {type, name, organisationId} = action.payload;

    let externalId;
    let mediaObject = {
      name: name,
      description: "",
    };

    if (type === "poster") {
      let posterObj = defaultPoster;
      posterObj.organisationId = organisationId;
      posterObj.name = name;
      const posterApiResult = yield call(apiCreatePoster, posterObj);
      externalId = posterApiResult.data.externalId;

      yield put(startLandingPageSuccess(posterApiResult.data));
    } else {
      if (type === "track") {
        // defaultLandingPageObj.boxBorderVisibility = "HIDE";
        // defaultLandingPageObj.menuOpacity = 0;
        // defaultLandingPageObj.buttonColor = "#FECC33";
        // defaultLandingPageObj.goButtonColor = "#FF9A22";
        // defaultLandingPageObj.linkColor = "#000000";
        // defaultLandingPageObj.textcolor = "#e85558";
      } else {
        // defaultLandingPageObj.showTermsAndConditions = true;
      }

      let landingPageRequest: LandingPage = {
        organisationId: organisationId,
        name: name,
        active: false,
        track: type === "track",
        settings: defaultLandingPage
      };

      const landingPageApiResult = yield call(
          apiCreateLandingPage,
          landingPageRequest
      );
      externalId = landingPageApiResult.data.externalId;
      landingPageRequest.externalId = externalId;

      yield put(startLandingPageSuccess(
          landingPageApiResult.data, landingPageRequest));
    }

    mediaObject.externalId = externalId;
    yield call(apiCreateMedia, mediaObject);

    const pathname = history.location.pathname.includes(type)
        ? `${history.location.pathname}/${externalId}`
        : `${history.location.pathname}/${type}/${externalId}`;

    yield history.push({
      pathname,
      state: {create: true},
    });
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(startLandingPageFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* updateLandingPageWorker(action) {
  try {
    const {landingPageField, landingPageData} = action.payload;

    yield put(updateLandingPageSuccess(landingPageField, landingPageData));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(updateLandingPageFailure(JSON.stringify(errorMessage)));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* updateLandingPageStepWorker(action) {
  try {
    yield put(updateLandingPageStepSuccess(action.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(updateLandingPageStepFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getLandingPageWorker(action) {
  try {
    const apiResult = yield call(apiGetLandingPage, action.payload);

    yield put(getLandingPageSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getLandingPageFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getLandingPagesWorker(action) {
  try {
    const apiResult = yield call(apiGetLandingPages, action.payload);

    yield put(getLandingPagesSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getLandingPagesFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* deleteLandingPageWorker(action) {
  try {
    const apiResult = yield call(apiDeleteLandingPage, action.payload);

    yield put(deleteLandingPageSuccess(action.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(deleteLandingPageFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* activateLandingPageWorker(action) {
  try {
    const apiResult = yield call(apiActivateLandingPage, action.payload);

    yield put(getLandingPagesRequest(
        action.payload.organisationId, action.payload.type));

  } catch (error) {
    const errorMessage = yield error.message;
    yield put(activateLandingPageFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* saveLandingPageWorker(action) {
  try {

    const apiResult = yield call(apiSaveLandingPage, action.payload);

    history.push("/connect/design/wifi");

  } catch (error) {
    const errorMessage = yield error.message;
    yield put(saveLandingPageFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* uploadMediaToHostWorker(action) {
  try {
    if (!action?.payload?.landingPageId) {

      yield put(uploadMediaToHostSuccess(null));

    } else {
      const {event, landingPageId, mediaType} = action.payload;

      const trackingKey = uuid();

      const getSignatureApiResult = yield call(apiGetMediaSignature, {
        landingPageId,
        mediaType,
        trackingKey,
      });

      const {apiKey, cloudName, context, signature, timestamp, uploadOptions} =
          getSignatureApiResult.data;

      let uploadMediaFormData = new FormData();

      const file = event?.name ? event : event.dataTransfer.files[0].name;
      uploadMediaFormData.append("file", file);

      uploadMediaFormData.append("api_key", apiKey);
      uploadMediaFormData.append("type", "upload");
      uploadMediaFormData.append("folder", uploadOptions.folder);
      uploadMediaFormData.append("context", context);
      uploadMediaFormData.append("timestamp", timestamp.toString());
      uploadMediaFormData.append("signature", signature);

      const result = yield call(
          apiUploadMediaToHost,
          cloudName,
          uploadMediaFormData
      );

      yield put(uploadMediaToHostSuccess(result.data));
    }

  } catch (error) {
    const errorMessage = yield error.message;
    yield put(uploadMediaToHostFailure(JSON.stringify(errorMessage, null, 2)));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

export default function* watcher() {
  yield takeLatest(START_LANDING_PAGE_REQUEST, startLandingPageWorker);
  yield takeLatest(UPDATE_LANDING_PAGE_REQUEST, updateLandingPageWorker);
  yield takeLatest(ACTIVATE_LANDING_PAGE_REQUEST, activateLandingPageWorker);
  yield takeLatest(GET_LANDING_PAGE_REQUEST, getLandingPageWorker);
  yield takeLatest(GET_LANDING_PAGES_REQUEST, getLandingPagesWorker);
  yield takeLatest(DELETE_LANDING_PAGE_REQUEST, deleteLandingPageWorker);
  yield takeLatest(
      UPDATE_LANDING_PAGE_STEP_REQUEST,
      updateLandingPageStepWorker
  );
  yield takeLatest(SAVE_LANDING_PAGE_REQUEST, saveLandingPageWorker);
  yield takeLatest(UPLOAD_MEDIA_TO_HOST_REQUEST, uploadMediaToHostWorker);
}
