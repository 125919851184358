import React from "react";
import styled from "@emotion/styled";

import Iframe from "components/Iframe";

import {Gear} from "phosphor-react";

const PreviewWrapper = styled.div`
  grid-row: 1/2;
  grid-column: 10/13;

  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  padding: 25px 20px;

  cursor: pointer;
  overflow-y: scroll;
`;

const PreviewBody = styled.div`
  background-color: grey;
  height: 100%;
  margin-top: 40px;
  border-radius: 4px;
  overflow: hidden;

  height: 180px;

  position: relative;
  width: 320px;
  height: 192px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  transform: translate(-25px, 0);

  iframe {
    /* position: absolute; */
    cursor: pointer;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 1280px;
    height: 786px;
    border: 0;
    transform: scale(0.25) translateX(-90px);
    transform-origin: 0 0;
  }
`;

const PreviewTopPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .preview-title {
    color: black;
    font-size: 14px;
  }
`;

const EditMail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  label {
    font-size: 14px;
    margin-right: 5px;
    cursor: pointer;
    color: #ea5454;
  }
`;

const Preview = (props) => {
  const {currentTemplate, updateCurrentStepRequest} = props;

  return (
      <PreviewWrapper>
        <PreviewTopPanel>
          <label className="preview-title">Preview</label>
          <EditMail onClick={() => updateCurrentStepRequest(1)}>
            <label>Edit Email</label>
            <Gear color={"#EA5454"} size={18}/>
          </EditMail>
        </PreviewTopPanel>

        <PreviewBody>
          {currentTemplate?.unlayerDesignHtml && (
              <Iframe content={currentTemplate.unlayerDesignHtml}/>
          )}
        </PreviewBody>
      </PreviewWrapper>
  );
};

export default Preview;
