import React from "react";
import UnaroIcon from "../design/unaroIcon";
import PosterPreviewWrapper from "./PosterPreviewWrapper";

const PosterPreview = (props) => {
  const {poster, posterLogo} = props;

  return (
      <PosterPreviewWrapper className="poster-preview">
        <div className="poster-preview-content">
          <div
              className="poster-preview-top"
              style={{backgroundColor: poster.backgroundColor}}
          >
            <label className="powered-by">Powered by</label>
            <UnaroIcon height="20"/>
            <img alt="" src={require("assets/images/wifi-icon.svg").default}/>
            <label className="join">Join our free public</label>
            <label className="wifi">Wi-Fi</label>
          </div>

          <div className="container">
            <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
              <path
                  d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z"
                  fill={poster.backgroundColor}
              />
            </svg>
          </div>

          <div className="container-2">
            <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
              <path
                  d="M0,80 C178,190 300,0 500,105 L500,00 L0,0 Z"
                  fill={poster.accentColor}
              />
            </svg>
          </div>

          <div className="poster-preview-bottom">
            <div className="message-container">
              <label>{poster.message}</label>
            </div>
            <div className="logo-container">
              <img alt="" src={posterLogo?.url}/>
            </div>
          </div>
        </div>
      </PosterPreviewWrapper>
  );
};

export default PosterPreview;
