import React, {useContext, useEffect, useState} from "react";
import api from "utils/api";
import "assets/css/quick-send.scss";
import {VenuesContext} from "context/venueContext";
import SendMessage from "components/sms/campaign/create/sendMessage";
import PreviewPhone from "./PreviewPhone";
import Form from "./Form";
import {ampli} from "containers/Amplitude/ampli";
import {useHistory} from "react-router-dom";
import {
  Backdrop,
  Button,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography
} from "@mui/material";
// import group41Png from "../../../assets/images/Group41.png";
import makeStyles from "@mui/styles/makeStyles";
import styled from "@emotion/styled";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import PageTitle from "../../../components/PageTitle";
import NoSmsLicense from "../NoSmsLicense";
import {from} from "rxjs";

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
// @ts-ignore
    backgroundColor: theme.palette.background.paper,
    borderRadius: '25px',
// @ts-ignore
    boxShadow: theme.shadows[5],
// @ts-ignore
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
}));

const ConfirmationPlaceholder = styled.div`
  position: relative;
  width: 565px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const TopPanelWrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 25px 0px 25px;
`;

const WarningWrapper = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
  top: 40px;
  margin-top: 20px;
`;

const TypographyText = styled.div`
  height: 64px;
  font-family: 'Trebuchet MS', sans-serif ' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const HeaderWarningAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(255, 153, 51, 0.12);
  border-radius: 50%;
  display: inline-block;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-left: 15%;
  gap: 24px;
  color: #ff9933;
  width: 120px;
  height: 42px;
  margin-top: 100px;
`;

const QuickSend = (props) => {
  const {activeLicenses, campaign} = props;

  const history = useHistory();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user"));
  const organisationId = user.organisations[0].externalId;
// @ts-ignore
  const selectedCustomers =
      history.location.state !== undefined
          // @ts-ignore
          ? history.location.state?.selectedCustomers
          : "";

  console.log("DUDE")
  console.log(selectedCustomers)

  const currentProps =
// @ts-ignore
      history.location?.currentProps !== undefined
          // @ts-ignore
          ? history.location?.currentProps
          : "";
  const {selectedAudience, currentListType} = currentProps;
  const {id, name, color} = selectedAudience || "";

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [fromName, setFromName] = useState("");
  const [message, setMessage] = useState("");
  const [audienceTags, setAudienceTags] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [templates, setTemplates] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);

// @ts-ignore
  const {selected} = useContext(VenuesContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   console.log("selectedContacts");
  //   console.log(selectedContacts)
  // }, [selectedContacts]);

  useEffect(() => {
    if (selected) {
      sendTracking();

      from(api.get(`/sms/organisation/${selected.externalId}/template`))
      .subscribe({
        next: ({data}) => {
          setTemplates(data);
        },
        error: (err) => {
          console.error("Error getting templates");
          console.error(err);
        }
      });

      //TODO Audience query broken and what does it do??
      //
      // from(api.get(
      //     `/organisations/${selected.externalId}/contacts?type=&page=0&size=1000&requirePhone=true`))
      // .subscribe({
      //   next: ({data}) => {
      //
      //     console.log("AUDIENCES")
      //     console.log(data)
      //
      //
      //     setAudiences(data.content);
      //   },
      //   error: (err) => {
      //     console.error("Error getting audiences");
      //     console.error(err);
      //   }
      // });
    }
  }, [selected]);

  const changeSelectedTemplate = (option) => {
    setSelectedTemplate(option);
    setMessage(option.messageBody);
  };

  const sendMessage = () => {
    if (!validateFromName()) {
      return;
    }

    const recipients = selectedContacts
        ? selectedContacts
        .filter(c => c.phones && c.phones.length > 0)
        .flatMap(c => c.phones)
        : [];

    // console.log("selectedContacts");
    // console.log(selectedContacts)
    // console.log(recipients)


    if (recipients) {
      const sender = {
        name: fromName,
        organisationExternalId: selected.externalId,
      };

      from(api.post("/sms/sender", sender))
      .subscribe({
        next: ({data}) => {
          const senderId = data?.externalId;
          if (senderId) {
            const request = {
              ...campaign,
              name: campaignName,
              organisationExternalId: organisationId,
              messageBody: message,
              recipients: recipients,
              senderExternalId: senderId
            };

            from(api.post("/sms/send-sms", request))
            .subscribe({
              next: ({data}) => {
                //console.log("Send SMS successful, response:")
                //console.log(data);
                handleClose();
              },
              error: (err) => {
                console.error("Error getting sender");
                console.error(err);
              }
            });
          }
        },
        error: (err) => {
          console.error("Error getting sender");
          console.error(err);
        }
      });
    }
  }

  const validateFromName = () => {
    const fromNameRegex = /^([a-zA-Z0-9_-]){4,11}$/;
    if (fromNameRegex.test(fromName)) {
      setFormErrors([
        ...formErrors,
        "From must be a single word and be between 4-11 characters.",
      ]);
      return true;
    }
  };

  const onChangeFromName = (name) => {
    setErrorMessage("");
    setFromName(name);
  };

  // TODO: WHAT IS THE REQUIREMENTS TO ENABLE SMS SEND
  // const isSendDisabled = !campaignName || !selectedAudience.length || !fromName;
  const isSendDisabled = !campaignName || !fromName;

  const sendTracking = () => {
    ampli.unaroPageVisit("page visit: SMS -> Quick Send");
  }

  return (
      <div className="quick-send">
        {/*<TopPanel isSendDisabled={isSendDisabled} sendMessage={sendMessage}/>*/}
        <TopPanelWrapper>
          <PageTitle title={"SMS - Quick Send"}/>
          <Button
              size={"small"}
              onClick={handleOpen}
              endIcon={<SendRoundedIcon
                  sx={{fontSize: '80px', fontWeight: '400'}}/>}
              sx={{
                color: "#FFFFF",
                padding: 1,
                zIndex: 1,
                marginLeft: '300px',
                // marginRight: 40,
                borderRadius: 16,
                width: '10%',
                backgroundColor: "#FF9933",
                "&:hover": {
                  backgroundColor: "#FF9933",
                },
                "&:focus": {
                  backgroundColor: "#FF9933",
                }
              }}
              variant="contained"
              disabled={isSendDisabled}
          >
            Send Now
          </Button>
        </TopPanelWrapper>

        {/*NO SMS LICENSE*/}
        {(!activeLicenses.includes("SMS")) && (
            <NoSmsLicense {...props}/>
        )}

        {/*WITH SMS LICENSE*/}
        {(activeLicenses.includes("SMS")) && (
            <>
              <div className="quick-send__container">
                <Form
                    organisationId={organisationId}
                    setSelectedAudience={() => {
                    }}
                    audiences={audiences}
                    selectedAudience={selectedAudience}
                    message={message}
                    campaignName={campaignName}
                    errorMessage={errorMessage}
                    setCampaignName={setCampaignName}
                    templates={templates}
                    selectedTemplate={selectedTemplate}
                    changeSelectedTemplate={changeSelectedTemplate}
                    setMessage={setMessage}
                    audienceTags={audienceTags}
                    // setTrackUrl={setTrackUrl}
                    // addTrackUrls={addTrackUrls}
                    setAudienceTags={setAudienceTags}
                    fromName={fromName}
                    onChangeFromName={onChangeFromName}
                    // trackUrl={trackUrl}
                    selectedCustomers={selectedCustomers}
                    setSelectedContacts={setSelectedContacts}
                />
                <PreviewPhone message={message}/>
              </div>

              {/*WITH/WITHOUT AUDIENCE*/}
              <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
              >
                <Fade in={open}>
                  <div className={classes.paper}>
                    <ConfirmationPlaceholder>
                      {/*<img*/}
                      {/*    alt="group"*/}
                      {/*    onClick={handleClose}*/}
                      {/*    className={"closeImg"}*/}
                      {/*    src={group41Png}*/}
                      {/*/>*/}
                      <WarningWrapper>
                        <HeaderWarningAmber>
                          <WarningAmberRoundedIcon
                              sx={{
                                fontSize: 45,
                                padding: 3,
                                color: "#FF9933",
                              }}
                          />
                        </HeaderWarningAmber>
                      </WarningWrapper>
                      {currentListType !== "audiences" && (
                          <>
                            <TypographyText>
                              Are you sure you want to send SMS to:
                              <br/>
                              ({selectedContacts && selectedContacts.length}) {" "}
                              contacts?
                            </TypographyText>
                          </>
                      )}

                      {/*{currentListType === "audiences" && selectedAudience*/}
                      {/*    !== ""*/}
                      {/*    && (*/}
                      {/*        <>*/}
                      {/*          <TypographyText>*/}
                      {/*            Are you sure you want to send email to:*/}
                      {/*            <br/>*/}
                      {/*            /!*({selectedCustomers*!/*/}
                      {/*            /!*  && selectedCustomers.length}) {" "}*!/*/}
                      {/*            {"fuck"}*/}
                      {/*              /!*{selectedContacts && selectedContacts.length ? selectedContacts.length : 0}*!/*/}
                      {/*              {selectedContacts && selectedContacts.length ?`${selectedContacts.length}` : "0"}*/}
                      {/*            {")"}*/}
                      {/*            {" "} contacts?*/}
                      {/*            <List sx={{marginLeft: 18, marginTop: 0}}>*/}
                      {/*              <ListItem disablePadding>*/}
                      {/*                <ListItemButton>*/}
                      {/*                  <ListItemIcon>*/}
                      {/*                    {color !== null ? `${color}` : ''}*/}
                      {/*                  </ListItemIcon>*/}
                      {/*                  <ListItemText>*/}
                      {/*                    <Typography sx={{*/}
                      {/*                      fontFamily: "'Roboto',sans-serif !important",*/}
                      {/*                      fontSize: "16px",*/}
                      {/*                      fontWeight: 400*/}
                      {/*                    }}>*/}
                      {/*                      {name !== null*/}
                      {/*                          ? `${name}` : ''}*/}
                      {/*                    </Typography>*/}
                      {/*                  </ListItemText>*/}
                      {/*                </ListItemButton>*/}
                      {/*              </ListItem>*/}
                      {/*            </List>*/}
                      {/*          </TypographyText>*/}
                      {/*        </>*/}
                      {/*    )}*/}
                      <ButtonSection>
                        <Button
                            onClick={handleClose}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              zIndex: 1,
                              marginTop: 25,
                              marginLeft: '32ch',
                              marginRight: 4
                            }}
                            variant="text"
                        >
                          CANCEL
                        </Button>
                        <Button
                            onClick={() => sendMessage()}
                            endIcon={<CheckCircleOutlineIcon
                                sx={{fontSize: '80px', fontWeight: '400'}}/>}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              zIndex: 1,
                              marginTop: 25,
                              marginRight: 4
                            }}
                            variant="text"
                        >
                          CONFIRM
                        </Button>
                      </ButtonSection>
                    </ConfirmationPlaceholder>
                  </div>
                </Fade>
              </Modal>
            </>
        )}
      </div>
  );
};

export default QuickSend;
