import React, {useEffect} from "react";
import styled from "@emotion/styled";

import BackNextButtonsWrapper from "./BackNextButtonsWrapper";

import BackButton from "./BackButton";

const NextButtonWrapper = styled.button`
  height: 30px;
  width: 80px;
  background: #ea5454;
  border-radius: 45px;
  outline: none;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  opacity: ${(props) => (props.canGoNext ? 1 : 0.65)};
  pointer-events: ${(props) => (props.canGoNext ? "auto" : "none")};
`;

const BackNextButtons = (props) => {
  const {
    currentStep,
    currentCampaignContacts,
    currentCampaign,
    updateCurrentStepRequest,
    handleSaveDesignAndNext,
    currentUpdatedTemplate,
  } = props;

  const [canGoNext, setCanGoNext] = React.useState(false);

  React.useEffect(() => {
    switch (currentStep) {
      case 0:
        if (currentCampaign?.name && currentCampaign.name !== "") {
          setCanGoNext(true);
          break;
        } else {
          setCanGoNext(false);
          break;
        }
      case 1:
        if (currentUpdatedTemplate) {
          setCanGoNext(true);
          break;
        } else {
          setCanGoNext(false);
          break;
        }
      case 2:
        if (currentCampaignContacts && currentCampaignContacts.length > 0) {
          setCanGoNext(true);
          break;
        } else {
          setCanGoNext(false);
          break;
        }

      case 3:
        if (currentCampaign?.isCampaignSent) {
          setCanGoNext(true);

          break;
        } else {
          setCanGoNext(false);

          break;
        }
      default:
        setCanGoNext(false);
    }
  }, [currentStep, currentCampaign, currentUpdatedTemplate, currentCampaignContacts]);

  return (
      <BackNextButtonsWrapper>
        <BackButton {...props} />

        {currentStep < 3 ? (
            <NextButtonWrapper
                canGoNext={canGoNext}
                onClick={
                  currentStep === 1
                      ? () => {
                        handleSaveDesignAndNext();
                        updateCurrentStepRequest(currentStep + 1);
                      }
                      : () => updateCurrentStepRequest(currentStep + 1)
                }
            >
              Next
            </NextButtonWrapper>
        ) : (
            <>
            </>
        )}
      </BackNextButtonsWrapper>
  );
};

export default BackNextButtons;
