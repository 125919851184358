import React from "react";
import HttpsRedirect from "react-https-redirect";
import AppWrapper from "components/AppWrapper";
import {ThemeProvider} from "@mui/material/styles";
import theme from "./components/UI/Theme";

import "./App.scss";

import AppRouter from "components/AppRouter";

import {ampli, DefaultOptions} from "containers/Amplitude/ampli";
import {loggingMiddleware} from "containers/Amplitude/middleware";

ampli.load({
  client: {
    apiKey: `${process.env.REACT_APP_AMPLITUDE_API_KEY}`,
    options: {...DefaultOptions, logLevel: "INFO"},
  },
});

ampli.addEventMiddleware(loggingMiddleware);

if (
    JSON.parse(localStorage.getItem("user")) &&
    JSON.parse(localStorage.getItem("user")).email
) {
  const userId = JSON.parse(localStorage.getItem("user")).email;
  ampli.identify(userId, {});
}

const App = () => {
  return (
      <ThemeProvider theme={theme}>
        <AppWrapper>
          <HttpsRedirect>
            <AppRouter/>
          </HttpsRedirect>
        </AppWrapper>
      </ThemeProvider>
  );
};

export default App;
