import React, { useState } from "react";
import api from "utils/api";
import { ReactComponent as CloseIcon } from "assets/images/delete.svg";

import useUserOrganisation from "utils/hooks/useUserOrganisation";

import "assets/css/send/create/save-template-popup.scss";

const SaveTemplatePopup = (props) => {
  const {
    close,
    setShowSaveTemplate,
    setShowDone,
    currentCampaign,
    organisationId,
  } = props;

  const [name, setName] = useState();

  const userOrganisation = useUserOrganisation();

  //   const saveTemplate = () => {
  //     save(name);
  //   };

  const onClick = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  const saveTemplate = async () => {
    const obj = {
      name: name,
      messageBody: currentCampaign?.template?.unlayerDesignHtml,
      unlayerDesign: currentCampaign?.template?.unlayerDesign,
      externalId: userOrganisation?.externalId,
      organisationExternalId: organisationId,
      subject: "",
    };

    await api
      .post("/email/template", obj)
      .then(function (response) {
        // handle success
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  return (
    <div className="save-template-popup" onClick={onClick}>
      <div className="save-template-popup-content">
        <CloseIcon className="close-btn" onClick={close} />
        <label className="title">Name your Template</label>
        <input
          placeholder="Template Name"
          autoFocus
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
        />
        <div
          className="save-btn"
          onClick={() => {
            saveTemplate();
            setShowSaveTemplate(false);
            setShowDone(true);
          }}
        >
          <label>Save</label>
          <img alt="done" src={require("assets/images/done.svg").default} />
        </div>
      </div>
    </div>
  );
};

export default SaveTemplatePopup;
