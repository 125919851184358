import React, {useEffect, useRef, useState} from "react";
import {compose} from "recompose";
import SendNewEmailContainer from "containers/Send/SendNewEmail";
import SendNewEmailWrapper from "./SendNewEmailWrapper";
import StepsNavBar from "./components/StepsNavBar";
import SendCreate from "./steps/SendCreate";
import SendAudience from "./steps/SendAudience";
import SendPreview from "./steps/SendPreview";
import SendDesign from "./steps/SendDesign";
import SendDone from "./steps/SendDone";
import sendNewEmailConfig from "./config";
import api from "utils/api";
import {useHistory} from "react-router-dom";
import NoSendEmailLicense from "../NoSendEmailLicense";

const SendNewEmail = (props) => {
  const {
    updateCurrentStepRequest,
    currentStep,
    selectedEntity,
    updateCurrentCampaignRequest,
    currentCampaign,
    activeLicenses,
    isSuperAdmin
  } = props;

  const history = useHistory();

  const selectedCustomers =
      history.location.state !== undefined
          ? history.location.state.selectedCustomers
          : "";

  const organisationId = selectedEntity !== undefined
      ? selectedEntity.externalId : "";

  const emailEditorRef = useRef(null);

  const [steps, setSteps] = useState(sendNewEmailConfig);

  const [campaign, setCampaign] = useState({});
  const [sendOn, setSendOn] = useState();
  const [currentUpdatedTemplate, setCurrentUpdatedTemplate] = useState(currentCampaign?.template);
  const [currentCampaignContacts, setCurrentCampaignContacts] = useState(null);

  useEffect(() => {
    updateCurrentCampaignRequest(
        organisationId,
        "contacts",
        currentCampaignContacts
    );
  }, [currentCampaignContacts, organisationId]);

  const createTemplate = (name, template) => {
    setCampaign({
      ...campaign,
      name: name,
      subject: template ? template.subject : null,
      htmlText: template ? template.messageBody : null,
      design: template ? JSON.parse(template.unlayerDesign) : null,
    });
    updateCurrentStepRequest(steps.find((s) => s.id === currentStep + 1));
  };

  const handleSaveDesignAndNext = () => {
    updateCurrentCampaignRequest(
        organisationId,
        "template",
        currentUpdatedTemplate
    );
  };

  const goToNextStep = () => {
    // updateCurrentStepRequest(steps.find((s) => s.id === currentStep + 1));
    updateCurrentStepRequest(currentStep + 1);
  };

  const sendTest = async (email, subject) => {
    await api.post("/email", {
      ...campaign,
      subject: subject,
      emailAddresses: [email],
      selectedCustomers: [email],
    });
  };

  return (
      <SendNewEmailWrapper>
        {/*NO EMAIL LICENSE*/}
        {(!activeLicenses.includes("SEND") && !isSuperAdmin) && (
            <NoSendEmailLicense {...props}/>
        )}
        {/*WITH EMAIL LICENSE*/}
        {(activeLicenses.includes("SEND") || isSuperAdmin) && (
            <>
              <StepsNavBar
                  {...props}
                  handleSaveDesignAndNext={handleSaveDesignAndNext}
                  currentUpdatedTemplate={currentUpdatedTemplate}
                  currentStep={currentStep}
                  steps={steps}
                  emailEditorRef={emailEditorRef}
                  setCampaign={setCampaign}
                  campaign={campaign}
                  currentCampaignContacts={currentCampaignContacts}
              />

              <div className="cec-content">
                {currentStep === 0 ? (
                    <SendCreate
                        {...props}
                        createTemplate={createTemplate}
                        selected={selectedEntity}
                        organisationId={selectedEntity?.externalId}
                        goToNextStep={goToNextStep}
                    />
                ) : currentStep === 1 ? (
                    <SendDesign
                        initialTemplateDesign={currentCampaign?.template}
                        setCurrentUpdatedTemplate={setCurrentUpdatedTemplate}
                    />
                ) : currentStep === 2 ? (
                    <SendAudience
                        {...props}
                        selected={selectedEntity}
                        organisationId={selectedEntity?.externalId}
                        selectedCustomers={selectedCustomers}
                        currentCampaignContacts={currentCampaignContacts}
                        setCurrentCampaignContacts={setCurrentCampaignContacts}
                    />
                ) : currentStep === 3 ? (
                    <SendPreview
                        {...props}
                        sendTest={sendTest}
                        campaign={campaign}
                        organisationId={selectedEntity?.externalId}
                        currentTemplate={currentUpdatedTemplate}
                        selectedCustomers={selectedCustomers}
                        activeLicenses={activeLicenses}
                    />
                ) : (
                    <SendDone
                        {...props}
                        sendOn={sendOn}
                        organisationId={selectedEntity?.externalId}
                    />
                )}
              </div>
            </>
        )}
      </SendNewEmailWrapper>
  );
};

export default compose(SendNewEmailContainer)(SendNewEmail);
