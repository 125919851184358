import React from "react";
import styled from "@emotion/styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import InputButton from "./InputButton";

const DateInputWrapper = styled.div`
  .react-datepicker {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.17);
  }
`;

const DateInput = (props) => {
  const { change, ScheduleFieldValue } = props;

  const [startDate, setStartDate] = React.useState(null);

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    startDate &&
      change("campaignSchedule", { ...ScheduleFieldValue, date: startDate });
  }, [startDate]);

  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <DateInputWrapper>
      <InputButton
        className="schedule-time-input-button"
        onClick={handleClick}
        currentDate={
          startDate
            ? startDate.toLocaleString("en", { dateStyle: "short" })
            : "MM/DD/YY"
        }
      ></InputButton>

      {isOpen && (
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          minDate={new Date()}
          inline
        />
      )}
    </DateInputWrapper>
  );
};

export default DateInput;
