import { createSelector } from "reselect";
import _get from "lodash/fp/get";

import { miscSliceSelector } from "store/selectors";

export const selectIsLoading = createSelector(
  miscSliceSelector,
  _get("loading")
);

export const selectErrorMessage = createSelector(
  miscSliceSelector,
  _get("errorMessage")
);

export const selectIsNavBarLocked = createSelector(
  miscSliceSelector,
  _get("isNavBarLocked")
);
