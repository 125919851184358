import React, { useState } from "react";

import SaveTemplatePopup from "./SaveTemplatePopup";
import TemplateCreatedPopup from "./TemplateCreatedPopup";
// import DonePopup from "components/send/create/donePopup";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "assets/css/send/create/done.scss";

const SendDone = (props) => {
  const dispatch = useDispatch();
  const { sendOn, currentCampaign, organisationId } = props;

  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [showDone, setShowDone] = useState(false);

  const onClick = (e) => {
    dispatch({ type: "RESET_ALL_VALUES_REQUEST", payload: "/email/dashboard" });

    e.preventDefault();
    // if (e.target === e.currentTarget) {
    //   close();
    // }
  };

  //   const save = (name) => {
  //     setShowDone(true);
  //     setShowSaveTemplate(false);
  //     saveTemplate(name);
  //   };

  return (
    <div className="email-done">
      <label className="ed-title">You’re all done!</label>
      {sendOn ? (
        <div className="scheduled-campaign">
          <label className="ed-info">
            You’re campaign is scheduled to go Live on{" "}
            <span>{moment(sendOn).format("DD/MM/YY")}</span> at{" "}
            <span>{moment(sendOn).format("hh:mm")}</span>
          </label>
          <label className="ed-info">
            You can edit this campaign anytime before it goes live by going to{" "}
            <span>Manage Campaigns</span> and selecting edit on it, or press the
            button below now.
          </label>
        </div>
      ) : (
        <label className="ed-info">
          You will receive an email with the status of your campaign
        </label>
      )}
      <div className="done-actions">
        <div
          className="da-btn save-template"
          style={{ backgroundColor: "rgb(255, 102, 51)" }}
          onClick={(e) => onClick(e)}
        >
          <label>Go back to Dashboard</label>
        </div>

        <div
          className="da-btn save-template"
          onClick={() => setShowSaveTemplate(true)}
        >
          <label>Save as Template</label>
          <img alt="save template" src={require("assets/images/save-template-icon.svg").default} />
        </div>
        {sendOn && (
          <div className="da-btn">
            <label>Edit Campaign</label>
            <img alt="edit campaign" src={require("assets/images/edit-campaign-icon.svg").default} />
          </div>
        )}
      </div>
      {showSaveTemplate && (
        <SaveTemplatePopup
          close={() => setShowSaveTemplate(false)}
          setShowSaveTemplate={setShowSaveTemplate}
          setShowDone={setShowDone}
          organisationId={organisationId}
          //   save={save}
          currentCampaign={currentCampaign}
        />
      )}
      {showDone && (
        <TemplateCreatedPopup
          close={() => setShowDone(false)}
          text="Template Created"
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default SendDone;
