import React, { useState } from 'react'

import DropDownIcon from '../../icon/drop-down-icon'

import '../../assets/css/widget/title-dropdown.scss'

export default ({ color = '#000000', title, options = [], selected = '', onSelect }) => {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        if (options.length > 0)
            setOpen(!open)
    }

    const changeSelected = (option) => {
        setOpen(false)
        onSelect(option)
    }

    return (
        <div className='title-dropdown-container' tabIndex="0" onBlur={() => setOpen(false)}>
            <div className={`dropdown ${open && 'dropdown-open'}`} onClick={() => toggleOpen()} style={{ borderColor: color }} >
                <label style={{ color: color }}>{title}</label>
                <div>
                    <label style={{ color: color }}>{selected}</label>
                    <DropDownIcon className='down-icon' color={color} />
                </div>
            </div>
            {open &&
                <div className='options'>
                    {options.map(option => <label key={option} onClick={() => changeSelected(option)}>{option}</label>)}
                </div>
            }
        </div>
    )
}