import styled from "@emotion/styled";

const UnsubscribeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #eeeeee;
  align-items: center;
  user-select: none;

  .unsubscribe-card {
    width: 50%;
    background-color: white;
    min-height: 20%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 100px auto 0 auto;
    border-radius: 5px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
  }

  #logo {
    max-width: 100px;
  }

  h4 {
    font-size: 26px;
    opacity: 0.95;
  }

  p {
    padding: 0;
    margin: 0;
    font-weight: 400;
    opacity: 0.6;
    text-align: center;
  }

  button,
  input[type="submit"],
  input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 25px;
    border-radius: 25px;
    border: 1px solid #ea5454;
    padding: 5px 16px;

    > * {
      margin: 5px;
    }
  }

  button:hover {
    cursor: pointer;
  }
  label {
    color: #ea5454;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
  }
`;

export default UnsubscribeWrapper;
