import api from "utils/api";

export const apiGetOrganisationVenues = (organisationId) => {
  return api.get(
    `/organisations/${organisationId}/network?relationship=VENUE&page=0size=20`
  );
};

export const apiGetLicenses = (organisationId) => {
  return api.get(`/organisations/${organisationId}/licences`);
};

export const apiGetSummary = (organisationId) => {
  return api.get(`/organisation/${organisationId}/summary`);
};

export const apiGetCampaigns = (organisationId) => {
  return api.get(`/organisation/${organisationId}/campaign`);
};
