import React from "react";
import api from "utils/api";

import "assets/css/sms/contact/remove-popup.scss";

const DeleteTagPopup = ({
  title,
  text,
  remove,
  close,
  organisationId,
  selectedTag,
  fetchContacts,
  fetchTags,
}) => {
  // const save = async () => {
  //   await remove();
  // };

  const deleteTag = async () => {
    await api
      .delete(`/organisations/${organisationId}/tags/${selectedTag.externalId}`)
      .then(() => {
        fetchContacts();
        fetchTags();
        close();
      });
  };

  const onClick = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <div className="remove-popup" onClick={onClick}>
      <div className="remove-popup-content">
        <div className="remove-top">
          <label>{title}</label>
          <img alt="delete" src={require("assets/images/delete.svg").default} onClick={close} />
        </div>
        <div className="remove-content">
          <div className="remove-text">{text}</div>
          <div className="remove-actions">
            <button onClick={close}>Cancel</button>
            <button onClick={deleteTag}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteTagPopup;
