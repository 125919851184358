import React from "react";
import Lottie from "react-lottie";
import styled from "@emotion/styled";
import animationData from "./animationData.json";

const LoadingCirclesAnimWrapper = styled.div``;

const LoadingCirclesAnim = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <LoadingCirclesAnimWrapper>
      <Lottie
        options={defaultOptions}
        height={75}
        width={75}
        isStopped={false}
        isPaused={false}
      />
    </LoadingCirclesAnimWrapper>
  );
};

export default LoadingCirclesAnim;
