import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import { formValueSelector } from "redux-form";

import actions from "./actions";

import {
  //MISC
  selectCurrentListType,
  selectCurrentPagination,
  //CONTACTS
  selectAllContacts,
  selectExternalContactsList,
  selectCreatedContact,
  selectAreAllContactsSelected,
  selectDeletedContacts,
  selectIsDeleteContactsLoadingContacts,
  selectDeleteContactsErrorMessage,
  selectImportedContactsSuccessfully,
  selectImportContactsErrorMessage,
  selectIsDeleteContactsSuccessfully,
  //SEGMENTS
  selectAllSegments,
  selectSelectedSegment,
  selectSelectedSegmentContacts,
  selectCreatedSegment,
  selectDeletedSegmentId,
  selectAreAllSegmentContactsSelected,
  //AUDIENCES
  selectAllAudiences,
  selectSelectedAudience,
  selectSelectedAudienceContacts,
  selectCreatedAudience,
  selectAreAllAudienceContactsSelected,
  //FILTERS
  selectCurrentFilters,
  // selectCurrentFormattedFilters,
  //IMPORT CONTACTS LIST
  // selectCurrentExcel,
  // selectImportedListHeaders,
  // selectImportedListContacts,
} from "./selectors";

const formSelector = formValueSelector("ADD_FILTER_MODAL_FORM");

const {
  getAllContactsRequest,
  selectListTypeRequest,
  getSegmentsRequest,
  getSegmentContactsRequest,
  selectSegmentRequest,
  setCurrentPaginationRequest,
  toggleContactRequest,
  toggleSegmentContactRequest,
  toggleAllContactsRequest,
  toggleAllAudienceContactsRequest,
  toggleAllSegmentContactsRequest,
  importContactsRequest,
  getAudiencesRequest,
  createAudienceRequest,
  getAudienceContactsRequest,
  selectAudienceRequest,
  toggleAudienceContactRequest,
  addFilterRequest,
  removeFilterRequest,
  createSegmentRequest,
  deleteSegmentRequest,
  createContactRequest,
  deleteContactsRequest,
} = actions;

export const mapStateToProps = createStructuredSelector({
  currentListType: selectCurrentListType,
  currentPagination: selectCurrentPagination,
  allSegments: selectAllSegments,
  allContacts: selectAllContacts,
  selectedSegment: selectSelectedSegment,
  selectedSegmentContacts: selectSelectedSegmentContacts,
  createdSegment: selectCreatedSegment,
  deletedSegmentId: selectDeletedSegmentId,
  allAudiences: selectAllAudiences,
  selectedAudience: selectSelectedAudience,
  createdAudience: selectCreatedAudience,
  selectedAudienceContacts: selectSelectedAudienceContacts,
  currentFilters: selectCurrentFilters,
  // currentFormattedFilters: selectCurrentFormattedFilters,
  externalContactsList: selectExternalContactsList,
  createdContact: selectCreatedContact,
  areAllContactsSelected: selectAreAllContactsSelected,
  areAllAudienceContactsSelected: selectAreAllAudienceContactsSelected,
  areAllSegmentContactsSelected: selectAreAllSegmentContactsSelected,
  deletedContacts: selectDeletedContacts,
  isDeletingContacts: selectIsDeleteContactsLoadingContacts,
  deleteContactsErrorMessage: selectDeleteContactsErrorMessage,
  importedContactsSuccessfully: selectImportedContactsSuccessfully,
  importContactsErrorMessage:selectImportContactsErrorMessage,
  isDeleteContactsSuccessfully:selectIsDeleteContactsSuccessfully,

  modalFiltersValues: (state) => {
    formSelector(
      state,
      "filterField",
      "filterOperationValue",
      "filterOperation"
    );
  },
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllContactsRequest,
      selectListTypeRequest,
      getSegmentsRequest,
      getSegmentContactsRequest,
      selectSegmentRequest,
      setCurrentPaginationRequest,
      toggleContactRequest,
      toggleSegmentContactRequest,
      toggleAllContactsRequest,
      toggleAllAudienceContactsRequest,
      getAudiencesRequest,
      createAudienceRequest,
      getAudienceContactsRequest,
      selectAudienceRequest,
      toggleAudienceContactRequest,
      toggleAllSegmentContactsRequest,
      addFilterRequest,
      removeFilterRequest,
      createSegmentRequest,
      deleteSegmentRequest,
      createContactRequest,
      importContactsRequest,
      deleteContactsRequest,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
