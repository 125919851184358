import React from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TOASTS_TIMEOUT } from "consts/index";

import GlobalStyles from "./globalStyles";

import LoadingAnim from "components/LoadingAnim";

const StyledToastContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
  }
`;

const AppWrapper = (props) => {
  const selectIsAppLoading = createSelector(
    (state) => state,
    (state) =>
      Object.keys(state).some((key, index) => state[key].loading === true)
  );

  const isAppLoading = useSelector(selectIsAppLoading);

  const [canShow, setCanShow] = React.useState(false);

  React.useEffect(() => {
    isAppLoading &&
      setTimeout(() => {
        setCanShow(false);
      }, 1000);

    isAppLoading && setCanShow(true);
  }, [isAppLoading]);

  return (
    <>
      <StyledToastContainer
        position="bottom-left"
        newestOnTop={true}
        style={{ zIndex: 99999999 }}
        autoClose={TOASTS_TIMEOUT}
      />
      {canShow && <LoadingAnim />}
      <GlobalStyles />
      {props.children}
    </>
  );
};

export default AppWrapper;
