import React from "react";
import styled from "@emotion/styled";
import {XCircle} from "phosphor-react";
import {SearchCriteria} from "../../../../../containers/ContactsList/types";
import uuid from "react-uuid";

const FilterButtonsWrapper = styled.div`
  /* width:100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;

const FilterButtonWrapper = styled.div`
  background-color: #f2f2f2;
  padding: 4px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left: 15px;
`;

const AndMoreFilterButtonWrapper = styled.div`
  background-color: #b8bdd9;
  padding: 4px 6px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

const FilterButtonText = styled.label`
  font-family: "Roboto";
  font-size: 10px;
  color: ${(props) => (props.color ? props.color : "black")};
`;

const CloseButtonWrapper = styled.div`
  cursor: pointer;
  margin-left: 2px;
`;

// only 3 buttons are visible
const BUTTON_LIMIT = 3;

const opTextMap: Map<string, string> = new Map([
  ["LT", "less than"],
  ["GT", "more than"],
  ["EQUALS", "exactly"]
]);

const criteriaText = (criteria: SearchCriteria) => {
  const {key, operation, value} = criteria;
  const plural = value && value > 1 ? 's' : '';
  const opText = opTextMap.get(operation);
  if (key) {
    switch (key) {
      case "hasEmail":
        return 'Has email';
      case "hasPhone":
        return 'Has phone';
      case "visitCount":
        return `${opText} ${value} visit${plural}`;
      case "birthday":
        return `Birthday is within ${value} day${plural}`;
      case "gender":
        return `is ${value}`;
      case "firstVisit":
        return `first visit ${opText} ${value} day${plural} ago`;
      case "lastVisit":
        return `last visit ${opText} ${value} day${plural} ago`;
      default:
        throw new Error(`filter ${key} not supported`);
    }
  }
};

const FilterButton = (props) => {
  const {
    index,
    filter,
    isLast,
    isMore,
    isMoreDiff,
    deleteFilter,
    currentFilters
  } = props;
  return (
      <>
        <FilterButtonWrapper
            className="filter-button"
            filter={filter}
        >
          <FilterButtonText> {criteriaText(filter)}</FilterButtonText>

          <CloseButtonWrapper
              onClick={() => deleteFilter(index)}>
            <XCircle size={12} weight="bold" color={"black"}/>
          </CloseButtonWrapper>
        </FilterButtonWrapper>

        {isLast && isMore && (
            <AndMoreFilterButtonWrapper>
              <FilterButtonText
                  color={"white"}
              >{`and ${isMoreDiff} more`}</FilterButtonText>
            </AndMoreFilterButtonWrapper>
        )}
      </>
  );
}

const FilterButtons = (props) => {
  const {filters, deleteFilter} = props;
  let uniquefilters = [];
  return (
      <FilterButtonsWrapper>
        {filters.slice(0, BUTTON_LIMIT)
        .map((filter, index) => {
              const isMore = filters.length > BUTTON_LIMIT;
              const isMoreDiff = filters.length - BUTTON_LIMIT;
              const isLast = index === filters.length - 1 && index <= BUTTON_LIMIT
                  ? true : index === BUTTON_LIMIT - 1;

              if (uniquefilters.indexOf(filter.key) === -1) {
                uniquefilters.push(filter.key)
                return (
                    <FilterButton
                        key={uuid()}
                        index={index}
                        filter={filter}
                        isLast={isLast}
                        isMore={isMore}
                        isMoreDiff={isMoreDiff}
                        deleteFilter={deleteFilter}
                        currentFilters={filters}
                    />
                );
              }
            }
        )}
      </FilterButtonsWrapper>
  );
};

export default FilterButtons;
