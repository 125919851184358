import { createSelector } from "reselect";
import _get from "lodash/fp/get";
import { REDUCER_NAME } from "./consts";

export const selectIsLoading = createSelector(
  (state) => state[REDUCER_NAME],
  _get("loading")
);

export const selectErrorMessage = createSelector(
  (state) => state[REDUCER_NAME],
  _get("errorMessage")
);

export const selectYearVisits = createSelector(
  (state) => state[REDUCER_NAME],
  _get("year")
);

export const selectMonthVisits = createSelector(
  (state) => state[REDUCER_NAME],
  _get("month")
);

export const selectWeekVisits = createSelector(
  (state) => state[REDUCER_NAME],
  _get("week")
);

export const selectDayVisits = createSelector(
  (state) => state[REDUCER_NAME],
  _get("day")
);

export const selectChartMaxVal = createSelector(selectYearVisits, (data) => {
  if (data && "datasets" in data) {
    const maxVal = Math.max(...data.datasets.flat()[0].data);

    return maxVal;
  }
});
