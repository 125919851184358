import React, { useState } from 'react'
import { useRouteMatch, useHistory } from "react-router-dom";

import EditVenuePopup from './editVenuePopup'
import DeletePopup from '../buttonedPopup'
import UsersPopup from './usersPopup'
import api from '../../utils/api'

import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/location.svg';
import { ReactComponent as MobileIcon } from '../../assets/images/mobile.svg';
import { ReactComponent as UsersIcon } from '../../assets/images/users-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/org-delete.svg';

import '../../assets/css/venue/venue-item.scss'

export default ({ venue, setVenue, removeVenue, hideRemove = false, hideMore = false }) => {

    const match = useRouteMatch()
    const history = useHistory()
    const [showEdit, setShowEdit] = useState(false)
    const [users, setUsers] = useState([])
    const [showUsers, setShowUsers] = useState(false)
    const [deleteVenue, setDeleteVenue] = useState(false)

    const getInfo = type => {
        if (!venue || !venue.organisationContactInfos)
            return ''
        const contactInfo = venue.organisationContactInfos.find(info => info.contactType === type)
        if (contactInfo)
            return contactInfo.contactValue
        else
            return ''
    }

    const showAdminUsers = async () => {
        const { data } = await api.get(`/organisations/${venue.externalId}/users`)
        setUsers(data)
        setShowUsers(true)
    }

    const deleteCurrentVenue = async () => {
        await api.delete(`/organisations/${venue.externalId}`)
        removeVenue(venue.externalId)
        setDeleteVenue(false)
    }

    return (
        <div className='venue-item'>
            <div className='venue-item-top'>
                <label>{venue.name}</label>
                <div className='venue-item-actions'>
                    <UsersIcon className='venue-item-action venue-item-action-users' onClick={showAdminUsers} />
                    <EditIcon className='venue-item-action venue-item-action-edit' onClick={() => setShowEdit(true)} />
                    {!hideMore && <DeleteIcon className='venue-item-action venue-item-action-users' onClick={() => setDeleteVenue(true)} />}
                </div>
            </div>
            <hr />
            <div className='venue-item-content'>
                <LocationIcon className='venue-item-icon' />
                <label>{venue.location ? venue.location.fullAddress : ''}</label>
            </div>
            <div className='venue-item-content'>
                <MailIcon className='venue-item-icon' />
                <label>{getInfo('EMAIL')}</label>
            </div>
            <div className='venue-item-bottom'>
                <div className='venue-item-content'>
                    <MobileIcon className='venue-item-icon' />
                    <label>{getInfo('MOBILE')}</label>
                </div>
                {!hideMore && <button onClick={() => history.push(`${match.path}/${venue.externalId}`)}>More</button>}
            </div>
            {showEdit && <EditVenuePopup venue={venue} setVenue={setVenue} close={() => setShowEdit(false)} />}
            {showUsers && <UsersPopup organisationId={venue.externalId} users={users} setUsers={setUsers} close={() => setShowUsers(false)} />}
            {deleteVenue && <DeletePopup title='Delete customer' text='Are you sure you want to delete?' close={() => setDeleteVenue(false)} action={deleteCurrentVenue} actionText='Delete' />}
        </div >
    )
}