import React from "react";
import styled from "@emotion/styled";

import {Trash} from "phosphor-react";

const ActionsPanelWrapper = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
  position: absolute;
  top: 5px;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  *:focus {
    outline: 0 !important;
  }

  .template-delete-tooltip {
    margin-right: 40px !important;
    padding: 3px 5px !important;
  }
`;

const ActionsPanel = (props) => {
  const {
    isHover,
    height,
    isSuperAdmin,
    template,
    templateType,
    deleteTemplateRequest,
    organisationId,
  } = props;

  const [showFeaturedTemplatesControls, setShowFeaturedTemplatesControls] =
    React.useState(false);
  const [showSavedTemplatesControls, setShowSavedTemplatesControls] =
    React.useState(false);

  React.useEffect(() => {
    setShowFeaturedTemplatesControls(
      isSuperAdmin && templateType === "featured"
    );
    setShowSavedTemplatesControls(!isSuperAdmin && templateType === "saved");
  }, [isSuperAdmin, templateType]);

  return (
    (showFeaturedTemplatesControls && (
      <ActionsPanelWrapper height={height}>
        <a
          onClick={() =>
            deleteTemplateRequest(
              organisationId,
              template.externalId,
              templateType
            )
          }
        >
          <Trash
            data-tip
            data-for="delete"
            size={height * 0.9}
            style={{
              opacity: isHover ? 1 : 0,
              marginRight: "16px",
              transition: "all 400ms cubic-bezier(0.42, 0, 0.58, 1)",
            }}
          />
        </a>
      </ActionsPanelWrapper>
    )) ||
    (showSavedTemplatesControls && (
      <ActionsPanelWrapper height={height}>
        <a
          onClick={() =>
            deleteTemplateRequest(
              organisationId,
              template.externalId,
              templateType
            )
          }
        >
          <Trash
            data-tip
            data-for="delete"
            size={height * 0.9}
            style={{
              opacity: isHover ? 1 : 0,
              marginRight: "16px",
              transition: "all 400ms cubic-bezier(0.42, 0, 0.58, 1)",
            }}
          />
        </a>
      </ActionsPanelWrapper>
    ))
  );
};

export default ActionsPanel;
