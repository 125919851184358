import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";

import Title from "./Title";
import SegmentControls from "./SegmentControls";

const TopControlsWrapper = styled.div`
  justify-content: space-between;
  background: #ff9933;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  height: 70px;
  z-index: 99;
`;

const TopControlsTopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
`;

const TopControlsBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  padding: 16px 4px;
`;

const TopControls = (props) => {
  const {
    currentListType,
    allContacts,
    selectedAudience,
    selectedSegment,
    selectedCustomers,
    setSelectedCustomers,
    openAddNewAudience,
    setOpenAddNewAudience,
    getSegmentsRequest,
    selectSegmentRequest,
    getAllContactsRequest,
    selectListTypeRequest,
    setCurrentSelectedSegmentId,
    setSelectedContactsIds,
    setIsAllContactsSelected,
    removeFilterRequest
  } = props;

  const [currentTitle, setCurrentTitle] = useState("My Customers");

  useEffect(() => {
    allContacts && currentListType === "all" && setCurrentTitle("My Customers");

    selectedAudience &&
    currentListType === "audiences" &&
    setCurrentTitle(selectedAudience.name);

    selectedSegment &&
    currentListType === "segments" &&
    setCurrentTitle(selectedSegment.name);
  }, [selectedAudience, selectedSegment, currentListType]);

  return (
      <>

        <TopControlsWrapper>
          <TopControlsTopRow>
            <Title title={currentTitle}/>
          </TopControlsTopRow>

          <TopControlsBottomRow>
            <SegmentControls
                {...props}
                selectedCustomers={selectedCustomers}
                setSelectedCustomers={setSelectedCustomers}
                openAddNewAudience={openAddNewAudience}
                setOpenAddNewAudience={setOpenAddNewAudience}
                selectedSegment={selectedSegment}
                getSegmentsRequest={getSegmentsRequest}
                currentListType={currentListType}
                selectSegmentRequest={selectSegmentRequest}
                getAllContactsRequest={getAllContactsRequest}
                selectListTypeRequest={selectListTypeRequest}
                setCurrentSelectedSegmentId={setCurrentSelectedSegmentId}
                setSelectedContactsIds={setSelectedContactsIds}
                setIsAllContactsSelected={setIsAllContactsSelected}
                removeFilterRequest={removeFilterRequest}
            />
          </TopControlsBottomRow>
        </TopControlsWrapper>
      </>
  );
};

export default TopControls;
