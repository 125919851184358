import {
  GET_VISITS_AND_SIGNUPS_REQUEST,
  GET_VISITS_AND_SIGNUPS_FAILURE,
  GET_VISITS_AND_SIGNUPS_SUCCESS,
  GET_YEAR_VISITS_AND_SIGNUPS_REQUEST,
  GET_YEAR_VISITS_AND_SIGNUPS_SUCCESS,
  GET_YEAR_VISITS_AND_SIGNUPS_FAILURE,
  GET_MONTH_VISITS_AND_SIGNUPS_REQUEST,
  GET_MONTH_VISITS_AND_SIGNUPS_SUCCESS,
  GET_MONTH_VISITS_AND_SIGNUPS_FAILURE,
  GET_WEEK_VISITS_AND_SIGNUPS_REQUEST,
  GET_WEEK_VISITS_AND_SIGNUPS_SUCCESS,
  GET_WEEK_VISITS_AND_SIGNUPS_FAILURE,
  GET_DAY_VISITS_AND_SIGNUPS_REQUEST,
  GET_DAY_VISITS_AND_SIGNUPS_SUCCESS,
  GET_DAY_VISITS_AND_SIGNUPS_FAILURE,
  // GET_HOUR_VISITS_AND_SIGNUPS_REQUEST,
  // GET_HOUR_VISITS_AND_SIGNUPS_SUCCESS,
  // GET_HOUR_VISITS_AND_SIGNUPS_FAILURE,
  getAllVisitsAndSignupsRequest,
  getAllVisitsAndSignupsSuccess,
  getAllVisitsAndSignupsFailure,
  getYearVisitsAndSignupsRequest,
  getYearVisitsAndSignupsSuccess,
  getYearVisitsAndSignupsFailure,
  getMonthVisitsAndSignupsRequest,
  getMonthVisitsAndSignupsSuccess,
  getMonthVisitsAndSignupsFailure,
  getWeekVisitsAndSignupsRequest,
  getWeekVisitsAndSignupsSuccess,
  getWeekVisitsAndSignupsFailure,
  getDayVisitsAndSignupsRequest,
  getDayVisitsAndSignupsSuccess,
  getDayVisitsAndSignupsFailure,
  // getHourlyVisitsAndSignupsRequest,
  // getHourlyVisitsAndSignupsSuccess,
  // getHourlyVisitsAndSignupsFailure,
} from "./visitsAndSignupsActions";

const actions = {
  GET_VISITS_AND_SIGNUPS_REQUEST,
  GET_VISITS_AND_SIGNUPS_FAILURE,
  GET_VISITS_AND_SIGNUPS_SUCCESS,
  GET_YEAR_VISITS_AND_SIGNUPS_REQUEST,
  GET_YEAR_VISITS_AND_SIGNUPS_SUCCESS,
  GET_YEAR_VISITS_AND_SIGNUPS_FAILURE,
  GET_MONTH_VISITS_AND_SIGNUPS_REQUEST,
  GET_MONTH_VISITS_AND_SIGNUPS_SUCCESS,
  GET_MONTH_VISITS_AND_SIGNUPS_FAILURE,
  GET_WEEK_VISITS_AND_SIGNUPS_REQUEST,
  GET_WEEK_VISITS_AND_SIGNUPS_SUCCESS,
  GET_WEEK_VISITS_AND_SIGNUPS_FAILURE,
  GET_DAY_VISITS_AND_SIGNUPS_REQUEST,
  GET_DAY_VISITS_AND_SIGNUPS_SUCCESS,
  GET_DAY_VISITS_AND_SIGNUPS_FAILURE,
  // GET_HOUR_VISITS_AND_SIGNUPS_REQUEST,
  // GET_HOUR_VISITS_AND_SIGNUPS_SUCCESS,
  // GET_HOUR_VISITS_AND_SIGNUPS_FAILURE,
  getAllVisitsAndSignupsRequest,
  getAllVisitsAndSignupsSuccess,
  getAllVisitsAndSignupsFailure,
  getYearVisitsAndSignupsRequest,
  getYearVisitsAndSignupsSuccess,
  getYearVisitsAndSignupsFailure,
  getMonthVisitsAndSignupsRequest,
  getMonthVisitsAndSignupsSuccess,
  getMonthVisitsAndSignupsFailure,
  getWeekVisitsAndSignupsRequest,
  getWeekVisitsAndSignupsSuccess,
  getWeekVisitsAndSignupsFailure,
  getDayVisitsAndSignupsRequest,
  getDayVisitsAndSignupsSuccess,
  getDayVisitsAndSignupsFailure,
  // getHourlyVisitsAndSignupsRequest,
  // getHourlyVisitsAndSignupsSuccess,
  // getHourlyVisitsAndSignupsFailure,
};

export default actions;
