import React, {ChangeEvent} from "react";
import {ActionType, getActionTypeMap} from "../../types";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import uuid from 'react-uuid';

const actionTypeMap: Map<any, string> = getActionTypeMap();

type Props = {
  type: ActionType
  setType: (val: any) => void;
}

const ActionTypeSelect = ({type, setType}: Props) => {

  return (
      <FormControl variant="standard" sx={{m: 1, minWidth: 200}}>
        <InputLabel id="event-type-label">Action Type</InputLabel>
        <Select
            disableUnderline
            labelId='event-type-label'
            label="Event Type"
            fullWidth
            //variant="filled"
            value={type}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setType(e.target.value)}
        >
          {Array.from(actionTypeMap.keys())
          .map((key: any) =>
              <MenuItem
                  key={uuid()}
                  value={key}
              >
                {actionTypeMap.get(key)}
              </MenuItem>
          )}
        </Select>
      </FormControl>
  );
}

export default ActionTypeSelect;