import { createSelector } from "reselect";
import _get from "lodash/fp/get";
import { REDUCER_NAME } from "./consts";

export const selectCreatedIdentity = createSelector(
  (state) => state[REDUCER_NAME],
  _get("createdIdentity")
);

export const selectCurrentIdentity = createSelector(
  (state) => state[REDUCER_NAME],
  _get("currentIdentity")
);

export const selectDeletedIdentity = createSelector(
  (state) => state[REDUCER_NAME],
  _get("deletedIdentity")
);

export const selectOrganisationIdentities = createSelector(
  (state) => state[REDUCER_NAME],
  (state) => {
    return state["organisationIdentities"];
  }
);

export const selectCurrentIdentityDomainRecords = createSelector(
  (state) => state[REDUCER_NAME],
  _get("currentIdentityDomainRecords")
);
