import React, {useContext, useEffect, useState} from "react";

import {NavigationNumber} from "./NavigationNumber";
import {PageNavigationContext} from "context/navigationContext";

import "assets/css/page-navigator.scss";

const PageNavigator = (props) => {
  const {
    changePage,
    totalPages,
    currentPage,
    prevPage,
    nextPage,
    goToPage
  } = props;

  const {navigation, setNavigation} = useContext(PageNavigationContext);
  const [numberButtons, setNumberButtons] = useState(null);

  useEffect(() => {
    setNumberButtons(getNumberNavigationButtons());
  }, [totalPages, navigation]);

  const onNavigation = (page) => {
    if (page < totalPages && page >= 0) {
      changePage(page);
    }
  };

  const getNumberNavigationButtons = () => {
    let buttons = [];
    if (totalPages > 5) {
      const current = navigation.current;

      if (current < 3) {
        buttons = [...Array(5).keys()];
      } else if (current + 4 > totalPages) {
        buttons = [
          totalPages - 5,
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
        ];
      } else {
        buttons = [current - 2, current - 1, current, current + 1, current + 2];
      }
    } else {
      buttons = [...Array(totalPages).keys()];
    }

    let x = buttons.map((nr) => ({
      id: nr,
      text: nr + 1,
      selected: nr === navigation.current,
    }));

    return buttons.map((nr) => ({
      id: nr,
      text: nr + 1,
      selected: nr === navigation.current,
    }));
  };

  return (
      <div className="page-navigator">
        <button onClick={() => prevPage(currentPage)}>PREV</button>
        <div className="number-navigation">
          {numberButtons && numberButtons.map((number) => (
              <NavigationNumber
                  currentPage={currentPage}
                  key={number.id}
                  navigator={number}
                  onClick={goToPage}
              />
          ))}
        </div>
        <button onClick={() => nextPage(currentPage)}>NEXT</button>
      </div>
  );
};

export default PageNavigator;
