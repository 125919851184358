// import { all } from "@redux-saga/core/effects";
import actions from "../actions";

const {
  GET_ALL_CONTACTS_REQUEST,
  GET_ALL_CONTACTS_FAILURE,
  GET_ALL_CONTACTS_SUCCESS,
  TOGGLE_CONTACT_REQUEST,
  TOGGLE_CONTACT_FAILURE,
  TOGGLE_CONTACT_SUCCESS,
  TOGGLE_ALL_CONTACTS_REQUEST,
  TOGGLE_ALL_CONTACTS_FAILURE,
  TOGGLE_ALL_CONTACTS_SUCCESS,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_FAILURE,
  CREATE_CONTACT_SUCCESS,
  IMPORT_CONTACTS_REQUEST,
  IMPORT_CONTACTS_FAILURE,
  IMPORT_CONTACTS_SUCCESS,
  DELETE_CONTACTS_REQUEST,
  DELETE_CONTACTS_FAILURE,
  DELETE_CONTACTS_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  allContacts: null,
  currentPagination: null,
  createdContact: null,
  //delete contacts
  deletedContacts: null,
  isDeleteContactsLoading: false,
  isDeleteContactsSuccessfully: false,
  deleteContactsErrorMessage: null,
  importedContactsSuccessfully: false,
  importContactsErrorMessage: null,
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CONTACTS_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case GET_ALL_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_ALL_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allContacts: action.payload.allContacts,
        currentPagination: action.payload.currentPagination,
      };
    }
    ///---
    case TOGGLE_CONTACT_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case TOGGLE_CONTACT_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case TOGGLE_CONTACT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,

        allContacts: {
          ...state.allContacts,
          [action.payload.contactId]: {
            ...state.allContacts[action.payload.contactId],
            selected: !state.allContacts[action.payload.contactId].selected,
          },
        },
      };
    }

    case CREATE_CONTACT_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case CREATE_CONTACT_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
      };
    }

    case CREATE_CONTACT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        createdContact: action.payload,
      };
    }

    //---

    case TOGGLE_ALL_CONTACTS_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case TOGGLE_ALL_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case TOGGLE_ALL_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allContacts: { ...action.payload.updatedContacts },
      };
    }

    //---

    case IMPORT_CONTACTS_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
        importedContactsSuccessfully: false,
        importContactsErrorMessage: null,
      };
    }

    case IMPORT_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        importedContactsSuccessfully: false,
        importContactsErrorMessage: action.payload,
      };
    }

    case IMPORT_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        importedContactsSuccessfully: true,
        importContactsErrorMessage: null,
        // allContacts: { ...action.payload.updatedContacts },
      };
    }

    //---

    case DELETE_CONTACTS_REQUEST: {
      return {
        ...state,
        isDeleteContactsLoading: true,
        deleteContactsErrorMessage: null,
        isDeleteContactsSuccessfully: false,
      };
    }

    case DELETE_CONTACTS_FAILURE: {
      return {
        ...state,
        isDeleteContactsLoading: false,
        deleteContactsErrorMessage: action.payload,
        isDeleteContactsSuccessfully: false,
      };
    }

    case DELETE_CONTACTS_SUCCESS: {
      return {
        ...state,
        isDeleteContactsLoading: false,
        deleteContactsErrorMessage: null,
        deletedContacts: action.payload.deletedContacts.config.data,
        isDeleteContactsSuccessfully: true,
      };
    }

    default:
      return state;
  }
}
