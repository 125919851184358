import React from "react";
import styled from "@emotion/styled";

const TopWeekDaysHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 6px;

  @media only screen and (max-width: 1200px) {
    column-gap: 2px;
  }

  .week-day {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;

    label {
      font-weight: 500;
      font-family: Roboto Bold;
      font-size: 12px;
      color: #ea5454;
    }
  }
`;

const weekDaysLetters = ["M", "T", "W", "T", "F", "S", "S"];

const TopWeekDaysHeader = (props) => {
  return (
    <TopWeekDaysHeaderWrapper>
      {weekDaysLetters.map((wd, index) => {
        return (
          <div className="week-day">
            <label>{wd}</label>
          </div>
        );
      })}
    </TopWeekDaysHeaderWrapper>
  );
};

export default TopWeekDaysHeader;
