import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import {
  toggleNavBarRequest,
  subscribeRequest,
  unsubscribeRequest,
} from "./actions";

import {
  selectIsLoading,
  selectErrorMessage,
  selectIsNavBarLocked,
} from "./selectors";

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  errorMessage: selectErrorMessage,
  isNavBarLocked: selectIsNavBarLocked,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleNavBarRequest,
      subscribeRequest,
      unsubscribeRequest,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
