import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { BackButton } from "../components/widget/backButton";
import Subscriptions from "../components/billing/Subscriptions";
import PaymentMethods from "../components/billing/paymentMethods";
import Invoices from "../components/billing/invoices";
import DeletePopup from "../components/buttonedPopup";
import api from "../utils/api";
import { from } from "rxjs";
import "../assets/css/billing/billing.scss";

const Billing = (props) => {
  const { selectedOrganisation, selectOrganisationRequest, userOrganisation } =
    props;

  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [current, setCurrent] = useState("Subscriptions");
  const [suspended, setSuspended] = useState();
  const [organisationId, setOrganisationId] = useState();
  const [adminUser, setAdminUser] = useState();

  useEffect(() => {
    const adminUser = userOrganisation.orgType !== "ORGANISATION";
    setAdminUser(adminUser);
    setOrganisationId(
      adminUser &&
        selectedOrganisation &&
        selectedOrganisation.orgType === "ORGANISATION"
        ? selectedOrganisation.externalId
        : userOrganisation.externalId
    );
  }, [selectedOrganisation, userOrganisation]);

  useEffect(() => {
    if (location.pathname === "/billing/cards") {
      setCurrent("Manage Cards");
    } else if (location.pathname === "/billing/subscriptions") {
      setCurrent("Subscriptions");
    } // else if (location.pathname === '/billing/payments')
    //     setCurrent('Payments')
    else if (location.pathname === "/billing/invoices") {
      setCurrent("Invoices");
    }
  }, [location.pathname]);

  const suspend = async () => {
    const orgId = selectedOrganisation.externalId;
    from(
      selectedOrganisation.isSuspended
        ? api.delete(`/organisations/${orgId}/suspend`)
        : api.put(`/organisations/${orgId}/suspend`)
    ).subscribe(() => {
      selectOrganisationRequest({
        ...selectedOrganisation,
        isSuspended: !selectedOrganisation.isSuspended,
      });
      setSuspended(null);
    });
  };

  return (
    <div className="billing-page">
      <BackButton />
      <div className="billing-top">
        <label className="title">My Account/{current}</label>
        <div className="billing-actions">
          {adminUser &&
            selectedOrganisation &&
            selectedOrganisation.orgType === "ORGANISATION" && (
              <button onClick={() => setSuspended(true)}>
                {selectedOrganisation.isSuspended
                  ? "Unsuspend account"
                  : "Suspend account"}
              </button>
            )}
        </div>
      </div>
      <div className="billing-content">
        <div className="left">
          <label
            className={
              location.pathname === "/billing/subscriptions" &&
              "menu-item-selected"
            }
            onClick={() => history.push(`${match.path}/subscriptions`)}
          >
            Subscriptions
          </label>
          <label
            className={
              location.pathname === "/billing/cards" && "menu-item-selected"
            }
            onClick={() => history.push(`${match.path}/cards`)}
          >
            Manage Cards
          </label>

          <label
            className={
              location.pathname === "/billing/invoices" && "menu-item-selected"
            }
            onClick={() => history.push(`${match.path}/invoices`)}
          >
            Invoices
          </label>
        </div>
        <div className="right">
          <Switch>
            <Route path={`${match.path}/subscriptions`}>
              <Subscriptions
                selectedOrganisation={selectedOrganisation}
                organisationId={organisationId}
                adminUser={adminUser}
              />
            </Route>
            <Route path={`${match.path}/cards`}>
              <PaymentMethods organisationId={organisationId} />
            </Route>
            <Route path={`${match.path}/invoices`}>
              <Invoices organisationId={organisationId} />
            </Route>
          </Switch>
        </div>
      </div>
      {suspended && (
        <DeletePopup
          title="Delete customer"
          text={`Are you sure you want to ${
            selectedOrganisation.isSuspended ? "unsuspend" : "suspend"
          } account?`}
          close={() => setSuspended(null)}
          action={suspend}
          actionText={
            selectedOrganisation.isSuspended ? "Unsuspend" : "Suspend"
          }
        />
      )}
    </div>
  );
};

export default Billing;
