import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {from, map, Observable} from "rxjs";
import {AxiosResponse} from "axios";
import api from "../../../../../utils/api";
import {Param, ParamType} from "../../../types";
import uuid from "react-uuid";

type Audience = {
  id: string
  name: string
}

export const getAudiences = (organisationId: string): Observable<Audience[]> => {
  return from(api.get(`/organisation/${organisationId}/audiences`))
  .pipe(map((response: AxiosResponse<Audience[]>) => response.data));
}

type Props = {
  organisationId: string
  params: Param[]
  setParam: React.Dispatch<React.SetStateAction<Param>>
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const AudienceParam = ({organisationId, params, setParam, setDisabled}: Props) => {

  const [audiences, setAudiences] = useState<Audience[]>([]);
  const [audience, setAudience] = useState<string>("");

  useEffect(() => {
    if (params) {
      let p: Param | null = findAudienceParam();
      if (p && p.paramValue !== audience) {
        const a = audiences.find((a: Audience) => a.id === p.paramValue);
        if (a) {
          setParam(getParam(p.paramValue, a.name));
          setAudience(p.paramValue);
        }
      }
    }
  }, [params]);

  useEffect(() => {
    setDisabled(true);
    getAudiences(organisationId)
    .subscribe({
      next: (response: Audience[]) => {
        if (response && response.length > 0) {
          setAudiences(response);

          let p: Param | null = findAudienceParam();
          if (!p) {
            setAudience(response[0].id);
            setParam(getParam(response[0].id, response[0].name));
          } else {
            //setParam(getParam(p.paramValue, a.name));
            setAudience(p.paramValue);
          }

          setDisabled(false);
        }
      },
      error: (err: any) => {
        console.error("error")
        console.error(err)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  const onChange = (id: string) => {
    const a = audiences.find((a: Audience) => a.id === id);
    setParam(getParam(id, a.name));
    setAudience(id);
  }

  const getParam = (value: string, label: string): Param => {
    return {
      id: uuid(),
      paramType: ParamType.AUDIENCE_ID,
      paramValue: value,
      label: label
    }
  }

  const findAudienceParam = (): Param | null => {
    return params.find((p: Param) => p.paramType === ParamType.AUDIENCE_ID);
  }

  return (
      <FormControl
          variant="standard"
          sx={{m: 1, minWidth: 250}}
      >
        <InputLabel
            id="audience-type-label"
            sx={{marginLeft: "10px"}}
        >
          Audience
        </InputLabel>
        <Select
            disableUnderline
            disabled={audiences.length === 0}
            labelId='audience-type-label'
            label="Audience"
            sx={{width: '100%'}}
            value={audience}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange(e.target.value)}
        >
          {audiences && audiences
          .map((a: Audience) =>
              <MenuItem
                  key={a.id}
                  value={a.id}
              >
                {a.name}
              </MenuItem>
          )}
        </Select>
        {audiences.length === 0 && (
            <FormHelperText error>No Audiences Available</FormHelperText>
        )}
      </FormControl>
  );
}
export default AudienceParam;
