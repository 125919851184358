import actions from "../actions";

import { CONTACTS_LIST_TYPES } from "consts";

const {
  SELECT_LIST_TYPE_REQUEST,
  SELECT_LIST_TYPE_FAILURE,
  SELECT_LIST_TYPE_SUCCESS,
  SET_CURRENT_PAGINATION_REQUEST,
  SET_CURRENT_PAGINATION_FAILURE,
  SET_CURRENT_PAGINATION_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  currentPagination: null,
  currentListType: CONTACTS_LIST_TYPES.ALL,
};

export default function miscReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_LIST_TYPE_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case SELECT_LIST_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SELECT_LIST_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentListType: action.payload,
      };
    }

    //---

    case SET_CURRENT_PAGINATION_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case SET_CURRENT_PAGINATION_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SET_CURRENT_PAGINATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentPagination: action.payload,
      };
    }
    default:
      return state;
  }
}
