import * as currentCampaignActions from "./currentCampaign";
import * as miscActions from "./misc";
import * as templatesActions from "./templates";

const actions = {
  ...currentCampaignActions,
  ...miscActions,
  ...templatesActions,
};

export default actions;
