import {put, takeLatest} from "redux-saga/effects";

import {SET_USER_REQUEST, setUserFailure, setUserSuccess} from "./actions";

function* setUserWorker(action) {
  try {
    const isSuperAdmin = action.payload.organisations[0].orgType === "ACCOUNT";

    yield (action.payload.isSuperAdmin = isSuperAdmin);

    yield put(setUserSuccess(action.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(setUserFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

export default function* watcher() {
  yield takeLatest(SET_USER_REQUEST, setUserWorker);
}
