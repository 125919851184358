// noinspection DuplicatedCode

import React, {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import api from "utils/api";
import {chartDefaults} from "utils/defaults";
import colors from "./colors";
import chartButtons from "./chartButtons";
import {from, map} from 'rxjs';
import NewDashboardChartWrapper from "./NewDashboardChartWrapper";
import {
  days,
  getApiUnit,
  getChartOptions,
  getDayLabels,
  getMonthLabels,
  getStartDate,
  getWeekLabels,
  getYearLabels,
  months
} from "./DashboardChartService";
import uuid from "react-uuid";

const NewDashboardChart = ({organisationId}) => {

  const [statistics, setStatistics] = useState([]);
  const [chartLabels, setChartLabels] = useState(getDayLabels());
  const [currentUnit, setCurrentUnit] = useState("month");
  const [chartOptions, setChartOptions] = useState(getChartOptions(false));
  const [items, setItems] = useState(chartButtons);

  //TODO get date from redux
  const admin = JSON.parse(
          localStorage.getItem("user")).organisations[0].orgType
      !== "ORGANISATION" && !localStorage.getItem("selected-organisation");

  useEffect(() => {
    if (organisationId != null) {
      const apiUnit = getApiUnit(currentUnit);
      const start = getStartDate(currentUnit);
      from(api.get(
          `/organisation/${organisationId}/analytics?unit=${apiUnit}&startDate=${start}`
      ))
      .pipe(map((response) => response.data))
      .subscribe((data) => {
        setStatistics(data);
        setChartOptions(getChartOptions(data.length > 1));
      });
    }

    if (currentUnit === "day") {
      setChartLabels(getDayLabels());
    } else if (currentUnit === "week") {
      setChartLabels(getWeekLabels());
    } else if (currentUnit === "month") {
      setChartLabels(getMonthLabels);
    } else if (currentUnit === "year") {
      setChartLabels(getYearLabels());
    }

  }, [organisationId, currentUnit]);

  const changeSelected = (index) => {
    const unit = items[index].unit;
    if (unit !== currentUnit) {
      items.map((item) => (item.selected = false));
      items[index].selected = true;
      setItems([...items]);
      setCurrentUnit(unit);
    }
  };

  const mapToChartData = (canvas, analytics, visits, index) => {
    const dataset = {...chartDefaults};
    dataset.label = analytics.organisationName;
    let visitValues = [...visits];
    if (currentUnit === "day") {
      analytics.visits.forEach(
          (v) => (visitValues[chartLabels.indexOf(
              new Date(v.timestamp * 1000).getHours())] = v.number)
      );
    } else if (currentUnit === "week") {
      analytics.visits.forEach(
          (v) => (visitValues[chartLabels.indexOf(
              new Date(v.timestamp * 1000).getDate())] = v.number)
      );
    } else if (currentUnit === "month") {
      analytics.visits.forEach(
          (v) => (visitValues[chartLabels.indexOf(
              new Date(v.timestamp * 1000).getDate())] = v.number)
      );
    } else if (currentUnit === "year") {
      visitValues = analytics.visits
      .sort((a, b) => a.timestamp - b.timestamp)
      .map(v => v.number);
      visitValues.shift();
      // analytics.visits
      // .sort((a,b) => a.timestamp - b.timestamp)
      // .forEach(
      //     (v) => (visitValues[chartLabels.indexOf(
      //         new Date(v.timestamp * 1000).getMonth()) + 1] = v.number)
      // );
    }

    dataset.data = visitValues;

    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height / 2);
    const color = colors[index];
    gradient.addColorStop(0, color.zero);
    gradient.addColorStop(0.5, color.half);
    gradient.addColorStop(1, color.one);

    dataset.backgroundColor = gradient;

    return dataset;
  };

  const formatLabels = (labels) => {
    if (currentUnit === "day") {
      return labels.map((label) => `${label}:00`);
    } else if (currentUnit === "week") {
      return labels
      .map((label, index) => {
        const currentDate = new Date();
        currentDate.setDate(new Date().getDate() - index);
        return days[currentDate.getDay()];
      })
      .slice()
      .reverse();
    } else if (currentUnit === "month") {
      return labels;
    } else if (currentUnit === "year") {
      return labels
      .map((label, index) => {
        const currentDate = new Date();
        currentDate.setMonth(new Date().getMonth() - index);
        return `${
            months[currentDate.getMonth()]
        } ${currentDate.getFullYear()}`;
      })
      .slice()
      .reverse();
    } else {
      return [];
    }
  };

  const getData = (canvas) => {
    const stats = statistics.slice(0, 5);
    const dataset = {};
    const initialVisits = new Array(31).fill(0);
    dataset.labels = formatLabels(chartLabels);
    dataset.datasets = stats.map((a, index) =>
        mapToChartData(canvas, a, initialVisits, index)
    );

    return dataset;
  };

  return (
      <NewDashboardChartWrapper>
        <div className="chart-top">
          <div className="logo-title">
            <div className="logo">
              <img alt="position icon"
                   src={require("assets/images/position-icon.svg").default}/>
            </div>
            <label className="visits-title">Visits</label>
          </div>
          <div className="unit-selector">
            {items.map((item) => (
                <button
                    key={uuid()}
                    className={`chart-button ${
                        item.selected && "chart-button-selected"
                    }`}
                    onClick={() => changeSelected(item.id)}
                >
                  {item.text}
                </button>
            ))}
          </div>
        </div>
        <div
            className={`stats-chart ${!admin && "stats-chart-locked"} ${
                !admin && "stats-chart-row"
            }`}
        >
          <Line
              data={getData}
              options={chartOptions}
              datasetKeyProvider={() => uuid()}
          />
        </div>
      </NewDashboardChartWrapper>
  );
};

export default NewDashboardChart;
