import * as dashboardActions from "./dashboard";
import * as landingPageActions from "./landingPage";
import * as trackAndTraceActions from "./trackAndTrace";

const connectActions = {
  ...dashboardActions,
  ...landingPageActions,
  ...trackAndTraceActions,
};

export default connectActions;
