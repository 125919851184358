import React from "react";
import EmailEditor from "react-email-editor";
import {customOptions, customTools} from "./config";

const SendDesign = (props) => {
  const {initialTemplateDesign, setCurrentUpdatedTemplate} = props;

  const editorRef = React.useRef(null);

  const [hasToLoadInitialDesign, _] = React.useState(
      initialTemplateDesign !== null
  );
  const [hasInitialDesignLoaded, setHasInitialDesignLoaded] =
      React.useState(false);
  const [isEditorReady, setIsEditorReady] = React.useState(false);
  const [updateListenerAdded, setUpdateListenerAdded] = React.useState(false);
  const [updatedTemplate, setUpdatedTemplate] = React.useState(
      initialTemplateDesign
  );

  //check if editor ready
  React.useEffect(() => {
    if (!isEditorReady) {
      setInterval(() => {
        if (editorRef.current?.editor) {
          setIsEditorReady(true);
        }
      }, 1000);
    }
  }, []);

  //check if event listener has to be added
  React.useEffect(() => {
    if (isEditorReady && !updateListenerAdded) {
      editorRef.current.editor.addEventListener("design:updated", (data) => {
        editorRef.current.editor.exportHtml(async (data) => {
          const currentTemplate = {
            name: updatedTemplate?.template?.name || null,
            externalId: updatedTemplate?.template?.externalId || null,
            unlayerDesign: data?.design ? JSON.stringify(data.design) : null,
            unlayerDesignHtml: data.html,
          };

          setUpdatedTemplate(currentTemplate);
          setUpdateListenerAdded(true);
        });
      });
    }
  }, [isEditorReady]);

  //check if initial design has to be loaded
  React.useEffect(() => {
    if (
        initialTemplateDesign &&
        hasToLoadInitialDesign &&
        isEditorReady &&
        !hasInitialDesignLoaded
    ) {
      const parsedTemplate = JSON.parse(initialTemplateDesign.unlayerDesign);
      editorRef.current.editor.loadDesign(parsedTemplate);
      setHasInitialDesignLoaded(true);
    }
  }, [
    editorRef,
    initialTemplateDesign,
    hasToLoadInitialDesign,
    isEditorReady,
    hasInitialDesignLoaded,
  ]);

  React.useEffect(() => {
    updatedTemplate && setCurrentUpdatedTemplate(updatedTemplate);
  }, [updatedTemplate]);

  return (
      <EmailEditor
          ref={editorRef}
          minHeight="600px"
          //onLoad={onLoad}
          //onReady={() => console.log("editor ready")}
          id="editor"
          projectId={10425}
          options={customOptions}
          tools={customTools}
      />
  );
};

export default SendDesign;
