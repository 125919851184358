import React from "react";
import styled from "@emotion/styled";
import api from "utils/api";
import {Field, reduxForm} from "redux-form";
import {compose} from "recompose";
import ToField from "./Fields/ToField";
import FromField from "./Fields/FromField";
import SubjectField from "./Fields/SubjectField";
import PreviewTextField from "./Fields/PreviewTextField";
import ScheduleField from "./Fields/ScheduleField";
import ButtonsPanel from "./ButtonsPanel";
import {from, map} from 'rxjs';

const FormWrapper = styled.form`
  grid-row: 1/2;
  grid-column: 1/10;

  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  padding: 20px;
`;

const Form = (props) => {
  const {
    organisationId,
    handleSubmit,
    change,
    currentCampaign,
    currentContactsList,
    currentAudiences,
    currentSegments,
    activeLicenses,
    selectedCustomers
  } = props;

  const [currentOrganisationEmails, setCurrentOrganisationEmails] =
      React.useState(null);

  React.useEffect(() => {
    from(api.get(`/email/organisation/${organisationId}/identity`))
    .pipe(map((response) => response.data))
    .subscribe((data) => {
      if (data) {
        setCurrentOrganisationEmails(
            data.filter((identity) => identity.status === "Success")
        )
      }
    });
  }, [organisationId]);

  return (
      <FormWrapper onSubmit={handleSubmit}>
        <ToField
            currentCampaign={currentCampaign}
            currentContactsList={currentContactsList}
            currentAudiences={currentAudiences}
            currentSegments={currentSegments}
            selectedCustomers={selectedCustomers}
        />

        <Field
            name="campaignFrom"
            component={FromField}
            type="text"
            props={{
              change,
              className: "send-campaign",
              fieldName: "campaignFrom",
              currentOrganisationEmails,
            }}
        />

        <Field
            name="campaignSubject"
            component={SubjectField}
            type="text"
            props={{
              change,
              className: "send-campaign",
              fieldName: "campaignSubject",
            }}
        />

        <Field
            name="campaignPreviewText"
            component={PreviewTextField}
            type="text"
            props={{
              change,
              className: "send-campaign",
              fieldName: "campaignPreviewText",
            }}
        />

        <Field
            name="campaignSchedule"
            component={ScheduleField}
            type="text"
            props={{
              change,
              className: "send-campaign",
              fieldName: "campaignSchedule",
            }}
        />

        <ButtonsPanel
            {...props}
            currentOrganisationEmails={currentOrganisationEmails}
            activeLicenses={activeLicenses}
        />
      </FormWrapper>
  );
};

export default compose(
    reduxForm({
      form: "SEND_CAMPAIGN_FORM",
    })
)(Form);
