export interface Input {
  key: string,
  index?: number,
  selected: boolean,
  text: string,
}

export interface Inputs {
  [key: string]: Input[];
}

export interface Draggable {
  id: number,
  key: string,
  text: string,
}

export const defaultInputs: Input = {
  email: {
    key: 'email',
    index: 0,
    selected: true,
    text: 'Email',
  },

  firstName: {
    key: 'firstName',
    index: 1,
    selected: true,
    text: 'First Name'
  },

  lastName: {
    key: 'lastName',
    index: 2,
    selected: true,
    text: 'Last Name'
  },

  mobileNumber: {
    key: 'mobileNumber',
    selected: false,
    text: 'Mobile Number'
  },

  gender: {
    key: 'gender',
    selected: false,
    text: 'Gender'
  },

  dateOfBirth: {
    key: 'dateOfBirth',
    selected: false,
    text: 'Date of Birth'
  },

  question: {
    key: 'question',
    selected: false,
    text: ''
  }
};