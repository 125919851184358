import React from "react";

import PageTitle from "components/PageTitle";
import PageNavigator from "../../components/PageNavigator";

const WifiLandingPagesTop = (props) => {
  const {
    setPageSelected,
    pageSelected,
    totalPages,
    searchTerm,
    setSearchTerm

  } = props;
  return (
      <div className="landing-page-header">
        <PageTitle title={"Manage Landing Pages"}/>
        {totalPages && totalPages > 1 ? (
            <PageNavigator
                setPageSelected={setPageSelected}
                pageSelected={pageSelected}
                totalPages={totalPages}
            />
        ) : null}
        <div className="landing-page-search-inputs">
          <input
              value={searchTerm}
              type="search"
              placeholder="Search"
              className="quick-send__input--with-info"
              onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
  );
};

export default WifiLandingPagesTop;
