import React from 'react'

import Payment from './payment'

import { ReactComponent as CloseIcon } from '../../assets/images/delete.svg';

import '../../assets/css/billing/payment-popup.scss'

export default ({ plan, close, done, sendOrder }) => {

    return (
        <div className='payment-popup'>
            <div className='payment-popup-content'>
                <CloseIcon className='close-icon' onClick={close} />
                <Payment plan={plan} close={close} done={done} sendOrder={sendOrder} />
            </div>
        </div>
    )
}