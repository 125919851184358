import {call, put, takeLatest} from "redux-saga/effects";

import {apiGetCampaigns} from "./api";

import {
  GET_CAMPAIGNS_REQUEST,
  getCampaignsFailure,
  getCampaignsSuccess,
} from "./actions";

function* getCampaignsWorker(action) {
  try {
    const apiResult = yield call(apiGetCampaigns, action.payload);

    yield put(getCampaignsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getCampaignsFailure(errorMessage));
  }
}

export default function* watcher() {
  yield takeLatest(GET_CAMPAIGNS_REQUEST, getCampaignsWorker);
}
