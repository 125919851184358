import React from "react";

import { ReactComponent as CloseIcon } from "../assets/images/delete.svg";

import "../assets/css/buttoned-popup.scss";

export default ({ action, close, text, actionText }) => {
  const onClick = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <div className="buttoned-popup" onClick={onClick}>
      <div className="buttoned-popup-content">
        <CloseIcon className="close-icon" onClick={close} />
        <label>{text}</label>
        <div className="buttoned-popup-actions">
          <button onClick={action}>{actionText}</button>
          <button onClick={close}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
