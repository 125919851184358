import React from "react";
import styled from "@emotion/styled";
import Calendar from "./Calendar";
import Widget from "../Widget";

const SendCalendarWrapper = styled(Widget)`
  grid-row: 2/3;
  grid-column: 7/10;
  position: relative;


  @media only screen and (max-width: 1200px) {
    grid-row: 3/4;
    grid-column: 6/11;
  }
`;

const SendCalendar = (props) => {
  const { organisationCampaigns } = props;
  // const [formattedCampaigns, setFormatCampaigns] = React.useState([]);
  const currentYear = new Date().getFullYear().toString();
  const [year, setYear] = React.useState(currentYear);

  return (
    <SendCalendarWrapper
      showOverflow={true}
      className="send-dashboard-opens-widget"
    >
      <div className="inner-widget-header">
        <label className="inner-widget-header-title">
          Send Calendar {year}
        </label>
        <img alt="emails calendar" src={require("assets/images/emails-calendar.svg").default} />
      </div>
      <Calendar setYear={setYear} campaigns={organisationCampaigns} />
    </SendCalendarWrapper>
  );
};

export default SendCalendar;
