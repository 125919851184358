import { all, fork } from "redux-saga/effects";

import authSaga from "containers/Auth/sagas";
import userSaga from "containers/User/sagas";
import selectedOrganisationSaga from "containers/SelectedOrganisation/sagas";

import networkSaga from "containers/Network/sagas";

import connectSaga from "containers/Connect/sagas";
import miscSaga from "containers/Misc/sagas";

//---

//Send
import sendDashboardSaga from "containers/Send/SendDashboard/sagas";
import sendNewEmailSaga from "containers/Send/SendNewEmail/sagas";
import sendManageCampaignsSaga from "containers/Send/SendManage/sagas";
import sendSettingsSaga from "containers/Send/SendSettings/sagas";

//
import statisticsSaga from "containers/Statistics/sagas";
import campaignsSaga from "containers/Campaigns/sagas";
import analyticsSaga from "containers/Analytics/sagas";
import chartsSaga from "containers/Charts/sagas";
import superAdminDashboardSaga from "containers/SuperAdminDashboard/sagas";
import contactsListSaga from "containers/ContactsList/sagas";

// import venuesSagas from "containers/Venues/sagas";

export default function* rootSaga() {
  yield all([fork(authSaga)]);
  yield all([fork(userSaga)]);
  yield all([fork(selectedOrganisationSaga)]);
  yield all([fork(networkSaga)]);
  yield all([fork(connectSaga)]);
  yield all([fork(miscSaga)]);
  yield all([fork(statisticsSaga)]);
  yield all([fork(campaignsSaga)]);
  yield all([fork(sendDashboardSaga)]);
  yield all([fork(sendNewEmailSaga)]);
  yield all([fork(sendManageCampaignsSaga)]);
  yield all([fork(sendSettingsSaga)]);
  yield all([fork(analyticsSaga)]);
  yield all([fork(chartsSaga)]);
  yield all([fork(superAdminDashboardSaga)]);
  yield all([fork(contactsListSaga)]);
}
