import Axios from "axios";

export const apiLogin = (data) => {
  const { loginEmail, loginPassword } = data;

  return Axios.post(
    `${process.env.REACT_APP_BACKEND_API_URL}/user/authenticate`,
    {
      name: loginEmail,
      password: loginPassword,
    }
  );
};
