import {
  GET_YEAR_VISITS_REQUEST,
  GET_YEAR_VISITS_FAILURE,
  GET_YEAR_VISITS_SUCCESS,
  GET_MONTH_VISITS_REQUEST,
  GET_MONTH_VISITS_FAILURE,
  GET_MONTH_VISITS_SUCCESS,
  GET_WEEK_VISITS_REQUEST,
  GET_WEEK_VISITS_FAILURE,
  GET_WEEK_VISITS_SUCCESS,
  GET_DAY_VISITS_REQUEST,
  GET_DAY_VISITS_FAILURE,
  GET_DAY_VISITS_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  errorMessage: null,
  year: null,
  month: null,
  week: null,
  day: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    //--- YEAR

    case GET_YEAR_VISITS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        year: null,
      };
    }

    case GET_YEAR_VISITS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        year: null,
      };
    }

    case GET_YEAR_VISITS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        year: action.payload,
      };
    }

    //--- MONTH

    case GET_MONTH_VISITS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        month: null,
      };
    }

    case GET_MONTH_VISITS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        month: null,
      };
    }

    case GET_MONTH_VISITS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        month: action.payload,
      };
    }

    //--- WEEK

    case GET_WEEK_VISITS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        week: null,
      };
    }

    case GET_WEEK_VISITS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        week: null,
      };
    }

    case GET_WEEK_VISITS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        week: action.payload,
      };
    }
    // --- DAY

    case GET_DAY_VISITS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        day: null,
      };
    }

    case GET_DAY_VISITS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        day: null,
      };
    }

    case GET_DAY_VISITS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        day: action.payload,
      };
    }

    default:
      return state;
  }
}
