import React, {useState, useEffect} from "react";
import styled from "@emotion/styled";
import moment from "moment";

import {PaperPlaneTilt} from "phosphor-react";
import {
  Backdrop,
  Button,
  Fade,
  Modal,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material";
import group41Png from "../../../../../../../assets/images/Group41.png";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NoSendEmailLicense from "../../../../../NoSendEmailLicense";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '25px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
}));

const SendButtonWrapper = styled.div`
  background-color: #ea5454;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  padding: 10px 20px;

  opacity: ${(props) => (props.isEnabled ? 1 : 0.5)};
  transform: ${(props) => (props.isEnabled ? "scale(1)" : "scale(0.95)")};
  pointer-events: ${(props) => (props.isEnabled ? "auto" : "none")};
  cursor: pointer;

  transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1);

  label {
    color: white;
    font-weight: 300;
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
`;

const ConfirmationPlaceholder = styled.div`
  position: relative;
  width: 565px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const HeaderImage = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(46, 125, 50, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const ImageDiv = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
  top: 43.8px;
`;

const WarningWrapper = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
  top: 20px;
`;

const HeaderWarningAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(255, 153, 51, 0.12);
  border-radius: 50%;
  display: inline-block;
`;

const HeaderErrorAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(234, 84, 84, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-left: 15%;
  gap: 24px;
  color: #ff9933;
  width: 120px;
  height: 42px;
`;

const TypographyText = styled.div`
  position: absolute;
  height: 64px;
  left: 36px;
  right: 36px;
  top: 140.81px;
  font-family: 'Trebuchet MS', sans-serif ' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 133.4% !important;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const SendButton = (props) => {
  const {
    title,
    campaignFrom,
    // campaignPreviewText,
    campaignSchedule,
    campaignSubject,
    // campaignSendToTest,
    currentCampaign,
    organisationId,
    // sendTestEmailRequest,
    // change,
    isEnabled,
    createCurrentCampaignRequest,
    currentOrganisationEmails,
    selectedCustomers,
    currentContactsList,
    currentAudiences,
    activeLicenses,
    currentStep,
    updateCurrentStepRequest
  } = props;

  const classes = useStyles();

  const {selectedAudience} = currentAudiences || "";

  const {id, name, color} = selectedAudience || "";

  const [openError, setOpenError] = useState(false);

  const [open, setOpen] = useState(false);

  const [openNoLicense, setOpenNoLicense] = useState(false);

  const [sendTestBodyObj, setTestBodyObj] = React.useState(null);

  const [scheduleDate, setScheduleDate] = React.useState(null);

  const [senderId, setSenderId] = React.useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleConfirmClick = () => {
    createCurrentCampaignRequest(sendTestBodyObj)
    updateCurrentStepRequest(currentStep + 1);
    handleClose();
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const handleOpenNoLicense = () => {
    setOpenNoLicense(true);
  };

  const handleCloseNoLicense = () => {
    setOpenNoLicense(false);
  };

  useEffect(() => {
    if (campaignFrom?.email && currentOrganisationEmails) {
      const selectedSenderId = currentOrganisationEmails.filter((sender) => {
        return sender.email === campaignFrom.email;
      })[0].externalId;

      setSenderId(selectedSenderId);
    } else {
      setSenderId(null);
    }
  }, [currentCampaign, campaignFrom, currentOrganisationEmails]);

  useEffect(() => {
    const email = {
      messageBody: currentCampaign?.template?.unlayerDesignHtml,
      //messageBody: currentCampaign?.template?.messageBody,
      recipients: currentCampaign?.contacts ? currentCampaign.contacts : [],
      subject: campaignSubject,
      organisationExternalId: organisationId,
      senderExternalId: senderId,
      schedule: campaignSchedule?.active ? scheduleDate : null,
      test: false
    };
    setTestBodyObj(email);
  }, [props]);

  useEffect(() => {
    if (campaignSchedule?.active === false) {
      const minFromNow = moment(Date.now()).add(5, "minutes").unix();

      setScheduleDate(minFromNow);
    } else {
      if (campaignSchedule?.date && campaignSchedule?.time) {
        const formattedDate = moment(campaignSchedule.date).format(
            "YYYY/MM/DD"
        );

        const formattedTime = moment(campaignSchedule.time).format("HH:mm");

        const dateTime = moment(formattedDate + " " + formattedTime).unix();
        setScheduleDate(dateTime);
      }
    }
  }, [campaignSchedule]);

  return (
      <>
        <SendButtonWrapper
            isEnabled={isEnabled}
            onClick={handleOpen}
        >
          <label>{title}</label>
          <PaperPlaneTilt size={18} color={"white"}></PaperPlaneTilt>
        </SendButtonWrapper>

        {/*NO EMAIL LICENSE*/}
        {(!activeLicenses.includes("SEND")) && (
            <NoSendEmailLicense {...props}/>
        )}

        {/*WITH EMAIL LICENSE*/}
        {activeLicenses.includes("SEND") && (
            <>
              {/*WITH/WITHOUT AUDIENCE*/}
              <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
              >
                <Fade in={open}>
                  <div className={classes.paper}>
                    <ConfirmationPlaceholder>
                      <img
                          alt="group"
                          onClick={handleClose}
                          className={"closeImg"}
                          src={group41Png}
                      />
                      <WarningWrapper>
                        <HeaderWarningAmber>
                          <WarningAmberRoundedIcon
                              sx={{
                                fontSize: 45,
                                padding: 3,
                                color: "#FF9933",
                              }}
                          />
                        </HeaderWarningAmber>
                      </WarningWrapper>
                      {currentContactsList !== "audiences" && (
                          <>
                            <TypographyText>
                              Are you sure you want to send email to:
                              <br/>
                              ({currentCampaign && currentCampaign.contacts
                                && currentCampaign.contacts.length}) {" "}
                              contacts?
                            </TypographyText>
                          </>
                      )}

                      {currentContactsList === "audiences" && selectedAudience
                          !== ""
                          && (
                              <>
                                <TypographyText>
                                  Are you sure you want to send email to:
                                  <br/>
                                  ({currentCampaign && currentCampaign.contacts
                                    && currentCampaign.contacts.length}) {" "}
                                  contacts?
                                  <List sx={{marginLeft: 18, marginTop: 0}}>
                                    <ListItem disablePadding>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          {color !== null ? `${color}` : ''}
                                        </ListItemIcon>
                                        <ListItemText>
                                          <Typography sx={{
                                            fontFamily: "'Roboto',sans-serif !important",
                                            fontSize: "16px",
                                            fontWeight: 400
                                          }}>
                                            {name !== null
                                                ? `${name}` : ''}
                                          </Typography>
                                        </ListItemText>
                                      </ListItemButton>
                                    </ListItem>
                                  </List>
                                </TypographyText>
                              </>
                          )}
                      <ButtonSection>
                        <Button
                            onClick={handleClose}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              zIndex: 1,
                              marginTop: 60,
                              marginLeft: '32ch',
                              marginRight: 4
                            }}
                            variant="text"
                        >
                          CANCEL
                        </Button>
                        <Button
                            onClick={handleConfirmClick}
                            endIcon={<CheckCircleOutlineIcon
                                sx={{fontSize: '80px', fontWeight: '400'}}/>}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              zIndex: 1,
                              marginTop: 60,
                              marginRight: 4
                            }}
                            variant="text"
                            disabled={!currentCampaign?.contacts?.length > 0}
                        >
                          CONFIRM
                        </Button>
                      </ButtonSection>
                    </ConfirmationPlaceholder>
                  </div>
                </Fade>
              </Modal>


              {/*SEND EMAIL ERROR*/}
              {((openError === true)) && (
                  <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={openError}
                      onClose={handleCloseError}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                  >
                    <Fade in={openError}>
                      <div className={classes.paper}>
                        <ConfirmationPlaceholder>
                          <img
                              alt="group"
                              onClick={handleCloseError}
                              className={"closeImg"}
                              src={group41Png}
                          />
                          <ImageDiv>
                            <HeaderErrorAmber>
                              <WarningAmberRoundedIcon
                                  sx={{
                                    fontSize: 45,
                                    padding: 3,
                                    color: "#EA5454",
                                  }}
                              />
                            </HeaderErrorAmber>
                          </ImageDiv>
                          <TypographyText style={{marginTop: 40}}>
                            <Typography variant="h6" color="red">
                              Error Sending Email
                            </Typography>
                            <Typography fontSize={'large'}>
                              Please try again later?
                            </Typography>
                          </TypographyText>
                        </ConfirmationPlaceholder>
                      </div>
                    </Fade>
                  </Modal>
              )}
            </>
        )}
      </>
  );
};

export default SendButton;
