import {createSelector} from "reselect";
import _get from "lodash/fp/get";
import {REDUCER_NAME} from "./consts";

//CONTACTS

export const selectContactsSlice = createSelector(
    (state) => state[REDUCER_NAME]["contacts"],
    (state) => state
);

export const selectAllContacts = createSelector(
    selectContactsSlice,
    _get("allContacts")
);

export const selectCreatedContact = createSelector(
    selectContactsSlice,
    _get("createdContact")
);

export const selectDeletedContacts = createSelector(
    selectContactsSlice,
    _get("deletedContacts")
);

export const selectIsDeleteContactsLoadingContacts = createSelector(
    selectContactsSlice,
    _get("isDeleteContactsLoading")
);

export const selectDeleteContactsErrorMessage = createSelector(
    selectContactsSlice,
    _get("deleteContactsErrorMessage")
);

export const selectImportedContactsSuccessfully = createSelector(
    selectContactsSlice,
    _get("importedContactsSuccessfully")
);

export const selectIsDeleteContactsSuccessfully = createSelector(
    selectContactsSlice,
    _get("isDeleteContactsSuccessfully")
);

export const selectImportContactsErrorMessage = createSelector(
    selectContactsSlice,
    _get("importContactsErrorMessage")
);

export const selectAreAllContactsSelected = createSelector(
    selectAllContacts,
    (allContacts) => {
      if (allContacts) {
        const areAllSelected = Object.values(allContacts).every(
            (contact) => contact.selected === true
        );
        return areAllSelected;
      }
    }
);

//SEGMENTS

export const selectSegmentsSlice = createSelector(
    (state) => state[REDUCER_NAME]["segments"],
    (state) => state
);
export const selectAllSegments = createSelector(
    selectSegmentsSlice,
    _get("allSegments")
);

export const selectSelectedSegment = createSelector(
    selectSegmentsSlice,
    _get("selectedSegment")
);

export const selectSelectedSegmentContacts = createSelector(
    selectSegmentsSlice,
    (segments) => {
      return segments.selectedSegmentContacts;
    }
);

export const selectCreatedSegment = createSelector(
    selectSegmentsSlice,
    _get("createSegment")
);

export const selectDeletedSegmentId = createSelector(
    selectSegmentsSlice,
    _get("deletedSegmentId")
);

export const selectAreAllSegmentContactsSelected = createSelector(
    selectSelectedSegmentContacts,
    (allSelectedSegmentContacts) => {
      if (allSelectedSegmentContacts) {
        const areAllSelected = Object.values(allSelectedSegmentContacts).every(
            (contact) => contact.selected === true
        );
        return areAllSelected;
      }
    }
);
//AUDIENCES

export const selectAudiencesSlice = createSelector(
    (state) => state[REDUCER_NAME]["audiences"],
    (state) => state
);
export const selectAllAudiences = createSelector(
    selectAudiencesSlice,
    _get("allAudiences")
);

export const selectSelectedAudience = createSelector(
    selectAudiencesSlice,
    _get("selectedAudience")
);

export const selectSelectedAudienceContacts = createSelector(
    selectAudiencesSlice,
    _get("selectedAudienceContacts")
);

export const selectCreatedAudience = createSelector(
    selectAudiencesSlice,
    _get("createdAudience")
);

export const selectAreAllAudienceContactsSelected = createSelector(
    selectSelectedAudienceContacts,
    (allSelectedAudienceContacts) => {
      if (allSelectedAudienceContacts) {
        const areAllSelected = Object.values(allSelectedAudienceContacts).every(
            (contact) => contact.selected === true
        );
        return areAllSelected;
      }
    }
);

//MISC

export const selectMiscSlice = createSelector(
    (state) => state[REDUCER_NAME]["misc"],
    (state) => state
);

export const selectCurrentListType = createSelector(
    selectMiscSlice,
    _get("currentListType")
);

export const selectCurrentPagination = createSelector(
    selectMiscSlice,
    _get("currentPagination")
);
// export const selectOrganisationContacts = createSelector(
//   (state) => state[REDUCER_NAME],
//   _get("organisationContacts")
// );

// export const selectCurrentContactsFilterType = createSelector(
//   (state) => state[REDUCER_NAME],
//   _get("currentContactsFilterType")
// );

//FILTERS

export const selectFiltersSlice = createSelector(
    (state) => state[REDUCER_NAME]["filters"],
    (state) => state
);

export const selectCurrentFilters = createSelector(
    selectFiltersSlice,
    _get("currentFilters")
);

// export const selectCurrentFormattedFilters = createSelector(
//   selectCurrentFilters,
//   (filters) => {
//     var formatedFilters = [];

//     filters &&
//       filters.forEach((filter) => {
//         const formattedFilter = {};
//         switch (filter.filterField) {
//           case "visits":
//             formattedFilter.key = "visits";
//             formattedFilter.operation = "WHERE";

//             switch (filter.filterOperation) {
//               case "greater":
//                 formattedFilter.value = `this.visits.length < ${filter.filterOperationValue}`;
//                 break;
//               case "less":
//                 formattedFilter.value = `this.visits.length > ${filter.filterOperationValue}`;
//                 break;
//               case "equal":
//                 formattedFilter.value = `this.visits.length === ${filter.filterOperationValue}`;
//                 break;
//             }

//             return formatedFilters.push(formattedFilter);

//           case "birthday":
//             const formattedDate = moment()
//               .add(filter.filterOperationValue, "days")
//               .format("M/D");

//             formattedFilter.key = "birthday";
//             formattedFilter.operation = "REGEX";
//             formattedFilter.value = `${formattedDate}/.*$`;

//             return formatedFilters.push(formattedFilter);

//           case "gender":
//             formattedFilter.key = "gender";
//             formattedFilter.operation = "EQUALS";
//             formattedFilter.value = filter.filterOperationValue;

//             return formatedFilters.push(formattedFilter);
//         }
//       });
//     return formatedFilters;
//   }
// );

export const selectExternalContactsList = createSelector(
    selectCurrentListType,
    selectAllContacts,
    selectSelectedAudienceContacts,
    selectSelectedSegmentContacts,
    (
        listType,
        allContacts,
        selectedAudienceContacts,
        selectedSegmentContacts
    ) => {
      if (listType && allContacts) {
        switch (listType) {
          case "all":
            return Object.values(allContacts)
            .filter((contact) => contact.selected === true)
            .map((contact) => contact.id);

          case "segments":
            return (
                selectedSegmentContacts &&
                Object.values(selectedSegmentContacts)
                .filter((contact) => contact.selected === true)
                .map((contact) => contact.id)
            );

          case "audiences":
            return (
                selectedAudienceContacts &&
                Object.values(selectedAudienceContacts)
                .filter((contact) => contact.selected === true)
                .map((contact) => contact.id)
            );
        }
      }
    }
);

//IMPORT CONTACTS LIST

export const selectImportContactsListSlice = createSelector(
    (state) => state[REDUCER_NAME]["importContactsList"],
    (state) => state
);
export const selectCurrentExcel = createSelector(
    selectImportContactsListSlice,
    _get("currentExcel")
);

export const selectImportedListHeaders = createSelector(
    selectImportContactsListSlice,
    _get("importedListHeaders")
);

export const selectImportedListContacts = createSelector(
    selectImportContactsListSlice,
    _get("importedListContacts")
);
