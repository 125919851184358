import React from "react";
import styled from "@emotion/styled";

const DoneButtonWrapper = styled.div`
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  background-color: #ea5454;
  border-radius: 6px;
  padding: 3px 0px;
  transform: ${(props) => (props.disabled ? "scale(0.95)" : "scale(1)")};
  opacity: ${(props) => (props.disabled ? 0.8 : 1)};

  transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1);

  label {
    color: white;
  }
`;

const DoneButton = (props) => {
  const { disabled, onClick } = props;
  return (
    <DoneButtonWrapper disabled={disabled} onClick={onClick}>
      <label>Done</label>
    </DoneButtonWrapper>
  );
};

export default DoneButton;
