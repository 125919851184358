import React, {useEffect, useState} from "react";
import {Button, Grid, Switch} from "@mui/material";
import {AutomationSummary} from "../types";
import {DataGrid, GridColDef, GridRenderCellParams, GridRowId} from '@mui/x-data-grid';
import {getAutomations, toggleStatus} from "../AutomationService";
import {useHistory} from "react-router-dom";
import DeleteAutomationDialog from "./DeleteAutomationDialog";
import {ampli} from "../../../containers/Amplitude/ampli";

type Props = {
  organisationId: string,
}

const AutomationList = ({organisationId}: Props) => {
  const history = useHistory();

  const [automations, setAutomations] = useState<AutomationSummary[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [disableEdit, setDisableEdit] = useState<boolean>(true);

  useEffect(() => {
    fetchAutomations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  const handleToggleStatus = (e: any, params: GridRenderCellParams) => {
    const status = params.value === 'OFF' ? 'LIVE' : 'OFF';
    ampli.unaroPageVisit(`page visit: Automation -> Toggle Automation: ${status}`);

    e.stopPropagation();
    toggleStatus(params.row.id)
    .subscribe({
      next: () => {
        fetchAutomations();
      },
      error: (err: any) => {
        console.error("Error toggle automation")
        console.error(err);
      }
    })
  }

  const fetchAutomations = () => {
    setLoading(true);
    getAutomations(organisationId)
    .subscribe({
      next: (response: AutomationSummary[]) => {
        setAutomations(response);
      },
      error: (err: any) => {
        setLoading(false);
        console.error('Error loading automation list');
        console.error(err);
      },
      complete: () => {
        setLoading(false);
      }
    });
  }

  const columns: GridColDef[] = [
        {
          field: 'id',
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 200
        },
        {
          field: 'contacts',
          headerName: 'Contacts',
          width: 100
        },
        {
          field: 'description',
          headerName: 'Description',
          width: 300
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 100,
          renderCell: (params: GridRenderCellParams) => {
            return (
                <Switch
                    checked={params.row.status === 'LIVE'}
                    onChange={(e) => handleToggleStatus(e, params)}
                />
            )
          }
        },
      ]
  ;

  return (
      <Grid container>
        <Grid item marginY={3}>
          <Button
              variant='outlined'
              onClick={() => history.push('/automation/new')}
          >
            Add
          </Button>

          <Button
              variant='outlined'
              disabled={disableEdit}
              onClick={() => history.push(`/automation/edit/${selected[0]}`)}
              sx={{marginLeft: '30px'}}
          >
            Edit
          </Button>

          <Button
              variant='outlined'
              disabled={disableEdit}
              onClick={() => history.push(`/automation/copy/${selected[0]}`)}
              sx={{marginLeft: '30px'}}
          >
            Copy
          </Button>

          <DeleteAutomationDialog
              selected={selected}
              fetchAutomations={fetchAutomations}
              sx={{marginLeft: '30px'}}
          />
        </Grid>
        <Grid
            item
            style={{
              height: 500,
              width: '100%'
            }}>
          <DataGrid
              columns={columns}
              rows={automations}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false
                  }
                }
              }}
              onSelectionModelChange={(ids: GridRowId[]) => {
                const selectedIds = ids.map((g: GridRowId) => g.toLocaleString());
                setSelected(selectedIds);
                setDisableEdit(selectedIds?.length !== 1);
              }}
          />
        </Grid>
      </Grid>
  );
}

export default AutomationList;
