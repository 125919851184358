export const chartDefaults = {
  label: "Visits",
  fill: true,
  lineTension: 0.4,
  backgroundColor: "#ffcc33",
  borderColor: "#ffcc33",
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "#ffcc33",
  pointBackgroundColor: "#fff",
  pointBorderWidth: 5,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: "#ffcc33",
  pointHoverBorderColor: "#ffcc33",
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

export const generateOptions = (maxVal) => {
  return {
    elements: {
      point: {
        radius: 0,
      },
    },
    fill: true,
    lineTension: 0.4,
    backgroundColor: "#ff3311",
    borderColor: "#ffcc33",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: "#ffcc33",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 50,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: "#ffcc33",
    pointHoverBorderColor: "#ffcc33",
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    responsive: true,
    maintainAspectRatio: false,
    redraw: true,
    // legend: {
    //   display: statistics.length > 1 ? true : false,
    // },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            stepValue: 1,
            max: maxVal + 2,
            precision: 0,
            stepSize: 1,
            fixedStepSize: 1,
          },
        },
      ],
    },
  };
};

export const colors = [
  {
    zero: "rgba(254, 203, 69, 0.75)",
    half: "rgba(254, 203, 69, 0.5)",
    one: "rgba(254, 203, 69, 0)",
  },
  {
    zero: "rgba(242, 151, 57, 0.75)",
    half: "rgba(242, 151, 57, 0.5)",
    one: "rgba(242, 151, 57, 0)",
  },
  {
    zero: "rgba(232, 85, 88, 0.75)",
    half: "rgba(232, 85, 88, 0.5)",
    one: "rgba(232, 85, 88, 0)",
  },
  {
    zero: "rgba(222, 65, 70, 0.75)",
    half: "rgba(222, 65, 70, 0.5)",
    one: "rgba(222, 65, 70, 0)",
  },
  {
    zero: "rgba(0, 0, 0, 0.75)",
    half: "rgba(0, 0, 0, 0.5)",
    one: "rgba(0, 0, 0, 0)",
  },
];

export const defaultChartDropdownButtons = [
  {
    id: 0,
    index: 0,
    text: "Day",
    unit: "day",
    selected: false,
  },
  {
    id: 1,
    index: 1,
    text: "Week",
    unit: "week",
    selected: false,
  },
  {
    id: 2,
    index: 2,
    text: "Month",
    unit: "month",
    selected: true,
  },
  {
    id: 3,
    index: 3,
    text: "Year",
    unit: "year",
    selected: false,
  },
];
