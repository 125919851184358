import React from "react";
import styledReact from "styled-components";
import AvatarMui from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const Grid = styledReact.div``;
const Row = styledReact.div`
  display: flex;
`;
const Col = styledReact.div`
  flex: ${(props) => props.size};
`;

const stringAvatar = (name) => {
  let firstNameInitial = "";
  let lastNameInitial = "";

  let firstName = `${name.split(" ")[0]}`;
  let lastName = `${name.split(" ")[1]}`;

  if (firstName) {
    firstNameInitial = `${name.split(" ")[0][0]}`;
  }

  if (lastName) {
    lastNameInitial = `${name.split(" ")[1][0]}`;
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${firstNameInitial}${lastNameInitial}`,
  };
};

const stringToColor = (string) => {
  let color = "";
  let colors = ["#FF9933", "#EA5454", "#FFCC33"];
  let initalAToJ = /^[a-jA-J]+$/.test(string.charAt(0));
  let initialKToT = /^[k-tK-T]+$/.test(string.charAt(0));
  let initialUToZ = /^[u-zU-Z]+$/.test(string.charAt(0));

  if (initalAToJ) color = colors[0];
  if (initialKToT) color = colors[1];
  if (initialUToZ) color = colors[2];
  return color;
};

const ContactListAvatar = ({ name }) => {
  return (
    <Grid>
      <Row>
        <Col size={1}>
          <AvatarMui
            style={{ height: "30px", width: "30px" }}
            {...stringAvatar(name)}
          />
        </Col>
        <Col size={2}>
          <Typography
            style={{ fontSize: "14px", marginLeft: "10px", marginTop: "6px" }}
            component="legend"
          >
            {name}
          </Typography>
        </Col>
      </Row>
    </Grid>
  );
};

export default ContactListAvatar;
