import React, { useState } from 'react'

import '../../../../assets/css/sms/campaign/create/create-sender-popup.scss'

export default ({ close, save }) => {

    const [sender, setSender] = useState({})
    const [errors, setErrors] = useState([])
    const [errorMessage, setErrorMessage] = useState()

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    const changeName = value => {
        setErrors(errors.filter(error => error !== 'name'))
        setErrorMessage(null)
        setSender({ ...sender, name: value })
    }

    const changePhone = value => {
        setErrors(errors.filter(error => error !== 'name'))
        setErrorMessage(null)
        setSender({ ...sender, phone: value })
    }

    const create = () => {
        var e = []
        if ((!sender.name || sender.name < 3) && (!sender.phone || sender.phone.length < 4))
            e = [...e, 'name']

        setErrors(e)

        if (e.length > 0) {
            setErrorMessage('Please fill one field')
        } else if (sender.name && (sender.name.length < 4 || sender.name.length > 10))
            setErrorMessage('Name must be between 3 and 11 characters long.')
        else {
            setErrorMessage(null)
            setErrors([])
            save(sender)
            close()
        }
    }

    return (
        <div className='add-sender-popup' onClick={onClick}>
            <div className='add-sender-popup-content'>
                <div className='add-sender-top'>
                    <label>Add Sender Id</label>
                    <img alt="delete" src={require('../../../../assets/images/delete.svg').default} onClick={close} />
                </div>
                <div className='add-sender-content'>
                    <label>Name</label>
                    <input autoFocus value={sender.name || ''} onChange={e => changeName(e.target.value)} className={`${errors.length > 0 && errors.includes('name') && 'input-error'}`} />
                    <label>Phone</label>
                    <input value={sender.phone || ''} onChange={e => changePhone(e.target.value)} className={`${errors.length > 0 && errors.includes('name') && 'input-error'}`} />
                </div>
                {errorMessage && <label className='error-message'>{errorMessage}</label>}
                <div className='actions'>
                    <button onClick={close}>Cancel</button>
                    <button onClick={create}>Create Sender</button>
                </div>
            </div>
        </div>
    )
}