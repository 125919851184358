import actions from "./actions";

const {
  GET_VISITS_AND_SIGNUPS_REQUEST,
  GET_VISITS_AND_SIGNUPS_FAILURE,
  GET_VISITS_AND_SIGNUPS_SUCCESS,
  GET_YEAR_VISITS_AND_SIGNUPS_REQUEST,
  GET_YEAR_VISITS_AND_SIGNUPS_FAILURE,
  GET_YEAR_VISITS_AND_SIGNUPS_SUCCESS,
  GET_MONTH_VISITS_AND_SIGNUPS_REQUEST,
  GET_MONTH_VISITS_AND_SIGNUPS_FAILURE,
  GET_MONTH_VISITS_AND_SIGNUPS_SUCCESS,
  GET_WEEK_VISITS_AND_SIGNUPS_REQUEST,
  GET_WEEK_VISITS_AND_SIGNUPS_FAILURE,
  GET_WEEK_VISITS_AND_SIGNUPS_SUCCESS,
  GET_DAY_VISITS_AND_SIGNUPS_REQUEST,
  GET_DAY_VISITS_AND_SIGNUPS_FAILURE,
  GET_DAY_VISITS_AND_SIGNUPS_SUCCESS,
  // GET_HOURLY_VISITS_AND_SIGNUPS_REQUEST,
  // GET_HOURLY_VISITS_AND_SIGNUPS_FAILURE,
  // GET_HOURLY_VISITS_AND_SIGNUPS_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  // currentTimeUnit: "monthly",
  allVisitsAndSignups: null,
  // hourVisitsAndSignups: null,
  dayVisitsAndSignups: null,
  weekVisitsAndSignups: null,
  monthVisitsAndSignups: null,
  yearVisitsAndSignups: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    //---

    case GET_VISITS_AND_SIGNUPS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        allVisitsAndSignups: null,
      };
    }

    case GET_VISITS_AND_SIGNUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        allVisitsAndSignups: null,
      };
    }

    case GET_VISITS_AND_SIGNUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allVisitsAndSignups: action.payload,
      };
    }

    //--- YEAR

    case GET_YEAR_VISITS_AND_SIGNUPS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        yearVisitsAndSignups: null,
      };
    }

    case GET_YEAR_VISITS_AND_SIGNUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        yearVisitsAndSignups: null,
      };
    }

    case GET_YEAR_VISITS_AND_SIGNUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        yearVisitsAndSignups: action.payload,
      };
    }

    //--- MONTH

    case GET_MONTH_VISITS_AND_SIGNUPS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        monthVisitsAndSignups: null,
      };
    }

    case GET_MONTH_VISITS_AND_SIGNUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        monthVisitsAndSignups: null,
      };
    }

    case GET_MONTH_VISITS_AND_SIGNUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        monthVisitsAndSignups: action.payload,
      };
    }

    //--- WEEK

    case GET_WEEK_VISITS_AND_SIGNUPS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        weekVisitsAndSignups: null,
      };
    }

    case GET_WEEK_VISITS_AND_SIGNUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        weekVisitsAndSignups: null,
      };
    }

    case GET_WEEK_VISITS_AND_SIGNUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        weekVisitsAndSignups: action.payload,
      };
    }
    // --- DAY

    case GET_DAY_VISITS_AND_SIGNUPS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        dayVisitsAndSignups: null,
      };
    }

    case GET_DAY_VISITS_AND_SIGNUPS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        dayVisitsAndSignups: null,
      };
    }

    case GET_DAY_VISITS_AND_SIGNUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        dayVisitsAndSignups: action.payload,
      };
    }

        // --- HOURLY

        // case GET_HOURLY_VISITS_AND_SIGNUPS_REQUEST: {
        //   return {
        //     ...state,
        //     loading: true,
        //     errorMessage: null,
        //     hourlyVisitsAndSignups: null,
        //   };
        // }
    
        // case GET_HOURLY_VISITS_AND_SIGNUPS_FAILURE: {
        //   return {
        //     ...state,
        //     loading: false,
        //     errorMessage: action.payload.errorMessage,
        //     hourlyVisitsAndSignups: null,
        //   };
        // }
    
        // case GET_HOURLY_VISITS_AND_SIGNUPS_SUCCESS: {
        //   return {
        //     ...state,
        //     loading: false,
        //     errorMessage: null,
        //     hourlyVisitsAndSignups: action.payload,
        //   };
        // }

    default:
      return state;
  }
}
