import styled from "@emotion/styled";

const TemplatePreviewItemWrapper = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 180px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  padding: 10px;
  overflow-y: scroll;
  transform: ${(props) => (props.isHover ? "scale(1.05)" : "scale(1)")};
  box-shadow: ${(props) =>
    props.isHover
      ? "0px 2px 4px rgba(0, 0, 0, 0.24)"
      : "0px 1px 3px rgba(0, 0, 0, 0.14)"};
  border: ${(props) =>
    props.isHover ? "3px solid rgba(247, 146, 30, 0.3)" : "none"};

  ::-webkit-scrollbar {
    background: ${(props) => (props.isHover ? "auto" : "transparent")};
  }

  transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1);
`;

export default TemplatePreviewItemWrapper;
