import React, {useState} from "react";
import "assets/css/quick-send.scss";
import {
  Backdrop,
  Button,
  Fade,
  Modal
} from "@mui/material";
import group41Png from "../../assets/images/Group41.png";
import smsIcon from "../../assets/images/sms-icon.png";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import makeStyles from "@mui/styles/makeStyles";
import styled from "@emotion/styled";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '25px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
}));

const ConfirmationPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 500px;
  height: 300px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const Group11446 = styled.div`
  width: 96px;
  height: 96px;
`;

const WarningWrapper = styled.div`
  position: relative;
  width: 96px;
  height: 16px;
  top: 0px;
`;

const TypographyText = styled.div`
  height: 64px;
  top: 140.81px;
  font-family: 'Trebuchet MS', sans-serif' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  line-height: 133.4% !important;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-left: 8%;
  gap: 24px;
  color: #ff9933;
  width: 120px;
  height: 42px;
`;

const QuickSend = (props) => {
  const {activeLicenses} = props;
  const classes = useStyles();
  const [openNoLicense, setOpenNoLicense] = useState(true);

  const handleCloseNoLicense = () => {
    setOpenNoLicense(false);
  };

  return (
      <div className="quick-send">
        {/*NO SMS LICENSE*/}
        {(activeLicenses.includes("SMS")) && (
            <>
              <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={openNoLicense}
                  onClose={handleCloseNoLicense}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
              >
                <Fade in={openNoLicense}>
                  <div className={classes.paper}>
                    <img
                        alt="group"
                        onClick={handleCloseNoLicense}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <ConfirmationPlaceholder>
                      <Group11446>
                        <WarningWrapper>
                          <div>
                            <img
                                alt="group"
                                className={"item-logo"}
                                src={smsIcon}
                            />
                          </div>
                        </WarningWrapper>
                      </Group11446>
                      <TypographyText>
                        Sorry, SMS isn't part of your Unaro Package...
                      </TypographyText>
                      <ButtonSection>
                        <div>
                          <Button
                              size={"small"}
                              onClick={handleCloseNoLicense}
                              endIcon={<ArrowRightRoundedIcon
                                  sx={{fontSize: '80px', fontWeight: '400'}}/>}
                              sx={{
                                color: "#FFFFF",
                                fontSize: 16,
                                fontWeight: "500",
                                zIndex: 1,
                                marginTop: 5,
                                marginRight: 4,
                                borderRadius: 16,
                                width: '200px',
                                backgroundColor: "#FF9933"
                              }}
                              variant="contained"
                          >
                            UPGRADE NOW
                          </Button>
                        </div>
                      </ButtonSection>
                    </ConfirmationPlaceholder>
                  </div>
                </Fade>
              </Modal>
            </>
        )}
      </div>
  );
};

export default QuickSend;
