import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  errorMessage: null,
  isUserLoggedIn: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
        isUserLoggedIn: false,
        errorMessage: null,
      };
    }

    case LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        isUserLoggedIn: false,
        errorMessage: action.payload,
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        isUserLoggedIn: true,
      };
    }

    //---

    case LOGOUT_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case LOGOUT_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        isUserLoggedIn: false,
      };
    }

    default:
      return state;
  }
}
