import React from "react";

import { ReactComponent as RedirectIcon } from "assets/images/customRedirectIcon.svg";

import "assets/css/design/screens/custom-url-redirect-screen.scss";

const CustomURLRedirectScree = (props) => {
  return (
    <div className="custom-url-redirect">
      <div className="custom-url-redirect__content">
        <RedirectIcon />
        <label>Custom url redirect</label>
      </div>
    </div>
  );
};

export default CustomURLRedirectScree;
