import React, {useState, useEffect} from "react";
import styled from "@emotion/styled";
import uuid from "react-uuid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  inputLabel: {
    "&$inputFocused": {
      color: "orange"
    }
  },
  inputFocused: {},
  select: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FF9933',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    background: "red",
    color: "white"
  },
  input: {
    color: "red",
    backgroundColor: "rgba(255, 0, 0, 0.4)",
    "&:focus": {
      borderRadius: 4,
      borderColor: "rgba(255,255,255,0.2)",
      boxShadow: "0 0 0 0.2rem rgba(0,190,255,0.6)",
      background: "rgba(0,0,0,0)"
    }
  }
}));

const AudiencesLabelsItemWrapper = styled.div`
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 20px;
  width: 55px;
  height: 15px;
  margin: 0;
  font-family: Roboto;
  font-size: 9px;
  margin-right: 5px;
  padding: 3px 7px;
  opacity: ${(props) => (props.isThisItemSelected ? 1 : 0.6)};
  transform: ${(props) =>
    props.isThisItemSelected ? "scale(1) " : "scale(0.95) "};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: inline-block;

  transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
`;

const AudiencesLabelsItem = (props) => {
  const {
    audience,
    selectedAudiencesIds,
    setSelectedAudiencesIds,
    change
  } = props;
  const {id, name, color, organisationId} = audience;

  const [isThisItemSelected, setIsThisItemSelected] = React.useState(false);

  React.useEffect(() => {
    selectedAudiencesIds[id] && selectedAudiencesIds[id] === true
        ? setIsThisItemSelected(true)
        : setIsThisItemSelected(false);
  }, [selectedAudiencesIds]);

  const formattedName =
      name && name.length > 10 ? name.substring(0, 8) + "..." : name;

  const handleSelect = (id) => {
    if (!selectedAudiencesIds[id]) {
      setSelectedAudiencesIds({...selectedAudiencesIds, [id]: true});
    } else {
      setSelectedAudiencesIds({...selectedAudiencesIds, [id]: false});
    }
  };

  return (
      audience && (
          <AudiencesLabelsItemWrapper
              isThisItemSelected={isThisItemSelected}
              onClick={() => handleSelect(audience.id)}
              color={color}
          >
            {formattedName ? formattedName : "unknown"}
          </AudiencesLabelsItemWrapper>
      )
  );
};

const ChooseAudienceInput = (props) => {
  const {
    allAudiences,
    change,
    selectedAudiencesIds,
    setSelectedAudiencesIds,
  } = props;

  const classes = useStyles();
  const [audienceNames, setAudienceNames] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [audienceName, setAudienceName] = React.useState([]);

  if (allAudiences !== null) {
    Object.values(allAudiences).forEach((audience) => {
      if (audienceNames.includes(audience.name) === false) {
        setAudienceNames([...audienceNames, audience.name])
        setAudiences([...audiences, audience])
      }
    });
  }

  const handleSelect = (event) => {
    let name = event.target.value[event.target.value.length - 1];
    if (name === undefined) {
      setSelectedAudiencesIds([]);
    } else {
      audiences.map((item, idx) => {
        if (item.name === name) {
          if (!selectedAudiencesIds[item.id]) {
            setSelectedAudiencesIds({...selectedAudiencesIds, [item.id]: true});
          } else {
            setSelectedAudiencesIds(
                {...selectedAudiencesIds, [item.id]: false});
          }
        }
      });
    }
    setAudienceName(event.target.value)
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
      <>
        <FormControl sx={{width: '100%'}} className={classes.formControl}>
          <InputLabel shrink={true}
                      classes={{focused: classes.inputFocused}}
                      className={classes.inputLabel}
          >Audience</InputLabel>
          <Select
              className={classes.select}
              SelectDisplayProps={{
                style: {
                  paddingTop: 8,
                  paddingBottom: 8,
                  border: 'none'
                }
              }}
              variant="outlined"
              label="Audience"
              style={{width: '100%', borderRadius: '19px'}}
              InputLabelProps={{shrink: true}}
              notched={true}
              multiple
              value={audienceName}
              onChange={handleSelect}
              // input={<OutlinedInput label="Audience"/>}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              sx={{
                "&:hover": {
                  "&& fieldset": {
                    borderColor: "#FF9933"
                  }
                }
              }}
          >
            {audiences.map((audience) => (
                <MenuItem key={uuid()} value={audience.name}>
                  <Checkbox checked={audienceName.indexOf(audience.name) > -1}/>
                  <ListItemText primary={audience.name}/>
                </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
  );
};

export default ChooseAudienceInput;