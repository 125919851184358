import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import {compose} from "recompose";
import ContactsListContainer from "containers/ContactsList";
import ContactsListPanels from "./ContactsListPanels";
import ContactsListTable from "./ContactsListTable";
import {from, map, Observable} from "rxjs";
import {PAGE_SIZE, search} from "../../containers/ContactsList/api";

const ContactsListWrapper = styled.div`
  height: 100%;
  overflow-x: hidden;
  display: grid;
  /* grid-template-rows: minmax(35vh, 35vh) minmax(35vh, 35vh); */
  grid-template-rows: minmax(35vh, 35vh) minmax(35vh, 35vh);
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 26px;
  grid-row-gap: 26px;
`;

const getContactIds = (
        organisationId,
        type,
        requestId,
        criteria) => {
      return from(
          search({
            organisationId: organisationId,
            type: type,
            requestId: requestId,
            criteria: criteria,
          })
      ).pipe(
          map((response) => {
            let ids = {};
            if (response?.data?.content) {
              response.data.content.forEach(
                  (c) => (ids[c.id] = {selected: true})
              );
            }
            return ids;
          })
      );
    }
;

const ContactsList = (props) => {
  const {
    getAllContactsRequest,
    getSegmentsRequest,
    getAudiencesRequest,
    organisationId,
    deletedSegmentId,
    currentFilters,
    updateExternalContacts,
    createdContact,
    createdSegment,
    updateCampaign,
    getSegmentContactsRequest,
    getAudienceContactsRequest,
    currentListType,
    selectedSegment,
    selectedAudience,
    selectSegmentRequest,
    selectListTypeRequest
  } = props;

  const [isAllContactsSelected, setIsAllContactsSelected] = useState(false);
  const [selectedContactsIds, setSelectedContactsIds] = useState([]);
  const [allContactsIds, setAllContactsIds] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [page, setPage] = useState(0);
  const [currentSelectedSegmentId, setCurrentSelectedSegmentId] = useState(
      "segment-all-contacts"
  );

  const getAllContactIds = () => {
    if (currentListType) {
      let contactIds$;
      switch (currentListType) {
        case "all":
          contactIds$ = getContactIds(
              organisationId,
              "ALL",
              null,
              currentFilters
          );
          break;
        case "segments":
          contactIds$ = getContactIds(
              organisationId,
              "SEGMENT",
              selectedSegment.id,
              currentFilters
          );
          break;
        case "audiences":
          contactIds$ = getContactIds(
              organisationId,
              "AUDIENCE",
              selectedAudience.id
          );
          break;
      }

      if (contactIds$) {
        contactIds$.subscribe((data) => {
          setAllContactsIds(data);
          //setSelectedContactsIds(Object.keys(data));
        });
      }
    }
  };

  const loadContacts = (optionalPage) => {
    const page = optionalPage ? optionalPage : 0;
    const type = currentListType ? currentListType : "all";
    switch (type) {
      case "all":
        getAllContactsRequest(
            organisationId,
            page,
            PAGE_SIZE,
            searchQuery,
            currentFilters
        );
        break;
      case "segments":
        getSegmentContactsRequest(
            organisationId,
            selectedSegment.id,
            page,
            PAGE_SIZE,
            searchQuery,
            currentFilters
        );
        break;
      case "audiences":
        getAudienceContactsRequest(
            organisationId,
            selectedAudience.id,
            page,
            PAGE_SIZE,
            searchQuery
        );
        break;
    }
  };

  useEffect(() => {
    loadContacts();
    getAllContactIds();
  }, [currentFilters]);

  useEffect(() => {
    loadContacts(page);
  }, [page]);

  useEffect(() => {
    loadContacts();
    getAllContactIds();
    setPage(0);
  }, [currentListType, selectedSegment, selectedAudience, searchQuery]);

  useEffect(() => {
    if (organisationId) {
      getAllContactsRequest(organisationId);
      getSegmentsRequest(organisationId);
      getAudiencesRequest(organisationId);
      setPage(0);
    }
  }, [organisationId]);

  useEffect(() => {
    updateCampaign &&
    updateExternalContacts &&
    updateExternalContacts(selectedContactsIds);
  }, [selectedContactsIds]);

  useEffect(() => {
    if (organisationId) {
      getAllContactsRequest(organisationId);
    }
  }, [createdContact]);

  useEffect(() => {
    createdSegment && getSegmentsRequest(organisationId);
  }, [createdSegment]);

  useEffect(() => {
    deletedSegmentId && getSegmentsRequest(organisationId);
  }, [deletedSegmentId]);

  return (
      <ContactsListWrapper>
        <ContactsListTable
            {...props}
            organisationId={organisationId}
            loadContacts={loadContacts}
            setSearchQuery={setSearchQuery}
            setSelectedContactsIds={setSelectedContactsIds}
            selectedContactsIds={selectedContactsIds}
            setCurrentSelectedSegmentId={setCurrentSelectedSegmentId}
            getAllContactIds={getAllContactIds}
            allContactsIds={allContactsIds}
            setPage={setPage}
            page={page}
            isAllContactsSelected={isAllContactsSelected}
            setIsAllContactsSelected={setIsAllContactsSelected}
            getSegmentsRequest={getSegmentsRequest}
            selectSegmentRequest={selectSegmentRequest}
            getAllContactsRequest={getAllContactsRequest}
            selectListTypeRequest={selectListTypeRequest}
        />
        <ContactsListPanels
            {...props}
            organisationId={organisationId}
            setSelectedContactsIds={setSelectedContactsIds}
            selectedContactsIds={selectedContactsIds}
            setCurrentSelectedSegmentId={setCurrentSelectedSegmentId}
            currentSelectedSegmentId={currentSelectedSegmentId}
            allContactsIds={allContactsIds}
            searchQuery={searchQuery}
            setPage={setPage}
            isAllContactsSelected={isAllContactsSelected}
            setIsAllContactsSelected={setIsAllContactsSelected}
        />

      </ContactsListWrapper>
  );
};

export default compose(ContactsListContainer)(ContactsList);
