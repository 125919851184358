import { createTheme } from "@mui/material/styles";

const arcGreen = "#1EE107FF";
const arcRed = "#D00D0DFF";

export default createTheme({
    typography: {
        h3: {
            fontWeight: 100,
        },
    },
    palette: {
        paper: {
            primary: `${arcGreen}`,
            secondary: `${arcRed}`,
        },
    },
});
