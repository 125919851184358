import React from "react";

import "assets/css/widget/page-selector.scss";
import uuid from "react-uuid";

const PageNavigator = (props) => {
  const {pageSelected, setPageSelected, totalPages} = props;

  const [numbers, setNumbers] = React.useState(null);

  React.useEffect(() => {
    const generatedNumbers =
        totalPages > 0
            ? Array.from(Array(totalPages).keys()).map((item) => item + 1)
            : null;

    setNumbers(generatedNumbers);
  }, [totalPages]);

  return (
      numbers &&
      numbers.length > 1 && (
          <div className="page-selector">
            <div className="page-selector__page-toggle">
              {numbers.map((page) => (
                  <p key={uuid()}
                     style={
                       pageSelected === page
                           ? {
                             backgroundColor: "#ea5454",
                             color: "white",
                           }
                           : {}
                     }
                     onClick={() => setPageSelected(page)}
                     className="page-selector__page-number"
                  >
                    {page}
                  </p>
              ))}
            </div>
          </div>
      )
  );
};

export default PageNavigator;
