import actions from "../actions";

const { GET_CONTACTS_REQUEST, GET_CONTACTS_FAILURE, GET_CONTACTS_SUCCESS } =
  actions;

const initialState = {
  loading: false,
  errorMessage: null,
};

export default function trackAndTraceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTACTS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    }

    default:
      return state;
  }
}
