import {forkJoin, from, map, Observable} from "rxjs"
import api from "../../utils/api";
import {AxiosResponse} from "axios";
import {Action, AutomationRequest, AutomationSummary, ValidateActionResponse} from "./types";

export const titleCase = (str: string) =>
    str ? `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}` : '';

export const getAutomations = (organisationId: string): Observable<AutomationSummary[]> => {
  return from(api.get(`/automation/organisation/${organisationId}`))
  .pipe(map((response: AxiosResponse<AutomationSummary[]>) => response.data));
}

export const getAutomation = (automationId: string): Observable<AutomationRequest> => {
  return from(api.get(`/automation/${automationId}`))
  .pipe(map((response: AxiosResponse<AutomationRequest>) => response.data));
}

export const createAutomation = (request: AutomationRequest): Observable<AutomationSummary> => {
  return from(api.post('/automation', request))
  .pipe(map((response: AxiosResponse<AutomationSummary>) => response.data));
}

export const deleteAutomation = (automationIds: string[]): Observable<any> => {
  const observables: Observable<any>[] =
      automationIds.map((id: string) => from(api.delete(`/automation/${id}`)));
  return forkJoin(observables);
}

export const toggleStatus = (automationId: string): Observable<string> => {
  return from(api.post(`/automation/${automationId}/toggleStatus`))
  .pipe(map((response: AxiosResponse<string>) => response.data));
}
