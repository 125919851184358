
import {
  GET_EMAILS_STATS_REQUEST,
  GET_EMAILS_STATS_FAILURE,
  GET_EMAILS_STATS_SUCCESS,
  GET_YEAR_OPEN_RATE_STATS_REQUEST,
  GET_YEAR_OPEN_RATE_STATS_FAILURE,
  GET_YEAR_OPEN_RATE_STATS_SUCCESS,
  GET_MONTH_OPEN_RATE_STATS_REQUEST,
  GET_MONTH_OPEN_RATE_STATS_FAILURE,
  GET_MONTH_OPEN_RATE_STATS_SUCCESS,
  GET_WEEK_OPEN_RATE_STATS_REQUEST,
  GET_WEEK_OPEN_RATE_STATS_FAILURE,
  GET_WEEK_OPEN_RATE_STATS_SUCCESS,
  GET_DAY_OPEN_RATE_STATS_REQUEST,
  GET_DAY_OPEN_RATE_STATS_FAILURE,
  GET_DAY_OPEN_RATE_STATS_SUCCESS,
} from "../actions";

const initialState = {
  loading: false,
  errorMessage: null,
  emailsStats: null,
  yearOpenRateStats: null,
  monthOpenRateStats: null,
  weekOpenRateStats: null,
  dayOpenRateStats: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMAILS_STATS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        emailsStats: null,
      };
    }

    case GET_EMAILS_STATS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        emailsStats: null,
      };
    }

    case GET_EMAILS_STATS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        emailsStats: action.payload,
      };
    }

    // YEAR

    case GET_YEAR_OPEN_RATE_STATS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        yearOpenRateStats: null,
      };
    }

    case GET_YEAR_OPEN_RATE_STATS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        yearOpenRateStats: null,
      };
    }

    case GET_YEAR_OPEN_RATE_STATS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        yearOpenRateStats: action.payload,
      };
    }

    // MONTH

    case GET_MONTH_OPEN_RATE_STATS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        monthOpenRateStats: null,
      };
    }

    case GET_MONTH_OPEN_RATE_STATS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        monthOpenRateStats: null,
      };
    }

    case GET_MONTH_OPEN_RATE_STATS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        monthOpenRateStats: action.payload,
      };
    }

    // WEEK

    case GET_WEEK_OPEN_RATE_STATS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        weekOpenRateStats: null,
      };
    }

    case GET_WEEK_OPEN_RATE_STATS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        weekOpenRateStats: null,
      };
    }

    case GET_WEEK_OPEN_RATE_STATS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        weekOpenRateStats: action.payload,
      };
    }

    // DAY

    case GET_DAY_OPEN_RATE_STATS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        dayOpenRateStats: null,
      };
    }

    case GET_DAY_OPEN_RATE_STATS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        dayOpenRateStats: null,
      };
    }

    case GET_DAY_OPEN_RATE_STATS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        dayOpenRateStats: action.payload,
      };
    }

    default:
      return state;
  }
}
