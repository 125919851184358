import React from "react";

import "assets/css/customers/visit-customer.scss";

const VisitCustomer = (props) => {
  const { customer } = props;
  const getAvatar = (customer) => {
    if (customer.avatarUrl) {
      if (customer.avatarUrl.includes("facebook"))
        return `${customer.avatarUrl}?width=300&height=300`;
      else return customer.avatarUrl;
    }
    return require("assets/images/person-icon.svg");
  };

  return (
    <div className="visit-customer">
      <img src={getAvatar(customer)} alt="" />
      <div className="visit-customer-right">
        <label className="visit-customer-name">
          {customer.forename} {customer.surname}
        </label>
        <hr />
        <label className="visit-customer-email">{customer.email}</label>
      </div>
    </div>
  );
};

export default VisitCustomer;
