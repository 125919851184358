import React from "react";

import { useSelector } from "react-redux";

import { REDUCER_NAME as userReducerName } from "containers/User/consts";

const useUserOrganisation = () => {
  const [userOrganisation, setUserOrganisation] = React.useState();

  const userOrganisationState = useSelector(
    (state) => state[userReducerName]["userOrganisation"]
  );

  React.useEffect(() => {
    setUserOrganisation(userOrganisationState);
  }, [userOrganisationState]);

  if (!userOrganisation) {
    return {};
  }

  return userOrganisation;
};

export default useUserOrganisation;
