export const START_LANDING_PAGE_REQUEST = "START_LANDING_PAGE_REQUEST";
export const START_LANDING_PAGE_FAILURE = "START_LANDING_PAGE_FAILURE";
export const START_LANDING_PAGE_SUCCESS = "START_LANDING_PAGE_SUCCESS";

export const GET_LANDING_PAGE_REQUEST = "GET_LANDING_PAGE_REQUEST";
export const GET_LANDING_PAGE_FAILURE = "GET_LANDING_PAGE_FAILURE";
export const GET_LANDING_PAGE_SUCCESS = "GET_LANDING_PAGE_SUCCESS";

export const GET_LANDING_PAGES_REQUEST = "GET_LANDING_PAGES_REQUEST";
export const GET_LANDING_PAGES_FAILURE = "GET_LANDING_PAGES_FAILURE";
export const GET_LANDING_PAGES_SUCCESS = "GET_LANDING_PAGES_SUCCESS";

export const UPDATE_LANDING_PAGE_STEP_REQUEST =
    "UPDATE_LANDING_PAGE_STEP_REQUEST";
export const UPDATE_LANDING_PAGE_STEP_FAILURE =
    "UPDATE_LANDING_PAGE_STEP_FAILURE";
export const UPDATE_LANDING_PAGE_STEP_SUCCESS =
    "UPDATE_LANDING_PAGE_STEP_SUCCESS";

export const UPDATE_LANDING_PAGE_REQUEST = "UPDATE_LANDING_PAGE_REQUEST";
export const UPDATE_LANDING_PAGE_FAILURE = "UPDATE_LANDING_PAGE_FAILURE";
export const UPDATE_LANDING_PAGE_SUCCESS = "UPDATE_LANDING_PAGE_SUCCESS";

export const ACTIVATE_LANDING_PAGE_REQUEST = "ACTIVATE_LANDING_PAGE_REQUEST";
export const ACTIVATE_LANDING_PAGE_FAILURE = "ACTIVATE_LANDING_PAGE_FAILURE";

export const DELETE_LANDING_PAGE_REQUEST = "DELETE_LANDING_PAGE_REQUEST";
export const DELETE_LANDING_PAGE_FAILURE = "DELETE_LANDING_PAGE_FAILURE";
export const DELETE_LANDING_PAGE_SUCCESS = "DELETE_LANDING_PAGE_SUCCESS";

export const SAVE_LANDING_PAGE_REQUEST = "SAVE_LANDING_PAGE_REQUEST";
export const SAVE_LANDING_PAGE_FAILURE = "SAVE_LANDING_PAGE_FAILURE";
export const SAVE_LANDING_PAGE_SUCCESS = "SAVE_LANDING_PAGE_SUCCESS";

export const UPLOAD_MEDIA_TO_HOST_REQUEST = "UPLOAD_MEDIA_TO_HOST_REQUEST";
export const UPLOAD_MEDIA_TO_HOST_FAILURE = "UPLOAD_MEDIA_TO_HOST_FAILURE";
export const UPLOAD_MEDIA_TO_HOST_SUCCESS = "UPLOAD_MEDIA_TO_HOST_SUCCESS";

// --- START_LANDING_PAGE

export const startLandingPageRequest = (type, name, organisationId) => ({
  type: START_LANDING_PAGE_REQUEST,
  payload: {type, name, organisationId},
});

export const startLandingPageFailure = (errorMessage) => ({
  type: START_LANDING_PAGE_FAILURE,
  payload: errorMessage,
});

export const startLandingPageSuccess =
    (newLandingPageId, landingPageRequest) => {
      return {
        type: START_LANDING_PAGE_SUCCESS,
        payload: {
          newLandingPageId,
          landingPageRequest
        },
      };
    };

// --- UPDATE_LANDING_PAGE

export const updateLandingPageRequest = (
    landingPageField,
    landingPageData
) => ({
  type: UPDATE_LANDING_PAGE_REQUEST,
  payload: {landingPageField, landingPageData},
});

export const updateLandingPageFailure = (errorMessage) => ({
  type: UPDATE_LANDING_PAGE_FAILURE,
  payload: errorMessage,
});

export const updateLandingPageSuccess = (
    landingPageField,
    landingPageData
) => ({
  type: UPDATE_LANDING_PAGE_SUCCESS,
  payload: {landingPageField, landingPageData},
});

// --- UPDATE_LANDING_PAGE_STEP

export const updateLandingPageStepRequest = (step) => ({
  type: UPDATE_LANDING_PAGE_STEP_REQUEST,
  payload: step,
});

export const updateLandingPageStepFailure = (errorMessage) => ({
  type: UPDATE_LANDING_PAGE_STEP_FAILURE,
  payload: errorMessage,
});

export const updateLandingPageStepSuccess = (step) => ({
  type: UPDATE_LANDING_PAGE_STEP_SUCCESS,
  payload: step,
});

// --- ACTIVATE_LANDING_PAGE

export const activateLandingPageRequest = (
    organisationId,
    landingPageId,
    type
) => ({
  type: ACTIVATE_LANDING_PAGE_REQUEST,
  payload: {
    organisationId,
    landingPageId,
    type
  },
});

export const activateLandingPageFailure = (errorMessage) => ({
  type: ACTIVATE_LANDING_PAGE_FAILURE,
  payload: errorMessage,
});

// --- GET_LANDING_PAGE

export const getLandingPageRequest = (landingPageId) => ({
  type: GET_LANDING_PAGE_REQUEST,
  payload: landingPageId,
});

export const getLandingPageFailure = (errorMessage) => ({
  type: GET_LANDING_PAGE_FAILURE,
  payload: errorMessage,
});

export const getLandingPageSuccess = (currentLandingPage) => ({
  type: GET_LANDING_PAGE_SUCCESS,
  payload: currentLandingPage,
});

// --- GET_LANDING_PAGES

export const getLandingPagesRequest = (organisationId, type) => ({
  type: GET_LANDING_PAGES_REQUEST,
  payload: {organisationId, type},
});

export const getLandingPagesFailure = (errorMessage) => ({
  type: GET_LANDING_PAGES_FAILURE,
  payload: errorMessage,
});

export const getLandingPagesSuccess = (landingPages) => ({
  type: GET_LANDING_PAGES_SUCCESS,
  payload: landingPages,
});

// --- DELETE_LANDING_PAGE

export const deleteLandingPageRequest = (landingPageId) => ({
  type: DELETE_LANDING_PAGE_REQUEST,
  payload: landingPageId,
});

export const deleteLandingPageFailure = (errorMessage) => ({
  type: DELETE_LANDING_PAGE_FAILURE,
  payload: errorMessage,
});

export const deleteLandingPageSuccess = (deletedLandingPage) => ({
  type: DELETE_LANDING_PAGE_SUCCESS,
  payload: deletedLandingPage,
});

// --- SAVE_LANDING_PAGE

export const saveLandingPageRequest = (landingPageData) => {
  return {
    type: SAVE_LANDING_PAGE_REQUEST,
    payload: landingPageData,
  };
};

export const saveLandingPageFailure = (errorMessage) => ({
  type: SAVE_LANDING_PAGE_FAILURE,
  payload: errorMessage,
});

export const saveLandingPageSuccess = (landingPageData) => ({
  type: SAVE_LANDING_PAGE_SUCCESS,
  payload: landingPageData,
});

// --- UPLOAD MEDIA TO HOST

export const uploadMediaToHostRequest = (
    landingPageId,
    event,
    mediaType,
    media
) => ({
  type: UPLOAD_MEDIA_TO_HOST_REQUEST,
  payload: {
    landingPageId,
    event,
    mediaType,
    media,
  },
});

export const uploadMediaToHostFailure = (errorMessage) => ({
  type: UPLOAD_MEDIA_TO_HOST_FAILURE,
  payload: errorMessage,
});

export const uploadMediaToHostSuccess = (mediaData) => ({
  type: UPLOAD_MEDIA_TO_HOST_SUCCESS,
  payload: mediaData,
});
