import styled from "@emotion/styled";

const ChartWrapper = styled.div`
  /* display: flex; */
  border-radius: 10px;
  /* flex-direction: column; */
  
  /* flex: 1; */
  /* margin-bottom: 30px; */
  /* 
  padding: 10px 15px 10px 15px; */
  user-select: none;
  height: 100%;

  /* .chart-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .logo-title {
      display: flex;
      flex-direction: row;

      .logo {
        position: relative;
        display: flex;
        background-color: #ffcc33;
        height: 60px;
        width: 60px;
        border-radius: 0 0 0 30px;
        justify-content: center;
        align-items: center;
     
        transform:translate(0,-20px);

        img {
          height: 35px;
          width: 35px;
          align-self: center;
        }
      }

      .visits-title {
        font-size: 20px;
        margin-left: 20px;
        align-self:center;
      }
    }

    .unit-selector {
      .chart-button {
        min-width: 60px;
        margin-left: 10px;
        border-radius: 25px;
        color: #717171;
        align-self: center;
        background-color: #e9e9e9;
        border: none;
        padding-top: 5px;
        padding-bottom: 5px;
        outline: 0;
        cursor: pointer;
      }

      .chart-button-selected {
        color: white;
        background-color: $accent-color;
        border: none;
      }
    }
  } */

  .chart-container {
    /* display: -webkit-box;
    display: -ms-flexbox; */
    /* display: flex; */
    position:relative;
    border-radius: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: white;
    /* -webkit-box-flex: 2.5;
    -ms-flex: 2.5;
    flex: 2.5; */
    /* margin-left: 20px;
    margin-bottom: 50px; */
    padding: 0 20px;
    height:100%;
    
  }

  .stats-chart {
    position: absolute;
    margin: auto;
    margin-top: 10px;
    height:65%;
    width:90%;
    top:55%;
    left:50%;
    transform:translate(-50%,-50%);
  }

  /* .stats-chart-row {
    width: 90vw;
  }

  .stats-chart-locked {
    width: 80vw;
  }

  .chart-container-row {
    flex: 2.5;
  } */



  /* .chart-container .chart-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .chart-container .chart-top .unit-selector button {
    min-width: 60px;
    margin-left: 10px;
    border-radius: 25px;
    color: #717171;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    background-color: #e9e9e9;
    padding-top: 5px;
    padding-bottom: 5px;
    outline: 0;
  }

  .chart-container .chart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  } */

  /* @media only screen and (max-width: 1400px) {
    .chart-container {
      .stats-chart {
        width: 64vw;
      }

      .stats-chart-row {
        width: 88vw;
      }

      .stats-chart-locked {
        width: 78vw;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .chart-container {
      .stats-chart-row {
        width: 86vw;
      }

      .stats-chart-locked {
        width: 75vw;
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    .chart-container {
      .stats-chart {
        width: 60vw;
      }
      .stats-chart-row {
        width: 86vw;
      }

      .stats-chart-locked {
        width: 72vw;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .chart-container {
      .stats-chart-row {
        width: 84vw;
      }

      .stats-chart-locked {
        width: 74vw;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .chart-container {
      .stats-chart-row {
        width: 82vw;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .chart-container {
      .stats-chart {
        width: 55vw;
      }
      .stats-chart-row {
        width: 80vw;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .chart-container {
      .stats-chart-row {
        width: 75vw;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .chart-container {
      .stats-chart-row {
        width: 72vw;
      }
    }
  } */
`;

export default ChartWrapper;
