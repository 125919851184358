import React from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import ActivateSwitch from "./ActivateSwitch";
import {ReactComponent as EditIcon} from "assets/images/lightGrayEdit.svg";
import {ReactComponent as DeleteIcon} from "assets/images/lightGrayDelete.svg";
import "assets/css/design/landing-page-item.scss";

const LandingPageItem = (props) => {
  const {
    landingPage,
    type,
    onDelete,
    toggleActive,
  } = props;
  const match = useRouteMatch();
  const history = useHistory();

  const getLogo = () => {
    if (!landingPage) {
      return type === 'track'
          ? require('assets/images/unaro-full-icon.svg').default
          : require('assets/images/rsz_landing_page.png').default;
    }

    const creative = landingPage?.creatives &&
        landingPage.creatives.find((creative) =>
            (creative.type === type) === "poster" ? "POSTER_LOGO" : "LOGO");

    return creative
        ? creative.url
        : require("assets/images/rsz_landing_page.png");
  };

  return (
      <div
          className="landing-page-item"
          style={{border: '3px solid transparent'}}
      >
        <div className="landing-page-item__content">
          <div className="landing-page-item__left-content">
            <div className="landing-page-item__title-container">
              <p>{landingPage.name}</p>
              <hr/>
            </div>
            <div className="landing-page-item__image-container">
              <img src={getLogo()} className="landing-page-item__image" alt=""/>
            </div>
          </div>
          <div className="landing-page-item-status">
            <div className="landing-page-item-status-items">
              <p>Active</p>
              <ActivateSwitch
                  active={landingPage.active}
                  setActive={() => toggleActive(landingPage.externalId)}
              />
            </div>
          </div>
          <div className="landing-page-item__actions">
            <button
                className="landing-page-item__action-btn landing-page-item__action-btn-edit"
                onClick={() =>
                    history.push({
                      pathname: `${match.path}/${landingPage.externalId}`,
                      state: {create: false},
                    })
                }
            >
              Edit
              <EditIcon/>
            </button>
            <button
                className="landing-page-item__action-btn"
                onClick={() => onDelete(landingPage)}
            >
              Delete
              <DeleteIcon/>
            </button>
          </div>
        </div>
      </div>
  );
};

export default LandingPageItem;
