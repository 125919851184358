import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
// import { formValueSelector } from "redux-form";
import {
  selectFeaturedTemplates,
  selectSavedTemplates,
  selectCreatedTemplate,
  selectCreatedFeaturedTemplate,
  selectCurrentCampaign,
  selectCurrentTemplate,
  selectIsUsingSavedTemplate,
  selectCurrentContactsList,
  selectCurrentSegments,
  selectCurrentAudiences,
  selectIsTestEmailSent,
  selectIsCampaignSent,
  selectSavedTemplatesPagination,
  selectFeaturedTemplatesPagination,
  selectCurrentStep,
  selectNewCreatedCampaign
} from "./selectors";

import actions from "./actions";

const {
  updateCurrentCampaignRequest,
  sendVerificationCodeRequest,
  getFeaturedTemplatesRequest,
  getSavedTemplatesRequest,
  createTemplateRequest,
  deleteTemplateRequest,
  sendTestEmailRequest,
  createCurrentCampaignRequest,
  updateCurrentStepRequest,
  resetAllValuesRequest,
} = actions;

export const mapStateToProps = createStructuredSelector({
  featuredTemplates: selectFeaturedTemplates,
  savedTemplates: selectSavedTemplates,
  createdTemplate: selectCreatedTemplate,
  createdFeaturedTemplate: selectCreatedFeaturedTemplate,
  currentCampaign: selectCurrentCampaign,
  currentTemplate: selectCurrentTemplate,
  isUsingSavedTemplate: selectIsUsingSavedTemplate,
  currentContactsList: selectCurrentContactsList,
  currentSegments: selectCurrentSegments,
  currentAudiences: selectCurrentAudiences,
  isTestEmailSent: selectIsTestEmailSent,
  isCampaignSent: selectIsCampaignSent,
  savedTemplatesPagination: selectSavedTemplatesPagination,
  featuredTemplatesPagination: selectFeaturedTemplatesPagination,
  currentStep: selectCurrentStep,
  newCreatedCampaign:selectNewCreatedCampaign
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCurrentCampaignRequest,
      sendVerificationCodeRequest,
      getFeaturedTemplatesRequest,
      getSavedTemplatesRequest,
      createTemplateRequest,
      deleteTemplateRequest,
      sendTestEmailRequest,
      createCurrentCampaignRequest,
      updateCurrentStepRequest,
      resetAllValuesRequest
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
