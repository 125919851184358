import { ReactComponent as PersonLogo } from "assets/images/person-icon.svg";
import { ReactComponent as CalendarLogo } from "assets/images/interface.svg";
import { ReactComponent as EmailLogo } from "assets/images/email-icon.svg";
import { ReactComponent as FacebookLogo } from "assets/images/facebook.svg";
import { ReactComponent as TwitterLogo } from "assets/images/twitter.svg";
import { ReactComponent as ConnectedLogo } from "assets/images/connected.svg";

const lFilter = localStorage.getItem("l-filter");
const rFilter = localStorage.getItem("r-filter") || "";

export const filtersData = [
    {
      id: 0,
      name: "NOW",
      icon: ConnectedLogo,
      type: "connected",
      selected: lFilter === "connected",
    },
    {
      id: 1,
      name: "RECENT",
      icon: CalendarLogo,
      type: "recent",
      selected: lFilter === "recent",
    },
    {
      id: 2,
      name: "ALL",
      icon: PersonLogo,
      type: "",
      selected: rFilter === "",
    },
  ];

  export const usableFilters = [
    {
      id: 3,
      name: "EMAIL",
      icon: EmailLogo,
      type: "email",
      selected: rFilter === "email",
    },
    {
      id: 4,
      name: "FACEBOOK",
      icon: FacebookLogo,
      type: "facebook",
      selected: rFilter === "facebook",
    },
    {
      id: 5,
      name: "TWITTER",
      icon: TwitterLogo,
      type: "twitter",
      selected: rFilter === "twitter",
    },
  ];
