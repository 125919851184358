import actions from "../actions";

const {
  SELECT_ORGANISATION_REQUEST,
  SELECT_ORGANISATION_FAILURE,
  SELECT_ORGANISATION_SUCCESS,
  EXIT_ORGANISATION_REQUEST,
  EXIT_ORGANISATION_FAILURE,
  EXIT_ORGANISATION_SUCCESS,
  GET_SELECTED_ORGANISATION_LICENSES_REQUEST,
  GET_SELECTED_ORGANISATION_LICENSES_FAILURE,
  GET_SELECTED_ORGANISATION_LICENSES_SUCCESS,
  GET_SELECTED_ORGANISATION_VENUES_REQUEST,
  GET_SELECTED_ORGANISATION_VENUES_FAILURE,
  GET_SELECTED_ORGANISATION_VENUES_SUCCESS,
  GET_SELECTED_ORGANISATION_SUMMARY_REQUEST,
  GET_SELECTED_ORGANISATION_SUMMARY_FAILURE,
  GET_SELECTED_ORGANISATION_SUMMARY_SUCCESS,
  GET_SELECTED_ORGANISATION_CAMPAIGNS_REQUEST,
  GET_SELECTED_ORGANISATION_CAMPAIGNS_FAILURE,
  GET_SELECTED_ORGANISATION_CAMPAIGNS_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  selectedOrganisation: null,
  selectedOrganisationLicenses: null,
  selectedOrganisationVenues: null,
  selectedOrganisationSummary: null,
  selectedOrganisationCampaigns: null,
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_ORGANISATION_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        selectedOrganisationVenues: null,
      };
    }

    case SELECT_ORGANISATION_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SELECT_ORGANISATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedOrganisation: action.payload,
      };
    }
    ///---

    case EXIT_ORGANISATION_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case EXIT_ORGANISATION_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case EXIT_ORGANISATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedOrganisation: null,
      };
    }

    ///---

    case GET_SELECTED_ORGANISATION_LICENSES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_SELECTED_ORGANISATION_LICENSES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_SELECTED_ORGANISATION_LICENSES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedOrganisationLicenses: action.payload,
      };
    }

    ///---

    case GET_SELECTED_ORGANISATION_VENUES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_SELECTED_ORGANISATION_VENUES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_SELECTED_ORGANISATION_VENUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedOrganisationVenues: action.payload,
      };
    }

    ///---

    case GET_SELECTED_ORGANISATION_SUMMARY_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_SELECTED_ORGANISATION_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_SELECTED_ORGANISATION_SUMMARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedOrganisationSummary: action.payload,
      };
    }

    ///---

    case GET_SELECTED_ORGANISATION_CAMPAIGNS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_SELECTED_ORGANISATION_CAMPAIGNS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_SELECTED_ORGANISATION_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedOrganisationcampaigns: action.payload,
      };
    }

    default:
      return state;
  }
}
