import { createSelector } from "reselect";

// import getChartTimeLabels from "utils/charts/getChartTimeLabels";

import { chartsSliceSelector } from "store/selectors";

export const selectIsLoading = createSelector(
  chartsSliceSelector,
  (chartsSliceSelector) => chartsSliceSelector["loading"]
);

export const selectErrorMessage = createSelector(
  chartsSliceSelector,
  (chartsSliceSelector) => chartsSliceSelector["errorMessage"]
);

export const selectChartTimeUnit = createSelector(
  chartsSliceSelector,
  (chartsSliceSelector) => chartsSliceSelector["chartTimeUnit"]
);

// export const selectChartData = createSelector(
//   (state) => state[REDUCER_NAME],
//   _get("chartData")
// );

// export const selectChartMaxVal = createSelector(selectChartData, (data) => {
//   if (data && data.year && "datasets" in data.year) {
//     const maxVal = Math.max(...data.year.datasets.flat()[0].data);
//     return maxVal;
//   }
// });
