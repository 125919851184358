import React, { useState } from "react";
import { SketchPicker } from "react-color";

import "assets/css/widget/color-picker.scss";

export const ColorPicker = ({
  title,
  value,
  hideValue,
  setValue,
  className,
  popupPosition,
}) => {
  const [showPicker, setShowPicker] = useState(false);

  const onBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setShowPicker(false);
    }
  };

  return (
    <div
      className={`color-picker-container ${className}`}
      tabIndex="0"
      onBlur={onBlur}
    >
      <div className="picker-container">
        <div className="color-picker">
          {title && (
            <label
              className="color-picker-label"
              style={{ width: hideValue ? 100 : 180 }}
            >
              {title}
            </label>
          )}
          <div className="switch-color" onClick={() => setShowPicker(true)}>
            {!hideValue && <label className="color-label">{value}</label>}
            <div
              style={{
                borderRadius: "50px",
                width: "20px",
                height: "20px",
                marginLeft: hideValue ? "" : "20px",
                backgroundColor: value,
              }}
            />
          </div>
        </div>
        {showPicker && (
          <SketchPicker
            className={`sketch-picker sketch-picker--position-${popupPosition}`}
            color={value}
            onChangeComplete={(c) => setValue(c.hex)}
          />
        )}
      </div>
    </div>
  );
};
