import React from 'react'

export default ({ color = '#ffffff' }) => {
    return (
        <svg width="146" height="27" viewBox="0 0 146 27" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M84.9136 8.18328C84.2415 6.58946 83.2543 5.1562 82.0233 3.943C80.7922 2.72979 79.353 1.76636 77.7651 1.07055C74.4229 -0.321075 70.6821 -0.321075 67.3399 1.09433C65.7461 1.76636 64.289 2.75358 63.058 3.98463C61.8269 5.21567 60.8397 6.67271 60.1439 8.26654C59.4243 9.92578 59.0437 11.7218 59.0675 13.5119C59.0675 15.3079 59.4243 17.098 60.1439 18.7572C60.8397 20.351 61.8269 21.8081 63.058 23.0391C64.289 24.2523 65.7461 25.2336 67.3399 25.9294C68.9753 26.649 70.7714 27.0058 72.5614 26.9821C73.5903 26.9821 74.6251 26.8274 75.6123 26.536C76.0821 26.3814 76.5341 26.2208 76.9801 26.0186V26.8453H86.0376V13.3454C86.0138 11.5672 85.6332 9.81873 84.9136 8.18328ZM76.9326 15.3793C76.6887 15.9383 76.3497 16.4319 75.9215 16.8601C75.4755 17.2883 74.9819 17.6213 74.3991 17.8711C73.8163 18.1149 73.1859 18.2517 72.5614 18.2279C71.931 18.2279 71.3304 18.1149 70.7476 17.8711C69.6236 17.4013 68.7077 16.5033 68.2141 15.3852C67.6967 14.2196 67.6967 12.8755 68.2141 11.7099C68.4579 11.1509 68.8207 10.6573 69.2429 10.2291C69.6711 9.80089 70.1826 9.46785 70.7476 9.21807C71.3066 8.97424 71.937 8.83746 72.5614 8.86125C73.1918 8.86125 73.8163 8.97424 74.3991 9.21807C74.9581 9.4619 75.4755 9.80089 75.9215 10.2291C77.2715 11.5493 77.67 13.5892 76.9326 15.3793Z" fill={color} />
            <path d="M134.619 8.27234C133.923 6.67851 132.959 5.22147 131.728 3.99042C130.497 2.75937 129.058 1.77215 127.446 1.10013C124.104 -0.315278 120.316 -0.315278 116.974 1.10013C115.38 1.77215 113.923 2.75937 112.692 3.99042C111.461 5.22147 110.473 6.67851 109.778 8.27234C109.058 9.93158 108.701 11.7276 108.701 13.5177C108.701 15.3078 109.058 17.1038 109.778 18.763C110.473 20.3568 111.461 21.8139 112.692 23.0449C113.923 24.2581 115.38 25.2394 116.974 25.9352C120.316 27.3506 124.104 27.3506 127.446 25.9352C129.04 25.2394 130.497 24.276 131.728 23.0449C132.959 21.8139 133.923 20.3568 134.619 18.763C135.338 17.1038 135.695 15.3315 135.671 13.5177C135.695 11.7276 135.314 9.93158 134.619 8.27234ZM126.572 15.3791C126.328 15.9381 125.989 16.4318 125.543 16.8599C125.115 17.2881 124.604 17.6212 124.021 17.871C123.438 18.1148 122.831 18.2516 122.183 18.2278C121.553 18.2278 120.952 18.1148 120.369 17.871C119.245 17.3773 118.33 16.5031 117.836 15.3851C117.319 14.2194 117.319 12.8754 117.836 11.7098C118.08 11.1507 118.443 10.6571 118.865 10.2289C119.293 9.80074 119.804 9.4677 120.369 9.21792C120.928 8.97409 121.559 8.83731 122.183 8.8611C122.814 8.8611 123.438 8.97409 123.997 9.21792C124.556 9.46175 125.074 9.80074 125.52 10.2289C125.948 10.6571 126.305 11.1507 126.548 11.7098C127.042 12.8694 127.042 14.1897 126.572 15.3791Z" fill={color} />
            <path d="M140.964 17.5801C138.365 17.5801 136.254 19.6854 136.254 22.2902C136.254 24.895 138.359 27.0003 140.964 27.0003C143.569 27.0003 145.674 24.895 145.674 22.2902C145.674 19.6854 143.569 17.5801 140.964 17.5801Z" fill={color} />
            <path d="M42.6518 0H29.5801V26.9523H38.6375V22.9618C38.6613 22.7774 38.6613 22.6228 38.6613 22.4444V12.4235C38.7089 11.1687 39.0657 10.1339 39.7615 9.35479C40.4573 8.56977 41.397 8.16537 42.634 8.16537H42.6578C43.2406 8.16537 43.8234 8.30215 44.317 8.59356C44.8106 8.88497 45.2388 9.26558 45.5718 9.71756C45.9287 10.1874 46.2022 10.7286 46.3806 11.2876C46.5591 11.8466 46.6483 12.4532 46.6483 13.036V17.4964H46.6245V26.9106H55.7057V13.0717C55.7057 12.8457 55.7057 12.6257 55.6819 12.3997C55.337 5.44754 49.598 0 42.6518 0Z" fill={color} />
            <path d="M13.065 26.9521L26.1367 26.9521L26.1367 -0.000108719L17.0793 -0.000109511L17.0793 3.99039C17.0555 4.17475 17.0555 4.32938 17.0555 4.50779L17.0555 14.5287C17.0079 15.7835 16.6511 16.8183 15.9553 17.5974C15.2595 18.3824 14.3198 18.7868 13.0828 18.7868L13.059 18.7868C12.4762 18.7868 11.8934 18.65 11.3998 18.3586C10.9062 18.0672 10.478 17.6866 10.145 17.2346C9.78814 16.7648 9.51457 16.2236 9.33616 15.6646C9.15775 15.1055 9.06854 14.4989 9.06854 13.9161L9.06854 9.45578L9.09233 9.45578L9.09233 0.0415196L0.0111069 0.0415188L0.0111057 13.8804C0.0111057 14.1064 0.0111057 14.3265 0.0348941 14.5524C0.379824 21.5046 6.11878 26.9521 13.065 26.9521Z" fill={color} />
            <path d="M103.699 0.178831H103.586C103.539 0.178831 103.473 0.178831 103.432 0.20262C103.384 0.20262 103.319 0.226408 103.277 0.226408C102.201 0.273985 101.148 0.404821 100.113 0.67244C99.1914 0.89843 98.2993 1.23147 97.4251 1.63587C97.2883 1.70129 97.1099 1.65966 97.0207 1.54666C96.6638 1.11847 96.2357 0.809224 95.7658 0.559446C95.183 0.268038 94.5348 0.131254 93.8806 0.131254C92.1084 0.107466 90.4729 1.1601 89.7533 2.8015C89.5273 3.3189 89.3965 3.90172 89.3965 4.46074V26.9943H98.1209C98.2993 26.9943 98.4599 26.8397 98.4599 26.6553V12.8224C98.4599 12.644 98.5074 12.4418 98.5729 12.2871C98.8405 11.6389 99.2211 11.0561 99.7147 10.5387C100.185 10.0451 100.767 9.64067 101.398 9.34926C102.046 9.03406 102.742 8.87944 103.461 8.83186H103.598C104.205 8.83186 104.788 8.71887 105.347 8.49288C105.882 8.29068 106.375 7.97548 106.78 7.59487C107.63 6.78606 108.124 5.668 108.1 4.50237C108.148 3.36053 107.72 2.26032 106.934 1.43367C106.167 0.630811 105.091 0.20262 103.699 0.178831Z" fill={color} />
            <path d="M15.41 18.1626L15.3445 18.2102C14.6963 18.6384 13.9113 18.8406 13.1501 18.8168H13.1263C12.5435 18.8168 11.9606 18.68 11.467 18.3886C11.3778 18.341 11.2886 18.2756 11.1994 18.2102L11.0864 18.1626H0.726562C0.726562 18.1864 0.726562 18.1864 0.750351 18.2102C2.56422 23.4793 7.5241 27 13.1025 27H26.1742V18.1626H15.41Z" fill={color} />
            <path d="M11.0794 18.1626L11.1449 18.2101H0.743387C0.743387 18.1864 0.719599 18.1864 0.719599 18.1626C0.493609 17.5143 0.315196 16.8423 0.202201 16.1643C0.112995 15.6469 0.0475768 15.1117 0.0237884 14.5943C0.0237884 14.3683 0 14.1483 0 13.9223V9.19434H9.05743V13.9461C9.05743 14.5289 9.14664 15.1355 9.32505 15.6945C9.37263 15.8491 9.43805 16.0097 9.50347 16.1643C9.65809 16.545 9.88408 16.9256 10.1339 17.2646C10.4074 17.5976 10.7226 17.9128 11.0794 18.1626Z" fill={color} />
            <path d="M26.15 9.19434V18.2101H15.3203L15.3857 18.1626C15.6117 18.0079 15.7901 17.8236 15.9685 17.6273C16.3492 17.1991 16.6168 16.7055 16.7952 16.1703C16.9498 15.6529 17.039 15.1176 17.0628 14.5765V9.19434H26.15Z" fill={color} />
            <path d="M9.08868 0H0.03125V9.23585H9.08868V0Z" fill={color} />
            <path d="M26.1496 0H17.0684V9.23585H26.1496V0Z" fill={color} />
        </svg>

    )
}