import React, {useState, useEffect} from "react";
import {
  FormControl,
  FormControlLabel,
  Menu,
  Radio,
  RadioGroup,
  Divider, MenuItem, Button
} from "@mui/material";
import DoneButton from "./DoneButton";

const Gender = (props) => {
  const {change, destroy, handleSubmit, addFilterRequest, removeFilterRequest} = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [value, setValue] = useState('Male');
  const [genderField, setGenderField] = useState();

  const handleChange = (event) => {
    setValue(event.target.value);
    setGenderField(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    destroy("ADD_FILTER_MODAL_FORM");
    setGenderField(null);
    setAnchorEl(null);
    setGenderField("");
  };

  const handleOnChange = (event) => {
    change("value", event.target.value);
    setGenderField(event.target.value);
  };

  useEffect(() => {
    anchorEl === null && destroy("ADD_FILTER_MODAL_FORM");
  }, [anchorEl]);

  useEffect(() => {
    if (genderField?.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [genderField]);

  const onClick = (values: any) => {
    addFilterRequest({
      key: 'gender',
      operation: 'EQUALS',
      value: genderField ? genderField : ''
    });
    handleClose();
  }

  return (
      <div>
        <div
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
        >
          Gender
        </div>
        <Menu
            id="simple-menu"
            // anchorEl={anchorEl}
            anchorReference="anchorPosition"
            anchorPosition={{top: 350, left: 280}}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '140px',
                height: '200px',
                justifyContent: 'center'
              },
            }}
        >
            <FormControl
                style={{marginLeft: 20}}
            >
              <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="gender"
                  // value={genderField}
                  onChange={handleChange}
              >
                <FormControlLabel value="Male" control={<Radio sx={{
                  '&.Mui-checked': {
                    color: "#FF9933",
                  },
                }}/>} label="Is Male"/>
                <FormControlLabel value="Female" control={<Radio sx={{
                  '&.Mui-checked': {
                    color: "#FF9933",
                  },
                }}/>} label="Is Female"/>
                <FormControlLabel value="Other" control={<Radio sx={{
                  '&.Mui-checked': {
                    color: "#FF9933",
                  },
                }}/>} label="Is Other"/>
              </RadioGroup>
            </FormControl>
          <Divider fullWidth/>
          <Button
              onClick={handleSubmit((values) => onClick(values))}
              sx={{
                color: "#FF9933",
                fontSize: 16,
                marginTop: 1,
                fontWeight: "500",
                marginLeft: '5ch'
              }}
              variant="text"
              disabled={isButtonDisabled}
          >
            DONE
          </Button>
        </Menu>
      </div>
  );
};

export default Gender;
