import {
  GET_ALL_VENUES_REQUEST,
  GET_ALL_VENUES_FAILURE,
  GET_ALL_VENUES_SUCCESS,
  SELECT_VENUE_REQUEST,
  SELECT_VENUE_FAILURE,
  SELECT_VENUE_SUCCESS,
  CLEAR_SELECTED_VENUE_REQUEST,
  CLEAR_SELECTED_VENUE_FAILURE,
  CLEAR_SELECTED_VENUE_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  errorMessage: null,
  selectedVenue: null,
  allOrganisationVenues: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_VENUES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        allOrganisationVenues: null,
        
      };
    }

    case GET_ALL_VENUES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        allOrganisationVenues: null,
      };
    }

    case GET_ALL_VENUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allOrganisationVenues: action.payload,
      };
    }
    //---
    case SELECT_VENUE_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        // selectedVenue: null,
      };
    }

    case SELECT_VENUE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        selectedVenue: null,
      };
    }

    case SELECT_VENUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedVenue: action.payload,
      };
    }
    //---

    case CLEAR_SELECTED_VENUE_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case CLEAR_SELECTED_VENUE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case CLEAR_SELECTED_VENUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedVenue: null,
        allOrganisationVenues: null,
      };
    }

    default:
      return state;
  }
}
