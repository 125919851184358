import React, { useState } from "react";

import { ReactComponent as DropdownLogo } from "assets/images/dropdown-icon.svg";

import DropDownWrapper from "./DropDownWrapper";

import "assets/css/widget/dropdown.scss";

const Dropdown = ({
  options = [],
  selected = "",
  onSelect,
  dropdownPosition = "bottom",
  className,
  placeholder,
  hasSecondaryBackground = false,
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    if (options.length > 0) setOpen(!open);
  };

  const changeSelected = (option) => {
    setOpen(false);
    onSelect(option);
  };

  return (
    <DropDownWrapper tabIndex="0" onBlur={() => setOpen(false)}>
      <div
        className={`dropdown ${
          open && "dropdown-open"
        } dropdown--secondary-background-${hasSecondaryBackground}`}
        onClick={() => toggleOpen()}
      >
        <label>{selected || placeholder}</label>

        <DropdownLogo className="logo" />
      </div>
      {open && (
        <div className={`options dropdown-position--${dropdownPosition}`}>
          {options.map((option, i) => {
            return (
              <label
                key={option.externalId}
                onClick={() => changeSelected(option)}
              >
                {option.name}
              </label>
            );
          })}
        </div>
      )}
    </DropDownWrapper>
  );
};

export default Dropdown;
