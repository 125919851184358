import React from "react";
import styled from "@emotion/styled";

const FieldsRowWrapper = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding:15px 5px;
  

  /* &:nth-child(1) {
    background-color: yellow;
  }

  &:nth-child(2) {
    background-color: red;
  } */
`;

const FieldsRow = (props) => {
  const { children } = props;
  return <FieldsRowWrapper>{children}</FieldsRowWrapper>;
};

export default FieldsRow;
