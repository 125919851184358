import React from "react";

import TemplatePreviewItemWrapper from "./TemplatePreviewItemWrapper";

import Iframe from "components/Iframe";
import Preview from "./Preview";
import Description from "./Description";
import ActionsPanel from "./ActionsPanel";

const ACTIONS_PANEL_HEIGHT = 20;

const TemplatePreviewItem = (props) => {
  const {
    template,
    updateCurrentCampaignRequest,
    organisationId,
    currentTemplate,
    // isUsingSavedTemplate,
    setShowAddCampaign,
    isSuperAdmin,
    templateType,
    deleteTemplateRequest,
  } = props;
  const { name, unlayerDesignHtml } = template;

  const [isThisSelected, setIsThisSelected] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);

  React.useEffect(() => {
    if (template?.externalId === currentTemplate?.externalId) {
      setIsThisSelected(true);
    } else {
      setIsThisSelected(false);
    }
  }, [currentTemplate]);

  const handleSelectTemplate = () => {
    if (isThisSelected) {
      updateCurrentCampaignRequest(organisationId, "template", null);
    } else {
      updateCurrentCampaignRequest(organisationId, "template", template);
    }
  };

  return (
    <TemplatePreviewItemWrapper
      isThisSelected={isThisSelected}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      isHover={isHover}
    >
      <ActionsPanel
        height={ACTIONS_PANEL_HEIGHT}
        isHover={isHover}
        isSuperAdmin={isSuperAdmin}
        template={template}
        templateType={templateType}
        organisationId={organisationId}
        deleteTemplateRequest={deleteTemplateRequest}
      ></ActionsPanel>
      <div
        style={{
          marginTop: `${ACTIONS_PANEL_HEIGHT}px`,
        }}
        onClick={() => {
          handleSelectTemplate();
          setTimeout(() => setShowAddCampaign(true), 700);
        }}
      >
        <Description marginTop={ACTIONS_PANEL_HEIGHT}>
          <h3>{name}</h3>
        </Description>

        <Preview marginTop={ACTIONS_PANEL_HEIGHT}>
          <Iframe content={unlayerDesignHtml}></Iframe>
        </Preview>
      </div>
    </TemplatePreviewItemWrapper>
  );
};

export default TemplatePreviewItem;
