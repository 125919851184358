import React from "react";
import styled from "@emotion/styled";
import Chart from "components/Chart";

const ChartWidgetWrapper = styled.div`
  max-height: 100%;
  position: relative;
  grid-row: 1/2;
  grid-column: 7/16;

  /* min-height: ${(props) => props.vhPixels / 2.4}px;
  max-height: ${(props) => props.vhPixels / 2.4}px; */

  @media only screen and (max-width: 1200px) {
    grid-row: 1/2;
    grid-column: 1/16;
  }
`;

const ChartWidget = (props) => {
  const { vhPixels } = props;

  //pass data here
  return (
    <ChartWidgetWrapper vhPixels={vhPixels}>
      <Chart type="line" {...props} withIcon={false} />
    </ChartWidgetWrapper>
  );
};

export default ChartWidget;
