import React from "react";
import styled from "@emotion/styled";
import {Pencil, Trash} from "phosphor-react";

const TagListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 30px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? "#f4f4f4" : "white")};
  &:hover {
    .tag-edit {
      & > * {
        opacity: 0.75;
        pointer-events: auto;
      }
    }
  }

  .dot {
    width: 15px;
    height: 15px;
    border-radius: 25px;
    margin-right: 20px;
    margin-top: 10px;
    border: 1px solid gray;
  }

  .name {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    label {
      &:nth-child(1) {
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 5px;
        cursor: pointer;
      }

      &:nth-child(2) {
        font-size: 12px;
        color: #3c4858;
        cursor: pointer;
      }
    }
  }

  .tag-edit {
    align-self: flex-end;
    margin-left: auto;
    min-width: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * {
      opacity: 0;
      pointer-events: none;
      transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1);
    }
  }
`;

const TagListItem = (props) => {
  const {
    selectedTag,
    tag,
    setSelectedTag,
    setShowDeleteTagPopup,
    setShowEditTagPopup,
  } = props;

  const deleteTag = () => {
    setShowDeleteTagPopup(true);
  };

  const editTag = () => {
    setShowEditTagPopup(true);
  };

  return (
    <TagListItemWrapper
      id={tag.externalId}
      onClick={() => setSelectedTag(tag)}
      isSelected={selectedTag && selectedTag.externalId === tag.externalId}
    >
      <div
        className="dot"
        style={{
          backgroundColor: tag.color,
          borderColor: tag.color === "#ffffff" ? "#C4C4C4" : tag.color,
        }}
      />
      <div className="name">
        <label>{tag.name}</label>
        <label>{tag.contacts} contacts</label>
      </div>

      <div className="tag-edit">
        <Pencil color={"#3c4858"} size={20} onClick={editTag} />

        <Trash color={"#3c4858"} size={18} onClick={deleteTag} />
      </div>
    </TagListItemWrapper>
  );
};

export default TagListItem;
