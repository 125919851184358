import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import Select from 'react-select'
import api from "../../../../../../utils/api";
import {from, map} from "rxjs";

const StyleContainer = styled.div`
  .input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: auto;
    align-items: center;
    @media (min-width: 320px) and (max-width: 480px) {
      flex-direction: column;
      margin: auto auto 15px;
    }
  }

  .input-label {
    font-size: 20px;
    font-weight: bold;
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 16px;
      width: 85%;
    }
  }

  .select-container {
    display: flex;
    margin-right: 60px;
  }

  .select-button {
    border: 0;
    width: 100px;
    height: 30px;
    background: #ea5454;
    color: white;
    border-radius: 30px;
    margin-right: 15px;
    margin-top: 5px;

    &:focus {
      outline: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .select {
    min-width: 350px;
    max-width: 350px;
  }
`

type Option = {
  value: string
  label: string
}

type Contact = {
  id: string
  forename: string
  surname: string
  allowSms: boolean
  phones: string[]
}

const SendToField = (props) => {
  const {
    audienceTags,
    setSelectedAudience,
    setAudienceTags,
    organisationId,
    selectedCustomers,
    setSelectedContacts,
  } = props;

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState<Option[]>([]);

  useEffect(() => {
    if (selectedCustomers) {
      setContactList(selectedCustomers, true);
    } else {
      fetchSmsContacts();
    }
  }, [selectedCustomers]);

  useEffect(() => {
    if (selected) {
      const ids = new Set(selected.map(c => c.value));
      setSelectedContacts(contacts.filter(c => ids.has(c.id)));
    }
  }, [selected]);

  const fetchSmsContacts = () => {
    from(api.post('/contacts/search', {
      "organisationId": organisationId,
      "type": "ALL",
      "criteria": {
        "key": "hasPhone",
        "operation": "EQUALS",
        "value": "true"
      }
    }))
    .pipe(map((resp) => resp.data.content))
    .subscribe({
      next: (data: Contact[]) => data && setContactList(data),
      error: (err) => {
        console.error("Error searching contacts");
        console.error(err);
      }
    })
  }

  const setContactList = (contacts: Contact[], selectAll?: boolean) => {
    const c = contacts.filter(c => c.allowSms && c.phones && c.phones.length > 0);
    const o = c.map(x => {
      return {value: x.id, label: `${x.forename} ${x.surname}`}
    });
    setContacts(c);
    setOptions(o);
    selectAll && setSelected(o);
  }

  const selectAll = () => {
    setSelected(options);
  }

  return (
      <StyleContainer>
        <div className="input-container">
          <label className="input-label">Send to:</label>
          <div className="select-container">
            <button className="select-button" onClick={selectAll}>
              Select All
            </button>
            <Select
                className="select"
                isMulti
                options={options}
                value={selected}
                onChange={(e) => setSelected(e)}
            />
          </div>
        </div>
      </StyleContainer>
  );
};

export default SendToField;
