import React from "react";
import {ReactComponent as UnaroIcon} from "assets/images/full-icon.svg";
import MainLoginButton from "./MainLoginButton";
import OtherLoginButtons from "./OtherLoginButtons";
import LoginScreenWrapper from "./LoginScreenWrapper";
import LogoContainer from "../../components/LogoContainer";

const LoginScreen = (props) => {
  const {settings} = props;

  return (
      <LoginScreenWrapper opacity={settings?.opacity}>
        <div className="phone-preview-content-top">
          <div className="small-box">
            <LogoContainer logo={settings?.logoImage?.url}/>
          </div>
        </div>
        <div className="main-content">
          <label
              style={{
                color: settings?.textcolor ? settings?.textcolor : "black",
                textAlign: "center",
                width: "90%",
                fontSize: 15,
                margin: "auto",
              }}
          >
            {settings?.loginMainTitle}
          </label>

          <MainLoginButton settings={settings}/>

          <OtherLoginButtons settings={settings}/>

          <a href="#" className="privacy-policy">Read our Privacy Policy</a>
          <div className="powered-by">
            <label>Powered By</label>
            <div className="powered-by-unaro-icon">
              <UnaroIcon/>
            </div>
          </div>
        </div>
      </LoginScreenWrapper>
  );
};

export default LoginScreen;
