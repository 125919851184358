import styled from "@emotion/styled";

const DomainsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-right: 60px; */
  overflow-y: scroll;
  height: calc(100vh - 420px);
  margin-top: 50px;
  width: 85%;

  .ss-domain {
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr 1fr;
    justify-content: space-between;
    padding: 30px 0;
    border-top: 1px solid #c4c4c4;
    position: relative;

    .ssd-left {
      display: flex;
      flex-direction: column;

      .ssd-name {
        font-family: Roboto;
        font-size: 24px;
      }

      .ssd-info {
        font-family: Roboto;
        font-size: 16px;
        margin-top: 10px;
      }
    }
/* 
    .ssd-state {
      font-family: Roboto;
      font-weight: 300;
      font-size: 12px;
      color: #ffffff;
      background-color: #ea5454;
      padding: 10px 20px;
      border-radius: 45px;
      width: 50px;
    } */

    .ssd-dd * {
      cursor: pointer;
    }

    .ssd-options {
      position: absolute;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      z-index: 2;
      padding: 10px 0;
      right: 10px;
      top: 80px;
      width: 120px;
      outline: 0;

      label {
        padding: 10px 20px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 10px;
        cursor: pointer;
      }

      label:hover {
        background: #f2f2f2;
      }
    }
  }
`;

export default DomainsListWrapper;
