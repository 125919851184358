import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { reducer as formReducer } from "redux-form";

import { REDUCER_NAME as authReducerName } from "containers/Auth/consts";
import authReducer from "containers/Auth/reducer";

import { REDUCER_NAME as userReducerName } from "containers/User/consts";
import userReducer from "containers/User/reducer";

import { REDUCER_NAME as selectedOrganisationReducerName } from "containers/SelectedOrganisation/consts";
import selectedOrganisationReducer from "containers/SelectedOrganisation/reducer";

import { REDUCER_NAME as networkReducerName } from "containers/Network/consts";
import networkReducer from "containers/Network/reducer";

import { REDUCER_NAME as connectReducerName } from "containers/Connect/consts";
import connectReducer from "containers/Connect/reducer";

//---

import { REDUCER_NAME as statisticsReducerName } from "containers/Statistics/consts";
import statisticsReducer from "containers/Statistics/reducer";

import { REDUCER_NAME as campaignsReducerName } from "containers/Campaigns/consts";
import campaignsReducer from "containers/Campaigns/reducer";

import { REDUCER_NAME as sendDashboardReducerName } from "containers/Send/SendDashboard/consts";
import sendDashboardReducer from "containers/Send/SendDashboard/reducer";

import { REDUCER_NAME as sendNewEmailReducerName } from "containers/Send/SendNewEmail/consts";
import sendNewEmailReducer from "containers/Send/SendNewEmail/reducer";

import { REDUCER_NAME as sendSettingsReducerName } from "containers/Send/SendSettings/consts";
import sendSettingsReducer from "containers/Send/SendSettings/reducer";

//
import { REDUCER_NAME as sendManageCampaignsReducerName } from "containers/Send/SendManage/consts";
import sendManageCampaignsReducer from "containers/Send/SendManage/reducer";

import { REDUCER_NAME as analyticsReducerName } from "containers/Analytics/consts";
import analyticsReducer from "containers/Analytics/reducer";

import { REDUCER_NAME as chartsReducerName } from "containers/Charts/consts";
import chartsReducer from "containers/Charts/reducer";

import { REDUCER_NAME as superAdminDashboardReducerName } from "containers/SuperAdminDashboard/consts";
import superAdminDashboardReducer from "containers/SuperAdminDashboard/reducer";

import { REDUCER_NAME as venuesReducerName } from "containers/Venues/consts";
import venuesReducer from "containers/Venues/reducer";

import { REDUCER_NAME as miscReducerName } from "containers/Misc/consts";
import miscReducer from "containers/Misc/reducer";

import { REDUCER_NAME as contactsListReducerName } from "containers/ContactsList/consts";
import contactsListReducer from "containers/ContactsList/reducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    [authReducerName]: authReducer,
    [userReducerName]: userReducer,
    [selectedOrganisationReducerName]: selectedOrganisationReducer,
    [networkReducerName]: networkReducer,
    [connectReducerName]: connectReducer,
    [statisticsReducerName]: statisticsReducer,
    [campaignsReducerName]: campaignsReducer,

    //SEND
    [sendDashboardReducerName]: sendDashboardReducer,
    [sendNewEmailReducerName]: sendNewEmailReducer,
    [sendManageCampaignsReducerName]: sendManageCampaignsReducer,
    [sendSettingsReducerName]: sendSettingsReducer,
    //
    [analyticsReducerName]: analyticsReducer,
    [chartsReducerName]: chartsReducer,
    [superAdminDashboardReducerName]: superAdminDashboardReducer,
    [venuesReducerName]: venuesReducer,
    [miscReducerName]: miscReducer,
    [contactsListReducerName]: contactsListReducer,
  });

export default createRootReducer;
