import styled from "@emotion/styled";

const SendSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;

  /* .ss-title {
    font-family: Roboto;
    font-weight: 300;
    font-size: 30px;
    color: #5b5b5b;
    margin-top: 60px;
    margin-left: 20px;
  } */

  .ss-content {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    padding: 20px 30px;
    margin: 20px;
    min-height: 50%;
    align-items: center;
    flex: 1;

    .ssc-text {
      font-family: Roboto;
      font-size: 16px;
      margin: 20px 0;
    }

    .ssc-btn {
      background: #ea5454;
      border-radius: 45px;
      border: none;
      outline: none;
      cursor: pointer;
      width: 200px;
      height: 40px;
      color: white;
      margin-top: 25px;
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 12px;
      background: #efefef;
      border-radius: 18px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 18px;
      background-color: #d9d9d9;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    /* .ss-domains {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      padding-right: 60px;
      overflow-y: scroll;
      height: calc(100vh - 430px);

      .ss-domain {
        display: grid;
        align-items: center;
        grid-template-columns: 3fr 1fr 1fr;
        justify-content: space-between;
        padding: 30px 0;
        border-top: 1px solid #c4c4c4;
        position: relative;

        .ssd-left {
          display: flex;
          flex-direction: column;

          .ssd-name {
            font-family: Roboto;
            font-size: 24px;
          }

          .ssd-info {
            font-family: Roboto;
            font-size: 16px;
            margin-top: 10px;
          }
        }

        .ssd-state {
          font-family: Roboto;
          font-weight: 300;
          font-size: 12px;
          color: #ffffff;
          background-color: #ea5454;
          padding: 10px 20px;
          border-radius: 45px;
          width: 50px;
        }

        .ssd-dd * {
          cursor: pointer;
        }

        .ssd-dd {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-self: end;
          background: #c4c4c4;
          border-radius: 45px;
          width: 140px;
          height: 30px;
          cursor: pointer;

          label {
            font-family: Roboto;
            font-weight: 700;
            font-size: 14px;
            color: #ffffff;
            margin-left: 20px;
            border-right: 1px solid #ffffff;
            padding-top: 12.5px;
            height: 100%;
            width: 90px;
          }

          .dd-icon {
            margin-left: 6px;

            path {
              fill: #ffffff;
            }
          }
        }
        .ssd-options {
          position: absolute;
          display: flex;
          flex-direction: column;
          background: #ffffff;
          box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.2);
          border-radius: 12px;
          z-index: 2;
          padding: 10px 0;
          right: 10px;
          top: 80px;
          width: 120px;
          outline: 0;

          label {
            padding: 10px 20px;
            font-family: Roboto;
            font-weight: normal;
            font-size: 10px;
            cursor: pointer;
          }

          label:hover {
            background: #f2f2f2;
          }
        }
      }
    } */
  }
`;

export default SendSettingsWrapper;
