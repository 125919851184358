import React from "react";
import styled from "@emotion/styled";

import Map from "components/Map";

const DashboardMapWrapper = styled.div`
  grid-row: 3/4;
  grid-column: 1/13;

  @media (max-width: 900px) {
    grid-row: 4/5;
    grid-column: 1/13;
  }
`;

const DashboardMap = (props) => {
  const { admin, userOrganisation } = props;
  return (
    admin && (
      <DashboardMapWrapper>
        <Map organisationId={userOrganisation?.externalId} />
      </DashboardMapWrapper>
    )
  );
};

export default DashboardMap;
