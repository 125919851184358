import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ColorPicker} from "components/widget/colorPicker";
import {TextSwitch} from "components/widget/textSwitch";
import {PercentSlider} from "components/widget/slider";
import {hexToRGB} from "utils/common";
import QRCode from "qrcode.react";
import ShowQrPopup from "./ShowQrPopup";
import FileDragAndDrop from "../../../../../components/FileDragAndDrop";
import {ReactComponent as DesignLogo} from "assets/images/design-icon.svg";
import {ReactComponent as ArrowIcon} from "assets/images/arrow.svg";
import {ReactComponent as AddMoreIcon} from "assets/images/add.svg";
import {ReactComponent as DropdownLogo} from "assets/images/drop-down-icon.svg";
import {
  ReactComponent as DownloadIcon
} from "assets/images/download-qr-icon.svg";
import "assets/css/design/track/edit-track-and-trace-page.scss";
import {uploadMediaImage} from "../../../../../containers/Connect/mediaAPI";
import {
  ImageResponse,
  LandingPage
} from "../../../../../containers/Connect/types";
import api from "utils/api";
import _ from "lodash";

const NewQrLandingPage = (props) => {
  const {landingPageId} = useParams();
  const history = useHistory();
  const urlRef = useRef(null);

  const [landingPage: LandingPage, setLandingPage] = useState({});
  const [editDesign, setEditDesign] = useState(true);
  const [showQrPopup, setShowQrPopup] = useState(false);
  const [copyText, setCopyText] = useState("Copy to clipboard");

  useEffect(() => {
    api.get(`/landingPages/${landingPageId}`)
    .then((response: any) => setLandingPage(response.data))
    .catch((error: any) => {
      console.error('Landing page error ' + landingPageId);
      console.error(error);
    });
  }, [landingPageId]);

  const uploadImage = (file: File, mediaType: string) => {
    uploadMediaImage(file, {
      organisationId: landingPage.organisationId,
      mediaType: mediaType,
      projectId: landingPage.externalId,
      projectName: landingPage.name
    })
    .then((imageResponse: ImageResponse) =>
        updateSettings(`${imageResponse.type}Image`, imageResponse));
  };

  const saveLandingPage = async () => {
    api.put(`/landingPages/${landingPage.externalId}`, landingPage)
    .then((response: any) => {
      history.goBack();
    })
    .catch((error: any) => {
      console.error('Error saving');
      console.error(error);
    })
  };

  const updateSettings = (field: string, value: string) => {
    const newLandingPage = landingPage ? _.cloneDeep(landingPage) : {};
    _.set(newLandingPage, `settings.${field}`, value);
    setLandingPage(newLandingPage);
  }

  // noinspection DuplicatedCode
  const downloadQR = () => {
    const canvas = document.getElementById("qr");
    const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const copyToClipboard = async () => {
    setCopyText("Copied!");
    setTimeout(() => setCopyText("Copy to clipboard"), 1000);
    await navigator.clipboard.writeText(
        `https://${process.env.REACT_APP_TT_URL}/#/qr/register/${landingPage.externalId}`
    );
  };

  return (
      <div className="edit-track-and-trace-page">
        <div className="edit-track-and-trace">
          <div className="edit-track-and-trace-top">
            <div className="edit-track-and-trace-title">
              <div className="edit-track-and-trace-logo">
                <DesignLogo className="edit-track-and-trace-logo-svg"/>
              </div>
              <label className="landing-page-edit-title-text">
                {editDesign ? "Editor" : "Settings"}
              </label>
            </div>
            <div className="landing-page-edit-actions">
              <label
                  onClick={() => setEditDesign(true)}
                  className={`landing-page-edit-top-select ${
                      editDesign && "landing-page-edit-top-select-selected"
                  }`}
              >
                Design
              </label>
              <label
                  onClick={() => setEditDesign(false)}
                  className={`landing-page-edit-top-select ${
                      !editDesign && "landing-page-edit-top-select-selected"
                  }`}
              >
                Settings
              </label>
            </div>
          </div>
          <hr className="edit-track-and-trace-top-line"/>
          {editDesign ? (
              <div>
                <div className="edit-track-and-trace-name-input"
                     style={{width: '50%'}}>
                  <label>Name:</label>
                  <input
                      value={landingPage.name || ""}
                      onChange={(e) =>
                          setLandingPage({...landingPage, name: e.target.value})
                      }
                  />
                </div>
                <label className="edit-track-and-trace-subtitle">
                  Design - Landing Page
                </label>
                <p className="edit-track-and-trace-description">
                  Use the form below to upload and select assets for your
                  landing
                  page.
                </p>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  width: '80%'
                }}>
                  <FileDragAndDrop
                      title="Select Logo Image:"
                      className="file-selector"
                      fileName={landingPage?.settings?.logoImage?.filename}
                      setFile={(event) => uploadImage(event, "logo")}
                  />
                  <FileDragAndDrop
                      title="Select Background Image:"
                      className="file-selector"
                      fileName={landingPage?.settings?.backgroundImage?.filename}
                      setFile={(event) => uploadImage(event, "background")}
                  />
                </div>
                <hr className="edit-track-and-trace-menu-line"/>
                <label className="edit-track-and-trace-subtitle">Fields</label>
                <div className="edit-track-and-trace-field">
                  <label>Field 1</label>
                  <label
                      className="edit-track-and-trace-field-name">Forename</label>
                </div>
                <div className="edit-track-and-trace-field">
                  <label>Field 2</label>
                  <label
                      className="edit-track-and-trace-field-name">Surname</label>
                </div>
                <div className="edit-track-and-trace-field">
                  <label>Field 3</label>
                  <label className="edit-track-and-trace-field-name">
                    Gender
                  </label>
                </div>
                <div className="edit-track-and-trace-field">
                  <label>Field 4</label>
                  <label className="edit-track-and-trace-field-name">
                    Email address
                  </label>
                </div>
                <div className="edit-track-and-trace-field">
                  <label>Field 5</label>
                  <label className="edit-track-and-trace-field-name">Phone
                    number</label>
                </div>

                <div className="edit-track-and-trace-field">
                  <label>Field 6</label>
                  <label
                      className="edit-track-and-trace-field-name">Birthday</label>
                </div>

                <hr className="edit-track-and-trace-menu-line"/>
                <label className="edit-track-and-trace-subtitle">
                  Page styling
                </label>
                <p className="edit-track-and-trace-description">
                  Please use the controls below to style text, hyperlinks and
                  backgrounds.
                </p>
                <PercentSlider
                    title="Menu Box Opacity:"
                    value={1 - landingPage?.settings?.menuOpacity}
                    setValue={(value) => updateSettings('menuOpacity',
                        1 - value)}
                />
                <ColorPicker
                    title="Box Color:"
                    value={landingPage?.settings?.boxColor}
                    setValue={(value) => updateSettings('boxColor', value)}
                />
                <TextSwitch
                    title="Box Border Visibility:"
                    value={landingPage?.settings?.boxBorderVisibility}
                    values={["HIDE", "SHOW"]}
                    setValue={(value) => updateSettings('boxBorderVisibility',
                        value)}
                />
                {landingPage?.settings?.boxBorderVisibility === "SHOW" && (
                    <ColorPicker
                        title="Box Border Color:"
                        value={landingPage?.settings?.boxBorderColor}
                        setValue={(value) => updateSettings('boxBorderColor',
                            value)}
                    />
                )}
                <ColorPicker
                    title="Text Color:"
                    value={landingPage?.settings?.textcolor}
                    setValue={(value) => updateSettings('textcolor', value)}
                />
                <ColorPicker
                    title="Input Color:"
                    value={landingPage?.settings?.inputColor}
                    setValue={(value) => updateSettings('inputColor', value)}
                />
                <ColorPicker
                    title="Link Color:"
                    value={landingPage?.settings?.linkColor}
                    setValue={(value) => updateSettings('linkColor', value)}
                />
                <ColorPicker
                    title="Link Hover Color:"
                    value={landingPage?.settings?.linkHoverColor}
                    setValue={(value) => updateSettings('linkHoverColor',
                        value)}
                />
                <ColorPicker
                    title="Error Text Color:"
                    value={landingPage?.settings?.errorTextColor}
                    setValue={(value) => updateSettings('errorTextColor',
                        value)}
                />
                <ColorPicker
                    title="Button Color:"
                    value={landingPage?.settings?.buttonColor}
                    setValue={(value) => updateSettings('buttonColor', value)}
                />
                <ColorPicker
                    title="Add More Button Color:"
                    value={landingPage?.settings?.goButtonColor}
                    setValue={(value) => updateSettings('goButtonColor', value)}
                />

                <ColorPicker
                    title="Wait Indicator Color:"
                    value={landingPage?.settings?.waitIndicatorColor}
                    setValue={(value) => updateSettings('waitIndicatorColor',
                        value)}
                />
                <hr className="edit-track-and-trace-menu-line"/>
                <button
                    className="dit-track-and-trace-save-btn"
                    onClick={saveLandingPage}
                >
                  Save changes
                </button>
              </div>
          ) : (
              <div className="track-and-trace-settings">
                <div className="qr">
                  <div className="left">
                    <label className="title">Your QR Code</label>
                    <DownloadIcon/>
                    <button onClick={downloadQR}>Download</button>
                  </div>
                  <div className="right">
                    <QRCode
                        id="qr"
                        value={`https://${process.env.REACT_APP_TT_URL}/#/qr/register/${landingPage.externalId}`}
                        size={200}
                    />
                  </div>
                </div>
                <hr/>
                <div className="nfc">
                  <label className="title">Your NFC Code</label>
                  <div className="nfc-label">
                    <label className="copy-btn" onClick={copyToClipboard}>
                      {copyText}
                    </label>
                    <label
                        className="url"
                        ref={urlRef}
                    >{`https://${process.env.REACT_APP_TT_URL}/#/qr/register/${landingPage.externalId}`}</label>
                  </div>
                </div>
              </div>
          )}
        </div>

        <div className="edit-track-and-trace-preview">
          <div className="edit-track-and-trace-preview-content">
            <div className="edit-track-and-trace-phone-preview">
              <img alt="preview" src={require("assets/images/preview.png").default}/>

              <div
                  className="phone-preview-box"
                  style={{
                    backgroundColor: landingPage?.settings?.backgroundImage
                        ? 'unset'
                        : '#FECC33',
                    backgroundImage: landingPage?.settings?.backgroundImage
                        ? `url(${landingPage?.settings?.backgroundImage.url}`
                        : 'assets/images/unaro-yellow-bg.svg',
                  }}
              >
                <div
                    className="edit-track-and-trace-box"
                    style={{
                      backgroundColor: landingPage?.settings?.boxColor
                          ? hexToRGB(
                              landingPage?.settings?.boxColor,
                              landingPage?.settings?.menuOpacity
                                  ? 1 - landingPage?.settings?.menuOpacity
                                  : 1
                          )
                          : "#ffffff",
                      border:
                          landingPage?.settings?.boxBorderVisibility === "SHOW"
                              ? `1px solid ${landingPage.boxBorderColor}`
                              : "none",
                    }}
                >
                  <div className="edit-track-and-trace-top">
                    <img alt=""
                         src={
                           landingPage?.settings?.logoImage
                               ? landingPage?.settings?.logoImage.url
                               : require("assets/images/unaro-full-icon.svg").default
                         }
                    />
                    <label
                        style={{color: landingPage?.settings?.textcolor}}
                        className="connect-with-email"
                    >
                      Connect with email
                    </label>
                    <label
                        style={{
                          color: landingPage?.settings?.inputColor,
                          borderColor: landingPage?.settings?.inputColor,
                        }}
                        className="input-preview"
                    >
                      Forename:
                    </label>
                    <label
                        style={{
                          color: landingPage?.settings?.inputColor,
                          borderColor: landingPage?.settings?.inputColor,
                        }}
                        className="input-preview"
                    >
                      Surname:
                    </label>
                    <label
                        style={{
                          color: landingPage?.settings?.inputColor,
                          borderColor: landingPage?.settings?.inputColor,
                        }}
                        className="input-preview"
                    >
                      Phone number:
                    </label>
                    <label
                        style={{
                          color: landingPage?.settings?.inputColor,
                          borderColor: landingPage?.settings?.inputColor,
                        }}
                        className="input-preview"
                    >
                      Email:
                    </label>
                    <div
                        className="input-preview title-preview"
                        style={{borderColor: landingPage?.settings?.inputColor}}
                    >
                      <label style={{color: landingPage?.settings?.inputColor}}>
                        Gender:
                      </label>
                      <div>
                        <label
                            style={{color: landingPage?.settings?.inputColor}}>
                          male
                        </label>
                        <DropdownLogo className="icon"/>
                      </div>
                    </div>
                    <div
                        className="input-preview title-preview"
                        style={{borderColor: landingPage?.settings?.inputColor}}
                    >
                      <label
                          style={{color: landingPage?.settings?.inputColor}}>
                        Date of Birth:
                      </label>
                      <div>
                        <label
                            style={{color: landingPage?.settings?.inputColor}}>
                          DD/MM/YY
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="edit-track-and-trace-bottom">
                    <div
                        className="edit-track-and-trace-register-btn"
                        style={{backgroundColor: landingPage?.settings?.buttonColor}}
                    >
                      <label>Register</label>
                      <ArrowIcon/>
                    </div>
                    <div
                        className="edit-track-and-trace-register-btn add-more"
                        style={{backgroundColor: landingPage?.settings?.goButtonColor}}
                    >
                      <AddMoreIcon className="add-more-icon"/>
                      <label>Add More Guests</label>
                    </div>
                    <label className="privacy">
                      Click here to read our{" "}
                      <span style={{color: landingPage?.settings?.linkColor}}>
                      Privacy Policy
                    </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showQrPopup && (
            <ShowQrPopup
                landingPageId={landingPage?.externalId}
                close={() => setShowQrPopup(false)}
            />
        )}
      </div>
  );
};

export default NewQrLandingPage;
