import React from 'react'

import '../assets/css/success-popup.scss'

export default ({ message, close }) => {

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    return (
        <div className='success-popup' onClick={onClick}>
            <div className='success-popup-content'>
                <label>{message}</label>
                <button onClick={close}>Done</button>
            </div>
        </div>
    )
}