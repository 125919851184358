import React from "react";
import styled from "@emotion/styled";

const SegmentsTextWrapper = styled.div``;

const DynamicTextLabel = styled.label`
  border-radius: 15px;
  color: #ea5454;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 16px;
  margin-left: ${(props) => (props.ml ? props.ml : 0)}px;
  margin-right: ${(props) => (props.mr ? props.mr : 0)}px;
`;

const SegmentsText = (props) => {
  const { currentCampaign, currentSegments } = props;

  const { selectedSegment, selectedSegmentContacts } = currentSegments;

  return (
    currentCampaign && (
      <SegmentsTextWrapper>
        <DynamicTextLabel mr={5}>
          {currentCampaign.contacts.length} contacts
        </DynamicTextLabel>
        in the segment
        <DynamicTextLabel ml={5}>{selectedSegment.name}</DynamicTextLabel>
      </SegmentsTextWrapper>
    )
  );
};

export default SegmentsText;
