import React from "react";

import EditBranding from "./EditBranding";
import EditLogin from "./EditLogin";
import EditLandingPageSettings from "./EditLandingPageSettings";
import EditLandingRedirect from "./EditLandingRedirect";

import "assets/css/design/edit-landing-page-design.scss";

const EditLandingPageDesign = (props) => {
  const {currentLandingPageStep} = props;

  const getCurrentEditScreen = () => {
    switch (currentLandingPageStep) {
      case 1:
        return <EditBranding {...props}/>;
      case 2:
        return <EditLogin {...props}/>;
      case 3:
        return <EditLandingPageSettings {...props}/>;
      case 4:
        return <EditLandingRedirect {...props}/>;
      default:
        return;
    }
  };

  return <div>{getCurrentEditScreen()}</div>;
};

export default EditLandingPageDesign;
