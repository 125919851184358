export const enum SocialMedia {
  Default = 'Default',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Linkedin = 'Linkedin',
  Email = 'Email',
}

export const enum RedirectPageType {
  TEXT = "TEXT",
  CUSTOM_URL = "CUSTOM_URL",
  CUSTOM_IMAGE = "CUSTOM_IMAGE",
  INSTAGRAM = "INSTAGRAM",
}

export type PreviewScreen = {
  id: number,
  name: string
}

// Allowed keys: firstName, lastName, email, mobileNumber, gender, dateOfBirth, question
export type EmailInput = {
  key: string,
  index: number,
  text: string
}

export type GreetingNameOption = {
  externalId: number,
  name: string
}

export type LoginOptions = {
  Facebook: boolean,
  Twitter: boolean,
  Email: boolean,
  Linkedin: boolean
}

export type Image = {
  externalId: string,
  filename: string,
  url: string,
  format?: string,
  width?: number,
  height?: number
}

export type ImageMetadata = {
  organisationId: string,
  mediaType: string,
  projectId: string,
  projectName?: string,
}

export type ImageResponse = {
  externalId: string,
  type: string,
  filename: string,
  url: string,
  format: string,
  width: number,
  height: number
}

export type LandingPage = {
  externalId: string,
  organisationId: string,
  name: string,
  track: boolean,
  settings: LandingPageSettings
}

export type LandingPageSettings = {

  // Step 1
  preferredSocialMedia: string,
  textcolor: string,
  greetingMessage: string,
  greetingName: string, // First Name, Full Name : dropdown, possible enum???
  logoImage: Image,
  opacity?: string
  backgroundImage?: Image,

  welcomeText: string,
  welcomeHeaderColor: string,
  welcomeTextColor: string,
  welcomeButtonsTextColor: string,
  welcomeJoinColor: string,
  welcomeCancelColor: string,
  welcomeImage: Image,

  // Step 2
  loginMainTitle: string,
  loginSubtitle: string,
  loginOptions: LoginOptions,

  // Step 3
  emailInputs: EmailInput[],
  emailBackButtonColor: string,
  emailContinueButtonColor: string,

  // Step 4
  loginFacebookLike: boolean,
  loginFacebookUrl: string,
  loginFacebookTitle: string,

  // Step 5
  redirectPage: RedirectPage,
  customImage: Image,

  // QR
  menuOpacity: number,
  boxColor: string,
  boxBorderVisibility: string, // SHOW, HIDE
  boxBorderColor: string,
  inputColor: string,
  linkColor: string,
  linkHoverColor: string,
  errorTextColor: string,
  buttonColor: string,
  goButtonColor: string,
  waitIndicatorColor: string,
  //textcolor: string,

}

export type RedirectPage = {
  type: RedirectPageType,

  // TEXT
  titleText: string,
  titleTextColour: string,
  subTitleText: string,
  subTitleTextColour: string,
  buttonUrl: string,
  buttonText: string,
  buttonTextColour: string,
  buttonColour: string,
  backgroundColour: string,

  // INSTAGRAM
  embedCode: string,
  instagramButtonText: string,
  instagramButtonTextColor: string,
  instagramButtonColor: string,

  // CUSTOM_IMAGE
  customImageUrl: string,
  customImageButtonText: string,
  customImageButtonTextColor: string,
  customImageButtonColor: string,

  // CUSTOM_URL
  redirectUrl: string
}