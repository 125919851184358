import api from "utils/api";
import {SearchRequest} from "./types";

export const PAGE_SIZE = 10;

export const search = (request) => {
  return api.post('/contacts/search', request);
}

export const apiGetAllContacts = (data) => {
  const {
    organisationId,
    pageNumber = 0,
    pageSize = PAGE_SIZE,
    searchString,
    criteria
  } = data;

  return search({
    organisationId: organisationId,
    type: 'ALL',
    page: pageNumber,
    size: pageSize,
    query: searchString,
    criteria: criteria,
  });
};

export const apiGetSegments = (data) => {
  const {organisationId} = data;

  const apiRoute = `/organisation/${organisationId}/segment`;

  return api.get(apiRoute);
};

export const apiGetSegmentContacts = async (data) => {
  const {
    organisationId,
    segmentId,
    pageNumber = 0,
    pageSize = PAGE_SIZE,
    searchString,
    criteria
  } = data;

  return search({
    organisationId: organisationId,
    type: 'SEGMENT',
    requestId: segmentId,
    page: pageNumber,
    size: pageSize,
    query: searchString,
    criteria: criteria
  });
};

//

export const apiGetAudiences = (data) => {
  const {organisationId} = data;

  const apiRoute = `/organisation/${organisationId}/audiences`;

  return api.get(apiRoute);
};

export const apiGetAudienceContacts = async (data) => {
  const {
    organisationId,
    audienceId,
    pageNumber = 0,
    pageSize = PAGE_SIZE,
    searchString,
  } = data;

  return search({
    organisationId: organisationId,
    type: 'AUDIENCE',
    requestId: audienceId,
    page: pageNumber,
    size: pageSize,
    query: searchString
  });
};

export const apiCreateAudience = async (payload) => {
  const {
    organisationId,
    audienceName,
    audienceColor,
    contactIds,
    chosenEmoji
  } = payload;

  const {unified, emoji, originalUnified, names, activeSkinTone} = chosenEmoji;
  const postBodyObj = {
    name: audienceName,
    contactIds: contactIds,
    color: emoji,
  };

  const apiRoute = `/organisation/${organisationId}/audience`;

  return api.post(apiRoute, postBodyObj);
};

export const apiCreateSegment = async (payload) => {
  const {
    filters,
    organisationId,
    segmentEmoji,
    segmentName,
  } = payload;

  const postBodyObj = {
    externalId: organisationId,
    organisationId: organisationId,
    name: segmentName,
    iconName: segmentEmoji,
    criteria: filters
  };

  const apiRoute = `/organisation/${organisationId}/segment`;

  return api.post(apiRoute, postBodyObj);
};

export const apiUpdateSegment = async (payload) => {
  const {
    criteria,
    organisationId,
    segmentEmoji,
    segmentName,
    segmentId
  } = payload;

  const putBodyObj = {
    externalId: segmentId,
    organisationId: organisationId,
    name: segmentName,
    iconName: segmentEmoji,
    criteria: criteria
  };

  const apiRoute = `/organisation/${organisationId}/segment/${segmentId}`;

  return api.put(apiRoute, putBodyObj);
};

export const apiDeleteSegment = async (payload) => {
  const {organisationId, segmentId} = payload;
  const externalId = segmentId;
  const apiRoute = `/organisation/${organisationId}/segment/${externalId}`;

  return api.delete(apiRoute);
};

export const apiCreateContact = (payload) => {
  let config = {
    headers: {
      api_key: "pk_live_FGHJYYTALAek2rwZ0ZHbrTDj00skByotel",
    },
  };

  const cc = payload?.countryCode ? payload?.countryCode : '';
  const phone = payload?.mobileNumber ? payload?.mobileNumber : '';

  const postObj = {
    ...payload,
    mobileNumber: `${cc} ${phone}`.trim(),
    registrationType: "EMAIL",
    allowSms: true,
    allowEmail: true,
  }

  const apiRoute = `/contact/register`;

  return api.post(apiRoute, postObj, config);
};

export const apiLinkContactsToAudiences = (payload) => {

  const {organisationId, contactIds, contactAudiences} = payload;

  let requests = [];

  const bodyObj = {contactIds: contactIds};

  for (let i = 0; i < contactAudiences.length; i++) {
    const currentAudienceId = contactAudiences[i];

    requests[i] = api.post(
        `/organisation/${organisationId}/audience/${currentAudienceId}/link`,
        bodyObj
    );
  }

  return Promise.all(requests).then((data) => {
    return data.map((item) => {
      return {
        item,
      };
    });
  });
};

export const apiImportContacts = async (payload) => {
  const formData = new FormData();

  const {organisationId, columns, file} = payload;

  formData.append("file", file);
  //formData.append("action", action); // not used in BE
  formData.append('importType', 'EMAIL'); //TODO should be part of payload
  formData.append(
      "columns",
      columns
      // columns.filter((col) => col.name.length > 0).map((col) => col.name)
  );

  return api.post(
      `/organisations/${organisationId}/contacts/import`,
      formData,
      {headers: {"Content-Type": "multipart/form-data"}}
  );
};

export const apiDeleteContacts = async (payload) => {
  const {organisationId, contactsIds} = payload;

  return api.delete(`/organisations/${organisationId}/contacts`, {
    data: {contactExternalIds: contactsIds},
  });
};
