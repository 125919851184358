import {put, takeLatest} from "redux-saga/effects";
import moment from "moment";
import getChartTimeLabels from "utils/charts/getChartTimeLabels";

import {
  SET_CHART_TIME_UNIT_REQUEST,
  setChartTimeUnitFailure,
  setChartTimeUnitSuccess,
  UPDATE_CHART_DATA_REQUEST,
  updateChartDataFailure,
  updateChartDataSuccess,
} from "./actions";

const formatData = (timeUnit, data) => {
  return data
    .map((item) => item.visits)
    .filter((item) => item.length > 0)
    .flat()
    .sort((a, b) => {
      return Number(b.timestamp) - Number(a.timestamp);
    })
    .map((item) => {
      const date = moment(item.timestamp * 1000);

      let dateFormat;
      switch (timeUnit) {
        case "year":
          dateFormat = "MMM YY";
          break;
        case "month":
          dateFormat = "DD MMM";
          break;
        case "week":
          dateFormat = "DD";
          break;
        case "day":
          dateFormat = "HH";
          break;
      }

      item.formattedDate = date.format(dateFormat);

      return item;
    });
};

const generateChartData = (timeUnit, data, name) => {
  const chartConfig = {
    backgroundColor: "rgba(254, 203, 69, 0.3)",
    borderWidth: 3,
    borderColor: "rgba(254, 203, 69, 1)",
    tension: 0.5,
  };

  if (data) {
    const labels = getChartTimeLabels(timeUnit);

    const hashTable = {};
    labels.forEach((item) => (hashTable[item] = 0));
    data.forEach((item) => {
      if (hashTable.hasOwnProperty(item.formattedDate)) {
        hashTable[item.formattedDate] =
          hashTable[item.formattedDate] + item.number;
      }
    });

    const dataSet = Object.values(hashTable);

    const readyData = {};

    readyData.labels = labels;
    readyData.datasets = [
      {
        label: name,
        data: dataSet,
        ...chartConfig,
        // ...dataSetConfig,
      },
    ];

    return readyData;
  }
};

function* setChartTimeUnitWorker(action) {
  try {
    yield put(setChartTimeUnitSuccess(action.payload.timeUnit));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(setChartTimeUnitFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* updateChartDataWorker(action) {
  try {
    const { chartData, timeUnit } = action.payload;
    // yield put(updateChartDataSuccess(action.payload.timeUnit));

    const formattedData = yield formatData(timeUnit, chartData);



    const readyChartData = yield generateChartData(
      timeUnit,
      formattedData,
      "Visits"
    );

    yield put(updateChartDataSuccess(timeUnit, readyChartData));
  } catch (error) {
    // const errorMessage = yield error.message;
    yield put(updateChartDataFailure(error));
    console.log("error");
    console.log(error);
  }
}

export default function* watcher() {
  yield takeLatest(SET_CHART_TIME_UNIT_REQUEST, setChartTimeUnitWorker);
  yield takeLatest(UPDATE_CHART_DATA_REQUEST, updateChartDataWorker);
}
