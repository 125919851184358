import React, { useState } from 'react'

import { ReactComponent as CloseIcon } from '../../../../assets/images/delete.svg';

import '../../../../assets/css/sms/campaign/create/save-template-popup.scss'

export default ({ close, save }) => {

    const [template, setTemplate] = useState({})
    const [error, setError] = useState(false)

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    const changeName = value => {
        setError(false)
        setTemplate({ ...template, name: value })
    }

    const create = () => {
        if (!template.name || template.name < 3)
            setError(true)

        else {
            save(template)
            close()
        }
    }

    return (
        <div className='save-template-popup' onClick={onClick}>
            <div className='save-template-popup-content'>
                <div className='save-template-top'>
                    <label>Name Template</label>
                    <CloseIcon className='close-icon' onClick={close} />
                </div>
                <div className='save-template-content'>
                    <input placeholder='Name' autoFocus value={template.name || ''} onChange={e => changeName(e.target.value)} className={`${error && 'input-error'}`} />
                </div>
                {error && <label className='error-message'>Name is empty</label>}
                <div className='save-tempalte-actions'>
                    <button onClick={close}>Cancel</button>
                    <button onClick={create}>Save</button>
                </div>
            </div>
        </div>
    )
}