import React from "react";
import styled from "@emotion/styled";

const NoDomainsmessageWrapper = styled.div`
  border-top: 1px solid rgba(201, 201, 201, 0.65);
  margin-top: 50px;
  width:100%;
 
  p {
    text-align: center;
    font-weight: 700;
  }

  p:first-child {
    padding-top: 50px;
  }
`;

const NoDomainsMessage = (props) => {
  return (
    <NoDomainsmessageWrapper>
      <p>You haven't set up any Domains Yet.</p>
      <p>Click the button below to get started so you can send emails.</p>
    </NoDomainsmessageWrapper>
  );
};

export default NoDomainsMessage;
