import React, {useEffect} from "react";
import {compose} from "recompose";

import StatisticsContainer from "containers/Statistics";
import CampaignsContainer from "containers/Campaigns";
import SendDashboardContainer from "containers/Send/SendDashboard";
import ChartsContainer from "containers/Charts";
import styled from "@emotion/styled";

import CampaignsWidget from "./Widgets/CampaignsWidget";
import ChartWidget from "./Widgets/ChartWidget";
import ClicksWidget from "./Widgets/ClicksWidget";
import FailedWidget from "./Widgets/FailedWidget";
import OpenRateWidget from "./Widgets/OpenRateWidget";
import OpensWidget from "./Widgets/OpensWidget";
import SendCalendarWidget from "./Widgets/SendCalendarWidget";

import {chartDropdownButtons} from "./chartDropdownButtons";

import PageTitle from "components/PageTitle";

import {VenuesContext} from "context/venueContext";
import {ampli} from "containers/Amplitude/ampli";

const SendDashboardWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%; // change on mediaqueries?
  margin: 0 auto;
  max-height: 100%;
  /* overflow-x: hidden;
  overflow-y: hidden; */
  overflow: hidden;
  display: grid;
  grid-template-rows: minmax(auto, auto) minmax(auto, 40vh);
  grid-template-columns: repeat(15, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding: 50px 20px 0 20px;
  margin: 20px 10px 20px 10px;

  @media only screen and (min-width: 1400px) {
    /* max-width: 90%; */
  }

  @media only screen and (max-width: 1200px) {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }
`;

const SendDashBoard = (props) => {
  const {
    getEmailGlobalStatsRequest,
    getOrganisationCampaignsRequest,
    organisationCampaigns,
    globalEmailStats,
    getEmailsStatsRequest,
    getYearOpenRateStatsRequest,
    getMonthOpenRateStatsRequest,
    getWeekOpenRateStatsRequest,
    getDayOpenRateStatsRequest,
    setChartTimeUnitRequest,
    chartTimeUnit,
    yearDataSet,
    monthDataSet,
    weekDataSet,
    dayDataSet,
    openRatesChartMaxVal,
    campaignsSchedules,
    selectedEntity,
  } = props;

  const {selected} = React.useContext(VenuesContext);

  const [currentChartDataSet, setCurrentChartDataSet] = React.useState({
    ...monthDataSet,
  });

  const [chartTouched, setChartTouched] = React.useState(false);

  React.useEffect(() => {
    selected && getOrganisationCampaignsRequest(selectedEntity?.externalId);
    selected && getEmailGlobalStatsRequest(selectedEntity?.externalId);
    selected &&
    getEmailsStatsRequest(selectedEntity?.externalId, chartTimeUnit);
  }, [selectedEntity]);

  React.useEffect(() => {
    selected && getYearOpenRateStatsRequest(selectedEntity?.externalId);
    selected && getMonthOpenRateStatsRequest(selectedEntity?.externalId);
    selected && getWeekOpenRateStatsRequest(selectedEntity?.externalId);
    selected && getDayOpenRateStatsRequest(selectedEntity?.externalId);
  }, [selectedEntity]);

  React.useEffect(() => {
    switch (chartTimeUnit) {
      case "year":
        return setCurrentChartDataSet(yearDataSet);
      case "month":
        return setCurrentChartDataSet(monthDataSet);
      case "week":
        return setCurrentChartDataSet(weekDataSet);

      case "day":
      default:
        return setCurrentChartDataSet(dayDataSet);
    }
  }, [chartTimeUnit, selectedEntity]);

  React.useEffect(() => {
    !chartTouched && monthDataSet && setCurrentChartDataSet(monthDataSet);
  }, [monthDataSet]);

  const [vhPixels, setVhPixels] = React.useState(null);

  React.useEffect(() => {
    const vhInPx = window.innerHeight;

    setVhPixels(vhInPx);

    ampli.unaroPageVisit("page visit: Send -> Dashboard");
  }, []);

  return (
      <SendDashboardWrapper className="send-dashboard-wrapper">
        <PageTitle
            title={"Send Dashboard"}
            style={{position: "absolute", paddingLeft: "20px"}}
        ></PageTitle>
        <CampaignsWidget
            vhPixels={vhPixels}
            organisationCampaigns={organisationCampaigns}
        />
        <ChartWidget
            type="line"
            organisationId={selectedEntity?.externalId}
            currentTimeUnit={chartTimeUnit}
            withIcon={false}
            chartTitle="Opened emails"
            setCurrentTimeUnit={setChartTimeUnitRequest}
            data={currentChartDataSet}
            chartDropdownButtons={chartDropdownButtons}
            chartMaxVal={openRatesChartMaxVal}
            setChartTouched={setChartTouched}
            selected={selected}
            vhPixels={vhPixels}
        />

        <OpensWidget statsData={globalEmailStats}/>
        <OpenRateWidget statsData={globalEmailStats}/>
        <ClicksWidget statsData={globalEmailStats}/>
        <FailedWidget statsData={globalEmailStats}/>
        <SendCalendarWidget
            statsData={globalEmailStats}
            organisationCampaigns={campaignsSchedules}
        />
      </SendDashboardWrapper>
  );
};

export default compose(
    StatisticsContainer,
    CampaignsContainer,
    SendDashboardContainer,
    ChartsContainer
)(SendDashBoard);
