import React, { useState } from "react";

import { ReactComponent as DropdownIcon } from "assets/images/dropdown-icon.svg";

import "assets/css/widget/dropdown.scss";

const ChartUnitsDropdown = ({
  options = [],
  selected = "",
  onSelect,
  dropdownPosition = "bottom",
  className,
  placeholder,
  hasSecondaryBackground = false,
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    if (options.length > 0) setOpen(!open);
  };

  const changeSelected = (option) => {
    setOpen(false);
    onSelect(option);
  };

  return (
    <div
      className={`dropdown-container ${className}`}
      tabIndex="0"
      onBlur={() => setOpen(false)}
    >
      <div
        className={`dropdown ${
          open && "dropdown-open"
        } dropdown--secondary-background-${hasSecondaryBackground}`}
        onClick={() => toggleOpen()}
      >
        <label>{selected.text || placeholder}</label>
        <DropdownIcon className="logo" />
      </div>
      {open && (
        <div className={`options dropdown-position--${dropdownPosition}`}>
          {options.map((option, i) => {
            return (
              <label
                key={option.id}
                onClick={() => {
                  changeSelected(option);
                }}
              >
                {option.text}
              </label>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ChartUnitsDropdown;
