// noinspection JSUnresolvedVariable

import React from "react";
import styled from "@emotion/styled";
import getSocialMediaColor from "utils/getSocialMediaColor";
import getPrimarySocialMediaButton from "../getPrimarySocialMediaButton";
import {SocialMedia} from "../../../../../../../../../containers/Connect/types";
import uuid from "react-uuid";

const MainLoginButtonWrapper = styled.button`
  background: #3b5998;
  outline: 0;
  width: 80%;
  height: 45px;
  border-radius: 5px;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  border: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .primary-social-media-button__default {
    background: #3b5998;
    outline: 0;
    width: 80%;
    border-radius: 5px;
    display: flex;
    color: white;
    border: 0;
  }

  .media-logo {
    flex: 1;
    border-right: 1px solid white;
    height: 35px;
  }

  p {
    flex: 4;
    text-align: center;
    opacity: .85;
    font-weight: 600;
  }
`;

const MainLoginButton = (props) => {
  const {settings} = props;

  const defaultSocialMediaList: SocialMedia[] = [
    SocialMedia.Facebook,
    SocialMedia.Twitter,
    SocialMedia.Linkedin,
    SocialMedia.Email
  ];

  // noinspection
  const socialMediaList: SocialMedia[] =
      settings?.preferredSocialMedia === SocialMedia.Default
          ? defaultSocialMediaList.filter(
              (socialMedia: SocialMedia) => settings?.loginOptions
                  && settings?.loginOptions[socialMedia])
          : [settings?.preferredSocialMedia
              ? settings?.preferredSocialMedia
              : SocialMedia.Facebook];

  return socialMediaList && socialMediaList.map((socialMedia: SocialMedia) => {
        return <div key={uuid()}>
          <MainLoginButtonWrapper
              className="primary-social-media-button"
              style={{
                backgroundColor: getSocialMediaColor(socialMedia),
                flex: 1
              }}
          >
            <div className="media-logo">
              {getPrimarySocialMediaButton(socialMedia)}
            </div>
            <p>{socialMedia}</p>
          </MainLoginButtonWrapper>
        </div>
      }
  );
};

export default MainLoginButton;
