// noinspection DuplicatedCode
//
export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug",
  "Sep", "Oct", "Nov", "Dec",];

export const getChartOptions = (display) => { // boolean
  return {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: display,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            precision: 0,
          },
        },
      ],
    },
  }
};

export const getApiUnit = (unit) => {
  if (unit === "day") {
    return "hourly";
  } else if (unit === "week" || unit === "month") {
    return "daily";
  } else if (unit === "year") {
    return "monthly";
  }
};

export const getDayLabels = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  return Array.from(Array(24).keys()).map(
      (k) => (currentDate.getHours() + k + 1) % 24
  );
};

export const getWeekLabels = () => {
  return Array.from(Array(8).keys())
  .map((a) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7 + a);
    return currentDate;
  })
  .map((d) => d.getDate());
};

export const getMonthLabels = () => {
  const now = new Date();
  const daysInMonth = new Date(
      now.getFullYear(),
      now.getMonth(),
      0
  ).getDate();
  return Array.from(Array(daysInMonth).keys())
  .map((a) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - daysInMonth + a + 1);
    return currentDate;
  })
  .map((d) => d.getDate());
};

export const getYearLabels = () => {
  return Array.from(Array(13).keys())
  .map((a) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 11 + a, 1);
    return currentDate;
  })
  .map((d) => (d.getMonth() === 0 ? 12 : d.getMonth()));
};

export const getStartDate = (unit) => {
  if (unit === "day") {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    startDate.setHours(startDate.getHours(), 0, 0, 0);
    return startDate.getTime() / 1000;
  } else if (unit === "week") {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    startDate.setHours(0, 0, 0, 0);
    return startDate.getTime() / 1000;
  } else if (unit === "month") {
    const now = new Date();
    const startDate = new Date();
    startDate.setDate(
        startDate.getDate() -
        new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()
    );
    startDate.setHours(0, 0, 0, 0);
    return startDate.getTime() / 1000;
  } else if (unit === "year") {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    startDate.setMonth(startDate.getMonth() - 12, 1);
    return startDate.getTime() / 1000;
  }
};
