import React from "react";
import styled from "@emotion/styled";

const PreviewPhoneWrapper = styled.div``;

const PreviewPhone = (props) => {
  const { message } = props;

  return (
    <PreviewPhoneWrapper>
      <div className="quick-send__phone-image">
        <div className="quick-send__phone-text">
          <p>{message}</p>
        </div>
      </div>
    </PreviewPhoneWrapper>
  );
};

export default PreviewPhone;
