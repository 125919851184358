import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import {
  getIdentitiesRequest,
  createIdentityRequest,
  deleteIdentityRequest,
  getDomainRecordsRequest,
  resendIdentityRequest,
  selectIdentityRequest,
} from "./actions";

import {
  selectCreatedIdentity,
  selectDeletedIdentity,
  selectOrganisationIdentities,
  selectCurrentIdentity,
  selectCurrentIdentityDomainRecords,
} from "./selectors";

export const mapStateToProps = createStructuredSelector({
  createdIdentity: selectCreatedIdentity,
  deletedIdentity: selectDeletedIdentity,
  organisationIdentities: selectOrganisationIdentities,
  currentIdentity: selectCurrentIdentity,
  currentIdentityDomainRecords: selectCurrentIdentityDomainRecords,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getIdentitiesRequest,
      createIdentityRequest,
      deleteIdentityRequest,
      getDomainRecordsRequest,
      resendIdentityRequest,
      selectIdentityRequest,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
//
