import api from "utils/api";

// import setOneDayAgo from "utils/time/setOneDayAgo";
// import setOneWeekAgo from "utils/time/setOneWeekAgo";
// import setOneMonthAgo from "utils/time/setOneMonthAgo";
// import setOneYearAgo from "utils/time/setOneYearAgo";

export const apiGetVisits = (data) => {
  const { organisationId, timeUnit, startDate } = data;

  const endDate = Math.ceil(new Date() / 1000);

  const route = `/organisation/${organisationId}/analytics?unit=${timeUnit}&startDate=${startDate}&endDate=${endDate}`;

  // const route = `/organisation/8daf3f88-00b4-4f83-b49c-01c7801df2c6/analytics?unit=${timeUnit}&startDate=${startDate}&endDate=${endDate}`;
  // const route = `/organisation/eadf1637-85e3-4cbc-a1e4-0fb86ef4b83c/analytics?unit=${timeUnit}&startDate=${startDate}&endDate=${endDate}`;

  const result = api.get(route);

  return result;
};
