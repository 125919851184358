import React from 'react';
import {Box} from "@mui/material";
import SenderParam from "./SenderParam";
import TemplateParam from "./TemplateParam";
import Grid from "@mui/material/Unstable_Grid2";
import {Param} from "../../../../types";

type Props = {
  organisationId: string
  setParam: React.Dispatch<React.SetStateAction<Param>>
}

const SmsParams = ({organisationId, setParam}: Props) => {

  return (
      <Box sx={{minWidth: 150}}>
        <Grid
            container
            direction="column"
        >
          <Grid xs={12}>
            <SenderParam
                organisationId={organisationId}
                setParam={setParam}
            />
          </Grid>
          <Grid xs={12} mt={2}>
            <TemplateParam
                organisationId={organisationId}
                setParam={setParam}
            />
          </Grid>
          {/*//TODO figure out how to deep link to sms settings */}
          {/*<Grid xs={12} mt={2}>*/}
          {/*  <Link to="/sms/settings" target="_blank" rel="noopener noreferrer">*/}
          {/*    Configure SMS Settings*/}
          {/*  </Link>*/}
          {/*</Grid>*/}
        </Grid>
      </Box>
  );
}

export default SmsParams;