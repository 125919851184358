import React, {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import api from "utils/api";
import {
  ReactComponent as AddLandingIcon
} from "assets/images/add-landing-icon.svg";
import DeletePopup from "../components/DeletePopup";
// import PageSelector from "components/widget/pageSelector";
import PageNavigator from "../components/PageNavigator";
import LandingPageItem from "./LandingPageItem";
// import Popup from "components/widget/popup";
import CreateNewPopup from "../components/CreateNewPopup";

import "assets/css/design/landing-pages.scss";
import PageTitle from "components/PageTitle";
// import { Dropdown } from "components/widget/dropdown";

const ITEMS_PER_PAGE = 5;

const LandingPages = (props) => {
  const {organisationId, setPopupType, type, disableCreate} = props;

  const [showCreateNewPopup, setShowCreateNewPopup] = React.useState(false);

  const [landingPages, setLandingPages] = useState([]);
  const [landingPageToBeDeleted, setLandingPageToBeDeleted] = useState();
  const [selectedPoster, setSelectedPoster] = useState(null);
  const [searchTerm, setSearchTerm] = useState();
  // const [statusFilter, setStatusFilter] = useState({ name: "All", value: 3 });
  const [filteredLandingPages, setFilteredLandingPages] =
      useState(landingPages);
  const [currentItems, setCurrentItems] = React.useState(null);

  const [pageSelected, setPageSelected] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [disabled, setDisabled] = useState(false);

  React.useEffect(() => {
    filteredLandingPages &&
    setTotalPages(Math.ceil(filteredLandingPages.length / ITEMS_PER_PAGE));
  }, [filteredLandingPages]);

  React.useEffect(() => {
    const indexOfLastPost = pageSelected * ITEMS_PER_PAGE;
    const indexOfFirstPost = indexOfLastPost - ITEMS_PER_PAGE;

    setCurrentItems(
        filteredLandingPages.slice(indexOfFirstPost, indexOfLastPost)
    );
  }, [pageSelected, filteredLandingPages]);

  const history = useHistory();
  const match = useRouteMatch();

  const createProject = async (landingPageId, landingPageName) => {
    const project = {};
    project.name = landingPageName;
    project.externalId = landingPageId;
    project.description = "";

    await api.post("/media", project);
  };

  const savePoster = async (name) => {
    const poster = {
      name: name,
      backgroundColor: "#E73E1C",
      accentColor: "#F29739",
      message:
          "We take pride in providing excellent customer service and would love to hear what you think and if we can improve on any of our services. Please let us know your opinions by clicking the link in the email you receive from us, or by leaving a Google or Trustpilot review.",
    };
    const response = await api.post(
        `/organisations/${organisationId}/posters`,
        poster
    );
    createProject(response.data.externalId, name);
    history.push({
      pathname: `${match.path}/poster/${response.data.externalId}`,
      state: {create: true},
    });
  };

  const handleSelectActive = (posterId) => {
    const updatedLandingPages = landingPages.map((landingPage) => {
      if (landingPage.externalId === posterId) {
        landingPage.active = !landingPage.active;
        return landingPage;
      } else {
        landingPage.active = false;
        return landingPage;
      }
    });

    setLandingPages(updatedLandingPages);
  };

  // const toggleLandingStatus = async () => {
  //   try {
  //     setIsActive(!isActive);
  //     await api.put(
  //       `/organisations/${organisationId}/landingPages/${landingPage.externalId}/active`,
  //       { active: !isActive }
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   if (statusFilter.name === "All") {
  //     setFilteredLandingPages(landingPages);
  //   } else {
  //     const activeLandingPages = landingPages.filter(
  //       (landing) => landing.active === statusFilter.value
  //     );
  //     setFilteredLandingPages(activeLandingPages);
  //   }
  // }, [statusFilter]);

  const fetchLandingPages = async () => {
    const url = `/organisations/${organisationId}/posters`;

    const result = await api.get(url);
    setLandingPages(result.data);
    setFilteredLandingPages(result.data);
  };

  const deleteLandingpage = async () => {
    await api.delete(
        `/organisations/${organisationId}/posters/${landingPageToBeDeleted.externalId}`
    );

    setLandingPages([
      ...landingPages.filter(
          (landingpage) => landingpage.externalId !== landingPageToBeDeleted
      ),
    ]);
    setLandingPageToBeDeleted(null);
    fetchLandingPages();
  };

  useEffect(() => {
    setDisabled(disableCreate);
  }, [disableCreate]);

  useEffect(() => {
    const fetchData = async () => {
      fetchLandingPages();
    };
    fetchData();
  }, [organisationId]);

  const onKeyPressEnter = (event) => {
    if (event.key === "Enter") {
      setPopupType(type);
    }
  };

  const onChangeTextFilter = (searchText) => {
    if (searchText !== "") {
      const newFiltedPages = landingPages.filter((landing) =>
          landing.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredLandingPages(newFiltedPages);
    } else {
      setFilteredLandingPages(landingPages);
    }
  };

  React.useEffect(() => {
    const activePage = landingPages.filter((page) => {
      return page?.active === true;
    });

    activePage.length > 0
        ? setSelectedPoster(activePage[0].externalId)
        : setSelectedPoster(null);
  }, [landingPages]);

  return (
      <div>
        <div className="landing-page-header">
          <PageTitle title={"Manage WiFi Poster"}></PageTitle>
          {totalPages && totalPages > 1 ? (
              <PageNavigator
                  setPageSelected={setPageSelected}
                  pageSelected={pageSelected}
                  totalPages={totalPages}
              />
          ) : null}
          <div className="landing-page-search-inputs">
            <input
                value={searchTerm}
                type="search"
                placeholder="Search"
                className="quick-send__input--with-info"
                onChange={(e) => onChangeTextFilter(e.target.value)}
            />
          </div>
        </div>

        <div className="landing-pages">
          <div className="landing-pages-flex-container">
            <div
                style={disabled ? {
                  pointerEvents: 'none',
                  opacity: 0.4
                } : {}}
                className="landing-page-add-item"
                // onClick={() => setShowCreateNewPopup(true)}
                onClick={() => setPopupType("poster")}
                tabIndex="0"
                onKeyDown={(event) => onKeyPressEnter(event)}
            >
              <div className="add-card-content">
                <div className="add-card-content-image">
                  <AddLandingIcon/>
                </div>
                <label>Create New Wifi Poster</label>
              </div>
            </div>
            {currentItems &&
                currentItems.map((landingPage) => (
                    <LandingPageItem
                        itemId={landingPage.externalId}
                        key={landingPage.externalId}
                        landingPage={landingPage}
                        type={type}
                        handleSelectActive={handleSelectActive}
                        onDelete={(landingPage) =>
                            setLandingPageToBeDeleted(landingPage)
                        }
                        selectedPoster={selectedPoster}
                    />
                ))}
          </div>
          {landingPageToBeDeleted && (
              <DeletePopup
                  onCancel={() => setLandingPageToBeDeleted(null)}
                  onDelete={deleteLandingpage}
                  landingName={landingPageToBeDeleted.name}
              />
          )}
        </div>

        {showCreateNewPopup && (
            <CreateNewPopup
                onCancel={() => setShowCreateNewPopup(false)}
                onCreate={savePoster}
            />
        )}
      </div>
  );
};

export default LandingPages;
