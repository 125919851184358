import React, { useState, useEffect } from 'react'

import { ReactComponent as CloseIcon } from 'assets/images/delete.svg';

import 'assets/css/sms/reports/message-info-popup.scss'

export default ({ message, close }) => {

    const [sendTime, setSendTime] = useState()

    useEffect(() => {
        if (message && message.createdOn) {
            const createdOn = message.createdOn
            setSendTime(`${createdOn.getFullYear()}-${createdOn.getMonth() < 10 ? '0' + createdOn.getMonth() : createdOn.getMonth()}-${createdOn.getDate() < 10 ? '0' + createdOn.getDate() : createdOn.getDate()} ${createdOn.getHours() < 10 ? '0' + createdOn.getHours() : createdOn.getHours()}:${createdOn.getMinutes() < 10 ? '0' + createdOn.getMinutes() : createdOn.getMinutes()}:${createdOn.getSeconds() < 10 ? '0' + createdOn.getSeconds() : createdOn.getSeconds()}`)
        }
    }, message)

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    return (
        <div className='message-info-popup' onClick={onClick}>
            <div className='message-info-popup-content' >
                <CloseIcon className='close-icon' onClick={close} />
                <label className='title'>Message Information</label>
                <div className='message-info'>
                    <label>ID</label>
                    <label>{message.externalId}</label>
                </div>
                <div className='message-info grey'>
                    <label>Originator</label>
                    <label>{message.from}</label>
                </div>
                <div className='message-info'>
                    <label>Send time</label>
                    <label>{sendTime}</label>
                </div>
                <div className='message-info grey'>
                    <label>Message</label>
                    <label>{message.body}</label>
                </div>
            </div>
        </div>
    )
}