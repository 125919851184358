import actions from "../actions";

const {
  GET_SEGMENTS_REQUEST,
  GET_SEGMENTS_FAILURE,
  GET_SEGMENTS_SUCCESS,
  SELECT_SEGMENT_REQUEST,
  SELECT_SEGMENT_FAILURE,
  SELECT_SEGMENT_SUCCESS,
  GET_SEGMENT_CONTACTS_REQUEST,
  GET_SEGMENT_CONTACTS_FAILURE,
  GET_SEGMENT_CONTACTS_SUCCESS,
  TOGGLE_SEGMENT_CONTACT_REQUEST,
  TOGGLE_SEGMENT_CONTACT_FAILURE,
  TOGGLE_SEGMENT_CONTACT_SUCCESS,
  TOGGLE_ALL_SEGMENT_CONTACTS_SUCCESS,
  TOGGLE_ALL_SEGMENT_CONTACTS_REQUEST,
  TOGGLE_ALL_SEGMENT_CONTACTS_FAILURE,
  CREATE_SEGMENT_REQUEST,
  CREATE_SEGMENT_FAILURE,
  CREATE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_REQUEST,
  DELETE_SEGMENT_FAILURE,
  DELETE_SEGMENT_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  currentPagination: null,
  //
  allSegments: null,
  allSegmentsContacts: null,
  //
  selectedSegment: null,
  selectedSegmentContacts: null,
  //
  createdSegment: null,
  deletedSegmentId:null
};

export default function segmentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SEGMENTS_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
        allSegments: null,
      };
    }

    case GET_SEGMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        allSegments: null,
      };
    }

    case GET_SEGMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allSegments: action.payload,
      };
    }

    ///---
    case SELECT_SEGMENT_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case SELECT_SEGMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SELECT_SEGMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedSegment: action.payload.selectedSegment,
      };
    }

    ///---
    case GET_SEGMENT_CONTACTS_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case GET_SEGMENT_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_SEGMENT_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedSegmentContacts: action.payload.segmentContacts,
        currentPagination: action.payload.currentPagination,
      };
    }

    ///---
    case TOGGLE_SEGMENT_CONTACT_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        // selectedSegmentContacts: null,
      };
    }

    case TOGGLE_SEGMENT_CONTACT_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        selectedSegmentContacts: null,
      };
    }

    case TOGGLE_SEGMENT_CONTACT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,

        selectedSegmentContacts: {
          ...state.selectedSegmentContacts,
          [action.payload.contactId]: {
            ...state.selectedSegmentContacts[action.payload.contactId],
            selected:
              !state.selectedSegmentContacts[action.payload.contactId].selected,
          },
        },
      };
    }

    ///---
    case CREATE_SEGMENT_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case CREATE_SEGMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case CREATE_SEGMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        createdSegment: action.payload,
      };
    }

    ///---
    case DELETE_SEGMENT_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case DELETE_SEGMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case DELETE_SEGMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        deletedSegmentId: action.payload.deletedSegmentId,
      };
    }

    //---

    case TOGGLE_ALL_SEGMENT_CONTACTS_REQUEST: {
      return {
        ...state,
        // loading: true,
        errorMessage: null,
      };
    }

    case TOGGLE_ALL_SEGMENT_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case TOGGLE_ALL_SEGMENT_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedSegmentContacts: {
          ...action.payload.updatedSegmentSelectedContacts,
        },
      };
    }

    default:
      return state;
  }
}
