import React, {useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {Importer, ImporterField} from "react-csv-importer";
import {from} from 'rxjs';
import api from "../../../../../../../../utils/api";
import {toast} from "react-toastify";
import 'react-csv-importer/dist/index.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "25px",
    maxWidth: "600px",
    zIndex: 1,
  },
  stack: {
    zIndex: 2,
    width: '600px'
  }
}));

const ImportListModal = (props) => {
  const {
    organisationId,
    loadContacts,
    getAllContactIds,
    setOpenBulkUpload
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseBulkUpload = () => {
    setOpenBulkUpload(false);
  };

  return (
      <div className={classes.stack}>
        <Importer
            width={500}
            assumeNoHeaders={false}
            restartable={false}
            processChunk={(rows, {startIndex}) => {
              if (rows && !error) {
                from(api.post('/contacts/import', {
                  organisationId: organisationId,
                  registrationType: 'EMAIL',
                  rows: rows
                }))
                .subscribe({
                  error: (err: any) => {
                    console.error('Error')
                    console.error(err)
                    setError(err);
                  }
                })
              }
            }}
            onComplete={({file, preview, fields, columnFields}) => {
              if (error) {
                toast.error('Error importing contacts');
              }
            }}
            onClose={({file, preview, fields, columnFields}) => {
              loadContacts();
              getAllContactIds();
              handleCloseBulkUpload();
            }}
        >
          <ImporterField name="firstName" label="First Name"/>
          <ImporterField name="lastName" label="Last Name"/>
          <ImporterField name="email" label="Email"/>
          <ImporterField name="mobilePhone" label="Phone Number" optional/>
          <ImporterField name="dateOfBirth" label="Date of Birth" optional/>
          <ImporterField name="gender" label="Gender" optional/>
        </Importer>
      </div>
  );
};

export default ImportListModal;
