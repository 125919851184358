import React from "react";
import styled from "@emotion/styled";
import Toggle from "components/Toggle";

import ScheduleFieldTimeInputs from "./ScheduleFieldTimeInputs";

import {Info} from "phosphor-react";

// [{ frequency: "ONCE", date: minFromNow }];

const ScheduleFieldWrapper = styled.div`
  width: 100%;
  span {
    color: red;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 175px;
  .title {
    padding-left: 5px;
  }
`;

const ScheduleField = (props) => {
  const [isScheduleOn, setIsScheduleOn] = React.useState(false);

  const [scheduleDate, setScheduleDate] = React.useState(null);

  const { change, ScheduleFieldValue } = props;

  React.useEffect(() => {
    change("campaignSchedule", {
      ...ScheduleFieldValue,
      active: isScheduleOn,
    });
  }, [isScheduleOn]);

  return (
    <ScheduleFieldWrapper>
      <TitleWrapper>
        <Info size={18} color={"#5d6161"} />
        <h4 className="title">Schedule</h4>
        <div style={{ marginLeft: "25px" }}>
          <Toggle
            enabledTrackColor="#FFCC33"
            disabledTrackColor="#C4C4C4"
            className="toggle"
            defaultChecked={false}
            onChange={() => setIsScheduleOn(!isScheduleOn)}
          />
        </div>
      </TitleWrapper>

      {isScheduleOn && (
        <ScheduleFieldTimeInputs
          scheduleDate={scheduleDate}
          setScheduleDate={setScheduleDate}
          change={props.change}
          ScheduleFieldValue={props.input.value}
        />
      )}
    </ScheduleFieldWrapper>
  );
};

export default ScheduleField;
