import React from 'react'
import { useHistory } from "react-router-dom";

import { ReactComponent as BackIcon } from '../../assets/images/back-icon.svg';

import '../../assets/css/widget/back-button.scss'

export const BackButton = () => {

    const history = useHistory()

    return (
        <div className='back-btn' onClick={() => history.goBack()}>
            <BackIcon className='back-icon' />
            <label>BACK</label>
        </div>
    )
}