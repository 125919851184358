import React from "react";

import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";

import DashboardLoginsWrapper from "./DashboardLoginsWrapper";

import {COLORS, DEFAULT_DATA} from "./config";

const DashboardLogins = (props) => {
  const { selectedEntitySummary } = props;

  const [twitter, setTwitter] = React.useState(0);
  const [facebook, setFacebook] = React.useState(0);
  const [email, setEmail] = React.useState(0);
  const [data, setData] = React.useState([]);

  const ref = React.useRef();

  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  const [currentStatsData, setCurrentStatsData] = React.useState(null);

  React.useEffect(() => {
    setHeight(ref.current.clientHeight);
    setWidth(ref.current.clientWidth);
  }, [ref]);

  React.useEffect(() => {
    selectedEntitySummary
      ? setCurrentStatsData(selectedEntitySummary)
      : setCurrentStatsData(DEFAULT_DATA);
  }, [selectedEntitySummary]);

  React.useEffect(() => {
    if (currentStatsData) {
      currentStatsData?.signups && delete currentStatsData.signups["sms_added"];
      const total = Object.entries(currentStatsData.signups)
        .map(([key, value]) => value.total)
        .reduce((c, t) => (t += c));
      setTwitter(
        Math.round((currentStatsData.signups.twitter.total * 100) / total) || 0
      );
      setFacebook(
        Math.round((currentStatsData.signups.facebook.total * 100) / total) || 0
      );
      setEmail(
        Math.round((currentStatsData.signups.email.total * 100) / total) || 0
      );
    }
  }, [currentStatsData]);

  React.useEffect(() => {
    setData([{ value: twitter }, { value: facebook }, { value: email }]);
  }, [twitter, facebook, email]);

  return (
    <DashboardLoginsWrapper ref={ref}>
      <div className="customer-logins-widget-container">
        <label className="customer-logins-widget-title">Customer Logins</label>
        <div
          className="customer-logins-content"
          style={{
            flexDirection: width < 360 && height > 200 ? "column" : "row",
          }}
        >
          {width !== 0 ? (
            <div className="cl-chart">
              <ResponsiveContainer
                width="99%"
                height="99%"
                minWidth="0"
                minHeight="0"
              >
                <PieChart>
                  <Pie data={data} dataKey='value'>
                    {data.map((entry, index) => {
                      return (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index]}
                          stroke={COLORS[index]}
                        />
                      );
                    })}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div></div>
          )}
          <div
            className="cl-stats"
            style={{
              alignSelf: width < 360 && height > 200 ? "center" : "unset",
            }}
          >
            <div className="cl-stat">
              <img
                style={{
                  width: height > 300 ? 30 : height / 10,
                  height: height > 300 ? 35 : height / 10,
                }}
                src={require("assets/images/twitter-login.svg").default}
               alt=''/>
              <label style={{ fontSize: width < 360 ? "12px" : "17px" }}>
                Twitter:{" "}
                <span style={{ fontSize: width < 360 ? "12px" : "17px" }}>
                  {twitter}%
                </span>
              </label>
            </div>
            <div className="cl-stat">
              <img alt=''
                style={{
                  width: height > 300 ? 30 : height / 10,
                  height: height > 300 ? 35 : height / 10,
                }}
                src={require("assets/images/fb-login.svg").default}
              />
              <label style={{ fontSize: width < 360 ? "12px" : "17px" }}>
                Facebook:{" "}
                <span style={{ fontSize: width < 360 ? "12px" : "17px" }}>
                  {facebook}%
                </span>
              </label>
            </div>
            <div className="cl-stat">
              <img alt=''
                style={{
                  width: height > 300 ? 30 : height / 10,
                  height: height > 300 ? 35 : height / 10,
                }}
                src={require("assets/images/email-login.svg").default}
              />
              <label style={{ fontSize: width < 360 ? "12px" : "17px" }}>
                Email:{" "}
                <span style={{ fontSize: width < 360 ? "12px" : "17px" }}>
                  {email}%
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </DashboardLoginsWrapper>
  );
};

export default DashboardLogins;
