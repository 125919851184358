export const GET_NETWORK_ORGANISATIONS_REQUEST =
  "GET_NETWORK_ORGANISATIONS_REQUEST";
export const GET_NETWORK_ORGANISATIONS_FAILURE =
  "GET_NETWORK_ORGANISATIONS_FAILURE";
export const GET_NETWORK_ORGANISATIONS_SUCCESS =
  "GET_NETWORK_ORGANISATIONS_SUCCESS";

export const GET_NETWORK_INDUSTRIES_REQUEST = "GET_NETWORK_INDUSTRIES_REQUEST";
export const GET_NETWORK_INDUSTRIES_FAILURE = "GET_NETWORK_INDUSTRIES_FAILURE";
export const GET_NETWORK_INDUSTRIES_SUCCESS = "GET_NETWORK_INDUSTRIES_SUCCESS";

//DELETE

export const DELETE_NETWORK_ORGANISATION_REQUEST =
  "DELETE_NETWORK_ORGANISATION_REQUEST";
export const DELETE_NETWORK_ORGANISATION_FAILURE =
  "DELETE_NETWORK_ORGANISATION_FAILURE";
export const DELETE_NETWORK_ORGANISATION_SUCCESS =
  "DELETE_NETWORK_ORGANISATION_SUCCESS";

//EXPORT

// --- GET NETWORK ORGANISATIONS

export const getNetworkOrganisationsRequest = (organisationId, page, name) => {


  return {
    type: GET_NETWORK_ORGANISATIONS_REQUEST,
    payload: {
      organisationId,
      page,
      name,
    },
  };
};

export const getNetworkOrganisationsFailure = (errorMessage) => ({
  type: GET_NETWORK_ORGANISATIONS_FAILURE,
  payload: errorMessage,
});

export const getNetworkOrganisationsSuccess = (allNetworkOrganisations) => ({
  type: GET_NETWORK_ORGANISATIONS_SUCCESS,
  payload: allNetworkOrganisations,
});

//GET NETWORK INDUSTRIES

export const getNetworkIndustriesRequest = () => ({
  type: GET_NETWORK_INDUSTRIES_REQUEST,
});

export const getNetworkIndustriesFailure = (errorMessage) => ({
  type: GET_NETWORK_INDUSTRIES_FAILURE,
  payload: errorMessage,
});

export const getNetworkIndustriesSuccess = (networkIndustries) => ({
  type: GET_NETWORK_INDUSTRIES_SUCCESS,
  payload: networkIndustries,
});

//DELETE NETWORK ORGANISATIOn

export const deleteNetworkOrganisationRequest = (organisationId) => ({
  type: DELETE_NETWORK_ORGANISATION_REQUEST,
  payload: organisationId,
});

export const deleteNetworkOrganisationFailure = (errorMessage) => ({
  type: DELETE_NETWORK_ORGANISATION_FAILURE,
  payload: errorMessage,
});

export const deleteNetworkOrganisationSuccess = () => ({
  type: DELETE_NETWORK_ORGANISATION_SUCCESS,
});
