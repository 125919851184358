import React, {useState} from "react";

import {Check, X} from "phosphor-react";

import DeletePopupTemplateWrapper from "./DeletePopupTemplateWrapper";

import DeleteButton from "./DeleteButton";

const DeleteTemplatePopup = (props) => {
  const { save, close } = props;

  const [name, setName] = useState();

  const [isNameValidated, setIsNameValidated] = React.useState(false);

  React.useEffect(() => {
    if (name && name.length > 0) {
      setIsNameValidated(true);
    } else {
      setIsNameValidated(false);
    }
  }, [name]);

  const saveTemplate = () => {
    save(name);
  };

  const onClick = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <DeletePopupTemplateWrapper onClick={onClick}>
      <div className="add-email-campaign-popup-content">
        <X className="close-btn" onClick={close} size={22} />
        <label className="title">Name your Campaign</label>
        <input
          placeholder="Campaign Name"
          autoFocus
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
        />
        <DeleteButton
          isNameValidated={isNameValidated}
          onClick={() =>
            isNameValidated ? saveTemplate() : console.log("please fill name")
          }
        >
          <label>Delete</label>
          <Check className="check" color="white" onClick={close} size={20} />
        </DeleteButton>
      </div>
    </DeletePopupTemplateWrapper>
  );
};

export default DeleteTemplatePopup;
