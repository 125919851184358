import React from "react";
import styled from "@emotion/styled";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const GenderFieldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    // gap: 24px;
    width: 100%;
    height: 42px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
`;

const GenderField = (props) => {
  const {change, flex} = props;
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    change("gender", event.target.value)
  };

  return (
      <>
        <GenderFieldWrapper>
          <FormControl>
            <RadioGroup row sx={{gap: '20px'}}
                        onChange={handleChange}
                        value={value}
            >
              <FormLabel sx={{
                marginTop: '10px',
                marginRight: '40px',
                marginLeft: '10px',
                gap: '204px',
                color: 'black !important'
              }}>Gender: </FormLabel>
              <FormControlLabel value="male"
                                control={<Radio sx={{
                                  '&.Mui-checked': {
                                    color: "#FF9933",
                                  },
                                }}/>} label="Male"/>

              <FormControlLabel value="female"
                                control={<Radio sx={{
                                  '&.Mui-checked': {
                                    color: "#FF9933",
                                  },
                                }}/>}
                                label="Female"/>
              <FormControlLabel value="other" control={<Radio sx={{
                '&.Mui-checked': {
                  color: "#FF9933",
                },
              }}/>} label="Other"/>
            </RadioGroup>
          </FormControl>
        </GenderFieldWrapper>

      </>

  );
};

export default GenderField;
