import React from "react";

const WriteMessageField = (props) => {
  const { setMessage, message } = props;
  return (
    <div className="quick-send__text-area-container">
      <label className="quick-send__text-area-label">Write Message:</label>
      <textarea
        className="quick-send__text-area"
        placeholder="Maximum 160 characters"
        maxLength="160"
        onChange={(e) => setMessage(e.target.value)}
        value={message}
      />
      <p className="quick-send__text-counter">{message.length} / 160</p>
    </div>
  );
};

export default WriteMessageField;
