import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Step, StepLabel, Stepper} from "@mui/material";
import {AutomationRequest, AutomationSummary, getNewAutomation} from "../types";
import EventTypeSelect from "./EventTypeSelect";
import Actions from "./Actions";
import Schedule from "./Schedule";
import {createAutomation, getAutomation} from "../AutomationService";
import {toast} from "react-toastify";
import {useHistory, useParams} from "react-router-dom";
import NameSelect from "./NameSelect";
import AutomationDisplay from "./AutomationDisplay";
import {ampli} from "../../../containers/Amplitude/ampli";
import {debounce} from "lodash";
import uuid from "react-uuid";

type Props = {
  organisationId: string
}

const AutomationBuilder = ({organisationId}: Props) => {

  const {action, automationId} = useParams<any>();
  const history = useHistory();

  const [automation, setAutomation] = useState<AutomationRequest>();

  const [activeStep, setActiveStep] = useState(0);
  const [disabled, setDisabled] = useState<boolean>(false);

  const steps = ['Event Type', 'Actions', 'Schedule', 'Name'];

  const trackSteps = debounce(() => track(), 1000);

  useEffect(() => {
    trackSteps();

    switch (activeStep) {
      case 1: { // Actions
        setDisabled(automation?.actions.length === 0);
        break;
      }
      case 3: { // Name
        setDisabled(automation?.name.length === 0);
        break;
      }
      default:
        setDisabled(false);
    }
  }, [activeStep, automation])

  useEffect(() => {
    if (!automationId) {
      setAutomation(getNewAutomation());
    } else {
      getAutomation(automationId)
      .subscribe({
        next: (response: AutomationRequest) => {
          if (action === 'copy') {
            response.id = null;
          }
          setAutomation(response);
        },
        error: (err: any) => {
          console.error('Error');
          console.error(err);
        }
      })
    }
  }, [organisationId, action, automationId]);

  const saveAutomation = () => {
    ampli.unaroPageVisit(`page visit: Automation Builder: Save Automation'}`);
    setDisabled(true);

    createAutomation({
      ...automation,
      organisationId: organisationId
    })
    .subscribe({
      next: (response: AutomationSummary) => {
        toast.success(`Automation ${response.name} saved`,
            {onClose: () => history.push('/automation')});
      },
      error: (err: any) => {
        console.error(`Error: ${err ? JSON.stringify(err) : ''}`);
        toast.error("Automation creation failed");
        setDisabled(false);
      }
    });
  }

  const updateAutomation = (field: string, value: any): void => {
    setAutomation({
      ...automation,
      [field]: value
    })
  }

  const getStepContent = () => {
    switch (activeStep) {
      case 0: {
        return (
            <EventTypeSelect
                type={automation?.eventType}
                updateAutomation={updateAutomation}
            />
        )
      }
      case 1: {
        return (
            <Actions
                organisationId={organisationId}
                actions={automation?.actions}
                updateAutomation={updateAutomation}
            />
        )
      }
      case 2: {
        return (
            <Schedule
                automation={automation}
                updateAutomation={updateAutomation}
            />
        )
      }
      case 3: {
        return (
            <NameSelect
                automation={automation}
                updateAutomation={updateAutomation}
            />
        )
      }
    }
  }

  const track = () => {
    switch (activeStep) {
      case 0:
        ampli.unaroPageVisit("page visit: Automation Builder: Event Type Select");
        break;
      case 1:
        ampli.unaroPageVisit("page visit: Automation Builder: Create Actions")
        break;
      case 2:
        ampli.unaroPageVisit("page visit: Automation Builder: Add Schedule")
        break;
      case 3:
        ampli.unaroPageVisit("page visit: Automation Builder: Add Name and Finish")
    }
  }

  return (
      <Box sx={{width: '100%'}}>
        <Stepper activeStep={activeStep}>
          {steps.map((step: string) => {
            return (
                <Step key={uuid()}>
                  <StepLabel key={uuid()}>{step}</StepLabel>
                </Step>
            );
          })}
        </Stepper>
        <>
          {activeStep === steps.length ? (
              <>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 2,
                  marginTop: 1,
                }}>
                  <Box sx={{flex: '1 1 auto'}}/>
                  <Button
                      onClick={() => history.push('/automation')}
                      color="error"
                      size="small"
                  >
                    Cancel
                  </Button>
                  <Box sx={{flex: '1 1 auto'}}/>
                  <Button
                      onClick={() => setActiveStep(0)}
                      color='error'
                      disabled={disabled}
                  >
                    Reset
                  </Button>
                  <Box sx={{flex: '1 1 auto'}}/>
                  <Button
                      onClick={saveAutomation}
                      disabled={disabled}
                  >
                    Save
                  </Button>
                  <Box sx={{flex: '1 1 auto'}}/>
                </Box>

                <AutomationDisplay automation={automation}/>

              </>
          ) : (
              <>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                  <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={() => setActiveStep(activeStep - 1)}
                      sx={{mr: 1}}
                  >
                    Back
                  </Button>

                  <Box sx={{flex: '1 1 auto'}}/>

                  <Button
                      onClick={() => history.push('/automation')}
                      color="error"
                      size="small"
                  >
                    Cancel
                  </Button>

                  <Box sx={{flex: '1 1 auto'}}/>

                  <Button
                      onClick={() => setActiveStep(activeStep + 1)}
                      disabled={disabled}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </>
          )}
          <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{marginTop: 2}}
          >
            <Grid item xs={3}>
              {getStepContent()}
            </Grid>

            {/*//TODO console.log("REMOVE ME") */}
            {/*<Grid marginTop={10}>*/}
            {/*  {automation && JSON.stringify(automation)}*/}
            {/*</Grid>*/}

          </Grid>
        </>
      </Box>
  );
}

export default AutomationBuilder;
