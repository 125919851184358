import React from "react";
import styled from "@emotion/styled";

const CampaignCardHeaderWrapper = styled.label`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  padding: 25px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #c4c4c4;
`;

const CampaignCardHeader = ({ campaignName }) => {
  return <CampaignCardHeaderWrapper>{campaignName}</CampaignCardHeaderWrapper>;
};

export default CampaignCardHeader;
