import React from "react";
import styled from "@emotion/styled";

import NavigatorNumber from "./NavigatorNumber";

import getNumberNavigationButtons from "./utils/getNumberNavigationButtons";

const NavigatorWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
`;

const Navigator = (props) => {
  const {
    currentPagination,
    setNavigatorPage,
    // featuredTemplatesPagination,
    // savedTemplatesPagination,
  } = props;

  const numberOfPages = currentPagination.totalPages;

  const currentPage = currentPagination.number;

  const numberButtons = getNumberNavigationButtons(numberOfPages, currentPage);

  return (
    <NavigatorWrapper>
      {/* <button onClick={() => prevPage(currentPage)}>PREV</button> */}
      <div className="number-navigation">
        {numberButtons.map((number, index) => {
          return (
            <NavigatorNumber
              currentPage={currentPage}
              key={number.id}
              navigator={number}
              // onClick={() => fetchAction(organisationId, index)}
              onClick={() => setNavigatorPage(index)}
            />
          );
        })}
      </div>
      {/* <button onClick={() => nextPage(currentPage)}>NEXT</button> */}
    </NavigatorWrapper>
  );
};

export default Navigator;
