import React from "react";
import styled from "@emotion/styled";

const CalendarPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  border: 2px solid blue;
  right: 50%;
  top: -60%;
  transform: translate(50%, -50%);
  justify-content: flex-start;
  align-items: flex-start;

  background-color: white;
  border: 1px solid #c4c4c4;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  padding: 20px 30px;

  z-index: 2;

  .calendar-popup-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 16px;
  }

  label {
    width: 100%;
    clear: both;
    /* overflow: hidden; */
    white-space: nowrap;
    font-size: 14px;
    font-family: Roboto;
    /* margin-bottom: 20px; */
    color: #000000;
    align-self: flex-start;

    .calendar-popup-campaign-title,
    .calendar-popup-campaign-receiver {
      font-family: Roboto Bold;
      color: #5b5b5b;
      margin: 0;
      padding: 0;
      margin-top: 5px;
      font-weight: 700;
      white-space: nowrap;
      font-size: 14px;
      font-family: Roboto;
    }

    .calendar-popup-campaign-title {
      span {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .calendar-popup-campaign-receiver {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;

      span {
        font-family: Roboto Bold;
      }
    }
  }

  button {
    align-self: center;
    color: white;
    background-color: #ea5454;
    outline: 0;
    border-radius: 30px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    margin-top: 20px;
  }
`;

const CalendarPopup = (props) => {
  const { selectedDay, day } = props;

  const { campaigns } = selectedDay;

  return (
    <CalendarPopupWrapper>
      {selectedDay &&
        campaigns.map((campaign) => {
          const { name, formattedScheduleDate } = campaign;

          return (
            <div className="calendar-popup-inner-wrapper">
              <label>
                <p className="calendar-popup-campaign-title">
                  {name} <span>scheduled for</span>{" "}
                  {formattedScheduleDate.format("hh:mm")}
                </p>

                <p className="calendar-popup-campaign-receiver">
                  Sending to: <span>All</span>
                </p>
              </label>

              <button>Edit Campaign</button>
            </div>
          );
        })}
    </CalendarPopupWrapper>
  );
};

export default CalendarPopup;
