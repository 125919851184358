import React from 'react'
import '../../assets/css/integrations/add-integrations-item.scss'

const AddIntegrationItem = ({disabled = true, icon, name, onClick, allSet}) => {

  return (
      <div className='add-integrations-item'>
        <img alt="add integrations" src={icon}/>
        <label>{name}</label>
        <button
            disabled={disabled || allSet}
            onClick={onClick}
        >
          {disabled ? 'Coming soon' : allSet ? 'All set' : 'Add integration'}
        </button>
      </div>
  )
}

export default AddIntegrationItem;