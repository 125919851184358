import React, { useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

// import SwitchBtn from "components/widget/switchBtn";
// import api from "utils/api";
import { ReactComponent as CalendarIcon } from "assets/images/lightGrayCalendar.svg";
import { ReactComponent as EditIcon } from "assets/images/lightGrayEdit.svg";
import { ReactComponent as DeleteIcon } from "assets/images/lightGrayDelete.svg";
// import { ReactComponent as DownloadIcon } from "assets/images/downloadIcon.svg";

import "assets/css/design/landing-page-item.scss";

const LandingPageItem = (props) => {
  const {
    landingPage,
    type,
    onDelete,
    // onEdit,
    // handleSelectActive,
    // selectedPoster,
    // itemId,
  } = props;
  const match = useRouteMatch();
  const history = useHistory();
  // const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(
    JSON.parse(localStorage.getItem("selected-organisation"))
  );

  const user = JSON.parse(localStorage.getItem("user"));
  const adminUser = user.organisations[0].orgType !== "ORGANISATION";
  const organisationId =
    adminUser && selected && selected.orgType === "ORGANISATION"
      ? selected.externalId
      : user.organisations[0].externalId;

  const getLogo = () => {
    if (!landingPage || !landingPage.creatives)
      return require("assets/images/rsz_landing_page.png").default;
    const creative = landingPage.creatives.find((creative) =>
      (creative.type === type) === "poster" ? "POSTER_LOGO" : "LOGO"
    );
    return creative
      ? creative.url
      : require("assets/images/rsz_landing_page.png").default;
  };

  const CalendarButton = ({ value, onClick }) => (
    <CalendarIcon onClick={onClick} className="landing-page-item__calendar">
      {value}
    </CalendarIcon>
  );


  // const toggleLandingStatus = async () => {
  //   try {
  //     setIsActive(!isActive);
  //     await api.put(
  //       `/organisations/${organisationId}/landingPages/${landingPage.externalId}/active`,
  //       { active: !isActive }
  //     );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <div
      className="landing-page-item"
      // style={{
      //   border:
      //     selectedPoster === itemId
      //       ? "3px solid #2BD947"
      //       : "3px solid transparent",
      // }}
    >
      <div className="landing-page-item__content">
        <div className="landing-page-item__left-content">
          <div className="landing-page-item__title-container">
            <p>{landingPage.name}</p>
            <hr />
          </div>
          <div className="landing-page-item__image-container">
            <img src={getLogo()} className="landing-page-item__image" alt="" />
          </div>
        </div>
        <div className="landing-page-item-status">
        
        </div>
        <div className="landing-page-item__actions">
          <button
            onClick={() =>
              history.push({
                pathname: `${match.path}/${landingPage.externalId}`,
                state: { create: false },
              })
            }
            className="landing-page-item__action-btn landing-page-item__action-btn-edit"
          >
            Edit
            <EditIcon />
          </button>
          <button
            className="landing-page-item__action-btn"
            onClick={() => onDelete(landingPage)}
          >
            Delete
            <DeleteIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPageItem;
