import React from "react";

import {ReactComponent as UnaroIcon} from "assets/images/full-icon.svg";
import DefaultJoinImage from "assets/images/defaultJoinImage.svg";

import JoinScreenWrapper from "./JoinScreenWrapper";
import LogoContainer from "../../components/LogoContainer";
import {
  greetingNameOptions
} from "../../../../../../../../containers/Connect/consts";

const JoinScreen = (props) => {
  const {settings} = props;
  const greeting = settings?.greetingMessage
      ? settings?.greetingMessage : "Hi";
  const chosenName = settings?.greetingName
      ? settings?.greetingName : greetingNameOptions[0].name;

  const welcomeText = settings?.welcomeText ? settings?.welcomeText :
      `Hi First name Last name we recognized you from X. would you like to join ${settings?.name}?`;

  const defaultTextColour = '#000000';
  const defaultButtonTextColour = '#ffffff';
  const defaultWelcomeJoinColor = '#ea5454';

  return (
      <JoinScreenWrapper opacity={settings?.opacity}>
        <div className="join-screen__main-content">
          <LogoContainer logo={settings?.logoImage?.url}/>
          <hr className="content-divider"/>
          <div className="join-screen__text-content">
            <label
                style={{
                  color: settings?.welcomeHeaderColor
                      ? settings?.welcomeHeaderColor
                      : defaultTextColour,
                }}
                className="join-screen__greeting"
            >
              {greeting} {chosenName}
            </label>
            <label
                className="join-screen__description"
                style={{
                  color: settings?.welcomeTextColor
                      ? settings?.welcomeTextColor
                      : defaultTextColour,
                  marginTop: "10px",
                  textAlign: "center",
                }}
            >
              {
                settings?.welcomeText
                    ? settings?.welcomeText
                    : welcomeText
              }
            </label>
          </div>
          <LogoContainer logo={
            settings?.welcomeImage?.url ?
                settings?.welcomeImage?.url
                : DefaultJoinImage
          }/>
          <div className="join-screen__button-container">
            <button
                className="join-screen__button join"
                style={{
                  color: settings?.welcomeButtonsTextColor
                      ? settings?.welcomeButtonsTextColor
                      : defaultButtonTextColour,
                  backgroundColor: settings?.welcomeJoinColor
                      ? settings?.welcomeJoinColor
                      : defaultWelcomeJoinColor,
                }}
            >
              Join
            </button>
          </div>
          <div className="powered-by">
            <label style={{
              color: defaultTextColour,
              fontSize: '8px'
            }}>
              Powered By
            </label>
            <div className="powered-by-unaro-icon">
              <UnaroIcon/>
            </div>
          </div>
        </div>
      </JoinScreenWrapper>
  );
};

export default JoinScreen;
