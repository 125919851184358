import React, {memo} from "react";

import DraggableItem from "./DraggableItem";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import type {DragUpdate} from "react-beautiful-dnd/src";
import type {Draggable, Input} from "../types";

const DraggableList = memo(
    function DraggableList({items}) {
      return items.map((input: Draggable, index: number) => (
          <DraggableItem
              key={input.key}
              id={input.id}
              text={input.text}
              index={index}
              amountOfItems={items?.length}
          />
      ));
    }
);

const FieldOrderPanel = (props) => {
  const {
    draggableItems,
    findSelectedInOrder,
    inputs,
    updateInputs
  } = props;

  function reorder(result: string[], startIndex: number,
      endIndex: number): string[] {
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const onDragEnd = (result: DragUpdate) => {
    if (!result.destination
        || result.destination.index === result.source.index) {
      return;
    }

    const keys: string[] = findSelectedInOrder(inputs)
    .map((i: Input): string => i.key);

    reorder(keys, result.source.index, result.destination.index)
    .forEach((key: string, index: number) => inputs[key].index = index);

    updateInputs(inputs);
  };

  return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <DraggableList items={draggableItems}/>
                {provided.placeholder}
              </div>
          )}
        </Droppable>
      </DragDropContext>
  );
};

export default FieldOrderPanel;
