import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

type Props = {
  deleteTemplate: () => void
  disabled: boolean
}

const DeleteTemplateButton = ({deleteTemplate, disabled}: Props) => {

  const [open, setOpen] = useState<boolean>(false);

  const onClick = () => {
    setOpen(false);
    deleteTemplate();
  }

  return (
      <>
        <Button onClick={() => setOpen(true)} disabled={disabled}>
          Delete
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Delete Template</DialogTitle>
          <DialogContent>
            Are you sure you want to delete template?
          </DialogContent>
          <DialogActions>
            <Button onClick={onClick}>Delete</Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default DeleteTemplateButton;
