import React, { useState } from "react";
import api from "utils/api";

import "assets/css/sms/contact/create-tag-popup.scss";

const EditTagPopup = (props) => {
  const {
    // createTag,
    close,
    organisationId,
    selectedTag,
    fetchContacts,
    fetchTags,
  } = props;

  const colors = [
    "#FFCC33",
    "#EA5459",
    "#FF9933",
    "#14A697",
    "#5AC040",
    "#D353C7",
    "#A83FCD",
    "#ffffff",
    "#2B2B2B",
  ];

  const [tag, setTag] = useState({ color: "#FFCC33", contacts: 0 });
  const [invalid, setInvalid] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  const editTag = async () => {
    const updatedTag = {
      name: tag.name,
      color: tag.color,
    };
    await api
      .put(
        `/organisations/${organisationId}/tags/${selectedTag.externalId}`,
        updatedTag
      )
      .then(async () => {
        await fetchContacts();
        await fetchTags();
        await close();
      });
  };

  return (
    <div className="create-tag-popup" onClick={onClick}>
      <div className="create-tag-popup-content">
        <div className="create-tag-top">
          <label>Edit tag</label>
          <img alt="delete" src={require("assets/images/delete.svg").default} onClick={close} />
        </div>
        <div className="create-tag-content">
          <label className="tag-name">Tag Name</label>
          <input
            autoFocus
            className={invalid ? "error-label" : ""}
            onChange={(e) => setTag({ ...tag, name: e.target.value })}
            defaultValue={selectedTag.name}
          />
          <div className="tag-colors">
            <label>Tag color:</label>
            {colors.map((c) => (
              <div
                className={`tag-color-container ${
                  tag.color === c && "tag-color-container-selected "
                }`}
                onClick={() => setTag({ ...tag, color: c })}
              >
                <div
                  style={{
                    backgroundColor: c,
                    borderColor: c === "#ffffff" ? "#C4C4C4" : c,
                  }}
                  className="tag-color"
                />
              </div>
            ))}
          </div>
          <div className="create-tag-actions">
            <button onClick={close}>Cancel</button>
            <button onClick={editTag}>Edit tag</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTagPopup;
