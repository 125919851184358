export const SET_CHART_TIME_UNIT_REQUEST = "SET_CHART_TIME_UNIT_REQUEST";
export const SET_CHART_TIME_UNIT_FAILURE = "SET_CHART_TIME_UNIT_FAILURE";
export const SET_CHART_TIME_UNIT_SUCCESS = "SET_CHART_TIME_UNIT_SUCCESS";

export const UPDATE_CHART_DATA_REQUEST = "UPDATE_CHART_DATA_REQUEST";
export const UPDATE_CHART_DATA_FAILURE = "UPDATE_CHART_DATA_FAILURE";
export const UPDATE_CHART_DATA_SUCCESS = "UPDATE_CHART_DATA_SUCCESS";

// --- SET DASHBOARD TIME

export const setChartTimeUnitRequest = (timeUnit) => ({
  type: SET_CHART_TIME_UNIT_REQUEST,
  payload: {
    timeUnit,
  },
});

export const setChartTimeUnitFailure = (errorMessage) => ({
  type: SET_CHART_TIME_UNIT_FAILURE,
  payload: errorMessage,
});

export const setChartTimeUnitSuccess = (timeUnit) => ({
  type: SET_CHART_TIME_UNIT_SUCCESS,
  payload: timeUnit,
});

// --- UPDATE_CHART_DATA

export const updateChartDataRequest = (timeUnit,chartData) => ({
  type: UPDATE_CHART_DATA_REQUEST,
  payload: {
    timeUnit,
    chartData
  },
});

export const updateChartDataFailure = (errorMessage) => ({
  type: UPDATE_CHART_DATA_FAILURE,
  payload: errorMessage,
});

export const updateChartDataSuccess = (timeUnit, mappedChartData) => ({
  type: UPDATE_CHART_DATA_SUCCESS,
  payload: {timeUnit,mappedChartData}
});
