import React, {useEffect} from "react";
import styled from "@emotion/styled";

import {Info} from "phosphor-react";

import AllText from "./AllText";
import SegmentsText from "./SegmentsText";
import AudiencesText from "./AudiencesText";

const ToFieldWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span {
    color: red;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 175px;

  .title {
    padding-left: 5px;
  }
`;

const ToField = (props) => {
  const {
    currentCampaign,
    currentContactsList,
    currentSegments,
    currentAudiences,
    selectedCustomers
  } = props;

  //  Update current segement
  useEffect(() => {
    if (selectedCustomers && selectedCustomers.length > 0) {
      currentCampaign.contacts = selectedCustomers;
    }
  }, []);

  return (
      <ToFieldWrapper>
        <TitleWrapper>
          <Info size={18} color={"#5d6161"}/>
          <h4 className="title">To</h4>
        </TitleWrapper>

        {currentCampaign?.contacts?.length > 0 ? (
            <div>
              {(() => {
                switch (currentContactsList) {
                  case "all":
                    return <AllText currentCampaign={currentCampaign}/>;

                  case "segments":
                    return (
                        <SegmentsText
                            currentSegments={currentSegments}
                            currentCampaign={currentCampaign}
                        />
                    );

                  case "audiences":
                    return (
                        <AudiencesText
                            currentAudiences={currentAudiences}
                            currentCampaign={currentCampaign}
                        />
                    );

                  default:
                    return <AllText currentCampaign={currentCampaign}/>;
                }
              })()}
            </div>
        ) : (
            <p>
              You dont have any contacts selected, please go to the previous
              screen
            </p>
        )}
      </ToFieldWrapper>
  );
};

export default ToField;
