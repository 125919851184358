import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import EditLandingPageDesign from "./EditLandingPageDesign";
import NewWifiLandingPageWrapper from "./NewWifiLandingPageWrapper";
import MobilePreview from "./MobilePreview";
import Top from "./Top";
import {
  RedirectPageType,
  SocialMedia
} from "../../../../containers/Connect/types";
import _ from "lodash";
import "assets/css/design/edit-landing-page.scss";
import {uploadMediaImage} from "../../../../containers/Connect/mediaAPI";

const initialPreviewScreens = [
  {
    id: 0,
    name: "login",
  },
  {
    id: 1,
    name: "join",
  },
];

const NewWifiLandingPage = (props) => {
  const {
    currentLandingPageStep,
    updateLandingPageStepRequest,
    getLandingPageRequest,
    currentLandingPage,
    updateLandingPageRequest,
    uploadMediaToHostRequest,
    saveLandingPageRequest
  } = props;

  const {landingPageId} = useParams();
  const [settings, setSettings] = useState({});

  useEffect(() => {
    // loads selected landingPageId to currentLandingPage
    getLandingPageRequest(landingPageId);
    // reset step to 1
    updateLandingPageStepRequest(1);
    // remove any existing uploaded media
    uploadMediaToHostRequest(null);
  }, []);

  useEffect(() => {
    // JSON ignores Symbols such as SocialMedia and RedirectPageType
    // These should be changed to enums when Typescript is available
    // The following converts string to the Symbol
    if (currentLandingPage && currentLandingPage.settings) {
      const socialMedia = {
        preferredSocialMedia: currentLandingPage.settings?.preferredSocialMedia
            ? SocialMedia[currentLandingPage.settings?.preferredSocialMedia]
            : SocialMedia.Default
      }
      const redirectPage = currentLandingPage.settings?.redirectPage;
      redirectPage.type = currentLandingPage.settings?.redirectPage?.type
          ? RedirectPageType[currentLandingPage.settings?.redirectPage?.type]
          : RedirectPageType.TEXT;

      setSettings({
        ...currentLandingPage.settings,
        ...socialMedia,
        ...redirectPage,
      });
    }
  }, [currentLandingPage]);

  const updateSettings = (field, value) => {
    const newSettings = settings ? settings : {};
    _.set(newSettings, field, value);
    setSettings({...settings, ...newSettings});
  }

  const [previewScreens] = useState(initialPreviewScreens);
  const [currentPreviewScreen, setCurrentPreviewScreen] =
      useState(previewScreens[0].id);

  const uploadImage = (file, mediaType) => {
    console.log("uploading image " + file.name);

    uploadMediaImage(file, {
      organisationId: currentLandingPage.organisationId,
      mediaType: mediaType,
      projectId: currentLandingPage.externalId,
      projectName: currentLandingPage.name
    })
    .then((imageResponse) =>
        updateSettings(`${imageResponse.type}Image`, imageResponse));
  };

  const saveLandingPage = () => {
    const clonedSettings = _.cloneDeep(settings);
    updateLandingPageRequest('settings', clonedSettings);
    saveLandingPageRequest(currentLandingPage);
  }

  return (
      <NewWifiLandingPageWrapper>
        <Top
            numberOfSteps={4}
            saveLandingPage={saveLandingPage}
            currentLandingPageStep={currentLandingPageStep}
            updateLandingPageStepRequest={updateLandingPageStepRequest}
        />

        <div className="inner-wrapper">
          <div className="edit">
            <EditLandingPageDesign
                {...props}
                settings={settings}
                updateSettings={updateSettings}
                currentPreviewScreen={currentPreviewScreen}
                setCurrentPreviewScreen={setCurrentPreviewScreen}
                uploadImage={uploadImage}
            />
          </div>

          <MobilePreview
              settings={settings}
              previewScreens={previewScreens}
              currentPreviewScreen={currentPreviewScreen}
              setCurrentPreviewScreen={setCurrentPreviewScreen}
              currentLandingPageStep={currentLandingPageStep}
          />
        </div>
      </NewWifiLandingPageWrapper>
  );
};

export default NewWifiLandingPage;
