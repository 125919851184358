import React from "react";

import {RadioButton} from "components/widget/radioButton";
import LogoContainer from "../../components/LogoContainer";

import RegistrationInputs from "./RegistrationInputs";
import StyleContainer from "./StyleContainer";

const EmailRegistrationScreen = (props) => {
  const {settings} = props;

  return (
      <StyleContainer opacity={settings?.opacity}>
        <div className="email-registration-screen">
          <div className="email-registration-screen-content">
            <div className="registration-top">

              <div className="registration-logo-container">
                <LogoContainer logo={settings?.logoImage?.url}/>
              </div>

              <div className="content-divider"/>

              <div className="registration-text">
                <label className="registration-title"
                       style={{color: settings?.textcolor}}>
                  Registration
                </label>
                <p style={{color: settings?.textcolor}}
                   className="registration-subtitle">
                  Please Enter Your Details Below
                </p>
              </div>
            </div>

            <RegistrationInputs settings={settings}/>

            <div className="marketing-opt-in">
              <RadioButton
                  buttonStlye={{height: 20, width: 20}}
                  selected={true}
              />
              <label className="privacy" style={{color: settings?.textcolor}}>
                Yes I would like to receive news, offers and promotions
              </label>
            </div>

            <div className="registration-buttons">
              <div
                  className="register-btn register-btn__back"
                  style={{
                    backgroundColor: settings?.emailBackButtonColor
                        ? settings?.emailBackButtonColor
                        : "#ffcc33",
                  }}
              >
                <label>Back</label>
              </div>
              <div
                  className="register-btn register-btn__continue"
                  style={{
                    backgroundColor: settings?.emailContinueButtonColor
                        ? settings?.emailContinueButtonColor
                        : "#ea5454",
                  }}
              >
                <label>Continue</label>
              </div>
            </div>
          </div>
        </div>
      </StyleContainer>
  );
};

export default EmailRegistrationScreen;
