export const adminCardsConfig = [
  {
    id: 0,
    bigIcon: require("assets/images/emails-icon.svg").default,
    title: "Total Emails Collected",
    number: 0,
    smallIcon: require("assets/images/calendar-icon.svg").default,
    text: "All time",
  },
  {
    id: 1,
    bigIcon: require("assets/images/person-icon.svg").default,
    title: "Total Accounts",
    number: 2,
    smallIcon: require("assets/images/calendar-icon.svg").default,
    text: "All time",
  },
  {
    id: 2,
    bigIcon: require("assets/images/position-icon.svg").default,
    title: "Total Venues",
    number: 0,
    smallIcon: require("assets/images/calendar-icon.svg").default,
    text: "All time",
  },
  {
    id: 3,
    bigIcon: require("assets/images/phone-icon.svg").default,
    title: "Total Devices",
    number: 0,
    smallIcon: require("assets/images/calendar-icon.svg").default,
    text: "All time",
  },
];

export const userCardsConfig = [
  {
    id: 0,
    bigIcon: require("assets/images/position-icon.svg").default,
    title: "Customers connected",
    number: "",
    smallIcon: require("assets/images/calendar-icon.svg").default,
    text: "All time",
  },
  {
    id: 1,
    bigIcon: require("assets/images/position-icon.svg").default,
    title: "Customer emails collected",
    number: 0,
    smallIcon: require("assets/images/calendar-icon.svg").default,
    text: "All time",
  },
  {
    id: 2,
    bigIcon: require("assets/images/phone-icon.svg").default,
    title: "Customer return rate",
    number: "0%",
    smallIcon: require("assets/images/calendar-icon.svg").default,
    text: "All time",
  },
  {
    id: 3,
    bigIcon: require("assets/images/phone-icon.svg").default,
    title: "New customers today",
    number: 0,
    smallIcon: require("assets/images/calendar-icon.svg").default,
    text: "All time",
  },
];
