import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";

import history from "./utils/history";
import reduxConfigStore from "./store";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const { store, persistor } = reduxConfigStore;

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Suspense fallback={<p>loading...</p>}>
            <App />
          </Suspense>
        </ConnectedRouter>
      </PersistGate>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
