import React from 'react'

import '../assets/css/suspend.scss'

export default () => {
    return (
        <div className='suspend-page'>
            <label>Your account has been suspended</label>
            <label>Get in touch with our accounts team at</label>
            <label>billing@unaro.co.uk</label>
        </div >
    )
}