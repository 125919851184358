import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import {
  getYearVisitsRequest,
  getMonthVisitsRequest,
  getWeekVisitsRequest,
  getDayVisitsRequest,
} from "./actions";

import {
  selectIsLoading,
  selectErrorMessage,
  selectYearVisits,
  selectMonthVisits,
  selectWeekVisits,
  selectDayVisits,
  selectChartMaxVal
} from "./selectors";

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  errorMessage: selectErrorMessage,
  yearVisits: selectYearVisits,
  monthVisits: selectMonthVisits,
  weekVisits: selectWeekVisits,
  dayVisits: selectDayVisits,
  chartMaxVal:selectChartMaxVal
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getYearVisitsRequest,
      getMonthVisitsRequest,
      getWeekVisitsRequest,
      getDayVisitsRequest,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
