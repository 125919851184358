import React, { useState, useEffect } from 'react'

import { useHistory, useLocation } from "react-router-dom";
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import Axios from 'axios';

import { isValidEmail } from '../utils/common'
import { Dropdown } from '../components/widget/dropdown'

import '../assets/css/signup.scss'

export default () => {

    const loc = useLocation()
    const history = useHistory()

    const productId = new URLSearchParams(loc.search).get('productId')

    const [verify, setVerify] = useState(false)
    const [organisation, setOrganisation] = useState({})
    const [errors, setErrors] = useState([])
    const [errorMessage, setErrorMessage] = useState()
    const [emailError, setEmailError] = useState()
    const [step, setStep] = useState(0)
    const [industries, setIndustries] = useState([])
    const [selectedIndustry, setSelectedIndustry] = useState()
    const [location, setLocation] = useState({ isPrimary: true })
    const [passwordError, setPasswordError] = useState()
    const [confirmPassword, setConfirmPassword] = useState()

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const changeForename = value => {
        setErrorMessage(null)
        setErrors(errors.filter(error => error !== 'forename'))
        setOrganisation({ ...organisation, forename: capitalize(value) })
    }

    const changeSurname = value => {
        setErrorMessage(null)
        setErrors(errors.filter(error => error !== 'surname'))
        setOrganisation({ ...organisation, surname: capitalize(value) })
    }

    const changeCompanyName = value => {
        setErrorMessage(null)
        setErrors(errors.filter(error => error !== 'companyName'))
        setOrganisation({ ...organisation, name: capitalize(value) })
    }

    const changeEmail = value => {
        setErrorMessage(null)
        setEmailError(null)
        setErrors(errors.filter(error => error !== 'email'))
        setOrganisation({ ...organisation, email: value })
    }

    const signup = async () => {
        var e = []
        if (!organisation.forename || organisation.forename.length < 3)
            e = [...e, 'forename']
        if (!organisation.surname || organisation.surname.length < 3)
            e = [...e, 'surname']
        if (!organisation.name || organisation.name.length < 3)
            e = [...e, 'companyName']
        if (!organisation.email || organisation.email.length < 3)
            e = [...e, 'email']

        setErrors(e)

        if (e.length > 0)
            setErrorMessage('Please fill all fields')
        else if (!isValidEmail(organisation.email)) {
            e = [...e, 'email']
            setEmailError('Invalid email address')
        }

        if (e.length === 0) {
            setErrors([])
            setVerify(true)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await Axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/industries`)
            setIndustries(data)
            setSelectedIndustry(data[0])
        }

        fetchData()
    }, [productId])

    const getComponent = (components, name) => {
        return components.find(c => c.types.includes(name)) || {}
    }

    const onSelectSuggestion = async (seggestion) => {
        const geocodes = await geocodeByPlaceId(seggestion.place_id)
        const geocode = geocodes[0]
        const latLng = await getLatLng(geocode)

        const address = {}
        // address.areaCode = getComponent(geocode.address_components, 'administrative_area_level_2').long_name
        const country = getComponent(geocode.address_components, 'country')
        address.country = country.long_name
        address.countryCode = country.short_name
        address.fullAddress = geocode.formatted_address
        address.fullAddressLine2 = ''
        address.googlePlaceId = geocode.place_id
        address.isBilling = true
        address.isPrimary = true
        address.lat = latLng.lat
        address.lon = latLng.lng
        address.name = geocode.address_components[0].short_name
        address.postcodeZipcode = getComponent(geocode.address_components, 'postal_code').long_name
        address.city = getComponent(geocode.address_components, 'postal_town').long_name

        setLocation(address)
    }

    const changeLocationFullAddress = (value) => {
        setErrorMessage(null)
        setErrors(errors.filter(error => error !== 'fullAddress'))
        setLocation({ ...location, fullAddress: value })
    }

    const changeLocationCity = (value) => {
        setErrorMessage(null)
        setErrors(errors.filter(error => error !== 'city'))
        setLocation({ ...location, city: value })
    }

    const changeLocationCountry = (value) => {
        setErrorMessage(null)
        setErrors(errors.filter(error => error !== 'country'))
        setLocation({ ...location, country: value })
    }

    const changeLocationPostcodeZipcode = (value) => {
        setErrorMessage(null)
        setErrors(errors.filter(error => error !== 'postcodeZipcode'))
        setLocation({ ...location, postcodeZipcode: value })
    }

    const changePhone = (value) => {
        setErrorMessage(null)
        setErrors(errors.filter(error => error !== 'phone'))
        setOrganisation({ ...organisation, phone: value })
    }

    const changePassword = (value) => {
        setPasswordError(null)
        setErrorMessage(null)
        setErrors(errors.filter(error => error !== 'password'))
        setOrganisation({ ...organisation, password: value })
    }

    const changeConfirmPassword = value => {
        setPasswordError(null)
        setErrorMessage(null)
        setErrors(errors.filter(error => error !== 'password'))
        setConfirmPassword(value)
    }

    const next = async () => {
        var e = []

        if (step === 0) {
            if (!organisation.phone || organisation.phone.length < 3)
                e = [...e, 'phone']
            if (!location.fullAddress || location.fullAddress.length < 3)
                e = [...e, 'fullAddress']
            if (!location.city || location.city.length < 3)
                e = [...e, 'city']
            if (!location.country || location.country.length < 3)
                e = [...e, 'country']
            if (!location.postcodeZipcode || location.postcodeZipcode.length < 3)
                e = [...e, 'postcodeZipcode']

            setErrors(e)

            if (e.length > 0)
                setErrorMessage('Please fill all fields')
        } else {
            if (!organisation.password || organisation.password.length < 3)
                e = [...e, 'password']

            if (e.length > 0)
                setErrorMessage('Please fill all fields')
            else if (!/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/.test(organisation.password)) {
                e = [...e, 'password']
                setPasswordError("Password is too weak")
            } else if (organisation.password !== confirmPassword) {
                e = [...e, 'password']
                setPasswordError("Passwords don't match")
            }
        }

        if (e.length === 0) {
            setErrors([])

            if (step === 0) {
                setStep(step + 1)
            } else {
                try {
                    const { data } = await Axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/organisations`,
                        {
                            name: organisation.name,
                            location: location,
                            industryExternalId: selectedIndustry.externalId,
                            owner: {
                                forename: organisation.forename,
                                surname: organisation.surname,
                                email: organisation.email,
                                password: organisation.password,
                                phone: organisation.phone,
                            },
                            productId: productId
                        }, { headers: { 'api_key': 'pk_live_FGHJYYTALAek2rwZ0ZHbrTDj00skByotel' } })

                    localStorage.setItem('user', JSON.stringify(data));
                    history.replace(`/card?productId=${productId}`)
                } catch (e) {
                    if (e.response && e.response.data && e.response.data.errorDescription)
                        setErrorMessage(e.response.data.errorDescription)
                }
            }
        }
    }

    return (
        <div className='signup-page'>
            {verify ?
                // <div className='verify-content'>
                //     <img src={require('../assets/images/verify-icon.svg').default} />
                //     <label className='verify-title'>Verify Email</label>
                //     <label className='verify-subtitle'>We’ve sent an email to <span>{organisation.email}</span><br />Click the link on the email to verify your account</label>
                // </div>
                <div className='verify-page'>

                    <div className='verify-page-content'>
                        <div className='signup-back-btn' onClick={() => step === 0 ? setVerify(false) : setStep(step - 1)}>
                            <img alt="back icon" src={require('../assets/images/back-icon.svg').default} />
                            <label>Back</label>
                        </div>
                        <label className='verify-title'>{step === 0 ? 'Almost there...' : 'Finally...'}</label>
                        {step === 0 ?
                            <div className='verify-form'>
                                <label className='verify-subtitle'>Just a few more details then you’re account<br />will be ready to go!</label>
                                <label className='verify-label'>Industry</label>
                                <Dropdown className='industries-dd' options={industries} selected={selectedIndustry} onSelect={setSelectedIndustry} />
                                <label className='verify-label'>Phone Number</label>
                                <input
                                    className={`verify-input ${errors.length > 0 && errors.includes('phone') && 'input-error'}`}
                                    value={organisation.phone || ''}
                                    onChange={e => changePhone(e.target.value)}
                                />
                                <label className='verify-label'>Address</label>
                                <div className='verify-search'>
                                    <GooglePlacesAutocomplete
                                        placeholder='Search...'
                                        autocompletionRequest={{

                                            componentRestrictions: {
                                                country: ['uk'],
                                            }
                                        }}
                                        onSelect={onSelectSuggestion}
                                    />
                                    <div className='vs-btn'>
                                        <label>Search</label>
                                        <img alt="search icon" src={require('../assets/images/search-icon.svg').default} />
                                    </div>
                                </div>
                                <input
                                    placeholder='Address line 1'
                                    className={`verify-input ${errors.length > 0 && errors.includes('fullAddress') && 'input-error'}`}
                                    value={location.fullAddress || ''}
                                    onChange={e => changeLocationFullAddress(e.target.value)}
                                />
                                <input
                                    placeholder='Address line 2'
                                    className='verify-input'
                                    value={location.fullAddressLine2 || ''}
                                    onChange={e => setLocation({ ...location, fullAddressLine2: e.target.value })}
                                />
                                <div className='va-bottom'>
                                    <input
                                        placeholder='City'
                                        className={`verify-input ${errors.length > 0 && errors.includes('city') && 'input-error'}`} value={location.city || ''}
                                        onChange={e => changeLocationCity(e.target.value)}
                                    />
                                    <input
                                        placeholder='Post code'
                                        className={`verify-input ${errors.length > 0 && errors.includes('postcodeZipcode') && 'input-error'}`} value={location.postcodeZipcode || ''}
                                        onChange={e => changeLocationPostcodeZipcode(e.target.value)}
                                    />
                                    <input
                                        placeholder='Country'
                                        className={`verify-input ${errors.length > 0 && errors.includes('country') && 'input-error'}`} value={location.country || ''}
                                        onChange={e => changeLocationCountry(e.target.value)}
                                    />
                                </div>
                            </div>
                            :
                            <div className='verify-form'>
                                <label className='verify-subtitle'>Now choose a memorable password.<span> Password<br />must contain number and capital letter.</span></label>
                                <div className='vp-inputs'>
                                    <div className='vp-input'>
                                        <label className='verify-label'>Create Password</label>
                                        <input
                                            type='password'
                                            className={`verify-input ${errors.length > 0 && errors.includes('password') && 'input-error'}`} value={organisation.password || ''}
                                            onChange={e => changePassword(e.target.value)}
                                        />
                                    </div>
                                    <div className='vp-input'>
                                        <label className='verify-label'>Confirm Password</label>
                                        <input
                                            type='password'
                                            className={`verify-input ${errors.length > 0 && errors.includes('password') && 'input-error'}`} value={confirmPassword || ''}
                                            onChange={e => changeConfirmPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {errorMessage && <label className='error-label'>{errorMessage}</label>}
                        {passwordError && <label className='error-label'>{passwordError}</label>}

                        <div className='vc-btn' onClick={next}>
                            <label>Continue</label>
                            <img alt="continue icon" src={require('../assets/images/continue-icon.svg').default} />
                        </div>
                    </div>
                </div>
                :
                <div className='signup-content'>
                    <label className='signup-title'>Welcome to Unaro</label>
                    <label className='signup-subtitle'>Already registered? <span onClick={() => history.push('/login')}>sign in</span></label>
                    <div className='signup-names'>
                        <div className='signup-name'>
                            <label className='signup-label'>First Name</label>
                            <input
                                className={`${errors.length > 0 && errors.includes('forename') && 'input-error'}`}
                                value={organisation.forename || ''}
                                onChange={e => changeForename(e.target.value)}
                            />
                        </div>
                        <div className='signup-name'>
                            <label className='signup-label'>Last Name</label>
                            <input
                                className={`${errors.length > 0 && errors.includes('surname') && 'input-error'}`}
                                value={organisation.surname || ''}
                                onChange={e => changeSurname(e.target.value)}
                            />
                        </div>
                    </div>
                    <label className='signup-label'>Company Name</label>
                    <input
                        className={`${errors.length > 0 && errors.includes('companyName') && 'input-error'}`}
                        value={organisation.name || ''}
                        onChange={e => changeCompanyName(e.target.value)}
                    />
                    <label className='signup-label'>Email</label>
                    <input
                        className={`${errors.length > 0 && errors.includes('email') && 'input-error'}`}
                        value={organisation.email || ''}
                        onChange={e => changeEmail(e.target.value)}
                    />

                    <label className='terms-services'>By clicking sign up I agree to unaro.co.uk <a href="#">Terms of Service</a> & <a href="#">Privacy Policy</a></label>
                    {errorMessage && <label className='error-label'>{errorMessage}</label>}
                    {emailError && <label className='error-label'>{emailError}</label>}

                    <button onClick={signup}>Sign Up</button>
                </div>
            }
        </div>
    )
}