import styled from "@emotion/styled";

const DeleteTemplatePopupWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .add-email-campaign-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    overflow: hidden;
    outline: 0;
    width: 400px;
    padding: 20px;

    .close-btn {
      width: 14px;
      height: 14px;
      cursor: pointer;
      align-self: flex-end;

      path {
        fill: #c4c4c4;
      }
    }

    .title {
      font-family: Roboto;
      font-weight: bold;
      font-size: 20px;
      font-weight: 700;
    }

    input {
      width: 80%;
      margin: 30px 0;
      border: 1px solid #c4c4c4;
      border-radius: 21.75px;
      padding: 7px 14px;
      font-size: 12px;
      outline: none;
    }
  }
`;

export default DeleteTemplatePopupWrapper;
