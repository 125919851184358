import React, { useState } from 'react'

import { ReactComponent as DropdownLogo } from '../../assets/images/dropdown-icon.svg';

import '../../assets/css/widget/multi-selector-dropdown.scss'


export default ({ options, onSelect, className, style, selected, placeholder }) => {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        if (options.length > 0)
            setOpen(!open)
    }

    const changeSelected = (option) => {
        onSelect(option)
    }

    return (
        <div className={`multi-selector-dropdown-container ${className}`} tabIndex="0" onBlur={() => setOpen(false)}>
            <div style={style} className={`dropdown ${open && 'dropdown-open'}`} onClick={() => toggleOpen()}   >
                <label>{selected || placeholder}</label>
                <DropdownLogo className='logo' />
            </div>
            {open &&
                <div className='options'>
                    {options.map(option =>
                        <div className='option' key={option.externalId} onClick={() => changeSelected(option)}>
                            <label>{option.name}</label>
                            <div className='selector'>{option.selected && <div className='selected' />}</div>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}