import React from "react";

import { useSelector } from "react-redux";

import { REDUCER_NAME as miscReducerName } from "containers/Misc/consts";

const useIsNavBarLocked = () => {
  const [isNavBarLocked, setIsNavBarLocked] = React.useState();

  const isNavBarLockedState = useSelector(
    (state) => state[miscReducerName]["isNavBarLocked"]
  );

  React.useEffect(() => {
    setIsNavBarLocked(isNavBarLockedState);
  }, [isNavBarLockedState]);

  if (!isNavBarLocked) {
    return false;
  }

  return isNavBarLocked;
};

export default useIsNavBarLocked;
