import React from "react";
import Dropdown from "../DropDown";

const SelectTemplateField = (props) => {
  const { templates, selectedTemplate, changeSelectedTemplate } = props;

  return (
    <div className="quick-send__input-container">
      <label className="quick-send__input-label">Select Template: </label>
      <div className="quick-send__input-and-info-container">
        <Dropdown
          hasSecondaryBackground={true}
          className="quick-send__dropdown"
          placeholder={
            templates && templates.length > 0
              ? "Select template"
              : "No templates"
          }
          options={templates}
          // selected={selectedTemplate ? selectedTemplate.name : undefined}
          selected={selectedTemplate ? selectedTemplate.name : undefined}
          onSelect={changeSelectedTemplate}
        />
      </div>
    </div>
  );
};

export default SelectTemplateField;
