import React, { useState, useEffect } from 'react'

import { Route, Switch, useRouteMatch, useHistory, useLocation } from "react-router-dom";

import api from 'utils/api'
import { BackButton } from 'components/widget/backButton'
import ShowAccount from 'components/account/showAccount'
import EditAccount from 'components/account/editAccount'

import 'assets/css/account/account.scss'

export const Account = () => {
    const [account, setAccount] = useState({ organisation: { venues: 0, location: {} } })
    const [organisation, setOrganisation] = useState({ venues: 0, location: {} })

    const match = useRouteMatch()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get('/user/profile')
            setAccount(data)
            setOrganisation(data.organisation)
        }
        fetchData()
    }, [])

    return (
        <div className='account-page'>
            <BackButton />

            <div className='account-top'>
                <label>My Account</label>
                <div>
                    <button className='billing-btn' onClick={() => history.push(`/billing/subscriptions`)}>Billing</button>
                    {!location.pathname.includes('/edit') &&
                        <button onClick={() => history.push(`${match.path}/edit`)}>Edit</button>
                    }
                </div>
            </div>
            <Switch>
                <Route path={`${match.path}/edit`}>
                    <EditAccount account={account} setAccount={setAccount} organisation={organisation} setOrganisation={setOrganisation} />
                </Route>
                <Route path={match.path}>
                    <ShowAccount account={account} organisation={organisation} />
                </Route>
            </Switch>
        </div>
    )
}