import React from "react";
import styled from "@emotion/styled";
import GradientSVG from "./GradientSvg";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const GaugeWrapper = styled.div`
  .CircularProgressbar .CircularProgressbar-path {
    stroke: url("#gradient-id") !important;
 
  }

  .CircularProgressbar-text {
    transform: translate(0, -5%);
    font-size: 26px !important;
    font-weight: 500 !important;
  }
`;

const Gauge = (props) => {
  const { children } = props;
  return (
    <GaugeWrapper>
      <GradientSVG startColor={"#ff9933"} endColor={"#ffcf9e"} />
      <CircularProgressbarWithChildren
        {...props}
        text={props.text + "%"}
        strokeWidth={12}
        styles={buildStyles({
          strokeLinecap: "round",
          textSize: "16px",
          pathTransitionDuration: 1,
          pathColor: `#ff9933`,
          textColor: "#ff8888",
          trailColor: "#F2F2F2",
        
        })}
      >
        {children}
      </CircularProgressbarWithChildren>
    </GaugeWrapper>
  );
};

export default Gauge;
