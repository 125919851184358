import React from "react";
import styled from "@emotion/styled";

const AllTextWrapper = styled.div``;

const DynamicTextLabel = styled.label`
  border-radius: 15px;
  color: #ea5454;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 16px;
`;

const AllText = (props) => {
  const { contacts } = props.currentCampaign;

  return (
    <AllTextWrapper>
      <DynamicTextLabel>{contacts.length} contacts</DynamicTextLabel> selected.
    </AllTextWrapper>
  );
};

export default AllText;
