import React from 'react'

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CheckoutForm from './checkoutForm'

import '../../assets/css/billing/payment.scss'

export default ({plan, close, done, sendOrder}) => {

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

    return (
        <div className='payment-component'>
            <label className='payment-title'>Please enter payment details below for <span>{plan.name}</span></label>
            <Elements stripe={stripePromise}>
                <CheckoutForm clientSecret={plan.clientSecret} close={close} done={done} sendOrder={sendOrder} />
            </Elements>
        </div>
    )
}