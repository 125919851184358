import React from "react";

import { useSelector } from "react-redux";

import { REDUCER_NAME as userReducerName } from "containers/User/consts";

const useUser = () => {
  const [user, setUser] = React.useState();

  const userState = useSelector((state) => state[userReducerName]["user"]);

  React.useEffect(() => {
    setUser(userState);
  }, [userState]);

  if (!user) {
    return {};
  }

  return user;
};

export default useUser;
