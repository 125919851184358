import api, {cloudinaryApi} from "utils/api";
import {mediaTypes} from "./consts";

export const apiGetVisits = (data) => {
  const {organisationId, timeUnit, startDate} = data;

  const endDate = Math.ceil(new Date() / 1000);

  return api.get(
      `/organisation/${organisationId}/analytics?unit=${timeUnit}&startDate=${startDate}&endDate=${endDate}`);
};

export const apiCreateLandingPage = (data) => {
  const {organisationId} = data;

  const route = `/organisations/${organisationId}/landingPages`;

  return api.post(route, data);
};

export const apiCreateMedia = (data) => {
  return api.post("/media", data);
};

export const apiCreatePoster = (data) => {
  const {organisationId} = data;
  return api.post(`/organisations/${organisationId}/posters`, data);
};

export const apiActivateLandingPage = (data) => {
  const {organisationId, landingPageId, type, isActive} = data;
  // toggles active in the BE
  return api.put(
      `/organisations/${organisationId}/landingPages/${landingPageId}/active`
  );
};

export const apiUpdateLandingPage = (data) => {
  return data;
};

export const apiGetLandingPage = (landingPageId) => {
  return api.get(`/landingPages/${landingPageId}`);
};

export const apiGetLandingPages = (data) => {
  const {organisationId, type} = data;

  const route = `/organisations/${organisationId}/landingPages?track=${
      type === "track"
  }`;

  return api.get(route);
};

export const apiDeleteLandingPage = (landingPageId) => {
  return api.delete(`/landingPages/${landingPageId}`);
};

export const apiSaveLandingPage = (landingPageData) => {
  return api.put(
      `/landingPages/${landingPageData.externalId}`,
      landingPageData
  );
};

export const apiGetMediaSignature = ({
  landingPageId,
  mediaType,
  trackingKey,
}) => {
  const {type, folder} = mediaTypes[mediaType];
  return api.get(
      `/upload/sign?mediaGroupExternalId=${landingPageId}&mediaUsageType=${type}&folder=${folder}&trackingKey=${trackingKey}`
  );
};

export const apiUploadMediaToHost = (cloudName, uploadMediaFormData) => {
  return cloudinaryApi.post(
      `/${cloudName}/upload`,
      uploadMediaFormData,
      {headers: {"Content-Type": "multipart/form-data"}}
  );
};
