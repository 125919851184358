import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

import "assets/css/customers/customer-item.scss";

import { timeDifference } from "utils/common";

const CustomerItem = ({ customer }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const getAvatar = (customer) => {
    if (customer.avatarUrl) {
      if (customer.avatarUrl.includes("facebook"))
        return `${customer.avatarUrl}?width=300&height=300`;
      else return customer.avatarUrl;
    }
    return require("assets/images/person-icon.svg");
  };

  return (
    <div
      className="item-view"
      onClick={() => history.push(`${match.path}/${customer.externalId}`)}
    >
      <div className="customer-top">
        <img src={getAvatar(customer)} alt="" />
        <div className="names">
          <label>{customer.forename}</label>
          <label>{customer.surname}</label>
        </div>
      </div>

      <hr />

      <div className="customer-item-bottom">
        <label>
          Visited:{" "}
          {customer.lastVisit
            ? timeDifference(new Date(), new Date(customer.lastVisit * 1000))
            : "never"}
        </label>
      </div>
    </div>
  );
};

export default CustomerItem;
