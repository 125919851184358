import styled from "@emotion/styled";

const DeleteButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 45px;
  width: 70px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: ${(props) => (props.isNameValidated ? "1" : "0.5")};
  background: ${(props) => (props.isNameValidated ? "#ea5454" : "#b05a5a")};

  transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1);

  label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
  }
`;


export default DeleteButton