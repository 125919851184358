import React, { useState } from "react";
import styled from "@emotion/styled";

import "assets/css/widget/popup.scss";

const PopupWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;

  .popup-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 40%;
    left: 40%;
    width: 30%;
    padding: 20px;
    border-radius: 5px;

    input {
      border: 0.75px solid #dbdbdb;
      box-sizing: border-box;
      border-radius: 21.75px;
      background-color: #f5f5f5;
      padding: 10px 20px 10px 20px;
      width: 30vw;
      outline: 0;
      font-size: 15px;
    }

    .popup-actions {
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      margin-top: 20px;

      button {
        min-width: 120px;
        border-radius: 25px;
        color: white;
        align-self: center;

        border: none;
        padding: 10px;
        outline: 0;
        margin-left: 20px;
        font-size: 12px;
        cursor: pointer;
        transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1);
      }

      .create-button {
        background-color: ${(props) =>
          props.enabled ? "rgba(234, 84, 84, 1)" : "rgba(234, 84, 84, 0.3)"};
        pointer-events: ${(props) => (props.enabled ? "auto" : "none")};
      }

      .cancel-button {
        background-color: #dbdbdb;
        border: none;
      }
    }
  }
`;

const Popup = (props) => {
  const {
    setPopupType,
    // onCreate,
    type,
    startLandingPageRequest,
    organisationId,
  } = props;

  const [name, setName] = useState();

  return (
    <PopupWrapper enabled={name?.length > 0}>
      <div className="popup-content">
        <h3>{`Create ${type === "poster" ? "poster" : "landing page"}`}</h3>
        <input
          placeholder={`${type === "poster" ? "Poster" : "Landing page"} name`}
          type="text"
          autoFocus
          onChange={(e) => setName(e.target.value)}
        />
        <div className="popup-actions">
          <button className="cancel-button" onClick={() => setPopupType()}>
            Cancel
          </button>

          <button
            className="create-button"
            onClick={async () => {
              await startLandingPageRequest(type, name, organisationId);
              await setPopupType();
            }}
          >
            Create
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default Popup;
