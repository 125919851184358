import React from "react";

import { ReactComponent as MaleIcon } from "assets/images/male.svg";
import { ReactComponent as FemaleIcon } from "assets/images/female.svg";
import { Users } from "phosphor-react";

import NoData from "./NoData";

// import DemographicsWidgetBottom from "./DemographicsWidgetBottom";

import DemographicsWidgetWrapper from "./DemographicsWidgetWrapper";

const DemographicsWidget = (props) => {
  let male = props?.demographics?.male;
  let female = props?.demographics?.female;

  return (
    <DemographicsWidgetWrapper>
      <div className="demographics-top">
        <div className="demographics-logo">
          {/* <img src={require("assets/images/position-icon.svg").default} alt="" /> */}
          <Users
            size={28}
            color={"white"}
            weight="bold"
            style={{ transform: "translateY(-3px)" }}
          />
        </div>
        <label className="card-title">Demographics</label>
      </div>
      <hr />
      {male && female ? (
        <div className="demographics-stats-container">
          <div className="demographics-stats">
            <MaleIcon className="demographics-icon" />
            <label className="male-percent">
              {((male * 100) / (male + female) || 0).toFixed(0)}%
            </label>
          </div>
          <div className="demographics-stats">
            <FemaleIcon className="demographics-icon" />
            <label className="female-percent">
              {((female * 100) / (male + female) || 0).toFixed(0)}%
            </label>
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </DemographicsWidgetWrapper>
  );
};

export default DemographicsWidget;
