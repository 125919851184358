import React from "react";

import styled from "@emotion/styled";

import ContactsList from "components/ContactsList";
import {ampli} from "containers/Amplitude/ampli";

const CustomersWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;

  .cec-content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    box-sizing: border-box;
  }
`;

const Customers = (props) => {
  const { selectedEntity } = props;

  ampli.unaroPageVisitEventWithProperties({
    emailAddress: JSON.parse(localStorage.getItem("user")).email,
    organisationName:
      JSON.parse(localStorage.getItem("user")).organisations[0] != null
        ? JSON.parse(localStorage.getItem("user")).organisations[0].name
        : "",
        eventType: "page visit: Send -> Customers",
  });

  return (
    <CustomersWrapper>
      {selectedEntity && (
        <div className="cec-content">
          <ContactsList
            updateCampaign={false}
            className="contacts-list"
            organisationId={selectedEntity.externalId}
            //   updateExternalContacts={setCurrentCampaignContacts}
          />
        </div>
      )}
    </CustomersWrapper>
  );
};

export default Customers;
