import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";

import Poster from "components/poster/poster";
import Popup from "./components/Popup";
import {compose} from "recompose";

import ConnectContainer from "containers/Connect";

import {ReactComponent as AddIcon} from "assets/images/add-icon.svg";
import {ReactComponent as CogIcon} from "assets/images/settingsIcon.svg";

import PageTitle from "components/PageTitle";

import WifiLandingPages from "./WifiLandingPages";
import QrLandingPage from "./QrLandingPage";
import PosterLandingPage from "./PosterLandingPage";

import NewQrLandingPage from "./QrLandingPage/NewQrLandingPage";
import NewWifiLandingPage from "./NewWifiLandingPage";

import "assets/css/design.scss";
import {ampli} from "containers/Amplitude/ampli";

const disabledStyle = {
  pointerEvents: "none",
  opacity: 0.4,
};

const ConnectLandingPage = (props) => {
  const {
    // organisationId,
    startLandingPageRequest,
    selectedOrganisation,
    selectedEntity,
    // disableCreate,
    // selectedOrganisationVenues
  } = props;

  const match = useRouteMatch();
  const history = useHistory();
  const [organisationId, setOrganisationId] = useState();
  const [popupType, setPopupType] = useState();
  const [disableCreate, setDisableCreate] = useState(true);

  useEffect(() => {
    if (selectedEntity) {
      setOrganisationId(selectedEntity.externalId);
      ampli.unaroPageVisit("page visit: Connect -> Landing Page");
    }
  }, [selectedEntity]);

  useEffect(() => {

    const disableCreate = !selectedEntity ||
        selectedEntity?.externalId === selectedOrganisation?.externalId;

    setDisableCreate(disableCreate);

  }, [selectedEntity, selectedOrganisation]);

  return (
      <div className="design-page">
        <Switch>
          <Route exact path={`${match.path}/track/:landingPageId`}>
            <NewQrLandingPage/>
          </Route>
          <Route path={`${match.path}/wifi/:landingPageId`}>
            <NewWifiLandingPage {...props} />
          </Route>
          <Route path={`${match.path}/wifi`}>
            <WifiLandingPages
                {...props}
                organisationId={organisationId}
                setPopupType={setPopupType}
                type={"wifi"}
            />
          </Route>

          <Route path={`${match.path}/track`}>
            <QrLandingPage
                organisationId={organisationId}
                setPopupType={setPopupType}
                disableCreate={disableCreate}
                type={"track"}
            />
          </Route>
          <Route path={`${match.path}/poster/:posterId`}>
            <Poster organisationId={organisationId}/>
          </Route>
          <Route path={`${match.path}/poster`}>
            <PosterLandingPage
                organisationId={organisationId}
                setPopupType={setPopupType}
                disableCreate={disableCreate}
                type={"poster"}
            />
          </Route>
          <Route path={match.path}>
            <PageTitle
                title={"Manage Landing Pages"}
                style={{marginLeft: "20px"}}
            />
            <div className="design-page-editor">
              <div className="design-page-editor-types">
                <div className="design-page-editor-type">
                  <div className="design-page-editor-type-top">
                    <img alt="wifi login"
                         src={require("assets/images/wifi-login.png")}/>
                  </div>
                  <div className="design-page-editor-type-bottom">
                    <label className="design-page-editor-type-title">
                      WiFi Login Page
                    </label>
                    <div className="design-page-editor-type-buttons">
                      <div
                          style={disableCreate ? disabledStyle : {}}
                          className="design-page-editor-type-button"
                          onClick={() => setPopupType("wifi")}
                      >
                        <label>Create New</label>
                        <div>
                          <AddIcon/>
                        </div>
                      </div>
                      <div
                          className="design-page-editor-type-button existing"
                          onClick={() => history.push(`${match.path}/wifi`)}
                      >
                        <label>Manage Existing</label>
                        <CogIcon/>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="design-page-editor-type">*/}
                {/*  <div className="design-page-editor-type-top tt">*/}
                {/*    <img src={require("assets/images/track-and-trace.png").default}/>*/}
                {/*  </div>*/}
                {/*  <div className="design-page-editor-type-bottom">*/}
                {/*    <label className="design-page-editor-type-title">*/}
                {/*      QR Data Capture*/}
                {/*    </label>*/}
                {/*    <div className="design-page-editor-type-buttons">*/}
                {/*      <div*/}
                {/*          style={disabled ? disabledStyle : {}}*/}
                {/*          className="design-page-editor-type-button"*/}
                {/*          onClick={() => setPopupType("track")}*/}
                {/*      >*/}
                {/*        <label>Create New</label>*/}
                {/*        <AddIcon/>*/}
                {/*      </div>*/}
                {/*      <div*/}
                {/*          className="design-page-editor-type-button existing"*/}
                {/*          onClick={() => history.push(`${match.path}/track`)}*/}
                {/*      >*/}
                {/*        <label>Manage Existing</label>*/}
                {/*        <CogIcon/>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="design-page-editor-type">
                  <div className="design-page-editor-type-top wifi">
                    <img alt="wifi poster"
                         src={require("assets/images/wifi-poster.png")}/>
                  </div>
                  <div className="design-page-editor-type-bottom">
                    <label className="design-page-editor-type-title">
                      WiFi Poster
                    </label>
                    <div className="design-page-editor-type-buttons">
                      <div
                          style={disableCreate ? disabledStyle : {}}
                          className="design-page-editor-type-button"
                          onClick={() => setPopupType("poster")}
                      >
                        <label>Create New</label>
                        <AddIcon/>
                      </div>
                      <div
                          className="design-page-editor-type-button existing"
                          onClick={() => history.push(`${match.path}/poster`)}
                      >
                        <label>Manage Existing</label>
                        <CogIcon/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Route>
        </Switch>
        {popupType && (
            <Popup
                setPopupType={setPopupType}
                startLandingPageRequest={startLandingPageRequest}
                organisationId={organisationId}
                type={popupType}
            />
        )}
      </div>
  );
};

export default compose(ConnectContainer)(ConnectLandingPage);
