import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { compose } from "recompose";
import ChartsContainer from "containers/Charts";
import ConnectContainer from "containers/Connect";
import DashboardTop from "./components/DashboardTop";
import DashboardStats from "./components/DashboardStats";
import NewDashboardChart from "./components/NewDashboardChart";
import DashboardDemographics from "./components/DashboardDemographics";
import DashboardLogins from "./components/DashboardLogins";

import DIMENSIONS from "consts/dimensions";
import { ampli } from "containers/Amplitude/ampli";

const ConnectDashboardWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 98%; // change on mediaqueries?
  margin: 0 auto;
  overflow: hidden;

  display: grid;
  grid-template-rows: minmax(auto, 60px) minmax(48%, 48%) minmax(
      calc(55% - 60px),
      calc(55% - 60px)
    );
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  padding: 0 20px;

  max-height: calc(100vh - ${DIMENSIONS.topBarHeight}px - 10px);

  /* box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 25px; */

  @media (max-width: 1200px) {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    /* grid-template-rows: auto minmax(auto, 420px) auto; */
  }

  @media (max-width: 900px) {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    /* grid-template-rows: auto auto auto auto; */
  }
`;

const BottomStatsRow = styled.div`
  max-height: calc(100% - 60px);
  grid-row: 3/5;
  grid-column: 1/13;

  display: flex;
  justify-content: space-between;
  height: 75%;
`;

const ConnectDashboard = (props) => {
  const {
    selectedEntity,
    selectedEntitySummary,
    isSuperAdmin,
    getDayVisitsRequest,
    getWeekVisitsRequest,
    getMonthVisitsRequest,
    getYearVisitsRequest,
    dayVisits,
    weekVisits,
    monthVisits,
    yearVisits,
  } = props;

  const [currentChartData, setCurrentChartData] = React.useState({});
  // const [chartTouched, setChartTouched] = React.useState(false);

  const [currentChartTimeUnit, setCurrentChartTimeUnit] = useState("month");

  useEffect(() => {
    function getAllVisits() {
      getYearVisitsRequest(selectedEntity?.externalId);
      getMonthVisitsRequest(selectedEntity?.externalId);
      getWeekVisitsRequest(selectedEntity?.externalId);
      getDayVisitsRequest(selectedEntity?.externalId);
      // startLandingPageRequest();
    }

    if (selectedEntity?.externalId)
      getAllVisits();

    ampli.unaroPageVisitEventWithProperties({
      emailAddress: JSON.parse(localStorage.getItem("user")).email,
      organisationName:
        JSON.parse(localStorage.getItem("user")).organisations[0] != null
          ? JSON.parse(localStorage.getItem("user")).organisations[0].name
          : "",
      eventType: "page visit: Connect -> Dashboard",
    });
  }, [selectedEntity?.externalId]);

  useEffect(() => {
    if (!currentChartData) {
      setCurrentChartData(monthVisits);
      return;
    }

    switch (currentChartTimeUnit) {
      case "year":
        setCurrentChartData(yearVisits);
        break;
      case "month":
        setCurrentChartData(monthVisits);
        break;
      case "week":
        setCurrentChartData(weekVisits);
        break;
      case "day":
        setCurrentChartData(dayVisits);
        break;
    }
  }, [currentChartTimeUnit, monthVisits]);

  return (
    <ConnectDashboardWrapper>
      <DashboardTop admin={isSuperAdmin} />
      {/* <DashboardChart
        withIcon={true}
        chartTitle="Visits"
        organisationId={selectedEntity?.externalId}
        setCurrentTimeUnit={setCurrentChartTimeUnit}
        currentTimeUnit={currentChartTimeUnit}
        // data={superAdminState[currentChartTimeUnit]}
        data={currentChartData}
        setChartTouched={setChartTouched}
        chartMaxVal={chartMaxVal}
      /> */}
      <NewDashboardChart organisationId={selectedEntity?.externalId} />

      <BottomStatsRow>
        <DashboardStats
          organisationId={selectedEntity?.externalId}
          selectedEntitySummary={selectedEntitySummary}
          isSuperAdmin={isSuperAdmin}
        />
        <DashboardDemographics selectedEntitySummary={selectedEntitySummary} />
        <DashboardLogins selectedEntitySummary={selectedEntitySummary} />
      </BottomStatsRow>
    </ConnectDashboardWrapper>
  );
};

export default compose(ConnectContainer, ChartsContainer)(ConnectDashboard);
