import { createSelector } from "reselect";
// import _get from "lodash/fp/get";
import { REDUCER_NAME as authReducerName } from "containers/Auth/consts";
import { REDUCER_NAME as userReducerName } from "containers/User/consts";
import { REDUCER_NAME as selectedOrganisationReducerName } from "containers/SelectedOrganisation/consts";
import { REDUCER_NAME as networkReducerName } from "containers/Network/consts";
import { REDUCER_NAME as chartsReducerName } from "containers/Charts/consts";
import { REDUCER_NAME as connectReducerName } from "containers/Connect/consts";
import { REDUCER_NAME as miscReducerName } from "containers/Misc/consts";

export const authSliceSelector = createSelector(
  (state) => state[authReducerName],
  (state) => state
);

export const userSliceSelector = createSelector(
  (state) => state[userReducerName],
  (state) => state
);

export const selectedOrganisationSliceSelector = createSelector(
  (state) => state[selectedOrganisationReducerName],
  (state) => state
);

export const networkSliceSelector = createSelector(
  (state) => state[networkReducerName],
  (state) => state
);

export const connectSliceSelector = createSelector(
  (state) => state[connectReducerName],
  (state) => state
);

export const miscSliceSelector = createSelector(
  (state) => state[miscReducerName],
  (state) => state
);

export const chartsSliceSelector = createSelector(
  (state) => state[chartsReducerName],
  (state) => state
);
