import React, {useEffect, useState} from 'react';
import {
  Box,
  Button, ButtonGroup,
  FormControl, FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import api from "../../../utils/api";
import {from, map} from "rxjs";
import {AxiosResponse} from "axios";
import uuid from "react-uuid";

const MAX_SIZE = 160;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type Template = {
  externalId: string | null
  name: string
  messageBody: string
  organisationExternalId: string
}

type Props = {
  organisationId: string
}

const TemplateModal = ({organisationId}: Props) => {

  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorDelete, setErrorDelete] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [messageBody, setMessageBody] = useState<string>('');
  const [templateId, setTemplateId] = useState<string>('');
  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const onChangeTemplate = (e: SelectChangeEvent) => {
    let id = e.target.value;
    if (id) {
      const template = templates.find((x: Template) => x.externalId === id);
      template && setTemplate(template);
    }
  }

  const onChangeMessageBody = (e) => {
    const text = e.target.value;
    if (text.length <= MAX_SIZE) {
      setMessageBody(text);
    } else {
      setError(`Max ${MAX_SIZE} characters`);
      setTimeout(() => setError(""), 2000);
    }
  }

  const saveTemplate = () => {
    const t: Template = {
      externalId: edit ? templateId : uuid(),
      name: name,
      messageBody: messageBody,
      organisationExternalId: organisationId
    };
    from(api.post(`/sms/template`, t))
    .pipe(map((response: AxiosResponse) => response.data))
    .subscribe({
      next: (data: Template) => {
        onClose();
        fetchTemplates(data.externalId);
      },
      error: (err) => {
        setError("Failed to save template");
        console.error(err);
      }
    })
  }

  const deleteTemplate = () => {
    if (templateId) {
      from(api.post(`/sms/template/${templateId}`))
      .subscribe({
        next: () => {
          onClose();
          fetchTemplates();
        },
        error: (err: any) => {
          const error: string = err?.response?.data?.errorDescription;
          if (error && error.includes('ConstraintViolationException')) {
            setErrorDelete("Cannot delete template used in campaign");
          } else {
            setErrorDelete("Error deleting template");
          }
          setTimeout(() => setErrorDelete(''), 3000);
        }
      });
    }
  }

  const fetchTemplates = (id?: string) => {
    from(api.get(`/sms/organisation/${organisationId}/template`))
    .pipe(map((response: AxiosResponse) => response.data))
    .subscribe({
      next: (data: Template[]) => {
        if (data && data.length > 0) {
          setTemplates(data);
          const template = data.find((x: Template) => x.externalId === id);
          setTemplate(template ? template : data[0]);
        } else {
          setTemplates([]);
        }
      },
      error: (err: any) => {
        console.error("Error getting sms senders");
        console.error(err);
      }
    });
  }

  const setTemplate = (t: Template) => {
    if (t) {
      setTemplateId(t.externalId);
      setName(t.name);
      setMessageBody(t.messageBody);
    }
  }

  const newTemplate = (): void => {
    setOpen(true);
    setName('');
    setMessageBody('');
  }

  const editTemplate = (): void => {
    setOpen(true);
    setEdit(true);
  }


  const onClose = () => {
    setOpen(false);
    setEdit(false);
  }

  return (
      <Box sx={{minWidth: 500, maxWidth: 500}}>
        <Grid
            container
            direction="row"
        >
          <Grid xs={6}>
            <FormControl fullWidth size="small" >
              <InputLabel>Template</InputLabel>
              <Select
                  value={templateId}
                  label="Template"
                  onChange={onChangeTemplate}
                  disabled={templates.length === 0}
              >
                {templates && templates.map((s: any) =>
                    <MenuItem key={uuid()} value={s.externalId}>{s.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            {errorDelete && <FormHelperText sx={{color: 'red'}}>{errorDelete}</FormHelperText>}
          </Grid>
          <Grid xs={6} mt={1}>
            <ButtonGroup
                size="small"
                variant="outlined"
            >
              <Button onClick={newTemplate}>
                New
              </Button>
              <Button onClick={editTemplate}>
                Edit
              </Button>
              <Button onClick={deleteTemplate}>
                Delete
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container direction="row" sx={{textAlign: "center"}}>
              <Grid xs={12}>
                <Typography variant="h6" component="h2">
                  Add Template
                </Typography>
              </Grid>
              <Grid xsOffset={1} xs={10} sx={{mt: 2}}>
                <TextField
                    id={uuid()}
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!name}
                />
              </Grid>
              <Grid xsOffset={1} xs={10} sx={{mt: 2}}>
                <TextField
                    id={uuid()}
                    multiline
                    fullWidth
                    rows={4}
                    value={messageBody}
                    variant="filled"
                    onChange={onChangeMessageBody}
                    helperText={error ? error : `${(messageBody ? messageBody.length : "0")}/160`}
                    error={error.length > 0 || !messageBody}
                />
              </Grid>
              <Grid xs={12} sx={{mt: 2}}>
                <Button
                    onClick={saveTemplate}
                    disabled={!name || name.length < 4}
                >
                  Save
                </Button>
                <Button
                    onClick={onClose}
                    color='warning'
                    sx={{ml: 2}}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
  )
}

export default TemplateModal;