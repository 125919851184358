import React from "react";
import styled from "@emotion/styled";

import {SmileySad} from "phosphor-react";

const NoCampaignsWrapper = styled.div`
  width: 30%;
  margin: 50px auto 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 32px;
    padding: 16px;
    margin: 0;
    color:#5B5B5B;
  }

  h3 {
    font-size: 22px;
    padding: 16px;
    padding: 0;
    margin: 10px 0;
    color:#5B5B5B;
  }
`;

const NoCampaigns = (props) => {
  return (
    <NoCampaignsWrapper>
      <h2>Oops...</h2>
      <SmileySad size={42} color={"#5B5B5B"}/>
      <h3>No Campaigns Found</h3>
    </NoCampaignsWrapper>
  );
};

export default NoCampaigns;
