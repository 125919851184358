export const SELECT_ENTITY_REQUEST = "SELECT_ENTITY_REQUEST";
export const SELECT_ENTITY_FAILURE = "SELECT_ENTITY_FAILURE";
export const SELECT_ENTITY_SUCCESS = "SELECT_ENTITY_SUCCESS";

export const CLEAR_ENTITY_REQUEST = "CLEAR_ENTITY_REQUEST";
export const CLEAR_ENTITY_FAILURE = "CLEAR_ENTITY_FAILURE";
export const CLEAR_ENTITY_SUCCESS = "CLEAR_ENTITY_SUCCESS";

export const GET_SELECTED_ENTITY_SUMMARY_REQUEST =
  "GET_SELECTED_ENTITY_SUMMARY_REQUEST";
export const GET_SELECTED_ENTITY_SUMMARY_FAILURE =
  "GET_SELECTED_ENTITY_SUMMARY_FAILURE";
export const GET_SELECTED_ENTITY_SUMMARY_SUCCESS =
  "GET_SELECTED_ENTITY_SUMMARY_SUCCESS";

export const GET_SELECTED_ENTITY_CAMPAIGNS_REQUEST =
  "GET_SELECTED_ENTITY_CAMPAIGNS_REQUEST";
export const GET_SELECTED_ENTITY_CAMPAIGNS_FAILURE =
  "GET_SELECTED_ENTITY_CAMPAIGNS_FAILURE";
export const GET_SELECTED_ENTITY_CAMPAIGNS_SUCCESS =
  "GET_SELECTED_ENTITY_CAMPAIGNS_SUCCESS";

// --- SELECT_ENTITY

export const selectEntityRequest = (selectedEntity) => ({
  type: SELECT_ENTITY_REQUEST,
  payload: selectedEntity,
});

export const selectEntityFailure = (errorMessage) => ({
  type: SELECT_ENTITY_FAILURE,
  payload: errorMessage,
});

export const selectEntitySuccess = (selectedEntity) => ({
  type: SELECT_ENTITY_SUCCESS,
  payload: selectedEntity,
});

// --- CLEAR_ENTITY

export const clearEntityRequest = () => ({
  type: CLEAR_ENTITY_REQUEST,
});

export const clearEntityFailure = (errorMessage) => ({
  type: CLEAR_ENTITY_FAILURE,
  payload: errorMessage,
});

export const clearEntitySuccess = () => ({
  type: CLEAR_ENTITY_SUCCESS,
});

// --- GET_SELECTED_ENTITY_SUMMARY

export const getSelectedEntitySummaryRequest = (entityId) => ({
  type: GET_SELECTED_ENTITY_SUMMARY_REQUEST,
  payload: entityId,
});

export const getSelectedEntitySummaryFailure = (errorMessage) => ({
  type: GET_SELECTED_ENTITY_SUMMARY_FAILURE,
  payload: errorMessage,
});

export const getSelectedEntitySummarySuccess = (entitySummary) => ({
  type: GET_SELECTED_ENTITY_SUMMARY_SUCCESS,
  payload: entitySummary,
});

// --- CLEAR_ENTITY

// export const clearEntityRequest = () => ({
//     type: CLEAR_ENTITY_REQUEST,
//   });

//   export const clearEntityFailure = (errorMessage) => ({
//     type: CLEAR_ENTITY_FAILURE,
//     payload: errorMessage,
//   });

//   export const clearEntitySuccess = () => ({
//     type: CLEAR_ENTITY_SUCCESS,
//   });
