import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import CreatePoster from './createPoster'
import PosterPreview from './posterPreview'
import {ReactComponent as DesignLogo} from 'assets/images/design-icon.svg'
import api from 'utils/api'
import 'assets/css/poster/poster.scss'
import {uploadMediaImage} from "../../containers/Connect/mediaAPI";
import {Image, ImageResponse} from "../../containers/Connect/types";

export default ({organisationId}) => {
  const history = useHistory()
  const {posterId} = useParams();

  const [posterLogo: Image, setPosterLogo] = useState(null);
  const [poster, setPoster] = useState({
    backgroundColor: '#E73E1C',
    name: 'Default'
  });

  const uploadFile = async (file: File, type: string) => {
    uploadMediaImage(file, {
      organisationId: organisationId,
      projectId: posterId,
      mediaType: type
    })
    .then((imageResponse: ImageResponse) => {
      setPosterLogo({
        externalId: imageResponse.externalId,
        filename: imageResponse.filename,
        url: imageResponse.url,
        format: imageResponse.format,
        width: imageResponse.width,
        height: imageResponse.height
      });
      setPoster({
        ...poster,
        logoExternalId: imageResponse.externalId
      });
    });
  }

  useEffect(() => {
    api.get(`/organisations/${organisationId}/posters/${posterId}`)
    .then((response: any) => {
      setPoster(response.data)
      setPosterLogo(response.data?.logoImage);
    })
    .catch((error: any) => {
      console.error('Error loading poster');
      console.error(error)
    });

  }, [posterId])

  const savePoster = () => {
    api.put(
        `/organisations/${organisationId}/posters/${poster.externalId}`, poster)
    .then((response: any) => history.goBack());
  }

  return (
      <div className='poster-page'>
        <div className='pp-edit'>
          <div className='pp-edit-top'>
            <div className='pp-edit-title'>
              <div className='pp-edit-logo'>
                <DesignLogo className='pp-edit-logo-svg'/>
              </div>
              <label className='pp-title-text'>Editor</label>
            </div>
          </div>
          <hr className='pp-top-line'/>
          <CreatePoster
              posterLogo={posterLogo}
              poster={poster}
              setPoster={setPoster}
              uploadFile={uploadFile}
              save={savePoster}
          />
        </div>
        <PosterPreview poster={poster} posterLogo={posterLogo}/>
      </div>
  )
}