import {useState} from "react";

const PostContactForm = async (
    values,
    successCallback,
    errorCallback
) => {
  if (true) {
    successCallback();
  } else {
    errorCallback();
  }
};

const initialFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  number: "",
  countryCode: "",
  formSubmitted: false,
  success: false
};

export const useFormControls = () => {
      const [values, setValues] = useState(initialFormValues);
      const [errors, setErrors] = useState({});

      const validate = (fieldValues = values) => {
        let temp = {...errors};

        if ("firstName" in fieldValues) {
          temp.firstName = fieldValues.firstName ? "" : "This field is required.";
        }

        if ("lastName" in fieldValues) {
          temp.lastName = fieldValues.lastName ? "" : "This field is required.";
        }

        if ("number" in fieldValues) {
          if (fieldValues.number) {
            if (/^[0-9\b]+$/.test(fieldValues.number)) {
              temp.number = "";
              temp.email = "";
            } else {
              temp.number = "phone number is not valid.";
            }
          } else if (!fieldValues.number && !values.email
              && values.email.trim().length === 0) {
            temp.number = "Contact number or email is required.";
          }
        }

        // Email is touched
        if ("email" in fieldValues) {
          if (fieldValues.email) {
            if (/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)) {
              temp.email = "";
              temp.number = ""
              if(!/^[0-9\b]+$/.test(fieldValues.number)){
                values.number = '';
              }
            } else {
              temp.email = "Email is not valid.";
            }
          } else if (!fieldValues.email && !values.number
              && values.number.trim().length === 0) {
            temp.email = "Contact number or email is required.";
          }
        }

        setErrors({
          ...temp
        });
      };

      const handleInputValue = (e) => {
        const {name, value} = e.target;
        setValues({
          ...values,
          [name]: value
        });
        validate({[name]: value});
      };

      const handleSuccess = () => {
        setValues({
          ...initialFormValues,
          formSubmitted: true,
          success: true
        });
      };

      const handleError = () => {
        setValues({
          ...initialFormValues,
          formSubmitted: true,
          success: false
        });
      };

      const formIsValid = (fieldValues = values) => {
        if(
            (fieldValues.firstName && fieldValues.lastName && fieldValues.email && Object.values(errors).every((x) => x === ""))
            ||(fieldValues.firstName && fieldValues.lastName && fieldValues.number && Object.values(errors).every((x) => x === ""))
        ){
          return true;
        }
        return false;
      };

      const handleFormSubmit = async (e) => {
        e.preventDefault();
        const isValid =
            Object.values(errors).every((x) => x === "") && formIsValid();
        if (isValid) {
          await PostContactForm(values, handleSuccess, handleError);
        }
      };

      return {
        values,
        errors,
        handleInputValue,
        handleFormSubmit,
        formIsValid
      };
    }
;
