import React, {ChangeEvent, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import uuid from "react-uuid";

type Props = {
  addTemplate: (name: string) => void
}

const NewTemplateButton = ({addTemplate}: Props) => {

  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const onCreate = () => {
    setOpen(false);
    addTemplate(name);
  }

  return (
      <>
        <Button onClick={() => {
          setOpen(true);
          setName('');
        }}>
          New
        </Button>

        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>New Template</DialogTitle>
          <DialogContent>
            <TextField
                id={uuid()}
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCreate} disabled={!name}>Create</Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default NewTemplateButton;
