import React from "react";
import "assets/css/design/edit-login-welcome.scss";
import {ColorPicker} from "components/widget/colorPicker";
import {Dropdown} from "components/widget/dropdown";
import FileDragAndDrop from "components/FileDragAndDrop";
import {greetingNameOptions} from "../../../../../../containers/Connect/consts";

const EditLoginLandingWelcome = ({
  settings,
  updateSettings,
  uploadImage,
  currentPreviewScreen,
}) => {

  return (
      <div
          className={`edit-login-welcome edit-login-animate-${currentPreviewScreen
          === 1}`}>
        <label className="edit-login-welcome__title">Select First Field</label>
        <div className="edit-login-welcome__top-inputs">
          <input
              className="edit-login-welcome__input"
              value={settings?.greetingMessage}
              placeholder="Greeting"
              onChange={(e) =>
                  updateSettings('greetingMessage', e.target.value)
              }
          />
          <Dropdown
              hasSecondaryBackground
              className="edit-login-welcome__dropdown"
              options={greetingNameOptions}
              selected={{name: settings?.greetingName}}
              onSelect={(option) =>
                  updateSettings('greetingName', option.name)
              }
              placeholder="First Name"
          />
        </div>
        <hr className="edit-login-welcome__divider"/>
        <div className="edit-login-welcome__content-managers">
          <div className="edit-login-welcome__content-input">
            <label className="edit-login-welcome__title">Welcome Message</label>
            <textarea
                className="edit-login-welcome__text-area"
                type="textarea"
                value={settings?.welcomeText}
                maxLength="160"
                onChange={(e) =>
                    updateSettings('welcomeText', e.target.value)
                }
            />
            <label className="edit-login-welcome__text-counter">
              {settings?.welcomeText
                  ? settings?.welcomeText.length
                  : 0}
              / 160
            </label>
          </div>
          <div className="file-upload-container" style={{width: '100%'}}>
            <FileDragAndDrop
                title="Upload Image"
                className="file-selector"
                fileName={settings?.welcomeImage?.filename}
                setFile={(event) => uploadImage(event, "welcome")}
            />
          </div>
        </div>
        <hr className="edit-login-welcome__divider"/>
        <label className="edit-login-welcome__title--options">
          Colour Options
        </label>
        <div
            className="edit-login-welcome__color-options"
            style={{marginTop: "30px"}}
        >
          <ColorPicker
              className="edit-login-welcome__colour-picker"
              title="Title:"
              value={settings?.welcomeHeaderColor}
              hideValue
              setValue={(value) =>
                  updateSettings('welcomeHeaderColor', value)
              }
          />
          <ColorPicker
              className="edit-login-welcome__colour-picker"
              title="Body Text:"
              value={settings?.welcomeTextColor}
              hideValue
              setValue={(value) =>
                  updateSettings('welcomeTextColor', value)
              }
          />
          <ColorPicker
              className="edit-login-welcome__colour-picker"
              title="Button Text:"
              value={settings?.welcomeButtonsTextColor}
              hideValue
              setValue={(value) =>
                  updateSettings('welcomeButtonsTextColor', value)
              }
          />
          <ColorPicker
              className="edit-login-welcome__colour-picker"
              title="Join Button:"
              value={settings?.welcomeJoinColor}
              hideValue
              setValue={(value) =>
                  updateSettings('welcomeJoinColor', value)
              }
          />
        </div>
      </div>
  );
};

export default EditLoginLandingWelcome;
