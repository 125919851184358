import React, {useState} from "react";
import styled from "@emotion/styled";

import CardStats from "./CardStats";

import {adminCardsConfig, userCardsConfig} from "./cardsConfig";

const DashboardStatsWrapper = styled.div`
  flex-direction: column;
  flex: 3;
  /* justify-content: space-between; */
  height: 100%;

  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5%;
  grid-row-gap: 26px;

  @media (max-width: 900px) {
    grid-row: 2/3;
    grid-column: 1/13;
    flex-direction: row;
  }

  .card-stats {
    @media (max-width: 900px) {
      width: 24%;
    }
  }
`;

const DashboardStats = (props) => {
  const { organisationId, selectedEntitySummary, isSuperAdmin } = props;

  // const admin =
  //   JSON.parse(localStorage.getItem("user")).organisations[0].orgType !==
  //     "ORGANISATION" && !localStorage.getItem("selected-organisation");

  const [statistics, setStatistics] = useState();

  const [adminStats, setAdminStats] = useState(adminCardsConfig);
  const [stats, setStats] = useState(userCardsConfig);

  const getCards = () => {
    // return isSuperAdmin ? adminStats : stats;

    return stats;
  };

  const setAdminCardStats = (statistics) => {
    const relationships = statistics.relationships;
    const stats = adminStats.map((x) => {
      if (x.id === 0)
        return {
          ...x,
          number: Object.entries(statistics.signups)
            .map(([key, value]) => value.total)
            .reduce((c, t) => (t += c)),
        };
      else if (x.id === 1) return { ...x, number: relationships.customers };
      else if (x.id === 2) return { ...x, number: relationships.venues };
      else if (x.id === 3) return { ...x, number: relationships.devices };
    });
    setAdminStats(stats);
  };

  const setNonAdminCardStats = (statistics) => {
    const result = stats.map((x) => {
      if (x.id === 0) return { ...x, number: statistics.connectedDevices };

      if (x.id === 1)
        return {
          ...x,
          number: Object.entries(statistics.signups)
            .map(([key, value]) => value.total)
            .reduce((c, t) => (t += c)),
        };
      else if (x.id === 2)
        return { ...x, number: `${Math.round(statistics.visits.returnRate)}%` };
      else if (x.id === 3)
        return {
          ...x,
          number: Object.entries(statistics.signups)
            .map(([key, value]) => value.today)
            .reduce((c, t) => (t += c)),
        };
    });
    setStats(result);
  };

  const setCardStats = (statistics) => {
    return setNonAdminCardStats(statistics);
  };

  React.useEffect(() => {
    selectedEntitySummary && setStatistics(selectedEntitySummary);
    selectedEntitySummary && setCardStats(selectedEntitySummary);
  }, [selectedEntitySummary]);

  return (
    <DashboardStatsWrapper className="dashboard-statistics">
      {getCards().map((stat) => (
        <CardStats key={stat.title} statistics={stat} />
      ))}
    </DashboardStatsWrapper>
  );
};

export default DashboardStats;
