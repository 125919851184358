import { createSelector } from "reselect";
import _get from "lodash/fp/get";
import { REDUCER_NAME } from "./consts";
import getChartTimeLabels from "utils/charts/getChartTimeLabels";
import moment from "moment";

// export const selectIsLoading = createSelector(
//   (state) => state[REDUCER_NAME],
//   _get("loading")
// );

// export const selectErrorMessage = createSelector(
//   (state) => state[REDUCER_NAME],
//   _get("errorMessage")
// );

export const selectEmailsStats = createSelector(
  (state) => state[REDUCER_NAME]["dashboard"],
  _get("emailsStats")
);

export const selectUpdatedContacts = createSelector(
  (state) => state["sendNewEmail"],
  _get("updatedContacts")
);

export const selectSendDashboardTimeUnit = createSelector(
  (state) => state["DASHBOARD"],
  _get("timeUnit")
);

export const selectChartLabels = createSelector(
  selectSendDashboardTimeUnit,
  (timeUnit) => getChartTimeLabels(timeUnit)
);

export const selectYearOpenRates = createSelector(
  (state) => state[REDUCER_NAME]["dashboard"]["yearOpenRateStats"],
  (data) =>
    data &&
    data
      .map((item) => item.messages.OPEN)
      .filter((item) => item.length > 0)
      .flat()
      .sort((a, b) => {
        return Number(b.timestamp) - Number(a.timestamp);
      })
      .map((item) => {
        const date = moment(item.timestamp * 1000);

        item.formattedDate = date.format("MMM YY");
        return item;
      })
);

export const selectMonthOpenRates = createSelector(
  (state) => state[REDUCER_NAME]["dashboard"]["monthOpenRateStats"],
  (data) =>
    data &&
    data
      .map((item) => item.messages.OPEN)
      .filter((item) => item.length > 0)
      .flat()
      .sort((a, b) => {
        return Number(b.timestamp) - Number(a.timestamp);
      })
      .map((item) => {
        const date = moment(item.timestamp * 1000);

        item.formattedDate = date.format("DD MMM");
        return item;
      })
);

export const selectWeekOpenRates = createSelector(
  (state) => state[REDUCER_NAME]["dashboard"]["weekOpenRateStats"],
  (data) =>
    data &&
    data
      .map((item) => item.messages.OPEN)
      .filter((item) => item.length > 0)
      .flat()
      .sort((a, b) => {
        return Number(b.timestamp) - Number(a.timestamp);
      })
      .map((item) => {
        let day = new Date(item.timestamp * 1000);
        item.formattedDate = day.toLocaleDateString("default", {
          weekday: "short",
        });

        return item;
      })
);

export const selectDayOpenRates = createSelector(
  (state) => state[REDUCER_NAME]["dashboard"]["dayOpenRateStats"],
  (data) =>
    data &&
    data
      .map((item) => item.messages.OPEN)
      .filter((item) => item.length > 0)
      .flat()
      .sort((a, b) => {
        return Number(b.timestamp) - Number(a.timestamp);
      })
      .map((item) => {
        let day = new Date(item.timestamp * 1000);
        item.formattedDate = day.toLocaleTimeString("en-GB", {
          hour: "2-digit",
        });

        return item;
      })
);

// DATA SETS

export const selectYearDataSet = createSelector(
  selectYearOpenRates,
  (openEmails) => {
    if (openEmails) {
      const labels = getChartTimeLabels("yearly");
      const hashTable = {};
      labels.forEach((item) => (hashTable[item] = 0));
      openEmails.forEach((item) => {
        if (hashTable.hasOwnProperty(item.formattedDate)) {
          hashTable[item.formattedDate] =
            hashTable[item.formattedDate] + item.number;
        }
      });

      const yearDataSet = Object.values(hashTable);

      const data = {};

      data.labels = labels;
      data.datasets = [
        {
          label: "Open Emails",
          data: yearDataSet,
          ...dataSetConfig,
        },
      ];

      return data;
    }
  }
);

export const selectMonthDataSet = createSelector(
  selectMonthOpenRates,
  (openEmails) => {
    if (openEmails) {
      const labels = getChartTimeLabels("monthly");

      const hashTable = {};
      labels.forEach((item) => (hashTable[item] = 0));
      openEmails.forEach((item) => {
        if (hashTable.hasOwnProperty(item.formattedDate)) {
          hashTable[item.formattedDate] =
            hashTable[item.formattedDate] + item.number;
        }
      });

      const monthDataSet = Object.values(hashTable);

      const data = {};

      data.labels = labels;
      data.datasets = [
        {
          label: "Open Emails",
          data: monthDataSet,
          ...dataSetConfig,
        },
      ];

      return data;
    }
  }
);

export const selectWeekDataSet = createSelector(
  selectWeekOpenRates,
  (openEmails) => {
    if (openEmails) {
      const labels = getChartTimeLabels("weekly");

      const hashTable = {};
      labels.forEach((item) => (hashTable[item] = 0));
      openEmails.forEach((item) => {
        if (hashTable.hasOwnProperty(item.formattedDate)) {
          hashTable[item.formattedDate] =
            hashTable[item.formattedDate] + item.number;
        }
      });

      const weekDataSet = Object.values(hashTable);

      const data = {};

      data.labels = labels;
      data.datasets = [
        {
          label: "Open Emails",
          data: weekDataSet,
          ...dataSetConfig,
        },
      ];

      return data;
    }
  }
);

export const selectDayDataSet = createSelector(
  selectDayOpenRates,
  (openEmails) => {
    if (openEmails) {
      const labels = getChartTimeLabels("daily");

      const hashTable = {};
      labels.forEach((item) => (hashTable[item] = 0));
      openEmails.forEach((item) => {
        if (hashTable.hasOwnProperty(item.formattedDate)) {
          hashTable[item.formattedDate] =
            hashTable[item.formattedDate] + item.number;
        }
      });

      const dayDataSet = Object.values(hashTable);

      const data = {};

      data.labels = labels;
      data.datasets = [
        {
          label: "Open Emails",
          data: dayDataSet,
          ...dataSetConfig,
        },
      ];



      return data;
    }
  }
);

export const selectOpenRatesChartMaxVal = createSelector(
  selectYearDataSet,
  (messages) => {
    if (messages) {
      const maxVal = Math.max(...messages.datasets.flat()[0].data);

      return maxVal;
    }
  }
);

const dataSetConfig = {
  backgroundColor: "rgba(254, 203, 69, 0.3)",
  borderWidth: 3,
  borderColor: "rgba(254, 203, 69, 1)",
  tension: 0.5,
};
