import React from "react";
import styled from "@emotion/styled";

import StepsNumberButtons from "./StepsNumberButtons";
import BackNextButtons from "./BackNextButtons";
import stepsConfig from "../../config";

const StepsNavBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

const SelectedStepTitle = styled.label`
  font-size: 28px;
  font-weight: 300;
  width: 300px;
`;

const StepsNavBar = (props) => {
  const {
    steps,
    updateCurrentStepRequest,
    currentStep,
    currentCampaignContacts,
  } = props;

  const next = () => {
    updateCurrentStepRequest(currentStep + 1);
  };

  const [stepTitle, setStepTitle] = React.useState("");

  React.useEffect(() => {
    if (stepsConfig && currentStep) {
      setStepTitle(stepsConfig[currentStep].name);
    }
  }, [currentStep, stepsConfig]);

  return (
      <StepsNavBarWrapper>
        <SelectedStepTitle>{stepTitle && stepTitle}</SelectedStepTitle>

        <StepsNumberButtons
            steps={steps}
            currentStep={currentStep}
        />

        <BackNextButtons
            {...props}
            steps={steps}
            next={next}
            currentCampaignContacts={currentCampaignContacts}
        />
      </StepsNavBarWrapper>
  );
};

export default StepsNavBar;
