import { combineReducers } from "redux";

import currentCampaignReducer from "./currentCampaign";
import templatesReducer from "./templates";
import miscReducer from "./misc";

const sendRootReducer = combineReducers({
  "currentCampaign": currentCampaignReducer,
  "templates": templatesReducer,
  "misc": miscReducer,
});

export default sendRootReducer;
