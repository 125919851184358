export const GET_FEATURED_TEMPLATES_REQUEST = " GET_FEATURED_TEMPLATES_REQUEST";
export const GET_FEATURED_TEMPLATES_FAILURE = " GET_FEATURED_TEMPLATES_FAILURE";
export const GET_FEATURED_TEMPLATES_SUCCESS = " GET_FEATURED_TEMPLATES_SUCCESS";

export const GET_SAVED_TEMPLATES_REQUEST = " GET_SAVED_TEMPLATES_REQUEST";
export const GET_SAVED_TEMPLATES_FAILURE = " GET_SAVED_TEMPLATES_FAILURE";
export const GET_SAVED_TEMPLATES_SUCCESS = " GET_SAVED_TEMPLATES_SUCCESS";

// export const EXPORT_TEMPLATE_TO_IMG_REQUEST = " EXPORT_TEMPLATE_TO_IMG_REQUEST";
// export const EXPORT_TEMPLATE_TO_IMG_FAILURE = " EXPORT_TEMPLATE_TO_IMG_FAILURE";
// export const EXPORT_TEMPLATE_TO_IMG_SUCCESS = " EXPORT_TEMPLATE_TO_IMG_SUCCESS";

export const CREATE_TEMPLATE_REQUEST = " CREATE_TEMPLATE_REQUEST";
export const CREATE_TEMPLATE_FAILURE = " CREATE_TEMPLATE_FAILURE";
export const CREATE_TEMPLATE_SUCCESS = " CREATE_TEMPLATE_SUCCESS";

export const CREATE_FEATURED_TEMPLATE_REQUEST =
  " CREATE_FEATURED_TEMPLATE_REQUEST";
export const CREATE_FEATURED_TEMPLATE_FAILURE =
  " CREATE_FEATURED_TEMPLATE_FAILURE";
export const CREATE_FEATURED_TEMPLATE_SUCCESS =
  " CREATE_FEATURED_TEMPLATE_SUCCESS";

// export const SELECT_TEMPLATE_REQUEST = " SELECT_TEMPLATE_REQUEST";
// export const SELECT_TEMPLATE_FAILURE = " SELECT_TEMPLATE_FAILURE";
// export const SELECT_TEMPLATE_SUCCESS = " SELECT_TEMPLATE_SUCCESS";

export const SELECT_TEMPLATE_REQUEST = " SELECT_TEMPLATE_REQUEST";
export const SELECT_TEMPLATE_FAILURE = " SELECT_TEMPLATE_FAILURE";
export const SELECT_TEMPLATE_SUCCESS = " SELECT_TEMPLATE_SUCCESS";

export const DELETE_TEMPLATE_REQUEST = " DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_FAILURE = " DELETE_TEMPLATE_FAILURE";
export const DELETE_TEMPLATE_SUCCESS = " DELETE_TEMPLATE_SUCCESS";

// ---  GET_FEATURED_TEMPLATES

export const getFeaturedTemplatesRequest = (page) => ({
  type: GET_FEATURED_TEMPLATES_REQUEST,
  payload: {
    page,
  },
});

export const getFeaturedTemplatesFailure = (errorMessage) => ({
  type: GET_FEATURED_TEMPLATES_FAILURE,
  payload: errorMessage,
});

export const getFeaturedTemplatesSuccess = (featuredTemplates) => ({
  type: GET_FEATURED_TEMPLATES_SUCCESS,
  payload: featuredTemplates,
});

// ---  GET SAVED TEMPLATES

export const getSavedTemplatesRequest = (organisationId, page) => ({
  type: GET_SAVED_TEMPLATES_REQUEST,
  payload: {
    organisationId,
    page,
  },
});

export const getSavedTemplatesFailure = (errorMessage) => ({
  type: GET_SAVED_TEMPLATES_FAILURE,
  payload: errorMessage,
});

export const getSavedTemplatesSuccess = (savedTemplate) => ({
  type: GET_SAVED_TEMPLATES_SUCCESS,
  payload: savedTemplate,
});

// ---  EXPORT TO IMG

// export const exportTemplateToImgRequest = (templateData) => ({
//   type: EXPORT_TEMPLATE_TO_IMG_REQUEST,
//   payload: {
//     templateData,
//   },
// });

// export const exportTemplateToImgFailure = (errorMessage) => ({
//   type: EXPORT_TEMPLATE_TO_IMG_FAILURE,
//   payload: errorMessage,
// });

// export const exportTemplateToImgSuccess = (templateImageUrl) => ({
//   type: EXPORT_TEMPLATE_TO_IMG_SUCCESS,
//   payload: templateImageUrl,
// });

// ---  CREATE TEMPLATE

export const createTemplateRequest = (templateData) => ({
  type: CREATE_TEMPLATE_REQUEST,
  payload: {
    templateData,
  },
});

export const createTemplateFailure = (errorMessage) => ({
  type: CREATE_TEMPLATE_FAILURE,
  payload: errorMessage,
});

export const createTemplateSuccess = (createdTemplate) => ({
  type: CREATE_TEMPLATE_SUCCESS,
  payload: createdTemplate,
});

// ---  CREATE FEATURED TEMPLATE

export const createFeaturedTemplateRequest = (featuredTemplateData) => ({
  type: CREATE_FEATURED_TEMPLATE_REQUEST,
  payload: {
    featuredTemplateData,
  },
});

export const createFeaturedTemplateFailure = (errorMessage) => ({
  type: CREATE_FEATURED_TEMPLATE_FAILURE,
  payload: errorMessage,
});

export const createFeaturedTemplateSuccess = (createdFeaturedTemplate) => ({
  type: CREATE_FEATURED_TEMPLATE_SUCCESS,
  payload: createdFeaturedTemplate,
});

// ---  SELECT TEMPLATE

// export const selectTemplateRequest = (selectedTemplate) => ({
//   type: SELECT_TEMPLATE_REQUEST,
//   payload: {
//     selectedTemplate,
//   },
// });

// export const selectTemplateFailure = (errorMessage) => ({
//   type: SELECT_TEMPLATE_FAILURE,
//   payload: errorMessage,
// });

// export const selectTemplateSuccess = (selectedTemplate) => ({
//   type: SELECT_TEMPLATE_SUCCESS,
//   payload: selectedTemplate,
// });

// ---  CREATE FEATURED TEMPLATE

export const deleteTemplateRequest = (
  organisationId,
  templateId,
  templateType
) => ({
  type: DELETE_TEMPLATE_REQUEST,
  payload: {
    organisationId,
    templateId,
    templateType,
  },
});

export const deleteTemplateFailure = (errorMessage) => ({
  type: DELETE_TEMPLATE_FAILURE,
  payload: errorMessage,
});

export const deleteTemplateSuccess = (deletedTemplateId) => ({
  type: DELETE_TEMPLATE_SUCCESS,
  payload: deletedTemplateId,
});
