import React, {useEffect, useState} from 'react';
import {
  Box,
  Button, ButtonGroup,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import api from "../../../utils/api";
import {from, map} from "rxjs";
import {AxiosResponse} from "axios";
import uuid from "react-uuid";

type Sender = {
  externalId: string,
  name: string,
  phone?: string,
  organisationExternalId: string
}

type Props = {
  organisationId: string
}

const SenderModal = ({organisationId}: Props) => {

  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorDelete, setErrorDelete] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [senderId, setSenderId] = useState<string>("");
  const [senders, setSenders] = useState<Sender[]>([]);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    fetchSenders();
  }, []);

  const onChangeSender = (e) => {
    let id = e.target.value;
    if (id) {
      const sender = senders.find((x: Sender) => x.externalId === id);
      sender && setSender(sender);
    }
  }

  const addSender = () => {
    const newSender: Sender = {
      externalId: edit ? senderId : uuid(),
      name: name,
      phone: phone,
      organisationExternalId: organisationId
    }
    from(api.post("/sms/sender", newSender))
    .pipe(map((response: AxiosResponse) => response.data))
    .subscribe({
      next: (sender: Sender) => {
        fetchSenders(sender.externalId);
        setName('');
        setPhone('');
        onClose();
      },
      error: (err: any) => {
        console.error("Error adding sender");
        console.error(err);
      }
    });
  };

  const deleteSender = () => {
    if (senderId) {
      from(api.delete(`/sms/sender/${senderId}`))
      .subscribe({
        next: () => {
          onClose();
          fetchSenders();
        },
        error: (err) => {
          const error: string = err?.response?.data?.errorDescription;
          if (error && error.includes('ConstraintViolationException')) {
            setErrorDelete("Cannot delete sender used in campaign");
          } else {
            setErrorDelete("Error deleting sender");
          }
          setTimeout(() => setErrorDelete(''), 3000);
        }
      })
    }
  }

  const fetchSenders = (senderId?: string | null) => {
    from(api.get(`/sms/organisation/${organisationId}/sender`))
    .pipe(map((response: AxiosResponse) => response.data))
    .subscribe({
      next: (data: Sender[]) => {
        if (data && data.length > 0) {
          setSenders(data);
          const sender = data.find((x: Sender) => x.externalId === senderId);
          setSender(sender ? sender : data[0]);
        } else {
          setSenders([]);
        }
      },
      error: (err) => {
        console.error("Error getting senders");
        console.error(err);
      }
    })
  }

  const onChangeName = (e) => {
    const n = e.target.value;
    if (!n || n.length === 0) {
      setName('');
      setError("Name required");
    } else if (n && n.length > 11) {
      setError("Name must be less than 11 characters");
      setTimeout(() => setError(''), 2000);
    } else {
      setError('');
      setName(e.target.value);
    }
  }

  const setSender = (s: Sender) => {
    if (s) {
      setSenderId(s.externalId);
      setName(s.name);
      setPhone(s.phone);
    }
  }

  const newSender = () => {
    setOpen(true);
    setName('');
    setPhone('');
    setError('Name required');
  }

  const editSender = () => {
    setOpen(true);
    setEdit(true);
  }

  const onClose = () => {
    setOpen(false);
    setEdit(false);
  }

  return (
      <Box sx={{minWidth: 500, maxWidth: 500}}>
        <Grid
            container
            direction="row"
        >
          <Grid xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Sender</InputLabel>
              <Select
                  value={senderId}
                  label="Sender"
                  onChange={onChangeSender}
                  disabled={senders.length === 0 || senders.length >= 11}
              >
                {senders && senders.map((s: any) =>
                    <MenuItem key={uuid()} value={s.externalId}>{s.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            {errorDelete && <FormHelperText sx={{color: 'red'}}>{errorDelete}</FormHelperText>}
          </Grid>
          <Grid xs={6} mt={1}>
            <ButtonGroup
                size="small"
                variant="outlined"
            >
              <Button onClick={newSender}>
                New
              </Button>
              <Button onClick={editSender}>
                Edit
              </Button>
              <Button onClick={deleteSender}>
                Delete
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container direction="row" sx={{textAlign: "center"}}>
              <Grid xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Add Sender
                </Typography>
              </Grid>
              <Grid xsOffset={1} xs={10} sx={{mt: 2}}>
                <TextField
                    fullWidth
                    value={name}
                    label="Sender Name"
                    onChange={onChangeName}
                />
                {error && <FormHelperText sx={{color: 'red'}}>{error}</FormHelperText>}
              </Grid>
              <Grid xsOffset={1} xs={10} sx={{mt: 2}}>
                <TextField
                    fullWidth
                    value={phone}
                    label="Sender Phone"
                    onChange={(e) => setPhone(e.target.value)}
                />
                <FormHelperText>Optional</FormHelperText>
              </Grid>
              <Grid xs={12} sx={{mt: 2}}>
                <Button
                    onClick={addSender}
                    disabled={!name || name.length < 4}
                >
                  Save
                </Button>
                <Button onClick={onClose} color='warning' sx={{ml: 2}}>Cancel</Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
  )
}

export default SenderModal;