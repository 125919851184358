import React from "react";

import { Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import Main from "pages/Main";
import {
  ActivateUser,
  ForgotPassword,
  ResetPassword,
  SignUp,
  PricePlans,
  Login,
  Unsubscribe,
} from "pages";

const AppRouter = () => {
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/unsubscribe">
        <Unsubscribe />
      </Route>
      <Route path="/activate">
        <ActivateUser />
      </Route>
      <Route path="/password/new">
        <ForgotPassword />
      </Route>
      <Route path="/password/reset">
        <ResetPassword />
      </Route>
      <Route path="/card">
        <PricePlans />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>
      <PrivateRoute path="/" Component={Main} />
    </Switch>
  );
};

export default AppRouter;
