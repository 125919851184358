import React from "react";
import styled from "@emotion/styled";

const NoDataWrapper = styled.div`
  width: 75%;
  margin: 28px auto 0 auto;

  p {
    font-size: 14px;
    text-align: center;
    opacity: 0.55;
  }
`;

const NoData = (props) => {
  return (
    <NoDataWrapper>
      <p>Sorry, no demographics data available.</p>
    </NoDataWrapper>
  );
};

export default NoData;
