import actions from "../actions";

const {
  IMPORT_CONTACTS_LIST_REQUEST,
  IMPORT_CONTACTS_LIST_FAILURE,
  IMPORT_CONTACTS_LIST_SUCCESS,
  PARSE_EXCEL_REQUEST,
  PARSE_EXCEL_FAILURE,
  PARSE_EXCEL_SUCCESS,
  DELETE_IMPORTED_LIST_COLUMN_REQUEST,
  DELETE_IMPORTED_LIST_COLUMN_FAILURE,
  DELETE_IMPORTED_LIST_COLUMN_SUCCESS,
  EDIT_IMPORTED_LIST_COLUMN_REQUEST,
  EDIT_IMPORTED_LIST_COLUMN_FAILURE,
  EDIT_IMPORTED_LIST_COLUMN_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  importedListHeaders: null,
  importedListContacts: null,
  currentExcel: null,
  
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case IMPORT_CONTACTS_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      
      };
    }

    case IMPORT_CONTACTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,

      };
    }

    case IMPORT_CONTACTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        
      };
    }
    ///---

    case PARSE_EXCEL_REQUEST: {
      return {
        ...state,
        loading: true,
        currentExcel: action.payload,
      };
    }

    case PARSE_EXCEL_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case PARSE_EXCEL_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentExcel: action.payload,
      };
    }
    //---

    case DELETE_IMPORTED_LIST_COLUMN_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case DELETE_IMPORTED_LIST_COLUMN_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case DELETE_IMPORTED_LIST_COLUMN_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentExcel: action.payload.updatedColumns,
      };
    }
    ///---

    case EDIT_IMPORTED_LIST_COLUMN_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case EDIT_IMPORTED_LIST_COLUMN_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case EDIT_IMPORTED_LIST_COLUMN_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentExcel: action.payload.updatedColumns,
      };
    }

    default:
      return state;
  }
}
