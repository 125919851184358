import {from, map, Observable} from "rxjs";
import {AxiosResponse} from "axios";
import api from "utils/api";

export type Template = {
  name: string
  externalId: string
  orgId: string
  unlayerDesign: string
  messageBody: string
  subject?: string
  //unlayerDesignHtml: string
  //unlayerPreviewImageUrl: string
}

export const getTemplates = (orgId: string): Observable<Template[]> => {
  return from(api.get(`/email/organisation/${orgId}/templates/`))
  .pipe(map((response: AxiosResponse<Template[]>) => response.data));
}

export const getTemplate = (templateId: string): Observable<Template> => {
  return from(api.get(`/email/template/${templateId}/`))
  .pipe(map((response: AxiosResponse<Template>) => response.data));
}

export const saveTemplate = (t: Template): Observable<any> => {
  return from(api.post('/email/template', t));
}

export const removeTemplate = (orgId: string, templateId: string): Observable<any> => {
  return from(api.delete(`/email/organisation/${orgId}/template/${templateId}`));
}
