import React from 'react'

import {
  ReactComponent as LandingPageIcon
} from "assets/images/landing-page-icon.svg";
import {
  ReactComponent as DashboardSubItemIcon
} from "assets/images/dashboard-subitem-icon.svg";
import {ReactComponent as NewEmailIcon} from "assets/images/new-email-icon.svg";
import {
  ReactComponent as ManageCampaignIcon
} from "assets/images/manage-campaigns-icon.svg";
import {ReactComponent as ReportsIcon} from "assets/images/reports-icon.svg";
import {ReactComponent as PlaneIcon} from "assets/images/sms-plane.svg";

import {Article, Gear, Flag, Palette} from "phosphor-react";

import AutomationIcon from '@mui/icons-material/PrecisionManufacturing';

import {
  ReactComponent as DashboardIcon
} from 'assets/images/dashboard-icon.svg';
import {
  ReactComponent as InactiveDashboardLogo
} from 'assets/images/dashboard-icon-inactive.svg';

import {ReactComponent as CustomersLogo} from 'assets/images/customer-icon.svg';
import {
  ReactComponent as InactiveCustomersLogo
} from 'assets/images/customer-icon-inactive.svg';

import {ReactComponent as ConnectLogo} from 'assets/images/connect-icon.svg';
import {
  ReactComponent as InactiveConnectLogo
} from 'assets/images/connect-icon-inactive.svg';

import {ReactComponent as SendLogo} from 'assets/images/send-icon.svg';
import {
  ReactComponent as InactiveSendLogo
} from 'assets/images/send-icon-inactive.svg';

import {ReactComponent as SMSLogo} from 'assets/images/sms-icon.svg';
import {
  ReactComponent as InactiveSMSLogo
} from 'assets/images/sms-icon-inactive.svg';

const iconStyle = {
  width: "25px",
  height: "25px"
};

const menuConfig = (location) => [
  {
    id: 0,
    logo: <DashboardIcon style={iconStyle}/>,
    inactiveLogo: <InactiveDashboardLogo style={iconStyle}/>,
    text: "Dashboard",
    redirect: "/org-dashboard",
    selected: location.pathname === "/org-dashboard",
    active: true,
    subItems: [],
  },
  {
    id: 1,
    logo: <CustomersLogo style={iconStyle}/>,
    inactiveLogo: <InactiveCustomersLogo style={iconStyle}/>,
    text: "Customers",
    redirect: "/customers",
    selected: location.pathname.startsWith("/customers"),
    active: true,
    subItems: [],
  },
  {
    id: 2,
    logo: <ConnectLogo style={iconStyle}/>,
    inactiveLogo: <InactiveConnectLogo style={iconStyle}/>,
    text: "Connect",
    redirect: "/connect",
    selected: location.pathname.startsWith("/connect"),
    active: false,
    requireLicense: "CONNECT",
    showSubItems: location.pathname.startsWith("/connect"),
    subItems: [
      {
        id: 0,
        Icon: DashboardIcon,
        text: "Dashboard",
        redirect: "/connect/dashboard",
        selected: location.pathname.startsWith("/connect/dashboard"),
        active: false,
        requireLicense: "CONNECT",
      },
      {
        id: 1,
        Icon: () => <Palette size={26} weight="bold" color={"#EA5454"}/>,
        // Icon: () => <LandingPageIcon />,
        text: "Landing page",
        redirect: "/connect/design",
        selected: location.pathname.startsWith("/connect/design"),
        active: false,
        requireLicense: "CONNECT",
      },
    ],
  },
  {
    id: 3,
    logo: <SendLogo/>,
    inactiveLogo: <InactiveSendLogo/>,
    text: "Send",
    redirect: "/send",
    selected: location.pathname.startsWith("/send"),
    active: true,
    requireLicense: "SEND",
    showSubItems: location.pathname.startsWith("/email"),
    subItems: [
      {
        id: 0,
        text: "Dashboard",
        redirect: "/email/dashboard",
        selected: location.pathname.startsWith("/email/dashboard"),
        Icon: DashboardSubItemIcon,
        active: false,
        requireLicense: "SEND",
      },
      {
        id: 1,
        text: "New Email",
        redirect: "/email/new",
        selected: location.pathname.startsWith("/email/new"),
        Icon: NewEmailIcon,
        active: false,
        requireLicense: "SEND",
      },
      {
        id: 3,
        text: "Manage Campaigns",
        redirect: "/email/campaigns",
        selected: location.pathname.startsWith("/email/campaigns"),
        Icon: ManageCampaignIcon,
        active: false,
        requireLicense: "SEND",
      },
      {
        id: 4,
        text: "Templates",
        redirect: "/send/templates",
        selected: location.pathname.startsWith("/send/templates"),
        Icon: () => <Article size={26} weight="bold" color={"#EA5454"}/>,
        active: false,
        requireLicense: "SEND",
      },
      {
        id: 5,
        text: "Settings",
        redirect: "/send/settings",
        selected: location.pathname.startsWith("/send/settings"),
        Icon: () => <Gear size={26} weight="bold" color={"#EA5454"}/>,
        active: false,
        requireLicense: "SEND",
      },
    ],
  },
  {
    id: 4,
    logo: <SMSLogo/>,
    inactiveLogo: <InactiveSMSLogo/>,
    text: "SMS",
    redirect: "/sms",
    selected: location.pathname.startsWith("/sms"),
    active: false,
    requireLicense: "SMS",
    showSubItems: location.pathname.startsWith("/sms"),
    subItems: [
      {
        id: 0,
        text: "Dashboard",
        redirect: "/sms/dashboard",
        selected: location.pathname.startsWith("/sms/dashboard"),
        Icon: DashboardIcon,
        active: false,
        requireLicense: "SMS",
      },
      {
        id: 1,
        text: "SMS Settings",
        redirect: "/sms/settings",
        Icon: () => <Article size={26} weight="bold" color={"#EA5454"}/>,
        selected: location.pathname === "/sms/settings",
        active: false,
        requireLicense: "SMS",
      },
      {
        id: 2,
        text: "Quick Send",
        redirect: "/sms/quick-sms",
        Icon: PlaneIcon,
        selected: location.pathname === "/sms/quick-sms",
        active: false,
        requireLicense: "SMS",
      },
      {
        id: 3,
        text: "Create Campaign",
        redirect: "/sms/campaigns/create",
        Icon: ManageCampaignIcon,
        selected: location.pathname === "/sms/campaigns/create",
        active: false,
        requireLicense: "SMS",
      },
      {
        id: 4,
        text: "Reports",
        redirect: "/sms/reports",
        selected: location.pathname.startsWith("/sms/reports"),
        // Icon: <ReportsIcon />,
        Icon: () => <Flag size={26} weight="bold" color={"#EA5454"}/>,
        active: false,
        requireLicense: "SMS",
      },
    ],
  },
  {
    id: 5,
    logo: <AutomationIcon sx={{
      color: '#FF9933',
      fontSize: '25px'
    }}/>,
    text: "Automation",
    redirect: "/automation",
    selected: location.pathname.startsWith("/automation"),
    active: true,
    subItems: [],
  },
];

export default menuConfig;
