import { createSelector } from "reselect";
import _get from "lodash/fp/get";
import { REDUCER_NAME } from "./consts";

export const selectCampaigns = createSelector(
  (state) => state[REDUCER_NAME],
  _get("campaigns")
);

export const selectCurrentPagination = createSelector(
  (state) => state[REDUCER_NAME],
  _get("currentPagination")
);
