import React, { useState, useEffect, useContext } from "react";

import { VenuesContext } from "context/venueContext";
import api from "utils/api";
import MessageInfoPopup from "./messageInfoPopup";
import PageNavigator from "components/PageNavigator";
import { PageNavigationContext } from "context/navigationContext";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import PageTitle from "components/PageTitle";

import "assets/css/sms/reports/reports.scss";
import { ampli } from "containers/Amplitude/ampli";

export default () => {
  const history = useHistory();
  const [campaigns, setCampaigns] = useState([]);
  const { selected } = useContext(VenuesContext);
  const [navigation, setNavigation] = useState({ total: 1, current: 0 });
  const [showMessageInfoPopup, setShowMessageInfoPopup] = useState();
  const [tags, setTags] = useState();
  const [name, setName] = useState("");
  const [loadingCampaigns, setLoadingCamapaigns] = useState(true);

  const fetctCampaigns = async (page) => {
    setLoadingCamapaigns(true);
    const { data } = await api.get(
      `/sms/organisation/${selected.externalId}/campaign/paged?size=15&page=${page}&name=${name}&sort=createdOn,DESC`
    );
    setCampaigns(
      data.content.map((campaign) => ({
        ...campaign,
        createdOn: new Date(campaign.createdOn * 1000),
        tags:
          campaign.tags && campaign.tags.length > 0
            ? campaign.tags.map((t) => tags[t]).filter((t) => t)
            : [{ name: "All Contacts", color: "#14A697" }],
      }))
    );
    setNavigation({
      total: Math.ceil(data.totalElements / 15),
      current: data.pageable.pageNumber,
    });
    setLoadingCamapaigns(false);
  };

  const changePage = (page) => {
    fetctCampaigns(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      fetctCampaigns(0);
    };
    if (selected && tags) fetchData();
  }, [selected, name, tags]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(
        `/organisations/${selected.externalId}/tags`
      );
      const tagsDic = [];
      data.forEach((t) => (tagsDic[t.externalId] = t));
      setTags(tagsDic);
    };

    if (selected) fetchData();
    ampli.unaroPageVisitEventWithProperties({
      emailAddress: JSON.parse(localStorage.getItem("user")).email,
      organisationName:
        JSON.parse(localStorage.getItem("user")).organisations[0] != null
          ? JSON.parse(localStorage.getItem("user")).organisations[0].name
          : "",
          eventType: "page visit: SMS -> Reports",
    });
  }, [selected]);

  return (
    <div className="sms-reports">
      <div className="sr-top">
        <PageTitle title={"Manage Campaigns"} />
        <div className="srt-right">
          <input
            placeholder="Search campaign..."
            type="search"
            value={name || ""}
            onChange={(e) => setName(e.target.value)}
          />
          <PageNavigationContext.Provider value={{ navigation, setNavigation }}>
            <PageNavigator changePage={(page) => changePage(page)} />
          </PageNavigationContext.Provider>
        </div>
      </div>
      <div className="sr-content">
        <div className="src-header">
          <label>Date</label>
          <label className="src-header-center">SMS sent</label>
          <label>Recipient</label>
          <label>Campaign</label>
          <label>Message</label>
          <div />
        </div>
        <div className="sr-messages">
          {campaigns && campaigns.length > 0 ? (
            campaigns.map((campaign) => (
              <div className="sr-message">
                <label className="srm-text">
                  {(campaign.createdOn.getDate() < 10
                    ? "0" + campaign.createdOn.getDate()
                    : campaign.createdOn.getDate()) +
                    "/" +
                    (campaign.createdOn.getMonth() + 1) +
                    "/" +
                    campaign.createdOn.getFullYear()}
                </label>
                <label className="srm-text srm-sent">
                  {campaign.statistics.error + campaign.statistics.success}
                </label>
                <div className="sr-tags">
                  {campaign.tags.map((tag) => (
                    <label
                      className="sr-tag"
                      style={{
                        backgroundColor: tag.color,
                        borderColor:
                          tag.color === "#ffffff" ? "#C4C4C4" : tag.color,
                        color: tag.color === "#ffffff" ? "black" : "white",
                      }}
                    >
                      {" "}
                      {tag.name}
                    </label>
                  ))}
                </div>
                <label className="sr-name">{campaign.name}</label>
                <label className="srm-text">{campaign.messageBody}</label>

                <div className="srm-actions">
                  <button
                    onClick={() =>
                      history.push(`/sms/campaigns/${campaign.externalId}`)
                    }
                  >
                    Edit
                  </button>
                  <button
                    onClick={() =>
                      history.push(`/sms/reports/${campaign.externalId}`)
                    }
                  >
                    View
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="no-messages-container">
              {loadingCampaigns ? (
                <Loader type="Oval" color="#EA5459" height={30} width={30} />
              ) : (
                <div className="no-campaigns">
                  <label>You have no campaigns yet</label>
                  <label>Click the button below to start</label>
                  <button
                    className="create-btn"
                    onClick={() => history.push("/sms/campaigns/new")}
                  >
                    Create Campaign
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showMessageInfoPopup && (
        <MessageInfoPopup
          message={showMessageInfoPopup}
          close={() => setShowMessageInfoPopup(null)}
        />
      )}
    </div>
  );
};
