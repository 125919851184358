import React from "react";

import "assets/css/widget/switch-btn.scss";

const ActivateSwitch = (props) => {
  const { active, setActive } = props;
  return (
    <div
      className={`switch-btn ${active && "active-switch"}`}
      onClick={() => setActive(!active)}
    >
      <div className="switch" />
    </div>
  );
};

export default ActivateSwitch;
