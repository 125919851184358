import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import TopControls from "./TopControls";
import Table from "./Table";

const ContactsListTableWrapper = styled.div`
  display: flex;
  position: relative;
  width: 1290px;
  height: 1020px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  grid-row: 1/6;
`;

const ContactsListTable = (props) => {
  const {
    allContacts,
    currentListType,
    selectedSegmentContacts,
    selectedAudienceContacts,
    getSegmentsRequest,
    selectSegmentRequest,
    getAllContactsRequest,
    selectListTypeRequest,
    removeFilterRequest,
    setCurrentSelectedSegmentId,
    setSelectedContactsIds,
    setIsAllContactsSelected
  } = props;

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [openAddNewAudience, setOpenAddNewAudience] = React.useState(false);

  const selectedCustomerFromContactList = (customers) => {
    setSelectedCustomers(customers);
  };

  const [isHover, setIsHover] = useState(false);
  const [contactsList, setContactsList] = useState({});
  const [allContactSelected, setAllContactSelected] = useState(true);
  const [isContactsListEmpty, setIsContactsListEmpty] = useState(true);

  useEffect(() => {
    let contacts = [];
    switch (currentListType) {
      case "all":
        contacts = allContacts;
        break;
      case "segments":
        contacts = selectedSegmentContacts;
        break;
      case "audiences":
        contacts = selectedAudienceContacts;
        break;
    }

    setContactsList(contacts);
  }, [
    allContacts,
    selectedSegmentContacts,
    selectedAudienceContacts,
    currentListType,
  ]);

  useEffect(() => {
    if (contactsList && Object.keys(contactsList).length > 0) {
      setIsContactsListEmpty(false);
    } else {
      setIsContactsListEmpty(true);
    }
  }, [contactsList]);

  return (
    <ContactsListTableWrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      isHover={true}
    >
      <TopControls
        {...props}
        contactsList={contactsList}
        selectedCustomers={selectedCustomers}
        setSelectedCustomers={selectedCustomerFromContactList}
        openAddNewAudience={openAddNewAudience}
        setOpenAddNewAudience={setOpenAddNewAudience}
        getSegmentsRequest={getSegmentsRequest}
        selectSegmentRequest={selectSegmentRequest}
        getAllContactsRequest={getAllContactsRequest}
        selectListTypeRequest={selectListTypeRequest}
        setCurrentSelectedSegmentId={setCurrentSelectedSegmentId}
        setSelectedContactsIds={setSelectedContactsIds}
        setIsAllContactsSelected={setIsAllContactsSelected}
        removeFilterRequest={removeFilterRequest}
      />
      <Table
        {...props}
        contactsList={contactsList}
        allContactSelected={allContactSelected}
        setAllContactSelected={setAllContactSelected}
        isContactsListEmpty={isContactsListEmpty}
        setSelectedCustomers={selectedCustomerFromContactList}
      />
    </ContactsListTableWrapper>
  );
};

export default ContactsListTable;
