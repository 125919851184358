import React from "react";
import styled from "@emotion/styled";
import {Menu, MenuItem} from "@mui/material";
import TransitionsModal from "components/customers/modal/createAudience";
import AddContact from "components/customers/modal/addContact";
import Export from "components/customers/modal/export";
import DeleteAudience from "components/customers/modal/deleteAudience";

const SimpleMenuWrapper = styled.div`

`;

const SimpleMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
      <SimpleMenuWrapper>
        <div
            className={"burgerButton"}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
        >
          <img alt="hamburger menu" src={require("assets/images/hamburger-menu-btn.svg").default}/>
        </div>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <AddContact/>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <TransitionsModal/>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Export/>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <DeleteAudience/>
          </MenuItem>
        </Menu>
      </SimpleMenuWrapper>
  );
};

export default SimpleMenu;
