import _ from "lodash";

import {
  SET_USER_REQUEST,
  SET_USER_FAILURE,
  SET_USER_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  errorMessage: null,
  user: null,
  userOrganisation: null,
  userOrganisationLicenses: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        user: null,
        userOrganisation: null,
        userOrganisationLicenses: null,
      };
    }

    case SET_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case SET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        user: _.omit(action.payload, ["organisations"]),
        userOrganisation: _.omit(action.payload.organisations[0], ["licences"]),
        userOrganisationLicenses: action.payload.organisations[0].licences,
      };
    }

    default:
      return state;
  }
}
