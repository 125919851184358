import React from "react";
import InfoTooltip from "components/widget/infoTooltip";

const FromField = (props) => {
  const { fromName, onChangeFromName,errorMessage } = props;
  return (
    <div className="quick-send__input-container">
      <label className="quick-send__input-label">From: </label>
      <div className="quick-send__input-and-info-container">
        <input
          value={fromName}
          style={{
            border: fromName && errorMessage ? "1px solid red" : "",
          }}
          type="search"
          className="quick-send__input--with-info"
          onChange={(e) => onChangeFromName(e.target.value)}
          maxLength="11"
        />
        <InfoTooltip tooltipMessage="This will be who the recipent see the message is from!" />
      </div>
    </div>
  );
};

export default FromField;
