import {createSelector} from "reselect";

import {userSliceSelector} from "store/selectors";

export const selectIsLoading = createSelector(
    userSliceSelector,
    (userSliceSelector) => userSliceSelector["loading"]
);

export const selectErrorMessage = createSelector(
    userSliceSelector,
    (userSliceSelector) => userSliceSelector["errorMessage"]
);

export const selectUser = createSelector(
    userSliceSelector,
    (userSliceSelector) => userSliceSelector["user"]
);

export const selectUserOrganisation = createSelector(
    userSliceSelector,
    (userSliceSelector) => userSliceSelector["userOrganisation"]
);

export const selectUserOrganisationLicenses = createSelector(
    userSliceSelector,
    (userSliceSelector) => userSliceSelector["userOrganisationLicenses"]
);

export const selectIsSuperAdmin = createSelector(
    selectUserOrganisation,
    (userOrganisation) => userOrganisation?.orgType === "ACCOUNT"
);
