import { createSelector } from "reselect";
import _get from "lodash/fp/get";
import { REDUCER_NAME } from "./consts";

// import getChartTimeLabels from "utils/charts/getChartTimeLabels";

//Slices

// export const selectCurrentCampaignSlice = createSelector(
//   (state) => state && state[REDUCER_NAME]["currentCampaign"]
// );

// export const selectTemplatesSlice = createSelector(
//   (state) => state && state[REDUCER_NAME]["templates"]
// );

// export const selectMiscSlice = createSelector(
//   (state) => state && state[REDUCER_NAME]["misc"]
// );

//Templates

export const selectFeaturedTemplates = createSelector(
  (state) => state[REDUCER_NAME]["templates"],
  _get("featuredTemplates")
);

export const selectSavedTemplates = createSelector(
  (state) => state[REDUCER_NAME]["templates"],
  _get("savedTemplates")
);

export const selectCreatedTemplate = createSelector(
  (state) => state[REDUCER_NAME]["templates"],
  _get("createdTemplate")
);

export const selectCreatedFeaturedTemplate = createSelector(
  (state) => state[REDUCER_NAME]["templates"],
  _get("createdFeaturedTemplate")
);

export const selectSavedTemplatesPagination = createSelector(
  (state) => state[REDUCER_NAME]["templates"]["savedTemplatesPagination"],
  (savedTemplates) => {
    return savedTemplates;
  }
);

export const selectFeaturedTemplatesPagination = createSelector(
  (state) => state[REDUCER_NAME]["templates"],
  _get("featuredTemplatesPagination")
);

//Current campaign

export const selectCurrentCampaign = createSelector(
  (state) => state[REDUCER_NAME]["currentCampaign"],
  (state) => state
);

export const selectCurrentTemplate = createSelector(
  (state) => state[REDUCER_NAME]["currentCampaign"],
  (state) => {
    if (state?.template?.externalId) {
      return state.template;
    }
  }
);

export const selectNewCreatedCampaign = createSelector(
  (state) => state[REDUCER_NAME]["currentCampaign"],
  (state) => state?.createdCampaign
);

export const selectIsUsingSavedTemplate = createSelector(
  (state) => state[REDUCER_NAME]["currentCampaign"],
  (state) => {
    if (state?.template?.externalId) {
      return true;
    } else {
      return false;
    }
  }
);

export const selectCurrentContactsList = createSelector(
  (state) => state["CONTACTS_LIST"]["misc"],
  _get("currentListType")
);

export const selectCurrentSegments = createSelector(
  (state) => state["CONTACTS_LIST"]["segments"],
  (segmentsState) => segmentsState
);

export const selectCurrentAudiences = createSelector(
  (state) => state["CONTACTS_LIST"]["audiences"],
  (audiencesState) => audiencesState
);

export const selectIsTestEmailSent = createSelector(
  (state) => state[REDUCER_NAME]["misc"],
  _get("testEmailSent")
);

export const selectIsCampaignSent = createSelector(
  (state) => state[REDUCER_NAME]["misc"],
  _get("isCampaignSent")
);

export const selectCurrentStep = createSelector(
  (state) => state[REDUCER_NAME]["misc"],
  _get("currentStep")
);
