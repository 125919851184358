import api from "utils/api";

export const apiGetNetworkOrganisations = (data) => {
  const { organisationId, page, name } = data;

  return api.get(
    `/organisations/${organisationId}/network?relationship=CUSTOMER&relationship=VENUE&page=${page}&size=20&name=${name}`
  );
};

export const apiGetNetworkIndustries = () => {
  return api.get(`/industries`);
};

export const apiDeleteNetworkOrganisation = (data) => {
  return api.delete(`/organisations/${data}`);
};
