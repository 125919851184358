import React from "react";

import {NavigationNumber} from "./NavigationNumber";

import "assets/css/page-navigator.scss";

const PageNavigator = (props) => {
  const {changePage} = props;
  const {currentPage, totalElements, totalPages} = props.currentPagination;

  // const onNavigation = (page) => {
  //   if (currentPage < totalPages && currentPage >= 0) {
  //     changePage(page);
  //   }
  // };

  const getNumberNavigationButtons = () => {
    var buttons = [];
    if (totalPages > 5) {
      if (currentPage < 3) {
        buttons = [...Array(5).keys()];
      } else if (currentPage + 4 > totalPages) {
        buttons = [
          totalPages - 5,
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
        ];
      } else {
        buttons = [
          currentPage - 2,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        ];
      }
    } else {
      buttons = [...Array(totalPages).keys()];
    }

    return buttons.map((nr) => ({
      id: nr,
      text: nr + 1,
      selected: nr === currentPage,
    }));
  };

  const numberButtons = getNumberNavigationButtons();

  return (
      <div className="page-navigator">
        {/* <button onClick={() => prevPage(currentPage)}>PREV</button> */}
        <div className="number-navigation">
          {numberButtons.map((number) => {
            return (
                <NavigationNumber
                    currentPage={currentPage}
                    key={number.id}
                    navigator={number}
                    onClick={() => changePage(number.id)}
                />
            );
          })}
        </div>
        {/* <button onClick={() => nextPage(currentPage)}>NEXT</button> */}
      </div>
  );
};

export default PageNavigator;
