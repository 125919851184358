import React, { useState, useContext, useEffect } from "react";

import Dropdown from "../../contact/contactDropdown";
import TagsDropdown from "../../contact/tagsDropdown";
import api from "../../../../utils/api";
import { VenuesContext } from "context/venueContext";
import CreateSenderPopup from "./createSenderPopup";
import { useHistory } from "react-router-dom";

import { ReactComponent as SwitchOn } from "assets/images/switch-on.svg";
import { ReactComponent as SwitchOff } from "assets/images/switch-off.svg";

import "assets/css/sms/campaign/create/new-campaign.scss";

export default ({ campaign, setCampaign, next }) => {
  const history = useHistory();

  const [selectAll, setSelectAll] = useState(true);
  const [showSelectTemplateInfo, setShoSelectTemplateInfo] = useState(false);
  const [showCampaignIdInfo, setShowCampaignIdInfo] = useState(false);
  const [showCreateSenderPopup, setShowCreateSenderPopup] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [unusedTags, setUnusedTags] = useState();
  const [senderIds, setSenderIds] = useState([]);
  const [errors, setErrors] = useState([]);
  const [noRecipients, seteNoRecipients] = useState(false);
  const [selectedSender, setSelectedSender] = useState(
    campaign ? campaign.sender : null
  );
  const [templates, setTemplates] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [recipients, setRecipients] = useState([]);
  const [name, setName] = useState();
  const [messageBody, setMessageBody] = useState();

  const { selected } = useContext(VenuesContext);

  useEffect(() => {
    if (campaign) {
      setMessageBody(campaign.messageBody);
      setName(campaign.name);
    }
  }, [campaign]);

  useEffect(() => {
    if (campaign && campaign.tags && tags) {
      setSelectedTags(
        tags.filter((tag) => campaign.tags.includes(tag.externalId))
      );
      if (campaign.tags.length > 0) setSelectAll(false);
    }
  }, [campaign, tags]);

  const onBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setShoSelectTemplateInfo(false);
      setShowCampaignIdInfo(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(
        `/organisations/${selected.externalId}/tags`
      );
      setTags(data);
      setUnusedTags(data);
    };
    if (selected) fetchData();
  }, [selected]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(
        `/sms/organisation/${selected.externalId}/template`
      );
      setTemplates(data);
    };
    if (selected) fetchData();
  }, [selected]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(
        `/sms/organisation/${selected.externalId}/sender`
      );
      setSenderIds(data);
      if (data && data.length > 0) {
        setSelectedSender(data[0]);
      }
    };

    if (selected) fetchData();
  }, [selected]);

  useEffect(() => {
    const fetchData = async () => {
      var url = `/organisations/${selected.externalId}/contacts?type=&page=0&size=1000&requirePhone=true`;
      if (selectedTags && selectedTags.length > 0)
        selectedTags.forEach((st) => (url += `&tag=${st.externalId}`));
      const { data } = await api.get(url);
      setRecipients(data.content.map((c) => c.externalId));
    };

    if (selected) fetchData();
  }, [selected, selectedTags]);

  const changeSelectedTags = (changedTags) => {
    seteNoRecipients(false);
    setSelectAll(false);
    setSelectedTags(changedTags);
  };

  useEffect(() => {
    setUnusedTags(tags.filter((t) => !selectedTags.includes(t)));
  }, [selectedTags]);

  const changeSelectAll = () => {
    seteNoRecipients(false);
    if (!selectAll) {
      setSelectedTags([]);
    }
    setSelectAll(!selectAll);
  };

  const addSender = async (sender) => {
    sender.organisationExternalId = selected.externalId;
    const { data } = await api.post("/sms/sender", sender);
    sender.externalId = data.externalId;
    setSenderIds([...senderIds, sender]);
  };

  const nextPage = () => {
    var e = [];

    if (!name || name.length < 3) e = [...e, "name"];
    if (!selectedSender) e = [...e, "sender"];
    if (!selectAll && selectedTags.length === 0) seteNoRecipients(true);

    setErrors(e);

    if (e.length === 0) {
      setCampaign({
        ...campaign,
        senderExternalId: selectedSender.externalId,
        recipients: recipients,
        name: name,
        messageBody: selectedTemplate
          ? selectedTemplate.messageBody
          : messageBody,
        tags: selectedTags.map((st) => st.externalId),
      });
      next();
    }
  };

  const changeName = (value) => {
    setErrors(errors.filter((e) => e !== "name"));
    setName(value);
  };

  const changeSender = (value) => {
    setErrors(errors.filter((e) => e !== "sender"));
    setSelectedSender(value);
  };

  const changeSelectedTemplate = (option) => {
    setSelectedTemplate(option);
    setMessageBody(option.messageBody);
  };

  return (
    <div className="new-campaign">
      <div className="left">
        <label className="title">Create Campaign</label>
        <div className="create-campaign-input">
          <label className="name">Select template</label>
          <Dropdown
            placeholder={
              templates && templates.length > 0
                ? "Select template"
                : "No templates"
            }
            options={templates}
            selected={selectedTemplate ? selectedTemplate.name : null}
            onSelect={changeSelectedTemplate}
          />
          <div className="info-container">
            <div
              className="info"
              onClick={() => setShoSelectTemplateInfo(!showSelectTemplateInfo)}
              tabIndex="0"
              onBlur={onBlur}
            >
              <label>i</label>
            </div>
            {showSelectTemplateInfo && (
              <label className="template-info">
                Select a previous template to save time
              </label>
            )}
          </div>
        </div>
        <hr />
        <div className="create-campaign-input">
          <label className="name">Campaign name</label>
          <input
            style={{
              borderColor: errors.includes("name") ? "#EA5454" : "#DBDBDB",
            }}
            value={name || ""}
            onChange={(e) => changeName(e.target.value)}
          />
        </div>
        <div className="create-campaign-input sender-id">
          <label className="name">Sender ID</label>
          <Dropdown
            style={{
              borderColor: errors.includes("sender") ? "#EA5454" : "#DBDBDB",
            }}
            placeholder={"No sender id"}
            options={senderIds}
            selected={selectedSender ? selectedSender.name : null}
            onSelect={(option) => changeSender(option)}
          />
          <div className="info-container">
            <div
              className="info"
              onClick={() => setShowCampaignIdInfo(!showCampaignIdInfo)}
              tabIndex="0"
              onBlur={onBlur}
            >
              <label>i</label>
            </div>
            {showCampaignIdInfo && (
              <label className="template-info">
                The Sender ID relates to what will be presented on your
                customers phones when they receive a text from you!
              </label>
            )}
          </div>
          <button onClick={() => setShowCreateSenderPopup(true)}>
            New Sender ID
          </button>
        </div>
        <hr />
        <label className="title">Recipients</label>
        <div className="all-selector">
          <label className="name">Select all</label>
          {selectAll && <label className="value">{recipients.length}</label>}
          {selectAll ? (
            <SwitchOn className="switch" onClick={changeSelectAll} />
          ) : (
            <SwitchOff className="switch" onClick={changeSelectAll} />
          )}
        </div>
        <label className="or">or</label>
        <div className="create-campaign-input">
          <label className="name">Select tags</label>
          <TagsDropdown
            height="33px"
            className="tags-dd"
            placeholder=""
            options={unusedTags}
            tags={selectedTags}
            setTags={changeSelectedTags}
          />
        </div>
        {errors.length > 0 && (
          <label className="error-message">Please fill all fields</label>
        )}
        {noRecipients && (
          <label className="error-message">Please select recipients</label>
        )}
        <div className="actions">
          <button onClick={() => history.goBack()}>Cancel</button>
          <button onClick={nextPage}>Next</button>
        </div>
      </div>
      <div className="right" />
      {showCreateSenderPopup && (
        <CreateSenderPopup
          close={() => setShowCreateSenderPopup(false)}
          save={addSender}
        />
      )}
    </div>
  );
};
