import React from "react";

const NameCampaingField = (props) => {
  const { setCampaignName, campaignName } = props;
  return (
    <div className="quick-send__input-container">
      <label className="quick-send__input-label">Name Campaign </label>
      <div className="quick-send__input-and-info-container">
        <input
          value={campaignName}
          type="search"
          className="quick-send__input"
          onChange={(e) => setCampaignName(e.target.value)}
        />
      </div>
    </div>
  );
};

export default NameCampaingField;
