import React from "react";
import styled from "@emotion/styled";

const Preview = styled.div`
  position: relative;
  width: 320px;
  height: 192px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  margin-top: ${(props) => props.marginTop}px;

  iframe {
    /* position: absolute; */
    cursor: pointer;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 1280px;
    height: 786px;
    border: 0;
    transform: scale(0.25) translateX(-90px);
    transform-origin: 0 0;
  }
`;

export default Preview;
