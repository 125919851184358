import React from 'react'
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ShowVenues from '../components/venue/showVenues'
import VenueDetails from '../components/venue/venueDetails'
import { BackButton } from '../components/widget/backButton'

import '../assets/css/venue/venues.scss'

export default () => {
    const match = useRouteMatch()

    return (
        <div className='venues'>
            <BackButton />
            <Switch>
                <Route path={`${match.path}/:venueId`}>
                    <VenueDetails />
                </Route>
                <Route path={match.path}>
                    <ShowVenues />
                </Route>
            </Switch>
        </div>
    )
}