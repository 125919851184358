import React from "react";
import styled from "@emotion/styled";
import Widget from "../Widget";

const FailedWidgetWrapper = styled(Widget)`
  grid-row: 2/3;
  grid-column: 10/13;

  padding-bottom: 25px;

  @media only screen and (max-width: 1200px) {
      grid-row: 3/4;
      grid-column: 11/16;
    }

  .inner-widget-body {
    margin-top: 16%;
    .stats {
      padding-bottom: 25px;
      .stat-value {
        font-weight: 700;
        font-size: 36px;
        display: block;
        text-align: center;
        color: #ea5454;
      }

      .stat-title {
        padding-bottom: 25px;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
`;

const FailedWidget = (props) => {
  return (
    <FailedWidgetWrapper className="send-dashboard-opens-widget">
      <div className="inner-widget-header">
        <label className="inner-widget-header-title">
          Failed & Unsubscribed
        </label>
        <img alt="unsubscribed" src={require("assets/images/unsubscribed.svg").default} />
      </div>
      <div className="inner-widget-body">
        <div className="stats">
          <label className="stat-value stat-value-failed">
            {props?.statsData?.failedToSend || 0}
          </label>
          <label className="stat-title">Failed to Send</label>
        </div>
        <div className="stats">
          <label className="stat-value stat-value-unsubscribed">
            {props?.statsData?.unsubscribed || 0}
          </label>
          <label className="stat-title">Unsubscribed</label>
        </div>
      </div>
    </FailedWidgetWrapper>
  );
};

export default FailedWidget;
