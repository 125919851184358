import {call, put, takeLatest} from "redux-saga/effects";

import {apiGetOrganisationCampaigns} from "./api";

import {
  GET_ORGANISATION_CAMPAIGNS_REQUEST,
  getOrganisationCampaignsFailure,
  getOrganisationCampaignsSuccess,
} from "./actions";

function* getEmailGlobalStatsWorker(action) {
  try {
    const apiResult = yield call(apiGetOrganisationCampaigns, action.payload);

    yield put(getOrganisationCampaignsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getOrganisationCampaignsFailure(errorMessage));
  }
}

export default function* watcher() {
  yield takeLatest(
    GET_ORGANISATION_CAMPAIGNS_REQUEST,
    getEmailGlobalStatsWorker
  );
}
