import React from 'react'

import '../../assets/css/billing/delete-pm-popup.scss'

export default ({ name, cancel, remove }) => {

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            cancel()
        }
    }

    return (
        <div className='delete-pm-popup' onClick={onClick}>
            <div className='delete-pm-popup-content'>
                <div className='delete-pm-top'>
                    <label>Delete Payment Method</label>
                </div>
                <div className='delete-pm-content'>
                    <label>Are you sure you want to delete <span>{name}</span>?</label>
                    <div className='delete-pm-actions'>
                        <button onClick={cancel}>Cancel</button>
                        <button onClick={remove}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}