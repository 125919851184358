import React from "react";
import styled from "@emotion/styled";
import Widget from "../Widget";

import Gauge from "components/Gauge";

const OpenRateWidgetWrapper = styled(Widget)`
  grid-row: 2/3;
  grid-column: 4/7;

  @media only screen and (max-width: 1200px) {
    grid-row: 2/3;
    grid-column: 6/11;
  }

  /* padding-bottom: 25px; */

  .inner-widget-body {
    padding: 20px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .inner-widget-body-gauge-title {
      font-size: 12px;
      font-weight: 500;
      width: 40%;
      text-align: center;
      transform: translate(0, 100%);
      line-height: 1;

      @media only screen and (max-width: 1200px) {
        font-size: 16px;
        transform: translate(0, 120%);
      }

      @media only screen and (min-width: 1700px) {
        font-size: 16px;
        transform: translate(0, 120%);
      }

      @media only screen and (max-width: 850px) {
        font-size: 14px;
        transform: translate(0, 100%);
      }
    }

    .inner-widget-body-gauge {
    }

    .total-sent {
      font-size: 16px;
      font-weight: 500;
      padding-top: 10px;
    }

    .total-sent span {
      color: #ea5454;
    }
  }

  /* .total-sent {
    font-size: 18px;
    font-weight: 900;
    background-color: red;
  } */
`;

const OpenRateWidget = (props) => {
  return (
      <OpenRateWidgetWrapper className="send-dashboard-open-rate">
        <div className="inner-widget-header">
          <label className="inner-widget-header-title">Overall Open Rate</label>
          <img
              alt="email return rate"
              className="inner-widget-header-img"
              src={require("assets/images/email-return-rate.svg").default}
          />
        </div>

        <div className="inner-widget-body">
          <Gauge
              className="inner-widget-body-gauge"
              value={props?.statsData?.overallOpenRate || 0}
              text={props?.statsData?.overallOpenRate || 0}
          >
            {/* <label>{statistics.overallOpenRate || 0}%</label> */}
            <label className="inner-widget-body-gauge-title">
              Overall Open Rate
            </label>
          </Gauge>

          <label className="total-sent">
            Total Sent: <span> {props?.statsData?.totalSent || 0}</span>
          </label>
        </div>
      </OpenRateWidgetWrapper>
  );
};

export default OpenRateWidget;
