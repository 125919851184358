import styled from "@emotion/styled";

const WidgetWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  height: 100%;
  /* margin: 10px;
  padding: 15px; */
  box-sizing: border-box;
  overflow:${props=> props.showOverflow ? 'visible' : 'hidden'};


  .inner-widget-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    border-bottom: 1px solid #c4c4c4;
    /* padding-top: 22px;
    padding-bottom: 16px; */
    padding:22px 5px 16px 0px;

    .inner-widget-header-title {
      margin-right: 5px;
      font-size: 16px;
      font-family: Roboto;
      font-weight: 600;
      opacity: 0.7;
    }

    .inner-widget-header-img {
      /* width: 35px; */
      margin-left: 5px;
    }
  }

  .inner-widget-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:75%;
  }
`;

export default WidgetWrapper;
