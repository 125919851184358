import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import { getOrganisationCampaignsRequest } from "./actions";

import {
  selectIsLoading,
  selectErrorMessage,
  selectOrganisationCampaigns,
  selectCampaignsSchedules,
} from "./selectors";

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  errorMessage: selectErrorMessage,
  organisationCampaigns: selectOrganisationCampaigns,
  campaignsSchedules: selectCampaignsSchedules,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrganisationCampaignsRequest,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
