export const ADD_FILTER_REQUEST = "ADD_FILTER_REQUEST";
export const ADD_FILTER_FAILURE = "ADD_FILTER_FAILURE";
export const ADD_FILTER_SUCCESS = "ADD_FILTER_SUCCESS";

export const REMOVE_FILTER_REQUEST = "REMOVE_FILTER_REQUEST";
export const REMOVE_FILTER_FAILURE = "REMOVE_FILTER_FAILURE";
export const REMOVE_FILTER_SUCCESS = "REMOVE_FILTER_SUCCESS";

export const addFilterRequest = (newFilter) => ({
  type: ADD_FILTER_REQUEST,
  payload: newFilter,
});

export const addFilterFailure = (errorMessage) => ({
  type: ADD_FILTER_FAILURE,
  payload: errorMessage,
});

export const addFilterSuccess = (updatedFilters) => ({
  type: ADD_FILTER_SUCCESS,
  payload: updatedFilters,
});

export const removeFilterRequest = (filterToDelete) => ({
  type: REMOVE_FILTER_REQUEST,
  payload: filterToDelete,
});

export const removeFilterFailure = (errorMessage) => ({
  type: REMOVE_FILTER_FAILURE,
  payload: errorMessage,
});

export const removeFilterSuccess = (updatedFilters) => ({
  type: REMOVE_FILTER_SUCCESS,
  payload: updatedFilters,
});

//---
