import React from "react";
import styled from "@emotion/styled";
import {useHistory} from "react-router-dom";
import DropDown from "../DropDown";
import PageTitle from "components/PageTitle";

const DashboardTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .cd-title {
    font-size: 38px;
    color: #858688;
    align-self: center;
  }

  .cd-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    .contact-dropdown-container {
      cursor: pointer;

      .dropdown {
        min-width: 110px;
        padding: 10px 20px;
        border: none;
        box-sizing: border-box;
        border-radius: 21.75px;
        background-color: #ff9933;
        cursor: pointer;
        align-items: center;
        align-items: center;
        position: relative;

        .logo {
          path {
            fill: white;
          }
        }

        label {
          width: inherit;
          color: white;
          cursor: pointer;
        }
      }

      .options {
        min-width: 110px;
        height: fit-content;

        label {
          width: inherit;
          cursor: pointer;
          color: black;
        }
      }
    }
  }
`;

const DashboardTop = (props) => {
  const history = useHistory();

  const { selectors, changeSelected } = props;

  return (
    <DashboardTopWrapper>
      <PageTitle title="SMS Dashboard"></PageTitle>
      <div className="cd-actions">
        <DropDown
          options={selectors}
          selected={selectors.find((s) => s.selected).name}
          onSelect={changeSelected}
        />
        <button onClick={() => history.push("/sms/campaigns/manage")}>
          Manage Campaigns
        </button>
        <button
          className="create-btn"
          onClick={() => history.push("/sms/campaigns/create")}
        >
          Create New Campaign
        </button>
      </div>
    </DashboardTopWrapper>
  );
};

export default DashboardTop;
