import React, {useEffect, useState} from 'react'
import {useLocation, useHistory} from "react-router-dom";
import {ReactComponent as EyeIcon} from 'assets/images/eye.svg';
import {ReactComponent as EyeShowIcon} from 'assets/images/eye-show.svg';
import 'assets/css/reset-password.scss'
import {from} from "rxjs";
import axios from "axios";

type PasswordChangeRequest = {
  token: string,
  password: string
}

const ResetPassword = () => {
  const location = useLocation();
  const history = useHistory();

  const token = new URLSearchParams(location.search).get('token');

  const [password, setPassword] = useState<string>("");
  const [passwordMatch, setPasswordMatch] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  useEffect(() => {
    if (!password || password.length < 6) {
      setError("Invalid password");
    } else if (password !== passwordMatch) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  }, [password, passwordMatch]);

  const save = async () => {
    const request: PasswordChangeRequest = {
      token: token,
      password: password
    };
    from(axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/user/password/change`, request))
    .subscribe({
      next: () => {
        setPassword("");
        setPasswordMatch('');
        history.replace('/login');
      },
      error: (err: any) => {
        console.error("Error changing password");
        console.error(err);
        setError("Error changing password. Please contact administrator");
      }
    });
  }

  return (
      <div className='reset-password-page'>
        <div className='reset-password'>
          <div className='reset-password-top'>
            <label>Reset Password</label>
          </div>
          <hr/>
          <div className='reset-password-inputs'>
            <div className='reset-password-input'>
              <label>Password</label>
              <div className='password-input'>
                <input placeholder='Please enter password'
                       type={showPassword ? 'text' : 'password'}
                       className={`${error && 'input-error'}`}
                       value={password}
                       onChange={e => setPassword(e.target.value)}/>
                {showPassword
                    ? <EyeIcon
                        className='eye-icon'
                        onClick={() => setShowPassword(!showPassword)}
                    />
                    : <EyeShowIcon
                        className='eye-icon'
                        onClick={() => setShowPassword(!showPassword)}
                    />}
              </div>
            </div>
            <div className='reset-password-input'>
              <label>Confirm password</label>
              <div className='password-input'>
                <input placeholder='Please confirm password'
                       type={showConfirmPassword ? 'text' : 'password'}
                       className={`${error && 'input-error'}`}
                       value={passwordMatch}
                       onChange={e => setPasswordMatch(e.target.value)}/>
                {showConfirmPassword
                    ? <EyeIcon
                        className='eye-icon'
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    />
                    : <EyeShowIcon
                        className='eye-icon'
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    />}
              </div>
            </div>
            {error && <label className='error-message'>{error}</label>}
          </div>
          <hr/>
          <div className='reset-password-actions'>
            <button
                className={error && error.length > 0 ? 'disabled' : ''}
                onClick={save}
                disabled={error && error.length > 0}
            >
              Save
            </button>
          </div>
        </div>
      </div>
  )
}

export default ResetPassword;
