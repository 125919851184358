import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import { setChartTimeUnitRequest, updateChartDataRequest } from "./actions";

import {
  selectIsLoading,
  selectErrorMessage,
  selectChartTimeUnit,
  // selectChartData,
  // selectChartMaxVal,
} from "./selectors";

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  errorMessage: selectErrorMessage,
  chartTimeUnit: selectChartTimeUnit,
  // chartData: selectChartData,
  // chartMaxVal: selectChartMaxVal,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setChartTimeUnitRequest,
      // updateChartDataRequest,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
