import api from "utils/api";

export const apiGetEmailsStats = (data) => {
  const { organisationId, timeUnit } = data;

  const apiRoute = `/track/organisation/${organisationId}/statistics?unit=${timeUnit}`;

  return api.get(apiRoute);
};

export const apiGetOpenRateStats = (data) => {
  const { organisationId, timeUnit, startDate, endDate } = data;

  const apiRoute = `/track/organisation/${organisationId}/statistics?unit=${timeUnit}&startDate=${startDate}&endDate=${endDate}`;
  return api.get(apiRoute);
};
