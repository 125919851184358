import React from "react";
import styled from "@emotion/styled";

const DefaultButton = (props) => {
  const { size, title, backgroundColor, textcolor, icon, iconColor, onClick } =
    props;


  const handleSize = (size) => {
    switch (size) {
      case "large":
        return { paddingV: 10, paddingH: 25, fontSize: 17 };
      case "medium":
        return { paddingV: 10, paddingH: 25, fontSize: 14 };
      case "small":
        return { paddingV: 10, paddingH: 25, fontSize: 11 };
      default:
        return { paddingV: 10, paddingH: 25, fontSize: 14 };
    }
  };

  const DefaultButtonWrapper = styled.div`
    .dashboard-add-btn {
      border: 0px solid transparent;
      border-radius: 30px;
      cursor: pointer;
      color: ${(props) => (props.textcolor ? props.textcolor : "white")};
      background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : "#E85558"};
      padding: ${() => handleSize(size).paddingV}px
        ${() => handleSize(size).paddingH}px;
      font-size: ${() => handleSize(size).fontSize}px;
    }
  `;

  return (
    <DefaultButtonWrapper>
      <button
        className="dashboard-add-btn"
        onClick={onClick}
        textcolor={textcolor}
        backgroundColor={backgroundColor}
        size={size}
      >
        {title}
      </button>
    </DefaultButtonWrapper>
  );
};

export default DefaultButton;
