import React from "react";

import SelectedIcon from "../../assets/images/selected.js";
import UnselectedIcon from "../../assets/images/unselected.js";

export const RadioButton = ({
  selected,
  onSelect,
  buttonStlye = { height: "32", width: "32" },
}) => {
  return (
    <div onClick={onSelect}>
      {selected ? (
        <SelectedIcon
          height={buttonStlye.height}
          width={buttonStlye.width}
          buttonStlyeclassName="selector"
        />
      ) : (
        <UnselectedIcon
          height={buttonStlye.height}
          width={buttonStlye.width}
          className="selector"
        />
      )}
    </div>
  );
};
