import {createSelector} from "reselect";
import _get from "lodash/fp/get";
import moment from "moment";
import {REDUCER_NAME} from "./consts";

export const selectIsLoading = createSelector(
    (state) => state[REDUCER_NAME],
    _get("loading")
);

export const selectErrorMessage = createSelector(
    (state) => state[REDUCER_NAME],
    _get("errorMessage")
);

export const selectOrganisationCampaigns = createSelector(
    (state) => state[REDUCER_NAME],
    _get("organisationCampaigns")
);

export const selectCampaignsSchedules = createSelector(
    selectOrganisationCampaigns,
    (schedules) => {
      if (schedules && schedules.length > 0) {
        return schedules
        .filter((schedule) => schedule && schedule.schedules)
        .map((schedule) => ({
          ...schedule,
          formattedScheduleDate: moment(
              new Date(schedule.schedules[0].scheduleDate * 1000)),
        }));
      }
    }
);
