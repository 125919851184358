import React, {useState} from "react"
import styled from "@emotion/styled"
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  TextField
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import {change, Field, reduxForm} from "redux-form"
import {compose} from "recompose";
import AuthContainer from "containers/Auth"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "assets/css/login.scss";
import logo from "assets/images/full-icon.svg"

import banner1 from "assets/images/banner-1.png"
import banner2 from "assets/images/banner-2.png"
import banner3 from "assets/images/banner-3.png"

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Slider from 'infinite-react-carousel';

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  customTextField: {
    "& label": {
      marginLeft: 10,
    },
    "& label.Mui-focused": {
      color: "orange"
    },
    "& .MuiOutlinedInput-root": {
      width: 350,
      height: 40,
      marginLeft: 10,
      marginBottom: 20,
      "&.Mui-focused fieldset": {
        borderColor: "orange"
      },
      "& > fieldset": {
        borderRadius: 25,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange"
      }
    },
    "& .MuiFormHelperText-root": {
      marginBottom: 0,
    }
  },
}));

const TypographyText = styled.div`
  height: 32px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: left;
  color: rgba(15, 15, 15, 0.87);
  margin-bottom: 40px;
`;

const Login = (props) => {

  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [values, setValues] = useState({password: '', showPassword: false});
  const {errorMessage, loginRequest, isUserLoggedIn, handleSubmit} = props;

  const [redirect, setRedirect] = useState(false);

  const [rememberMe, setRememberMe] = useState(true);

  React.useEffect(() => {
    isUserLoggedIn && setRedirect(true);
  }, [isUserLoggedIn]);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [prop]: event.target.value});
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit({'loginEmail': email, 'loginPassword': password});
    loginRequest({'loginEmail': email, 'loginPassword': password});
  }

  const settings = {
    autoplay: true,
    dots: false,
    arrows: true,
  };

  return (
      <div className="App">
        <div className="appAside">
          <Slider styles={{width: '100%'}} {...settings}>
            <div>
              <img style={{width: '100%', maxWidth: '100%'}} alt="banner1"
                   className="logo" src={banner1}/>
            </div>
            <div>
              <img style={{width: '100%', maxWidth: '100%'}} alt="banner2"
                   className="logo" src={banner2}/>
            </div>
            <div>
              <img style={{width: '100%', maxWidth: '100%'}} alt="banner3"
                   className="logo" src={banner3}/>
            </div>
          </Slider>
        </div>

        <div className="appForm">
          <div className="formCenter">
            <div className="login-page">
              <div className="login-form">
                <img alt="logo" className="logo" src={logo}/>
                <div
                    className={`error-message-container ${
                        !errorMessage && "error-message-container-hidden"
                    }`}
                >
                  <label>Invalid username or password</label>
                </div>
                <TypographyText>Login</TypographyText>
                <form onSubmit={handleFormSubmit}>
                  <TextField
                      size="small"
                      name="loginEmail"
                      label="Email Address"
                      InputLabelProps={{shrink: true}}
                      className={classes.customTextField}
                      onInput={e => setEmail(e.target.value)}
                  />
                  <FormControl sx={{
                    m: 1, width: '35ch',
                    outlineColor: "orange"
                    , "& label.Mui-focused": {
                      color: "orange"
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "orange"
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: "orange",
                      },
                    }
                  }} variant="outlined">
                    <InputLabel shrink={true}
                                htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        className={classes.customTextField}
                        notched
                        sx={{
                          borderRadius: 16,
                          height: 40,
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "orange",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "orange",
                          }
                        }}
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onInput={e => setPassword(e.target.value)}
                        onChange={handleChange('password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                              {values.showPassword ? <VisibilityOff/> :
                                  <Visibility/>}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                    />
                  </FormControl>

                  <a style={{
                    marginTop: '20px',
                    marginLeft: '10px',
                    fontWeight: '500'
                  }} href="/password/new">FORGOTTEN PASSWORD?</a>
                  <Button variant="contained"
                          type="submit"
                          endIcon={<ArrowForwardIcon
                              sx={{fontSize: '80px', fontWeight: '400'}}/>}
                          sx={{
                            width: '55ch',
                            marginTop: 4,
                            marginBottom: 4,
                            backgroundColor: "#FF9933",
                            borderRadius: 16,
                            color: "white",
                            fontSize: 12,
                            fontWeight: "500",
                            zIndex: 1,
                            '&:hover': {
                              backgroundColor: '#FF9933',
                              color: 'white',
                            },
                          }}
                          disabled={false}
                  >
                    LOGIN
                  </Button>
                </form>

                <div className="remember-me">
                  <FormGroup>
                    <FormControlLabel control={
                      <Checkbox
                          checked={rememberMe}
                          onChange={handleCheckboxChange}
                          inputProps={{'aria-label': 'controlled'}}
                          sx={{
                            marginLeft: '12ch',
                            "&.Mui-checked": {
                              color: "#ff9933",
                            },
                            color: 'black'
                          }}
                      />

                    } label="Remember Me"/>
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default compose(
    reduxForm({
      form: "LOGIN_FORM",
    }),
    AuthContainer
)(Login);
