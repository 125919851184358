import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import navigationDebouncer from "react-navigation-redux-debouncer";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createRootReducer from "./rootReducer";

import rootSaga from "./rootSaga";

import { REDUCER_NAME as userReducerName } from "containers/User/consts";
import { REDUCER_NAME as contactsListReducerName } from "containers/ContactsList/consts";
import { REDUCER_NAME as sendNewEmailReducerName } from "containers/Send/SendNewEmail/consts";
import { REDUCER_NAME as selectedOrganisationReducerName } from "containers/SelectedOrganisation/consts";

import history from "utils/history";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "router",
    selectedOrganisationReducerName,
    userReducerName,
    contactsListReducerName,
    sendNewEmailReducerName,
  ],
  // blacklist: ["router"],
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

export default function configureStore(initialState = {}) {
  const middlewares = [
    sagaMiddleware,
    navigationDebouncer(500),
    routerMiddleware(history),
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers)
  );

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
}
