import React from "react";
import styled from "@emotion/styled";
import ChartUnitsDropdown from "./ChartUnitsDropdown";
// import { mapToChartData, chartDropdownButtons } from "utils/charts";
import positionIcon from "assets/images/position-icon.svg"

const ChartHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${(props) => (props.withIcon ? "10px" : "26px")};

  .logo-title {
    display: flex;
    flex-direction: row;

    .logo {
      position: relative;
      display: flex;
      background-color: #ffcc33;
      height: 60px;
      width: 60px;
      border-radius: 0 0 0 30px;
      justify-content: center;
      align-items: center;
      transform: translate(0, -20px);

      img {
        height: 35px;
        width: 35px;
        align-self: center;
      }
    }

    .chart-title {
      margin-left: 25px;
      font-size: 18px;
      font-family: Roboto;
      font-weight: 600;
      opacity: 0.7;
      align-self: center;
    }
  }

  .unit-selector {
    .chart-button {
      min-width: 60px;
      margin-left: 10px;
      border-radius: 25px;
      color: #717171;
      align-self: center;
      background-color: #e9e9e9;
      border: none;
      padding-top: 5px;
      padding-bottom: 5px;
      outline: 0;
      cursor: pointer;
    }

    .chart-button-selected {
      color: white;
      background-color: $accent-color;
      border: none;
    }
  }
`;

const ChartHeader = (props) => {
  const {
    chartDropdownButtons,
    withIcon,
    chartTitle,
    currentTimeUnit,
    setCurrentTimeUnit,
    setChartTouched,
  } = props;

  const [items, setItems] = React.useState(chartDropdownButtons);

  const changeSelected = (item) => {
    const { index } = item;

    const unit = items[index].unit;

    if (unit !== currentTimeUnit) {
      setChartTouched(true);
      items.map((item) => (item.selected = false));
      items[index].selected = true;
      setItems([...items]);
      setCurrentTimeUnit(unit);
    }
  };

  return (
    <ChartHeaderWrapper withIcon={withIcon}>
      <div className="logo-title">
        {withIcon && (
          <div className="logo">
            <img alt="position icon" src={positionIcon} />
          </div>
        )}
        <label className="chart-title">{chartTitle}</label>
      </div>
      <div className="unit-selector">
        <ChartUnitsDropdown
          options={chartDropdownButtons}
          selected={chartDropdownButtons.find((s) => s.selected)}
          onSelect={changeSelected}
        />
      </div>
    </ChartHeaderWrapper>
  );
};

export default ChartHeader;
