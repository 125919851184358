import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import PageNavigator from "components/PageNavigator";
import { PageNavigationContext } from "context/navigationContext";
import { ReactComponent as DeviceLogo } from "assets/images/devices-icon.svg";
import { DeletePopup } from "../widget/deletePopup";
import { SelectDevicePopup } from "../widget/selectDevicePopup";

import { DeviceItem } from "./deviceItem";
import api from "utils/api";

import "assets/css/devices/show-devices.scss";

export const ShowDevices = ({
  venue,
  devices,
  setDevices,
  setName,
  navigation,
  setNavigation,
  changePage,
}) => {
  const [deletedDevice, setDeletedDevice] = useState();

  const [showSelectDevicePopup, setShowSelectDevicePopup] = useState(false);
  const history = useHistory();

  const filterByName = (e) => {
    if (e.target.value.length > 2) setName(e.target.value);
    else setName("");
  };

  const deleteDevice = async () => {
    await api.delete(
      `/organisations/${deletedDevice.organisationId}/devices/${deletedDevice.externalId}`
    );
    setDevices([
      ...devices.filter(
        (device) => device.externalId !== deletedDevice.externalId
      ),
    ]);
    setDeletedDevice(null);
  };

  const updateDevice = async (device) => {
    await api.put(`/devices/${device.externalId}`, device);
  };

  return (
    <div className="show-devices">
      <div className="devices-page-top">
        <div className="devices-page-top-left">
          <div className="devices-page-logo">
            <DeviceLogo className="devices-page-logo-svg" />
          </div>
          <input
            type="search"
            placeholder="Search devices..."
            onChange={(e) => filterByName(e)}
          />
        </div>

        <div className="devices-page-top-right">
          <div className="navigation">
            <PageNavigationContext.Provider
              value={{ navigation, setNavigation }}
            >
              <PageNavigator changePage={(page) => changePage(page)} />
            </PageNavigationContext.Provider>
          </div>
          <button
            className="add-device-btn"
            onClick={() => setShowSelectDevicePopup(true)}
          ></button>
        </div>
      </div>
      <div className="devices-grid">
        {devices && devices.map((device) => (
          <DeviceItem
            key={device.externalId}
            device={device}
            updateDevice={updateDevice}
            onDelete={(device) => setDeletedDevice(device)}
          />
        ))}
      </div>
      {deletedDevice && (
        <DeletePopup
          onCancel={() => setDeletedDevice(null)}
          onDelete={deleteDevice}
        />
      )}
      {showSelectDevicePopup && (
        <SelectDevicePopup
          close={() => setShowSelectDevicePopup(false)}
          next={(source) =>
            history.push(`/devices/import?source=${source}&venue=${venue}`)
          }
        />
      )}
    </div>
  );
};
