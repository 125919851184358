export const GET_SEGMENTS_REQUEST = "GET_SEGMENTS_REQUEST";
export const GET_SEGMENTS_FAILURE = "GET_SEGMENTS_FAILURE";
export const GET_SEGMENTS_SUCCESS = "GET_SEGMENTS_SUCCESS";

export const SELECT_SEGMENT_REQUEST = "SELECT_SEGMENT_REQUEST";
export const SELECT_SEGMENT_FAILURE = "SELECT_SEGMENT_FAILURE";
export const SELECT_SEGMENT_SUCCESS = "SELECT_SEGMENT_SUCCESS";

export const GET_SEGMENT_CONTACTS_REQUEST = "GET_SEGMENT_CONTACTS_REQUEST";
export const GET_SEGMENT_CONTACTS_FAILURE = "GET_SEGMENT_CONTACTS_FAILURE";
export const GET_SEGMENT_CONTACTS_SUCCESS = "GET_SEGMENT_CONTACTS_SUCCESS";

export const TOGGLE_SEGMENT_CONTACT_REQUEST = "TOGGLE_SEGMENT_CONTACT_REQUEST";
export const TOGGLE_SEGMENT_CONTACT_FAILURE = "TOGGLE_SEGMENT_CONTACT_FAILURE";
export const TOGGLE_SEGMENT_CONTACT_SUCCESS = "TOGGLE_SEGMENT_CONTACT_SUCCESS";

export const TOGGLE_ALL_SEGMENT_CONTACTS_REQUEST = "TOGGLE_ALL_SEGMENT_CONTACTS_REQUEST";
export const TOGGLE_ALL_SEGMENT_CONTACTS_FAILURE = "TOGGLE_ALL_SEGMENT_CONTACTS_FAILURE";
export const TOGGLE_ALL_SEGMENT_CONTACTS_SUCCESS = "TOGGLE_ALL_SEGMENT_CONTACTS_SUCCESS";

export const CREATE_SEGMENT_REQUEST = "CREATE_SEGMENT_REQUEST";
export const CREATE_SEGMENT_FAILURE = "CREATE_SEGMENT_FAILURE";
export const CREATE_SEGMENT_SUCCESS = "CREATE_SEGMENT_SUCCESS";

export const DELETE_SEGMENT_REQUEST = "DELETE_SEGMENT_REQUEST";
export const DELETE_SEGMENT_FAILURE = "DELETE_SEGMENT_FAILURE";
export const DELETE_SEGMENT_SUCCESS = "DELETE_SEGMENT_SUCCESS";

// --- GET SEGMENT CONTACTS

export const getSegmentsRequest = (organisationId) => ({
  type: GET_SEGMENTS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getSegmentsFailure = (errorMessage) => ({
  type: GET_SEGMENTS_FAILURE,
  payload: errorMessage,
});

export const getSegmentsSuccess = (segments) => ({
  type: GET_SEGMENTS_SUCCESS,
  payload: segments,
});

// --- SELECT SEGMENT

export const selectSegmentRequest = (segmentId) => ({
  type: SELECT_SEGMENT_REQUEST,
  payload: {
    segmentId,
  },
});

export const selectSegmentFailure = (errorMessage) => ({
  type: SELECT_SEGMENT_FAILURE,
  payload: errorMessage,
});

export const selectSegmentSuccess = (selectedSegment) => ({
  type: SELECT_SEGMENT_SUCCESS,
  payload: {
    selectedSegment,
  },
});

// --- GET SEGMENT CONTACTS

export const getSegmentContactsRequest = (
    organisationId,
    segmentId,
    pageNumber,
    pageSize,
    searchString,
    criteria
) => ({
  type: GET_SEGMENT_CONTACTS_REQUEST,
  payload: {
    organisationId,
    segmentId,
    pageNumber,
    pageSize,
    searchString,
    criteria
  },
});

export const getSegmentContactsFailure = (errorMessage) => ({
  type: GET_SEGMENT_CONTACTS_FAILURE,
  payload: errorMessage,
});

export const getSegmentContactsSuccess = (
    segmentContacts,
    currentPagination
) => ({
  type: GET_SEGMENT_CONTACTS_SUCCESS,
  payload: {
    segmentContacts,
    currentPagination,
  },
});

// --- TOGGLE SEGMENT CONTACT

export const toggleSegmentContactRequest = (contactId) => ({
  type: TOGGLE_SEGMENT_CONTACT_REQUEST,
  payload: {
    contactId,
  },
});

export const toggleSegmentContactFailure = (errorMessage) => ({
  type: TOGGLE_SEGMENT_CONTACT_FAILURE,
  payload: errorMessage,
});

export const toggleSegmentContactSuccess = (contactId) => ({
  type: TOGGLE_SEGMENT_CONTACT_SUCCESS,
  payload: {
    contactId,
  },
});

// --- CREATE SEGMENT

export const createSegmentRequest = (newSegmentData) => ({
  type: CREATE_SEGMENT_REQUEST,
  payload: {
    newSegmentData,
  },
});

export const createSegmentFailure = (errorMessage) => ({
  type: CREATE_SEGMENT_FAILURE,
  payload: errorMessage,
});

export const createSegmentSuccess = (updatedSegments) => ({
  type: CREATE_SEGMENT_SUCCESS,
  payload: {
    updatedSegments,
  },
});

// --- DELETE SEGMENT

export const deleteSegmentRequest = (segmentToDelete) => ({
  type: DELETE_SEGMENT_REQUEST,
  payload: {
    segmentToDelete,
  },
});

export const deleteSegmentFailure = (errorMessage) => ({
  type: DELETE_SEGMENT_FAILURE,
  payload: errorMessage,
});

export const deleteSegmentSuccess = (deletedSegmentId) => ({
  type: DELETE_SEGMENT_SUCCESS,
  payload: {
    deletedSegmentId,
  },
});

// --- TOGGLE ALL SEGMENT CONTACTS

export const toggleAllSegmentContactsRequest = (
    segmentContacts,
    shouldToggleOn
) => ({
  type: TOGGLE_ALL_SEGMENT_CONTACTS_REQUEST,
  payload: {
    segmentContacts,
    shouldToggleOn,
  },
});

export const toggleAllSegmentContactsFailure = (errorMessage) => ({
  type: TOGGLE_ALL_SEGMENT_CONTACTS_FAILURE,
  payload: errorMessage,
});

export const toggleAllSegmentContactsSuccess = (updatedSegmentSelectedContacts) => ({
  type: TOGGLE_ALL_SEGMENT_CONTACTS_SUCCESS,
  payload: {
    updatedSegmentSelectedContacts,
  },
});