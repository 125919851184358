import React from "react";
import styled from "@emotion/styled";

import Widget from "../Widget";

const OpensWidgetWrapper = styled(Widget)`
  grid-row: 2/3;
  grid-column: 1/4;

  padding-bottom: 25px;



  @media only screen and (max-width: 1200px) {
    grid-row: 2/3;
    grid-column: 11/16;
  }

  .inner-widget-body {
    margin-top: 16%;
    .stats {
      padding-bottom: 25px;
      .stat-value {
        font-weight: 700;
        font-size: 36px;
        display: block;
        text-align: center;
      }

      .stat-value-unique {
        color: #ff9933;
      }

      .stat-value-total {
        color: #ea5454;
      }

      .stat-title {
        padding-bottom: 25px;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
`;

const OpensWidget = (props) => {
  return (
      <OpensWidgetWrapper className="send-dashboard-opens-widget">
        <div className="inner-widget-header">
          <label className="inner-widget-header-title">Opens</label>
          <img
              alt="emails open"
              className="inner-widget-header-img"
              src={require("assets/images/emails-open.svg").default}
          />
        </div>
        <div className="inner-widget-body">
          <div className="stats">
            <label className="stat-value stat-value-unique">
              {props?.statsData?.totalUniqueOpens || 0}
            </label>
            <label className="stat-title">Total Unique Opens</label>
          </div>
          <div className="stats">
            <label className="stat-value stat-value-total">
              {props?.statsData?.totalOpens || 0}
            </label>
            <label className="stat-title">Total Opens</label>
          </div>
        </div>
      </OpensWidgetWrapper>
  );
};

export default OpensWidget;
