import { createSelector } from "reselect";

import { authSliceSelector } from "store/selectors";

export const selectIsLoading = createSelector(
  authSliceSelector,
  (authSliceSelector) => authSliceSelector && authSliceSelector["loading"]
);

export const selectErrorMessage = createSelector(
  authSliceSelector,
  (authSliceSelector) => authSliceSelector && authSliceSelector["errorMessage"]
);

export const selectIsUserLoggedIn = createSelector(
  authSliceSelector,
  (authSliceSelector) =>
    authSliceSelector && authSliceSelector["isUserLoggedIn"]
);
