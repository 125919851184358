import React from "react";

const Iframe = (props) => {
  const {width, height, borderRadius} = props;
  let iframe_ref = null;
  const writeHTML = (frame) => {
    if (!frame) {
      return;
    }
    iframe_ref = frame;
    let doc = frame.contentDocument;
    doc.open();
    doc.write(props.content);
    doc.close();

    if (width) {
      frame.style.width = width;
    }

    if (height) {
      frame.style.height = height;
    }

    if (borderRadius) {
      frame.style.borderRadius = borderRadius;
    }

    //  frame.style.width = '100%';
    //  frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
    // frame.style.position="absolute"
  };
  return (
      <iframe
          title="iframe"
          className="iframe-component"
          src="about:blank"
          frameBorder="0"
          ref={writeHTML}
      />
  );
};
export default Iframe;
