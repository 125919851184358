import React from "react";
import styled from "@emotion/styled";

const BackButtonWrapper = styled.button`
  height: 30px;
  width: 80px;
  background: #ffcc33;
  border-radius: 45px;
  outline: none;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
`;

const BackButton = (props) => {
  const { currentStep, updateCurrentStepRequest, steps } = props;
  return (
    currentStep > 0 && (
      <BackButtonWrapper
        onClick={() => updateCurrentStepRequest(currentStep - 1)}
      >
        Back
      </BackButtonWrapper>
    )
  );
};

export default BackButton;
