import {
  TOGGLE_NAV_BAR_REQUEST,
  TOGGLE_NAV_BAR_FAILURE,
  TOGGLE_NAV_BAR_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  errorMessage: null,
  isNavBarLocked: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_NAV_BAR_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case TOGGLE_NAV_BAR_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case TOGGLE_NAV_BAR_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        isNavBarLocked: action.payload,
      };
    }

    default:
      return state;
  }
}
