import React from "react";
import styled from "@emotion/styled";

import CampaignCard from "./CampaignCard";
import NoCampaigns from "./NoCampaigns";

const CampaignsDisplayWrapper = styled.div``;

const CampaignsDisplay = (props) => {
  const { campaigns } = props;
  return (
    <CampaignsDisplayWrapper>
      {campaigns && campaigns.length > 0 ? (
        <div className="mec-content">
          {campaigns.map((campaign) => {
            return <CampaignCard campaign={campaign} />;
          })}
        </div>
      ) : (
        <NoCampaigns />
      )}
    </CampaignsDisplayWrapper>
  );
};

export default CampaignsDisplay;
