import {call, put, takeLatest} from "redux-saga/effects";

import {apiGetEmailGlobalStats} from "./api";

import {
  GET_EMAIL_GLOBAL_STATS_REQUEST,
  getEmailGlobalStatsFailure,
  getEmailGlobalStatsSuccess,
} from "./actions";

function* getEmailGlobalStatsWorker(action) {
  try {
    const apiResult = yield call(apiGetEmailGlobalStats, action.payload);

    yield put(getEmailGlobalStatsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getEmailGlobalStatsFailure(errorMessage));
  }
}

export default function* watcher() {
  yield takeLatest(GET_EMAIL_GLOBAL_STATS_REQUEST, getEmailGlobalStatsWorker);
}
