import React from "react";

import {ReactComponent as BulletIcon} from "assets/images/bullet.svg";

import "assets/css/widget/bullet-navigator.scss";

const BulletNavigator = ({
      screens,
      currentPreviewScreen = 0,
      setCurrentPreviewScreen
    }) => {
  return (
    <div className="bullet-navigator">
      {screens.map((value) => (
        <BulletIcon
          key={value}
          className={`bullet-navigator-bullet ${
            value === currentPreviewScreen && "bullet-navigator-bullet-active"
          }`}
          onClick={() => setCurrentPreviewScreen(value)}
        />
      ))}
    </div>
  );
};

export default BulletNavigator;
