import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import { loginRequest, logoutRequest } from "./actions";

import {
  selectIsLoading,
  selectErrorMessage,
  selectIsUserLoggedIn,
} from "./selectors";

// const { injectReducer } = reduxConfigStore.store;

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  errorMessage: selectErrorMessage,
  isUserLoggedIn: selectIsUserLoggedIn,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginRequest,
      logoutRequest,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  // lifecycle({
  //   componentDidMount() {
  //     injectReducer(REDUCER_NAME, reducer);
  //   },
  // })
);
