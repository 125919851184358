import React from "react";
import {Backdrop, Fade, Modal} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "25px",
    maxWidth: "400px",
    zIndex: 1,
  },
}));

export default function AddContactt() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <div>
        <div className={"importList"} onClick={handleOpen}>
          {/*react-transition-group*/}
          Import list
        </div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={"importList2"}>
                <div className={"importList"}>Import list</div>
                <div className={"line"}></div>
                <div className={"importTitle"}>Import List</div>
                <div className={"dragDrop"}>
                  <img alt="group" src={require("../../../assets/images/Group11428.png").default}/>
                  <p className={"titleDrag"}>File Name/ Preview</p>
                  <button>Choose Different File</button>
                </div>

                <div className={"audence"}>
                  <div className={"audenceLeft"}>
                    <p className={"audenceRightTitle"}>Add to Audience list</p>
                    <img
                        alt="group"
                        src={require("../../../assets/images/Group 11426.png")}
                    />
                  </div>

                  <div className={"audenceLine"}></div>
                  <div className={"audenceRight"}>
                    <div className={"bl"}>
                      <p className={"audenceRightTitle"}>
                        Auto-update your existing contacts
                      </p>
                      <p className={"audenceRightDescription"}>
                        If a duplicate contact e.g. Phone Number / Email Address
                        is found it will be updated with the new details from
                        this
                        list. If this is left unchecked it will create a new
                        contact.
                      </p>
                    </div>

                    <div className={"bl"}>
                      <p className={"audenceRightTitle"}>
                        Mark these contacts as subscribed?
                      </p>
                      <p className={"audenceRightDescription"}>
                        When you choose the "Subscribed" status for your
                        contacts,
                        it indicates that you've gained permission to market to
                        them.
                      </p>
                    </div>
                  </div>
                </div>

                <button className={"nextAudence"}>Next</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
  );
}
