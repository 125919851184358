export const UPDATE_CURRENT_CAMPAIGN_REQUEST =
  "UPDATE_CURRENT_CAMPAIGN_REQUEST";
export const UPDATE_CURRENT_CAMPAIGN_FAILURE =
  "UPDATE_CURRENT_CAMPAIGN_FAILURE";
export const UPDATE_CURRENT_CAMPAIGN_SUCCESS =
  "UPDATE_CURRENT_CAMPAIGN_SUCCESS";

export const CREATE_CURRENT_CAMPAIGN_REQUEST =
  "CREATE_CURRENT_CAMPAIGN_REQUEST";
export const CREATE_CURRENT_CAMPAIGN_FAILURE =
  "CREATE_CURRENT_CAMPAIGN_FAILURE";
export const CREATE_CURRENT_CAMPAIGN_SUCCESS =
  "CREATE_CURRENT_CAMPAIGN_SUCCESS";

// --- UPDATE_CURRENT_CAMPAIGN

export const updateCurrentCampaignRequest = (
  organisationId,
  fieldToUpdate,
  fieldData
) => ({
  type: UPDATE_CURRENT_CAMPAIGN_REQUEST,
  payload: {
    organisationId,
    fieldToUpdate,
    fieldData,
  },
});

export const updateCurrentCampaignFailure = (errorMessage) => ({
  type: UPDATE_CURRENT_CAMPAIGN_FAILURE,
  payload: errorMessage,
});

export const updateCurrentCampaignSuccess = (
  organisationId,
  fieldToUpdate,
  fieldData
) => ({
  type: UPDATE_CURRENT_CAMPAIGN_SUCCESS,
  payload: organisationId,
  fieldToUpdate,
  fieldData,
});

// --- CREATE_CURRENT_CAMPAIGN

export const createCurrentCampaignRequest = (campaignData) => ({
  type: CREATE_CURRENT_CAMPAIGN_REQUEST,
  payload: campaignData,
});

export const createCurrentCampaignFailure = (errorMessage) => ({
  type: CREATE_CURRENT_CAMPAIGN_FAILURE,
  payload: errorMessage,
});

export const createCurrentCampaignSuccess = (campaignData) => ({
  type: CREATE_CURRENT_CAMPAIGN_SUCCESS,
  payload: campaignData,
});
