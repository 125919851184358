import {
  GET_EMAIL_GLOBAL_STATS_REQUEST,
  GET_EMAIL_GLOBAL_STATS_FAILURE,
  GET_EMAIL_GLOBAL_STATS_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  errorMessage: null,
  globalEmailStats: null,
  organisationVisits: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMAIL_GLOBAL_STATS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        globalEmailStats: null,
      };
    }

    case GET_EMAIL_GLOBAL_STATS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        globalEmailStats: null,
      };
    }

    case GET_EMAIL_GLOBAL_STATS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        globalEmailStats: action.payload[0],
      };
    }

    default:
      return state;
  }
}
