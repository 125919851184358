export const GET_CONTACTS_REQUEST = "GET_CONTACTS_REQUEST";
export const GET_CONTACTS_FAILURE = "GET_CONTACTS_FAILURE";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";

// --- GET_CONTACTS

export const getContactsRequest = () => ({
  type: GET_CONTACTS_REQUEST,
});

export const getContactsFailure = (errorMessage) => ({
  type: GET_CONTACTS_FAILURE,
  payload: errorMessage,
});

export const getContactsSuccess = () => ({
  type: GET_CONTACTS_SUCCESS,
});
