import * as selectedOrganisationActions from "./selectedOrganisation";
import * as selectedVenueActions from "./selectedVenue";
import * as selectedEntityActions from "./selectedEntity";

const organisationActions = {
  ...selectedOrganisationActions,
  ...selectedVenueActions,
  ...selectedEntityActions,
};

export default organisationActions;
