export const GET_ALL_VENUES_REQUEST = "GET_ALL_VENUES_REQUEST";
export const GET_ALL_VENUES_FAILURE = "GET_ALL_VENUES_FAILURE";
export const GET_ALL_VENUES_SUCCESS = "GET_ALL_VENUES_SUCCESS";

export const SELECT_VENUE_REQUEST = "SELECT_VENUE_REQUEST";
export const SELECT_VENUE_FAILURE = "SELECT_VENUE_FAILURE";
export const SELECT_VENUE_SUCCESS = "SELECT_VENUE_SUCCESS";

export const CLEAR_SELECTED_VENUE_REQUEST = "CLEAR_SELECTED_VENUE_REQUEST";
export const CLEAR_SELECTED_VENUE_FAILURE = "CLEAR_SELECTED_VENUE_FAILURE";
export const CLEAR_SELECTED_VENUE_SUCCESS = "CLEAR_SELECTED_VENUE_SUCCESS";

// --- GET ALL VENUES _REQUEST

export const getAllVenuesRequest = (organisationId) => ({
  type: GET_ALL_VENUES_REQUEST,
  payload: {
    organisationId,
  },
});

export const getAllVenuesFailure = (errorMessage) => ({
  type: GET_ALL_VENUES_FAILURE,
  payload: errorMessage,
});

export const getAllVenuesSuccess = (allVenues) => ({
  type: GET_ALL_VENUES_SUCCESS,
  payload: allVenues,
});

// --- SELECT_VENUE_REQUEST

export const selectVenueRequest = (selectedVenue) => ({
  type: SELECT_VENUE_REQUEST,
  payload: {
    selectedVenue,
  },
});

export const selectVenueFailure = (errorMessage) => ({
  type: SELECT_VENUE_FAILURE,
  payload: errorMessage,
});

export const selectVenueSuccess = (selectedVenue) => ({
  type: SELECT_VENUE_SUCCESS,
  payload: selectedVenue,
});

// --- CLEAR_SELECTED__VENUE_REQUEST

export const clearSelectedVenueVenueRequest = () => ({
  type: CLEAR_SELECTED_VENUE_REQUEST,
});

export const clearSelectedVenueVenueFailure = (errorMessage) => ({
  type: CLEAR_SELECTED_VENUE_FAILURE,
  payload: errorMessage,
});

export const clearSelectedVenueVenueSuccess = () => ({
  type: CLEAR_SELECTED_VENUE_SUCCESS,
});
