import React from "react";

export default ({ height = "32", width = "32" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="15.5"
        fill="#F7F7F7"
        stroke="#C6C6C6"
      />
    </svg>
  );
};
