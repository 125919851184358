import styled from "@emotion/styled";

const CreateContactModalWrapper = styled.form`
  position: relative;
  width: 565px;
  height: 530px;
  background: #FFFFFF;
  border-radius: 16px;
`;

export default CreateContactModalWrapper;