import React, {useState, useEffect} from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Menu,
  Radio,
  RadioGroup,
  Divider
} from "@mui/material";
import DoneButton from "./DoneButton";

const FirstVisit = (props) => {

  const {change, destroy, handleSubmit, addFilterRequest} = props;
  const [greaterField, setGreaterField] = useState("");
  const [lessField, setLessField] = useState("");
  const [equalField, setEqualField] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (
        greaterField?.length > 0 ||
        lessField?.length > 0 ||
        equalField?.length > 0
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [greaterField, lessField, equalField]);

  const [value, setValue] = useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    destroy("ADD_FILTER_MODAL_FORM");
    setGreaterField("");
    setLessField("");
    setEqualField("");
  };

  useEffect(() => {
    anchorEl === null && destroy("ADD_FILTER_MODAL_FORM");
  }, [anchorEl]);

  const handleOnChange = (operation, event) => {
    const regexExp = new RegExp(/^(\s*|\d+)$/);

    switch (operation) {
      case "GT":
        setLessField("");
        setEqualField("");

        if (regexExp.test(event.target.value)) {
          setGreaterField(event.target.value);
          change("value", event.target.value);
          change("operation", "GT");
        }

        break;
      case "LT":
        setGreaterField("");
        setEqualField("");

        if (regexExp.test(event.target.value)) {
          setLessField(event.target.value);
          change("value", event.target.value);
          change("operation", "LT");
        }
        break;
      case "EQUALS":
        setGreaterField("");
        setLessField("");

        if (regexExp.test(event.target.value)) {
          setEqualField(event.target.value);
          change("value", event.target.value);
          change("operation", "EQUALS");
        }
        break;
    }
  };

  const onClick = (values: any) => {
    addFilterRequest({
      key: 'firstVisit',
      operation: values.operation,
      value: values.value
    });
    handleClose();
  }

  return (
      <div>
        <div
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
        >
          First Visit
        </div>

        <Menu
            id="simple-menu"
            // anchorEl={anchorEl}
            anchorReference="anchorPosition"
            anchorPosition={{top: 350, left: 280}}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            // getContentAnchorEl={null}
            // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            // transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '260px',
                height: '270px',
                justifyContent: 'center',
              },
            }}
            // MenuListProps={{
            //   style: {
            //     padding: 0,
            //   },
            // }}
        >
          <FormControl className={"filterMenu"} component="fieldset">
            <RadioGroup
                aria-label="firstVisit"
                name="firstVisit"
                value={value}
                onChange={handleChange}
            >
              <FormControlLabel
                  value="More Than"
                  control={<Radio sx={{
                    '&.Mui-checked': {
                      color: "#FF9933",
                    },
                  }}/>}
                  onClick={() => {
                    setLessField("");
                    setEqualField("");
                    change("value", null);
                    change("operation", "GT");
                  }}
                  label="More Than (Days Ago)"
              />
              {value === "More Than" && (
                  <input
                      onChange={(event) => {
                        handleOnChange("GT", event);
                      }}
                      style={{
                        height: '30px',
                        borderRadius: '28px',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        outlineColor: "#FF9933",
                        textAlign: 'center',
                        fontSize: '16px'
                      }}
                      value={greaterField}
                      type="text"
                  />
              )}

              <FormControlLabel
                  value="Less Than"
                  control={<Radio sx={{
                    '&.Mui-checked': {
                      color: "#FF9933",
                    },
                  }}/>}
                  onClick={() => {
                    setGreaterField("");
                    setEqualField("");
                    change("value", null);
                    change("operation", "LT");
                  }}
                  label="Less Than (Days Ago)"
              />

              {value === "Less Than" && (
                  <input
                      onChange={(event) => {
                        handleOnChange("LT", event);
                      }}
                      style={{
                        height: '30px',
                        borderRadius: '28px',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        outlineColor: "#FF9933",
                        textAlign: 'center',
                        fontSize: '16px'
                      }}
                      value={lessField}
                      type="text"
                  />
              )}

              <FormControlLabel
                  value="Was Exactly"
                  control={<Radio sx={{
                    '&.Mui-checked': {
                      color: "#FF9933",
                    },
                  }}/>}
                  onClick={() => {
                    setGreaterField("");
                    setLessField("");
                    change("value", null);
                    change("operation", "EQUALS");
                  }}
                  label="Was Exactly (Days Ago)"
              />
              {value === "Was Exactly" && (
                  <input
                      onChange={(event) => {
                        handleOnChange("EQUALS", event);
                      }}
                      style={{
                        height: '30px',
                        borderRadius: '28px',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        outlineColor: "#FF9933",
                        textAlign: 'center',
                        fontSize: '16px'
                      }}
                      value={equalField}
                      type="text"
                  />
              )}
              <br/>
              <Divider sx={{ width: '100%' }}/>
            </RadioGroup>
          </FormControl>
          <Button
              onClick={handleSubmit((values) => onClick(values))}
              sx={{
                color: "#FF9933",
                fontSize: 16,
                fontWeight: "500",
                marginLeft: '10ch'
              }}
              variant="text"
              disabled={isButtonDisabled}
          >
            DONE
          </Button>
        </Menu>
      </div>
  );
};

export default FirstVisit;
