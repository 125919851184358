import React from "react";
import styled from "@emotion/styled";

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #ea5454;
  border-radius: 45px;
  min-width: 50%;
  max-width: 90%;
  padding: 5px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transform: ${(props) => (props.disabled ? "scale(.98)" : "scale(1)")};
  transition: all 0.75s;

  label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
  }
`;

const Button = (props) => {
  const { title, disabled, onClick, icon: Icon } = props;

  return (
    <ButtonWrapper disabled={disabled} onClick={onClick}>
      <label>{title}</label>
      {Icon && Icon}
    </ButtonWrapper>
  );
};

export default Button;
