import styled from "@emotion/styled";

const LoginScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 20px;

  .main-content {
    height: 75%;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    overflow-wrap: break-word;
    border-radius: 10px;
    background: rgba(255, 255, 255,
      ${props => props.opacity ? props.opacity : 1});
  }

  .phone-preview-content-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    border-radius: 10px;

    label {
      font-family: Futura PT Book;
    }

    .phone-preview-text {
      margin-bottom: 20px;
    }

    .small-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;
      margin-bottom: 15px;
      min-height: 105px;
      min-width: 220px;
      border-radius: 10px;
      background: rgba(255, 255, 255,
        ${props => props.opacity ? props.opacity : 1});


      .phone-preview-logo {
        max-height: 150px;
        max-width: 150px;
        object-fit: contain;
      }

      .logo-placeholder {
        display: flex;
        margin-top: 24px;
        height: 110px;
        width: 220px;
        background-color: white;
      }

      .phone-preview-social {
        display: flex;
        flex-direction: row;

        margin-top: 20px;

        .social-preview {
          margin-top: 10px;
        }
      }
    }

    .phone-preview-register-by-email-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .phone-preview-register-by-email {
        display: flex;
        flex-direction: row;
        background-color: white;
        align-items: center;
        opacity: 0.9;

        input {
          font-size: 18px;
          outline: 0;
          border: none;
          padding: 5px;
          max-width: 250px;
        }

        .phone-preview-go-btn {
          padding: 10px;
          border: none;
          color: white;
        }
      }
    }
  }

  .powered-by {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: 15px;
    font-size: 8px;

    .powered-by-unaro-icon {
      display: flex;
      flex-direction: column;
      height: 10px;
    }
  }

  .content-divider {
    border: 0;
    clear: both;
    display: block;
    width: 90%;
    background-color: $grey-2;
    height: 1px;
  }

  .secondary-social-media-buttons {
    display: flex;
    justify-content: center;
    object-fit: fill;
  }

  .secondary-social-media-button {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 10px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      object-fit: contain;
      height: 35px;
      width: 35px;
    }

    &__twitter {
    @extend . secondary-social-media-button;
      background-color: #1da1f2;
    }

    &__linkedin {
    @extend . secondary-social-media-button;
      background-color: #0e76a8;
    }

    &__mail {
    @extend . secondary-social-media-button;
      background-color: $unaro-red;
    }

    &__facebook {
    @extend . secondary-social-media-button;
      background-color: #3b5998;
    }
  }

  .privacy-policy {
    text-align: center;
    font-size: 12px;
    color: blue;
    margin: auto;
  }
`;

export default LoginScreenWrapper;

// @import "../../colors.scss";

// .should-animate-in-true {
//   animation: fade-in 1.2s;
// }

// @keyframes fade-in {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
