import React from "react";
import styled from "@emotion/styled";
import TableBody from "./TableBody";

import NoContactsMessage from "./NoContactsMessage";

const TableOuterWrapper = styled.div`
  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #f0c33c;
  }
`;

const TableWrapper = styled.table`
  border-spacing: 0px;
  width: 95%;
  max-width: 95%;
  border-collapse: collapse;
  margin: 46px auto 0 auto;
  /* max-height:20px; */
  overflow: hidden;
`;

const Table = (props) => {
  const { contactsList, isContactsListEmpty } = props;

  return (
    <TableOuterWrapper>
      {!isContactsListEmpty ? (
        <TableWrapper>
          {/* <TableHeader {...props} className="customer-list-table-header" /> (deprecated) UNARO-66 */}
          <TableBody {...props} className="customer-list-table-body" />
        </TableWrapper>
      ) : (
        <NoContactsMessage />
      )}
    </TableOuterWrapper>
  );
};

export default Table;
