import React from "react";
import styled from "@emotion/styled";
import Lottie from "react-lottie";

import animData from "./animData.json";

// import LoadingAnimGif from "assets/logo-anim.gif";

const LoadingAnimWrapper = styled.div`
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(115, 128, 148, 0.3);
`;

const LoadingAnimImage = styled.img`
  height: 500px;
  width: 300px;
`;

// const LoadingAnim = (props) => {
//   //   const [canShow, setCanShow] = React.useState(true);

//   //   React.useEffect(() => {
//   //     setTimeout(() => {
//   //         setCanShow(false)
//   //     }, 5000);

//   //     setCanShow(true);
//   //   }, []);

//   return (
//     <LoadingAnimWrapper>
//       <LoadingAnimImage
//         src={LoadingAnimGif}
//         alt="loading..."
//       ></LoadingAnimImage>
//     </LoadingAnimWrapper>
//   );
// };

const LoadingAnim = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <LoadingAnimWrapper>
      <Lottie
        options={defaultOptions}
        height={150}
        width={150}
        isStopped={false}
        isPaused={false}
      />
    </LoadingAnimWrapper>
  );
};

export default LoadingAnim;
