import React from 'react'

import '../../assets/css/widget/filter.scss'

export default ({ filter, Icon, changeSelected }) => {

    return (
        <div className={`filter ${filter.selected && 'filter-selected'}`} onClick={() => changeSelected(filter.id)}>
            <Icon className='icon' />
            <label>{filter.name}</label>
        </div>
    )
}