import api from "../../utils/api";
import type {ImageMetadata, ImageResponse} from "./types";

export function uploadMediaImage(file: File, metadata: ImageMetadata)
    : Promise<ImageResponse> {
  return new Promise((resolve, reject) => {
    const formData: FormData = new FormData();
    formData.set('file', file, file.name);
    formData.set('metadata', JSON.stringify(metadata));
    api.post('/upload/image', formData, {timeout: 1000 * 60 * 10})
    .then((response: any) => {
      const imageResponse: ImageResponse = response.data;
      resolve(imageResponse);
    })
    .catch((error: any) => {
      console.error('Upload image failed, error:');
      console.error(error);
      reject(error);
    });
  });
}
