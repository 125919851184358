/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull browser-js-ampli'
 *
 * Required dependencies: amplitude-js
 * Tracking Plan Version: 0
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli
 *
 * [View Tracking Plan](https://data.amplitude.com/unaro/Unaro/implementation/main/latest/tracking-event/web/)
 *
 * [Full Setup Instructions](https://data.amplitude.com/unaro/Unaro/implementation/main/latest/getting-started/web/)
 */

import amplitude from "amplitude-js";
import {
  UNARO_LOGIN_TRACKING_PLAN,
  UNARO_PAGE_VISIT_TRACKING_PLAN,
} from "./consts";

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 */

/**
 * Data to be processed by middleware
 * @typedef MiddlewarePayload
 * @type {object}
 * @property {BaseEvent} event
 * @property {MiddlewareExtra} [extra]
 */

/**
 * Function called at the end of each Middleware to run the next middleware in the chain
 * @typedef MiddlewareNext
 * @type {function}
 * @param {MiddlewarePayload} payload
 *
 * @return
 */

/**
 * A function to run on the Event stream (each logEvent call)
 * @typedef Middleware
 * @type {function}
 * @param {MiddlewarePayload} payload The event and extra data being sent
 * @param {MiddlewareNext} next Function to run the next middleware in the chain, not calling next will end the middleware chain
 * @return
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [options]
 * @property {AmplitudeClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'development'|'production'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef {Object} IdentifyOptions
 * @type {object}
 */

/**
 * @typedef {Object} GroupOptions
 * @type {object}
 */

/**
 * @typedef {Object} MiddlewareExtra
 * @type {Object.<string, *>}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} development
 * @property {string} production
 */
export const ApiKey = {
  development: "",
  production: "",
};

/**
 * @typedef {Object} EventTemplate
 * @param {number} [optional_template_property] optional_template_property description
 * @param {string} required_template_property required_template_property description
 */

/**
 * @typedef {Object} SourceTemplate
 * @param {'Value 1'|'Value 2'} [optionalEnum] description for context optionalEnum
 * @param {string} requiredString description for context requiredString
 */

export const SpecialEventType = {
  Identify: "Identify",
  Group: "Group",
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultOptions = {
  plan: {
    version: "0",
    branch: "main",
    source: "unaro-web",
  },
};

export class Identify {
  constructor(properties) {
    this.event_type = "Identify";
    this.event_properties = properties;
  }
}

export class Group {
  constructor(properties) {
    this.event_type = "Group";
    this.event_properties = properties;
  }
}

export class EventMaxIntForTest {
  constructor(properties) {
    this.event_type = "EventMaxIntForTest";
    this.event_properties = properties;
  }
}

export class UnaroLoginEventWithProperties {
  constructor(properties) {
    const {eventType} = properties;
    this.event_type = eventType;
    this.event_properties = {
      ...properties,
    };
  }
}

export class UnaroPageVisitEventWithProperties {
  constructor(properties) {
    const {eventType} = properties;
    this.event_type = eventType;
    this.event_properties = {
      ...properties,
    };
  }
}

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {AmplitudeClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
    /* @type {Middleware[]} */
    this.middlewares = [];
  }

  /**
   * @return {AmplitudeClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.amplitude) {
      throw new Error(
          'Ampli is not yet initialized. Have you called ampli.load() on app start?');
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   * @param {LoadOptions} [options] Configuration options to initialize the Ampli SDK with.
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.amplitude) {
      console.log("errorMessage");
      return;
    }

    const apiKey = `${process.env.REACT_APP_AMPLITUDE_API_KEY}`

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.getInstance();
      this.amplitude?.init(apiKey, undefined,
          {...DefaultOptions, ...options?.client?.options});
    } else {
      throw new Error(
          "ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} properties The user's properties.
   * @param {string[]} [properties.optionalArray] Description for identify optionalArray
   * @param {number} properties.requiredNumber Description for identify requiredNumber
   * @param {IdentifyOptions} [options] Optional event options.
   * @param {MiddlewareExtra} [extra] Extra unstructured data for middleware.
   */
  identify(userId, properties, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event = {
      event_type: SpecialEventType.Identify,
      event_properties: properties,
      user_id: userId || options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({event, extra}, payload => {
      const e = payload.event;
      if (e.user_id) {
        this.amplitude.setUserId(e.user_id);
      }
      if (e.device_id) {
        this.amplitude.setDeviceId(e.device_id);
      }
      const ampIdentify = new amplitude.Identify();
      if (e.event_properties != null) {
        for (const [key, value] of Object.entries(e.event_properties)) {
          ampIdentify.set(key, value);
        }
      }
      this.amplitude.identify(
          ampIdentify,
          options?.callback,
          options?.errorCallback
      );
    });
  }

  /**
   * Set Group for the current user
   *
   * @param {String} name
   * @param {String|String[]} value
   * @param {GroupOptions} [options]
   * @param {MiddlewareExtra} [extra]
   */
  setGroup(name, value, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.amplitude?.setGroup(name, value);
  }

  /**
   * Identify a group and set or update that group's properties.
   *
   * @param {string} groupType The group type.
   * @param {string|string[]} groupName The group name.
   * @param {Object} properties The group's properties.
   * @param {string} [properties.optionalString] Description for group optionalString
   * @param {boolean} properties.requiredBoolean Description for group requiredBoolean
   * @param {GroupOptions} [options] Options for this groupIdentify call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  groupIdentify(groupType, groupName, properties, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event = {
      event_type: SpecialEventType.Group,
      event_properties: properties,
      user_id: options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({event, extra}, payload => {
      const e = payload.event;
      if (e.user_id) {
        this.amplitude.setUserId(e.user_id);
      }
      if (e.device_id) {
        this.amplitude.setDeviceId(e.device_id);
      }
      const amplitudeIdentify = new amplitude.Identify();
      if (e.event_properties != null) {
        for (const [key, value] of Object.entries(e.event_properties)) {
          amplitudeIdentify.set(key, value);
        }
      }
      this.amplitude.groupIdentify(groupType, groupName, amplitudeIdentify,
          options?.callback);
    });
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   * @param {MiddlewareExtra} [extra] Extra unstructured data for middleware.
   */
  track(event, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.runMiddleware({event, extra}, payload => {
      this.amplitude.logEvent(
          payload.event.event_type,
          payload.event.event_properties,
          options?.callback,
          options?.errorCallback,
      );
    });
  }

  /**
   * EventMaxIntForTest
   *
   * [View Tracking Plan](https://data.amplitude.com/unaro/Unaro/implementation/main/latest/tracking-event/web/)
   *
   * Event to test schema validation
   *
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.intMax10 property to test schema validation
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  eventMaxIntForTest(properties, options, extra) {
    this.track(new EventMaxIntForTest(properties), options, extra);
  }

  /**
   * Unaro Login Event With Properties
   *
   * [View Tracking Plan](https://data.amplitude.com/unaro/Unaro/implementation/main/latest/tracking-event/web/)
   *
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.optionalString] Optional String
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  unaroLoginEventWithProperties(properties, options, extra) {
    this.track(new UnaroLoginEventWithProperties(properties), options, extra);
  }

  /**
   * Unaro Page Visit Event With Properties
   *
   * [View Tracking Plan](https://data.amplitude.com/unaro/Unaro/implementation/main/latest/tracking-event/web/)
   *
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.optionalString] Optional String
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  unaroPageVisitEventWithProperties(properties, options, extra) {
    this.track(new UnaroPageVisitEventWithProperties(properties), options,
        extra);
  }

  unaroPageVisit(eventType) {
    //console.log(`unaroPageVisit: ${eventType}`);
    const user = JSON.parse(localStorage.getItem("user"));
    const orgName = user?.organisations.length > 0
        ? user.organisations[0].name
        : "";
    const properties = {
      emailAddress: user?.email,
      organisationName: orgName,
      eventType: eventType
    };
    this.track(new UnaroPageVisitEventWithProperties(properties), {}, {});
  }

  /**
   * Add new middleware to end of chain
   *
   * @param {Middleware} middleware
   */
  addEventMiddleware(middleware) {
    this.middlewares.push(middleware);
  }

  /**
   * Runs all middleware
   *
   * @param {MiddlewarePayload} payload
   * @param {MiddlewareNext} next The method to run after all middleware.
   *
   * @protected
   */
  runMiddleware(payload, next) {
    let curMiddlewareIndex = -1;
    const middlewareCount = this.middlewares.length;

    const middlewareNext = curPayload => {
      curMiddlewareIndex += 1;
      if (curMiddlewareIndex < middlewareCount) {
        this.middlewares[curMiddlewareIndex](curPayload, _next);
      } else {
        next(curPayload);
      }
    };

    const _next = middlewareCount > 0 ? middlewareNext : next;

    _next(payload);
  }
}

export const ampli = new Ampli();
