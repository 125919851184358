import React from "react";

import styled from "@emotion/styled";

import SegmentsPanelItem from "./SegmentsPanelItem";
import uuid from "react-uuid";

const SegmentsNamesListWrapper = styled.div`
  flex: 1;
  background: #ffffff;
  height: 45%;
  width: 100%;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.isThisPanelSelected ? 0.95 : 0.5)};

  transform: ${(props) =>
          props.isThisPanelSelected ? "scale(1)" : "scale(.98)"};
  transition: all 0.75s;
`;

const SegmentsListTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  position: relative;
  top: 0;
  // z-index: 99999;

  label {
    font-family: Trebuchet MS, sans-serif !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    color: #FF9933 !important;
    line-height: 133.4% ;
  }
`;

const SegmentsNamesListBodyWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #ea5454;
  }
`;

const SegmentsNamesList = (props) => {
  const {allSegments, currentListType, listTypes} = props;

  const [noSegments, setNoSegments] = React.useState(true);

  const [isHover, setIsHover] = React.useState(false);

  const [isThisPanelSelected, setIsThisPanelSelected] = React.useState(false);

  React.useEffect(() => {
    (currentListType && currentListType === listTypes.ALL) ||
    currentListType === listTypes.SEGMENTS
        ? setIsThisPanelSelected(true)
        : setIsThisPanelSelected(false);
  }, [currentListType]);

  React.useEffect(() => {
    allSegments ? setNoSegments(false) : setNoSegments(true);
  }, [allSegments]);

  const allSegmentsPanel = (
      <SegmentsPanelItem
          key={"segment-all-contacts"}
          iconName={"1F601"}
          id={"segment-all-contacts"}
          isAllContactsSegmentPanel={true}
          {...props}
      />
  );

  return (
      // <SegmentsNamesListWrapper
      <div
          isThisPanelSelected={isThisPanelSelected}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          isHover={isHover}
      >
        <SegmentsListTitle>
          <label>Segments</label>
        </SegmentsListTitle>

        <SegmentsNamesListBodyWrapper>
          {allSegments && Object.values(allSegments).length > 0 ? (
                  Object.values(allSegments).map((segment, index) => {
                    const {iconName, id, name} = segment;
                    return (
                        <div key={uuid()}>
                          {index === 0 && allSegmentsPanel}
                          <SegmentsPanelItem
                              key={uuid()}
                              id={id}
                              name={name}
                              iconName={iconName}
                              {...props}
                          />
                        </div>
                    );
                  })
              )
              : allSegmentsPanel
          }
        </SegmentsNamesListBodyWrapper>
      </div>
  );
};

export default SegmentsNamesList;
