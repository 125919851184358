import React, {useContext, useEffect, useState} from "react";
import {VenuesContext} from "context/venueContext";
import {PageNavigationContext} from "context/navigationContext";
import PageNavigator from "components/PageNavigator";
import api from "utils/api";
import {
  ReactComponent as AllCusotmersIcon
} from "assets/images/all-customers.svg";
import {
  ReactComponent as LoyalCusotmersIcon
} from "assets/images/loyal-customers.svg";
import {
  ReactComponent as LostCusotmersIcon
} from "assets/images/lost-customers.svg";
import {
  ReactComponent as UpcomingBirthdaysIcon
} from "assets/images/upcoming-birthdays.svg";
import {ReactComponent as EmailsIcon} from "assets/images/emails.svg";
import {ReactComponent as SearchIcon} from "assets/images/search-icon.svg";
import SimpleMenu from "./modal/ModalMenu";
import ModalMenuAddFilter from "./modal/ModalMenuAddFilter";
import SaveSegment from "./modal/saveSegment";
import {Modal} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {DataGrid, GridColumns, GridRowsProp} from "@mui/x-data-grid";
import "../customers/customers.css";
import "assets/css/customers/customers-page.scss";

const columns: GridColumns = [
  {field: "name", headerName: "First Name", width: 175, editable: true},
  {field: "age", headerName: "Last Name", width: 175, editable: true},
  {
    field: "dateCreated",
    headerName: "Number",
    width: 175,
    editable: true,
  },
  {
    field: "lastLogin",
    headerName: "Email",
    width: 155,
    editable: true,
  },
];

const rows: GridRowsProp = [
];

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
}));

const options = [
  {
    label: "Single contact",
    value: "Single contact",
  },
  {
    label: "Import list",
    value: "Import list",
  },
];

const optionCustomerList = [
  {
    label: "Selected Contacts",
    value: "Selected Contacts",
  },
  {
    label: "Audience",
    value: "Audience",
  },
  {
    label: "Customer List",
    value: "Customer List",
  },
];

const selectCustomerList = [
  {
    label: "All Customers",
    value: "All Customers",
  },
  {
    label: "Loyal Customers",
    value: "Loyal Customers",
  },
  {
    label: "Lost Customers",
    value: "Lost Customers",
  },
  {
    label: "Upcoming Birthday",
    value: "Upcoming Birthday",
  },
  {
    label: "Emails",
    value: "Emails",
  },
];

export default ({setSelectedCustomers}) => {
  // ________________________pop up_____________________________
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [openSegment, setOpenSegment] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenSegment = () => {
    setOpenSegment(true);
  };
  const popUp3 = () => {
    setOpenSegment(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenSegment(false);
  };

  const saveSegment = (
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">Save Segment</h2>
        <p className={"saveSegmentLiB"}>
          <label>
            <input
                type="radio"
                name="choice"
                value="Overwrite Existing Segment 'Segment Name'"
            />{" "}
            Overwrite Existing Segment 'Segment Name'
          </label>
        </p>
        <p className={"saveSegmentLiB"}>
          <label>
            <input type="radio" name="choice" value="Add a New Segment"/> Add a
            New Segment
          </label>
        </p>
        <div className={"borderSaveSegment"}></div>
        <div className={"buttons"}>
          <button className={"yesDelete"}>Save</button>
        </div>
      </div>
  );

  const DeleteSegment = (
      <div style={modalStyle} className={classes.paper}>
        <img alt="minus" src={require("assets/images/minus.png").default}/>
        <h2 id="simple-modal-title">Are You Sure?</h2>
        <p className={"saveSegmentLi"}>
          <a>Delete Audience.</a> This will only delete the audience
        </p>
        <p className={"saveSegmentLi"}>
          tag not the contacts. This cannot be undone
        </p>

        <div className={"buttons"}>
          <button className={"yesDelete"}>Yes, Delete</button>
          <button className={"notDelete"}>Cancel</button>
        </div>
      </div>
  );

  const OpenList = (
      <div style={modalStyle} className={classes.paper}>
        <div className={"importList"}>Import list</div>
        <div className={"line"}></div>
        <div className={"importTitle"}>Import List</div>
        <div className={"dragDrop"}>
          <img alt="frame" src={require("assets/images/Frame.png").default}/>
          <p className={"titleDrag"}>Drag & Drop</p>
          <button>Choose File</button>
          <p>
            Every number needs to include a country code for a successful import
            e.g +44
          </p>
          <p>(File types, .XLS, .XLSX, .CSV, .TXT)</p>
        </div>

        <div className={"audence"}>
          <div className={"audenceLeft"}>
            <p className={"audenceRightTitle"}>Add to Audience list</p>
            <img alt="group" src={require("assets/images/Group 11426.png").default}/>
          </div>

          <div className={"audenceLine"}></div>
          <div className={"audenceRight"}>
            <div className={"bl"}>
              <p className={"audenceRightTitle"}>
                Auto-update your existing contacts
              </p>
              <p className={"audenceRightDescription"}>
                If a duplicate contact e.g. Phone Number / Email Address is
                found
                it will be updated with the new details from this list. If this
                is
                left unchecked it will create a new contact.
              </p>
            </div>

            <div className={"bl"}>
              <p className={"audenceRightTitle"}>
                Auto-update your existing contacts
              </p>
              <p className={"audenceRightDescription"}>
                If a duplicate contact e.g. Phone Number / Email Address is
                found
                it will be updated with the new details from this list. If this
                is
                left unchecked it will create a new contact.
              </p>
            </div>
          </div>
        </div>

        <button className={"nextAudence"}>Next</button>
      </div>
  );

  const successfulImport = (
      <div style={modalStyle} className={classes.paper}>
        <div className={"successfulImport"}>
          <img alt="component" src={require("assets/images/Component6.png").default}/>
          <h2 id="simple-modal-title">Your Import Was Successful</h2>
          <p>We've updated 6 contacts in Audience 3</p>
        </div>
      </div>
  );

  const importFailed = (
      <div style={modalStyle} className={classes.paper}>
        <div className={"successfulImport"}>
          <img
              alt="import failed"
              className={"imgFailed"}
              src={require("assets/images/importFailed.png")}
          />
          <h2 id="simple-modal-title">Import Failed</h2>
          <p>
            Import not successful, <a>error 321937</a>
          </p>
          <button className={"tryAgain"}>Try Again</button>
        </div>
      </div>
  );

  const importList = (
      <div style={modalStyle} className={classes.paper}>
        <img alt="minus" src={require("assets/images/minus.png").default}/>
        <h2 id="simple-modal-title">Are You Sure?</h2>
        <p className={"saveSegmentLi"}>
          <a>Delete Audience.</a> This will only delete the audience
        </p>
        <p className={"saveSegmentLi"}>
          tag not the contacts. This cannot be undone
        </p>

        <div className={"buttons"}>
          <button className={"yesDelete"}>Yes, Delete</button>
          <button className={"notDelete"}>Cancel</button>
        </div>
      </div>
  );

  const importList2 = (
      <div style={modalStyle} className={classes.paper}>
        <div className={"importList2"}>
          <div className={"importList"}>Import list</div>
          <div className={"line"}></div>
          <div className={"importTitle"}>Import List</div>
          <div className={"dragDrop"}>
            <img src={require("assets/images/Group11428.png").default}/>
            <p className={"titleDrag"}>File Name/ Preview</p>
            <button>Choose Different File</button>
          </div>

          <div className={"audence"}>
            <div className={"audenceLeft"}>
              <p className={"audenceRightTitle"}>Add to Audience list</p>
              <img src={require("assets/images/Group 11426.png").default}/>
            </div>

            <div className={"audenceLine"}></div>
            <div className={"audenceRight"}>
              <div className={"bl"}>
                <p className={"audenceRightTitle"}>
                  Auto-update your existing contacts
                </p>
                <p className={"audenceRightDescription"}>
                  If a duplicate contact e.g. Phone Number / Email Address is
                  found it will be updated with the new details from this list.
                  If
                  this is left unchecked it will create a new contact.
                </p>
              </div>

              <div className={"bl"}>
                <p className={"audenceRightTitle"}>
                  Mark these contacts as subscribed?
                </p>
                <p className={"audenceRightDescription"}>
                  When you choose the "Subscribed" status for your contacts, it
                  indicates that you've gained permission to market to them.
                </p>
              </div>
            </div>
          </div>

          <button className={"nextAudence"}>Next</button>
        </div>
      </div>
  );

  const customersDeleted = (
      <div style={modalStyle} className={classes.paper}>
        <div className={"successfulImport"}>
          <img src={require("assets/images/Component6.png").default}/>
          <h2 id="simple-modal-title">34 Customers Deleted</h2>
        </div>
      </div>
  );

  const audenceDeleted = (
      <div style={modalStyle} className={classes.paper}>
        <div className={"successfulImport"}>
          <img src={require("assets/images/Component6.png").default}/>
          <h2 id="simple-modal-title">Audience 1 Deleted</h2>
        </div>
      </div>
  );

  const createAudience = (
      <div style={modalStyle} className={classes.paper}>
        <div className={"successfulImport"}>
          <h2 id="simple-modal-title">Create Audience</h2>
          <input
              className={"audienceName"}
              type={"text"}
              placeholder={"Audience Name"}
          />
          <button className={"tryAgain"}>Try Again</button>
        </div>
      </div>
  );

  const createContact = (
      <div style={modalStyle} className={classes.paper}>
        <div className={"createContact"}>
          <div className="select-contact">
            <select>
              {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>

          <h2 id="simple-modal-title">Create Contact</h2>
          <input type="text" placeholder="Name"/>
          <input type="text" placeholder="Surname"/>
          <input type="text" placeholder="Select Country"/>
          <input type="text" placeholder="Number"/>

          <input
              className={"emailWidth"}
              type="text"
              placeholder="Email address"
          />

          <div className={"assignAudience"}>
            <span className={"spbl1"}>Assign Audience(s):</span>
            <span className={"spbl2"}>
            <div className={"asd c1"}></div>
            <div className={"asd c2"}></div>
            <div className={"asd c3"}></div>
            <div className={"asd c4"}></div>
            <div className={"asd c5"}></div>
            <div className={"asd c6"}></div>
            <div className={"asd c7"}></div>
            <div className={"asd c8"}></div>
          </span>
          </div>

          <button className={"saveB"}>Save</button>
        </div>
      </div>
  );

  const exportContact = (
      <div style={modalStyle} className={classes.paper}>
        <div className={"createContact"}>
          <div className="select-contact">
            <select>
              {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <h2 id="simple-modal-title">Export Contacts</h2>
          <p>Export 1234 Selected Contacts as CSV file</p>
          <div className={"exportButton"}>
            <div className={"exportCSV"}>Export CSV</div>
            <div className={"cancelCSV"}>Cancel</div>
          </div>
        </div>
      </div>
  );

  const customerList = (
      <div style={modalStyle} className={classes.paper}>
        <div className={"createContact"}>
          <div className="select-contact">
            <select>
              {optionCustomerList.map((option) => (
                  <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <h2 id="simple-modal-title">Import Customer List</h2>
          <select className={"blockSelectCustomerList"}>
            {selectCustomerList.map((option) => (
                <option value={option.value}>{option.label}</option>
            ))}
          </select>
          <p className={"exportLoyalText"}>
            Export Loyal Customers (538 contacts) as CSV file.
          </p>
          <div className={"exportButton"}>
            <div className={"exportCSV"}>Export CSV</div>
            <div className={"cancelCSV"}>Cancel</div>
          </div>
        </div>
      </div>
  );

  const editList = (
      <div className={"editList"}>
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Edit List</h2>
          <div className={"borderEdit ad"}>
            <div className={"blockEdit fb"}>
              <img
                  className={"imgEditBorder"}
                  src={require("assets/images/Component1.png")}
              />
              <span>First Name</span>
            </div>
            <div className={"blockEdit"}>
              <img
                  className={"imgEditBorder"}
                  src={require("assets/images/Component1.png")}
              />
              <span>Last Name</span>
            </div>
            <div className={"blockEdit"}>
              <img
                  className={"imgEditBorder"}
                  src={require("assets/images/Component1.png")}
              />
              <span>Number</span>
            </div>
            <div className={"blockEdit lb"}>
              <img
                  className={"imgEditBorder"}
                  src={require("assets/images/Component1.png")}
              />
              <span>Email</span>
            </div>
            <div style={{height: "400px", width: "100%"}}>
              <DataGrid rows={rows} columns={columns}/>
            </div>
          </div>

          <div className={"congrRecogn"}>7 Contacts recognised</div>
          <div className={"columnImport"}>
            3 columns will be imported. <a>1 column will not be imported</a>
          </div>

          <div className={"exportButton"}>
            <div className={"exportCSV"}>Back</div>
            <div className={"cancelCSV"}>Finalise Import</div>
          </div>
        </div>
      </div>
  );

  const {selected} = useContext(VenuesContext);

  const [selectors, setSelectors] = useState([
    {
      id: 0,
      name: "All Customers",
      Icon: AllCusotmersIcon,
      value: 220,
      selected: true,
    },
    {
      id: 1,
      name: "Loyal Customers",
      Icon: LoyalCusotmersIcon,
      value: 220,
      selected: false,
    },
    {
      id: 2,
      name: "Lost Customers",
      Icon: LostCusotmersIcon,
      value: 220,
      selected: false,
    },
    {
      id: 3,
      name: "Upcoming Birthdays",
      Icon: UpcomingBirthdaysIcon,
      value: 220,
      selected: false,
    },
    {
      id: 4,
      name: "Emails",
      Icon: EmailsIcon,
      value: 220,
      selected: false,
    },
  ]);

  const audiences = [
    {
      id: 0,
      name: "Audience 1",
      contacts: 220,
      color: "#FFCC33",
    },
    {
      id: 1,
      name: "Audience 2",
      contacts: 220,
      color: "#EA5459",
    },
    {
      id: 2,
      name: "Audience 3",
      contacts: 220,
      color: "#EA5454",
    },
    {
      id: 3,
      name: "Audience 4",
      contacts: 220,
      color: "#FFCC33",
    },
    {
      id: 4,
      name: "Audience 5",
      contacts: 220,
      color: "#EA5459",
    },
    {
      id: 5,
      name: "Audience 6",
      contacts: 220,
      color: "#FFCC33",
    },
    {
      id: 6,
      name: "Audience 7",
      contacts: 220,
      color: "#EA5459",
    },
  ];

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [name, setName] = useState("");
  const [navigation, setNavigation] = useState({total: 1, current: 0});
  const [allSelected, setAllSelected] = useState(false);

  const fetctCustomers = async (page) => {
    var url = `/organisations/${selected.externalId}/contacts?size=20&page=${page}&name=${name}&type=`;
    const result = await api.get(url);
    setContacts(result.data.content);
    setNavigation({
      total: Math.ceil(result.data.total / 20),
      current: result.data.pageable.page,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selected) {
        fetctCustomers(0);
      }
    };
    fetchData();
  }, [selected, name]);

  useEffect(() => {
    setSelectedCustomers(selectedContacts);
  }, [selectedContacts]);

  useEffect(() => {
    if (allSelected) {
      setSelectedContacts(contacts);
    } else {
      setSelectedContacts([]);
    }
  }, [allSelected]);

  return (
      <div className="customers-page">
        <div className="customers-left">
          <div className="cl-customers">
            <div className="cp-top">
              <label>Customers</label>
            </div>
            <div className="cp-content">
              {selectors.map((selector) => (
                  <div
                      key={selector.id}
                      className={`cl-selector ${selector.selected
                      && "cl-selected"}`}
                      onClick={() =>
                          setSelectors(
                              selectors.map((s) => ({
                                ...s,
                                selected: s.id === selector.id,
                              }))
                          )
                      }
                  >
                    <selector.Icon className="cls-icon"/>
                    <div className="cls-info">
                      <label>{selector.name}</label>
                      <label>{selector.value} contacts</label>
                    </div>
                  </div>
              ))}
            </div>
          </div>
          <div className="cl-audiences">
            <div className="cp-top">
              <label>Audiences</label>
            </div>
            <div className="cla-content">
              {audiences.map((audience) => (
                  <div className="cl-audience" key={audience.id}>
                    <div
                        className="audience-color"
                        style={{backgroundColor: audience.color}}
                    />
                    <div className="audience-info">
                      <label>{audience.name}</label>
                      <label>{audience.contacts} contacts</label>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>
        <div className="customers-right">
          <div className="cp-top">
            <label>{selectors.find((s) => s.selected).name}</label>
            <div className="navigation">
              <PageNavigationContext.Provider
                  value={{navigation, setNavigation}}
              >
                <PageNavigator changePage={(page) => fetctCustomers(page)}/>
              </PageNavigationContext.Provider>
            </div>
            <div className="cp-right">
              <div className="cp-search">
                <input
                    placeholder="Search contact"
                    value={name || ""}
                    onChange={(e) => setName(e.target.value)}
                />
                <SearchIcon className="search-icon"/>
              </div>

              <SimpleMenu/>
            </div>
          </div>
          <div className="cp-actions">
            <div className="cp-action">
              <img src={require("assets/images/add-filter.svg").default}/>
              <label>
                <ModalMenuAddFilter/>
              </label>
            </div>

            <div className="cp-action">
              <img src={require("assets/images/save-segment.svg").default}/>
              <SaveSegment/>
              <Modal
                  open={openSegment}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
              >
                {/*{editList}*/}
                {importList2}
                {/*{customerList}*/}
                {/*{exportContact}*/}
                {/*{createContact}*/}
                {/*{customersDeleted}*/}
                {/*{createAudience}*/}
                {/*{importFailed}*/}
                {/*{successfulImport}*/}
                {/*{saveSegment}*/}
                {/*{OpenList}*/}
              </Modal>
            </div>

            <div className="cp-action">
              <img src={require("assets/images/delete-segment.svg").default}/>

              <label onClick={handleOpen}>Delete Segment</label>

              <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
              >
                {audenceDeleted}
              </Modal>
            </div>
          </div>
          <div className="cp-header">
            <div
                className="cp-selector"
                onClick={() => setAllSelected(!allSelected)}
            >
              {allSelected && <div className="cps-selected"/>}
            </div>
            <label>Contact</label>
            <label>Phone Number</label>
            <label>Email</label>
            <label>Audience</label>
          </div>
          <div className="cp-contacts">
            {contacts.map((contact) => (
                <div
                    className="cp-contact"
                    key={contact.externalId}
                    onClick={() =>
                        setSelectedContacts(
                            selectedContacts.find(
                                (sc) => sc.externalId === contact.externalId
                            )
                                ? selectedContacts.filter(
                                    (sc) => sc.externalId !== contact.externalId
                                )
                                : [...selectedContacts, contact]
                        )
                    }
                >
                  <div className="cp-selector">
                    {selectedContacts.find(
                        (sc) => sc.externalId === contact.externalId
                    ) && <div className="cps-selected"/>}
                  </div>
                  <label className="cp-name">
                    {contact.forename} {contact.surname}
                  </label>
                  <label className="cp-info">
                    {contact.phones &&
                        contact.phones.length > 0 &&
                        contact.phones[0].phoneNumber}
                  </label>
                  <label className="cp-info">{contact.email}</label>
                  <label></label>
                </div>
            ))}
          </div>
        </div>
        {/*<EditList/>*/}
      </div>
  );
};
