import React, { useState } from 'react'

import { ReactComponent as CloseIcon } from '../../assets/images/delete.svg';
import { ReactComponent as DropdownLogo } from '../../assets/images/dropdown-icon.svg';

import api from '../../utils/api'

import '../../assets/css/venue/users-popup.scss'

export default ({ organisationId, users, setUsers, close, setShowPopup }) => {

    const [editUser, setEditUser] = useState()
    const [errors, setErrors] = useState([])
    const [errorMessage, setErrorMessage] = useState()

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    const changeEmail = value => {
        setErrors(errors.filter(error => error !== 'email'))
        setErrorMessage(null)
        setEditUser({ ...editUser, email: value })
    }

    const changeForename = value => {
        setErrors(errors.filter(error => error !== 'forename'))
        setErrorMessage(null)
        setEditUser({ ...editUser, forename: value })
    }

    const changeSurname = value => {
        setErrors(errors.filter(error => error !== 'surname'))
        setErrorMessage(null)
        setEditUser({ ...editUser, surname: value })
    }

    const save = async () => {
        var e = []
        if (!editUser.email || editUser.email.length < 3)
            e = [...e, 'email']
        if (!editUser.forename || editUser.forename.length < 3)
            e = [...e, 'forename']
        if (!editUser.surname || editUser.surname.length < 3)
            e = [...e, 'surname']

        setErrors(e)

        if (e.length > 0) {
            setErrorMessage('Please fill all fields')
        } else if (editUser.password && editUser.password.length < 8) {
            setErrorMessage('Password is short')
        } else {
            setErrorMessage(null)
            setErrors([])
            try {
                await api.put(`/organisations/${organisationId}/users/${editUser.externalId}`, editUser)
                setUsers(users.map(user => user.externalId === editUser.externalId ? editUser : user))
                setShowPopup('User updated!')
                setTimeout(() => setShowPopup(null), 2000)
                close()
            } catch (err) {
                if (err && err.response && err.response.data && err.response.data && err.response.data.errorDescription)
                    setErrorMessage(err.response.data.errorDescription)
            }
        }
    }

    return (
        <div className='users-popup' onClick={onClick}>
            <div className='users-popup-content'>
                <CloseIcon className='close-icon' onClick={close} />
                <label className='users-popup-title'>Admins</label>
                {users.map(user =>
                    <div className='user' key={user.externalId}>
                        <div className='user-top' onClick={() => setEditUser(editUser && editUser.externalId === user.externalId ? null : user)}>
                            <label>{user.forename} {user.surname}</label>
                            <DropdownLogo className={`open-icon ${user.open && 'open-icon-open'}`} />
                        </div>
                        {editUser && user.externalId === editUser.externalId &&
                            <div className='user-inputs'>
                                <label className='subtitle'>Email</label>
                                <input className={`${errors.length > 0 && errors.includes('email') && 'input-error'}`} value={editUser.email || ''} onChange={e => changeEmail(e.target.value)} />
                                <label className='subtitle'>Forename</label>
                                <input className={`${errors.length > 0 && errors.includes('forename') && 'input-error'}`} value={editUser.forename || ''} onChange={e => changeForename(e.target.value)} />
                                <label className='subtitle'>Surname</label>
                                <input className={`${errors.length > 0 && errors.includes('surname') && 'input-error'}`} value={editUser.surname || ''} onChange={e => changeSurname(e.target.value)} />
                                <label className='subtitle'>Change Password</label>
                                <input type='password' value={editUser.password || ''} onChange={e => setEditUser({ ...editUser, password: e.target.value })} />
                                {errorMessage && <label className='error-message'>{errorMessage}</label>}
                            </div>
                        }
                    </div>
                )}

                <button onClick={editUser ? save : close}>{editUser ? 'Save' : 'Done'}</button>
            </div>
        </div>
    )
}