import React, {useEffect, useState} from "react";
import {Duration, DurationUnit, Param, ParamType} from "../../../types";
import Grid from '@mui/material/Unstable_Grid2';
import {titleCase} from "../../../AutomationService";
import DurationSelect from "../../DurationSelect";
import uuid from "react-uuid";
import {Typography} from "@mui/material";

const DAY_HOURS: number = 24;
const WEEK_HOURS: number = DAY_HOURS * 7;

// Converts a duration into a string that the
// Java class java.time.Duration can parse
export const getDurationString = (duration: Duration): string | null => {
  switch (duration?.unit) {
    case DurationUnit.SECOND:
      return `PT${duration.amount}S`;
    case DurationUnit.MINUTE:
      return `PT${duration.amount}M`;
    case DurationUnit.HOUR:
      return `PT${duration.amount}H`;
    case DurationUnit.DAY:
      return `PT${duration.amount * DAY_HOURS}H`;
    case DurationUnit.WEEK:
      return `PT${duration.amount * WEEK_HOURS}H`;
    default:
      return null;
  }
}

const parseDurationString = (d: string): Duration => {
  if (d) {
    const re = /PT(.+)?([SMH])/;
    const parts = re.exec(d);

    if (parts[1] && parts[2]) {
      const amount = parseFloat(parts[1]);
      const unit = parts[2];

      switch (unit) {
        case "S":
          return {
            amount: amount,
            unit: DurationUnit.SECOND
          }
        case "M":
          return {
            amount: amount,
            unit: DurationUnit.MINUTE
          }
        case "H": {
          if (amount < DAY_HOURS) {
            return {
              amount: amount,
              unit: DurationUnit.HOUR
            }
          } else if (amount >= DAY_HOURS && amount < WEEK_HOURS) {
            return {
              amount: amount / DAY_HOURS,
              unit: DurationUnit.DAY
            }
          } else {
            return {
              amount: amount / WEEK_HOURS,
              unit: DurationUnit.WEEK
            }
          }
        }
      }
    }
  }

  return {
    amount: 1,
    unit: DurationUnit.WEEK
  }
}


type Props = {
  params: Param[]
  setParam: React.Dispatch<React.SetStateAction<Param>>
}

const WaitDurationParam = ({setParam, params}: Props) => {

  const [duration, setDuration] = useState<Duration>();

  useEffect(() => {
    if (params) {
      let waitParam: Param | null = params.find((p: Param) => p.paramType === ParamType.WAIT_PERIOD);
      if (waitParam && waitParam?.paramValue !== getDurationString(duration)) {
        setDuration(parseDurationString(waitParam.paramValue));
      }
    }
  }, [params]);

  useEffect(() => {
    if (duration) {
      setParam({
        id: uuid(),
        paramType: ParamType.WAIT_PERIOD,
        paramValue: getDurationString(duration),
        label: `${duration.amount} ${titleCase(duration.unit)}`
      });
    }
  }, [duration]);

  return (
      <Grid container direction='column'>
        <Grid>
          <Typography variant='body1' sx={{textAlign: 'center'}}>
            Wait Duration
          </Typography>
        </Grid>
        <Grid>
          <DurationSelect
              duration={duration}
              setDuration={setDuration}
          />
        </Grid>
      </Grid>
  );
}
export default WaitDurationParam;
