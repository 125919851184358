import React, {useState} from "react";
import {Field, reduxForm} from "redux-form";
import {compose} from "recompose";
import makeStyles from "@mui/styles/makeStyles";
import CreateContactModalWrapper from "./CreateContactModalWrapper";
import GenderField from "./fields/GenderField";
import FieldsRow from "./FieldsRow";
import ChooseAudienceInput from "./ChooseAudienceInput";
import {
  apiCreateContact,
  apiLinkContactsToAudiences
} from "../../../../../../containers/ContactsList/api";
import {from, map, tap} from 'rxjs';
import {toast} from "react-toastify";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  TextField,
  Typography
} from '@mui/material';
import {UpdateRounded} from "@mui/icons-material";
import styled from "@emotion/styled";
import group41Png from "../../../../../../assets/images/Group41.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import api from "../../../../../../utils/api/";
import 'react-csv-importer/dist/index.css';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import UAParser from '../../../../../../utils/uaparser';
import ImportListModal from "./Modals/ImportListModal";
import {useFormControls} from "./ContactFormControls";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {COUNTRY_PHONE_CODES} from "../../../../../../consts";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "25px",
    zIndex: 1,
  },

  customTextField: {
    "& label": {
      marginLeft: 10,
    },
    "& label.Mui-focused": {
      color: "orange"
    },
    "& .MuiOutlinedInput-root": {
      width: 250,
      marginLeft: 10,
      "&.Mui-focused fieldset": {
        borderColor: "orange"
      },
      "& > fieldset": {
        borderRadius: 25,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange"
      }
    },
    "& .MuiFormHelperText-root": {
      marginBottom: 0,
    }
  },

  customEmailTextField: {
    "& label": {
      marginLeft: 10,
    },
    "& label.Mui-focused": {
      color: "orange"
    },
    "& .MuiOutlinedInput-root": {
      width: 520,
      marginLeft: 10,
      "&.Mui-focused fieldset": {
        borderColor: "orange"
      },
      "& > fieldset": {
        borderRadius: 25,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange"
      }
    },
    "& .MuiFormHelperText-root": {
      marginBottom: 0,
    }
  },

  customMobileTextField: {
    "& label": {
      marginLeft: 10,
    },
    "& label.Mui-focused": {
      color: "orange"
    },
    "& .MuiOutlinedInput-root": {
      width: 360,
      marginLeft: 10,
      "&.Mui-focused fieldset": {
        borderColor: "orange"
      },
      "& > fieldset": {
        borderRadius: 25,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange"
      }
    },
    "& .MuiFormHelperText-root": {
      marginBottom: 0,
    }
  },
  select: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "#FF9933",
    },
  },
  inputFocused: {},
  inputLabel: {
    "&$inputFocused": {
      color: "orange"
    }
  },
}));

const selectAudienceProps = {
  backgroundColor: "background.paper",
  borderColor: "text.primary",
  m: 1,
  border: '1px solid rgba(0, 0, 0, 0.26)',
  style: {width: "500px", height: "172px"}
};

const ConfirmationPlaceholder = styled.div`
  position: relative;
  width: 565px;
  height: 348px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const SelectAudienceModalWrapper = styled.form`
  position: relative;
  width: 565px;
  height: 390px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const ConfirmationContactUploaded = styled.div`
  position: relative;
  width: 565px;
  height: 239px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const HeaderImage = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(46, 125, 50, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const WarningWrapper = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
  top: 43.8px;
`;

const HeaderWarningAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(255, 153, 51, 0.12);
  border-radius: 50%;
  display: inline-block;
`;

const UploadChoiceContainer = styled.div`
  position: relative;
  width: 565px;
  height: 183px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const BulkUploadContainer = styled.div`
  position: relative;
  width: 565px;
  height: 413px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const BulkUploadFrameContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 59px 70px;
  gap: 10px;
  position: absolute;
  left: 36px;
  right: 35.89px;
  top: 91.85px;
  bottom: 106.65px;
  border-radius: 16px;
`;

const TypographyText = styled.div`
  height: 32px;
  margin-top: 20px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const TypographyTextContactExist = styled.div`
  position: absolute;
  height: 64px;
  left: 36px;
  right: 36px;
  top: 170.81px;
  font-family: 'Trebuchet MS', sans-serif' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const TypographyTextSelectAudience = styled.div`
  height: 32px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const TypographyTextContactUpdated = styled.div`
  height: 32px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const ContactDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  // position: absolute;
  height: 266px;
  left: 36px;
  right: 36px;
  top: 60px;
`;

const SelectAudienceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: 493px;
  height: 156px;
  left: 25px;
  top: 180px;
  bottom: 100px;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
  position: absolute;
  width: 299px;
  height: 42px;
  left: calc(50% - 299px/2);
  bottom: 36.5px;
`;

const CountryCodeFieldWrapper = styled.div`
  height: 40px;
  select {
    flex: ${(props) => props.flex};
    display: inline-block;
    border-radius: 20px;
    height: 40px;
    border: 1px solid #c4c4c4;
    padding-left: 5px;
    color: #5b5b5b;
    cursor: pointer;
    outline: none;
  }
`;

const CreateContactModal = (props) => {
  const {
    change,
    allAudiences,
    getAllContactIds,
    handleSubmit,
    organisationId,
    loadContacts,
    setOpenAddNewAudience,
  } = props;

  const classes = useStyles();

  const selectRef = React.useRef(null);
  const [isSelectOpen, setIsSelectOpen] = React.useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = React.useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [openSingleUpload, setOpenSingleUpload] = React.useState(false);
  const [openBulkUpload, setOpenBulkUpload] = React.useState(false);
  const [openImportList, setOpenImportList] = React.useState(false);
  const [openUploadChoice, setOpenUploadChoice] = React.useState(false);
  const [openContactAlreadyExist, setOpenContactAlreadyExist] = React.useState(
      false);
  const [openContactUploadedSuccessfully, setOpenContactUploadedSuccessfully] = React.useState(
      false);
  const [openSelectAudience, setOpenSelectAudience] = React.useState(false);
  const [contactExternalId, setContactExternalId] = React.useState("");
  const [selectedAudiencesIds, setSelectedAudiencesIds] = React.useState({});

  const {
    handleInputValue,
    formIsValid,
    errors
  } = useFormControls();

  const handleOpenUploadChoice = () => {
    setOpenUploadChoice(true);
  };

  const handleOpenSingleUpload = () => {
    setOpenUploadChoice(false);
    setOpenSingleUpload(true);
  };

  const handleOpenBulkUpload = () => {
    setOpenUploadChoice(false);
    setOpenBulkUpload(true);
  };

  const handleOpenImportList = () => {
    setOpenBulkUpload(false);
    setOpenImportList(true);
  };

  const handleOpenSelectAudience = () => {
    setOpenSelectAudience(true);
  };

  const handleOpenAddNewAudience = () => {
    setOpenAddNewAudience(true);
  };

  const handleContactAlreadyExistsClose = () => {
    setOpenContactAlreadyExist(false);
  };

  const handleUploadChoiceClose = () => {
    setOpenUploadChoice(false);
  };

  const handleSelectAudienceClose = () => {
    setOpenSelectAudience(false);
  };

  const handleCloseSingleUpload = () => {
    setOpenSingleUpload(false);
  };

  const handleCloseBulkUpload = () => {
    setOpenBulkUpload(false);
  };

  const handleCloseContactUploadSuccessfully = () => {
    setOpenContactUploadedSuccessfully(false);
  };

  const handleCloseImportList = () => {
    setOpenImportList(false);
  };

  React.useEffect(() => {
    const filteredAudienceIds = Object.entries(selectedAudiencesIds)
    .map((item) => {
      if (item[1] === true) {
        return item[0];
      }
    })
    .filter((x) => x);

    change("audiences", filteredAudienceIds);
  }, [selectedAudiencesIds]);

  const saveUpdatedContact = async (contact) => {

    console.log("saveUpdatedContact")
    console.log(contact)

    const updateContact = {
      forename: contact.firstName,
      surname: contact.lastName,
      email: contact.email,
      registrationType: "EMAIL",
      tenantId: organisationId,
      phones: [
        {
          phoneType: "MOBILE",
          ext: "",
          isPrimary: true,
          priority: 1,
          deviceType: contact.deviceType,
          countryCode: countryCode,
          phoneNumber: phoneNumber,
        }
      ],
      tags: contact.audiences
    };

    from(api.put(
        `/organisations/${organisationId}/contacts/${contactExternalId}`,
        updateContact
    ))
    .subscribe({
      next: () => {
        setOpenContactAlreadyExist(false);
        toast.success("Contact updated");
      },
      error: (error) => {
        toast.error("Error updating contact");
        console.log('Error updating contact');
        console.log(error);
      }
    })
  };

  const onSave = (contactValues) => {
    const parser = UAParser();
    const userAgentString = window.navigator.userAgent;
    const values = new parser(userAgentString, null);
    const browser = values.getBrowser();
    const device = values.getDevice();
    const vendor = device.vendor || '';
    const model = device.model || '';
    const type = device.type || '';

    let newContact = {
      email: email,
      firstName: firstName,
      lastName: lastName,

      mobileNumber: `${selectedCountryCode} ${phoneNumber}`,
      gender: contactValues.gender,
      dateOfBirth: contactValues.dateOfBirth,
      question: contactValues.question,
      answer: contactValues.answer,
      contactType: "PERSON",
      allowSms: true,
      allowEmail: true,
      organisationId: organisationId,
      registrationType: 'EMAIL',
      accessToken: "",
      devices: {
        deviceType: `${vendor} ${model} ${type}`,
        MAC: "00:00:00:00:00:00",
        operatingSystem: values.getOS().name,
        version: values.getEngine().name,
        browser: browser.name,
        browserVersion: browser.version,
        cpu: values.getCPU().architecture,
        userAgent: values.getUA()
      },
      trackAndTrace: false,
      code: "",
    }
    from(apiCreateContact({
      ...newContact,
      organisationId: organisationId
    }))
    .pipe(
        map((response) => response.data),
        tap((contact) => {
          setContactExternalId(contact.externalId);
          from(apiLinkContactsToAudiences({
            organisationId: organisationId,
            contactIds: [contact.externalId],
            contactAudiences: contactValues.audiences
          }))
          .subscribe({
            error: (error) => {
              console.log('Error adding contact');
              console.log(error);
            }
          })
        })
    )
    .subscribe({
      next: (contact) => {
        if (contact.alreadyRegistered) {
          toast.error(
              `${contact.firstName} ${contact.lastName} Contact already exists`);
          setOpenSingleUpload(false);
          setOpenContactAlreadyExist(true);
        } else {
          toast.success(
              `${contact.firstName} ${contact.lastName} Successfully Added to contacts`
          );
          setOpenSingleUpload(false);
          setOpenContactUploadedSuccessfully(true);
          setTimeout(
              () => setOpenContactUploadedSuccessfully(false),
              2000
          );
          setFirstName("");
          setLastName("");
          setCountryCode("");
          setPhoneNumber("");
          setEmail("");
          setSelectedAudiencesIds([]);
          loadContacts();
          getAllContactIds();
        }
      },
      error: (error) => {
        console.log(error);
        setOpenSingleUpload(false);
        const msg = 'Failed to create contact';
        console.error(msg);
        console.error(error);
        toast.error(msg);
      },
      complete: () => {
        handleCloseSingleUpload();
      }
    });
  }

  return (
      <div>
        <div onClick={handleOpenUploadChoice}>
          <ListItemIcon>
            <PersonAddAlt1Icon fontSize="small"/>
          </ListItemIcon>
          Add Contact
        </div>

        {/*UPLOAD CHOICE*/}
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openUploadChoice}
            onClose={handleUploadChoiceClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={openUploadChoice}>
            <div className={classes.paper}>
              <UploadChoiceContainer>
                <img
                    alt="group"
                    onClick={handleUploadChoiceClose}
                    className={"closeImg"}
                    src={group41Png}
                />
                <TypographyText>
                  Add single contact or bulk upload?
                </TypographyText>
                <ButtonSection>
                  <Button
                      onClick={handleOpenSingleUpload}
                      sx={{
                        color: "#FF9933",
                        fontSize: 16,
                        fontWeight: "500"
                      }}
                      variant="text"
                  >
                    SINGLE CONTACT
                  </Button>

                  <Button
                      onClick={handleOpenBulkUpload}
                      sx={{
                        color: "#FF9933",
                        fontSize: 16,
                        fontWeight: "500"
                      }}
                      variant="text"
                  >
                    BULK UPLOAD
                  </Button>
                </ButtonSection>
              </UploadChoiceContainer>
            </div>
          </Fade>
        </Modal>

        {/*SINGLE UPLOAD */}
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openSingleUpload}
            onClose={handleCloseSingleUpload}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
          <Fade in={openSingleUpload}>
            <div className={classes.paper}>
              <CreateContactModalWrapper>
                <img
                    alt="close"
                    onClick={handleCloseSingleUpload}
                    className={"closeImg"}
                    src={require("assets/images/Group41.png")}
                />
                <TypographyText>
                  Contact Details
                </TypographyText>
                <ContactDetailsBox>
                  <div className={"addContactBlock"}>
                    <div className={"successfulImport"}>
                      <form onSubmit={handleSubmit}>
                        <Box
                            sx={{
                              '& .MuiTextField-root': {
                                marginBottom: 0,
                                marginTop: 5,
                                marginRight: 2,
                                width: '25ch'
                              },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                          <TextField
                              size="small"
                              value={firstName}
                              className={classes.customTextField}
                              onChange={e => setFirstName(e.target.value)}
                              onBlur={handleInputValue}
                              name="firstName"
                              label="First Name"
                              error={errors['firstName']}
                              multiline={false}
                              autoComplete="none"
                              {...(errors['firstName'] && {
                                error: true,
                                helperText: errors['firstName']
                              })}
                          />
                          <TextField
                              size="small"
                              className={classes.customTextField}
                              onChange={e => setLastName(e.target.value)}
                              onBlur={handleInputValue}
                              name="lastName"
                              label="Last Name"
                              error={errors['lastName']}
                              multiline={false}
                              autoComplete="none"
                              {...(errors['lastName'] && {
                                error: true,
                                helperText: errors['lastName']
                              })}
                          />
                        </Box>
                        <Box
                            fullWidth
                            sx={{marginTop: 1, marginBottom: 0}}
                        >
                          <FieldsRow>
                            <Box sx={{minWidth: 160}}>


                              <CountryCodeFieldWrapper flex={true}>
                                <select
                                    ref={selectRef}
                                    onFocus={() => setIsSelectOpen(true)}
                                    onBlur={() => setIsSelectOpen(false)}
                                    onClick={() => setIsSelectOpen(true)}
                                    onInput={() => selectRef.current.blur()}
                                    onChange={(event) => {
                                      setSelectedCountryCode(
                                          event.target.value);
                                    }}
                                >
                                  <option value="" disabled selected>
                                    Select country
                                  </option>
                                  {COUNTRY_PHONE_CODES.map((country, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={country.code}>
                                          {!isSelectOpen && selectedCountryCode
                                          === country.code
                                              ? `${country.flag} ${country.code}`
                                              : country.name}
                                        </option>
                                    );
                                  })}
                                </select>
                              </CountryCodeFieldWrapper>
                            </Box>

                            <TextField
                                size="small"
                                className={classes.customMobileTextField}
                                onChange={e => setPhoneNumber(e.target.value)}
                                //onChange={handleInputValue}
                                onBlur={handleInputValue}
                                name="number"
                                label="Number"
                                error={errors['number']}
                                multiline={false}
                                autoComplete="none"
                                {...(errors['number'] && {
                                  error: true,
                                  helperText: errors['number']
                                })}
                            />
                          </FieldsRow>
                        </Box>
                        <FieldsRow>
                          <Field
                              name="gender"
                              component={GenderField}
                              props={{
                                change,
                                className: "create-contact-modal",
                                placeholder: "Gender",
                                fieldName: "gender",
                                flex: 1,
                              }}
                          />
                        </FieldsRow>
                        <Box
                            fullWidth
                            sx={{marginTop: 1, marginBottom: 0}}
                        >
                          <div>
                            <TextField
                                size="small"
                                className={classes.customEmailTextField}
                                onChange={e => setEmail(e.target.value)}
                                onBlur={handleInputValue}
                                name="email"
                                label="Email"
                                error={errors['email']}
                                multiline={false}
                                autoComplete="none"
                                {...(errors['email'] && {
                                  error: true,
                                  helperText: errors['email']
                                })}
                                fullWidth
                            />
                          </div>
                        </Box>
                        <FieldsRow>
                          <Field
                              name="audiences"
                              component={ChooseAudienceInput}
                              props={{
                                change,
                                className: "create-contact-modal",
                                allAudiences: allAudiences,
                                fieldName: "audiences",
                                flex: 3,
                                selectedAudiencesIds,
                                setSelectedAudiencesIds,
                              }}
                          />
                        </FieldsRow>
                        <Button
                            onClick={handleCloseSingleUpload}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500"
                            }}
                            variant="text"
                        >
                          CANCEL
                        </Button>
                        <Button
                            onClick={handleSubmit((values) => onSave(values))}
                            endIcon={<CheckCircleOutlineIcon
                                sx={{fontSize: '80px', fontWeight: '400'}}/>}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              zIndex: 1
                            }}
                            variant="text"
                            disabled={!formIsValid()}
                        >
                          CONFIRM
                        </Button>
                      </form>
                    </div>
                  </div>
                </ContactDetailsBox>
              </CreateContactModalWrapper>
            </div>
          </Fade>
        </Modal>

        {/*BULK UPLOAD*/}
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openBulkUpload}
            onClose={handleCloseBulkUpload}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={openBulkUpload}>
            <div className={classes.paper}>
              <BulkUploadContainer>
                <img
                    alt="group"
                    onClick={handleCloseBulkUpload}
                    className={"closeImg"}
                    src={group41Png}
                />
                <TypographyText>
                  <Typography sx={{fontSize: '24px', fontWeight: '400'}}>
                    Bulk Upload Contacts
                  </Typography>
                </TypographyText>
                <BulkUploadFrameContainer>
                  <ImportListModal setOpenBulkUpload={setOpenBulkUpload} organisationId={organisationId}/>
                </BulkUploadFrameContainer>
                <ButtonSection>
                  <Button
                      onClick={handleCloseBulkUpload}
                      sx={{
                        color: "#FF9933",
                        fontSize: 16,
                        fontWeight: "500"
                      }}
                      variant="text"
                  >
                    CANCEL
                  </Button>
                  <Button
                      onClick={handleCloseBulkUpload}
                      endIcon={<CheckCircleOutlineIcon
                          sx={{fontSize: '80px', fontWeight: '400'}}/>}
                      sx={{
                        color: "#FF9933",
                        fontSize: 16,
                        fontWeight: "500",
                        zIndex: 1
                      }}
                      variant="text"
                  >
                    CONFIRM
                  </Button>
                </ButtonSection>
              </BulkUploadContainer>
            </div>
          </Fade>
        </Modal>

        {/*SELECT AUDIENCE */}
        {((openSelectAudience === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openSelectAudience}
                onClose={handleSelectAudienceClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                disableEnforceFocus={true}
                disableAutoFocus={true}
            >
              <Fade in={openSelectAudience}>
                <div className={classes.paper}>
                  <SelectAudienceModalWrapper>
                    <img
                        alt="close"
                        onClick={handleSelectAudienceClose}
                        className={"closeImg"}
                        src={require("assets/images/Group41.png")}
                    />
                    <TypographyTextSelectAudience>
                      <Typography sx={{fontSize: 24}}>
                        Which Audience Would You Like To Upload To?
                      </Typography>
                    </TypographyTextSelectAudience>
                    <SelectAudienceBox>
                      <div className={"audienceBlock addContactBlock"}>
                        <div className={"successfulImport"}>
                          <div>
                            <form onSubmit={handleSubmit}>
                              <Box borderRadius="8px" {...selectAudienceProps}>
                                <List dense sx={{
                                  margin: 1,
                                  bgcolor: 'background.paper'
                                }}>
                                  {allAudiences &&
                                      Object.values(allAudiences).map(
                                          (audience) => {
                                            const {color, id, name} = audience;
                                            return (
                                                <ListItem disablePadding>
                                                  <ListItemButton>
                                                    <ListItemIcon>
                                                      {color}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={name}/>
                                                  </ListItemButton>
                                                </ListItem>
                                            );
                                          })}
                                  <Divider variant='fullWidth'/>
                                  <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={handleOpenAddNewAudience}>
                                      <ListItemIcon>
                                        <GroupAddRoundedIcon/>
                                      </ListItemIcon>
                                      <ListItemText primary="New Audience"/>
                                      <ListItemIcon>
                                        <AddCircleRoundedIcon/>
                                      </ListItemIcon>
                                    </ListItemButton>
                                  </ListItem>
                                </List>
                              </Box>
                              <Button
                                  onClick={handleSelectAudienceClose}
                                  sx={{
                                    color: "#FF9933",
                                    fontSize: 16,
                                    fontWeight: "500",
                                    marginTop: 2,
                                    marginRight: 4
                                  }}
                                  variant="text"
                              >
                                CANCEL
                              </Button>
                              <Button
                                  onClick={handleSubmit(
                                      (values) => onSave(values))}
                                  endIcon={<CheckCircleOutlineIcon
                                      sx={{
                                        fontSize: '80px',
                                        fontWeight: '400',
                                      }}/>}
                                  sx={{
                                    color: "#FF9933",
                                    fontSize: 16,
                                    fontWeight: "500",
                                    zIndex: 1,
                                    marginTop: 2
                                  }}
                                  variant="text"
                              >
                                CONFIRM
                              </Button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </SelectAudienceBox>
                  </SelectAudienceModalWrapper>
                </div>
              </Fade>
            </Modal>
        )}

        {/*CONTACT ALREADY EXISTS*/}
        {((openContactAlreadyExist === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openContactAlreadyExist}
                onClose={handleContactAlreadyExistsClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openContactAlreadyExist}>
                <div className={classes.paper}>
                  <ConfirmationPlaceholder>
                    <img
                        alt="group"
                        onClick={handleContactAlreadyExistsClose}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <WarningWrapper>
                      <HeaderWarningAmber>
                        <WarningAmberRoundedIcon
                            sx={{
                              fontSize: 45,
                              padding: 3,
                              color: "#FF9933",
                            }}
                        />
                      </HeaderWarningAmber>
                    </WarningWrapper>
                    <TypographyTextContactExist>
                      Contact already exists, do you want to update any missing
                      information?
                    </TypographyTextContactExist>
                    <ButtonSection>
                      <Button
                          onClick={handleContactAlreadyExistsClose}
                          sx={{
                            color: "#FF9933",
                            fontSize: 16,
                            fontWeight: "500"
                          }}
                          variant="text"
                      >
                        CANCEL
                      </Button>
                      <Button
                          onClick={handleSubmit(
                              (values) => saveUpdatedContact(values))}
                          endIcon={<UpdateRounded
                              sx={{fontSize: '80px', fontWeight: '400'}}/>}
                          sx={{
                            color: "#FF9933",
                            fontSize: 16,
                            fontWeight: "500",
                            zIndex: 1
                          }}
                          variant="text"
                      >
                        UPDATE CONTACT
                      </Button>
                    </ButtonSection>

                  </ConfirmationPlaceholder>
                </div>
              </Fade>
            </Modal>
        )}

        {/*CONTACT UPDATED SUCCESSFULLY*/}
        {((openContactUploadedSuccessfully === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openContactUploadedSuccessfully}
                onClose={handleUploadChoiceClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openContactUploadedSuccessfully}>
                <div className={classes.paper}>
                  <ConfirmationContactUploaded>
                    <img
                        alt="group"
                        onClick={handleCloseContactUploadSuccessfully}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <WarningWrapper>
                      <HeaderImage>
                        <CheckCircleOutlineIcon
                            sx={{
                              fontSize: 60,
                              padding: 2.3,
                              color: "#1DB350",
                            }}
                        />
                      </HeaderImage>
                    </WarningWrapper>
                    <TypographyTextContactUpdated>
                      Contact Successfully Uploaded!
                    </TypographyTextContactUpdated>
                  </ConfirmationContactUploaded>
                </div>
              </Fade>
            </Modal>
        )}
      </div>
  );
};

export default compose(
    reduxForm({
      form: "CREATE_CONTACT_MODAL_FORM",
      initialValues: {
        audiences: [],
      },
    })
)(CreateContactModal);
