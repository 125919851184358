import React from "react";
import {Backdrop, Fade, Modal} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RadioButtons from "components/customers/modal/createAudienceset";
import CreateAudienceSuccess from "./addContactSuccessful";
import AddContactt from "./importList";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "25px",
    zIndex: 1,
  },
}));

export default function AddContact() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
      <div>
        <div onClick={handleOpen}>
          {/*react-transition-group*/}
          <img alt="group" src={require("assets/images/Group11565.png").default}/>
          Add Contact(s)
        </div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <img
                  alt="close"
                  onClick={handleClose}
                  className={"closeImg"}
                  src={require("assets/images/Group41.png")}
              />
              <div className={"audienceBlock addContactBlock"}>
                <div className={"successfulImport"}>
                  <div className={"createContact"}>
                    <div className="select-contact">
                      {/*<select onChange={handleClose}>
                                                <option value={'Single contact'}>{'Single contact'}</option>
                                                <option value={'Import list'}>{'Import list'}</option>
                                        </select>*/}
                      <AddContactt/>
                    </div>

                    <h2 id="simple-modal-title">Create Contact</h2>
                    <input type="text" placeholder="Name"/>
                    <input type="text" placeholder="Surname"/>
                    {/*<input type='text' placeholder='Select Country'/>*/}

                    <select className={"country"}>
                      <option value="" disabled selected>
                        Select country
                      </option>
                      <option
                          value={"United Kingdom"}>{"United Kingdom"}</option>
                      <option value={"United States"}>{"United States"}</option>
                      <option value={"Germany"}>{"Germany"}</option>
                      <option value={"Spain"}>{"Spain"}</option>
                      <option value={"France"}>{"France"}</option>
                    </select>

                    <input type="text" placeholder="Number"/>

                    <input
                        className={"emailWidth"}
                        type="text"
                        placeholder="Email address"
                    />

                    <div className={"assignAudience"}>
                      <RadioButtons/>
                    </div>

                    <button className={"saveB"}>
                      <CreateAudienceSuccess/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
  );
}
