import React from "react";

import MobilePreviewOuter from "./MobilePreviewOuter";
import MobilePreviewInner from "./MobilePreviewInner";

const MobilePreview = (props) => {
  return (
      <MobilePreviewOuter {...props}>
        <MobilePreviewInner {...props}/>
      </MobilePreviewOuter>
  );
};

export default MobilePreview;
