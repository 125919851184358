import React from "react";

import LoginScreen from "./screens/LoginScreen";
import JoinScreen from "./screens/JoinScreen";
import EmailRegistrationScreen from "./screens/EmailRegistrationScreen";
import TextRedirectScreen from "./screens/TextRedirectScreen";
import InstagramRedirectScreen from "./screens/InstagramRedirectScreen";
import CustomImageRedirectScreen from "./screens/CustomImageRedirectScreen";
import CustomURLRedirectScreen from "./screens/CustomURLRedirectScreen";
import {RedirectPageType} from "../../../../../../containers/Connect/types";

const MobilePreviewInner = (props) => {
  const {
    currentLandingPageStep,
    currentPreviewScreen,
    settings
  } = props;

  const switchPreview = () => {
    switch (currentPreviewScreen) {
      case 0:
        return <LoginScreen settings={settings}/>;
      case 1:
        return <JoinScreen settings={settings}/>;
      default:
        return;
    }
  }

  const switchRedirectPage = () => {
    const type = settings?.redirectPage?.type
        ? settings?.redirectPage?.type
        : RedirectPageType.TEXT

    switch (type) {
      case RedirectPageType.INSTAGRAM:
        return <InstagramRedirectScreen settings={settings}/>;

      case RedirectPageType.CUSTOM_IMAGE:
        return <CustomImageRedirectScreen settings={settings}/>;

      case RedirectPageType.CUSTOM_URL:
        return <CustomURLRedirectScreen/>;

      case RedirectPageType.TEXT:
      default:
        return <TextRedirectScreen settings={settings}/>;
    }
  }

  switch (currentLandingPageStep) {
    case 1:
      return switchPreview();
    case 2:
      return <LoginScreen settings={settings}/>;
    case 3:
      return <EmailRegistrationScreen settings={settings}/>;
    // case 4:
    //   return <GrowFacebookScreen settings={settings}/>;
    case 4:
      // return <EmailPasswordScreen settings={settings}/>;
      return switchRedirectPage();
    default:
      return;
  }
};

export default MobilePreviewInner;
