import {
  GET_IDENTITIES_REQUEST,
  GET_IDENTITIES_FAILURE,
  GET_IDENTITIES_SUCCESS,
  CREATE_IDENTITY_REQUEST,
  CREATE_IDENTITY_FAILURE,
  CREATE_IDENTITY_SUCCESS,
  DELETE_IDENTITY_REQUEST,
  DELETE_IDENTITY_FAILURE,
  DELETE_IDENTITY_SUCCESS,
  SELECT_IDENTITY_REQUEST,
  SELECT_IDENTITY_FAILURE,
  SELECT_IDENTITY_SUCCESS,
  GET_DOMAIN_RECORDS_REQUEST,
  GET_DOMAIN_RECORDS_FAILURE,
  GET_DOMAIN_RECORDS_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  errorMessage: null,
  organisationIdentities: null,
  createdIdentity: null,
  deletedIdentity: null,
  currentIdentity: null,
  currentIdentityDomainRecords: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_IDENTITY_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        createdIdentity: null,
        currentIdentity: null,
      };
    }

    case CREATE_IDENTITY_FAILURE: {
      return {
        ...state,
        loading: false,
        createdIdentity: null,
        errorMessage: action.payload,
      };
    }

    case CREATE_IDENTITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        createdIdentity: action.payload,
        currentIdentity: action.payload,
      };
    }

    //---

    case GET_IDENTITIES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        organisationIdentities: null,
      };
    }

    case GET_IDENTITIES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_IDENTITIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        organisationIdentities: action.payload,
      };
    }

    //---

    case SELECT_IDENTITY_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        currentIdentity: null,
      };
    }

    case SELECT_IDENTITY_FAILURE: {
      return {
        ...state,
        loading: false,
        deletedIdentity: null,
        errorMessage: action.payload,
      };
    }

    case SELECT_IDENTITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentIdentity: action.payload,
      };
    }

    //---

    case DELETE_IDENTITY_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        deletedIdentity: null,
      };
    }

    case DELETE_IDENTITY_FAILURE: {
      return {
        ...state,
        loading: false,
        deletedIdentity: null,
        errorMessage: action.payload,
      };
    }

    case DELETE_IDENTITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        deletedIdentity: action.payload,
      };
    }

    //---

    case GET_DOMAIN_RECORDS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        currentIdentityDomainRecords: null,
      };
    }

    case GET_DOMAIN_RECORDS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        currentIdentityDomainRecords: null,
      };
    }

    case GET_DOMAIN_RECORDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentIdentityDomainRecords: action.payload,
      };
    }

    default:
      return state;
  }
}
