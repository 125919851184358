import React from "react";
import styled from "@emotion/styled";
import {UserCircleGear, Users} from "phosphor-react";
import {PAGE_SIZE} from "../../../../containers/ContactsList/api";
import {SearchCriteria} from "../../../../containers/ContactsList/types";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
  Box,
  ListItemIcon,
} from '@mui/material';
import uuid from "react-uuid";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

const SegmentNameItemWrapper = styled.a`
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  background: ${(props) =>
    props.isThisItemSelected
        ? "linear-gradient(90deg, rgba(253,237,216,1) 0%, rgba(253,237,216,1) 55%, rgba(253,237,216,0) 95%)"
        : "white"};

  &:hover {
    background: linear-gradient(90deg,
    rgba(253, 237, 216, 1) 0%,
    rgba(253, 237, 216, 1) 55%,
    rgba(253, 237, 216, 0) 95%);
    cursor: pointer;
  }

  &:active {
    background: linear-gradient(90deg,
    rgba(253, 237, 216, 1) 0%,
    rgba(253, 237, 216, 1) 55%,
    rgba(253, 237, 216, 0) 95%);
  }
`;

const ItemIcon = ({isAllContactsSegmentPanel}) => {
  return !isAllContactsSegmentPanel ? (
      <UserCircleGear size={26} weight="bold" color={"#5B5B5B"}/>
  ) : (
      <Users size={26} weight="bold" color={"#5B5B5B"}/>
  );
};

const ListItemName = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  margin-left: 12px;

  .title {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    opacity: 0.75;
  }
`;

const SegmentNameItem = (props) => {
  const {
    organisationId,
    id,
    name,
    iconName,
    allSegments,
    selectSegmentRequest,
    getAllContactsRequest,
    getSegmentContactsRequest,
    selectListTypeRequest,
    isAllContactsSegmentPanel,
    listTypes,
    setCurrentSelectedSegmentId,
    setSelectedContactsIds,
    setIsAllContactsSelected,
    currentSelectedSegmentId,
    addFilterRequest,
    removeFilterRequest,
    searchQuery,
    setPage,
    currentListType
  } = props;

  const handleClick = (isAllContactsSegmentPanel, id, organisationId) => {
    setCurrentSelectedSegmentId(id);
    removeFilterRequest();
    setSelectedContactsIds([]);
    setIsAllContactsSelected(false);

    if (isAllContactsSegmentPanel === true) {
      selectListTypeRequest(listTypes.ALL);
      getAllContactsRequest(organisationId, 0, PAGE_SIZE, searchQuery);
      setPage(0);
    } else {
      selectListTypeRequest(listTypes.SEGMENTS);
      selectSegmentRequest(id);
      getSegmentContactsRequest(organisationId, id, 0, PAGE_SIZE, searchQuery);
      setPage(0);

      if (allSegments[id] && allSegments[id]?.criteria) {
        allSegments[id].criteria
        .forEach((c: SearchCriteria) => addFilterRequest(c))
      }
    }
  };

  return (
      <SegmentNameItemWrapper
          isThisItemSelected={id === currentSelectedSegmentId && currentListType === "segments"}
          onClick={() => handleClick(isAllContactsSegmentPanel, id,
              organisationId)}
      >
        {isAllContactsSegmentPanel &&
            <Box sx={{margin: 2, fontFamily:"'Trebuchet MS', sans-serif' !important", fontSize:"16px"}}>
              <ListItemIcon>
                <PersonRoundedIcon fontSize="medium"/>
              </ListItemIcon>
              {"All Contacts"}
            </Box>
        }
        {!isAllContactsSegmentPanel &&
            <Box sx={{margin: 2, fontFamily:"'Trebuchet MS', sans-serif' !important", fontSize:"16px"}}>
              <ListItemIcon>
                <span role="emoji" aria-label="emoji">{iconName}</span>
              </ListItemIcon>
              {name}
            </Box>
        }
      </SegmentNameItemWrapper>
  );
};

export default SegmentNameItem;
