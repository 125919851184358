import React, {useState} from "react";
import {ReactComponent as DropdownLogo} from "assets/images/dropdown-icon.svg";
import uuid from "react-uuid";
import "assets/css/widget/dropdown.scss";

export const Dropdown = ({
  options= [],
  selected,
  onSelect,
  dropdownPosition = "bottom",
  className,
  placeholder,
  hasSecondaryBackground = false,
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    if (options.length > 0) {
      setOpen(!open);
    }
  };

  const changeSelected = (option) => {
    setOpen(false);
    onSelect(option);
  };

  return (
      <div
          className={`dropdown-container ${className}`}
          tabIndex="0"
          onBlur={() => setOpen(false)}
      >
        <div
            className={`dropdown ${
                open && "dropdown-open"
            } dropdown--secondary-background-${hasSecondaryBackground}`}
            onClick={() => toggleOpen()}
        >
          <label>{selected?.name || placeholder}</label>

          <DropdownLogo className="logo"/>
        </div>
        {open && (
            <div className={`options dropdown-position--${dropdownPosition}`}>
              {options.map((option) => {
                return (
                    <label
                        key={uuid()}
                        onClick={() => changeSelected(option)}
                    >
                      {option.name}
                    </label>
                );
              })}
            </div>
        )}
      </div>
  );
};
