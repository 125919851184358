import React, {useState} from 'react'

import AddIntegrationPopupItem from './addIntegrationPopupItem'

import {ReactComponent as CloseIcon} from '../../assets/images/delete.svg';
import {
  ReactComponent as RightArrow
} from '../../assets/images/right-arrow.svg';
import {ReactComponent as LeftArrow} from '../../assets/images/left-arrow.svg';

import '../../assets/css/integrations/add-integration-popup.scss'

export default ({close, addIntegration, data}) => {

  const [isSliding, setIsSliding] = useState(false)

  // noinspection DuplicatedCode
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
      return c / 2 * t * t + b;
    }
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  const scroll = (element, change, duration) => {
    setIsSliding(true)
    setTimeout(() => setIsSliding(false), duration)

    var start = element.scrollLeft,
        currentTime = 0,
        increment = 20;

    const animateScroll = function () {
      currentTime += increment;
      element.scrollLeft =
          Math.easeInOutQuad(currentTime, start, change, duration);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  const scrollRight = () => {
    if (!isSliding) {
      scroll(document.getElementById('venues-content'), 500, 500)
    }
  }

  const scrollLeft = () => {
    if (!isSliding) {
      scroll(document.getElementById('venues-content'), -500, 500)
    }
  }

  return (
      <div className='add-integration-popup'>
        <div className='add-integration-popup-content'>
          <div className='add-integration-popup-content-top'>
            <label className='add-integration-popup-title'>Add
              integration</label>
            <CloseIcon className='close-icon' onClick={close}/>
          </div>
          <div className='add-integration-popup-content-slider'>
            {data.unsetVenues.length > 3 &&
                <div className='slider-button' onClick={scrollLeft}>
                  <LeftArrow/>
                </div>
            }
            <div id='venues-content'
                 className={`add-integration-popup-venues ${data.unsetVenues.length
                 === 1
                 && 'add-integration-popup-venues-small'} ${data.unsetVenues.length
                 === 2 && 'add-integration-popup-venues-medium'}`}>
              {data.unsetVenues.map(
                  venue => <AddIntegrationPopupItem key={venue.externalId}
                                                    connector={data.connector}
                                                    venue={venue}
                                                    addIntegration={addIntegration}/>)}
            </div>
            {data.unsetVenues.length > 3 &&
                <div className='slider-button slider-button-right'
                     onClick={scrollRight}>
                  <RightArrow/>
                </div>
            }
          </div>
        </div>
      </div>
  )
}