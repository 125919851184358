import React, {useEffect, useState} from "react";
import {
  ReactComponent as AddLandingIcon
} from "assets/images/add-landing-icon.svg";
import {DeletePopup} from "components/widget/deletePopup";
import PageNavigator from "../components/PageNavigator";
import LandingPageItem from "./LandingPageItem";
import PageTitle from 'components/PageTitle'
import api from "utils/api";
import "assets/css/design/landing-pages.scss";

const ITEMS_PER_PAGE = 5;

const LandingPages = (props) => {
  const {organisationId, setPopupType, type, disableCreate} = props;

  const [landingPages, setLandingPages] = useState([]);
  const [landingPageToBeDeleted, setLandingPageToBeDeleted] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [filteredLandingPages, setFilteredLandingPages] = useState([]);
  const [selectedLandingPage, setSelectedLandingPage] = useState(null);
  const [currentItems, setCurrentItems] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pageSelected, setPageSelected] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [disabled, setDisabled] = useState(false);


  useEffect(() => {
    filteredLandingPages &&
    setTotalPages(Math.ceil(filteredLandingPages.length / ITEMS_PER_PAGE));
  }, [filteredLandingPages]);

  useEffect(() => {
    const indexOfLastPost = pageSelected * ITEMS_PER_PAGE;
    const indexOfFirstPost = indexOfLastPost - ITEMS_PER_PAGE;

    setCurrentItems(
        filteredLandingPages.slice(indexOfFirstPost, indexOfLastPost)
    );
  }, [pageSelected, filteredLandingPages]);

  const handleSelectActive = (posterId) => {
    const updatedLandingPages = landingPages.map((landingPage) => {
      if (landingPage.externalId === posterId) {
        landingPage.active = !landingPage.active;
        return landingPage;
      } else {
        landingPage.active = false;
        return landingPage;
      }
    });

    setLandingPages(updatedLandingPages);
  };

  const getLandingPages = async () => {
    const url = `/organisations/${organisationId}/landingPages?track=true`;
    const result = await api.get(url);
    setLandingPages(result.data);
    setFilteredLandingPages(result.data);
  };

  const deleteLandingpage = async () => {
    await api.delete(`/landingPages/${landingPageToBeDeleted.externalId}`);
    setLandingPages([
      ...landingPages.filter(
          (landingpage) => landingpage.externalId !== landingPageToBeDeleted
      ),
    ]);
    setLandingPageToBeDeleted(null);

    await getLandingPages();
  };

  useEffect(() => {
    setDisabled(disableCreate);
    getLandingPages()
    .catch(e => console.log(e));
  }, [organisationId]);

  // noinspection DuplicatedCode
  const onKeyPressEnter = (event) => {
    if (event.key === "Enter") {
      setPopupType(type);
    }
  };

  const onChangeTextFilter = (searchText) => {
    if (searchText !== "") {
      const newFiltedPages = landingPages.filter((landing) =>
          landing.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredLandingPages(newFiltedPages);
    } else {
      setFilteredLandingPages(landingPages);
    }
  };

  useEffect(() => {
    const activePage = landingPages.filter((page) => {
      return page?.active === true;
    });

    activePage.length > 0
        ? setSelectedLandingPage(activePage[0].externalId)
        : setSelectedLandingPage(null);
  }, [landingPages]);

  return (
      <div>
        <div className="landing-page-header">
          <PageTitle title={"Manage QR Data Capture"}/>
          {totalPages && totalPages > 1 ? (
              <PageNavigator
                  setPageSelected={setPageSelected}
                  pageSelected={pageSelected}
                  totalPages={totalPages}
              />
          ) : null}
          <div className="landing-page-search-inputs">
            <input
                value={searchTerm}
                type="search"
                placeholder="Search"
                className="quick-send__input--with-info"
                onChange={(e) => onChangeTextFilter(e.target.value)}
            />
          </div>
        </div>

        <div className="landing-pages">
          <div className="landing-pages-flex-container">
            <div
                style={disabled ? {
                  pointerEvents: 'none',
                  opacity: 0.4
                } : {}}
                className="landing-page-add-item"
                onClick={() => setPopupType("track")}
                tabIndex="0"
                onKeyDown={(event) => onKeyPressEnter(event)}
            >
              <div className="add-card-content">
                <div className="add-card-content-image">
                  <AddLandingIcon/>
                </div>
                <label>Create New QR Data Capture</label>
              </div>
            </div>
            {currentItems &&
                currentItems.map((landingPage) => (
                    <LandingPageItem
                        itemId={landingPage.externalId}
                        key={landingPage.externalId}
                        landingPage={landingPage}
                        type={type}
                        handleSelectActive={handleSelectActive}
                        onDelete={(landingPage) =>
                            setLandingPageToBeDeleted(landingPage)
                        }
                        selectedItem={selectedItem}
                    />
                ))}
          </div>
          {landingPageToBeDeleted && (
              <DeletePopup
                  onCancel={() => setLandingPageToBeDeleted(null)}
                  onDelete={deleteLandingpage}
                  landingName={landingPageToBeDeleted.name}
              />
          )}
        </div>
      </div>
  );
};

export default LandingPages;
