import React from "react";
import { UserPlus, Envelope, DeviceMobile, ArrowUUpLeft } from "phosphor-react";

const iconStyle = {
  transform: "translateY(-3px)",
};

export const adminCardsConfig = [
  {
    id: 0,
    bigIcon: require("assets/images/emails-icon.svg"),
    title: "Total Emails Collected",
    number: 0,
    smallIcon: require("assets/images/calendar-icon.svg"),
    text: "All time",
  },
  {
    id: 1,
    bigIcon: require("assets/images/person-icon.svg"),
    title: "Total Accounts",
    number: 2,
    smallIcon: require("assets/images/calendar-icon.svg"),
    text: "All time",
  },
  {
    id: 2,
    bigIcon: require("assets/images/position-icon.svg"),
    title: "Total Venues",
    number: 0,
    smallIcon: require("assets/images/calendar-icon.svg"),
    text: "All time",
  },
  {
    id: 3,
    bigIcon: require("assets/images/phone-icon.svg"),
    title: "Total Devices",
    number: 0,
    smallIcon: require("assets/images/calendar-icon.svg"),
    text: "All time",
  },
];

export const userCardsConfig = [
  {
    id: 0,
    bigIcon: require("assets/images/position-icon.svg"),
    title: "Customers connected",
    number: "",
    smallIcon: require("assets/images/calendar-icon.svg"),
    text: "All time",
    renderIcon: () => (
      <DeviceMobile size={28} color={"white"} weight="bold" style={iconStyle} />
    ),
  },
  {
    id: 1,
    bigIcon: require("assets/images/position-icon.svg"),
    title: "Customer emails collected",
    number: 0,
    smallIcon: require("assets/images/calendar-icon.svg"),
    text: "All time",
    renderIcon: () => (
      <Envelope size={28} color={"white"} weight="bold" style={iconStyle} />
    ),
  },
  {
    id: 2,
    bigIcon: require("assets/images/phone-icon.svg"),
    title: "Customer return rate",
    number: "0%",
    smallIcon: require("assets/images/calendar-icon.svg"),
    text: "All time",
    renderIcon: () => (
      <ArrowUUpLeft size={28} color={"white"} weight="bold" style={iconStyle} />
    ),
  },
  {
    id: 3,
    bigIcon: require("assets/images/phone-icon.svg"),
    title: "New customers today",
    number: 0,
    smallIcon: require("assets/images/calendar-icon.svg"),
    text: "All time",
    renderIcon: () => (
      <UserPlus size={28} color={"white"} weight="bold" style={iconStyle} />
    ),
  },
];
