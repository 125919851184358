import React, {useState, useEffect} from "react";
import {
  FormControl,
  FormControlLabel,
  Menu,
  Radio,
  RadioGroup,
  Divider,
  Button
} from "@mui/material";
import DoneButton from "./DoneButton";

const Birthday = (props) => {
  const {change, destroy, handleSubmit, addFilterRequest} = props;

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [greaterField, setGreaterField] = useState("");
  const [lessField, setLessField] = useState("");
  const [equalField, setEqualField] = useState("");

  useEffect(() => {
    if (lessField?.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [lessField]);

  const [value, setValue] = useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    destroy("ADD_FILTER_MODAL_FORM");
    setLessField("");
  };

  useEffect(() => {
    anchorEl === null && destroy("ADD_FILTER_MODAL_FORM");
  }, [anchorEl]);

  useEffect(() => {
    change("operation", "LT");
  }, []);

  const handleOnChange = (operation, event) => {
    const regexExp = new RegExp(/^(\s*|\d+)$/);

    switch (operation) {
      case "GT":
        setLessField("");
        setEqualField("");

        if (regexExp.test(event.target.value)) {
          setGreaterField(event.target.value);
          change("value", event.target.value);
          change("operation", "GT");
        }

        break;
      case "LT":
        setGreaterField("");
        setEqualField("");

        if (regexExp.test(event.target.value)) {
          setLessField(event.target.value);
          change("value", event.target.value);
          change("operation", "LT");
        }
        break;
      case "EQUALS":
        setGreaterField("");
        setLessField("");

        if (regexExp.test(event.target.value)) {
          setEqualField(event.target.value);
          change("value", event.target.value);
          change("operation", "EQUALS");
        }
        break;
    }
  };

  const onClick = (values) => {
    addFilterRequest({
      key: 'birthday',
      operation: values.operation,
      value: values.value
    });
    handleClose();
  }

  return (
      <div>
        <div
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
        >
          Birthday
        </div>

        <Menu
            id="simple-menu"
            // anchorEl={anchorEl}
            anchorReference="anchorPosition"
            anchorPosition={{top: 350, left: 280}}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '240px',
                height: '190px',
                justifyContent: 'center',
              },
            }}
        >
          <FormControl className={"filterMenu"} component="fieldset">
            <RadioGroup
                aria-label="birthday"
                name="birthday"
                value={value}
                onChange={handleChange}
            >
              <FormControlLabel
                  // checked={true}
                  value="Is in less than _Days"
                  control={<Radio sx={{
                    '&.Mui-checked': {
                      color: "#FF9933",
                    },
                  }}/>}
                  // onClick={() => {
                  //   change("operation", "in");
                  // }}
                  label="Is in less than (Days)"
              />

              {value === "Is in less than _Days" && (
                  <input
                      onChange={(event) => {
                        handleOnChange("LT", event);
                      }}
                      style={{
                        height: '30px',
                        borderRadius: '28px',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        outlineColor: "#FF9933",
                        textAlign: 'center',
                        fontSize: '16px'
                      }}
                      value={lessField}
                      type="text"
                  />
              )}
              <br/>
              <Divider fullWidth/>
            </RadioGroup>
          </FormControl>
          <Button
              onClick={handleSubmit((values) => onClick(values))}
              sx={{
                color: "#FF9933",
                fontSize: 16,
                fontWeight: "500",
                marginLeft: '10ch'
              }}
              variant="text"
              disabled={isButtonDisabled}
          >
            DONE
          </Button>
        </Menu>
      </div>
  );
};

export default Birthday;
