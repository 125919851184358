import React from "react";

import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ route, Component }) => {
  if (JSON.parse(localStorage.getItem("user")) !== null) {
    return (
      <Route path={route}>
        <Component />
      </Route>
    );
  }
  return <Redirect to="/login" />;
};

export default PrivateRoute;
