import styled from "@emotion/styled";

const DraggableItemWrapper = styled.div`
  border: 1px solid #c4c4c4;
  padding: 5px;
  background-color: #f2f2f2;
  max-width: 100%;
  display: flex;
  align-items: center;
  cursor: move;
  font-size: 14px;
  
  &__drag-handle {
    margin: 5px;
  }
`;

export default DraggableItemWrapper;
