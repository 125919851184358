import { combineReducers } from "redux";

import sendDashboardReducer from "./sendDashboardReducer";
import sendNewEmailReducer from "./sendNewEmail";

const sendRootReducer = combineReducers({
  "dashboard": sendDashboardReducer,
  "sendNewEmail": sendNewEmailReducer,
});

export default sendRootReducer;
