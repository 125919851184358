import React, {useState} from "react";
import {Backdrop, Fade, Modal} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LoadingCirclesAnim from "components/LoadingCirclesAnim";
import group41Png from "../../../../../../assets/images/Group41.png";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import styled from "@emotion/styled";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import {from} from "rxjs";
import {apiDeleteContacts} from "../../../../../../containers/ContactsList/api";
import {toast} from "react-toastify";
import ConfirmDeleteCustomerModal from "./ConfirmDeleteCustomerModal";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "16px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
    width: 565,
    height: 337,
  },
}));

const ConfirmationPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 501px;
  height: 266px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const StatusPlaceholder = styled.div`
  min-height: 75px;
  position: relative;
  margin-left: 15%;
  p {
    position: absolute;
    font-family: Roboto-Bold;
    font-size: 14px;
    opacity: 0.85;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const Group11446 = styled.div`
  width: 96px;
  height: 96px;
  margin-top: 14%;
  margin-left: 12%;
`;

const TypographyText = styled.div`
  height: 32px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-left: 15%;
  gap: 24px;
  color: #ff9933;
  width: 120px;
  height: 42px;
`;

const HeaderWarningAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(255, 153, 51, 0.12);
  border-radius: 50%;
  display: inline-block;
`;

const HeaderWarningRed = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(234, 84, 84, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const DeleteContactsModal = (props) => {
  const {
    selectedContactsIds,
    organisationId,
    getAllContactIds,
    loadContacts,
    currentListType,
    selectedAudience
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showSuccessfulDeleteMessage, setShowSuccessfulDeleteMessage] = useState(false);
  const [showSuccessfulRemoveMessage, setShowSuccessfulRemoveMessage] = useState(false);
  const [showRemoveFromAudience, setShowRemoveFromAudience] = useState(false);
  const [showDeleteFromCustomers, setShowDeleteFromCustomers] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowRemoveFromAudience(false);
    setShowDeleteFromCustomers(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);
    from(
        apiDeleteContacts({
          organisationId: organisationId,
          contactsIds: selectedContactsIds,
        })
    ).subscribe({
      next: () => {
        setIsLoading(false);
        setShowSuccessfulDeleteMessage(true)
        setTimeout(
            () =>  setShowSuccessfulDeleteMessage(false),
            1500
        );
        loadContacts();
        getAllContactIds();
        handleClose();
      },
      error: (err) => {
        const msg = "Error deleting contacts";
        toast.error(msg);
        console.error(msg);
        console.error(err);

        setIsLoading(false);
        handleClose();
      },
      complete: () => {
        setIsLoading(false);
        setShowSuccessfulDeleteMessage(true)
        setTimeout(
            () =>  setShowSuccessfulDeleteMessage(false),
            1500
        );
        handleClose();
      },
    });
  };

  // TODO: Implement Remove from Audience
  const handleRemoveContactFromAudience = () => {
    console.log("handleRemoveContactFromAudience API Call")
    setShowSuccessfulRemoveMessage(true);
    setIsLoading(true);
    from(
        apiDeleteContacts({
          organisationId: organisationId,
          contactsIds: selectedContactsIds,
        })
    ).subscribe({
      next: () => {
        loadContacts();
        getAllContactIds();
        setShowSuccessfulRemoveMessage(true);
        handleClose();
      },
      error: (err) => {
        const msg = "Error deleting contacts";
        toast.error(msg);
        console.error(msg);
        console.error(err);
        setIsLoading(false);
        handleClose();
      },
      complete: () => {
        setIsLoading(false);
        handleClose();
      },
    });
  };

  const handleShowRemoveFromAudienceMessage = () => {
    setShowRemoveFromAudience(true);
  };

  const handleShowDeleteFromCustomersMessage = () => {
    setShowDeleteFromCustomers(true);
  };

  return (
      <div>
        <Button
            variant="outlined"
            startIcon={<DeleteIcon sx={{fontSize: 80}}/>}
            sx={{
              color: selectedContactsIds ? "#EA5454" : "#C4C4C4",
              marginRight: 1,
              marginTop: 0.2,
              border: "none !important",
            }}
            onClick={handleOpen}
            disabled={!(selectedContactsIds && selectedContactsIds.length > 0)}
        ></Button>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <img
                  alt="group"
                  onClick={handleClose}
                  className={"closeImg"}
                  src={group41Png}
              />
              <ConfirmationPlaceholder>
                <Group11446>
                  {(currentListType !== "audiences" || (currentListType === "audiences" && showDeleteFromCustomers === false)) && (
                      <HeaderWarningAmber>
                        <WarningAmberRoundedIcon
                            sx={{
                              fontSize: 45,
                              padding: 3,
                              color: "#FF9933",
                            }}
                        />
                      </HeaderWarningAmber>
                  )}
                  {currentListType === "audiences" && showDeleteFromCustomers === true && (
                      <HeaderWarningRed>
                        <WarningAmberRoundedIcon
                            sx={{
                              fontSize: 45,
                              padding: 3,
                              color: "#EA5454",
                            }}
                        />
                      </HeaderWarningRed>
                  )}
                </Group11446>

                {showRemoveFromAudience === true && (
                    <>
                      <TypographyText>
                        Are you sure you want to Remove (
                        {selectedContactsIds && selectedContactsIds.length})
                        contacts from {" "} ({selectedAudience.name})?
                      </TypographyText>
                    </>
                )}

                {currentListType === "audiences" && showDeleteFromCustomers === true && (
                    <>
                      <TypographyText>
                        Are you sure you want to
                        <span style={{color: "#EA5454"}}> Delete</span> (
                        {selectedContactsIds && selectedContactsIds.length})
                        contacts from {" "} customers?
                      </TypographyText>
                    </>
                )}

                {currentListType !== "audiences" && (
                    <>
                      <TypographyText style={{marginLeft: '60px'}}>
                        Are you sure you want to delete (
                        {selectedContactsIds && selectedContactsIds.length})
                        contacts?{" "}
                      </TypographyText>

                      <StatusPlaceholder>
                        {isLoading && (
                            <>
                              <p>Deleting...</p>
                              <LoadingCirclesAnim/>
                            </>
                        )}
                      </StatusPlaceholder>
                    </>
                )}

                {(currentListType === "audiences" && !showRemoveFromAudience && !showDeleteFromCustomers) && (
                    <TypographyText>
                      Do you want to remove (
                      {selectedContactsIds && selectedContactsIds.length})
                      contacts? from ({selectedAudience ? selectedAudience.name: ""}) {" "}
                      or fully delete from customers?
                    </TypographyText>
                )}

                <ButtonSection>
                  { (currentListType !== "audiences" || showDeleteFromCustomers) && (
                      <>
                        <Button
                            onClick={handleDelete}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "bold"
                            }}
                            variant="text"
                        >
                          YES
                        </Button>
                        <Button
                            onClick={handleClose}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                            variant="text"
                        >
                          NO
                        </Button>
                      </>
                  )}

                  {(currentListType === "audiences" && showRemoveFromAudience)
                      && (
                          <>
                            <Button
                                onClick={handleRemoveContactFromAudience}
                                sx={{
                                  color: "#FF9933",
                                  fontSize: 16,
                                  fontWeight: "bold"
                                }}
                                variant="text"
                            >
                              YES
                            </Button>
                            <Button
                                onClick={handleClose}
                                sx={{
                                  color: "#FF9933",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                }}
                                variant="text"
                            >
                              NO
                            </Button>
                          </>
                      )}

                  {(currentListType === "audiences" && !showRemoveFromAudience && !showDeleteFromCustomers)
                      && (
                          <>
                            <Button
                                onClick={handleShowRemoveFromAudienceMessage}
                                sx={{
                                  color: "#FF9933",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  marginRight: 4
                                }}
                                variant="text"
                            >
                              REMOVE
                            </Button>
                            <Button
                                variant="text"
                                startIcon={<DeleteForeverIcon
                                    sx={{fontSize: 80}}/>}
                                onClick={handleShowDeleteFromCustomersMessage}
                                sx={{
                                  color: "#EA5454",
                                  fontSize: 16,
                                  fontWeight: "bold"
                                }}
                            >
                              DELETE
                            </Button>
                          </>
                      )}
                </ButtonSection>
              </ConfirmationPlaceholder>
            </div>
          </Fade>
        </Modal>

        {((showSuccessfulDeleteMessage === true) || (showSuccessfulRemoveMessage
            === true)) && (
            <ConfirmDeleteCustomerModal
                selectedContactsIds={selectedContactsIds}
                showSuccessfulDeleteMessage={showSuccessfulDeleteMessage}
                setShowSuccessfulDeleteMessage={setShowSuccessfulDeleteMessage}
                showSuccessfulRemoveMessage={showSuccessfulRemoveMessage}
                setShowSuccessfulRemoveMessage={setShowSuccessfulRemoveMessage}
                showDeleteFromCustomers={showDeleteFromCustomers}
                setShowDeleteFromCustomers={setShowDeleteFromCustomers}
                {...props}
            />

        )}
      </div>
  );
};

export default DeleteContactsModal;
