import React from "react";

import styled from "@emotion/styled";

import useIsNavBarLocked from "utils/hooks/useIsNavBarLocked";

const PageTitleWrapper = styled.label`
  font-size: 32px;
  font-weight: 400;
  color: #a0a8b1;
  // opacity: ${(props) => (props.isNavBarLocked ? 0 : 1)};
  // transition: all 600ms cubic-bezier(0.42, 0, 0.58, 1);
`;

const PageTitle = (props) => {
  const isNavBarLocked = useIsNavBarLocked();

  const { title, style } = props;

  return (
    <PageTitleWrapper style={style} isNavBarLocked={isNavBarLocked}>
      {title}
    </PageTitleWrapper>
  );
};

export default PageTitle;
