import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import { setUserRequest } from "./actions";

import {
  selectIsLoading,
  selectErrorMessage,
  selectUser,
  selectUserOrganisation,
  selectUserOrganisationLicenses,
  selectIsSuperAdmin,
} from "./selectors";

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  errorMessage: selectErrorMessage,
  user: selectUser,
  userOrganisation: selectUserOrganisation,
  userOrganisationLicenses: selectUserOrganisationLicenses,
  isSuperAdmin: selectIsSuperAdmin,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUserRequest,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
