import api from "utils/api";

export const apiGetCampaigns = (data) => {
  const { organisationId, page, searchString } = data;

  const apiRoute = `/email/organisation/${organisationId}/campaign/paged?size=10&page=${
    page ? page : "0"
  }&name=${searchString ? searchString : ""}`;

  return api.get(apiRoute);
};
