import React from "react";
import styled from "@emotion/styled";
import {Envelope} from "phosphor-react";
import {Backdrop, Fade, Modal} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ToTestField from "../Fields/ToTestField";
import {Field} from "redux-form";
import {from} from "rxjs";
import api from "../../../../../../../utils/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "25px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
}));

const TestButtonWrapper = styled.div`
  background-color: #ffcc33;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  padding: 10px 20px;
  opacity: ${(props) => (props.isEnabled ? 1 : 0.5)};
  transform: ${(props) => (props.isEnabled ? "scale(1)" : "scale(0.95)")};
  pointer-events: ${(props) => (props.isEnabled ? "auto" : "none")};
  cursor: pointer;

  transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1);

  label {
    color: white;
    font-weight: 300;
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
`;

const ModalTitle = styled.h4`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
`;

const SendTestToInputModal = (props) => {
  const {
    // title,
    campaignFrom,
    // campaignPreviewText,
    // campaignSchedule,
    // organisationId,
    campaignSubject,
    campaignSendToTest,
    currentCampaign,
    sendTestEmailRequest,
    selectedCustomers,
    change,
    isEnabled,
    organisationId
  } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [sendTestBodyObj, setTestBodyObj] = React.useState(null);

  React.useEffect(() => {
    const obj = {};
    let selectedCustomersEmail = [];

    if (selectedCustomers) {
      Object.entries(selectedCustomers).map(([key, value]) => {
        selectedCustomersEmail = [...selectedCustomersEmail, value.email];
      });
    }

    if (campaignSendToTest) {
      obj.emailAddresses = selectedCustomersEmail;
    }

    if (campaignSubject) {
      obj.subject = campaignSubject;
    }

    if (currentCampaign && currentCampaign.template) {
      obj.htmlText = currentCampaign.template.unlayerDesignHtml;
    }

    setTestBodyObj(obj);
  }, [props]);

  const sendEmail = () => {
    const request = {
      organisationExternalId: organisationId,
      senderExternalId: campaignFrom.senderDomainId,
      recipients: [campaignSendToTest],
      subject: sendTestBodyObj.subject,
      messageBody: sendTestBodyObj.htmlText,
      unlayerDesign: null,
      schedule: null,
      test: true
    }
    from(api.post('/email', request))
    .subscribe({
      next: () => handleClose(),
      error: (err) => {
        console.error("Error sending email");
        console.error(err);
      }
    });
  }

  return (
      <div>
        <TestButtonWrapper onClick={handleOpen} isEnabled={isEnabled}>
          {/* react-transition-group */}
          <label>Send Test Email</label>
          <Envelope size={18} color={"white"}></Envelope>
        </TestButtonWrapper>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <div
                  className={"audienceBlock addContactBlock addContactBlockSuccess"}
              >
                <img
                    alt="contact successful"
                    onClick={handleClose}
                    className={"closeImg contactSuccessfulImg"}
                    src={require("assets/images/Group41.png")}
                />
                <div className={"successfulImport"}>
                  <ModalTitle>Send Test email to:</ModalTitle>
                  <Field
                      name="campaignSendToTest"
                      component={ToTestField}
                      type="text"
                      props={{
                        change,
                        //   className: "send-campaign",
                        fieldName: "campaignSendToTest",
                      }}
                  ></Field>

                  <TestButtonWrapper
                      isEnabled={campaignSendToTest &&
                          campaignSendToTest !== ""}
                      onClick={sendEmail}
                  >
                    <label>Send Test Email</label>
                    <Envelope size={18} color={"white"}></Envelope>
                  </TestButtonWrapper>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
  );
};

export default SendTestToInputModal;
