import React from "react";
import styled from "@emotion/styled";
import Iframe from "components/Iframe";

const PreviewTemplateWrapper = styled.div`
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 100%;
  min-height:200px;

  .dynamic-content-div {
    width: 100%;
    border: none;
  }
`;

const PreviewTemplate = ({templateContent}) => {
  return (
    <PreviewTemplateWrapper>
      <Iframe
        width="100%"
        height="100%"
        borderRadius="5px"
        className="dynamic-content-div"
        content={templateContent}
      ></Iframe>
    </PreviewTemplateWrapper>
  );
};

export default PreviewTemplate;
