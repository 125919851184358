import React from "react";

import styled from "@emotion/styled";

import WifiLoginPanelItem from "./WifiLoginPanelItem";
import uuid from "react-uuid";

const AudiencesNamesListWrapper = styled.div`
  flex: 1;
  background: #ffffff;
  height: 45%;
  width: 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  // opacity: ${(props) => (props.isThisPanelSelected ? 0.95 : 0.5)};

  transform: ${(props) =>
          props.isThisPanelSelected ? "scale(1)" : "scale(.98)"};
  transition: all 0.75s;
`;

const SegmentsListTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  // background: #ff9933;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  // z-index: 99999;

  label {
    font-family: Trebuchet MS, sans-serif !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    color: #FF9933 !important;
    line-height: 133.4% ;
  }
`;

const WiFiLoginListBodyWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #ff9933;
      /* visibility: ${(props) => (props.isHover ? "visible" : "hidden")}; */
  }
`;

const WiFiLoginList = (props) => {
  const {allAudiences, currentListType, listTypes} = props;

  const [isHover, setIsHover] = React.useState(false);

  const [isThisPanelSelected, setIsThisPanelSelected] = React.useState(false);

  React.useEffect(() => {
    setIsThisPanelSelected(currentListType === listTypes.AUDIENCES);
  }, [currentListType]);

  return (
      // <AudiencesNamesListWrapper
      <div
          isThisPanelSelected={isThisPanelSelected}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          isHover={isHover}
      >
        <SegmentsListTitle>
          <label>WiFi Login</label>
        </SegmentsListTitle>

        <WiFiLoginListBodyWrapper>

          <WifiLoginPanelItem/>

          {/*{allWifiLogin &&*/}
          {/*    Object.values(allAudiences).map((wifilogin) => {*/}
          {/*      const {color, id, name} = wifilogin;*/}
          {/*      return (*/}
          {/*          <WifiLoginPanelItem*/}
          {/*              key={uuid()}*/}
          {/*              id={id}*/}
          {/*              name={name}*/}
          {/*              color={color}*/}
          {/*              {...props}*/}
          {/*          />*/}
          {/*      );*/}
          {/*    })}*/}
        </WiFiLoginListBodyWrapper>
      </div>
  );
};

export default WiFiLoginList;
