import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import moment from "moment";
import TopWeekDaysHeader from "./TopWeekDaysHeader";

import CalendarBody from "./CalendarBody";
import MonthSelector from "./MonthSelector";

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 65%;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 10%;
  width:80%;
  
  position: relative;
 
`;

const Calendar = (props) => {
  const { setYear, campaigns } = props;

  const [value, setValue] = useState(moment());
  const [calendar, setCalendar] = useState([]);
  const [selectedDay, setSelectedDay] = useState();

  // https://momentjs.com/docs/#/customization/dow-doy/
  moment.updateLocale('en', {
    week: {
      dow : 1, // Monday is the first day of the week.
      doy: 4
    }
  });

  const startDay = value.clone().startOf("month").startOf("week");
  const endDay = value.clone().endOf("month").endOf("week");

  useEffect(() => {
    const day = startDay.clone().subtract(1, "day");
    const a = [];
    while (day.isBefore(endDay, "day")) {
      a.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, "day").clone())
      );
    }
    setCalendar(a);
  }, [value]);

  useEffect(() => {
    setYear(value.format("yyyy"));
  }, [value]);



  return (
    <CalendarWrapper>
      <TopWeekDaysHeader />

      {calendar && (
        <CalendarBody
          value={value}
          calendar={calendar}
          campaigns={campaigns}
          setValue={setValue}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        />
      )}

      <MonthSelector setValue={setValue} value={value}/>
    </CalendarWrapper>
  );
};

export default Calendar;
