import React, {useEffect, useState} from "react";
import "assets/css/design/screens/instagram-redirect-screen.scss";

function stripTrailingSlash(site) {
  return site.replace(/\/$/, '');
}

const InstagramRedirectScreen = (props) => {
  const {settings} = props;

  const [instagramUrl, setInstagramUrl] = useState(null);
  useEffect(() => {
    setInstagramUrl(
        settings?.redirectPage?.embedCode
            ? `${stripTrailingSlash(settings?.redirectPage?.embedCode)}/embed`
            : 'https://www.instagram.com/p/CZe8cicNpqP/embed'
    );
  }, [settings]);

  return (
      <div className="instagram-redirect">
        {settings?.logoImage?.url ? (
            <img
                className="instagram-redirect__logo"
                style={{width: "100%", height: 85}}
                src={settings?.logoImage?.url}
                alt=""
                role="presentation"
            />
        ) : (
            <div
                style={{width: "100%", height: 85}}
                className="instagram-redirect__logo"
                role="presentation"
            />
        )}
        <div className="instagram-redirect__instagram-preview">
          <iframe
              title="instagram redirect"
              style={{marginTop: '10px'}}
              src={instagramUrl}
              height={450}
              width='95%'
              frameBorder="0"
              scrolling="auto"
          />
        </div>
        <button
            className="instagram-redirect__button"
            style={{
              color: settings?.redirectPage?.instagramButtonTextColor
                  ? settings?.redirectPage?.instagramButtonTextColor
                  : "white",
              backgroundColor: settings?.redirectPage?.instagramButtonColor
                  ? settings?.redirectPage?.instagramButtonColor
                  : "#A41C4A",
            }}
        >
          {settings?.redirectPage?.instagramButtonText
              ? settings?.redirectPage?.instagramButtonText
              : "Continue To Wifi"}
        </button>
      </div>
  );
};

export default InstagramRedirectScreen;
