import React from "react";

import { ReactComponent as CloseIcon } from "assets/images/delete.svg";

import AddDomainPopupWrapper from "./AddDomainPopupWrapper";

import ChooseVerificationStep from "./steps/ChooseVerificationStep";
import AddCnameStep from "./steps/AddCnameStep";

const AddDomainPopup = (props) => {
  const {
    close,
    currentVerificationType,
    setCurrentVerificationType,
    hasModalBeenOpened,
    createdIdentity,
    setHasModalBeenOpened,
    currentIdentityDomainRecords,
    selectIdentityRequest,
  } = props;

  const [isAddCnameStep, setIsAddCnameStep] = React.useState(false);

  React.useEffect(() => {
    // componentWillUnmount
    return () => {
      selectIdentityRequest(null);
    };
  }, []);

  React.useEffect(() => {
    setHasModalBeenOpened(true);
  }, []);

  React.useEffect(() => {
    if (hasModalBeenOpened === true && currentVerificationType === "domain") {
      setIsAddCnameStep(true);
    }
  }, [hasModalBeenOpened, currentVerificationType, createdIdentity]);

  const onClick = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <AddDomainPopupWrapper onClick={onClick}>
      <div className="verify-domain-popup-content">
        <CloseIcon className="close-btn" onClick={close} />

        {!isAddCnameStep ? (
          <ChooseVerificationStep
            {...props}
            setCurrentVerificationType={setCurrentVerificationType}
            currentVerificationType={currentVerificationType}
            isAddCnameStep={isAddCnameStep}
            setIsAddCnameStep={setIsAddCnameStep}
          />
        ) : (
          currentIdentityDomainRecords && <AddCnameStep {...props} />
        )}
      </div>
    </AddDomainPopupWrapper>
  );
};

export default AddDomainPopup;
