import React, {useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import {deleteAutomation} from "../AutomationService";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

type Props = {
  selected: string[]
  fetchAutomations: () => void
  sx?: SxProps<Theme>
}

const DeleteAutomationDialog = (props: Props) => {
  const {selected, fetchAutomations, sx} = props;

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(selected ? selected.length === 0 : false);
  }, [selected]);

  const onClickDelete = () => {
    if (selected) {
      deleteAutomation(selected)
      .subscribe({
        next: () => {
          setOpen(false);
          fetchAutomations();
        },
        error: (err: any) => {
          //TODO Handle errors
          console.error('Error');
          console.error(err);
        },
      });
    }
  }

  return (
      <>
        <Button
            disabled={disabled}
            variant='outlined'
            color='error'
            onClick={() => setOpen(true)}
            sx={sx}
        >
          Delete
        </Button>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {selected ? selected.length : 0} items selected
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={onClickDelete} autoFocus color='error'>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default DeleteAutomationDialog;