import React from "react";
import styled from "@emotion/styled";
import uuid from "react-uuid";

const StepsNumberButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;

  .cec-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 20px;
    background-color: #c4c4c4;

    label {
      font-size: 16px;
      font-family: Roboto;
      font-weight: 900;
      color: white;
    }
  }
`;

const StepsNumberButtons = (props) => {
  const {steps, currentStep} = {props};

  return (
      <StepsNumberButtonsWrapper>
        {steps && steps
        //.filter((s) => s.id < 5)
        .map((s) => (
            <div
                key={uuid()}
                className="cec-step"
                style={{
                  border:
                      currentStep < steps.length && s.id === currentStep
                          ? "5px solid #EA5454"
                          : "none",
                  backgroundColor:
                      s.id <= currentStep ? s.selectedColor : "#C4C4C4",
                }}
            >
              <label>{s.id + 1}</label>
            </div>
        ))}
      </StepsNumberButtonsWrapper>
  );
};

// @ts-ignore
export default StepsNumberButtons;
