import actions from "../actions";

import _ from "lodash";

const {
  GET_FEATURED_TEMPLATES_REQUEST,
  GET_FEATURED_TEMPLATES_FAILURE,
  GET_FEATURED_TEMPLATES_SUCCESS,
  GET_SAVED_TEMPLATES_REQUEST,
  GET_SAVED_TEMPLATES_FAILURE,
  GET_SAVED_TEMPLATES_SUCCESS,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_FAILURE,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_FEATURED_TEMPLATE_REQUEST,
  CREATE_FEATURED_TEMPLATE_FAILURE,
  CREATE_FEATURED_TEMPLATE_SUCCESS,
  RESET_ALL_VALUES_REQUEST,
  RESET_ALL_VALUES_FAILURE,
  RESET_ALL_VALUES_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  featuredTemplates: null,
  savedTemplates: null,
  createdTemplate: null,
  createdFeaturedTemplate: null,
  featuredTemplatesPagination: null,
  savedTemplatesPagination: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FEATURED_TEMPLATES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_FEATURED_TEMPLATES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_FEATURED_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        featuredTemplates: action.payload.content,
        featuredTemplatesPagination: { ..._.omit(action.payload, ["content"]) },
      };
    }

    //---

    case GET_SAVED_TEMPLATES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_SAVED_TEMPLATES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        savedTemplates: null,
      };
    }

    case GET_SAVED_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        savedTemplates: action.payload.content,
        savedTemplatesPagination: { ..._.omit(action.payload, ["content"]) },
      };
    }

    //---

    // case EXPORT_TEMPLATE_TO_IMG_REQUEST: {
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // }

    // case EXPORT_TEMPLATE_TO_IMG_FAILURE: {
    //   return {
    //     ...state,
    //     loading: false,
    //     errorMessage: action.payload,
    //   };
    // }

    // case EXPORT_TEMPLATE_TO_IMG_SUCCESS: {
    //   return {
    //     ...state,
    //     loading: false,
    //     errorMessage: null,
    //   };
    // }

    //---

    case CREATE_TEMPLATE_REQUEST: {
      return {
        ...state,
        loading: true,
        createdTemplate: null,
      };
    }

    case CREATE_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        createdTemplate: null,
      };
    }

    case CREATE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        createdTemplate: action.payload,
      };
    }

    //---

    case CREATE_FEATURED_TEMPLATE_REQUEST: {
      return {
        ...state,
        loading: true,
        createdFeaturedTemplate: null,
      };
    }

    case CREATE_FEATURED_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        createdFeaturedTemplate: null,
      };
    }

    case CREATE_FEATURED_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        createdFeaturedTemplate: action.payload.createdFeaturedTemplate,
      };
    }

    //---

    case RESET_ALL_VALUES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case RESET_ALL_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case RESET_ALL_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        // featuredTemplates: null,
        savedTemplates: null,
        createdTemplate: null,
        createdFeaturedTemplate: null,
        // featuredTemplatesPagination: null,
        savedTemplatesPagination: null,
      };
    }

    default:
      return state;
  }
}
