import React from "react";
import Chip from "@mui/material/Chip";

const ContactListChip = ({ label, color, style }) => {
    return (
        <Chip
        label={label}
        color={color}
        variant="filled"
        size={"small"}
        style={style}
      />
    );
  };
  
  export default ContactListChip;
  