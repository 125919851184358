import actions from "../actions";
import _ from "lodash";

const {
  START_LANDING_PAGE_REQUEST,
  START_LANDING_PAGE_FAILURE,
  START_LANDING_PAGE_SUCCESS,
  UPDATE_LANDING_PAGE_REQUEST,
  UPDATE_LANDING_PAGE_FAILURE,
  UPDATE_LANDING_PAGE_SUCCESS,
  UPDATE_LANDING_PAGE_STEP_REQUEST,
  UPDATE_LANDING_PAGE_STEP_FAILURE,
  UPDATE_LANDING_PAGE_STEP_SUCCESS,
  GET_LANDING_PAGE_REQUEST,
  GET_LANDING_PAGE_FAILURE,
  GET_LANDING_PAGE_SUCCESS,
  GET_LANDING_PAGES_REQUEST,
  GET_LANDING_PAGES_FAILURE,
  GET_LANDING_PAGES_SUCCESS,
  DELETE_LANDING_PAGE_REQUEST,
  DELETE_LANDING_PAGE_FAILURE,
  DELETE_LANDING_PAGE_SUCCESS,
  SAVE_LANDING_PAGE_REQUEST,
  SAVE_LANDING_PAGE_FAILURE,
  SAVE_LANDING_PAGE_SUCCESS,
  UPLOAD_MEDIA_TO_HOST_REQUEST,
  UPLOAD_MEDIA_TO_HOST_FAILURE,
  UPLOAD_MEDIA_TO_HOST_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  landingPages: null,
  newLandingPage: null,
  currentLandingPage: null,
  currentLandingPageStep: 1,
  deletedLandingPage: null,
  savedLandingPage: null,
  uploadedMediaData: null,
};

export default function landingPageReducer(state = initialState, action) {
  switch (action.type) {
    case START_LANDING_PAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case START_LANDING_PAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case START_LANDING_PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        newLandingPage: action.payload.newLandingPageId,
        currentLandingPage: action.payload.landingPageRequest,
      };
    }

      //---

    case UPDATE_LANDING_PAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case UPDATE_LANDING_PAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case UPDATE_LANDING_PAGE_SUCCESS: {
      const {landingPageField, landingPageData} = action.payload;

      const value = typeof landingPageData === 'symbol'
          ? landingPageData.description
          : landingPageData;

      const currentLandingPage = state.currentLandingPage
          ? state.currentLandingPage : {};
      _.set(currentLandingPage, landingPageField, value);

      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentLandingPage: {
          ...currentLandingPage
        },
      };
    }

      //---

    case UPDATE_LANDING_PAGE_STEP_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case UPDATE_LANDING_PAGE_STEP_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case UPDATE_LANDING_PAGE_STEP_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentLandingPageStep: action.payload,
      };
    }

      //---

    case GET_LANDING_PAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_LANDING_PAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_LANDING_PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentLandingPage: action.payload,
      };
    }

      //---

    case GET_LANDING_PAGES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_LANDING_PAGES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_LANDING_PAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        landingPages: action.payload,
      };
    }

    case DELETE_LANDING_PAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case DELETE_LANDING_PAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case DELETE_LANDING_PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        deletedLandingPage: action.payload,
      };
    }

      //---

    case SAVE_LANDING_PAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case SAVE_LANDING_PAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SAVE_LANDING_PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        savedLandingPage: action.payload,
      };
    }

    case UPLOAD_MEDIA_TO_HOST_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case UPLOAD_MEDIA_TO_HOST_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case UPLOAD_MEDIA_TO_HOST_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        uploadedMediaData: action.payload,
      };
    }
    default:
      return state;
  }
}
