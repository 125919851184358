import React, { useState } from "react";

import { ReactComponent as CloseIcon } from "../../assets/images/delete.svg";
import { ReactComponent as AddIcon } from "../../assets/images/plusIcon.svg";

import "../../assets/css/widget/tags-input.scss";

export default ({
  tags,
  setTags,
  classes,
  hasSecondaryBackground,
  hasAddIcon,
}) => {
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
    setInputValue("");
  };

  const removeTag = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const onKey = (e) => {
    if (e.key === "Enter") addTags(e);
    else if (e.key === "Backspace" && value.length === 0) {
      tags.pop();
      setTags([...tags]);
    }
    setValue(e.target.value);
  };

  const addButtonPress = () => {
    setTags([...tags, inputValue]);
    setInputValue("");
  };

  return (
    <div className={`tags-input ${classes}`}>
      <ul id="tags">
        {tags.map((tag, index) => (
          <li className="tag-input-tag" key={index}>
            <span className="tag-title">{tag}</span>
            <CloseIcon
              className="close-icon"
              onClick={() => removeTag(index)}
            />
          </li>
        ))}
      </ul>
      <div className="tag-input-container">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          id={`tag-input-secondary--${hasSecondaryBackground}`}
          placeholder={hasAddIcon ? '' : "Press enter to add tag"}
          onKeyUp={onKey}
        />
        {hasAddIcon &&  <AddIcon
          tabIndex="0"
          className="tag-add-icon"
          onKeyUp={() => addButtonPress()}
          onClick={() => addButtonPress()}
        />}
      </div>
    </div>
  );
};
