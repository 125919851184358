export const GET_YEAR_VISITS_REQUEST = "GET_YEAR_VISITS_REQUEST";
export const GET_YEAR_VISITS_FAILURE = "GET_YEAR_VISITS_FAILURE";
export const GET_YEAR_VISITS_SUCCESS = "GET_YEAR_VISITS_SUCCESS";

export const GET_MONTH_VISITS_REQUEST = "GET_MONTH_VISITS_REQUEST";
export const GET_MONTH_VISITS_FAILURE = "GET_MONTH_VISITS_FAILURE";
export const GET_MONTH_VISITS_SUCCESS = "GET_MONTH_VISITS_SUCCESS";

export const GET_WEEK_VISITS_REQUEST = "GET_WEEK_VISITS_REQUEST";
export const GET_WEEK_VISITS_FAILURE = "GET_WEEK_VISITS_FAILURE";
export const GET_WEEK_VISITS_SUCCESS = "GET_WEEK_VISITS_SUCCESS";

export const GET_DAY_VISITS_REQUEST = "GET_DAY_VISITS_REQUEST";
export const GET_DAY_VISITS_FAILURE = "GET_DAY_VISITS_FAILURE";
export const GET_DAY_VISITS_SUCCESS = "GET_DAY_VISITS_SUCCESS";

//--- YEAR

export const getYearVisitsRequest = (organisationId) => ({
  type: GET_YEAR_VISITS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getYearVisitsFailure = (errorMessage) => ({
  type: GET_YEAR_VISITS_FAILURE,
  payload: errorMessage,
});

export const getYearVisitsSuccess = (yearVisits) => ({
  type: GET_YEAR_VISITS_SUCCESS,
  payload: yearVisits,
});

//--- MONTH

export const getMonthVisitsRequest = (organisationId) => ({
  type: GET_MONTH_VISITS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getMonthVisitsFailure = (errorMessage) => ({
  type: GET_MONTH_VISITS_FAILURE,
  payload: errorMessage,
});

export const getMonthVisitsSuccess = (monthVisits) => ({
  type: GET_MONTH_VISITS_SUCCESS,
  payload: monthVisits,
});

//--- WEEK

export const getWeekVisitsRequest = (organisationId) => ({
  type: GET_WEEK_VISITS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getWeekVisitsFailure = (errorMessage) => ({
  type: GET_WEEK_VISITS_FAILURE,
  payload: errorMessage,
});

export const getWeekVisitsSuccess = (weekVisits) => ({
  type: GET_WEEK_VISITS_SUCCESS,
  payload: weekVisits,
});

//--- DAY

export const getDayVisitsRequest = (organisationId) => ({
  type: GET_DAY_VISITS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getDayVisitsFailure = (errorMessage) => ({
  type: GET_DAY_VISITS_FAILURE,
  payload: errorMessage,
});

export const getDayVisitsSuccess = (dayVisits) => ({
  type: GET_DAY_VISITS_SUCCESS,
  payload: dayVisits,
});
