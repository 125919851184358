import React from "react";

import {ReactComponent as FacebookIcon} from "assets/images/roundFacebook.svg";
import {ReactComponent as TwitterLogo}  from "assets/images/roundTwitter.svg";
import {ReactComponent as LinkedInLogo} from "assets/images/roundLinkedin.svg";
import {ReactComponent as MailIcon} from "assets/images/roundMail.svg";
import {SocialMedia} from "../../../../../../../../containers/Connect/types";

const getPrimarySocialMediaButton = (socialMedia: SocialMedia) => {
  switch (socialMedia) {
    case SocialMedia.Facebook:
      return (
          <FacebookIcon
              width="30px"
              height="30px"
              fill='#ffffff'
              style={{margin: "auto"}}
          />
      );
    case SocialMedia.Linkedin:
      return (
          <LinkedInLogo
              width="30px"
              height="30px"
              fill='#ffffff'
              style={{margin: "auto"}}
          />
      );
    case SocialMedia.Twitter:
      return (
          <TwitterLogo
              width="30px"
              height="30px"
              fill='#ffffff'
              style={{margin: "auto"}}
          />
      );
    case SocialMedia.Email:
      return (
          <MailIcon
              width="25px"
              height="25px"
              fill='#ffffff'
              style={{margin: "auto", marginTop: "5px"}}
          />
      );
    default:
      return (
          <FacebookIcon
              width="30px"
              height="30px"
              fill='#ffffff'
              style={{margin: "auto"}}
          />
      );
  }
};

export default getPrimarySocialMediaButton;
