import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import actions from "./actions";

import {
  selectIsLoading,
  selectErrorMessage,
  selectSelectedOrganisation,
  selectSelectedOrganisationLicenses,
  selectSelectedOrganisationVenues,
  selectSelectedOrganisationSummary,
  selectSelectedOrganisationCampaigns,
  selectSelectedVenue,
  selectSelectedVenueSummary,
  selectSelectedVenueCampaigns,
  selectSelectedOrganisationActiveLicenses,
  selectSelectedOrganisationHasVenues,
  selectSelectedEntity,
  selectSelectedEntitySummary,
} from "./selectors";

const {
  selectOrganisationRequest,
  exitOrganisationRequest,
  getSelectedOrganisationLicensesRequest,
  getSelectedOrganisationVenuesRequest,
  getSelectedOrganisationSummaryRequest,
  getSelectedOrganisationCampaignsRequest,
  selectVenueRequest,
  getSelectedVenueSummaryRequest,
  getSelectedVenueCampaignsRequest,
  selectEntityRequest,
  clearEntityRequest,
  getSelectedEntitySummaryRequest,
} = actions;

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  errorMessage: selectErrorMessage,
  selectedOrganisation: selectSelectedOrganisation,
  selectedOrganisationLicenses: selectSelectedOrganisationLicenses,
  selectedOrganisationVenues: selectSelectedOrganisationVenues,
  selectedOrganisationSummary: selectSelectedOrganisationSummary,
  selectedOrganisationCampaigns: selectSelectedOrganisationCampaigns,
  selectedVenue: selectSelectedVenue,
  selectedVenueSummary: selectSelectedVenueSummary,
  selectedVenueCampaigns: selectSelectedVenueCampaigns,
  selectedOrganisationActiveLicenses: selectSelectedOrganisationActiveLicenses,
  selectedOrganisationHasVenues: selectSelectedOrganisationHasVenues,
  selectedEntity: selectSelectedEntity,
  selectedEntitySummary: selectSelectedEntitySummary,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectOrganisationRequest,
      exitOrganisationRequest,
      getSelectedOrganisationLicensesRequest,
      getSelectedOrganisationVenuesRequest,
      getSelectedOrganisationSummaryRequest,
      getSelectedOrganisationCampaignsRequest,
      selectVenueRequest,
      getSelectedVenueSummaryRequest,
      getSelectedVenueCampaignsRequest,
      selectEntityRequest,
      clearEntityRequest,
      getSelectedEntitySummaryRequest,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
