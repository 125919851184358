import React, { useState } from "react";

import api from "utils/api";
import { compose } from "recompose";
// import useUserOrganisation from "utils/hooks/useUserOrganisation";
// import OrganisationsContainer from "containers/Organisations";

import NetworkContainer from "containers/Network";
import SelectedOrganisationContainer from "containers/SelectedOrganisation";
import NetworkItem from "./NetworkItem";
import { useHistory } from "react-router-dom";
import PageNavigator from "components/PageNavigator";
import { PageNavigationContext } from "context/navigationContext";
// import DeletePopup from "components/buttonedPopup";
import ExportPopup from "components/exportPopup";
import SuccessPopup from "components/successPopup";
import FileSaver from "file-saver";
import ConfirmDeleteCustomerModal from "./ConfirmDeleteCustomerModal";

import "assets/css/network.scss";

const Network = (props) => {
  const {
    organisationId,
    getNetworkOrganisationsRequest,
    getNetworkIndustriesRequest,
    networkOrganisations,
    networkIndustries,
    networkPagination,
    selectOrganisationRequest,
    setShowPopup,
    deleteNetworkOrganisationRequest,
    isNetworkDeletedSuccessfully,
  } = props;

  const history = useHistory();

  const [networks, setNetworks] = useState([]);
  const [name, setName] = useState("");
  const [navigation, setNavigation] = useState({ total: 1, current: 0 });
  const [deleteCustomer, setDeleteCustomer] = useState();
  const [showExportPopup, setShowExportPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [industries, setIndustries] = useState([]);

  const [networkCurrentPage, setNetworkCurrentPage] = React.useState(0);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    React.useState(false);

  const [selectedNetwork, setSelectedNetwork] = React.useState(null);

  const prevPage = (networkCurrentPage) => {
    networkCurrentPage > 0 && setNetworkCurrentPage(networkCurrentPage - 1);
  };

  const nextPage = (networkCurrentPage) => {
    networkCurrentPage < networkPagination.totalPages - 1 &&
      setNetworkCurrentPage(networkCurrentPage + 1);
  };

  const goToPage = (currPage, nextPageNumber) => {
    if (currPage !== nextPageNumber) {
      setNetworkCurrentPage(nextPageNumber);
    }
  };

  React.useEffect(() => {
    getNetworkIndustriesRequest();
  }, []);

  React.useEffect(() => {
    networkIndustries && setIndustries(networkIndustries);
  }, [networkIndustries]);

  React.useEffect(() => {
    getNetworkOrganisationsRequest(organisationId, 0, "");
  }, []);

  React.useEffect(() => {
    getNetworkOrganisationsRequest(organisationId, networkCurrentPage, name);
  }, [networkCurrentPage]);

  React.useEffect(() => {
    getNetworkOrganisationsRequest(organisationId, 0, name);
  }, [name]);

  const filter = (e) => {
    if (e.target.value.length > 2) setName(e.target.value);
    else setName("");
  };

  // const deleteNetwork = async () => {
  //   await api.delete(`/organisations/${deleteCustomer.externalId}`);
  //   setNetworks(
  //     networks.filter((n) => n.externalId !== deleteCustomer.externalId)
  //   );
  //   setDeleteCustomer(null);
  // };

  const exportCustomers = async (fields) => {
    const { data } = await api.post(`/organisations/export/csv`, {
      fields: fields.includes("BUSINESS_NAME")
        ? [fields[0], "INDUSTRY", ...fields.slice(1)]
        : fields,
      industries: networkIndustries
        .filter((i) => i.selected)
        .map((i) => i.externalId),
    });
    const csvData = new Blob([data], { type: "text/csv;charset=utf-8;" });
    const today = new Date();
    FileSaver.saveAs(
      csvData,
      `Unaro Dashboard Export ${today.getDate()}/${today.getMonth()}/${today.getFullYear()}.csv`
    );
    setShowExportPopup(false);
    setShowSuccessPopup(true);
    setIndustries(industries.map((i) => ({ ...i, selected: false })));
  };

  const closeExport = () => {
    setShowExportPopup(false);
    setIndustries(industries.map((i) => ({ ...i, selected: false })));
  };

  return (
    <div className="network-page">
      <label className="network-title">Network</label>
      <div className="actions">
        <input
          type="search"
          className="search"
          placeholder="Search..."
          onChange={(e) => filter(e)}
        />
        {networkPagination && (
          <div className="navigation">
            <PageNavigationContext.Provider
              value={{ navigation, setNavigation }}
            >
              <PageNavigator
                totalPages={networkPagination.totalPages}
                currentPage={networkPagination.page}
                prevPage={prevPage}
                nextPage={nextPage}
                goToPage={goToPage}
                // changePage={(page) => getNetworkOrganisationsRequest(page)}
              />
            </PageNavigationContext.Provider>
          </div>
        )}
        <div className="right">
          <button
            className="export-btn"
            onClick={() => setShowExportPopup(true)}
          >
            Export
          </button>
          <button
            className="add-btn"
            onClick={() => history.push("/organisation")}
          ></button>
        </div>
      </div>
      <div className="network-grid">
        {networkOrganisations &&
          networkOrganisations.map((network) => {
            return (
              <div key={network.externalId} className="network-item">
                <NetworkItem
                  {...props}
                  setSelectedNetwork={setSelectedNetwork}
                  network={network}
                  setShowPopup={setShowPopup}
                  setShowConfirmDeleteModal={setShowConfirmDeleteModal}
                  setNetwork={(updated) =>
                    setNetworks(
                      networks.map((n) =>
                        n.externalId === updated.externalId ? updated : n
                      )
                    )
                  }
                  // selectNetwork={selectNetwork}
                  deleteNetwork={(customer) => setDeleteCustomer(customer)}
                  selectOrganisationRequest={selectOrganisationRequest}
                />
              </div>
            );
          })}
      </div>

      {showConfirmDeleteModal && (
        <ConfirmDeleteCustomerModal
          showConfirmDeleteModal={showConfirmDeleteModal}
          setShowConfirmDeleteModal={setShowConfirmDeleteModal}
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
          deleteNetworkOrganisationRequest={deleteNetworkOrganisationRequest}
          isNetworkDeletedSuccessfully={isNetworkDeletedSuccessfully}
        />
      )}
      {showExportPopup && (
        <ExportPopup
          close={closeExport}
          industries={industries}
          setIndustries={setIndustries}
          exportCustomers={exportCustomers}
        />
      )}
      {showSuccessPopup && (
        <SuccessPopup
          message="Export Successful!"
          close={() => setShowSuccessPopup(false)}
        />
      )}
    </div>
  );
};

export default compose(
  NetworkContainer,
  SelectedOrganisationContainer
)(Network);
