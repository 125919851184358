import React, { useState } from 'react'

import { ReactComponent as DropdownLogo } from '../../../assets/images/dropdown-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/delete.svg';

import '../../../assets/css/sms/contact/tags-drop-down.scss'

export default ({ options = [], tags, setTags, placeholder, onSelect, className, height='20px' }) => {
    const [open, setOpen] = useState(false)

    const toggleOpen = () => {
        if (options.length > 0)
            setOpen(!open)
    }

    const changeSelected = option => {
        setOpen(false)
        setTags([...tags, option])
    }

    const removeTag = (e, tag) => {
        e.stopPropagation()
        setTags(tags.filter(ct => ct.externalId !== tag.externalId))
    }

    return (
        <div className={`tags-dropdown-container ${className}`} tabIndex="0" onBlur={() => setOpen(false)}>
            <div className={`dropdown ${open && 'dropdown-open'}`} onClick={() => toggleOpen()} style={{ padding: tags.length > 0 ? '2px 20px 2px 2px' : '5px 20px', height: tags.length > 0 ? 'fit-content' : height }} >
                {tags && tags.length > 0 ?
                    <div className='contact-tags'>
                        {
                            tags.map(tag =>
                                <div className='contact-tag' style={{ backgroundColor: tag.color, borderColor: tag.color === '#ffffff' ? '#C4C4C4' : tag.color }} onClick={e => removeTag(e, tag)}>
                                    <label style={{ color: tag.color === '#ffffff' ? 'black' : 'white' }}>{tag.name}</label>
                                    <CloseIcon className={`remove-icon ${tag.color === '#ffffff' && 'remove-icon-black'}`}  />
                                </div>
                            )}
                    </div>
                    :
                    <label>{placeholder}</label>
                }
                <div>
                    <DropdownLogo className='logo' />
                </div>
            </div>
            {open &&
                <div className='options'>
                    {options.map(option => <label key={option.externalId} onClick={() => changeSelected(option)}>{option.name}</label>)}
                </div>
            }
        </div>
    )
}