import React from 'react'

import '../../assets/css/widget/text-switch.scss'

import { ReactComponent as DropdownLogoDown } from '../../assets/images/dropdown-icon-down.svg';
import { ReactComponent as DropdownLogoUp } from '../../assets/images/dropdown-icon-up.svg';


export const TextSwitch = ({ title, value, setValue, values }) => {

    return (
        <div className='text-switch'>
            <label className='text-switch-label'>{title}</label>
            <div className='text-switch-actions' onClick={() => setValue(value === values[0] ? values[1] : values[0])}>
                <label className='text-switch-value'>{value || '' }</label>
                <div className='text-switch-buttons'>
                    <DropdownLogoUp className='text-switch-button' />
                    <DropdownLogoDown className='text-switch-button' />
                </div>
            </div>
        </div>
    )
}
