import actions from "../actions";

const {
  SEND_VERIFICATION_CODE_REQUEST,
  SEND_VERIFICATION_CODE_FAILURE,
  SEND_VERIFICATION_CODE_SUCCESS,
  SEND_TEST_EMAIL_REQUEST,
  SEND_TEST_EMAIL_FAILURE,
  SEND_TEST_EMAIL_SUCCESS,
  UPDATE_CURRENT_STEP_REQUEST,
  UPDATE_CURRENT_STEP_FAILURE,
  UPDATE_CURRENT_STEP_SUCCESS,
  RESET_ALL_VALUES_REQUEST,
  RESET_ALL_VALUES_FAILURE,
  RESET_ALL_VALUES_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  isSent: null,
  isEmailVerified: false,
  needsVerification: true,
  isEmailVerificationFailing: false,
  testEmail: null,
  campaignPending: false,
  testEmailSent: false,
  currentStep: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SEND_VERIFICATION_CODE_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case SEND_VERIFICATION_CODE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SEND_VERIFICATION_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    }

    //---

    case SEND_TEST_EMAIL_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case SEND_TEST_EMAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SEND_TEST_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        testEmailSent: true,
      };
    }

    //---

    case UPDATE_CURRENT_STEP_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case UPDATE_CURRENT_STEP_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case UPDATE_CURRENT_STEP_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentStep: action.payload.step,
      };
    }

    //---

    case RESET_ALL_VALUES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case RESET_ALL_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case RESET_ALL_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        isSent: null,
        isEmailVerified: false,
        needsVerification: true,
        isEmailVerificationFailing: false,
        testEmail: null,
        campaignPending: false,
        testEmailSent: false,
        currentStep: 0,
      };
    }

    default:
      return state;
  }
}
