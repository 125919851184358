import React, {useEffect, useRef, useState} from "react";
import {reduxForm} from "redux-form";
import {compose} from "recompose";
import {from} from 'rxjs';
import {apiCreateAudience} from "../../../../../../containers/ContactsList/api";
import {toast} from "react-toastify";
import {
  Backdrop,
  Button,
  Fade,
  ListItemIcon,
  Modal,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FieldsRow from "../CreateContactModal/FieldsRow";
import TextField from '@mui/material/TextField';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import styled from "@emotion/styled";
import group41Png from "../../../../../../assets/images/Group41.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import CustomDataGrid from "../../../../../UI/Customer/ContactList";
import {randomColor, randomId} from "@mui/x-data-grid-generator";
import ContactListAvatar from "../../../../../Avatar/ContactListAvatar";
import ContactListChip from "../../../../../Chips/ContactListChip";
import {useDispatch} from "react-redux";
import ImportListModal from "../CreateContactModal/Modals/ImportListModal";
import Picker, {SKIN_TONE_MEDIUM_DARK} from 'emoji-picker-react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import {UpdateRounded} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "25px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
  customTextField: {
    // input label when focused
    "& label.Mui-focused": {
      color: "orange"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      width: {sm: 250, md: 550},
      "&.Mui-focused fieldset": {
        borderColor: "orange"
      },
      "& > fieldset": {
        borderRadius: 25,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange"
      }
    },
  }
}));

const CreateAudienceModalWrapper = styled.form`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 565px;
  height: 560px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const SelectContactsModalWrapper = styled.form`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 565px;
  height: 600px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const TypographyText = styled.div`
  height: 32px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const TypographyTextContactExist = styled.div`
  height: 32px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const NewAudienceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  position: absolute;
  height: 266px;
  left: 36px;
  right: 36px;
  top: 60px;
`;

const ConfirmationPlaceholder = styled.div`
  position: relative;
  width: 565px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const WarningWrapper = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
`;

const BulkUploadContainer = styled.div`
  position: relative;
  width: 565px;
  height: 413px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const BulkUploadFrameContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 59px 70px;
  gap: 10px;
  position: absolute;
  left: 36px;
  right: 35.89px;
  top: 91.85px;
  bottom: 106.65px;
  border-radius: 16px;
`;

const ConfirmationAudienceCreated = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 565px;
  height: 231px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const ConfirmationAddContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 565px;
  height: 120px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const SelectContactsContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;
  width: 501px;
  height: 568px;
  margin-left: 35%;
`;

const ImportContactsContainer = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
  margin-left: 35%;
  margin-bottom: 25%;
  width: 501px;
  height: 30px;
`;

const ImageDiv = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
  top: 43.8px;
`;

const HeaderImage = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(46, 125, 50, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const HeaderWarningAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(255, 153, 51, 0.12);
  border-radius: 50%;
  display: inline-block;
`;

const HeaderErrorAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(234, 84, 84, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

// const TypographyTextAudienceCreated = styled.div`
//   position: absolute;
//   height: 32px;
//   left: 44.5px;
//   right: 44.5px;
//   top: 170.81px;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 24px;
//   line-height: 133.4%;
//   text-align: center;
//   color: rgba(15, 15, 15, 0.87);
// `;

// const TypographyTextAddContact = styled.div`
//   width: 476px;
//   height: 32px;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 24px;
//   line-height: 133.4%;
//   text-align: center;
//   color: rgba(15, 15, 15, 0.87);
// `;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  // margin-left: 15%;
  // gap: 24px;
  color: #ff9933;
  width: 120px;
  height: 42px;
  margin-top: 5%;
`;

const columns = [
  {
    field: "id",
    generateData: () => randomId,
    hide: true,
  },
  {
    field: "phones",
    hide: true,
  },
  {
    disableExport: true,
    field: "fullName",
    filterable: false,
    headerName: "Name",
    width: 300,
    flex: 1,
    renderCell: (params) => {
      return <ContactListAvatar name={params.row.fullName}/>;
    },
    generateData: () => randomColor,
    sortable: false,
  },
  {
    field: "allowEmail",
    headerName: "Email Opt-In",
    width: 150,
    editable: false,
    headerAlign: "Left",
    align: "left",
    type: "singleSelect",
    selectable: false,
    flex: 1,
    renderCell: (params) => {
      return (
          <div>
            {params.row.allowEmail ? (
                <ContactListChip
                    label={"Yes"}
                    color={"success"}
                    style={{
                      backgroundColor: "#1DB250",
                      justifyContent: "right"
                    }}
                />
            ) : (
                <ContactListChip label={"No"} color={"default"}/>
            )}
          </div>
      );
    },
  },
];

let contactsRows = [];
let contactsData = [];
let initialState = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};

contactsData.columns = columns;
contactsData.initialState = initialState;
contactsData.rows = contactsRows;

const CreateAudiencesModal = (props) => {
  const {
    organisationId,
    getAudiencesRequest,
    selectedContactsIds,
    loadContacts,
    currentPagination,
    setPage,
    currentListType,
    setSelectedContactsIds,
    contactsList,
    currentCampaign,
    allContactsIds,
    setSelectedCustomers,
    setIsAllContactsSelected,
    openAddNewAudience,
    setOpenAddNewAudience
  } = props;

  const dispatch = useDispatch();

  const PAGE_SIZE = 5;
  const classes = useStyles();
  const [openAudienceCreated, setOpenAudienceCreated] = useState(false);
  const [openAudienceCreatedWithContacts, setOpenAudienceCreatedWithContacts] = useState(false);
  const [openAudienceAlreadyExist, setOpenAudienceAlreadyExist] = useState(false);
  const [openAudienceAndContactAdded, setOpenAudienceAndContactAdded] = useState(false);
  const [openAddContact, setOpenAddContact] = useState(false);
  const [openSelectContacts, setOpenSelectContacts] = useState(false);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [openContactAlreadyExist, setOpenContactAlreadyExist] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [currentEmoji, setCurrentEmoji] = useState("");
  const [audienceName, setAudienceName] = useState("");

  const {page, total} = currentPagination || {page: 0, total: 0};
  const [rowCountState, setRowCountState] = useState(total || 0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const rowSelectionChanged = async (newSelection) => {
    let id = newSelection.rowIds[0];
    await dispatch({
      type: "FAT_SELECT_EDIT",
      payload: rows.filter((i) => i.id === +id)[0],
    });
  };

  const loadServerRows = (page, contactsData) => {
    return new Promise((resolve) => {
      resolve(
          contactsData.rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
      );
    });
  };

  const initializeContactList = () => {
    contactsData.columns = columns;
    contactsData.initialState = initialState;
    contactsData.rows = [];
  };

  useEffect(() => {
    if (
        (currentListType === "segments" || currentListType === "all") &&
        page === 0
    ) {
      initializeContactList();
      setRowCountState(0);
    }
  }, [currentPagination]);

  const formatContactData = () => {
    contactsList &&
    Object.values(contactsList).forEach((contact) => {
      const {
        forename,
        surname,
      } = contact;

      if (contactsData.rows.includes(contact) === false) {
        contact.isChecked = false;
        contact.fullName = forename + " " + surname;
        if (contact.noOfVisits <= 0) {
          contact.noOfVisits = '-';
          contact.lastVisit = "-";
        }
        contactsData.rows = [...contactsData.rows, contact];
      }

      if (currentCampaign && currentCampaign.contacts) {
        currentCampaign.contacts = [...currentCampaign.contacts, contact];
      }
    });
  };

  useEffect(() => {
    setSelectedCustomers(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    if (
        (currentListType === "segments" || currentListType === "all") &&
        page === 0
    ) {
      initializeContactList();
      setRowCountState(0); // start pagination from the begining of the list
    }
  }, [currentPagination]);

  useEffect(() => {
    setRowCountState(total);
    formatContactData();
  }, []);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      formatContactData();
      const newRows = await loadServerRows(page, contactsData);
      if (!active) {
        return;
      }
      setRows(newRows);
      setLoading(false);
      setRowCountState(total);
      setSelectionModel(prevSelectionModel.current);
      const selectedId = new Set(prevSelectionModel.current);
      const selectedRowsData = contactsData.rows.filter((row) =>
          selectedId.has(row.id)
      );
      setSelectedRows(selectedRowsData);
    })();

    return () => {
      active = false;
    };
  }, [contactsList]);

  const handleSelectAllCustomers = (event) => {
    const isChecked = event.target.checked;
    let allContact = [];
    if (isChecked) {
      if (allContactsIds) {
        Object.entries(allContactsIds).forEach(([key, value]) => {
          allContact = [...allContact, key];
        });
        setSelectionModel(allContact);
        setIsAllContactsSelected(true);
        setSelectedContactsIds(allContact)
      }
    } else {
      setSelectionModel([]);
      setSelectedRows([]);
      setSelectedContactsIds([])
      setIsAllContactsSelected(false);
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    const {emoji} = emojiObject;
    setCurrentEmoji(emoji)
  };

  const handleBack = () => {
    handleCloseSelectContacts();
    handleCloseAddContact();
    handleCloseAudienceCreated();
    handleOpen();
  }

  const handleAudienceNameChange = (event) => {
    setAudienceName(event.target.value)
  }

  const handleImportContacts = () => {
    handleCloseSelectContacts();
    handleCloseAddContact();
    handleCloseAudienceCreated();
    setOpenBulkUpload(true);
  }
  const handleCloseBulkUpload = () => {
    setOpenBulkUpload(false);
  };

  const handleOpen = () => {
    setOpenAddNewAudience(true);
  };

  const handleClose = () => {
    setOpenAddNewAudience(false);
  };

  const handleOpenContactAlreadyExist = () => {
    setOpenContactAlreadyExist(true);
  };

  const handleCloseContactAlreadyExist = () => {
    setOpenContactAlreadyExist(false);
  };

  const handleOpenSelectContacts = () => {
    handleCloseAddContact();
    setOpenSelectContacts(true);
  };

  const handleOpenAddContact = () => {
    handleClose();
    setOpenAddContact(true);
  };

  const handleCloseAddContact = () => {
    setOpenAddContact(false);
  };

  const handleCloseAudienceCreated = () => {
    setOpenAudienceCreated(false);
  };

  const handleCloseAudienceCreatedWithContacts = () => {
    setOpenAudienceCreatedWithContacts(false);
  };

  const handleOpenAudienceCreatedWithContacts = () => {
    setOpenAudienceCreatedWithContacts(true);
  };

  const handleCloseAudienceAlreadyExist = () => {
    setOpenAudienceAlreadyExist(false);
    setAudienceName("");
    setCurrentEmoji("");
  };

  const handleOpenAudienceAlreadyExist = () => {
    setOpenAudienceAlreadyExist(true);
  };

  const handleCloseAudienceAndContactAdded = () => {
    setOpenAudienceAndContactAdded(false);
  };
  const handleOpenAudienceAndContactAdded = () => {
    setOpenSelectContacts(false);
    setOpenAudienceCreated(false);
    setOpenAudienceAndContactAdded(true);
    setTimeout(
        () => setOpenAudienceAndContactAdded(false),
        2000
    );
  };

  const handleCloseSelectContacts = () => {
    setOpenSelectContacts(false);
  };

  const handleNextClick = () => {
    setOpenAddNewAudience(false);
    handleOpenAddContact();
  }

  const onCreateAudience = () => {
    handleCloseAddContact();
    handleCloseSelectContacts();

    let values = {
      audienceName: audienceName,
      chosenEmoji: chosenEmoji || ""
    }

    const request = {
      ...values,
      organisationId: organisationId,
      contactIds: selectedContactsIds,
    };

    from(apiCreateAudience(request))
    .subscribe({
      next: (audience) => {
        const {data} = audience
        const {id, name, organisationId} = data
        if (id) {
          getAudiencesRequest(organisationId);
          getAudiencesRequest(organisationId);
          loadContacts();
          if (selectedRows.length > 0) {
            setOpenAudienceCreatedWithContacts(true);
            setTimeout(
                () => {
                  setOpenAudienceCreatedWithContacts(false);
                  setSelectedRows([]);
                }, 2000
            );
          } else {
            setOpenAudienceCreated(true);
            setTimeout(
                () => {
                  setOpenAudienceCreated(false);
                }, 2000
            );
          }
          setAudienceName("");
          setCurrentEmoji("");
        }
        if (data.trim() === "") {
          setOpenAudienceCreated(false);
          handleOpenAudienceAlreadyExist();
          setTimeout(
              () => {
                handleCloseAudienceAlreadyExist();
                setAudienceName("");
                setCurrentEmoji("");
              },
              3000
          );
        }
      },
      error: (err) => {
        console.error('Error creating audience')
        console.error(err);
        toast.error('Error creating audience');
      },
      complete: () => {
        handleClose();
      }
    });
  }

  return (
      <div>
        <div onClick={handleOpen}>
          <ListItemIcon>
            <GroupAddRoundedIcon fontSize="small" />
          </ListItemIcon>
          Create Audience
        </div>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openAddNewAudience}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            disableEnforceFocus={true}
            disableAutoFocus={true}
        >
          <Fade in={openAddNewAudience}>
            <div className={classes.paper}>
              <CreateAudienceModalWrapper>
                <img
                    alt="close"
                    onClick={handleClose}
                    className={"closeImg"}
                    src={require("assets/images/Group41.png")}
                />
                <TypographyText>
                  Create New Audience
                </TypographyText>
                <NewAudienceContainer>
                  <div className={"audienceBlock addContactBlock"}>
                    <div className={"successfulImport"}>
                      <div>
                        <FieldsRow>
                          <TextField
                              fullWidth
                              size="small"
                              name="audienceName"
                              label="Audience Name"
                              className={classes.customTextField}
                              value={audienceName}
                              onChange={handleAudienceNameChange}
                              variant='outlined'
                              InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                      <>
                                        {
                                          audienceName !== "" ? currentEmoji
                                              : ""
                                        }
                                      </>
                                    </InputAdornment>
                                ),
                              }}
                          />
                        </FieldsRow>
                        <FieldsRow>
                          <div>
                            <Picker
                                onEmojiClick={onEmojiClick}
                                pickerStyle={{width: '100%'}}
                                // disableAutoFocus={true}
                                skinTone={SKIN_TONE_MEDIUM_DARK}
                                groupNames={{smileys_people: 'PEOPLE'}}
                                // native
                                disableSearchBar={true}
                                disableSkinTonePicker={true}
                            />
                          </div>
                        </FieldsRow>

                        <Button
                            onClick={handleClose}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              zIndex: 1,
                              marginTop: 5,
                              marginRight: 5
                            }}
                            variant="text"
                        >
                          CANCEL
                        </Button>
                        <Button
                            onClick={handleNextClick}
                            endIcon={<ChevronRightRoundedIcon
                                sx={{fontSize: '80px', fontWeight: '400'}}/>}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              zIndex: 1,
                              marginTop: 5,
                            }}
                            variant="text"
                        >
                          NEXT
                        </Button>
                        {/*</form>*/}
                      </div>
                    </div>
                  </div>
                </NewAudienceContainer>
              </CreateAudienceModalWrapper>
            </div>
          </Fade>
        </Modal>


        {/*CONTACT ALREADY EXISTS*/}
        {((openContactAlreadyExist === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openContactAlreadyExist}
                onClose={handleOpenContactAlreadyExist}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openContactAlreadyExist}>
                <div className={classes.paper}>
                  <ConfirmationPlaceholder>
                    <img
                        alt="group"
                        onClick={handleCloseContactAlreadyExist}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <WarningWrapper>
                      <HeaderWarningAmber>
                        <WarningAmberRoundedIcon
                            sx={{
                              fontSize: 45,
                              padding: 3,
                              color: "#FF9933",
                            }}
                        />
                      </HeaderWarningAmber>
                    </WarningWrapper>
                    <TypographyTextContactExist>
                      (7) Contacts already exists, do you want to update any
                      missing
                      information?
                    </TypographyTextContactExist>
                    <ButtonSection>
                      <Button
                          onClick={handleCloseContactAlreadyExist}
                          sx={{
                            color: "#FF9933",
                            fontSize: 16,
                            fontWeight: "500",
                            zIndex: 1,
                            marginTop: 60,
                            marginLeft: 60
                          }}
                          variant="text"
                      >
                        CANCEL
                      </Button>
                      <Button
                          endIcon={<UpdateRounded
                              sx={{fontSize: '80px', fontWeight: '400'}}/>}
                          sx={{
                            color: "#FF9933",
                            fontSize: 16,
                            fontWeight: "500",
                            zIndex: 1,
                            marginTop: 60,
                            marginLeft: 5,
                            minWidth: 200
                          }}
                          variant="text"
                      >
                        UPDATE CONTACT
                      </Button>
                    </ButtonSection>

                  </ConfirmationPlaceholder>
                </div>
              </Fade>
            </Modal>
        )}

        {/*AUDIENCE CREATED WITH CONTACTS*/}
        {((openAudienceCreatedWithContacts === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openAudienceCreatedWithContacts}
                onClose={handleCloseAudienceCreatedWithContacts}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openAudienceCreatedWithContacts}>
                <div className={classes.paper}>
                  <ConfirmationAudienceCreated>
                    <img
                        alt="group"
                        onClick={handleCloseAudienceCreatedWithContacts}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <ImageDiv>
                      <HeaderImage>
                        <CheckCircleOutlineIcon
                            sx={{
                              fontSize: 60,
                              padding: 2.3,
                              color: "#1DB350",
                            }}
                        />
                      </HeaderImage>
                    </ImageDiv>
                    <>
                      {/*<TypographyTextAudienceCreated>*/}
                      <TypographyText>
                        Audience Created and ({selectedRows.length}) {" "}
                        contacts added!
                      </TypographyText>
                    </>
                  </ConfirmationAudienceCreated>
                </div>
              </Fade>
            </Modal>
        )}

        {((openAudienceCreated === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openAudienceCreated}
                onClose={handleCloseAudienceCreated}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openAudienceCreated}>
                <div className={classes.paper}>
                  <ConfirmationAudienceCreated>
                    <img
                        alt="group"
                        onClick={handleCloseAudienceCreated}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <ImageDiv>
                      <HeaderImage>
                        <CheckCircleOutlineIcon
                            sx={{
                              fontSize: 60,
                              padding: 2.3,
                              color: "#1DB350",
                            }}
                        />
                      </HeaderImage>
                    </ImageDiv>
                    <>
                      {/*<TypographyTextAudienceCreated>*/}
                      <TypographyText>
                        Audience Created
                      </TypographyText>
                    </>
                  </ConfirmationAudienceCreated>
                </div>
              </Fade>
            </Modal>
        )}

        {/*AUDIENCE ALREADY EXIST*/}
        {((openAudienceAlreadyExist === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openAudienceAlreadyExist}
                onClose={handleCloseAudienceAlreadyExist}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openAudienceAlreadyExist}>
                <div className={classes.paper}>
                  <ConfirmationAudienceCreated>
                    <img
                        alt="group"
                        onClick={handleCloseAudienceAlreadyExist}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <ImageDiv>
                      <HeaderErrorAmber>
                        <WarningAmberRoundedIcon
                            sx={{
                              fontSize: 45,
                              padding: 3,
                              color: "#EA5454",
                            }}
                        />
                      </HeaderErrorAmber>

                    </ImageDiv>
                    {/*<TypographyTextAudienceCreated>*/}
                    <TypographyText>
                      <Typography variant="h6" color="red">
                        {audienceName}
                      </Typography>
                      <br/>
                      Audience Already Exists!
                    </TypographyText>
                  </ConfirmationAudienceCreated>
                </div>
              </Fade>
            </Modal>
        )}

        {/*AUDIENCE CREATED AND CONTACTS ADDED*/}
        {((openAudienceAndContactAdded === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openAudienceAndContactAdded}
                onClose={handleCloseAudienceAndContactAdded}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openAudienceAndContactAdded}>
                <div className={classes.paper}>
                  <ConfirmationAudienceCreated>
                    <img
                        alt="group"
                        onClick={handleCloseAudienceAndContactAdded}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <ImageDiv>
                      <HeaderImage>
                        <CheckCircleOutlineIcon
                            sx={{
                              fontSize: 60,
                              padding: 2.3,
                              color: "#1DB350",
                            }}
                        />
                      </HeaderImage>
                    </ImageDiv>
                    {/*<TypographyTextAudienceCreated>*/}
                    <TypographyText>
                      Audience Created and (
                      {selectedContactsIds && selectedContactsIds.length})
                      contacts added!
                    </TypographyText>
                  </ConfirmationAudienceCreated>
                </div>
              </Fade>
            </Modal>
        )}

        {/*ADD CONTACT QUESTION*/}
        {((openAddContact === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openAddContact}
                onClose={handleCloseAddContact}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openAddContact}>
                <div className={classes.paper}>
                  <img
                      alt="group"
                      onClick={handleCloseAddContact}
                      className={"closeImg"}
                      src={group41Png}
                  />
                  <ConfirmationAddContact>
                    {/*<TypographyTextAddContact>*/}
                    <TypographyText>
                      Do you want to add contacts?
                    </TypographyText>
                    <ButtonSection>
                      <>
                        <Button
                            onClick={handleOpenSelectContacts}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "bold",
                              marginTop: 10
                            }}
                            variant="text"
                        >
                          YES
                        </Button>
                        <Button
                            onClick={onCreateAudience}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "bold",
                              marginTop: 10
                            }}
                            variant="text"
                        >
                          NO
                        </Button>
                      </>
                    </ButtonSection>
                  </ConfirmationAddContact>
                </div>
              </Fade>
            </Modal>
        )}

        {/*SELECT CONTACTS*/}
        {((openSelectContacts === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openSelectContacts}
                onClose={handleCloseSelectContacts}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openSelectContacts}>
                <div className={classes.paper}>
                  <SelectContactsModalWrapper>
                    <img
                        alt="group"
                        onClick={handleCloseSelectContacts}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <TypographyText>
                      <Typography sx={{fontSize: 24}}>
                        Select Contacts
                      </Typography>
                    </TypographyText>
                    <ImportContactsContainer>
                      <Button
                          onClick={handleImportContacts}
                          endIcon={<UploadFileRoundedIcon
                              sx={{fontSize: '80px', fontWeight: '400'}}/>}
                          sx={{
                            color: "#FF9933",
                            fontSize: 12,
                            fontWeight: "500",
                            zIndex: 1,
                            outline: "#FF9933",
                            borderRadius: 12,
                            marginTop: 12,
                            borderColor: "#FF9933",
                            "&:hover": {
                              color: "#FF9933",
                              borderColor: "#FF9933",
                            }
                          }}
                          variant="outlined"
                      >
                        IMPORT CONTACTS
                      </Button>
                    </ImportContactsContainer>

                    <CustomDataGrid
                        autoHeight
                        rows={rows}
                        columns={contactsData.columns}
                        pagination
                        checkboxSelection
                        disableSelectionOnClick
                        pageSize={PAGE_SIZE}
                        rowCount={rowCountState}
                        paginationMode="server"
                        onPageChange={(newPage) => {
                          prevSelectionModel.current = selectionModel;
                          setPage(newPage);
                        }}

                        onSelectionModelChange={(newSelectionModel) => {
                          const selectedIDs = new Set(newSelectionModel);
                          const selectedRowsData = contactsData.rows.filter(
                              (row) =>
                                  selectedIDs.has(row.id)
                          );

                          setSelectionModel(newSelectionModel);
                          setSelectedContactsIds(newSelectionModel)
                          setSelectedRows(selectedRowsData);
                        }}

                        selectionModel={selectionModel}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? "even"
                                : "odd"
                        }
                        onSelectionChange={rowSelectionChanged}
                        localeText={{
                          footerRowSelected: (count) =>
                              count !== 1
                                  ? `${count} customers selected`
                                  : `${count} customer selected`,
                        }}
                        loading={loading}
                    />

                    <SelectContactsContainer>
                      <ButtonSection>
                        <Button
                            onClick={handleBack}
                            startIcon={<ChevronLeftRoundedIcon
                                sx={{fontSize: '80px', fontWeight: '400'}}/>}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              marginRight: 10,
                              marginLeft: 5
                            }}
                            variant="text"
                        >
                          BACK
                        </Button>
                        <Button
                            onClick={onCreateAudience}
                            endIcon={<CheckCircleOutlineIcon
                                sx={{fontSize: '80px', fontWeight: '400'}}/>}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              zIndex: 1
                            }}
                            variant="text"
                        >
                          CONFIRM
                        </Button>
                      </ButtonSection>
                    </SelectContactsContainer>
                  </SelectContactsModalWrapper>
                </div>
              </Fade>
            </Modal>
        )}


        {/*BULK UPLOAD*/}
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openBulkUpload}
            onClose={handleCloseBulkUpload}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={openBulkUpload}>
            <div className={classes.paper}>
              <BulkUploadContainer>
                <img
                    alt="group"
                    onClick={handleCloseBulkUpload}
                    className={"closeImg"}
                    src={group41Png}
                />
                <TypographyText>
                  <Typography sx={{fontSize: '24px', fontWeight: '400'}}>
                    Upload Contacts
                  </Typography>
                </TypographyText>
                <BulkUploadFrameContainer>
                  <ImportListModal setOpenBulkUpload={setOpenBulkUpload}/>
                </BulkUploadFrameContainer>
                <ButtonSection>
                  <Button
                      onClick={handleCloseBulkUpload}
                      endIcon={<CheckCircleOutlineIcon
                          sx={{fontSize: '80px', fontWeight: '400'}}/>}
                      sx={{
                        color: "#FF9933",
                        fontSize: 16,
                        fontWeight: "500",
                        zIndex: 1,
                        marginLeft: 55,
                        marginTop: 90
                      }}
                      variant="text"
                  >
                    CONFIRM
                  </Button>
                  <Button
                      onClick={handleCloseBulkUpload}
                      sx={{
                        color: "#FF9933",
                        fontSize: 16,
                        fontWeight: "500",
                        zIndex: 1,
                        marginLeft: 10,
                        marginTop: 90
                      }}
                      variant="text"
                  >
                    CANCEL
                  </Button>
                </ButtonSection>
              </BulkUploadContainer>
            </div>
          </Fade>
        </Modal>

      </div>
  );
};

export default compose(
    reduxForm({
      form: "CREATE_AUDIENCE_MODAL_FORM",
    })
)(CreateAudiencesModal);
