import React, { useState } from "react";

import ContactDropdown from "../ContactDropdown";
import TagsDropdown from "../TagsDropdown";
import ImportContacts from "../ImportContacts";

import { ReactComponent as CloseIcon } from "assets/images/delete.svg";

import "assets/css/sms/contact/add-contact-popup.scss";

export default ({
  selectedTag,
  tags,
  save,
  close,
  inputFile,
  selectedFile,
  organisationId,
  update = false,
  updateContact,
}) => {
  const countryCodes = [
    {
      name: "United Kingdom",
      flag: "🇬🇧",
      code: "+44",
    },
    // {
    //   name: "United States",
    //   flag: "🇺🇸",
    //   code: "+1",
    // },
    // {
    //   name: "Germany",
    //   flag: "🇩🇪",
    //   code: "+49",
    // },
    // {
    //   name: "Spain",
    //   flag: "🇪🇸",
    //   code: "+34",
    // },
    // {
    //   name: "France",
    //   flag: "🇫🇷",
    //   code: "+33",
    // },
  ];

  const [selectedCountry, setSelectedCountry] = useState(
    updateContact && updateContact.phones && updateContact.phones.length > 0
      ? countryCodes.find((cc) =>
          updateContact.phones[0].phoneNumber.startsWith(cc.code)
        )
      : null
  );

  const getPhoneNumber = () => {
    const country = countryCodes.find((cc) =>
      updateContact.phones[0].phoneNumber.startsWith(cc.code)
    );
    if (!country) return updateContact.phones[0].phoneNumber;
    else {
      return updateContact.phones[0].phoneNumber.replace(country.code, "");
    }
  };

  const [phone, setPhone] = useState(
    updateContact && updateContact.phones && updateContact.phones.length > 0
      ? getPhoneNumber()
      : null
  );

  const [unusedTags, setUnusedTags] = useState(tags);
  const [importList, setImportList] = useState(false);
  const [contact, setContact] = useState(
    updateContact
      ? { ...updateContact, tags: updateContact.contactTags }
      : { tags: selectedTag ? [selectedTag] : [] }
  );
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const onClick = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };
  const changeTags = (changedTags) => {
    setContact({ ...contact, tags: changedTags });
    setUnusedTags(tags.filter((t) => !changedTags.includes(t)));
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const changeSelectedCountry = (country) => {
    setErrors(errors.filter((error) => error !== "country"));
    setErrorMessage(null);
    setSelectedCountry(country);
  };

  const changePhoneNumber = (value) => {
    setErrors(errors.filter((error) => error !== "phone"));
    setErrorMessage(null);
    setPhone(value);
  };

  const changeForename = (value) => {
    setErrors(errors.filter((error) => error !== "forename"));
    setErrorMessage(null);
    setContact({ ...contact, forename: capitalize(value) });
  };

  const changeSurname = (value) => {
    setErrors(errors.filter((error) => error !== "surname"));
    setErrorMessage(null);
    setContact({ ...contact, surname: capitalize(value) });
  };

  const saveContact = async () => {
    var e = [];
    if (!selectedCountry) e = [...e, "country"];
    if (!phone || phone.length < 3) e = [...e, "phone"];
    if (!contact.forename || contact.forename.length < 3)
      e = [...e, "forename"];
    if (!contact.surname || contact.surname.length < 3) e = [...e, "surname"];

    setErrors(e);

    if (e.length > 0) {
      setErrorMessage("Please fill all mandatory fields");
    }

    if (e.length === 0) {
      setErrorMessage(null);
      setErrors([]);
      contact.phones = [
        {
          phoneNumber:
            selectedCountry.code +
            (phone.startsWith("0") ? phone.substring(1) : phone),
        },
      ];
      contact.registrationType = "SMS_ADDED";
      contact.tenantId = "8dcc3680-0b8a-11ea-9518-42010a9a0003";
      save(contact);
      close();
    }
  };

  return (
    <div className="add-contact-popup" onClick={onClick}>
      <div className="add-contact-popup-content">
        <div className="add-contact-top">
          {!update && (
            <div
              className={`page-selector ${
                importList && "page-selector-selected"
              }`}
              onClick={() => setImportList(true)}
            >
              <label>Import list</label>
            </div>
          )}
          {!update && (
            <div
              className={`page-selector ${
                !importList && "page-selector-selected"
              }`}
              onClick={() => setImportList(false)}
            >
              <label>Single contact</label>
            </div>
          )}
          <CloseIcon className="close-icon" onClick={close} />
        </div>

        {importList ? (
          <ImportContacts
            tags={tags}
            inputFile={inputFile}
            file={selectedFile}
            close={close}
            organisationId={organisationId}
          />
        ) : (
          <div className="add-single-contact">
            <label className="title">
      
              {update ? "Edit" : "Create"} Contact
            </label>
            <label className="input-label">Number</label>
            <div className="inputs">
              <ContactDropdown
                className={`country-codes ${
                  errors.length > 0 &&
                  errors.includes("country") &&
                  "input-error"
                }`}
                placeholder="Select country"
                options={countryCodes}
                onSelect={(selected) => changeSelectedCountry(selected)}
                selected={
                  selectedCountry
                    ? `${selectedCountry.flag} ${selectedCountry.code}`
                    : null
                }
              />
              <input
                className={`${
                  errors.length > 0 && errors.includes("phone") && "input-error"
                }`}
                value={phone || ""}
                onChange={(e) => changePhoneNumber(e.target.value)}
              />
            </div>
            <div className="names">
              <div className="name-input">
                <label className="input-label">Forename</label>
                <input
                  className={`${
                    errors.length > 0 &&
                    errors.includes("forename") &&
                    "input-error"
                  }`}
                  value={contact.forename || ""}
                  onChange={(e) => changeForename(e.target.value)}
                />
              </div>
              <div className="name-input">
                <label className="input-label">Surname</label>
                <input
                  className={`${
                    errors.length > 0 &&
                    errors.includes("surname") &&
                    "input-error"
                  }`}
                  value={contact.surname || ""}
                  onChange={(e) => changeSurname(e.target.value)}
                />
              </div>
            </div>
            <label className="input-label">Tags</label>
            <TagsDropdown
              className="tags-dd"
              placeholder="Select tag"
              options={unusedTags}
              tags={contact.tags}
              setTags={changeTags}
            />
            <label className="input-label">Customs</label>
            <div className="customs">
              <input />
              <input />
              <input />
              <input />
            </div>
            {errorMessage && (
              <label className="error-message">{errorMessage}</label>
            )}
            <button onClick={saveContact}>Save</button>
          </div>
        )}
      </div>
    </div>
  );
};
