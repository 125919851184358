import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import GoogleIcon from '@mui/icons-material/Google';

const WifiLoginItemWrapper = styled.a`
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 1px;
    
    background: ${(props) =>
    props.isThisItemSelected
        ? "linear-gradient(90deg, rgba(253,237,216,1) 0%, rgba(253,237,216,1) 55%, rgba(253,237,216,0) 95%)"
        : "white"};
  
    &:hover {
      background: linear-gradient(90deg, rgba(253, 237, 216, 1) 0%, rgba(253, 237, 216, 1) 55%, rgba(253, 237, 216, 0) 95%);
      cursor: pointer;
    }
  
    &:active {
      background: linear-gradient(90deg, rgba(253, 237, 216, 1) 0%, rgba(253, 237, 216, 1) 55%, rgba(253, 237, 216, 0) 95%);
    }
`;

const WifiLoginItem = (props) => {
  const {
    id,
    selectedWifiLogin,
  } = props;

  const [isThisItemSelected, setIsThisItemSelected] = React.useState(false);

  React.useEffect(() => {
    if (selectedWifiLogin) {
      setIsThisItemSelected(selectedWifiLogin.id === id);
    }
  }, [selectedWifiLogin]);

  const handleClick = (id, organisationId) => {
    // setSelectedContactsIds([]);
    // setIsAllContactsSelected(false);
    // selectListTypeRequest(listTypes.AUDIENCES);
    // selectAudienceRequest(id);
    // getAudienceContactsRequest(organisationId, id, 0, PAGE_SIZE, searchQuery);
  };

  return (
      <>
        {/*FACEBOOK*/}
        <WifiLoginItemWrapper
            isThisItemSelected={isThisItemSelected}
            // onClick={() => handleClick(id, organisationId)}
        >
          <Box sx={{
            marginLeft: 2,
            marginTop: 1,
            fontFamily: "'Roboto',sans-serif !important",
            fontSize: "16px"
          }}>
            <ListItemIcon>
              <FacebookRoundedIcon fontSize="large"/>
              <span style={{marginTop: 3, marginLeft: 30}}>
            <ListItemText
                disableTypography
                primary={<Typography style={{
                  fontFamily: "'Roboto',sans-serif !important",
                  fontSize: "16px",
                  color: 'black'
                }}>Facebook</Typography>}
            />
          </span>
            </ListItemIcon>
          </Box>
        </WifiLoginItemWrapper>

        {/*TWITTER*/}
        {/* <WifiLoginItemWrapper
            isThisItemSelected={isThisItemSelected}
            // onClick={() => handleClick(id, organisationId)}
        >
          <Box sx={{
            marginLeft: 2,
            marginTop: 2,
            fontFamily: "'Roboto',sans-serif !important",
            fontSize: "16px"
          }}>
            <ListItemIcon>
              <TwitterIcon fontSize="large"/>
              <span style={{marginTop: 3, marginLeft: 30}}>
            <ListItemText
                disableTypography
                primary={<Typography style={{
                  fontFamily: "'Roboto',sans-serif !important",
                  fontSize: "16px",
                  color: 'black'
                }}>Twitter</Typography>}
            />
          </span>
            </ListItemIcon>
          </Box>
        </WifiLoginItemWrapper> */}

        {/*EMAIL*/}
        <WifiLoginItemWrapper
            isThisItemSelected={isThisItemSelected}
            // onClick={() => handleClick(id, organisationId)}
        >
          <Box sx={{
            marginLeft: 2,
            marginTop: 2,
            fontFamily: "'Roboto',sans-serif !important",
            fontSize: "16px"
          }}>
            <ListItemIcon>
              <EmailRoundedIcon fontSize="large"/>
              <span style={{marginTop: 3, marginLeft: 30}}>
            <ListItemText
                disableTypography
                primary={<Typography style={{
                  fontFamily: "'Roboto',sans-serif !important",
                  fontSize: "16px",
                  color: 'black'
                }}>Email</Typography>}
            />
          </span>
            </ListItemIcon>
          </Box>
        </WifiLoginItemWrapper>

        {/*GMAIL*/}
        {/* <WifiLoginItemWrapper
            isThisItemSelected={isThisItemSelected}
            // onClick={() => handleClick(id, organisationId)}
        >
          <Box sx={{
            marginLeft: 2,
            marginTop: 2,
            fontFamily: "'Roboto',sans-serif !important",
            fontSize: "16px"
          }}>
            <ListItemIcon>
              <GoogleIcon fontSize="large"/>
              <span style={{marginTop: 3, marginLeft: 30}}>
            <ListItemText
                disableTypography
                primary={<Typography style={{
                  fontFamily: "'Roboto',sans-serif !important",
                  fontSize: "16px",
                  color: 'black'
                }}>Gmail</Typography>}
            />
          </span>
            </ListItemIcon>
          </Box>
        </WifiLoginItemWrapper> */}
      </>
  );
};

export default WifiLoginItem;
