import api from "utils/api";
export const apiCreateIdentity = (data) => {
  const { organisationId, emailIdentityData } = data;

  const apiRoute = `/email/organisation/${organisationId}/identity`;

  return api.post(apiRoute, emailIdentityData);
};

export const apiGetIdentities = (data) => {
  const { organisationId } = data;

  const apiRoute = `/email/organisation/${organisationId}/identity`;

  return api.get(apiRoute);
};

export const apiDeleteIdentity = (data) => {
  const { identityId } = data;

  const apiRoute = `/email/identity/${identityId}`;

  return api.delete(apiRoute);
};

export const apiGetDomainRecords = (data) => {
  const { identityId } = data;

  const apiRoute = `email/identity/${identityId}/dkim`;

  return api.get(apiRoute);
};

export const apiResendIdentity = (data) => {
  const { identityId } = data;

  const apiRoute = `email/identity/${identityId}/resend-email`;

  return api.get(apiRoute);
};
