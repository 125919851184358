import React from "react";

import { PaperPlaneRight, Gear } from "phosphor-react";
import Button from "../../components/Button";
import isEmail from "utils/validations/isEmail";

const ChooseVerificationStep = (props) => {
  const {
    selectedEntity,
    setCurrentVerificationType,
    createIdentityRequest,
    close,
  } = props;

  const [email, setEmail] = React.useState(null);
  const [fullName, setFullName] = React.useState(null);

  const [isFormFilled, setIsFormFilled] = React.useState(false);

  React.useEffect(() => {
    if (isEmail(email) && fullName?.length > 0) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [email, fullName]);

  const handleVerify = (method, email) => {
    if (isFormFilled) {
      const identityObj = {
        type: method,
        identity: email,
        email: email,
        name: fullName,
      };
      createIdentityRequest(selectedEntity.externalId, identityObj);
    }

    setCurrentVerificationType(method);
  };
  return (
    <>
      <label className="title">Verify a Domain</label>

      <label>
        We need to verify that you have access to an email address at the domain
        you plan to send campaigns from.
        <br />
        <b>Fill the input fields and select one of the verification methods.</b>
      </label>

      <div className="inputs-panel">
        <input
          placeholder="Your full name."
          value={fullName || ""}
          onChange={(e) => setFullName(e.target.value)}
        />
        <input
          placeholder="Email address of domain you want to verify."
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <Button
        title={"Verify by Email Notification"}
        disabled={!isFormFilled}
        onClick={() => {
          handleVerify("email", email);
          close();
        }}
        icon={
          <PaperPlaneRight
            size={16}
            color={"#FFF"}
            style={{ marginLeft: "10px" }}
          />
        }
      ></Button>

      <Button
        title={"Verify by adding CNAME records"}
        disabled={!isFormFilled}
        onClick={() => {
          handleVerify("domain", email);
        }}
        icon={<Gear size={16} color={"#FFF"} style={{ marginLeft: "10px" }} />}
      ></Button>
    </>
  );
};

export default ChooseVerificationStep;
