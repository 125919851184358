import React, {useEffect, useState} from "react";
import AddFilterModal from "../Modals/AddFilterModal";
import SaveSegmentModal from "../Modals/SaveSegmentModal";
import DeleteSegmentModal from "../Modals/DeleteSegmentModal";
import FilterButtons from "./FilterButtons";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InputAdornment from "@mui/material/InputAdornment";
import styled from "@emotion/styled";
import _ from "lodash";
import {Menu, MenuItem} from "@mui/material";
import Export from "components/customers/modal/export";
import DeleteContactsModal from "../Modals/DeleteContactsModal";
import CreateAudienceModal from "../Modals/CreateAudienceModal";
import CreateContactModal from "../Modals/CreateContactModal";
import CreateSegementModal from "../Modals/CreateSegementModal";
import MenuIcon from "@mui/icons-material/Menu";
import {useHistory} from "react-router-dom";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

const StyledTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "#FF9933",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: `90px`,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF9933",
    },
    width: "500px",
    height: `38px`,
  },
}));

const SegmentControlsPanel = (props) => {
  const {
    organisationId,
    addFilterRequest,
    removeFilterRequest,
    currentFilters,
    createSegmentRequest,
    setCurrentSelectedSegmentId,
    setSearchQuery,
    selectedCustomers,
    setSelectedCustomers,
    selectedContactsIds,
    openAddNewAudience,
    setOpenAddNewAudience,
    selectedSegment,
    getSegmentsRequest,
    currentListType,
    selectSegmentRequest,
    getAllContactsRequest,
    selectListTypeRequest,
    setSelectedContactsIds,
    setIsAllContactsSelected,
  } = props;

  const history = useHistory();

  const routeChangeToEmail = () => {
    let path = `/email/new`;
    history.push({
      pathname: path,
      state: {selectedCustomers: selectedCustomers},
    });
  };

  const routeChangeToSms = () => {
    let path = `/sms/quick-sms`;
    history.push({
      pathname: path,
      currentProps: props,
      state: {selectedCustomers: selectedCustomers},
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  //const open = Boolean(anchorEl);

  const showActions = true; //TODO should be passed in as a property

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isFromHamburgerMenu, setIsFromHamburgerMenu] = useState(true);
  const [value, setValue] = useState();
  const setSearch = _.debounce((val) => setSearchQuery(val), 1000);

  const set = (value) => {
    setValue(value);
    setSearch(value);
  };

  const onChange = ({target}) => set(target.value);
  const [anyFilters, setAnyFilters] = useState(false);

  useEffect(() => {
    currentFilters && currentFilters.length > 0
        ? setAnyFilters(true)
        : setAnyFilters(false);
  }, [currentFilters]);

  const deleteFilter = (index) => {
    removeFilterRequest(index);
    setCurrentSelectedSegmentId("segment-all-contacts");
  };

  return (
      <>
        <div style={{whiteSpace: "nowrap"}}>
          <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "normal",
                p: 1,
                m: 1,
                marginLeft: 2,
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
          >
            <div className="cp-action">
              <label>
                <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "20px",
                      marginLeft: "0px",
                    }}
                >
                  <StyledTextField
                      label="Search Customer"
                      defaultValue=""
                      value={value}
                      InputLabelProps={{shrink: true}}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                  type="submit"
                                  sx={{p: "10px"}}
                                  aria-label="search"
                              >
                                <SearchIcon/>
                              </IconButton>
                            </InputAdornment>
                        ),
                      }}
                  />
                </Box>
              </label>
            </div>
            {showActions && (
                <>
                  <div className="cp-action">
                    <label>
                      <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginTop: "20px",
                            marginLeft: "20px",
                          }}
                      >
                        <Stack direction="row" spacing={2}>
                          <Button
                              variant="outlined"
                              startIcon={<EmailIcon/>}
                              sx={{
                                color: "#FF9933",
                                border: "none",
                              }}
                              onClick={routeChangeToEmail}
                              disabled={!selectedContactsIds.length > 0}
                          >
                            Send Email
                          </Button>
                        </Stack>
                      </Box>
                    </label>
                  </div>
                  <div className="cp-action">
                    <label>
                      <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginTop: "20px",
                            marginLeft: "20px",
                          }}
                      >
                        <Stack direction="row" spacing={2}>
                          <Button
                              variant="outlined"
                              startIcon={<SmsIcon/>}
                              sx={{
                                color: "#FF9933",
                                border: "none",
                              }}
                              onClick={routeChangeToSms}
                              disabled={!selectedContactsIds.length > 0}
                          >
                            Send Sms
                          </Button>
                        </Stack>
                      </Box>
                    </label>
                  </div>
                </>
            )}
            <div className="cp-action">
              <label>
                <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "20px",
                      marginLeft: "20px",
                    }}
                >
                  <Stack direction="row" spacing={2}>
                    <DeleteContactsModal {...props} />
                  </Stack>
                </Box>
              </label>
            </div>

            <div className="cp-action">
              <label>
                <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "10px",
                      marginLeft: "0px",
                    }}
                >
                  <div
                      className={"burgerButton"}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                  >
                    <IconButton sx={{marginTop: "0px"}} aria-label="menu">
                      <MenuIcon sx={{fontSize: "35px"}}/>
                    </IconButton>
                  </div>
                  <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <CreateContactModal
                          {...props}
                          openAddNewAudience={openAddNewAudience}
                          setOpenAddNewAudience={setOpenAddNewAudience}
                      />
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                      <CreateAudienceModal
                          {...props}
                          selectedCustomers={selectedCustomers}
                          setSelectedCustomers={setSelectedCustomers}
                          openAddNewAudience={openAddNewAudience}
                          setOpenAddNewAudience={setOpenAddNewAudience}
                      />
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                      <CreateSegementModal
                          {...props}
                          openAddNewAudience={openAddNewAudience}
                          setOpenAddNewAudience={setOpenAddNewAudience}
                          getSegmentsRequest={getSegmentsRequest}
                          isFromHamburgerMenu={isFromHamburgerMenu}
                          removeFilterRequest={removeFilterRequest}
                      />
                    </MenuItem>

                    <MenuItem disabled={!selectedContactsIds.length > 0}
                              onClick={handleClose}>
                      <Export {...props}
                              setSelectedCustomers={setSelectedCustomers}
                              setSelectedContactsIds={setSelectedContactsIds}
                              selectedCustomers={selectedCustomers}
                      />
                    </MenuItem>
                  </Menu>
                </Box>
              </label>
            </div>
          </Box>
          <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "normal",
                p: 1,
                m: 1,
                marginTop: "10px",
              }}
          >
            {currentFilters && (
                <FilterButtons
                    deleteFilter={deleteFilter}
                    filters={currentFilters}
                />
            )}
            <div className="cp-action">
              <label>
                <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginLeft: "20px",
                    }}
                >
                  <Stack direction="row" spacing={2}>

                    <Button
                        size="small"
                        variant="outlined"
                        startIcon={<FilterAltIcon/>}
                        sx={{
                          color: "#FF9933",
                          borderRadius: 16,
                          borderColor: "#FF9933",
                          maxHeight: '30px',
                          "&:hover": {
                            borderColor: "#FF9933 !important"
                          }
                        }}
                    >
                      <AddFilterModal
                          currentFilters={currentFilters}
                          removeFilterRequest={removeFilterRequest}
                          addFilterRequest={addFilterRequest}
                      />
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        startIcon={<SaveIcon/>}
                        sx={{
                          color: anyFilters ? "#FF9933" : "#C4C4C4",
                          borderRadius: 16,
                          borderColor: anyFilters ? "#FF9933" : "#C4C4C4",
                          maxHeight: '30px',
                          "&:hover": {
                            borderColor: "#FF9933 !important"
                          }
                        }}
                    >
                      <SaveSegmentModal
                          organisationId={organisationId}
                          createSegmentRequest={createSegmentRequest}
                          disabled={!anyFilters}
                          currentFilters={currentFilters}
                          selectedSegment={selectedSegment}
                          removeFilterRequest={removeFilterRequest}
                          setCurrentSelectedSegmentId={setCurrentSelectedSegmentId}
                          getSegmentsRequest={getSegmentsRequest}
                      />
                    </Button>
                    {/* <Button
                        size="small"
                        variant="outlined"
                        startIcon={<EditRoundedIcon/>}
                        sx={{
                          color: anyFilters ? "#FF9933" : "#C4C4C4",
                          borderRadius: 16,
                          borderColor: anyFilters ? "#FF9933" : "#C4C4C4",
                          maxHeight: '30px'
                        }}
                    >
                      <EditSegmentModal
                          organisationId={organisationId}
                          createSegmentRequest={createSegmentRequest}
                          disabled={!anyFilters}
                          currentFilters={currentFilters}
                      />
                    </Button> */}
                    <Button
                        size="small"
                        variant="outlined"
                        startIcon={<DeleteForeverRoundedIcon/>}
                        sx={{
                          color: anyFilters ? "#EA5454" : "#C4C4C4",
                          borderRadius: 16,
                          borderColor: anyFilters ? "#FF9933" : "#C4C4C4",
                          maxHeight: '30px',
                          "&:hover": {
                            borderColor: "#FF9933 !important"
                          }
                        }}
                    >
                      <DeleteSegmentModal
                          organisationId={organisationId}
                          createSegmentRequest={createSegmentRequest}
                          disabled={!anyFilters}
                          currentFilters={currentFilters}
                          selectedSegment={selectedSegment}
                          getSegmentsRequest={getSegmentsRequest}
                          removeFilterRequest={removeFilterRequest}
                          currentListType={currentListType}
                          selectSegmentRequest={selectSegmentRequest}
                          getAllContactsRequest={getAllContactsRequest}
                          setCurrentSelectedSegmentId={setCurrentSelectedSegmentId}
                          selectListTypeRequest={selectListTypeRequest}
                          setSelectedContactsIds={setSelectedContactsIds}
                          setIsAllContactsSelected={setIsAllContactsSelected}
                      />
                    </Button>
                  </Stack>
                </Box>
              </label>
            </div>
          </Box>
        </div>
      </>
  );
};

export default SegmentControlsPanel;
