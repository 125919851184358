const getNumberNavigationButtons = (numberOfPages, currentPage) => {
  var buttons = [];
  if (numberOfPages > 5) {
    const current = currentPage;

    if (currentPage < 3) buttons = [...Array(5).keys()];
    else if (currentPage + 4 > numberOfPages)
      buttons = [
        numberOfPages - 5,
        numberOfPages - 4,
        numberOfPages - 3,
        numberOfPages - 2,
        numberOfPages - 1,
      ];
    else {
      buttons = [current - 2, current - 1, current, current + 1, current + 2];
    }
  } else buttons = [...Array(numberOfPages).keys()];

  return buttons.map((nr) => ({
    id: nr,
    text: nr + 1,
    selected: nr === currentPage,
  }));
};

export default getNumberNavigationButtons;
