import styled from "@emotion/styled";

const DashboardStatsWrapper = styled.div`
  grid-row: 2/3;
  grid-column: 1/4;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;


  @media (max-width: 900px) {
    grid-row: 2/3;
    grid-column: 1/13;
    flex-direction: row;
  }

  .card-stats {
    @media (max-width: 900px) {
      width: 24%;
    }
  }

  /* .dashboard-statistics {
    .dashboard-statistics-stats {
      display: flex;
      flex-direction: row;
      flex: 2.5;
    }

    .cards {
     
      display: flex;
      flex-direction: column;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
    
    }

    .cards-2-columns {
      grid-template-columns: 1fr;
      flex: 1;
    }
  } */

  /* @media only screen and (max-width: 1000px) {
    .tops {
      margin-right: -15px;
    }
  } */
`;

export default DashboardStatsWrapper;
