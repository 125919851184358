import styled from "@emotion/styled";

const BackNextButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 400px;
  width: 300px;
  font-family: Roboto;

  .ceca-back {
    margin-right: 15px;
    background: #ff9933;
  }

  .ceca-fl {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 120px;
    border-radius: 45px;
    background: #ffcc33;
    padding: 0 20px;
    cursor: pointer;

    label {
      color: white;
      font-size: 16px;
      font-weight: 300;
      cursor: pointer;
    }

    img {
      cursor: pointer;
    }
  }
`;

export default BackNextButtonsWrapper;
