import React from "react";

import styled from "@emotion/styled";

const LogoContainerWrapper = styled.img`
  max-height: 150px;
  max-width: 150px;
  object-fit: contain;
  background-size: cover;
  margin-top: ${(props) => (props.mt ? props.mt : 0)}px;
`;

const LogoContainer = (props) => {
  const { logo, mt } = props;
  return logo ? (
    <LogoContainerWrapper mt={mt} src={logo} />
  ) : (
    <LogoContainerWrapper />
  );
};

export default LogoContainer;
