import React, { useState, useEffect, useContext } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { useParams, useHistory } from "react-router-dom";
import PageNavigator from "components/PageNavigator";
import { PageNavigationContext } from "context/navigationContext";
import { VenuesContext } from "context/venueContext";
import api from "utils/api";

import { ReactComponent as BackIcon } from "assets/images/back-icon.svg";

import "assets/css/sms/reports/report.scss";

export default () => {
  const history = useHistory();
  const { campaignId } = useParams();
  const { selected } = useContext(VenuesContext);
  const colors = ["#FFCC33", "#E95353"];
  const [messages, setMessages] = useState([]);
  const [navigation, setNavigation] = useState({ total: 1, current: 0 });
  const [campaign, setCampaign] = useState({});
  const [type, setType] = useState("summary");

  const [stats, setStats] = useState([
    {
      name: "Delivered",
      value: 0,
    },
    {
      name: "Not Delivered",
      value: 0,
    },
  ]);

  const formatMessage = (message) => {
    const names = message.recipientName.split(" ");
    return {
      ...message,
      sentOn: new Date(message.createdOn * 1000),
      delivered: new Date(
        message.events
          .map((event) => event.createdOn)
          .reduce((a, b) => Math.max(a, b)) * 1000
      ),
      firstName: names.length > 0 ? names[0] : "",
      lastName: names.length > 1 ? names[1] : "",
    };
  };

  const fetctMessages = async (page) => {
    const { data } = await api.get(
      `/sms/organisation/${selected.externalId}/campaign/${campaignId}/messages?size=15&page=${page}`
    );
    setMessages(data.content.map(formatMessage));
    setNavigation({
      total: Math.ceil(data.totalElements / 15),
      current: data.pageable.pageNumber,
    });
  };

  const fetchCampaign = async () => {
    const { data } = await api.get(
      `/sms/organisation/${selected.externalId}/campaign/${campaignId}`
    );
    setCampaign(data);
    setStats([
      {
        name: "Delivered",
        value: data.statistics.success,
      },
      {
        name: "Not Delivered",
        value: data.statistics.error,
      },
    ]);
  };

  const changePage = (page) => {
    fetctMessages(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      fetctMessages(0);
      fetchCampaign();
    };
    if (selected && campaignId) fetchData();
  }, [selected, campaignId]);

  const format = (nr) => {
    return nr < 10 ? `0${nr}` : nr;
  };

  return (
    <div className="report-page">
      <div className="rp-back" onClick={() => history.goBack()}>
        <BackIcon className="back-icon" />
        <label>Back to Manage Campaigns</label>
      </div>
      <div className="rp-content">
        <div className="rp-left">
          <div className="rp-message">
            <div className="rp-message-top">
              <label className="rp-title">Message</label>
            </div>
            <label className="rp-from">
              From: {campaign && campaign.sender ? campaign.sender.name : ""}{" "}
            </label>
            <label>{campaign.messageBody}</label>
          </div>
          <div className="rp-stats">
            {stats[0].value !== 0 || stats[1].value !== 0 ? (
              <ResponsiveContainer
                width="99%"
                height="99%"
                minWidth="0"
                className="rp-chart"
              >
                <PieChart>
                  <Pie data={stats}>
                    {stats.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colors[index]}
                        stroke={colors[index]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <div className="pie-chart-placeholder" />
            )}
            <div></div>
            <div className="rp-percents">
              <label className="rp-percent">
                {Math.round(
                  (stats[0].value * 100) / (stats[0].value + stats[1].value)
                ) || 0}
                %
              </label>
              <label className="rp-label">Delivered</label>
              <label className="rp-percent not-delivered">
                {100 -
                  Math.round(
                    (stats[0].value * 100) / (stats[0].value + stats[1].value)
                  ) || 0}
                %
              </label>
              <label className="rp-label">Not Delivered</label>
            </div>
          </div>
        </div>
        <div className="rp-summary">
          <div className="rp-summary-top">
            <label className="rp-title">Campaign Summary</label>
            <div className="rp-summary-actions">
              <PageNavigationContext.Provider
                value={{ navigation, setNavigation }}
              >
                <PageNavigator changePage={(page) => changePage(page)} />
              </PageNavigationContext.Provider>
              {/* <button className='rp-btn'>Clicks</button> */}
              {/* <button className='rp-btn rp-ummary-btn'>Campaign Summary</button> */}
            </div>
          </div>
          <div className="rp-messages">
            <div className="rp-messages-header">
              <label>Sent on</label>
              <label>Mobile</label>
              <label>First Name</label>
              <label>Last Name</label>
              <label>Delivered</label>
            </div>
            <div className="rpm-content">
              {messages.map((message, i) => (
                <div
                  key={i}
                  className="rpm-message"
                  style={{ backgroundColor: i % 2 === 0 ? "#F7F7F7" : "white" }}
                >
                  <div className="rpm-date">
                    <label>{`${format(message.sentOn.getDate())}/${format(
                      message.sentOn.getMonth()
                    )}/${message.sentOn.getFullYear()}`}</label>
                    <label>{`${format(message.sentOn.getHours())}:${format(
                      message.sentOn.getMinutes()
                    )}:${format(message.delivered.getSeconds())}`}</label>
                  </div>
                  <label>{message.to}</label>
                  <label>{message.firstName}</label>
                  <label>{message.lastName}</label>
                  <div className="rpm-date">
                    <label>{`${format(message.delivered.getDate())}/${format(
                      message.delivered.getMonth()
                    )}/${message.delivered.getFullYear()}`}</label>
                    <label>{`${format(message.delivered.getHours())}:${format(
                      message.delivered.getMinutes()
                    )}:${format(message.delivered.getSeconds())}`}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
