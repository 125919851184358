import React, { useContext } from 'react'

import { useHistory } from "react-router-dom";

import { VenuesContext } from 'context/venueContext'
import VenueItem from './venueItem'

import 'assets/css/venue/show-venues.scss'

export default () => {
    const history = useHistory()

    const { organisations, setOrganisations } = useContext(VenuesContext)

    const setVenue = venue => {
        setOrganisations(organisations.map(organisation => organisation.externalId === venue.externalId ? venue : organisation))
    }

    const removeVenue = externalId => {
        setOrganisations(organisations.filter(org => org.externalId !== externalId))
    }

    return (
        <div className='show-venues'>
            <div className='show-venues-top'>
                <label>Venues</label>
                <button onClick={() => history.push('/organisation')}>Add a new venue</button>
            </div>
            <div className='show-venues-content'>
                {organisations.slice(1).map(organisation => <VenueItem key={organisation.externalId} venue={organisation} setVenue={setVenue} removeVenue={removeVenue} />)}
            </div>
        </div>
    )
}