import React from "react";
import styled from "@emotion/styled";

const StatusButtonWrapper = styled.div`
  font-family: Roboto;
  font-weight: 300;
  font-size: 12px;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 45px;
  width: 50px;

  label{
      font-weight:500;
      text-shadow: 1px 1px #5B5B5B;
  }
`;

const StatusButton = (props) => {
  const { domain, status } = props;

  const getColor = (status) => {
    switch (status) {
      case "Pending":
        return "#FFCC33";

      case "Success":
        return "#4cd964";

      default:
        return "#EA5454";
    }
  };

  return (
    <StatusButtonWrapper
      style={{
        backgroundColor: getColor(domain.status),
      }}
    >
      <label>{domain.status}</label>
    </StatusButtonWrapper>
  );
};

export default StatusButton;
