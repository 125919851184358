import React from "react";
import styled from "@emotion/styled";
import {RadioButton} from "components/widget/radioButton";

const QuestionInputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;

  div {
    display: flex;
    justify-content: flex-start;

    p {
      margin-top: 10px;
      margin-left: 10px;
    }
  }
`;

const QuestionInput = (props) => {
  const {inputs, setInput, inputKey, setInputText} = props;
  const input = inputs[inputKey];
  return (
      <QuestionInputWrapper>
        <div role="button" tabIndex="0"
             onClick={(e) => setInput(inputKey)}>
          <RadioButton selected={input?.selected}/>
          <p className="text-switch">Question</p>
        </div>
        <input
            placeholder="Add Question"
            className="edit-landing-page-custom-input"
            defaultValue={inputs?.question?.text}
            onChange={(e) => setInputText(inputKey, e.target.value)}
        />
      </QuestionInputWrapper>
  );
};

export default QuestionInput;
