import React from "react";

import styled from "@emotion/styled";

import PageTitle from "components/PageTitle";

const DashboardTopWrapper = styled.div`
  grid-row: 1/2;
  grid-column: 1/13;
  height: 100%;
  /* border:1px solid green; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;
  flex: 1;
`;

const DashboardTop = (props) => {
  return (
    <DashboardTopWrapper>
      <PageTitle title={"Connect Dashboard"}></PageTitle>
    </DashboardTopWrapper>
  );
};

export default DashboardTop;
