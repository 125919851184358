export const GET_ORGANISATION_CAMPAIGNS_REQUEST =
  "GET_ORGANISATION_CAMPAIGNS_REQUEST";
export const GET_ORGANISATION_CAMPAIGNS_FAILURE =
  "GET_ORGANISATION_CAMPAIGNS_FAILURE";
export const GET_ORGANISATION_CAMPAIGNS_SUCCESS =
  "GET_ORGANISATION_CAMPAIGNS_SUCCESS";

// --- GET EMAIL STATS

export const getOrganisationCampaignsRequest = (organisationId) => ({
  type: GET_ORGANISATION_CAMPAIGNS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getOrganisationCampaignsFailure = (errorMessage) => ({
  type: GET_ORGANISATION_CAMPAIGNS_FAILURE,
  payload: errorMessage,
});

export const getOrganisationCampaignsSuccess = (organisationCampaigns) => ({
  type: GET_ORGANISATION_CAMPAIGNS_SUCCESS,
  payload: organisationCampaigns,
});
