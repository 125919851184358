import React from "react";
import Dropdown from "../DropDown";

const PersonalisationField = (props) => {
  const { message, setMessage } = props;

  const [personalisations, setPersonalisations] = React.useState([
    {
      externalId: "0",
      name: "Title",
      value: "title",
    },
    {
      externalId: "1",
      name: "Forename",
      value: "forename",
    },
    {
      externalId: "2",
      name: "Surname",
      value: "surname",
    },
    {
      externalId: "3",
      name: "DOB",
      value: "dob",
    },
  ]);
  return (
    <div className="quick-send__input-container">
      <label className="quick-send__input-label">Insert Personalisation:</label>
      <div className="quick-send__input-and-info-container">
        <Dropdown
          hasSecondaryBackground={true}
          dropdownPosition="top"
          className="quick-send__dropdown"
          options={personalisations}
          placeholder="First Name"
          onSelect={(personalisation) =>
            setMessage(message + " {{" + personalisation.value + "}}")
          }
        />
      </div>
    </div>
  );
};

export default PersonalisationField;
