import React from "react";
import styled from "@emotion/styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import InputButton from "./InputButton";

const TimeInputWrapper = styled.div`
  .react-datepicker {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 20px 1px rgba(0, 0, 0, 0.17);
  }
`;

const TimeInput = (props) => {
  const { change, ScheduleFieldValue } = props;
  const [startDate, setStartDate] = React.useState(null);

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    startDate &&
      change("campaignSchedule", { ...ScheduleFieldValue, time: startDate });
  }, [startDate]);

  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <TimeInputWrapper>
      <InputButton
        className="schedule-date-input-button"
        onClick={handleClick}
        currentDate={
          startDate
            ? startDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            : "hh:mm"
        }
      ></InputButton>

      {isOpen && (
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          inline
        />
      )}
    </TimeInputWrapper>
  );
};

export default TimeInput;
