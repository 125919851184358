import React, {ChangeEvent, useEffect, useState} from "react";
import {from, map, Observable} from "rxjs";
import api from "../../../../../utils/api";
import {AxiosResponse} from "axios";
import {Template, TemplateLinks} from "../../../types";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import TemplateSelect from "./TemplateSelect";
import Grid from "@mui/material/Unstable_Grid2";

const getTemplateLinks = (templateId: string): Observable<TemplateLinks> => {
  return from(api.get(`/email/template/links/${templateId}`))
  .pipe(map((response: AxiosResponse<TemplateLinks>) => response.data));
}

export const getTemplates = (templateIds: string[]): Observable<Template[]> => {
  const ids = templateIds.join(",");
  return from(api.get(`/email/templates/${ids}`))
  .pipe(map((response: AxiosResponse<Template[]>) => response.data));
}

type Props = {
  value: string
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setValue: React.Dispatch<React.SetStateAction<string>>
  templateIds: string[]
}

const LinkParam = ({
                     value,
                     setDisabled,
                     setValue,
                     templateIds
                   }: Props) => {

  const [links, setLinks] = useState<string[]>([]);
  const [templateId, setTemplateId] = useState<string>("");
  const [templates, setTemplates] = useState<Template[]>([]);

  const handleTemplateLinks = (id: string) => {
    if (id) {
      getTemplateLinks(id)
      .subscribe((templateLinks: TemplateLinks) => {
        if (templateLinks?.links) {
          setLinks(templateLinks.links);
          setValue(templateLinks.links[0]);
        } else {
          setDisabled(true);
        }
      });
    } else {
      setLinks([]);
      setDisabled(true);
    }
  }

  const onChangeLink = (link: string) => {
    setValue(link);
  }

  useEffect(() => {
    if (templateIds) {
      getTemplates(templateIds)
      .subscribe((t) => {
        if (t && t.length > 0) {
          setTemplates(t);
          setTemplateId(t[0].externalId);
          handleTemplateLinks(t[0].externalId);
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateIds]);

  const onChangeTemplate = (id: string) => {
    setTemplateId(id)
    handleTemplateLinks(id);
  }

  return (
      <Grid
          container
          direction={'column'}
      >
        <TemplateSelect
            templates={templates}
            value={templateId}
            onChange={onChangeTemplate}
        />

        <FormControl
            variant="standard"
            sx={{m: 1, minWidth: 400}}
        >
          <InputLabel
              id="links-label"
              sx={{marginLeft: "10px"}}
          >
            Links
          </InputLabel>
          <Select
              disableUnderline
              disabled={links.length === 0}
              labelId='links-label'
              label="Links"
              sx={{width: '100%'}}
              value={value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeLink(e.target.value)}
          >
            {links && links
            .map((lnk: string) =>
                <MenuItem
                    key={lnk}
                    value={lnk}
                >
                  {lnk}
                </MenuItem>
            )}
          </Select>
          {links.length === 0 && (
              <FormHelperText error>No Links Available For Chosen Template</FormHelperText>
          )}
        </FormControl>
      </Grid>
  );
}

export default LinkParam;
