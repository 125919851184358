import React, {useState} from "react";
import styled from "@emotion/styled";
import {reduxForm} from "redux-form";
import {
  Backdrop, Box, Button,
  Fade,
  Modal,
 Typography
} from "@mui/material";
import {compose} from "recompose";
import makeStyles from "@mui/styles/makeStyles";
import group41Png from "../../../../../../assets/images/Group41.png";
import CreateSegementModal from "../CreateSegementModal";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "25px",
    zIndex: 1,
  },
}));

const SaveOrUpdateContainer = styled.div`
  // display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 556px;
  height: 120px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const TypographyText = styled.div`
  height: 32px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
  position: absolute;
  width: 399px;
  height: 42px;
  left: calc(50% - 399px/2);
  bottom: 6.5px;
`;

const ButtonTextWrapper = styled.label`
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  color: ${(props) =>
    props.disabled ? "#C4C4C4 !important" : "#FF9933 !important"
  };
`;

const SaveSegmentModal = (props) => {
  const {
    disabled,
    currentFilters,
    selectedSegment,
    getSegmentsRequest
  } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [openSaveOrUpdate, setOpenSaveOrUpdate] = useState(false);
  const [openUpdateSegment, setOpenUpdateSegment] = useState(false);
  const [openSaveNewSegment, setOpenSaveNewSegment] = useState(false);

  const handleOpenSaveOrUpdate = () => {
    setOpenSaveOrUpdate(true);
  };

  const handleCloseSaveOrUpdate = () => {
    setOpenSaveOrUpdate(false);
  };

  const handleOpenAddNewSegment = () => {
    setOpenSaveOrUpdate(false);
    setOpenSaveNewSegment(true);
  };

  const handleCloseUpdateSegment = () => {
    setOpenUpdateSegment(false);
  };

  const handleOpenUpdateSegment = () => {
    setOpenSaveOrUpdate(false);
    setOpenUpdateSegment(true);
  };

  return (
      <>
        {currentFilters.length > 0 &&
            <div>
              <ButtonTextWrapper disabled={disabled}
                                 onClick={handleOpenSaveOrUpdate}>
                Save Segment
              </ButtonTextWrapper>

              <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={openSaveOrUpdate}
                  onClose={handleCloseSaveOrUpdate}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
              >
                <Fade in={openSaveOrUpdate}>
                  <div className={classes.paper}>
                    <SaveOrUpdateContainer>
                      <img
                          alt="group"
                          onClick={handleCloseSaveOrUpdate}
                          className={"closeImg"}
                          src={group41Png}
                      />
                      <TypographyText>
                          Save as new segment or update existing?
                      </TypographyText>
                      <ButtonSection>
                        <Button
                            onClick={handleOpenAddNewSegment}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500"
                            }}
                            variant="text"
                        >
                          NEW SEGMENT
                        </Button>
                        <Button
                            onClick={handleOpenUpdateSegment}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500"
                            }}
                            variant="text"
                        >
                          UPDATE EXISTING
                        </Button>
                      </ButtonSection>
                    </SaveOrUpdateContainer>
                  </div>
                </Fade>
              </Modal>

              {/*SAVING NEW SEGMENT*/}
              {((openSaveNewSegment === true)) && (
                  <CreateSegementModal
                      {...props}
                      getSegmentsRequest={getSegmentsRequest}
                      openSaveNewSegment={openSaveNewSegment}
                      setOpenSaveNewSegment={setOpenSaveNewSegment}
                      selectedSegment={selectedSegment}
                      setOpenUpdateSegment={setOpenUpdateSegment}
                      selectedSegment={selectedSegment}
                  />
              )}

              {/*UPDATING EXISTING SEGMENT*/}
              {((openUpdateSegment === true)) && (
                  <CreateSegementModal
                      {...props}
                      getSegmentsRequest={getSegmentsRequest}
                      openUpdateSegment={openUpdateSegment}
                      setOpenUpdateSegment={setOpenUpdateSegment}
                      selectedSegment={selectedSegment}
                      setOpenSaveNewSegment={setOpenSaveNewSegment}
                      selectedSegment={selectedSegment}
                  />
              )}
            </div>
        }
      </>
  );
};

export default compose(
    reduxForm({
      form: "SAVE_SEGMENT_MODAL_FORM",
    })
)(SaveSegmentModal);
