import api from "utils/api";
// import getStartDate from "utils/getStartDate";

import timeUnitToIntervals from "utils/timeUnitToIntervals";

// `/organisation/${organisationId}/analytics?unit=${getApiUnit(
//   unit
// )}&startDate=${getStartDate(unit)}`

export const apiGetEmailGlobalStats = (data) => {
  const {organisationId} = data;

  //const timeUnitInterval = timeUnitToIntervals(timeUnit);
  //const startDate = getStartDate(timeUnit);
  //const apiRoute = `/organisation/${organisationId}/analytics?unit=${timeUnitInterval}&startDate=${startDate}`;
  //const apiRoute = `/track/organisation/${organisationId}/statistics`
  //const test = "be4b3ac8-d89d-45fc-9e9a-635f1d2bafce";
  const apiRoute = `/track/organisation/${organisationId}/summary/statistics`;

  return api.get(apiRoute);
};

// curl --location --request GET 'https://api.dev.unaro.co.uk/v3/api/track/organisation/be4b3ac8-d89d-45fc-9e9a-635f1d2bafce/summary/statistics' \
// --header 'Authorization: Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhY2U5ZDRkMi0wYjhkLTExZWEtOTUxOC00MjAxMGE5YTAwMDMiLCJ0eXBlIjoiYWNjZXNzIiwicHJpbWFyeU9yZ2FuaXNhdGlvbiI6IjhkY2MzNjgwLTBiOGEtMTFlYS05NTE4LTQyMDEwYTlhMDAwMyIsImN1cnJlbnRPcmdhbmlzYXRpb24iOiI4ZGNjMzY4MC0wYjhhLTExZWEtOTUxOC00MjAxMGE5YTAwMDMiLCJwZXJtaXNzaW9ucyI6Ik1FU1NBR0VfUkVBRCxJTlZJVEVfVVNFUixPUkdBTklTQVRJT05fUkVBRCxPUkdBTklTQVRJT05fV1JJVEUsUFJPSkVDVF9SRUFELFBST0pFQ1RfV1JJVEUsQVBJS0VZX1JFQUQsQVBJS0VZX1dSSVRFLE1FU1NBR0VfUkVBRCxURVNUUExBTl9SRUFELFRFU1RQTEFOX1dSSVRFLFJPVVRFX1JFQUQsUk9VVEVfV1JJVEUsVEVTVEVYRUNVVElPTl9SRUFELFRFU1RFWEVDVVRJT05fV1JJVEUsU0xBX1dSSVRFLFNMQV9SRUFELExJQ0VOQ0VfV1JJVEUiLCJsaWNlbmNlcyI6IltdIiwiZXhwIjoxNjIzNDE2NjE3fQ.TRwDfGcp7qc7nw1kKAaQcX4CIjwY4WokgqdCX7nizgiiAWwJ2HWk6Gypa0xX8qRHuBuZL6sSyovWhH-WmDrGFA' \
// --header 'Cookie: JSESSIONID=FL6rt9jV4TD29EcbvT6a3jYTgEZigE_5XvX4X4eI'

// 'https://api.dev.unaro.co.uk/v3/api/track/organisation/be4b3ac8-d89d-45fc-9e9a-635f1d2bafce/summary/statistics'

// `/organisation/${organisationId}/analytics?unit=${getApiUnit(
//   unit
// )}&startDate=${getStartDate(unit)}`

// curl --location --request GET 'https://api.dev.unaro.co.uk/v3/api/track/organisation/be4b3ac8-d89d-45fc-9e9a-635f1d2bafce/summary/statistics' \
// --header 'Authorization: Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhY2U5ZDRkMi0wYjhkLTExZWEtOTUxOC00MjAxMGE5YTAwMDMiLCJ0eXBlIjoiYWNjZXNzIiwicHJpbWFyeU9yZ2FuaXNhdGlvbiI6IjhkY2MzNjgwLTBiOGEtMTFlYS05NTE4LTQyMDEwYTlhMDAwMyIsImN1cnJlbnRPcmdhbmlzYXRpb24iOiI4ZGNjMzY4MC0wYjhhLTExZWEtOTUxOC00MjAxMGE5YTAwMDMiLCJwZXJtaXNzaW9ucyI6Ik1FU1NBR0VfUkVBRCxJTlZJVEVfVVNFUixPUkdBTklTQVRJT05fUkVBRCxPUkdBTklTQVRJT05fV1JJVEUsUFJPSkVDVF9SRUFELFBST0pFQ1RfV1JJVEUsQVBJS0VZX1JFQUQsQVBJS0VZX1dSSVRFLE1FU1NBR0VfUkVBRCxURVNUUExBTl9SRUFELFRFU1RQTEFOX1dSSVRFLFJPVVRFX1JFQUQsUk9VVEVfV1JJVEUsVEVTVEVYRUNVVElPTl9SRUFELFRFU1RFWEVDVVRJT05fV1JJVEUsU0xBX1dSSVRFLFNMQV9SRUFELExJQ0VOQ0VfV1JJVEUiLCJsaWNlbmNlcyI6IltdIiwiZXhwIjoxNjIzNDE2NjE3fQ.TRwDfGcp7qc7nw1kKAaQcX4CIjwY4WokgqdCX7nizgiiAWwJ2HWk6Gypa0xX8qRHuBuZL6sSyovWhH-WmDrGFA' \
// --header 'Cookie: JSESSIONID=FL6rt9jV4TD29EcbvT6a3jYTgEZigE_5XvX4X4eI'

// curl --location --request GET 'https://api.dev.unaro.co.uk/v3/api/organisations/be4b3ac8-d89d-45fc-9e9a-635f1d2bafce/licences'



