import React from "react";
import styled from "@emotion/styled";

import Navigator from "./Navigator";

import SendCreateTopWrapper from "./SendCreateTopWrapper";

const NavigatorWrapper = styled.div`
  margin-right: 100px;
`;

const SendCreateTop = (props) => {
  const {
    organisationId,
    currentPagination,
    getFeaturedTemplatesRequest,
    getSavedTemplatesRequest,
    setTemplateType,
    templateType,
    featuredTemplatesPagination,
    savedTemplatesPagination,
  } = props;

  const [navigatorPage, setNavigatorPage] = React.useState(0);

  React.useEffect(() => {
    if (templateType === "featured") {
      getFeaturedTemplatesRequest(navigatorPage);
    }

    if (templateType === "saved") {
      getSavedTemplatesRequest(organisationId, navigatorPage);
    }
  }, [navigatorPage]);

  return (
    <SendCreateTopWrapper>
      <div className="ett-left">
        <label
          style={{
            color: templateType === "featured" ? "#EA5454" : "#000000",
          }}
          onClick={() => setTemplateType("featured")}
        >
          Featured Templates
        </label>
        <label
          style={{ color: templateType === "saved" ? "#EA5454" : "#000000" }}
          onClick={() => setTemplateType("saved")}
        >
          Saved Templates
        </label>
      </div>

      {currentPagination?.totalPages > 1 && (
        <NavigatorWrapper>
          <Navigator
            setNavigatorPage={setNavigatorPage}
            currentPagination={currentPagination}
            featuredTemplatesPagination={featuredTemplatesPagination}
            savedTemplatesPagination={savedTemplatesPagination}
          />
        </NavigatorWrapper>
      )}

      {/* <div className="ett-right">
          <Dropdown />
          <div className="ett-search">
            <input placeholder="Search" />
            <SearchIcon className="etts-icon" />
          </div>
        </div> */}
    </SendCreateTopWrapper>
  );
};

export default SendCreateTop;
