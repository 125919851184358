import styled from "@emotion/styled";

const AddDomainPopupWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .verify-domain-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    overflow: hidden;
    outline: 0;
    width: 500px;
    padding: 20px;

    .close-btn {
      width: 14px;
      height: 14px;
      cursor: pointer;
      align-self: flex-end;

      path {
        fill: #c4c4c4;
      }
    }

    label {
      font-family: Roboto;
      font-weight: 300;
      font-size: 12px;
      text-align: center;
      max-width: 450px;
    }

    .title {
      font-family: Roboto;
      font-weight: bold;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .inputs-panel {
      margin: 30px 0 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width:100%;

      input {
        width: 80%;
        border: 1px solid #c4c4c4;
        border-radius: 22px;
        padding: 7px 14px;
        font-size: 12px;
        outline: none;
        margin-bottom: 10px;
      }
    }
  }
`;

export default AddDomainPopupWrapper;
