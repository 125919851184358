import Chart from "chart.js";
import React from "react";

import ChartHeader from "./ChartHeader";

import {
  generateOptions,
  defaultChartDropdownButtons,
} from "./chartConfig";

class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      chartOptions: null,
      chartMaxVal: null,
      selected: null,
    };
  }

  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data !== this.state.chartData ||
      nextProps.selected !== this.state.selected
    ) {
      this.setState({ chartData: nextProps.data });
      this.setState({ selected: nextProps.selected });
    }

    if (nextProps.chartMaxVal !== this.state.chartMaxVal) {
      this.setState({ chartMaxVal: nextProps.chartMaxVal });
      this.setState({ chartOptions: generateOptions(nextProps.chartMaxVal) });
    }
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");

    const myLineChart = new Chart(myChartRef, {
      type: "line",
      data: this.state.chartData,
      options: this.state.chartOptions,
    });

    return myLineChart;
  };

  render() {
    return (
      <div className={`chart-container  ${"chart-container-row"}`}>
        <ChartHeader
          chartDropdownButtons={
            this.props.chartDropdownButtons
              ? this.props.chartDropdownButtons
              : defaultChartDropdownButtons
          }
          withIcon={this.props.withIcon}
          chartTitle={this.props.chartTitle}
          setCurrentTimeUnit={this.props.setCurrentTimeUnit}
          currentTimeUnit={this.props.currentTimeUnit}
          setChartTouched={this.props.setChartTouched}
        />

        <div
          className={`stats-chart ${
            this.props.locked && "stats-chart-locked"
          } ${!this.props.admin && "stats-chart-row"}`}
        >
          {/* <Line data={createBarChart} options={updatedOptions} /> */}
          <canvas id="myChart" ref={this.chartRef} />
        </div>
      </div>
    );
  }
}

export default LineChart;
