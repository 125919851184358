import {
  UPDATE_SEND_CONTACTS_REQUEST,
  UPDATE_SEND_CONTACTS_FAILURE,
  UPDATE_SEND_CONTACTS_SUCCESS,
} from "../actions";

const initialState = {
  loading: false,
  errorMessage: null,
  updatedContacts: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SEND_CONTACTS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        updatedContacts: null,
      };
    }

    case UPDATE_SEND_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        updatedContacts: null,
      };
    }

    case UPDATE_SEND_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        updatedContacts: action.payload,
      };
    }

    default:
      return state;
  }
}
