import React, {useState} from "react";
import styled from "@emotion/styled";

import {Check, X} from "phosphor-react";

const CreateCampaignPopupWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .add-email-campaign-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    overflow: hidden;
    outline: 0;
    width: 400px;
    padding: 20px;

    .close-btn {
      width: 14px;
      height: 14px;
      cursor: pointer;
      align-self: flex-end;

      path {
        fill: #c4c4c4;
      }
    }

    .title {
      font-family: Roboto;
      font-weight: bold;
      font-size: 20px;
      font-weight: 700;
    }

    input {
      width: 80%;
      margin: 30px 0;
      border: 1px solid #c4c4c4;
      border-radius: 21.75px;
      padding: 7px 14px;
      font-size: 12px;
      outline: none;
    }
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 45px;
  width: 70px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: ${(props) => (props.isNameValidated ? "1" : "0.5")};
  background: ${(props) => (props.isNameValidated ? "#ea5454" : "#b05a5a")};

  transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1);

  label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
  }
`;

const CreateCampaignPopup = (props) => {
  const { save, close } = props;

  const [name, setName] = useState();

  const [isNameValidated, setIsNameValidated] = React.useState(false);

  React.useEffect(() => {
    if (name && name.length > 0) {
      setIsNameValidated(true);
    } else {
      setIsNameValidated(false);
    }
  }, [name]);

  const saveTemplate = () => {
    save(name);
  };

  const onClick = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <CreateCampaignPopupWrapper onClick={onClick}>
      <div className="add-email-campaign-popup-content">
        <X className="close-btn" onClick={close} size={22} />
        <label className="title">Name your Campaign</label>
        <input
          placeholder="Campaign Name"
          autoFocus
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
        />
        <SaveButton
          isNameValidated={isNameValidated}
          onClick={() =>
            isNameValidated ? saveTemplate() : console.log("please fill name")
          }
        >
          <label>Save</label>
          <Check className="check" color="white" onClick={close} size={20} />
        </SaveButton>
      </div>
    </CreateCampaignPopupWrapper>
  );
};

export default CreateCampaignPopup;
