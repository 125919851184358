import React from 'react';
import {Backdrop, Fade, Modal} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '25px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
}));

export default function DeleteAudience() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <div>
        <div onClick={handleOpen}>
          {/*react-transition-group*/}
          <img alt="delete" src={require("../../../assets/images/DeleteA.png").default}/>Delete
        </div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <img alt="close" onClick={handleClose} className={'closeImg'}
                   src={require("../../../assets/images/Group41.png").default}/>
              <div className={'audienceBlock'}>
                <div className={'successfulImport'}>
                  <img alt="confirmation" src={require("../../../assets/images/areyousure.png").default}/>
                  <h2 id="simple-modal-title">Are You Sure?</h2>
                  <p className={'areYouSure'}>
                    <span>Delete 34 Customers.</span> This cannot be undone.</p>

                  <div className="exportButton">
                    <div className="exportCSV" onClick={handleClose}>Yes,
                      Delete
                    </div>
                    <div className="cancelCSV" onClick={handleClose}>Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
  );
}