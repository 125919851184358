import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, Button, Step, StepLabel, Stepper, TextField} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import ActionTypeSelect from "../ActionTypeSelect";
import {Action, ActionType, getActionTypeMap, Param} from "../../../types";
import WaitDurationParam from "../Params/WaitDurationParam";
import AudienceParam from "../Params/AudienceParam";
import Conditions from "../Conditions";
import uuid from "react-uuid";
import EmailAction from "./EmailAction";
import SmsParams from "../Params/SmsParams";

const actionTypeMap: Map<any, string> = getActionTypeMap();

type Props = {
  organisationId: string
  action: Action
  saveAction: (action: Action) => void
  cancel: () => void
  templateIds: string[]
}

const ActionStepper = ({organisationId, action, saveAction, cancel, templateIds}: Props) => {

  const [activeStep, setActiveStep] = useState(0);
  const [type, setType] = useState<ActionType>(ActionType.EMAIL);
  const [param, setParam] = useState<Param | null>(null);
  const [params, setParams] = useState<Param[]>([]);
  const [name, setName] = useState<string>(actionTypeMap.get(ActionType.EMAIL));
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (action) {
      setType(action.actionType);
      setName(action.name);
      setParams(action.params);
    }
  }, [action]);

  useEffect(() => {
    if (param) {
      // filter out any existing param
      const newParams: Param[] = params.filter((p: Param) => p.paramType !== param.paramType);
      const concat = newParams.concat(param);
      setParams(concat);
    }
  }, [param]);

  useEffect(() => {
    switch (activeStep) {
      case 0: {
        setDisabled(false);
        break;
      }
      case 1: {
        //TODO

        //setDisabled(false);
        break;
      }
      case 2: {
        //TODO

        setDisabled(false);
        break;
      }
      case 3: {
        if (!name) {
          setDisabled(true);
        }
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const save = () => {
    saveAction({
      stepNumber: action ? action.stepNumber : null,
      name: name,
      actionType: type,
      params: params
    });
  };

  const onChangeName = (name: string) => {
    setName(name);
    setDisabled(!name)
  }

  const removeConditions = () => {
    setParams(params.filter((p: Param) => !p.paramType.toString().startsWith("CONDITION")));
  }

  const getRequiredParams = () => {
    switch (type) {
      case ActionType.EMAIL:
        return <EmailAction
            organisationId={organisationId}
            params={params}
            setParam={setParam}
            setDisabled={setDisabled}
        />

      case ActionType.SMS:
        return <SmsParams
            organisationId={organisationId}
            setParam={setParam}
        />

      case ActionType.WAIT:
        return <WaitDurationParam setParam={setParam} params={params}/>

      case ActionType.AUDIENCE:
        return <AudienceParam
            organisationId={organisationId}
            params={params}
            setParam={setParam}
            setDisabled={setDisabled}
        />
    }
  }

  type Step = {
    label: string
    content: any
    skip: () => boolean
  }

  const steps: Step[] = [
    {
      label: 'Select Action Type',
      content: (
          <ActionTypeSelect
              type={type}
              setType={(val) => {
                if (val) {
                  setType(val);
                  setName(`${actionTypeMap.get(val)} action`);
                  setParams([]);
                }
              }}
          />
      ),
      skip: () => false
    },
    {
      label: 'Set Parameters',
      content: getRequiredParams(),
      skip: () => false
    },
    {
      label: 'Set Conditions',
      content: (
          <Conditions
              params={params}
              setParam={setParam}
              setDisabled={setDisabled}
              removeConditions={removeConditions}
              templateIds={templateIds}
          />
      ),
      skip: () => type === ActionType.SMS
    },
    {
      label: 'Name',
      content: (
          <TextField
              id='edit-action-name'
              key='edit-action-name'
              label="Name"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeName(e.target.value)}
              sx={{width: 350}}
              fullWidth
          />
      ),
      skip: () => false
    },
  ];

  const handleBack = () => {
    let nextStep = activeStep - 1;

    nextStep > 0 && steps[nextStep].skip() && nextStep--;

    setActiveStep(nextStep);
    setDisabled(false);
  }

  const handleNext = () => {
    let nextStep = activeStep + 1;

    nextStep < steps.length && steps[nextStep].skip() && nextStep++;

    setActiveStep(nextStep);
  }

  return (
      <Box sx={{width: '100%'}}>
        <Stepper activeStep={activeStep}>
          {steps.map((step) => {
            return (
                <Step key={uuid()}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
            );
          })}
        </Stepper>
        <>
          {activeStep === steps.length ? (
              <>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 2,
                  marginTop: 1,
                }}>
                  <Box sx={{flex: '1 1 auto'}}/>
                  <Button
                      onClick={cancel}
                      color="error"
                      size="small"
                  >
                    Cancel
                  </Button>
                  <Box sx={{flex: '1 1 auto'}}/>
                  <Button
                      onClick={() => setActiveStep(0)}
                      color='error'
                      disabled={disabled}
                  >
                    Reset
                  </Button>
                  <Box sx={{flex: '1 1 auto'}}/>
                  <Button
                      onClick={save}
                      disabled={disabled}
                  >
                    Save
                  </Button>
                  <Box sx={{flex: '1 1 auto'}}/>
                </Box>
              </>
          ) : (
              <>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                  <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{mr: 1}}
                  >
                    Back
                  </Button>

                  <Box sx={{flex: '1 1 auto'}}/>

                  <Button
                      onClick={cancel}
                      color="error"
                      size="small"
                  >
                    Cancel
                  </Button>

                  <Box sx={{flex: '1 1 auto'}}/>

                  <Button
                      onClick={handleNext}
                      disabled={disabled}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </>
          )}
          <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              sx={{marginTop: 1, height: 300}}
          >
            <Grid
                xs={4}
                sx={{textAlign: 'center'}}
            >
              {activeStep >= 0 && activeStep < steps.length && steps[activeStep].content}
            </Grid>

            {/*//TODO console.log("REMOVE ME") */}
            {/*<Grid xs={12} marginTop={3} sx={{textAlign: 'center'}}>*/}
            {/*  { params && JSON.stringify(params) }*/}
            {/*</Grid>*/}

          </Grid>
        </>
      </Box>
  );
}

export default ActionStepper;
