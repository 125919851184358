import React from "react";
import styled from "@emotion/styled";

import DateInput from "./DateInput";
import TimeInput from "./TimeInput";

const ScheduleFieldTimeInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left:20px;

  padding-bottom: 50px;

  > * {
    margin-right: 10px;
  }

  .time-zone-text {
    font-size: 16px;
    font-family: Roboto;
  }
`;

const ScheduleFieldTimeInputs = (props) => {
  const { change, ScheduleFieldValue, scheduleDate, setScheduleDate } = props;
  


  return (
    <ScheduleFieldTimeInputsWrapper>
      <DateInput
        ScheduleFieldValue={ScheduleFieldValue}
        change={change}
      ></DateInput>
      <label>at </label>
      <TimeInput
        ScheduleFieldValue={ScheduleFieldValue}
        change={change}
      ></TimeInput>
      <div className="time-zone-text">Europe/London (+00:00 GMT) </div>
    </ScheduleFieldTimeInputsWrapper>
  );
};

export default ScheduleFieldTimeInputs;
