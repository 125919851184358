import actions from "../actions";

const {
  UPDATE_CURRENT_CAMPAIGN_REQUEST,
  UPDATE_CURRENT_CAMPAIGN_FAILURE,
  UPDATE_CURRENT_CAMPAIGN_SUCCESS,
  CREATE_CURRENT_CAMPAIGN_REQUEST,
  CREATE_CURRENT_CAMPAIGN_FAILURE,
  CREATE_CURRENT_CAMPAIGN_SUCCESS,
  RESET_ALL_VALUES_REQUEST,
  RESET_ALL_VALUES_FAILURE,
  RESET_ALL_VALUES_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  name: null,
  contactsCurrentListType: null,
  contacts: [],
  template: null,
  emailTo: null,
  emailFrom: null,
  emailSubject: null,
  emailPreviewText: null,
  isScheduleOn: false,
  schedule: null,
  saved: false,
  isCampaignSent: false,
  createdCampaign: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_CAMPAIGN_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case UPDATE_CURRENT_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case UPDATE_CURRENT_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        [action.payload.fieldToUpdate]: action.payload.fieldData,
      };
    }

    //---

    case CREATE_CURRENT_CAMPAIGN_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        isCampaignSent: null,
        createdCampaign: null,
      };
    }

    case CREATE_CURRENT_CAMPAIGN_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        isCampaignSent: false,
        createdCampaign: null,
      };
    }

    case CREATE_CURRENT_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        isCampaignSent: true,
        createdCampaign: action.payload.externalId,
      };
    }

    //---

    case RESET_ALL_VALUES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case RESET_ALL_VALUES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case RESET_ALL_VALUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        name: null,
        contactsCurrentListType: null,
        contacts: [],
        template: null,
        emailTo: null,
        emailFrom: null,
        emailSubject: null,
        emailPreviewText: null,
        isScheduleOn: false,
        schedule: null,
        saved: false,
        isCampaignSent: false,
      };
    }

    default:
      return state;
  }
}
