import React, {ChangeEvent} from "react";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {Template} from "../../../types";

type Props = {
  templates: Template[]
  value: string
  onChange: (id: string) => void
}

const TemplateSelect = ({templates, value, onChange}: Props) => {
  return (
      <FormControl
          variant="standard"
          sx={{m: 1, minWidth: 400}}
      >
        <InputLabel
            id="template-type-label"
            sx={{marginLeft: "10px"}}
        >
          Templates
        </InputLabel>
        <Select
            disableUnderline
            labelId='template-type-label'
            label="Templates"
            sx={{width: '100%'}}
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            disabled={templates.length === 0}
        >
          {templates && templates
          .map((t: Template) =>
              <MenuItem
                  key={t.externalId}
                  value={t.externalId}
              >
                {t.name}
              </MenuItem>
          )}
        </Select>
        {templates.length === 0 && (
            <FormHelperText error>No Templates Available</FormHelperText>
        )}
      </FormControl>
  );
}
export default TemplateSelect;
