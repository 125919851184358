import React from 'react'

import { ReactComponent as On } from '../../assets/images/switch-on.svg';
import { ReactComponent as Off } from '../../assets/images/switch-off.svg';

import '../../assets/css/venue/venue-network-item.scss'

export default ({ name, on = false, changeOn }) => {
    return (
        <div className='venue-network-item'>
            <label className='venue-network-item-name'>{name}</label>
            <div className='venue-network-item-switch'>
                <label>Guest mode</label>
                {on ? <On onClick={changeOn} /> : <Off onClick={changeOn} />}
            </div>
        </div>
    )
}