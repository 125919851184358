import {put, takeLatest} from "redux-saga/effects";

// import { apiGetVisits } from "./api";
import actions from "../actions";

const { GET_CONTACTS_REQUEST, getContactsFailure, getContactsSuccess } =
  actions;

function* getContactsWorker(action) {
  try {
    yield put(getContactsSuccess());
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getContactsFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

export default function* watcher() {
  yield takeLatest(GET_CONTACTS_REQUEST, getContactsWorker);
}
