import React, {useEffect, useState} from "react";
import {Action, Param, ParamType} from "../../types";
import ActionRow from "./ActionRow";
import EditActionDialogStepper from "./ActionRow/EditActionDialogStepper";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Grid from '@mui/material/Unstable_Grid2';
import uuid from "react-uuid";

type Props = {
  organisationId: string
  actions: Action[],
  updateAutomation: (field: string, value: any) => void
}

const Actions = ({organisationId, actions, updateAutomation}: Props) => {

  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [templateIds, setTemplateIds] = useState<string[]>([]);

  const updateAction = (action: Action) => {
    updateAutomation('actions',
        actions.map(a => {
          return a.stepNumber === action.stepNumber
              ? action
              : a
        })
    );
  }

  const addAction = (action: Action) => {
    updateAutomation('actions', [
      ...(actions ? actions : []),
      {
        ...action,
        stepNumber: actions.length
      }
    ]);
  }

  const deleteAction = (action: Action) => {
    let index = 0;
    updateAutomation('actions',
        actions
        .filter((a: Action) => a.stepNumber !== action.stepNumber)
        .map((a: Action) => {
          a.stepNumber = index++;
          return a;
        })
    );
  }

  useEffect(() => {
    if (actions) {
      const templateIds = actions
      .flatMap((a: Action) => a.params
      .filter((p: Param) => p.paramType === ParamType.TEMPLATE_ID)
      .map((p: Param) => p.paramValue));
      setTemplateIds(templateIds);
    }
  }, [actions]);

  return (
      <>
        <Grid
            container
            marginTop={1}
            direction='column'
            alignContent='center'
            justifyContent='center'
        >
          <Grid
              marginTop={1.5}
              textAlign='center'
          >
            <Button
                // variant="outlined"
                startIcon={<AddIcon/>}
                onClick={() => setOpenEdit(true)}
            >
              Action
            </Button>
          </Grid>
          <Grid>
            {actions && actions.length > 0 &&
                actions.map(action => (
                    <ActionRow
                        key={uuid()}
                        organisationId={organisationId}
                        action={action}
                        addAction={addAction}
                        updateAction={updateAction}
                        deleteAction={deleteAction}
                        templateIds={templateIds}
                    />
                ))
            }
          </Grid>
        </Grid>
        <EditActionDialogStepper
            organisationId={organisationId}
            addAction={addAction}
            updateAction={updateAction}
            action={null}
            open={openEdit}
            setOpen={setOpenEdit}
            templateIds={templateIds}
        />
      </>
  )
}

export default Actions;
