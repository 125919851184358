export const GET_ALL_CONTACTS_REQUEST = "GET_ALL_CONTACTS_REQUEST";
export const GET_ALL_CONTACTS_FAILURE = "GET_ALL_CONTACTS_FAILURE";
export const GET_ALL_CONTACTS_SUCCESS = "GET_ALL_CONTACTS_SUCCESS";

export const TOGGLE_CONTACT_REQUEST = "TOGGLE_CONTACT_REQUEST";
export const TOGGLE_CONTACT_FAILURE = "TOGGLE_CONTACT_FAILURE";
export const TOGGLE_CONTACT_SUCCESS = "TOGGLE_CONTACT_SUCCESS";

export const TOGGLE_ALL_CONTACTS_REQUEST = "TOGGLE_ALL_CONTACTS_REQUEST";
export const TOGGLE_ALL_CONTACTS_FAILURE = "TOGGLE_ALL_CONTACTS_FAILURE";
export const TOGGLE_ALL_CONTACTS_SUCCESS = "TOGGLE_ALL_CONTACTS_SUCCESS";

export const CREATE_CONTACT_REQUEST = "CREATE_CONTACT_REQUEST";
export const CREATE_CONTACT_FAILURE = "CREATE_CONTACT_FAILURE";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";

export const IMPORT_CONTACTS_REQUEST = "IMPORT_CONTACTS_REQUEST";
export const IMPORT_CONTACTS_FAILURE = "IMPORT_CONTACTS_FAILURE";
export const IMPORT_CONTACTS_SUCCESS = "IMPORT_CONTACTS_SUCCESS";

export const DELETE_CONTACTS_REQUEST = "DELETE_CONTACTS_REQUEST";
export const DELETE_CONTACTS_FAILURE = "DELETE_CONTACTS_FAILURE";
export const DELETE_CONTACTS_SUCCESS = "DELETE_CONTACTS_SUCCESS";

// --- GET ALLCONTACTS

export const getAllContactsRequest = (
    organisationId,
    pageNumber,
    pageSize,
    searchString,
    criteria
) => ({
  type: GET_ALL_CONTACTS_REQUEST,
  payload: {
    organisationId,
    pageNumber,
    pageSize,
    searchString,
    criteria,
  },
});

export const getAllContactsFailure = (errorMessage) => ({
  type: GET_ALL_CONTACTS_FAILURE,
  payload: errorMessage,
});

export const getAllContactsSuccess = (allContacts, currentPagination) => ({
  type: GET_ALL_CONTACTS_SUCCESS,
  payload: {allContacts, currentPagination},
});

// --- TOGGLE  CONTACT

export const toggleContactRequest = (contactId) => ({
  type: TOGGLE_CONTACT_REQUEST,
  payload: {
    contactId,
  },
});

export const toggleContactFailure = (errorMessage) => ({
  type: TOGGLE_CONTACT_FAILURE,
  payload: errorMessage,
});

export const toggleContactSuccess = (contactId) => ({
  type: TOGGLE_CONTACT_SUCCESS,
  payload: {
    contactId,
  },
});

// --- CREATE  CONTACT

export const createContactRequest = (newContactData) => ({
  type: CREATE_CONTACT_REQUEST,
  payload: {
    newContactData,
  },
});

export const createContactFailure = (errorMessage) => ({
  type: CREATE_CONTACT_FAILURE,
  payload: errorMessage,
});

export const createContactSuccess = (newContactData) => ({
  type: CREATE_CONTACT_SUCCESS,
  payload: {
    newContactData,
  },
});

// --- TOGGLE ALL CONTACTS

export const toggleAllContactsRequest = (contacts, shouldToggleOn) => ({
  type: TOGGLE_ALL_CONTACTS_REQUEST,
  payload: {
    contacts,
    shouldToggleOn,
  },
});

export const toggleAllContactsFailure = (errorMessage) => ({
  type: TOGGLE_ALL_CONTACTS_FAILURE,
  payload: errorMessage,
});

export const toggleAllContactsSuccess = (updatedContacts) => ({
  type: TOGGLE_ALL_CONTACTS_SUCCESS,
  payload: {
    updatedContacts,
  },
});

// --- IMPORT CONTACTS

export const importContactsRequest = (
    organisationId,
    file,
    action,
    columns
) => ({
  type: IMPORT_CONTACTS_REQUEST,
  payload: {
    organisationId,
    file,
    action,
    columns,
  },
});

export const importContactsFailure = (errorMessage) => ({
  type: IMPORT_CONTACTS_FAILURE,
  payload: errorMessage,
});

export const importContactsSuccess = (updatedContacts) => ({
  type: IMPORT_CONTACTS_SUCCESS,
  payload: {
    updatedContacts,
  },
});

// --- DELETECONTACTS

export const deleteContactsRequest = (organisationId, contactsIds) => ({
  type: DELETE_CONTACTS_REQUEST,
  payload: {organisationId, contactsIds},
});

export const deleteContactsFailure = (errorMessage) => ({
  type: DELETE_CONTACTS_FAILURE,
  payload: errorMessage,
});

export const deleteContactsSuccess = (deletedContacts) => ({
  type: DELETE_CONTACTS_SUCCESS,
  payload: {
    deletedContacts,
  },
});
