import actions from "../actions";

const {
  SELECT_ENTITY_REQUEST,
  SELECT_ENTITY_FAILURE,
  SELECT_ENTITY_SUCCESS,
  CLEAR_ENTITY_REQUEST,
  CLEAR_ENTITY_FAILURE,
  CLEAR_ENTITY_SUCCESS,
  GET_SELECTED_ENTITY_SUMMARY_REQUEST,
  GET_SELECTED_ENTITY_SUMMARY_FAILURE,
  GET_SELECTED_ENTITY_SUMMARY_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  selectedEntity: null,
  selectedEntitySummary: null,
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_ENTITY_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        selectedEntity: null,
      };
    }

    case SELECT_ENTITY_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SELECT_ENTITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedEntity: action?.payload,
      };
    }

    case CLEAR_ENTITY_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case CLEAR_ENTITY_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case CLEAR_ENTITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedEntity: null,
      };
    }

    case GET_SELECTED_ENTITY_SUMMARY_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        selectedEntitySummary: null,
      };
    }

    case GET_SELECTED_ENTITY_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_SELECTED_ENTITY_SUMMARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedEntitySummary: action.payload,
      };
    }

    default:
      return state;
  }
}
