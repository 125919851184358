import React from "react";

import Slider from "rc-slider";

import "assets/css/widget/percent-slider.scss";
import "rc-slider/assets/index.css";

export const PercentSlider = ({
  title,
  value = 0,
  setValue,
  showPercentage,
  className,
  maxValue = 1,
  minValue = 0,
  stepCount = 0.1,
}) => {
  return (
    <div className={`percent-slider ${className}`}>
      <label className="percent-slider-title">{title}</label>
      <Slider
        min={minValue ? minValue : 0}
        max={maxValue ? maxValue : 1}
        className="slider"
        value={value}
        step={stepCount}
        onChange={(value) => setValue(value)}
      />
      {showPercentage && (
        <label className="percent-slider-value">{`${Math.round(
          value * 100
        )}%`}</label>
      )}
    </div>
  );
};
