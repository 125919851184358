import styled from "@emotion/styled";

const SendCreateTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  padding: 15px 10px;
  margin: 10px;

  .ett-left {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      font-family: Roboto;
      font-weight: 300;
      font-size: 22px;
      border-right: 1px solid #c4c4c4;
      padding: 10px 40px;
      cursor: pointer;
      text-align:center;
      margin:0 auto;
    }

    :nth-child(2) {
      margin-left: 40px;
    }
  }

  .ett-right {
    display: flex;
    flex-direction: row;
    align-items: center;

    .dropdown {
      right: 40px;
      background: white;
      border: 1px solid #c4c4c4;
      min-width: 180px;
      padding: 10px 20px;
      margin-right: 20px;

      label {
        font-weight: 400;
        font-family: Roboto;
        color: #000000;
      }

      .logo {
        path {
          fill: #c4c4c4;
        }
      }
    }

    .options {
      min-width: 180px;
    }

    .ett-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      border: 1px solid #c4c4c4;
      border-radius: 30px;
      padding: 7px 15px;
      min-width: 180px;

      input {
        outline: none;
        border: none;
      }

      .etts-icon {
        line {
          stroke: #c4c4c4;
        }

        circle {
          stroke: #c4c4c4;
        }
      }
    }
  }
`;

export default SendCreateTopWrapper;
