import React from "react";

import { ReactComponent as WarningIcon } from "assets/images/warningIcon.svg";

import "assets/css/widget/delete-popup.scss";

const DeletePopup = (props) => {
  const { onCancel, deleteLandingPageRequest, landingPageToBeDeleted } = props;
  return (
    <div className="delete-popup">
      <div className="delete-popup-content">
        <button onClick={() => onCancel()} className="delete-popup-close-icon">
          {" "}
          &#10005;
        </button>
        <div>
          <WarningIcon />
        </div>
        <div className="delete-popup-information">
          <h3>Are you sure?</h3>
          <p>
            Are you sure you wish to delete
            <span className="delete-popup-landing-name">
              {" "}
              {landingPageToBeDeleted.name}
            </span>
            ?
          </p>
        </div>
        <div className="delete-popup-actions">
          <button
            className="delete-popup-button cancel-button"
            onClick={onCancel}
          >
            Cancel <span> &#10005;</span>
          </button>
          <button
            className="delete-popup-button continue-button"
            onClick={async () => {
              await deleteLandingPageRequest(landingPageToBeDeleted.externalId);
              await onCancel();
            }}
          >
            Continue<span> &#10003;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
