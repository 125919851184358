/* eslint-disable react-hooks/exhaustive-deps */
import React, {ChangeEvent, useEffect, useState} from 'react';
import {from, map, Observable} from "rxjs";
import {AxiosResponse} from "axios";
import api from "../../../../../utils/api";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {Param, ParamType} from "../../../types";
import uuid from "react-uuid";

type Identity = {
  externalId: string,
  organisationId: string,
  type: string,
  identity: string,
  email: string,
  name: string,
  status
}

export const getIdentities = (organisationId: string): Observable<Identity[]> => {
  return from(api.get(`/email/organisation/${organisationId}/identity`))
  .pipe(map((response: AxiosResponse<Identity[]>) => response.data));
}

const getLabel = (identity: Identity) => {
  return identity ? `${identity.email} (${identity.name})` : '';
}

type Props = {
  organisationId: string
  sender: string
  setParam: React.Dispatch<React.SetStateAction<Param>>
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

// horrible hack to prevent repeat calls of getSender
// not sure why this is needed, debounce didn't work
let done = false;

const EmailSenderParam = ({organisationId, sender, setParam, setDisabled}: Props) => {

  const [value, setValue] = useState<string>("");
  const [identities, setIdentities] = useState<Identity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    organisationId && getSenders(organisationId);
    // eslint-disable-next-line
  }, [organisationId]);

  useEffect(() => {
    sender && onChange(sender);
  }, [sender]);

  const onChange = (id: string) => {
    const identity = identities.find((i: Identity) => i.externalId === id);
    setParam(getParam(id, identity));
    setValue(id);
  }

  const getParam = (value: string, identity: Identity): Param => {
    return {
      id: uuid(),
      paramType: ParamType.SENDER,
      paramValue: value,
      label: getLabel(identity)
    }
  }

  const getSenders = (organisationId: string) => {
    if (organisationId && (!done || identities.length === 0)) {
      setLoading(true);
      setDisabled(true);
      getIdentities(organisationId)
      .subscribe((response: Identity[]) => {
        if (response && response.length > 0) {
          setIdentities(response);
          if (sender) {
            const identity = identities.find((i: Identity) => i.externalId === sender);
            if (identity) {
              setValue(sender);
              setParam(getParam(sender, identity));
            }
          } else {
            setValue(response[0].externalId);
            setParam(getParam(response[0].externalId, response[0]));
          }
          setLoading(false);
          setDisabled(false);

          // horrible hack to prevent repeat calls,
          // not sure why debounce doesn't work
          done = true;
        } else {
          setDisabled(true);
          done = true;
        }
      });
    }
  }

  return (
      <FormControl
          variant="standard"
          sx={{m: 1, minWidth: 400}}
      >
        <InputLabel
            id="sender-type-label"
            sx={{marginLeft: "10px"}}
        >
          Sender
        </InputLabel>
        <Select
            disableUnderline
            labelId='sender-type-label'
            sx={{width: '100%'}}
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            disabled={loading}
        >
          {identities ?
              identities
              .map((a: Identity) =>
                  <MenuItem
                      key={a.externalId}
                      value={a.externalId}
                  >
                    {a.email}
                  </MenuItem>
              )
              : (
                  <div>
                    No email senders
                  </div>
              )}
        </Select>
        {identities.length === 0 && (
            <FormHelperText error>No Senders Available</FormHelperText>
        )}
      </FormControl>);
}

export default EmailSenderParam;
