import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import api from "utils/api";
import { Pie, HorizontalBar } from "react-chartjs-2";
import { formatBytes, formatDuration, bytesToMb } from "utils/common";
import { VenuesContext } from "context/venueContext";
import { PageNavigationContext } from "context/navigationContext";
import PageNavigator from "components/PageNavigator";
import VisitCustomer from "./visitCustomer";

import { ReactComponent as AlertIcon } from "assets/images/alert-icon.svg";
import { ReactComponent as FacebookLogo } from "assets/images/facebook-icon.svg";
import { ReactComponent as TwitterLogo } from "assets/images/twitter.svg";
import { ReactComponent as BackIcon } from "assets/images/back-icon.svg";
import { ReactComponent as EmailLogo } from "assets/images/email-icon.svg";

import "assets/css/customers/customer-details.scss";

export const TitledLabel = ({ title, text, Icon }) => {
  return (
    <div className="titled-label">
      <label className="title">{title}</label>
      <div className="icon-text">
        {Icon && <Icon className="icon" />}
        <label className="text">{text}</label>
      </div>
    </div>
  );
};

export const CustomerDetails = ({ track }) => {
  const { selected } = useContext(VenuesContext);

  const [customer, setCustomer] = useState({});
  const [sessions, setSessions] = useState([]);
  const [visits, setVisits] = useState([]);
  const [selectedVisit, setSelectedVisit] = useState(visits[0]);
  const [contacts, setContacts] = useState([]);
  const [navigation, setNavigation] = useState({ total: 1, current: 0 });

  const { customerId } = useParams();
  const history = useHistory();

  const fetchCustomer = async () => {
    const result = await api.get(
      `/organisations/${selected.externalId}/contacts/${customerId}`
    );
    setCustomer(result.data);
    setSessions(
      result.data.contactDevices
        .map((device) =>
          device.sessions.map((session) => ({
            ...session,
            device: device.deviceType,
          }))
        )
        .flat(1)
        .sort((a, b) =>
          a.assocTime > b.assocTime ? -1 : a.assocTime < b.assocTime ? 1 : 0
        )
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selected) fetchCustomer();
    };
    fetchData();
  }, [customerId, selected]);

  const getAvatar = () => {
    if (customer.avatarUrl) {
      if (customer.avatarUrl.includes("facebook"))
        return `${customer.avatarUrl}?width=300&height=300`;
      else return customer.avatarUrl;
    }
    return require("../../assets/images/person-icon.svg");
  };

  const getRegistrationType = () => {
    if (customer.registrationType === "FACEBOOK")
      return <FacebookLogo className="customer-details-registration-type" />;
    else if (customer.registrationType === "EMAIL")
      return <EmailLogo className="customer-details-registration-type" />;
    else if (customer.registrationType === "TWITTER")
      return <TwitterLogo className="customer-details-registration-type" />;
  };

  const getTotalData = () => {
    const data =
      sessions.length > 0
        ? {
            labels: ["Data upload", "Data download"],
            datasets: [
              {
                data: [
                  bytesToMb(
                    sessions
                      .map((session) => session.upData)
                      .reduce((c, t) => t + c, 0)
                  ),
                  bytesToMb(
                    sessions
                      .map((session) => session.downData)
                      .reduce((c, t) => t + c, 0)
                  ),
                ],
                backgroundColor: ["#FFCC33", "#E8555D"],
                hoverBackgroundColor: ["#FFCC33", "#E8555D"],
              },
            ],
          }
        : {
            labels: ["No data"],
            datasets: [
              {
                data: [1],
                backgroundColor: ["#dddddd"],
                hoverBackgroundColor: ["#dddddd"],
              },
            ],
          };

    return data;
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
  };

  const options2 = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
      },
    },
  };

  const arbitraryStackKey = "stack1";
  const getSessionData = () => {
    return {
      labels: ["", ""],
      datasets: [
        {
          stack: arbitraryStackKey,
          label: "Data download",
          data: sessions
            .slice(0, 2)
            .map((session) => session.downData)
            .map((data) => bytesToMb(data)) || [0, 0],
          backgroundColor: "#E8555D",
          barPercentage: 0.4,
        },
        {
          stack: arbitraryStackKey,
          label: "Data upload",
          data: sessions
            .slice(0, 2)
            .map((session) => session.upData)
            .map((data) => bytesToMb(data)) || [0, 0],
          backgroundColor: "#FFCC33",
          barPercentage: 0.4,
        },
      ],
    };
  };

  useEffect(() => {
    if (customer && customer.contactVisits) {
      const sortedVisits = customer.contactVisits.sort(
        (a, b) => b.visitDate - a.visitDate
      );
      setVisits(sortedVisits);
      setSelectedVisit(sortedVisits[0]);
    }
  }, [customer.externalId]);

  const fetchContacts = async (page) => {
    const { data } = await api.get(
      `/organisation/${selected.externalId}/contacts/${customer.externalId}/visits?visitDate=${selectedVisit.visitDate}&size=12&page=${page}`
    );
    setContacts(data.content);
    setNavigation({
      total: Math.ceil(data.total / 12),
      current: data.pageable.page,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchContacts(0);
    };
    if (selectedVisit && selected && selected.externalId) fetchData();
  }, [selected, selectedVisit]);

  const goToAlert = async () => {
    const { data } = await api.get(
      `/organisation/${selected.externalId}/contacts/${customer.externalId}/visits?visitDate=${selectedVisit.visitDate}&size=1000&page=0`
    );
    history.push({ pathname: "/alert", state: { customers: data.content } });
  };

  return (
    <div className="customer-details-page">
      <div className="customer-details-page-top">
        <div className="customer-details-back" onClick={() => history.goBack()}>
          <BackIcon className="back-icon" />
          <label>BACK</label>
        </div>
        {track && (
          <div className="alert-btn" onClick={goToAlert}>
            <AlertIcon className="alert-icon" />
            <label>ALERT</label>
          </div>
        )}
      </div>

      <div className="customer-details-container">
        <div className="customer-details">
          <div className="customer-details-contact">
            <img src={getAvatar()} />
            <div className="customer-details-name-email">
              <div className="customer-details-name">
                <label>{`${customer.forename} ${customer.surname}`}</label>
                {!track && getRegistrationType()}
              </div>
              {!track && (
                <label className="customer-details-email">
                  {customer.email}
                </label>
              )}
            </div>
          </div>
          <div className="customer-details-contact-details">
            <div className="customer-details-contact-details-left">
              {track ? (
                <TitledLabel title="Email" text={customer.email || "UNKNOWN"} />
              ) : (
                <>
                  <TitledLabel
                    title="DOB"
                    text={customer.birthday || "UNKNOWN"}
                  />
                  <TitledLabel
                    title="Gender"
                    text={customer.gender || "UNKNOWN"}
                  />
                  {/* <TitledLabel title='First Visit' text={visits && visits.length > 0 ? new Date(visits.map(visit => visit.visitDate).reduce(function (a, b) { return a < b ? a : b; }) * 1000).toLocaleString() : 'UNKNOWN'} /> */}
                </>
              )}
            </div>

            <div className="customer-details-contact-details-right">
              {track ? (
                <TitledLabel
                  title="Phone Number"
                  text={
                    customer.phones && customer.phones.length > 0
                      ? customer.phones[0].phoneNumber
                      : "UNKNOWN"
                  }
                />
              ) : (
                <>
                  <TitledLabel
                    title="Visited"
                    text={
                      visits
                        ? `${
                            visits
                              .map((visit) => visit.organistoId)
                              .filter((val, i, arr) => arr.indexOf(val) === i)
                              .length
                          } Venue`
                        : `${0} Venue`
                    }
                  />
                  <TitledLabel
                    title="Visited Count"
                    text={
                      visits
                        ? visits.filter(
                            (visit) =>
                              selected.orgType === "ORGANISATION" ||
                              visit.organisationId ===
                                (selected ? selected.externalId : "")
                          ).length
                        : 0
                    }
                  />
                  {/* <TitledLabel title='Last visit' text={visits && visits.length > 0 ? new Date(visits.map(visit => visit.visitDate).reduce(function (a, b) { return a > b ? a : b; }) * 1000).toLocaleString() : 'UNKNOWN'} /> */}
                </>
              )}
            </div>
          </div>
          {track && (
            <div className="visits-container">
              <div>
                <label className="title">Date of visit(s)</label>
                <label className="title">Arrival time</label>
              </div>

              <div className="visits">
                {visits
                  .filter(
                    (visit) =>
                      selected.orgType === "ORGANISATION" ||
                      visit.organisationId ===
                        (selected ? selected.externalId : "")
                  )
                  .map((visit) => (
                    <div
                      key={visit.externalId}
                      className={`visit ${
                        visit.externalId === selectedVisit.externalId &&
                        "visit-selected"
                      }`}
                      onClick={() => setSelectedVisit(visit)}
                    >
                      <label>
                        {new Date(visit.visitDate * 1000)
                          .toJSON()
                          .slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </label>
                      <label>
                        {new Date(visit.visitDate * 1000)
                          .toJSON()
                          .slice(11, 16)}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="customer-usage">
          {track ? (
            <div className="in-contact-container">
              <div>
                <label className="title">
                  People they have come into contact with:
                </label>
                <div className="in-contact">
                  {contacts.map((contact, i) => (
                    <VisitCustomer customer={contact} key={i} />
                  ))}
                </div>
              </div>
              <div className="navigation">
                <PageNavigationContext.Provider
                  value={{ navigation, setNavigation }}
                >
                  <PageNavigator changePage={(page) => fetchContacts(page)} />
                </PageNavigationContext.Provider>
              </div>
            </div>
          ) : (
            <>
              <div className="customer-usage-statistics">
                <div className="charts">
                  <div className="total-data-usage-chart">
                    <label className="total-data-usage-label">
                      Total data usage
                    </label>
                    <div className="total-data-usage-chart-description">
                      <Pie data={getTotalData()} options={options} />
                      <TitledLabel
                        title="Usage"
                        text={formatBytes(
                          sessions
                            .map((session) => session.upData)
                            .reduce((c, t) => t + c, 0) +
                            sessions
                              .map((session) => session.downData)
                              .reduce((c, t) => t + c, 0)
                        )}
                      />
                    </div>
                  </div>

                  <div className="data-usage-persession-chart">
                    <label>Data use by session</label>
                    <HorizontalBar data={getSessionData()} options={options2} />
                  </div>
                </div>

                <div className="data-usage-totals">
                  <div className="data-usage-totals-left ">
                    <TitledLabel
                      title="Total visits"
                      text={
                        visits
                          ? visits.filter(
                              (visit) =>
                                visit.organisationId ===
                                (selected ? selected.externalId : "")
                            ).length
                          : 0
                      }
                    />
                    <TitledLabel
                      title="Total sessions"
                      text={sessions.length}
                    />
                  </div>
                  <div className="data-usage-totals-right ">
                    <TitledLabel
                      title="Total data use"
                      text={formatBytes(
                        sessions
                          .map((session) => session.upData)
                          .reduce((c, t) => t + c, 0) +
                          sessions
                            .map((session) => session.downData)
                            .reduce((c, t) => t + c, 0)
                      )}
                    />
                    <TitledLabel
                      title="Total online time"
                      text={formatDuration(
                        sessions
                          .map((session) => session.duration)
                          .reduce((c, t) => t + c, 0)
                      )}
                    />
                    <TitledLabel
                      title="Average dwell time"
                      text={formatDuration(
                        Math.floor(
                          sessions
                            .map((session) => session.duration)
                            .reduce((c, t) => t + c, 0) / sessions.length
                        )
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="customer-usages">
                {sessions.map((session, i) => (
                  <div
                    key={session.externalId}
                    className="customer-usage-grid"
                    style={{
                      backgroundColor: i % 2 === 0 ? "#F7F7F7" : "white",
                    }}
                  >
                    <label>{session.device}</label>
                    <label>
                      {new Date(session.assocTime * 1000).toLocaleString()}
                    </label>
                    <label>{formatDuration(session.duration, true)}</label>
                    <label>{formatBytes(session.downData)}</label>
                    <label>{formatBytes(session.upData)}</label>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
