import React, {useEffect, useState} from "react";
import {compose} from "recompose";
import {Switch} from "react-router-dom";
import MainAdmin from "./MainAdmin";
import MainUser from "./MainUser";
import useIsNavBarLocked from "utils/hooks/useIsNavBarLocked";
import NavBar from "parts/NavBar";
import TopBar from "parts/TopBar";
import UserContainer from "containers/User";
import SelectedOrganisationContainer from "containers/SelectedOrganisation";
import {InfoPopup} from "components/widget/infoPopup";
import {VenuesContext} from "context/venueContext";
import api from "utils/api";
import {from, map} from 'rxjs';
import "assets/css/main.scss";
import {AxiosResponse} from "axios";

const Main = (props) => {
  const {
    selectedOrganisation,
    userOrganisation,
    isSuperAdmin,
    user,
    selectVenueRequest,
    selectOrganisationRequest,
    getSelectedOrganisationVenuesRequest,
    // getSelectedOrganisationLicensesRequest,
    // getSelectedOrganisationSummaryRequest,
    getSelectedEntitySummaryRequest,
    selectEntityRequest,

  } = props;

  const isNavBarLocked = useIsNavBarLocked();

  const [organisationId, setOrganisationId] = useState(null);
  const [licences, setLicences] = useState();
  const [activeLicenses, setActiveLicenses] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [selected, setSelected] = useState();
  const [showPopup, setShowPopup] = useState();
  const [venues, setVenues] = useState([]);

  useEffect(() => {
    const org = isSuperAdmin
        ? selectedOrganisation
        : userOrganisation;

    if (org) {
      const orgId = org?.externalId

      setOrganisationId(orgId);
      getSelectedEntitySummaryRequest(orgId);
      selectOrganisationRequest(org);
      //selectEntityRequest(org);
      getSelectedOrganisationVenuesRequest(orgId)

      fetchVenues(org);
      fetchLicenses(orgId);
    }
  }, [userOrganisation, selectedOrganisation]);

  useEffect(() => {
    if (venues && venues.length > 0) {
      // let current = undefined;
      //
      // if (isSuperAdmin) {
      //
      //   current = venues[0];
      //
      // } else if (venues.length > 0) {
      //
      //   // current = venues.find(
      //   //     (network) => network?.externalId === venues[0].externalId
      //   // );
      //
      //   console.log("current")
      //   console.log(current)
      //
      //   if (!current) {
      //     if (venues.length === 2) {
      //       current = venues[1];
      //     } else {
      //       current = venues[0];
      //     }
      //   }
      // }

      const entity = venues.length === 2 ? venues[1] : venues[0];

      setSelected(entity);
      selectVenueRequest(entity);
      setOrganisations(venues);
      selectEntityRequest(entity);

    }
  }, [venues]);

  const fetchVenues = (org) => {
    from(api.get(
        `/organisations/${org.externalId}/network?relationship=CUSTOMER&relationship=VENUE&page=0&size=100&name=`
    ))
    .pipe(map((response) => response.data))
    .subscribe((data) => {
      setVenues([org, ...data.content]);
    });
  }

  const fetchLicenses = (orgId) => {
    from(api.get(`organisations/${orgId}/licences`))
    .pipe(map((response) => response.data))
    .subscribe((data) => {
      if (data) {
        setLicences(data);
        const currentDate = Math.floor(Date.now() / 1000);
        setActiveLicenses(
            data.filter(
                (license) =>
                    license.startDate < currentDate &&
                    license.expiryDate > currentDate
            )
            .map((license) => license.licenceType)
        );
      }
    });
  }

  return user ? (
      <div className={`main-page ${!isNavBarLocked && "small-main"}`}>
        <NavBar
            isSuperAdmin={isSuperAdmin}
            licences={licences}
            activeLicenses={activeLicenses}
            selectedOrganisation={selectedOrganisation}
            userOrganisation={userOrganisation}
        />
        <VenuesContext.Provider
            value={{
              organisations,
              setOrganisations,
              selected,
              isNavBarLocked,
              licences,
              setLicences,
            }}
        >
          <TopBar
              className="top-bar"
              isSuperAdmin={isSuperAdmin}
              organisations={organisations}
              selected={selectedOrganisation}
              setSelected={setSelected}
              {...props}
          />
          <div className="main">
            <div className="content">
              <Switch>
                {isSuperAdmin ? (
                    <MainAdmin
                        {...props}
                        setSelected={setSelected}
                        setOrganisations={setOrganisations}
                        setShowPopup={setShowPopup}
                        activeLicenses={activeLicenses}
                        selectedOrganisation={selectedOrganisation}
                    />
                ) : (
                    <MainUser
                        {...props}
                        organisationId={organisationId}
                        activeLicenses={activeLicenses}
                    />
                )}
              </Switch>
            </div>
            {showPopup && (
                <InfoPopup text={showPopup} onOk={() => setShowPopup(false)}/>
            )}
          </div>
        </VenuesContext.Provider>
      </div>
  ) : null;
};

export default compose(UserContainer, SelectedOrganisationContainer)(Main);
