import React from "react";

import {Draggable} from "react-beautiful-dnd";

import DraggableItemWrapper from "./DraggableItemWrapper";

import {ReactComponent as DragHandle} from "assets/images/dragHandle.svg";

const DraggableItem = (props) => {
  const {id, text, index, amountOfItems} = props;
  return (
      <Draggable draggableId={`${id}`} index={index}>
        {(provided) => (
            <DraggableItemWrapper
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                amountOfItems={amountOfItems}
            >
              <div className="drag-item__drag-handle">
                <DragHandle/>
              </div>
              {text}
            </DraggableItemWrapper>
        )}
      </Draggable>
  );
};

export default DraggableItem;
