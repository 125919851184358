import React from 'react'

import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

import '../../assets/css/widget/info-popup.scss'

export const InfoPopup = ({ text, onOk }) => {
    return (
        <div className='info-popup'>
            <label>{text}</label>
            <DeleteIcon className='delete-icon' onClick={() => onOk()} />
        </div>
    )
}