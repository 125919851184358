import React, {useEffect, useState} from "react";

import "react-dropdown/style.css";
import "../assets/css/dashboard.css";
import "../assets/css/dashboard.scss";

export const GettingStarted = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [completedVideos, setCompletedVideos] = useState([]);
  const [currentVideoSource, setCurrentVideoSource] = useState("");

  const getTimerColor = (time) => {
    switch (time) {
      case (time = 2):
        return "#ffcc33";
      case (time = 3):
        return "#ff9933";
      case (time = 5):
        return "#ea5454";
      case (time = 8):
        return "#ea5454";
      default:
        return "#ffcc33";
    }
  };

  useEffect(() => {
    if (completedVideos.length) {
      localStorage.setItem("viewedTutorials", completedVideos);
    }
  }, [completedVideos]);

  useEffect(() => {
    const previouslyViewedContent = localStorage.getItem("viewedTutorials");
    if (previouslyViewedContent) {
      setCompletedVideos(previouslyViewedContent);
    }
  }, []);

  const introContent = [
    {
      id: 0,
      title: "Creating a Venue",
      backgroundType: "venue",
      subtitle:
          "In this video we will show you how to create your first Landing Page, quick description of what this tutorial is about and how long it will take.",
      timer: 2,
      videoSource: "https://www.youtube.com/embed/zokcWiiO_7c",
    },
    {
      id: 1,
      title: "Send your first SMS",
      backgroundType: "sms",
      subtitle: `Creating your first Landing Page, quick description of what this tutorial is about
    and how long it will take.`,
      timer: 5,
      videoSource: "https://www.youtube.com/embed/qhn2FyYIxXs",
    },
    {
      id: 2,
      title: "Creating your first Landing Page",
      backgroundType: "landing",
      subtitle: `Creating your first Landing Page, quick description of what this tutorial is about
      and how long it will take.`,
      timer: 3,
      videoSource: "https://www.youtube.com/embed/3V8JvdTBlBo",
    },
    {
      // Need to add route link to a support page
      title: "Support Info",
      backgroundType: "support",
      navLink: "https://www.unaro.co.uk/contact-us/",
      id: 3,
      videoSource: "https://youtu.be/d6MB3S1jYhM",
    },
  ];

  const onClickIntroCard = (content) => {
    if (content.navLink) {
      window.open(content.navLink, "_blank");
      return;
    }
    setCurrentVideoSource(content.videoSource);
    setShowVideo(true);
    setCompletedVideos([...completedVideos, content.id]);
  };

  return (
      <div className="intro-dashboard">
        {showVideo && (
            <div
                className="intro-dashboard__video-backdrop"
                onClick={() => setShowVideo(false)}
            >
              <div className="intro-dashboard__video-player">
                <iframe
                    title="intro dashboard"
                    width="560"
                    height="315"
                    src={currentVideoSource}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                />
              </div>
            </div>
        )}
        <div>
          <div className="intro-dashboard__dashboard-top">
            <label className="intro-dashboard__dashboard-title">
              Welcome to Unaro - Getting Started
            </label>
            <div className="intro-dashboard__dashboard-subtitle">
              <label>
                Thank you for joining Unaro! We recommened these tutorials to
                help
                you get started on the platform.
              </label>
            </div>
          </div>
          <div className="intro-dashboard__main-content">
            {introContent.map((content) => (
                <div
                    className="intro-dashboard__intro-card"
                    tabIndex="0"
                    key={content.id}
                    onKeyPress={() => onClickIntroCard(content)}
                    onClick={() => onClickIntroCard(content)}
                >
                  {completedVideos.includes(content.id) && (
                      <div className="intro-dashboard__card-check-mark"></div>
                  )}
                  <div className="intro-dashboard__card-image-container">
                    <div
                        className={`intro-dashboard__card-image intro-dashboard__card-image--${content.backgroundType}-background`}
                        alt="tutorial card"
                    />
                  </div>
                  <div className="intro-dashboard__card-content">
                    <div
                        className={`intro-dashboard__left-content intro-dashboard__left-content--${content.backgroundType}`}
                    >
                      <div>{content.title}</div>
                      <div className="intro-dashboard__card-subtitle">
                        {content.subtitle}
                      </div>
                    </div>
                    {content.backgroundType !== "support" && (
                        <div className="intro-dashboard__right-content">
                          <p style={{color: getTimerColor(content.timer)}}>
                            {content.timer}
                          </p>
                          <p>Minutes</p>
                        </div>
                    )}
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
  );
};
