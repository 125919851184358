import React from "react";
import styled from "@emotion/styled";
import { SmileySad } from "phosphor-react";

const NoContactsMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const NoContactsMessageText = styled.h3`
  width: 50%;
  opacity: 0.75;
  text-align: center;
`;

const NoContactsMessage = () => {
  return (
    <NoContactsMessageWrapper>
      <SmileySad size={48} color={"#ea5454"} />
      <NoContactsMessageText>No Contacts available</NoContactsMessageText>
    </NoContactsMessageWrapper>
  );
};

export default NoContactsMessage;
