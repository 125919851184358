export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// --- LOGIN USER

export const loginRequest = (loginCredentials) => ({
  type: LOGIN_REQUEST,
  payload: loginCredentials,
});

export const loginFailure = (errorMessage) => ({
  type: LOGIN_FAILURE,
  payload: errorMessage,
});

export const loginSuccess = (currentUser) => ({
  type: LOGIN_SUCCESS,
  payload: currentUser,
});

// --- LOGOUT USER

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
});

export const logoutFailure = (errorMessage) => ({
  type: LOGOUT_FAILURE,
  payload: errorMessage,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});
