import React from "react";
import styled from "@emotion/styled";

import QuestionInput from "./QuestionInput";
import StandardInput from "./StandardInput";

const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;

  .text-switch {
    margin-top: 10px;
    margin-left: 10px;

    .text-switch-label {
      width: 170px;
    }

    .text-switch-actions {
      width: 60px;
    }
  }
`;

const SelectOptionsPanel = (props) => {
  const {inputs, setInput, setInputText} = props;
  return (
      <div className="edit-landing-page-settings-top">
        <InputsRow>
          <StandardInput
              inputs={inputs}
              setInput={setInput}
              inputKey='email'
          />
          <StandardInput
              inputs={inputs}
              setInput={setInput}
              inputKey='firstName'
          />
          <StandardInput
              inputs={inputs}
              setInput={setInput}
              inputKey='lastName'
          />
        </InputsRow>
        <InputsRow>
          <StandardInput
              inputs={inputs}
              setInput={setInput}
              inputKey='mobileNumber'
          />
          <StandardInput
              inputs={inputs}
              setInput={setInput}
              inputKey='gender'
          />
          <StandardInput
              inputs={inputs}
              setInput={setInput}
              inputKey='dateOfBirth'
          />
        </InputsRow>
        <InputsRow>
          <QuestionInput
              inputs={inputs}
              setInput={setInput}
              inputKey='question'
              setInputText={setInputText}
          />
        </InputsRow>
        <hr className="edit-landing-page-settings-line"/>
      </div>
  );
};

export default SelectOptionsPanel;
