import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@mui/material";
import {Action} from "../../../types";

type Props = {
  action: Action
  deleteAction: (action: Action) => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteActionDialog = ({action, deleteAction, open, setOpen}: Props) => {

  const onClickDelete = () => {
    deleteAction(action);
    setOpen(false);
  }

  return (
      <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete action?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='h6' sx={{textAlign: 'center'}}>
              {action.name}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={onClickDelete} autoFocus color='error'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default DeleteActionDialog;