import React from "react";
import {Container} from "@mui/material";
import AutomationList from "./AutomationList";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import uuid from 'react-uuid';
import AutomationBuilder from "./AutomationBuilder";

type Props = {
  organisationId: string
}

const Automation = ({organisationId}: Props) => {
  return (
      <Container
          maxWidth="md"
          sx={{
            display: 'flex',
            direction: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            backgroundColor: 'white',
            padding: '10px',
            marginTop: '5px'
          }}
      >
        <BrowserRouter>
          <Switch>
            <Route key={uuid()} path='/automation/:action/:automationId?'>
              <AutomationBuilder organisationId={organisationId}/>
            </Route>
            <Route path='/automation'>
              <AutomationList organisationId={organisationId}/>
            </Route>
          </Switch>
        </BrowserRouter>
      </Container>
  );
}

export default Automation;