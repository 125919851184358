import React from "react";

import "assets/css/design/stepper.scss";

const Stepper = (props) => {
  const { numberOfSteps, onClickStep, currentStep, lastStepAction } = props;

  const isActive = (index) => currentStep === index + 1;
  const steps = Array.from(Array(numberOfSteps).keys());
  return (
    <div className="stepper">
      {steps.map((step, index) => (
        <div key={index} className="stepper__step">
          <div
            className={`stepper__inner--active-${isActive(
              index
            )}--${currentStep}`}
          >
            <p>{step + 1}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
