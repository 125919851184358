import moment from "moment";

const getChartTimeLabels = (unit) => {
  const currentDate = new Date();

  switch (unit) {
    case "day":
    case "daily": {
      currentDate.setDate(currentDate.getDate() - 1);
      const day = Array.from(Array(24).keys()).map(
        (k) => (currentDate.getHours() + k + 1) % 24 + ": 00"
      );

      return day;
    }

    case "week":
    case "weekly": {
      const week = [];
      const dateStart = moment().subtract(1, "week");
      const dateEnd = moment();
      while (dateEnd.diff(dateStart, "days") >= 0) {
        week.push(dateStart.format("DD"));
        dateStart.add(1, "day");
      }

      return week;
    }

    case "month":
    case "monthly": {
      const daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      ).getDate();

      const days = [];
      const dateStart = moment().subtract(daysInMonth, "day");
      const dateEnd = moment();
      while (dateEnd.diff(dateStart, "days") >= 0) {
        days.push(dateStart.format("DD MMM"));
        dateStart.add(1, "day");
      }

      return days;
    }

    case "year":
    case "yearly": {
      const months = [];
      const dateStart = moment().subtract(12, "month");
      const dateEnd = moment();
      while (dateEnd.diff(dateStart, "months") >= 0) {
        months.push(dateStart.format("MMM YY"));
        dateStart.add(1, "month");
      }
      return months;
    }

    default: {
      currentDate.setDate(currentDate.getDate() - 1);
      const day = Array.from(Array(24).keys()).map(
          (k) => (currentDate.getHours() + k + 1) % 24 + ": 00"
      );

      return day;
    }
  }
};

export default getChartTimeLabels;
