import React, { useState } from "react";

import "../../assets/css/widget/info-tooltip.scss";

function InfoTooltip({ tooltipMessage }) {
  const [showTooltip, setShowTooltip] = useState();

  return (
    <div className="info-container">
      <div
        className="info"
        onClick={() => setShowTooltip(!showTooltip)}
        tabIndex="0"
      >
        <label>i</label>
      </div>
      {showTooltip && <label className="template-info">{tooltipMessage}</label>}
    </div>
  );
}

export default InfoTooltip;
