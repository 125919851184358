import React from "react";
import styled from "@emotion/styled";
import Stepper from "./Stepper";

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 16px;

  .landing-creator__title {
    font-size: 22px;
  }
`;

const Top = (props) => {
  const {
    numberOfSteps,
    saveLandingPage,
    currentLandingPageStep,
    updateLandingPageStepRequest,
  } = props;

  const [isLastStep, setIsLastStep] = React.useState(false);

  React.useEffect(() => {
    setIsLastStep(currentLandingPageStep === numberOfSteps);
  }, [currentLandingPageStep, numberOfSteps]);

  return (
      <TopWrapper>
        <p className="landing-creator__title">Landing Page - Branding</p>
        <Stepper
            currentStep={currentLandingPageStep}
            numberOfSteps={numberOfSteps}
        />

        <button
            onClick={() => updateLandingPageStepRequest(
                currentLandingPageStep - 1)}
            className={`landing-creator__back disabled--${(
                currentLandingPageStep === 1
            ).toString()}`}
        >
          Back
        </button>

        {isLastStep ? (
            <button
                onClick={() => saveLandingPage()}
                className={`landing-creator__next `}
            >
              Save
            </button>
        ) : (
            <button
                onClick={() =>
                    updateLandingPageStepRequest(currentLandingPageStep + 1)
                }
                className={`landing-creator__next `}
            >
              Next
            </button>
        )}
      </TopWrapper>
  );
};

export default Top;
