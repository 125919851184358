import React, {useState} from "react";
import styled from "@emotion/styled";
import {
  Backdrop,
  Fade,
  List,
  ListItem,
  ListItemButton, ListItemIcon, ListItemText,
  Modal,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import group41Png from "../../../../../../assets/images/Group41.png";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {from} from "rxjs";
import {
  apiDeleteSegment,
} from "../../../../../../containers/ContactsList/api";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "25px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
}));

// const ConfirmationPlaceholder = styled.div`
//   position: relative;
//   width: 565px;
//   height: 385px;
//   background: #FFFFFF;
//   border-radius: 16px;
// `;

const ConfirmationPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 565px;
  height: 385px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const ConfirmationSegmentDeleted = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 565px;
  height: 231px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const WarningWrapper = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
  top: 20px;
`;

const HeaderWarningRed = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(234, 84, 84, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const HeaderErrorAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(234, 84, 84, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

// const TypographyText = styled.div`
//   position: absolute;
//   height: 64px;
//   left: 36px;
//   right: 36px;
//   top: 160.81px;
//   font-family: 'Trebuchet MS', sans-serif' !important;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 24px;
//   line-height: 133.4%;
//   text-align: center;
//   color: rgba(15, 15, 15, 0.87);
// `;

const Group11446 = styled.div`
  width: 96px;
  height: 96px;
  margin-top: 4%;
  margin-left: 8%;
`;

const TypographyText = styled.div`
  height: 32px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const TypographyTextSegmentDeleted = styled.div`
  position: absolute;
  height: 32px;
  left: 44.5px;
  right: 44.5px;
  top: 170.81px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-left: 8%;
  gap: 24px;
  color: #ff9933;
  width: 120px;
  height: 42px;
`;

const ImageDiv = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
  top: 43.8px;
`;

const HeaderImage = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(46, 125, 50, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const ButtonTextWrapper = styled.label`
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  color: ${(props) =>
    props.disabled ? "#C4C4C4 !important" : "#FF9933 !important"};
`;

const DeleteSegmentModal = (props) => {
  const {
    organisationId,
    disabled,
    selectedSegment,
    getSegmentsRequest,
    selectSegmentRequest,
    getAllContactsRequest,
    currentFilters,
    selectListTypeRequest,
    setCurrentSelectedSegmentId,
    setSelectedContactsIds,
    setIsAllContactsSelected,
    removeFilterRequest
  } = props;

  const {id, name, iconName, criteria} = selectedSegment || "";
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openSegmentDeleted, setOpenSegmentDeleted] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenSegmentDeleted = () => {
    setOpenSegmentDeleted(true);
  };

  const handleCloseSegmentDeleted = () => {
    setOpenSegmentDeleted(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  const handleDelete = () => {
    const request = {
      organisationId: organisationId,
      segmentId: selectedSegment.id,
    };
    from(apiDeleteSegment(request))
    .subscribe({
      next: (segment) => {
        const {status} = segment
        if (status === 200) {
          handleClose();
          setOpenSegmentDeleted(true);
          setTimeout(
              () => {
                setOpenSegmentDeleted(false);
                getSegmentsRequest(organisationId);
                selectSegmentRequest(null);
                getAllContactsRequest(organisationId, 0, 10, null,
                    currentFilters);
              },
              2000
          );
        }
        setCurrentSelectedSegmentId("segment-all-contacts");
        removeFilterRequest();
        setSelectedContactsIds([]);
        setIsAllContactsSelected(false);
        selectListTypeRequest("all");
      },
      error: (err) => {
        console.error('Error deleting segment')
        console.error(err);
        handleClose();
        handleOpenError();
        toast.error('Error deleting segment');
      },
      complete: () => {
        handleClose();
      }
    })

  };

  return (
      <div>
        <ButtonTextWrapper disabled={disabled} onClick={handleOpen}>
          Delete Segment
        </ButtonTextWrapper>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <img
                  alt="group"
                  onClick={handleClose}
                  className={"closeImg"}
                  src={group41Png}
              />
              <ConfirmationPlaceholder>
                <Group11446>
                  <HeaderWarningRed>
                    <WarningAmberRoundedIcon
                        sx={{
                          fontSize: 45,
                          padding: 3,
                          color: "#EA5454",
                        }}
                    />
                  </HeaderWarningRed>
                </Group11446>

                <TypographyText style={{marginLeft: '60px'}}>
                  Are you sure you want to delete segment:
                </TypographyText>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        {iconName !== null ? `${iconName}` : ''}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={{
                          fontFamily: "'Roboto',sans-serif !important",
                          fontSize: "16px",
                          fontWeight: 400
                        }}>
                          {name !== null
                              ? `${name}` : ''}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>

                <ButtonSection>
                  <>
                    <Button
                        onClick={handleClose}
                        sx={{
                          color: "#FF9933",
                          fontSize: 16,
                          fontWeight: "bold",
                          marginRight: 4
                        }}
                        variant="text"
                    >
                      CANCEL
                    </Button>
                    <Button
                        variant="text"
                        startIcon={<DeleteForeverIcon
                            sx={{fontSize: 80}}/>}
                        onClick={handleDelete}
                        sx={{
                          color: "#EA5454",
                          fontSize: 16,
                          fontWeight: "bold"
                        }}
                    >
                      DELETE
                    </Button>
                  </>
                </ButtonSection>
              </ConfirmationPlaceholder>
            </div>
          </Fade>
        </Modal>

        {/* <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
            <img
                    alt="group"
                    onClick={handleClose}
                    className={"closeImg"}
                    src={group41Png}
                />
              <ConfirmationPlaceholder>
              <Group11446>
              <WarningWrapper>
                  <HeaderWarningRed>
                    <WarningAmberRoundedIcon
                        sx={{
                          fontSize: 45,
                          padding: 3,
                          color: "#EA5454",
                        }}
                    />
                  </HeaderWarningRed>
                </WarningWrapper>
              </Group11446>

                <TypographyText>
                  Are you sure you want to delete segment:
                  <List sx={{marginLeft: 18, marginTop: 4}}>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon >
                          {iconName !== null ? `${iconName}` : ''}
                        </ListItemIcon>
                        <ListItemText>
                          <Typography sx={{fontFamily: "'Roboto',sans-serif !important", fontSize: "16px", fontWeight: 400}}>
                            {name !== null
                                ? `${name}` : ''}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </TypographyText>
                <ButtonSection>
                <>
                  <Button
                      onClick={handleClose}
                      sx={{
                        color: "#FF9933",
                        fontSize: 16,
                        fontWeight: "500",
                        // zIndex: 1,
                        // marginTop: 80,
                        // marginLeft: '30ch',
                        // marginRight: 4
                      }}
                      variant="text"
                  >
                    CANCEL
                  </Button>
                  <Button
                      onClick={handleDelete}
                      variant="text"
                      startIcon={<DeleteForeverIcon
                          sx={{fontSize: 80}}/>}
                      sx={{
                        color: "#EA5454",
                        fontSize: 16,
                        fontWeight: "bold",
                        // marginTop: 80
                      }}
                  >
                    DELETE
                  </Button>
                  </>
                </ButtonSection>
              </ConfirmationPlaceholder>
            </div>
          </Fade>
        </Modal> */
        }

        {/*SEGMENT DELETED*/
        }
        {
            ((openSegmentDeleted === true)) && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openSegmentDeleted}
                    onClose={handleCloseSegmentDeleted}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                >
                  <Fade in={openSegmentDeleted}>
                    <div className={classes.paper}>
                      <ConfirmationSegmentDeleted>
                        <img
                            alt="group"
                            onClick={handleCloseSegmentDeleted}
                            className={"closeImg"}
                            src={group41Png}
                        />
                        <ImageDiv>
                          <HeaderImage>
                            <CheckCircleOutlineIcon
                                sx={{
                                  fontSize: 60,
                                  padding: 2.3,
                                  color: "#1DB350",
                                }}
                            />
                          </HeaderImage>
                        </ImageDiv>
                        <>
                          <TypographyTextSegmentDeleted>
                            Segment Deleted
                          </TypographyTextSegmentDeleted>
                        </>
                      </ConfirmationSegmentDeleted>
                    </div>
                  </Fade>
                </Modal>
            )
        }

        {/*SEGMENT DELETE ERROR*/
        }
        {
            ((openError === true)) && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openError}
                    onClose={handleCloseError}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                >
                  <Fade in={openError}>
                    <div className={classes.paper}>
                      <ConfirmationSegmentDeleted>
                        <img
                            alt="group"
                            onClick={handleCloseError}
                            className={"closeImg"}
                            src={group41Png}
                        />
                        <ImageDiv>
                          <HeaderErrorAmber>
                            <WarningAmberRoundedIcon
                                sx={{
                                  fontSize: 45,
                                  padding: 3,
                                  color: "#EA5454",
                                }}
                            />
                          </HeaderErrorAmber>

                        </ImageDiv>
                        <TypographyTextSegmentDeleted>
                          <Typography variant="h6" color="red">
                            Error Deleting Segment
                          </Typography>
                        </TypographyTextSegmentDeleted>
                      </ConfirmationSegmentDeleted>
                    </div>
                  </Fade>
                </Modal>
            )
        }
      </div>
  )
      ;
};

export default DeleteSegmentModal;
