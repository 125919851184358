import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import styled from "@emotion/styled";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import group41Png from "../../../../../../assets/images/Group41.png";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "25px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
}));

const SuccessfulDeleteMessageInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 501px;
  height: 200px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

const Group11446 = styled.div`
  width: 96px;
  height: 96px;
  margin-top: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const HeaderImage = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(46, 125, 50, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const TypographyText = styled.div`
  height: 32px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const ConfirmDeleteCustomerModal = (props) => {
  const {
    selectedAudience,
    selectedContactsIds,

    showSuccessfulDeleteMessage,
    showSuccessfulRemoveMessage,
    showDeleteFromCustomers,

    setShowSuccessfulDeleteMessage,
    setShowSuccessfulRemoveMessage,
    setShowDeleteFromCustomers,
    setIsAllContactsSelected

  } = props;

  const classes = useStyles();

  const handleClose = () => {
    setShowSuccessfulDeleteMessage(false);
    setIsAllContactsSelected(false);
    setShowSuccessfulRemoveMessage(false)
    setIsAllContactsSelected(false);
    setShowDeleteFromCustomers(false);
  };

  return (
      <div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={showSuccessfulDeleteMessage || showSuccessfulRemoveMessage || showDeleteFromCustomers}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={showSuccessfulDeleteMessage || showSuccessfulRemoveMessage || showDeleteFromCustomers}>
            <div className={classes.paper}>
              <img
                  alt="group"
                  onClick={handleClose}
                  className={"closeImg"}
                  src={group41Png}
              />
              <SuccessfulDeleteMessageInnerContainer>
                <Group11446>
                  <HeaderImage>
                    <CheckCircleOutlineIcon
                        sx={{
                          fontSize: 60,
                          padding: 2.3,
                          color: "#1DB350",
                        }}
                    />
                  </HeaderImage>
                </Group11446>

                {(showSuccessfulDeleteMessage === true && showDeleteFromCustomers === false) && (
                    <>
                      <TypographyText>
                        ({selectedContactsIds && selectedContactsIds.length}) Contacts
                        successfully Deleted!
                      </TypographyText>
                    </>
                )}
                {showSuccessfulRemoveMessage === true && (
                    <>
                      <TypographyText>
                        ({selectedContactsIds && selectedContactsIds.length}) Contacts
                        successfully removed from {" "} ({selectedAudience.name}) !
                      </TypographyText>
                    </>
                )}
              </SuccessfulDeleteMessageInnerContainer>
            </div>
          </Fade>
        </Modal>
      </div>
  );
};

export default ConfirmDeleteCustomerModal;