export const GET_EMAIL_GLOBAL_STATS_REQUEST = "GET_EMAIL_GLOBAL_STATS_REQUEST";
export const GET_EMAIL_GLOBAL_STATS_FAILURE = "GET_EMAIL_GLOBAL_STATS_FAILURE";
export const GET_EMAIL_GLOBAL_STATS_SUCCESS = "GET_EMAIL_GLOBAL_STATS_SUCCESS";

// --- GET EMAIL STATS

//rename to get summary ?
export const getEmailGlobalStatsRequest = (organisationId) => ({
  type: GET_EMAIL_GLOBAL_STATS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getEmailGlobalStatsFailure = (errorMessage) => ({
  type: GET_EMAIL_GLOBAL_STATS_FAILURE,
  payload: errorMessage,
});

export const getEmailGlobalStatsSuccess = (globalEmailStats) => ({
  type: GET_EMAIL_GLOBAL_STATS_SUCCESS,
  payload: globalEmailStats,
});
