import React from "react";
// import ReactTooltip from "react-tooltip";
import TemplatePreviewItem from "./TemplatePreviewItem";
import StartNewButton from "./StartNewButton";
import SendCreateBottomWrapper from "./SendCreateBottomWrapper";

const SendCreateBottom = (props) => {
  const {
    organisationId,
    templateType,
    savedTemplates,
    featuredTemplates,
    setShowAddCampaign,
    updateCurrentCampaignRequest,
    currentTemplate,
    isUsingSavedTemplate,
    isSuperAdmin,
    deleteTemplateRequest
  } = props;

  const [currentTemplates, setCurrentTemplates] = React.useState(null);

  React.useEffect(() => {
    if (templateType === "saved") {
      setCurrentTemplates(savedTemplates);
    } else {
      setCurrentTemplates(featuredTemplates);
    }
  }, [templateType, savedTemplates, featuredTemplates]);

  return (
    <SendCreateBottomWrapper>
      <StartNewButton
        organisationId={organisationId}
        setShowAddCampaign={setShowAddCampaign}
        updateCurrentCampaignRequest={updateCurrentCampaignRequest}
      ></StartNewButton>

      {currentTemplates &&
        currentTemplates.map((template) => {
          return (
            <TemplatePreviewItem
              organisationId={organisationId}
              updateCurrentCampaignRequest={updateCurrentCampaignRequest}
              template={template}
              templateType={templateType}
              currentTemplate={currentTemplate}
              isUsingSavedTemplate={isUsingSavedTemplate}
              setShowAddCampaign={setShowAddCampaign}
              isSuperAdmin={isSuperAdmin}
              deleteTemplateRequest={deleteTemplateRequest}
            ></TemplatePreviewItem>
          );
        })}
    </SendCreateBottomWrapper>
  );
};

export default SendCreateBottom;
