import {call, delay, put, takeLatest} from "redux-saga/effects";

import {setUserRequest} from "containers/User/actions";
import reduxConfigStore from "store";
import {push} from "connected-react-router";
import {toast} from "react-toastify";

import {
  LOGIN_REQUEST,
  loginFailure,
  loginSuccess,
  LOGOUT_REQUEST,
  logoutFailure,
  logoutSuccess,
} from "./actions";

import { apiLogin } from "./api";
import { ampli } from '../Amplitude/ampli';

function* loginWorker(action) {
  try {
    yield reduxConfigStore.persistor.purge();

    const apiResult = yield call(apiLogin, action.payload);

    yield put(loginSuccess(apiResult.data));

    yield localStorage.setItem("user", JSON.stringify(apiResult.data));

    yield put(setUserRequest(apiResult.data));

    yield toast.success(`Welcome ${apiResult.data.forename}`);

    yield delay(1000);

    yield put(push("/dashboard"));

    const userId = `${apiResult.data.email != null ? apiResult.data.email : ''}`
    ampli.identify(userId, {});
    
    ampli.unaroLoginEventWithProperties({
      emailAddress: `${apiResult.data.email != null ? apiResult.data.email : ''}`,
      organisationName: apiResult.data.organisations[0] != undefined ? apiResult.data.organisations[0].name : '',
      eventType: "click login button",
    })

  } catch (error) {
    const errorMessage = yield error.message;
    yield put(loginFailure(errorMessage));
  }
}

function* logoutWorker(action) {
  try {
    // const user = yield JSON.parse(localStorage.getItem("user"));

    //clean local storage and persisted state

    yield reduxConfigStore.persistor.purge();
    yield put(logoutSuccess());

    yield put(push("/login"));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(logoutFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

export default function* watcher() {
  yield takeLatest(LOGIN_REQUEST, loginWorker);
  yield takeLatest(LOGOUT_REQUEST, logoutWorker);
}
