export const TOGGLE_NAV_BAR_REQUEST = "TOGGLE_NAV_BAR_REQUEST";
export const TOGGLE_NAV_BAR_FAILURE = "TOGGLE_NAV_BAR_FAILURE";
export const TOGGLE_NAV_BAR_SUCCESS = "TOGGLE_NAV_BAR_SUCCESS";

export const UNSUBSCRIBE_REQUEST = "UNSUBSCRIBE_REQUEST";
export const UNSUBSCRIBE_FAILURE = "UNSUBSCRIBE_FAILURE";
export const UNSUBSCRIBE_SUCCESS = "UNSUBSCRIBE_SUCCESS";

export const SUBSCRIBE_REQUEST = "SUBSCRIBE_REQUEST";
export const SUBSCRIBE_FAILURE = "SUBSCRIBE_FAILURE";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";

// --- TOGGLE NAVBAR _REQUEST

export const toggleNavBarRequest = (lockNavBar) => ({
  type: TOGGLE_NAV_BAR_REQUEST,
  payload: lockNavBar,
});

export const toggleNavBarFailure = (errorMessage) => ({
  type: TOGGLE_NAV_BAR_FAILURE,
  payload: errorMessage,
});

export const toggleNavBarSuccess = (lockNavBar) => ({
  type: TOGGLE_NAV_BAR_SUCCESS,
  payload: lockNavBar,
});

// --- SUBSCRIBE

export const subscribeRequest = (contactId) => ({
  type: SUBSCRIBE_REQUEST,
  payload: contactId,
});

export const subscribeFailure = (errorMessage) => ({
  type: SUBSCRIBE_FAILURE,
  payload: errorMessage,
});

export const subscribeSuccess = (contactId) => ({
  type: SUBSCRIBE_SUCCESS,
  payload: contactId,
});

// --- UNSUBSCRIBE

export const unsubscribeRequest = (contactId) => ({
  type: UNSUBSCRIBE_REQUEST,
  payload: contactId,
});

export const unsubscribeFailure = (errorMessage) => ({
  type: UNSUBSCRIBE_FAILURE,
  payload: errorMessage,
});

export const unsubscribeSuccess = (contactId) => ({
  type: UNSUBSCRIBE_SUCCESS,
  payload: contactId,
});
