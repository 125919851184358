import styled from "@emotion/styled";

const PosterPreviewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;

  .poster-preview-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 500px;

    .poster-preview-top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      label {
        color: white;
      }

      .powered-by {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 14px;
        font-family: Roboto Light;
      }

      img {
        width: 250px;
        margin: 30px 0;
      }

      .join {
        font-weight: bold;
        font-size: 30px;
        font-family: Roboto Bold;
      }

      .wifi {
        font-weight: bold;
        font-size: 60px;
        z-index: 3;
        font-family: Roboto Bold;
      }
    }

    .container {
      display: inline-block;
      position: relative;
      width: 100%;
      vertical-align: middle;
      overflow: hidden;
      height: 150px;
      top: -30px;
      z-index: 2;
    }

    .container-2 {
      display: inline-block;
      position: relative;
      width: 100%;
      vertical-align: middle;
      overflow: hidden;
      height: 150px;
      top: -160px;
    }

    .poster-preview-bottom {
      display: flex;
      flex-direction: row;
      padding: 20px;
      align-items: center;
      margin-top: -50px;
      top: -160px;
      position: relative;
      height: fit-content;
      margin-bottom: -160px;

      .message-container {
        display: flex;
        flex: 2;
        flex-direction: row;

        label {
          font-size: 12px;
          font-family: Roboto Light;
        }
      }

      .logo-container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        height: 100%;

        img {
          height: 60px;
        }
      }
    }
  }
`;

export default PosterPreviewWrapper;
