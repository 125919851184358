import React from "react";
import styled from "@emotion/styled";
import Widget from "../Widget";

const ClicksWidgetWrapper = styled(Widget)`
  grid-row: 2/3;
  grid-column: 13/16;

  padding-bottom: 25px;

  @media only screen and (max-width: 1200px) {
    grid-row: 3/4;
    grid-column: 1/6;
  }

  .inner-widget-body {
    margin-top: 16%;
    .stats {
      padding-bottom: 25px;
      .stat-value {
        font-weight: 700;
        font-size: 36px;
        display: block;
        text-align: center;
      }

      .stat-value-unique {
        color: #ff9933;
      }

      .stat-value-total {
        color: #ea5454;
      }

      .stat-title {
        padding-bottom: 25px;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
`;

const ClicksWidget = (props) => {
  return (
      <ClicksWidgetWrapper className="send-dashboard-clicks-widget">
        <div className="inner-widget-header">
          <label className="inner-widget-header-title">Clicks</label>
          <img
              alt="widget"
              className="inner-widget-header-img"
              src={require("assets/images/clicks.svg").default}
          />
        </div>
        <div className="inner-widget-body">
          <div className="stats">
            <label className="stat-value stat-value-unique">
              {props?.statsData?.totalUniqueClicks || 0}
            </label>
            <label className="stat-title">Total Unique Clicks</label>
          </div>
          <div className="stats">
            <label className="stat-value stat-value-total">
              {props?.statsData?.totalClicks || 0}
            </label>
            <label className="stat-title">Total Clicks</label>
          </div>
        </div>
      </ClicksWidgetWrapper>
  );
};

export default ClicksWidget;
