import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import { getCampaignsRequest } from "./actions";

import { selectCampaigns, selectCurrentPagination } from "./selectors";

export const mapStateToProps = createStructuredSelector({
  campaigns: selectCampaigns,
  currentPagination: selectCurrentPagination,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCampaignsRequest,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
