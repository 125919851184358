export const DEFAULT_DATA = {
  signups: {
    twitter: {
      total: 1,
    },
    facebook: {
      total: 1,
    },
    email: {
      total: 1,
    },
  },
};

export const COLORS = ["#E95353", "#F39638", "#FFCC33"];
