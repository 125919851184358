import React from "react";

import CardStatsWrapper from "./CardStatsWrapper";

export const CardStats = ({ statistics }) => {
  return (
    <CardStatsWrapper className="card-stats">
      <div className="statistics-card-top ">
        <div className="logo">
          {statistics.renderIcon ? (
            statistics.renderIcon()
          ) : (
            <img src={statistics.bigIcon} alt="" />
          )}
        </div>

        <div className="card-numbers">
          <label className="card-title" title={statistics.title}>
            {statistics.title}
          </label>
          <label className="card-number">{statistics.number}</label>
        </div>
      </div>
      <hr />
      <div className="statistics-card-bottom">
        <img src={statistics.smallIcon.default} alt="" />
        <label>{statistics.text}</label>
      </div>
    </CardStatsWrapper>
  );
};

export default CardStats;
