import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import {
  getNetworkOrganisationsRequest,
  getNetworkIndustriesRequest,
  deleteNetworkOrganisationRequest
} from "./actions";

import {
  selectIsLoading,
  selectErrorMessage,
  selectNetworkOrganisations,
  selectNetworkIndustries,
  selectNetworkPagination,
  selectIsNetworkDeletedSuccessfully
} from "./selectors";

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  errorMessage: selectErrorMessage,
  networkOrganisations: selectNetworkOrganisations,
  networkIndustries: selectNetworkIndustries,
  networkPagination:selectNetworkPagination,
  isNetworkDeletedSuccessfully:selectIsNetworkDeletedSuccessfully
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNetworkOrganisationsRequest,
      getNetworkIndustriesRequest,
      deleteNetworkOrganisationRequest
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
