import React from "react";

const StartNewButton = (props) => {
  const { setShowAddCampaign, updateCurrentCampaignRequest,organisationId } = props;
  return (
    <div
      className="et-template et-start-new"
      onClick={() => {
        updateCurrentCampaignRequest(organisationId, "template", null);
        setShowAddCampaign(true);
      }}
    >
      <img alt="start new" src={require("assets/images/start-new-icon.svg").default} />
      <label className="ett-name">+ Start New</label>
    </div>
  );
};

export default StartNewButton;
