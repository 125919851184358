import React from "react";
import styled from "@emotion/styled";

import DemographicsWidget from "./DemographicsWidget";

const DashboardDemographicsWrapper = styled.div`
  display: flex;
  flex: 1.6;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0 2%;
`;

const DashboardDemographics = (props) => {
  const { selectedEntitySummary } = props;

  return (
    <DashboardDemographicsWrapper>
      <DemographicsWidget
        demographics={selectedEntitySummary?.demographics}
      ></DemographicsWidget>
    </DashboardDemographicsWrapper>
  );
};

export default DashboardDemographics;
