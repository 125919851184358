import {call, put, takeLatest} from "redux-saga/effects";

import {apiGetVisitsAndSignups} from "./api";

import setOneDayAgo from "utils/time/setOneDayAgo";
import setOneWeekAgo from "utils/time/setOneWeekAgo";
import setOneMonthAgo from "utils/time/setOneMonthAgo";
import setOneYearAgo from "utils/time/setOneYearAgo";

import actions from "./actions";

const {
  GET_VISITS_AND_SIGNUPS_REQUEST,
  GET_YEAR_VISITS_AND_SIGNUPS_REQUEST,
  GET_MONTH_VISITS_AND_SIGNUPS_REQUEST,
  GET_WEEK_VISITS_AND_SIGNUPS_REQUEST,
  GET_DAY_VISITS_AND_SIGNUPS_REQUEST,
  getAllVisitsAndSignupsFailure,
  getAllVisitsAndSignupsSuccess,
  getYearVisitsAndSignupsFailure,
  getYearVisitsAndSignupsSuccess,
  getMonthVisitsAndSignupsSuccess,
  getMonthVisitsAndSignupsFailure,
  getWeekVisitsAndSignupsSuccess,
  getWeekVisitsAndSignupsFailure,
  getDayVisitsAndSignupsSuccess,
  getDayVisitsAndSignupsFailure,
  // getHourlyVisitsAndSignupsSuccess,
  // getHourlyVisitsAndSignupsFailure,
} = actions;

function* getAllVisitsAndSignupsWorker(action) {
  try {
    const apiResult = yield call(apiGetVisitsAndSignups, action.payload);

    yield put(getAllVisitsAndSignupsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getAllVisitsAndSignupsFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getYearVisitsAndSignupsWorker(action) {
  const oneYearAgo = setOneYearAgo();
  action.payload.startDate = oneYearAgo;
  action.payload.endDate = Math.ceil(new Date() / 1000);
  action.payload.timeUnit = "monthly";

  try {
    const apiResult = yield call(apiGetVisitsAndSignups, action.payload);

    yield put(getYearVisitsAndSignupsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getYearVisitsAndSignupsFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getMonthVisitsAndSignupsWorker(action) {
  const oneMonthAgo = setOneMonthAgo();
  action.payload.startDate = oneMonthAgo;
  action.payload.endDate = Math.ceil(new Date() / 1000);
  action.payload.timeUnit = "daily";
  try {
    const apiResult = yield call(apiGetVisitsAndSignups, action.payload);

    yield put(getMonthVisitsAndSignupsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getMonthVisitsAndSignupsFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getWeekVisitsAndSignupsWorker(action) {
  const oneWeekAgo = setOneWeekAgo();
  action.payload.startDate = oneWeekAgo;
  action.payload.endDate = Math.ceil(new Date() / 1000);
  action.payload.timeUnit = "daily";
  try {
    const apiResult = yield call(apiGetVisitsAndSignups, action.payload);

    yield put(getWeekVisitsAndSignupsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getWeekVisitsAndSignupsFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getDayVisitsAndSignupsWorker(action) {
  const oneDayAgo = setOneDayAgo();
  action.payload.startDate = oneDayAgo;
  action.payload.endDate = Math.ceil(new Date() / 1000);
  action.payload.timeUnit = "hourly";
  try {
    const apiResult = yield call(apiGetVisitsAndSignups, action.payload);

    yield put(getDayVisitsAndSignupsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getDayVisitsAndSignupsFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

// function* getHourlyVisitsAndSignupsWorker(action) {
//   const oneDayAgo = setOneDayAgo();
//   action.payload.timeUnit = "hourly";
//   action.payload.startDate = oneDayAgo;
//   action.payload.endDate = Math.ceil(new Date() / 1000);
//   try {
//     const apiResult = yield call(apiGetVisitsAndSignups, action.payload);

//     yield put(getHourlyVisitsAndSignupsSuccess(apiResult.data));
//   } catch (error) {
//     const errorMessage = yield error.message;
//     yield put(getHourlyVisitsAndSignupsFailure(errorMessage));
//     console.log("errorMessage");
//     console.log(errorMessage);
//   }
// }

export default function* watcher() {
  yield takeLatest(
    GET_VISITS_AND_SIGNUPS_REQUEST,
    getAllVisitsAndSignupsWorker
  );

  yield takeLatest(
    GET_YEAR_VISITS_AND_SIGNUPS_REQUEST,
    getYearVisitsAndSignupsWorker
  );

  yield takeLatest(
    GET_MONTH_VISITS_AND_SIGNUPS_REQUEST,
    getMonthVisitsAndSignupsWorker
  );

  yield takeLatest(
    GET_WEEK_VISITS_AND_SIGNUPS_REQUEST,
    getWeekVisitsAndSignupsWorker
  );

  yield takeLatest(
    GET_DAY_VISITS_AND_SIGNUPS_REQUEST,
    getDayVisitsAndSignupsWorker
  );
}
