import React from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import TemplateModal from "./TemplateModal";
import SenderModal from "./SenderModal";

type Props = {
  organisationId: string
}

const SmsSettings = ({organisationId}: Props) => {
  return (
      <Grid
          container
          spacing={2}
          direction="column"
          bgcolor={'white'}
          marginTop={2}
          marginLeft={2}
          sx={{minWidth: '1000px'}}
      >
        <Grid xs={12}>
          <Typography variant={"h5"}>
            SMS Settings
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant={"subtitle1"}>
            Template
          </Typography>
        </Grid>
        <Grid xs={6}>
          <TemplateModal organisationId={organisationId}/>
        </Grid>
        <Grid xs={12} mt={2}>
          <Typography variant={"subtitle1"}>
            Sender
          </Typography>
        </Grid>
        <Grid xs={12}>
          <SenderModal organisationId={organisationId}/>
        </Grid>
      </Grid>
  )
}

export default SmsSettings;