import { createSelector } from "reselect";

import { selectedOrganisationSliceSelector } from "store/selectors";

export const selectIsLoading = createSelector(
  selectedOrganisationSliceSelector,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector["loading"]
);

export const selectErrorMessage = createSelector(
  selectedOrganisationSliceSelector,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector["errorMessage"]
);

//Slices

export const selectSelectedOrganisationSlice = createSelector(
  selectedOrganisationSliceSelector,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector["selectedOrganisation"]
);

export const selectSelectedVenueSlice = createSelector(
  selectedOrganisationSliceSelector,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector["selectedVenue"]
);

export const selectSelectedEntitySlice = createSelector(
  selectedOrganisationSliceSelector,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector["selectedEntity"]
);

//Selected Organisation selectors

export const selectSelectedOrganisation = createSelector(
  selectSelectedOrganisationSlice,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector &&
    selectedOrganisationSliceSelector["selectedOrganisation"]
);

export const selectSelectedOrganisationLicenses = createSelector(
  selectSelectedOrganisationSlice,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector &&
    selectedOrganisationSliceSelector["selectedOrganisationLicenses"]
);

export const selectSelectedOrganisationActiveLicenses = createSelector(
  selectSelectedOrganisationLicenses,
  (licenses) => {
    const currentDate = Math.floor(Date.now() / 1000);

    let activeLicenses =
      licenses &&
      licenses
        .filter(
          (license) =>
            license.startDate < currentDate && license.expiryDate > currentDate
        )
        .map((license) => license.licenceType);

    return activeLicenses;
  }
);

export const selectSelectedOrganisationVenues = createSelector(
  selectSelectedOrganisationSlice,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector &&
    selectedOrganisationSliceSelector["selectedOrganisationVenues"]
);

export const selectSelectedOrganisationSummary = createSelector(
  selectSelectedOrganisationSlice,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector &&
    selectedOrganisationSliceSelector["selectedOrganisationSummary"]
);

export const selectSelectedOrganisationCampaigns = createSelector(
  selectSelectedOrganisationSlice,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector &&
    selectedOrganisationSliceSelector["selectedOrganisationCampaigns"]
);

export const selectSelectedOrganisationHasVenues = createSelector(
  selectSelectedOrganisationSlice,
  (selectedOrganisationSliceSelector) =>
    selectedOrganisationSliceSelector &&
    selectedOrganisationSliceSelector["selectedOrganisationVenues"]?.length > 0
);

//Selected Venues selectors

export const selectSelectedVenue = createSelector(
  selectSelectedVenueSlice,
  (venuesSliceSelector) => venuesSliceSelector["selectedVenue"]
);

export const selectSelectedVenueSummary = createSelector(
  selectSelectedVenueSlice,
  (venuesSliceSelector) => venuesSliceSelector["selectedVenueSummary"]
);

export const selectSelectedVenueCampaigns = createSelector(
  selectSelectedVenueSlice,
  (venuesSliceSelector) => venuesSliceSelector["selectedVenueCampaigns"]
);

//Selected Entity selectors

export const selectSelectedEntity = createSelector(
  selectSelectedEntitySlice,
  (selectSelectedEntitySlice) => selectSelectedEntitySlice["selectedEntity"]
);

export const selectSelectedEntitySummary = createSelector(
  selectSelectedEntitySlice,
  (selectSelectedEntitySlice) => {
    return selectSelectedEntitySlice["selectedEntitySummary"];
  }
);
