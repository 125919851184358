import React, { useState, useEffect } from "react";

import api from "utils/api";

import CardStats from "components/CardStats";
import { Signups } from "components/signups";
import Demographics from "components/demographics";
import DashboardStatsWrapper from "./style";

import { adminCardsConfig, userCardsConfig } from "./cardsConfig";

const Statistics = ({ organisationId }) => {
  const admin =
    JSON.parse(localStorage.getItem("user")).organisations[0].orgType !==
      "ORGANISATION" && !localStorage.getItem("selected-organisation");

  const [statistics, setStatistics] = useState();

  const [adminStats, setAdminStats] = useState(adminCardsConfig);
  const [stats, setStats] = useState(userCardsConfig);

  const getCards = () => {
    return admin ? adminStats : stats;
  };

  const setAdminCardStats = (statistics) => {
    const relationships = statistics.relationships;
    const stats = adminStats.map((x) => {
      if (x.id === 0)
        return {
          ...x,
          number: Object.entries(statistics.signups)
            .map(([key, value]) => value.total)
            .reduce((c, t) => (t += c)),
        };
      else if (x.id === 1) return { ...x, number: relationships.customers };
      else if (x.id === 2) return { ...x, number: relationships.venues };
      else if (x.id === 3) return { ...x, number: relationships.devices };
    });
    setAdminStats(stats);
  };

  const setNonAdminCardStats = (statistics) => {
    const result = stats.map((x) => {
      if (x.id === 0) return { ...x, number: statistics.connectedDevices };

      if (x.id === 1)
        return {
          ...x,
          number: Object.entries(statistics.signups)
            .map(([key, value]) => value.total)
            .reduce((c, t) => (t += c)),
        };
      else if (x.id === 2)
        return { ...x, number: `${Math.round(statistics.visits.returnRate)}%` };
      else if (x.id === 3)
        return {
          ...x,
          number: Object.entries(statistics.signups)
            .map(([key, value]) => value.today)
            .reduce((c, t) => (t += c)),
        };
    });
    setStats(result);
  };

  const setCardStats = (statistics) => {
    return admin
      ? setAdminCardStats(statistics)
      : setNonAdminCardStats(statistics);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (organisationId != null) {
        const result = await api.get(`/organisation/${organisationId}/summary`);
        setStatistics(result.data);
        setCardStats(result.data);
      }
    };
    fetchData();
  }, [organisationId]);

  return (
    <DashboardStatsWrapper className="dashboard-statistics">
      {/* <div className={`cards ${admin && "cards-2-columns"}`}> */}
      {getCards().map((stat) => (
        <CardStats key={stat.title} statistics={stat} />
      ))}
      {/* </div> */}
      {!admin && (
        <div className="dashboard-statistics-stats">
          <Demographics
            demographics={statistics ? statistics.demographics : {}}
          />
          <Signups statistics={statistics} />
        </div>
      )}
    </DashboardStatsWrapper>
  );
};

export default Statistics;
