export const SELECT_LIST_TYPE_REQUEST = "SELECT_LIST_TYPE_REQUEST";
export const SELECT_LIST_TYPE_FAILURE = "SELECT_LIST_TYPE_FAILURE";
export const SELECT_LIST_TYPE_SUCCESS = "SELECT_LIST_TYPE_SUCCESS";

export const SET_CURRENT_PAGINATION_REQUEST = "SET_CURRENT_PAGINATION_REQUEST";
export const SET_CURRENT_PAGINATION_FAILURE = "SET_CURRENT_PAGINATION_FAILURE";
export const SET_CURRENT_PAGINATION_SUCCESS = "SET_CURRENT_PAGINATION_SUCCESS";

export const selectListTypeRequest = (listType) => ({
  type: SELECT_LIST_TYPE_REQUEST,
  payload: listType,
});

export const selectListTypeFailure = (errorMessage) => ({
  type: SELECT_LIST_TYPE_FAILURE,
  payload: errorMessage,
});

export const selectListTypeSuccess = (listType) => ({
  type: SELECT_LIST_TYPE_SUCCESS,
  payload: listType,
});

//---

export const setCurrentPaginationRequest = (currentPagination) => ({
  type: SET_CURRENT_PAGINATION_REQUEST,
  payload: currentPagination,
});

export const setCurrentPaginationFailure = (errorMessage) => ({
  type: SET_CURRENT_PAGINATION_FAILURE,
  payload: errorMessage,
});

export const setCurrentPaginationSuccess = (currentPagination) => ({
  type: SET_CURRENT_PAGINATION_SUCCESS,
  payload: currentPagination,
});
