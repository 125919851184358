import styled from "@emotion/styled";

const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .email-registration-screen {

    .content-divider {
      border: 0;
      clear: both;
      display: block;
      width: 90%;
      background-color: #C4C4C4;
      height: 1px;
    }

    .email-registration-screen-content {
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin: 40px 20px 20px 20px;
      height: 100%;
      border-radius: 25px;
      justify-content: space-between;
      background: rgba(255, 255, 255,
        ${props => props.opacity ? props.opacity : 1});

      
      .registration-top {
        height: 40%;
        display: flex;
        justify-content: space-around;
        align-content: center;
        flex-direction: column;

        .registration-logo {
          width: 170px !important;
          height: 50px !important;
          align-self: center;
          object-fit: contain;
        }

        .registration-title {
          margin: 15px 0;
          font-weight: bold;
          font-size: 18px;
          line-height: 18px;
          color: #E85558;
          font-family: Futura PT Bold, serif;
          align-self: center;
        }

        label {
          font-size: 12px;
        }

        .preview-dropdown {
          margin-top: 3px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 3px;
          border: 1px solid #dee2e6;
          border-radius: 3px;
          margin-bottom: 3px;
          outline: 0;

          .preview-dropdown-logo {
            width: 10px;
            margin-left: 5px;
          }

          label {
            font-size: 12px;
          }
        }

        .birthday {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-bottom: 0.6px solid #898989;
          height: 10px;
          padding: 5px;
          margin: 5px 0 20px;

          label {
            font-size: 15px;
            font-family: Futura PT Book, serif;
            border-radius: 0;
            color: #666666;
          }
        }

        .registration-dropdown {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-bottom: 0.6px solid #898989;
          height: 10px;
          padding: 5px;
          margin-bottom: 20px;

          label {
            font-size: 15px;
            font-family: Futura PT Book, serif;
            border-radius: 0;
            color: #666666;
            margin: 5px 5px 5px 0;
          }

          .preview-dropdown-logo {
            margin-left: 5px;
          }
        }
      }

      .registration-bottom {
        display: flex;
        flex-direction: column;
        min-height: 35%;

        input {
          outline: 0;
          background: transparent;
          border: 0.6px solid #898989;
          border-radius: 18px;
          height: 20px;
          font-size: 15px;
          font-family: Futura PT Book, serif;
          margin: auto 2px;
          padding: 5px 5px 5px 20px;
        }

        .register-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          background-color: #FFCC33;
          padding: 10px 20px;
          border-radius: 30px;
          width: fit-content;
          align-self: center;
          margin: 10px;
          //width: 100px;
          height: 18px;

          label {
            font-family: Futura PT Book;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #ffffff;
            margin-right: 10px;
          }

          .arrow {
            height: 15px;
          }
        }

        .privacy {
          font-family: Futura PT Book;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #666666;
          margin-top: 5px;
          align-self: center;
        }
      }
    }
  }

  .registration-logo-container {
    // height: 50px;
    display: flex;
    margin-bottom: 2px;
    justify-content: center;
  }

  .registration-text {
    display: flex;
    justify-content: center;
    height: 40px;
    text-align: center;
    margin: 1px;
    flex-direction: column;
  }

  .registration-title {
    font-size: 12px;
  }

  .registration-subtitle {
    font-size: 10px;
  }

  .marketing-opt-in {
    display: flex;
    align-items: center;

    .opt-in-button {
      height: 25px;
      width: 25px;
    }
  }

  .privacy {
    font-family: Futura PT Book, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #666666;
    align-self: center;
    margin: auto auto auto 10px;
  }

  .registration-buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .register-btn {
      border-radius: 30px;
      width: 90px;
      height: 30px;
      display: flex;
      justify-content: center;
      color: white;
      align-content: center;
      font-size: 12px;

      label {
        margin: auto;
      }
    }
  }
`;

export default StyleContainer;
