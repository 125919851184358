import React from 'react';
import {AutomationRequest, getEventTypeMap} from "../../types";
import Grid from '@mui/material/Unstable_Grid2';
import cronstrue from 'cronstrue/i18n';
import ActionDisplay from "./ActionDisplay";
import {Typography} from "@mui/material";

const eventTypeMap: Map<any, string> = getEventTypeMap();

type Props = {
  automation: AutomationRequest
}

const AutomationDisplay = ({automation}: Props) => {
  return (
      <Grid
          container
          direction={'column'}
          alignItems={'center'}
          spacing={1}
          marginTop={3}
      >
        <Grid>
          {automation?.name}
        </Grid>
        <Grid>
          {automation?.description}
        </Grid>
        <Grid>
          {automation && eventTypeMap.get(automation.eventType)}
        </Grid>
        <Grid>
          {automation?.cron && cronstrue.toString(automation.cron)}
        </Grid>
        <Grid alignItems={'center'}>
          <Typography
              marginTop={2}
              variant={'h6'}
              sx={{textAlign: 'center'}}
          >
            Actions
          </Typography>
          <ActionDisplay actions={automation?.actions}/>
        </Grid>
      </Grid>
  )
}

export default AutomationDisplay;
