const colors = [
  {
    zero: "rgba(254, 203, 69, 0.75)",
    half: "rgba(254, 203, 69, 0.5)",
    one: "rgba(254, 203, 69, 0)",
  },
  {
    zero: "rgba(242, 151, 57, 0.75)",
    half: "rgba(242, 151, 57, 0.5)",
    one: "rgba(242, 151, 57, 0)",
  },
  {
    zero: "rgba(232, 85, 88, 0.75)",
    half: "rgba(232, 85, 88, 0.5)",
    one: "rgba(232, 85, 88, 0)",
  },
  {
    zero: "rgba(222, 65, 70, 0.75)",
    half: "rgba(222, 65, 70, 0.5)",
    one: "rgba(222, 65, 70, 0)",
  },
  {
    zero: "rgba(0, 0, 0, 0.75)",
    half: "rgba(0, 0, 0, 0.5)",
    one: "rgba(0, 0, 0, 0)",
  },
];

export default colors;
