import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import api from "utils/api";

import ShowCustomers from "./ShowCustomers";
import CustomerDetails from "./CustomerDetails";

import "assets/css/track/track.scss";

const ConnectTrackAndTrace = (props) => {
  const { selectedEntity } = props;

  const match = useRouteMatch();
  const history = useHistory();

  const [customers, setCustomers] = useState([]);
  const [navigation, setNavigation] = useState({ total: 1, current: 0 });

  const [name, setName] = useState("");
  const [selectedDate, setSelectedDate] = useState();

  const getDate = () => {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 21);
    currentDate.setMilliseconds(0);
    currentDate.setSeconds(0);
    currentDate.setMinutes(0);
    currentDate.setHours(0);
    return currentDate.getTime();
  };

  const getCustomers = async (page, size = 16) => {
    var url = `/organisations/${selectedEntity?.externalId}/contacts?size=${size}&page=${page}&sort=lastVisit,DESC&type=&name=${name}`;
    if (selectedDate) url += `&visitFrom=${selectedDate.getTime()}`;
    else url += `&visitFrom=${getDate()}`;

    return await api.get(url);
  };

  const fetctCustomers = async (page) => {
    const result = await getCustomers(page);
    setCustomers(result.data.content);
    setNavigation({
      total: Math.ceil(result.data.total / 16),
      current: result.data.pageable.page,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      fetctCustomers(0);
    };
    fetchData();
  }, [name, selectedDate, selectedEntity]);

  const goToAlert = async () => {
    const result = await getCustomers(0, 1000);
    history.push({
      pathname: "/alert",
      state: { customers: result.data.content },
    });
  };

  return (
    <div className="track-page">
      <Switch>
        <Route path={`${match.path}/:customerId`}>
          <CustomerDetails track={true} />
        </Route>
        <Route path={match.path}>
          <ShowCustomers
            customers={customers}
            fetctCustomers={fetctCustomers}
            setName={setName}
            navigation={navigation}
            setNavigation={setNavigation}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            track={true}
            organisationId={selectedEntity?.externalId}
            goToAlert={goToAlert}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default ConnectTrackAndTrace;
