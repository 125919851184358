import React, {useEffect, useState} from "react";
import {AutomationRequest} from "../types";
import Grid from '@mui/material/Unstable_Grid2';
import {Cron} from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import cronstrue from 'cronstrue/i18n';

const DEFAULT_CRON: string = '0 12 * * *';

type Props = {
  automation: AutomationRequest
  updateAutomation: (field: string, value: any) => void
}

const Schedule = ({automation, updateAutomation}: Props) => {

  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (automation?.cron && automation.cron !== value) {
      setValue(automation.cron);
    }

    if (!automation?.cron) {
      setCron(DEFAULT_CRON);
    }

  }, [automation?.cron]);

  const setCron = (cron: string) => {
    if (cron && cron !== automation?.cron) {
      updateAutomation('cron', cron);
      setValue(cron);
    }
  }

  return (
      <Grid
          container
          direction={'row'}
          alignItems="center"
      >
        <Grid
            xs={12}
            marginTop={2}
            alignItems={'center'}
        >
          <Cron
              value={value}
              setValue={setCron}
          />
        </Grid>
        <Grid
            xs={12}
            marginTop={2}
            alignItems={'center'}
        >
          {value && cronstrue.toString(value)}
        </Grid>
      </Grid>
  )
}

export default Schedule;