import React, {useEffect, useRef, useState} from "react";
import EmailEditor from "react-email-editor";
import {Template} from "./TemplateService";
import {isEmpty} from "lodash";

type EditorData = {
  design: string
  html: string
}

type Props = {
  template: Template
  setTemplate: (t: Template) => void
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const Editor = ({template, setTemplate, setDisabled}: Props) => {

  const editorRef = useRef(null);
  const [editorReady, setEditorReady] = useState<boolean>(false);
  const [data, setData] = useState<EditorData>();

  const onReady = () => {
    setEditorReady(true);
    editorRef.current.editor.addEventListener("design:updated",
        () => editorRef.current.editor.exportHtml((data: EditorData) => setData(data)));
  };

  useEffect(() => {
    if (template) {
      setTemplate({
        name: template.name,
        externalId: template.externalId,
        orgId: template.orgId,
        unlayerDesign: data?.design ? JSON.stringify(data.design) : null,
        //unlayerDesignHtml: data.html,
        messageBody: data?.html,
      });
    }
  }, [data]);

  useEffect(() => {
    if (editorReady) {
      if (template?.unlayerDesign) {
        const design: any = JSON.parse(template.unlayerDesign);
        !isEmpty(design) && editorRef.current.editor.loadDesign(design);
      } else {
        editorRef.current.editor.loadBlank();
      }
      setDisabled(false);
    }
  }, [template?.externalId, editorReady]);

  return (
      <EmailEditor
          projectId={10425}
          ref={editorRef}
          minHeight="500px"
          onReady={onReady}
      />
  );
}

export default Editor;
