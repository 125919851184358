import React from "react";
import styled from "@emotion/styled";
import {Check} from "phosphor-react";
import Button from "../../components/Button";

const AddCnameStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .fields-wrapper {
    margin: 16px 0;
    width: 95%;

    .field-wrapper {
      margin: 0 0 20px 0;

      label {
        padding: 0;
      }

      input {
        width: 100%;
        margin: 10px 0 0 0;
      }
    }
  }
`;

const AddCnameStep = (props) => {
  const { currentIdentityDomainRecords, close } = props;

  return (
    <AddCnameStepWrapper>
      <label className="title">Domain Authentication</label>

      <label>
        Authenticate your domain by adding the records below.
        <br />
        <b>
          {" "}
          After you’ve made the required DNS changes, please wait 24-48 hours
          for the changes to propagate.
        </b>
      </label>

      <div className="fields-wrapper">
        {currentIdentityDomainRecords?.length > 0 &&
          currentIdentityDomainRecords.map((record) => {
            const { value, name } = record;

            return (
              <div className="field-wrapper">
                <label>
                  Create a Cname record for <b>{name}</b>
                </label>
                <input type="text" defaultValue={value} readonly disabled />
              </div>
            );
          })}
      </div>

      <Button
        title={"Confirm"}
        // disabled={!isFormFilled}
        onClick={() => {
          close();
        }}
        icon={<Check size={16} color={"#FFF"} style={{ marginLeft: "10px" }} />}
      ></Button>
    </AddCnameStepWrapper>
  );
};

export default AddCnameStep;
