import React, {useState} from "react";
import {Box, IconButton, Typography} from "@mui/material";
import {Action, getActionTypeMap} from "../../../types";
import DeleteActionDialog from "./DeleteActionDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from '@mui/material/Unstable_Grid2';
import EditActionDialogStepper from "./EditActionDialogStepper";

const actionTypeMap: Map<any, string> = getActionTypeMap();

type Props = {
  organisationId: string
  action: Action
  addAction: (action: Action) => void
  updateAction: (action: Action) => void
  deleteAction: (action: Action) => void
  templateIds: string[]
}

const ActionRow = ({
                     organisationId,
                     action,
                     addAction,
                     deleteAction,
                     updateAction,
                     templateIds,
                   }: Props) => {

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  return (
      <>
        <Grid
            container
            key={`action-${action.stepNumber}`}
            alignContent='center'
            justifyContent='space-between'
            marginTop={1}
            minWidth='90vh'
        >
          <Grid xs={4}>
            <Typography variant="subtitle1" marginTop={1}>
              <span style={{marginRight: 15}}>{action.stepNumber + 1}</span>
              {actionTypeMap.get(action.actionType)}
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography variant="subtitle1" marginTop={1}>
              {action.name}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Box sx={{flex: '1 1 auto'}}/>
            <IconButton
                aria-label="edit"
                onClick={() => setOpenEdit(true)}
            >
              <EditIcon/>
            </IconButton>
            <IconButton
                aria-label="delete"
                onClick={() => setOpenDelete(true)}
            >
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>

        <DeleteActionDialog
            action={action}
            deleteAction={deleteAction}
            open={openDelete}
            setOpen={setOpenDelete}
        />

        <EditActionDialogStepper
            organisationId={organisationId}
            action={action}
            addAction={addAction}
            updateAction={updateAction}
            open={openEdit}
            setOpen={setOpenEdit}
            templateIds={templateIds}
        />
      </>
  );
}

export default ActionRow;