// export const EXIT_VENUE_REQUEST = "EXIT_VENUE_REQUEST";
// export const EXIT_VENUE_FAILURE = "EXIT_VENUE_FAILURE";
// export const EXIT_VENUE_SUCCESS = "EXIT_VENUE_SUCCESS";

export const SELECT_VENUE_REQUEST = "SELECT_VENUE_REQUEST";
export const SELECT_VENUE_FAILURE = "SELECT_VENUE_FAILURE";
export const SELECT_VENUE_SUCCESS = "SELECT_VENUE_SUCCESS";

export const CLEAR_SELECTED_VENUE_REQUEST = "CLEAR_SELECTED_VENUE_REQUEST";
export const CLEAR_SELECTED_VENUE_FAILURE = "CLEAR_SELECTED_VENUE_FAILURE";
export const CLEAR_SELECTED_VENUE_SUCCESS = "CLEAR_SELECTED_VENUE_SUCCESS";

export const GET_SELECTED_VENUE_SUMMARY_REQUEST =
  "GET_SELECTED_VENUE_SUMMARY_REQUEST";
export const GET_SELECTED_VENUE_SUMMARY_FAILURE =
  "GET_SELECTED_VENUE_SUMMARY_FAILURE";
export const GET_SELECTED_VENUE_SUMMARY_SUCCESS =
  "GET_SELECTED_VENUE_SUMMARY_SUCCESS";

export const GET_SELECTED_VENUE_CAMPAIGNS_REQUEST =
  "GET_SELECTED_VENUE_CAMPAIGNS_REQUEST";
export const GET_SELECTED_VENUE_CAMPAIGNS_FAILURE =
  "GET_SELECTED_VENUE_CAMPAIGNS_FAILURE";
export const GET_SELECTED_VENUE_CAMPAIGNS_SUCCESS =
  "GET_SELECTED_VENUE_CAMPAIGNS_SUCCESS";

// --- SELECT_VENUE

export const selectVenueRequest = (selectedVenue) => ({
  type: SELECT_VENUE_REQUEST,
  payload: selectedVenue,
});

export const selectVenueFailure = (errorMessage) => ({
  type: SELECT_VENUE_FAILURE,
  payload: errorMessage,
});

export const selectVenueSuccess = (selectedVenue) => ({
  type: SELECT_VENUE_SUCCESS,
  payload: selectedVenue,
});

// --- CLEAR_SELECTED_VENUE_REQUEST

export const clearSelectedVenueRequest = () => ({
  type: CLEAR_SELECTED_VENUE_REQUEST,
});

export const clearSelectedVenueFailure = (errorMessage) => ({
  type: CLEAR_SELECTED_VENUE_FAILURE,
  payload: errorMessage,
});

export const clearSelectedVenueSuccess = () => ({
  type: CLEAR_SELECTED_VENUE_SUCCESS,
});

// --- GET SELECT_VENUE SUMMARY

export const getSelectedVenueSummaryRequest = (venueId) => ({
  type: GET_SELECTED_VENUE_SUMMARY_REQUEST,
  payload: venueId,
});

export const getSelectedVenueSummaryFailure = (errorMessage) => ({
  type: GET_SELECTED_VENUE_SUMMARY_FAILURE,
  payload: errorMessage,
});

export const getSelectedVenueSummarySuccess = (venueSummary) => ({
  type: GET_SELECTED_VENUE_SUMMARY_SUCCESS,
  payload: venueSummary,
});

// --- GET SELECT_VENUE CAMPAIGNS

export const getSelectedVenueCampaignsRequest = (venueId) => ({
  type: GET_SELECTED_VENUE_CAMPAIGNS_REQUEST,
  payload: venueId,
});

export const getSelectedVenueCampaignsFailure = (errorMessage) => ({
  type: GET_SELECTED_VENUE_CAMPAIGNS_FAILURE,
  payload: errorMessage,
});

export const getSelectedVenueCampaignsSuccess = (venueCampaigns) => ({
  type: GET_SELECTED_VENUE_CAMPAIGNS_SUCCESS,
  payload: venueCampaigns,
});
