export const GET_EMAILS_STATS_REQUEST = "GET_EMAILS_STATS_REQUEST";
export const GET_EMAILS_STATS_FAILURE = "GET_EMAILS_STATS_FAILURE";
export const GET_EMAILS_STATS_SUCCESS = "GET_EMAILS_STATS_SUCCESS";

export const GET_YEAR_OPEN_RATE_STATS_REQUEST =
  "GET_YEAR_OPEN_RATE_STATS_REQUEST";
export const GET_YEAR_OPEN_RATE_STATS_FAILURE =
  "GET_YEAR_OPEN_RATE_STATS_FAILURE";
export const GET_YEAR_OPEN_RATE_STATS_SUCCESS =
  "GET_YEAR_OPEN_RATE_STATS_SUCCESS";

export const GET_MONTH_OPEN_RATE_STATS_REQUEST =
  "GET_MONTH_OPEN_RATE_STATS_REQUEST";
export const GET_MONTH_OPEN_RATE_STATS_FAILURE =
  "GET_MONTH_OPEN_RATE_STATS_FAILURE";
export const GET_MONTH_OPEN_RATE_STATS_SUCCESS =
  "GET_MONTH_OPEN_RATE_STATS_SUCCESS";

export const GET_WEEK_OPEN_RATE_STATS_REQUEST =
  "GET_WEEK_OPEN_RATE_STATS_REQUEST";
export const GET_WEEK_OPEN_RATE_STATS_FAILURE =
  "GET_WEEK_OPEN_RATE_STATS_FAILURE";
export const GET_WEEK_OPEN_RATE_STATS_SUCCESS =
  "GET_WEEK_OPEN_RATE_STATS_SUCCESS";

export const GET_DAY_OPEN_RATE_STATS_REQUEST =
  "GET_DAY_OPEN_RATE_STATS_REQUEST";
export const GET_DAY_OPEN_RATE_STATS_FAILURE =
  "GET_DAY_OPEN_RATE_STATS_FAILURE";
export const GET_DAY_OPEN_RATE_STATS_SUCCESS =
  "GET_DAY_OPEN_RATE_STATS_SUCCESS";

export const UPDATE_SEND_CONTACTS_REQUEST = "UPDATE_SEND_CONTACTS_REQUEST";
export const UPDATE_SEND_CONTACTS_FAILURE = "UPDATE_SEND_CONTACTS_FAILURE";
export const UPDATE_SEND_CONTACTS_SUCCESS = "UPDATE_SEND_CONTACTS_SUCCESS";

// --- GET EMAIL STATS

export const getEmailsStatsRequest = (organisationId, timeUnit) => ({
  type: GET_EMAILS_STATS_REQUEST,
  payload: {
    organisationId,
    timeUnit,
  },
});

export const getEmailsStatsFailure = (errorMessage) => ({
  type: GET_EMAILS_STATS_FAILURE,
  payload: errorMessage,
});

export const getEmailsStatsSuccess = (emailsStats) => ({
  type: GET_EMAILS_STATS_SUCCESS,
  payload: emailsStats,
});

// --- GET YEAR OPEN RATE

export const getYearOpenRateStatsRequest = (organisationId) => ({
  type: GET_YEAR_OPEN_RATE_STATS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getYearOpenRateStatsFailure = (errorMessage) => ({
  type: GET_YEAR_OPEN_RATE_STATS_FAILURE,
  payload: errorMessage,
});

export const getYearOpenRateStatsSuccess = (yearOpenRateStats) => ({
  type: GET_YEAR_OPEN_RATE_STATS_SUCCESS,
  payload: yearOpenRateStats,
});

// --- GET MONTH OPEN RATE

export const getMonthOpenRateStatsRequest = (organisationId) => ({
  type: GET_MONTH_OPEN_RATE_STATS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getMonthOpenRateStatsFailure = (errorMessage) => ({
  type: GET_MONTH_OPEN_RATE_STATS_FAILURE,
  payload: errorMessage,
});

export const getMonthOpenRateStatsSuccess = (monthOpenRateStats) => ({
  type: GET_MONTH_OPEN_RATE_STATS_SUCCESS,
  payload: monthOpenRateStats,
});

// --- GET WEEKLY OPEN RATE

export const getWeekOpenRateStatsRequest = (organisationId) => ({
  type: GET_WEEK_OPEN_RATE_STATS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getWeekOpenRateStatsFailure = (errorMessage) => ({
  type: GET_WEEK_OPEN_RATE_STATS_FAILURE,
  payload: errorMessage,
});

export const getWeekOpenRateStatsSuccess = (weekOpenRateStats) => ({
  type: GET_WEEK_OPEN_RATE_STATS_SUCCESS,
  payload: weekOpenRateStats,
});

// --- GET DAILY OPEN RATE

export const getDayOpenRateStatsRequest = (organisationId) => ({
  type: GET_DAY_OPEN_RATE_STATS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getDayOpenRateStatsFailure = (errorMessage) => ({
  type: GET_DAY_OPEN_RATE_STATS_FAILURE,
  payload: errorMessage,
});

export const getDayOpenRateStatsSuccess = (dayOpenRateStats) => ({
  type: GET_DAY_OPEN_RATE_STATS_SUCCESS,
  payload: dayOpenRateStats,
});

// --- Update contacts list

export const updateSendContactsRequest = (updatedContacts) => ({
  type: UPDATE_SEND_CONTACTS_REQUEST,
  payload: {
    updatedContacts,
  },
});

export const updateSendContactsFailure = (errorMessage) => ({
  type: UPDATE_SEND_CONTACTS_FAILURE,
  payload: errorMessage,
});

export const updateSendContactsSuccess = (updatedContacts) => ({
  type: UPDATE_SEND_CONTACTS_SUCCESS,
  payload: updatedContacts,
});
