import React from 'react'

import Plans from './plans'

import { ReactComponent as CloseIcon } from '../../assets/images/delete.svg';

import '../../assets/css/billing/plans-popup.scss'

export default ({ title, subtitle, plans, close, buy }) => {

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    return (
        <div className='plans-popup' onClick={onClick}>
            <div className='plans-popup-content'>
                <CloseIcon className='close-icon' onClick={close} />
                <Plans title={title} subtitle={subtitle} plans={plans} buy={buy} />
            </div>
        </div>
    )
}