import React from "react";

import styled from "@emotion/styled";

import {Gear, PaperPlaneTilt, Trash} from "phosphor-react";

import ReactTooltip from "react-tooltip";

const DomainControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-evenly;
  background: #ece9e9;
  border-radius: 45px;
  width: 140px;
  height: 30px;
  cursor: pointer;

  label {
    font-family: Roboto;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    margin-left: 20px;
    border-right: 1px solid #ffffff;
    padding-top: 12.5px;
    height: 100%;
    width: 90px;
  }

  .dd-icon {
    margin-left: 6px;

    path {
      fill: #ffffff;
    }
  }
`;

const DomainControls = (props) => {
  const {
    deleteIdentityRequest,
    setCurrentVerificationType,
    resendIdentityRequest,
    setShowAddDomainPopup,
    // getDomainRecordsRequest,
    selectIdentityRequest,
  } = props;
  const { externalId, status, type } =
    props.domain;

  const canResendEmail = type === "EMAIL" && status !== "Success";
  // const canAddCname = type === "EMAIL" && status == "Success";
  const canAddCname = true;

  return (
    <DomainControlsWrapper>
      <PaperPlaneTilt
        data-tip
        data-for="resend"
        size={18}
        style={{
          opacity: canResendEmail ? 1 : 0.22,
          pointerEvents: canResendEmail ? "auto" : "none",
        }}
        onClick={() => resendIdentityRequest(externalId)}
      />

      <Gear
        data-tip
        data-for="add-cname"
        size={18}
        style={{
          opacity: canAddCname ? 1 : 0.22,
          pointerEvents: canAddCname ? "auto" : "none",
        }}
        onClick={async () => {
          await selectIdentityRequest(externalId);
          // await getDomainRecordsRequest(externalId);
          await setCurrentVerificationType("domain");
          await setShowAddDomainPopup(true);
        }}
      />

      <Trash
        data-tip
        data-for="delete"
        size={18}
        style={{
          opacity: 1,
        }}
        onClick={() => deleteIdentityRequest(externalId)}
      />

      <ReactTooltip id="resend" type="warning" effect="solid">
        <span>Resend verification email</span>
      </ReactTooltip>

      <ReactTooltip id="add-cname" type="warning" effect="solid">
        <span>Add Cname records</span>
      </ReactTooltip>

      <ReactTooltip id="delete" type="warning" effect="solid">
        <span>Delete</span>
      </ReactTooltip>
    </DomainControlsWrapper>
  );
};

export default DomainControls;
