import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import VenuesDropDown from "./VenuesDropDown";
import UserDropdown from "./UserDropdown";
import TopBarWrapper from "./TopBarWrapper";

const TopBar = (props) => {
  const {
    selectedOrganisation,
    selectedOrganisationVenues,
    selectedOrganisationHasVenues,
    selectedVenue,
    selectVenueRequest,
    selectEntityRequest,
    selectedEntity,
    // editMode,
    // setEditMode,
    isSuperAdmin,
    exitOrganisationRequest,
    organisation,
    getSelectedEntitySummaryRequest
  } = props;

  const location = useLocation();
  const dispatch = useDispatch();

  const [isDropdownTouched, setIsDropdownTouched] = useState(false);
  const [venueName, setVenueName] = useState();
  const [showDropDown, setShowDropDown] = useState(false);
  const [showExit, setShowExit] = useState(false);

  useEffect(() => {
    setShowExit(selectedOrganisation && isSuperAdmin)
  }, [selectedOrganisation, isSuperAdmin])

  useEffect(() => {
    const showVenues = selectedOrganisation
        && selectedOrganisationVenues
        && selectedOrganisationVenues.length > 1

    setShowDropDown(showVenues);
    setName(showVenues);

  }, [selectedOrganisation, selectedOrganisationVenues]);

  useEffect(() => {
    if (selectedVenue) {
      onSelectVenue(selectedVenue);
      setName(showDropDown);
    }
  }, [selectedVenue]);

  const setName = (isShowDropDown) => {
    if (isShowDropDown) {
      setVenueName(selectedVenue?.name);
    } else {
      setVenueName(selectedOrganisation?.name);
    }
  }

  const onSelectVenue = (entity) => {
    if (entity) {
      !isDropdownTouched && setIsDropdownTouched(true);
      selectEntityRequest(entity);
      selectVenueRequest(entity);
      getSelectedEntitySummaryRequest(entity.externalId);
      location.pathname.includes("/email/new") &&
      dispatch({type: "RESET_ALL_VALUES_REQUEST"});
    }
  };

  return (
      <TopBarWrapper>
        {location.pathname === "/dashboard" ? (
            <div className="support-icon">
              <img alt="support icon" src={require("assets/images/support-icon.svg").default}/>

              <label>
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.unaro.co.uk/contact-us/"
                    style={{textDecoration: "none", color: "black"}}
                >
                  Support
                </a>
              </label>
            </div>
        ) : (
            <div>{' '}</div>
        )}
        {/*{location.pathname === "/dashboard" && !isSuperAdmin && (*/}
        {/*    // <div className='edit-mode' onClick={() => setEditMode(!editMode)}>*/}
        {/*    //     <label>Edit Mode</label>*/}
        {/*    //     {editMode ? <SwitchOn className='switch-icon' /> : <SwitchOff className='switch-icon' />}*/}
        {/*    // </div>*/}

        {/*    <SwitchBtn active={editMode} setActive={setEditMode}/>*/}
        {/*)}*/}
        {location.pathname === "/alert" && (
            <div className="email-creator">
              <img alt="email creator" src={require("assets/images/email-creator.svg").default}/>
              <label>Email Creator</label>
            </div>
        )}
        {location.pathname.includes("/sms/reports/") && (
            <div className="email-creator">
              <label>Message Report</label>
            </div>
        )}
        <div className="top-bar-right">
          {showExit && (
              <div
                  className="exit-organistion"
                  onClick={() => {
                    setIsDropdownTouched(false);
                    dispatch({type: "RESET_ALL_VALUES_REQUEST"});
                    exitOrganisationRequest();
                  }}
              >
                <label>Exit organisation</label>
              </div>
          )}

          {showDropDown ? (
              <VenuesDropDown
                  className="top-bar-organisations-dropdown"
                  options={selectedOrganisationVenues}
                  selectedEntity={selectedEntity}
                  selectedOrganisationHasVenues={selectedOrganisationHasVenues}
                  onSelect={onSelectVenue}
                  organisation={organisation}
              />
          ) : venueName && (
              <label className="venue-name" style={{minWidth: '220px'}}>
                {venueName}
              </label>
          )}
          <UserDropdown/>
        </div>
      </TopBarWrapper>
  );
};

export default TopBar;
