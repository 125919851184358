import styled from "@emotion/styled";

const EditLandingPageSettingsWrapper = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 24px;

  .edit-landing-page-switch {
    display: flex;
    align-items: center;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;

    &__custom {
    @extend . edit-landing-page-switch;
      margin-left: 15px;

      .text-switch--custom {
        margin-left: 10px;
      }
    }
  }

  .edit-landing-page-custom-input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 11px;
    border-radius: 25px;
    margin-right: 55px;
    width: 265px;
    padding: 12px 20px;
    background: transparent;
    border-color: $grey-2;
    outline: 0;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    margin-left: 24px;
  }

  .title {
    font-weight: bold;
    margin-bottom: 16px;
  }

  .edit-landing-page-settings-top {
    display: flex;
    flex-direction: column;

    .edit-landing-page-settings-inputs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .buttoned-input {
        margin-top: 10px;

        label {
          width: 130px;
        }
      }

      .edit-landing-page-settings-ssid {
        display: flex;
        flex-direction: column;
      }

      .edit-landing-page-settings-email {
        display: flex;
        flex-direction: column;
        margin-left: 50px;
      }

      .edit-landing-page-settings-inputs-label {
        background-color: $accent-color;
        color: white;
        border-radius: 25px;
        padding: 5px 20px 5px 20px;
        margin-top: 10px;
        margin-left: 100px;
        margin-right: 50px;
      }
    }

    .edit-landing-page-settings-switch {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 15px;
      margin-right: 15px;

      .text-switch {
        margin-top: 10px;
        margin-left: 10px;

        .text-switch-label {
          width: 170px;
        }

        .text-switch-actions {
          width: 60px;
        }
      }
    }

    .redirect-input {
      display: flex;
      flex-direction: row;
      user-select: none;
      align-items: center;
      margin-top: 10px;

      label {
        color: #3c4858;
        font-weight: 500;
        width: 150px;
        user-select: none;
      }

      input {
        padding: 10px 15px 10px 15px;
        border: 0.75px solid #dbdbdb;
        border-radius: 25px;
        background-color: #f5f5f5;
        color: #3c4858;
        outline: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 15px;
        flex: 1;
      }
    }
  }

  .edit-landing-page-settings-line {
    margin-top: 30px;
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    background-color: #eeeeee;
    height: 1px;
  }

  .facebook-like {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    margin-top: 30px;

    label {
      color: #3c4858;
      font-weight: 500;
      user-select: none;
    }

    .fb-likes-switch {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;

      .switch {
        height: 25px;
        margin-left: 20px;
      }
    }

    .fb-url-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 30px;
      flex: 1;
      height: 40px;

      input {
        padding: 10px 15px 10px 15px;
        border: 0.75px solid #dbdbdb;
        border-radius: 25px;
        background-color: #f5f5f5;
        color: #3c4858;
        outline: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 15px;
        flex: 1;
        margin-left: 20px;
        margin-right: 2px;
      }
    }
  }

  .debug-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;

    .switch {
      height: 25px;
      margin-left: 20px;
    }

    label {
      color: #3c4858;
      font-weight: 500;
      user-select: none;
      width: 178px;
    }
  }

  .edit-landing-page-settings-bottom {
    display: flex;
    flex-direction: column;

    .color-pickers {
      display: flex;
      margin-top: 30px;
      width: 75%;
      justify-content: space-between;
      align-content: center;
      align-items: center;
    }

    label {
      font-weight: bold;
      white-space: nowrap;
    }
  }
`

export default EditLandingPageSettingsWrapper