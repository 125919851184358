import styled from "@emotion/styled";

const CampaignsWidget = styled.div`
  cursor: pointer;
  grid-row: 1/2;
  grid-column: 1/7;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  /* margin: 0 10px; */
  box-sizing: border-box;
  /* max-height:60%; */
  position: relative;
  /* min-height: ${(props) => props.vhPixels / 2.4}px;
  max-height: ${(props) => props.vhPixels / 2.4}px; */



  @media only screen and (max-width: 1200px) {
    grid-row: 2/3;
    grid-column: 1/6;
  }

  .sdc-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    margin: 25px;

    label {
      margin-right: 10px;
      font-size: 18px;
      font-family: Roboto;
      font-weight: 600;
      opacity: 0.7;

      span {
        font-size: 10px;
        font-weight: 200;
        opacity: 0.85;
      }
    }

    img {
      width: 20px;
      opacity: 0.9;
    }
  }

  .sdc-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    /* border-top: 1px solid #e9ebeb; */
    border-bottom: 1px solid #c9c9c9;
    height: 30px;
    margin: 10px 25px 5px 25px;
    /* padding: 10px; */
    /* padding-right: 35px; */
    /* background: rgb(241, 245, 246); */
    /* background: linear-gradient(
      0deg,
      rgba(241, 245, 246, 1) 0%,
      rgba(255, 255, 255, 1) 51%
    ); */

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Roboto;
      font-weight: 500;
      font-size: 12px;
      color: #5b5b5b;
      text-align: center;
    }
  }

  .sdc-campaigns * {
    cursor: pointer;
  }

  .sdc-campaigns {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: calc(50vh - 200px);

    @media only screen and (max-width: 1200px) {
      max-height: calc(50vh - 150px);
    }

    @media only screen and (max-width: 850px) {
        padding: 10px 0;
        max-height: calc(50vh - 200px);
      }

    .sdc-campaign {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      padding: 10px 0;
      align-items: center;
      box-shadow: 0px 1px 3px rgb(0 0 0 / 14%);
      border-radius: 4px;
      background-color: #f2f2f2;
      margin: 10px 25px 0 25px;

      @media only screen and (max-width: 1200px) {
        padding: 10px 0;
        margin: 5px 10px;
      }

      label {
        font-family: Roboto;
        font-size: 11px;
        color: #5b5b5b;
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        margin-left: 10px;
      }

      :first-child {
        font-weight: 500;
        font-family: Roboto Bold;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
     
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      border-radius:10px;
      min-height:24px;
      visibility: ${(props) => (props.isHover ? "visible" : "hidden")};
    }
  }
`;

export default CampaignsWidget;
