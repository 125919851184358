import { combineReducers } from "redux";

import selectedOrganisationReducer from "./selectedOrganisationReducer";
import selectedVenueReducer from "./selectedVenueReducer";
import selectedEntityReducer from "./selectedEntityReducer";

const sendRootReducer = combineReducers({
  "selectedOrganisation": selectedOrganisationReducer,
  "selectedVenue": selectedVenueReducer,
  "selectedEntity": selectedEntityReducer,
});

export default sendRootReducer;
