export const IMPORT_CONTACTS_LIST_REQUEST = "IMPORT_CONTACTS_LIST_REQUEST";
export const IMPORT_CONTACTS_LIST_FAILURE = "IMPORT_CONTACTS_LIST_FAILURE";
export const IMPORT_CONTACTS_LIST_SUCCESS = "IMPORT_CONTACTS_LIST_SUCCESS";

export const PARSE_EXCEL_REQUEST = "PARSE_EXCEL_REQUEST";
export const PARSE_EXCEL_FAILURE = "PARSE_EXCEL_FAILURE";
export const PARSE_EXCEL_SUCCESS = "PARSE_EXCEL_SUCCESS";

export const DELETE_IMPORTED_LIST_COLUMN_REQUEST =
  "DELETE_IMPORTED_LIST_COLUMN_REQUEST";
export const DELETE_IMPORTED_LIST_COLUMN_FAILURE =
  "DELETE_IMPORTED_LIST_COLUMN_FAILURE";
export const DELETE_IMPORTED_LIST_COLUMN_SUCCESS =
  "DELETE_IMPORTED_LIST_COLUMN_SUCCESS";

export const EDIT_IMPORTED_LIST_COLUMN_REQUEST =
  "EDIT_IMPORTED_LIST_COLUMN_REQUEST";
export const EDIT_IMPORTED_LIST_COLUMN_FAILURE =
  "EDIT_IMPORTED_LIST_COLUMN_FAILURE";
export const EDIT_IMPORTED_LIST_COLUMN_SUCCESS =
  "EDIT_IMPORTED_LIST_COLUMN_SUCCESS";

export const importContactsListRequest = (
  organisationId,
  file,
  action,
  columns
) => ({
  type: IMPORT_CONTACTS_LIST_REQUEST,
  payload: {
    organisationId,
    file,
    action, //add
    columns,
  },
});

export const importContactsListFailure = (errorMessage) => ({
  type: IMPORT_CONTACTS_LIST_FAILURE,
  payload: errorMessage,
});

export const importContactsListSuccess = (updatedContacts) => ({
  type: IMPORT_CONTACTS_LIST_SUCCESS,
  payload: {
    updatedContacts,
  },
});

//---

export const parcelExcelRequest = (excel) => ({
  type: PARSE_EXCEL_REQUEST,
  payload: {
    excel,
  },
});

export const parcelExcelFailure = (errorMessage) => ({
  type: PARSE_EXCEL_FAILURE,
  payload: errorMessage,
});

export const parcelExcelSuccess = (parsedExcel) => ({
  type: PARSE_EXCEL_SUCCESS,
  payload: {
    parsedExcel,
  },
});

//---

export const deleteImportedListColumnRequest = (column) => ({
  type: DELETE_IMPORTED_LIST_COLUMN_REQUEST,
  payload: {
    column,
  },
});

export const deleteImportedListColumnFailure = (errorMessage) => ({
  type: DELETE_IMPORTED_LIST_COLUMN_FAILURE,
  payload: errorMessage,
});

export const deleteImportedListColumnSuccess = (updatedColumns) => ({
  type: DELETE_IMPORTED_LIST_COLUMN_SUCCESS,
  payload: {
    updatedColumns,
  },
});

//---

export const editImportedListColumnRequest = (editedColumn) => ({
  type: EDIT_IMPORTED_LIST_COLUMN_REQUEST,
  payload: {
    editedColumn,
  },
});

export const editImportedListColumnFailure = (errorMessage) => ({
  type: EDIT_IMPORTED_LIST_COLUMN_FAILURE,
  payload: errorMessage,
});

export const editImportedListColumnSuccess = (updatedColumns) => ({
  type: EDIT_IMPORTED_LIST_COLUMN_SUCCESS,
  payload: {
    updatedColumns,
  },
});

//---
