import React from "react";

import NameCampaingField from "./components/NameCampaignField";
import SelectTemplateField from "./components/SelectTemplateField";
import WriteMessageField from "./components/WriteMessageField";
import SendToField from "./components/SendToField";
import FromField from "./components/FromField";
import PersonalisationField from "./components/PersonalisationField";
// import InsertTrackField from "./components/InsertTrackField";

const Form = (props) => {
  const {
    organisationId,
    audiences,
    errorMessage,
    setCampaignName,
    templates,
    selectedTemplate,
    changeSelectedTemplate,
    setMessage,
    audienceTags,
    setTrackUrl,
    addTrackUrls,
    campaignName,
    selectedAudience,
    setSelectedAudience,
    message,
    fromName,
    onChangeFromName,
    trackUrl,
    setAudienceTags,
    selectedCustomers,
    setSelectedContacts,
  } = props;

  const contactOptions = audiences.map((contact) => ({
    label: `${contact.forename} ${contact.surname}`,
    value: contact.externalId,
  }));

  return (
      <div className="quick-send__main-content">
        {errorMessage && (
            <p className="quick-send__error-message">{errorMessage}</p>
        )}

        <NameCampaingField
            setCampaignName={setCampaignName}
            campaignName={campaignName}
        />
        <SelectTemplateField
            templates={templates}
            selectedTemplate={selectedTemplate}
            changeSelectedTemplate={changeSelectedTemplate}
        />
        <WriteMessageField setMessage={setMessage} message={message}/>

        <SendToField
            organisationId={organisationId}
            audienceTags={audienceTags}
            setSelectedAudience={setSelectedAudience}
            contactOptions={contactOptions}
            setAudienceTags={setAudienceTags}
            selectedAudience={selectedAudience}
            selectedCustomers={selectedCustomers}
            setSelectedContacts={setSelectedContacts}
        />

        <FromField
            fromName={fromName}
            errorMessage={errorMessage}
            onChangeFromName={onChangeFromName}
        />

        <PersonalisationField message={message} setMessage={setMessage}/>

        {/*<InsertTrackField*/}
        {/*  trackUrl={trackUrl}*/}
        {/*  addTrackUrls={addTrackUrls}*/}
        {/*  setTrackUrl={setTrackUrl}*/}
        {/*/>*/}
      </div>
  );
};

export default Form;
