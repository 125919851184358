import React from "react";
import LineChart from "./LineChart";
import ChartWrapper from "./style";

const Chart = (props) => {
  const { type } = props;
  if (type === "line") {
    return (
      <ChartWrapper className="chart-wrapper">
        <LineChart {...props} />
      </ChartWrapper>
    );
  }
};

export default Chart;
