import api from "utils/api";
// import moment from "moment";
import { UNLAYER_API_KEY } from "consts";

export const apiGetFeaturedTemplates = (data) => {
  const { page = 0 } = data;
  const apiRoute = `email/template/featured?size=10&page=${page}`;

  return api.get(apiRoute);
};

export const apiGetSavedTemplates = (data) => {
  const { organisationId, page = 0 } = data;

  const apiRoute = `/email/organisation/${organisationId}/template/?size=10&page=${page}`;

  // const apiRoute = `/email/organisation/${organisationId}/template`;

  return api.get(apiRoute);
};

export const apiExportTemplateToImg = (data) => {
  const { templateData } = data;

  const url = "https://api.unlayer.com/v2/export/image";

  const bodyObj = {
    displayMode: "email",
    design: templateData,
  };

  const headers = { headers: { Authorization: `Basic ${UNLAYER_API_KEY}` } };

  return api.get(url, bodyObj, headers);
};

export const apiCreateTemplate = (data) => {
  const { templateData } = data;

  const apiRoute = `/email/template`;

  return api.post(apiRoute, templateData);
};

export const apiDeleteTemplate = (data) => {
  const { organisationId, templateId } = data;

  const apiRoute = `/email/organisation/${organisationId}/template/${templateId}`;

  return api.delete(apiRoute);
};

export const apiCreateFeaturedTemplate = (data) => {
  // const { organisationId } = data;
  // const apiRoute = `/organisation/savedEmailDesignTemplates`;
  const apiRoute = `/email/organisation/be4b3ac8-d89d-45fc-9e9a-635f1d2bafce/template`;

  return api.get(apiRoute);
};

export const apiSendTestEmail = (data) => {
  const { emailData } = data;

  // emailData.htmlText = "<p>nu uita de examenul de maine de la ora 14 la tehnologia informatiei</p>"

  const apiRoute = `/email`;

  return api.post(apiRoute, emailData);
};

export const apiCreateCurrentCampaign = (data) => {
  // const apiRoute = `/email/campaign/schedule`;
  const apiRoute = `/email`;

  return api.post(apiRoute, data);
};
