import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import { getEmailGlobalStatsRequest } from "./actions";

import {
  selectIsLoading,
  selectErrorMessage,
  selectGlobalEmailStats,
} from "./selectors";

export const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  errorMessage: selectErrorMessage,
  globalEmailStats: selectGlobalEmailStats,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getEmailGlobalStatsRequest,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
