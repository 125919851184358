export const SEND_VERIFICATION_CODE_REQUEST = "SEND_VERIFICATION_CODE_REQUEST";
export const SEND_VERIFICATION_CODE_FAILURE = "SEND_VERIFICATION_CODE_FAILURE";
export const SEND_VERIFICATION_CODE_SUCCESS = "SEND_VERIFICATION_CODE_SUCCESS";

export const SEND_TEST_EMAIL_REQUEST = "SEND_TEST_EMAIL_REQUEST";
export const SEND_TEST_EMAIL_FAILURE = "SEND_TEST_EMAIL_FAILURE";
export const SEND_TEST_EMAIL_SUCCESS = "SEND_TEST_EMAIL_SUCCESS";

export const UPDATE_CURRENT_STEP_REQUEST = "UPDATE_CURRENT_STEP_REQUEST";
export const UPDATE_CURRENT_STEP_FAILURE = "UPDATE_CURRENT_STEP_FAILURE";
export const UPDATE_CURRENT_STEP_SUCCESS = "UPDATE_CURRENT_STEP_SUCCESS";

export const RESET_ALL_VALUES_REQUEST = "RESET_ALL_VALUES_REQUEST";
export const RESET_ALL_VALUES_FAILURE = "RESET_ALL_VALUES_FAILURE";
export const RESET_ALL_VALUES_SUCCESS = "RESET_ALL_VALUES_SUCCESS";

// --- SEND VERIFICATION CODE

export const sendVerificationCodeRequest = (organisationId, email) => ({
  type: SEND_VERIFICATION_CODE_REQUEST,
  payload: {
    organisationId,
    email,
  },
});

export const sendVerificationCodeFailure = (errorMessage) => ({
  type: SEND_VERIFICATION_CODE_FAILURE,
  payload: errorMessage,
});

export const sendVerificationCodeSuccess = (confirmationData) => ({
  type: SEND_VERIFICATION_CODE_SUCCESS,
  payload: confirmationData,
});

// --- SEND TEST EMAIL

export const sendTestEmailRequest = (emailData) => ({
  type: SEND_TEST_EMAIL_REQUEST,
  payload: {
    emailData,
  },
});

export const sendTestEmailFailure = (errorMessage) => ({
  type: SEND_TEST_EMAIL_FAILURE,
  payload: errorMessage,
});

export const sendTestEmailSuccess = (confirmationData) => ({
  type: SEND_TEST_EMAIL_SUCCESS,
  payload: confirmationData,
});

// --- UPDATE_CURRENT_STEP_REQUEST

export const updateCurrentStepRequest = (step) => ({
  type: UPDATE_CURRENT_STEP_REQUEST,
  payload: {
    step,
  },
});

export const updateCurrentStepFailure = (errorMessage) => ({
  type: UPDATE_CURRENT_STEP_FAILURE,
  payload: errorMessage,
});

export const updateCurrentStepSuccess = (updatedStep) => ({
  type: UPDATE_CURRENT_STEP_SUCCESS,
  payload: updatedStep,
});

// --- RESET_ALL_VALUES_REQUEST

export const resetAllValuesRequest = (redirectTo) => ({
  type: RESET_ALL_VALUES_REQUEST,
  payload: redirectTo,
});

export const resetAllValuesFailure = (errorMessage) => ({
  type: RESET_ALL_VALUES_FAILURE,
  payload: errorMessage,
});

export const resetAllValuesSuccess = () => ({
  type: RESET_ALL_VALUES_SUCCESS,
});
