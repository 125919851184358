const sendNewEmailConfig = [
  {
    id: 0,
    name: "Create Campaign",
    selectedColor: "#FFCC33",
  },
  {
    id: 1,
    name: "Design Email",
    selectedColor: "#FF9933",
  },
  {
    id: 2,
    name: "Select Audience",
    selectedColor: "#FF6633",
  },
  {
    id: 3,
    name: "Preview & Send",
    selectedColor: "#EA5454",
  },
  {
    id: 4,
    name: "All Done!",
    selectedColor: "#FF9933",
  },
];

export default sendNewEmailConfig;
