import {call, put, select, takeLatest} from "redux-saga/effects";

import {push} from "connected-react-router";

import actions from "./actions";
import {ampli} from "containers/Amplitude/ampli";

import {
  apiGetCampaigns,
  apiGetLicenses,
  apiGetOrganisationVenues,
  apiGetSummary,
} from "./api";

const {
  SELECT_ORGANISATION_REQUEST,
  SELECT_ORGANISATION_SUCCESS,
  EXIT_ORGANISATION_REQUEST,
  GET_SELECTED_ORGANISATION_LICENSES_REQUEST,
  GET_SELECTED_ORGANISATION_VENUES_REQUEST,
  GET_SELECTED_ORGANISATION_SUMMARY_REQUEST,
  GET_SELECTED_ORGANISATION_CAMPAIGNS_REQUEST,
  selectOrganisationFailure,
  selectOrganisationSuccess,
  exitOrganisationFailure,
  exitOrganisationSuccess,
  getSelectedOrganisationLicensesFailure,
  getSelectedOrganisationLicensesSuccess,
  getSelectedOrganisationVenuesFailure,
  getSelectedOrganisationVenuesSuccess,
  getSelectedOrganisationSummaryFailure,
  getSelectedOrganisationSummarySuccess,
  getSelectedOrganisationCampaignsFailure,
  getSelectedOrganisationCampaignsSuccess,
  SELECT_VENUE_REQUEST,
  SELECT_VENUE_SUCCESS,
  GET_SELECTED_VENUE_SUMMARY_REQUEST,
  GET_SELECTED_VENUE_CAMPAIGNS_REQUEST,
  selectVenueFailure,
  selectVenueSuccess,
  getSelectedVenueSummaryFailure,
  getSelectedVenueSummarySuccess,
  getSelectedVenueCampaignsFailure,
  getSelectedVenueCampaignsSuccess,
  clearSelectedVenueRequest,
  clearEntityRequest,
  SELECT_ENTITY_REQUEST,
  GET_SELECTED_ENTITY_SUMMARY_REQUEST,
  selectEntityRequest,
  selectEntityFailure,
  selectEntitySuccess,
  getSelectedEntitySummaryFailure,
  getSelectedEntitySummarySuccess,
} = actions;

//SELECTED ORGANISATION SAGAS

function* selectOrganisationWorker(action) {
  try {
    yield put(clearSelectedVenueRequest());

    yield put(selectOrganisationSuccess(action.payload));

    yield put(push("/org-dashboard"));

    ampli.unaroPageVisitEventWithProperties({
      emailAddress: JSON.parse(localStorage.getItem("user")).email,
      organisationName:
          JSON.parse(localStorage.getItem("user")).organisations[0] != null
              ? JSON.parse(localStorage.getItem("user")).organisations[0].name
              : "",
      eventType: "page visit: Organisation Dashboard",
    });
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(selectOrganisationFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* exitOrganisationWorker(action) {
  try {
    yield put(exitOrganisationSuccess(action.payload));

    localStorage.removeItem("selected-organisation");
    localStorage.removeItem("current-organisation");
    // history.replace("/dashboard");
    localStorage.removeItem("admin-page");
    // window.location.reload();

    yield put(clearSelectedVenueRequest());
    yield put(clearEntityRequest());
    // yield put(push("/org-dashboard"));
    yield put(push("/dashboard"));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(exitOrganisationFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getSelectedOrganisationLicensesWorker(action) {
  try {
    const apiResult = yield call(apiGetLicenses, action.payload);

    yield put(getSelectedOrganisationLicensesSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getSelectedOrganisationLicensesFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getSelectedOrganisationVenuesWorker(action) {
  try {
    const state = yield select();

    const apiResult = yield call(apiGetOrganisationVenues, action.payload);

    let formattedVenuesList = yield [];

    const parentOrganisation = yield state["SELECTED_ORGANISATION"][
        "selectedOrganisation"
        ]["selectedOrganisation"];

    parentOrganisation.isVenue = yield false;

    if (apiResult.data?.content?.length > 0) {
      formattedVenuesList = yield apiResult.data.content.map((venue) => {
        const updatedVenue = venue;
        updatedVenue.isVenue = true;

        return updatedVenue;
      });

      // If more than one venue, include parent org in list
      if (formattedVenuesList.length > 1) {
        yield formattedVenuesList.unshift(parentOrganisation);
      }
    }

    yield put(getSelectedOrganisationVenuesSuccess(formattedVenuesList));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getSelectedOrganisationVenuesFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getSelectedOrganisationSummaryWorker(action) {
  try {
    const apiResult = yield call(apiGetSummary, action.payload);

    yield put(getSelectedOrganisationSummarySuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getSelectedOrganisationSummaryFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getSelectedOrganisationCampaignsWorker(action) {
  try {
    const apiResult = yield call(apiGetCampaigns, action.payload);

    yield put(getSelectedOrganisationCampaignsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getSelectedOrganisationCampaignsFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

//SELECTED VENUE SAGAS

function* selectVenueWorker(action) {
  try {
    yield put(selectVenueSuccess(action.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(selectVenueFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getSelectedVenueSummaryWorker(action) {
  try {
    const apiResult = yield call(apiGetSummary, action.payload);

    yield put(getSelectedVenueSummarySuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getSelectedVenueSummaryFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getSelectedVenueCampaignsWorker(action) {
  try {
    const apiResult = yield call(apiGetCampaigns, action.payload);

    yield put(getSelectedVenueCampaignsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getSelectedVenueCampaignsFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

//SELECTED ENTITY SAGAS

function* manageSelectEntityWorker(action) {
  try {
    yield put(selectEntityRequest(action));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getSelectedVenueCampaignsFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* selectEntityWorker(action) {
  try {
    yield put(selectEntitySuccess(action?.payload?.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(selectEntityFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getSelectedEntitySummaryWorker(action) {
  try {
    const apiResult = yield call(apiGetSummary, action.payload);

    yield put(getSelectedEntitySummarySuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getSelectedEntitySummaryFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

export default function* watcher() {
  yield takeLatest(SELECT_ORGANISATION_REQUEST, selectOrganisationWorker);

  yield takeLatest(EXIT_ORGANISATION_REQUEST, exitOrganisationWorker);

  yield takeLatest(
      GET_SELECTED_ORGANISATION_LICENSES_REQUEST,
      getSelectedOrganisationLicensesWorker
  );

  yield takeLatest(
      GET_SELECTED_ORGANISATION_VENUES_REQUEST,
      getSelectedOrganisationVenuesWorker
  );

  yield takeLatest(
      GET_SELECTED_ORGANISATION_SUMMARY_REQUEST,
      getSelectedOrganisationSummaryWorker
  );

  yield takeLatest(
      GET_SELECTED_ORGANISATION_CAMPAIGNS_REQUEST,
      getSelectedOrganisationCampaignsWorker
  );

  yield takeLatest(SELECT_VENUE_REQUEST, selectVenueWorker);

  yield takeLatest(
      GET_SELECTED_VENUE_SUMMARY_REQUEST,
      getSelectedVenueSummaryWorker
  );

  yield takeLatest(
      GET_SELECTED_VENUE_CAMPAIGNS_REQUEST,
      getSelectedVenueCampaignsWorker
  );

  yield takeLatest(
    [SELECT_ORGANISATION_SUCCESS, SELECT_VENUE_SUCCESS],
    manageSelectEntityWorker
  );

  yield takeLatest(SELECT_ENTITY_REQUEST, selectEntityWorker);

  yield takeLatest(
      GET_SELECTED_ENTITY_SUMMARY_REQUEST,
      getSelectedEntitySummaryWorker
  );

  // yield takeLatest(
  //   GET_SELECTED_ORGANISATION_CAMPAIGNS_REQUEST,
  //   getSelectedOrganisationCampaignsWorker
  // );
}
