import React from "react";
import styled from "@emotion/styled";
import type {
  EmailInput
} from "../../../../../../../../../containers/Connect/types";
import uuid from "react-uuid";

const RegistrationInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 34%;
  overflow-y: auto;

  input {
    outline: 0;
    background: transparent;
    border: 0.25px solid #898989;
    border-radius: 18px;
    height: 16px;
    padding: 5px 0px 5px 16px;
    font-family: Futura PT Book;
    margin-bottom: 5px;
    width: 90%;

    ::placeholder {
      color: #898989;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .register-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color:red; */
    padding: 10px 20px;
    border-radius: 30px;
    width: fit-content;
    align-self: center;
    margin: 10px;
    width: 100px;
    height: 18px;

    label {
      font-family: Futura PT Book;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      color: #ffffff;
      margin-right: 10px;
    }

    .arrow {
      height: 15px;
    }
  }

  .privacy {
    font-family: Futura PT Book;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #666666;
    margin-top: 5px;
    align-self: center;
  }
`;

const RegistrationInputs = (props) => {
  const {settings} = props;
  return (
      <RegistrationInputsWrapper>
        {settings &&
            settings?.emailInputs?.map((i: EmailInput) => {
              return (
                  <input
                      key={uuid()}
                      placeholder={i.text}
                      style={{
                        color: settings?.textcolor,
                        borderColor: settings?.textcolor,
                      }}
                  />
              );
            })}
      </RegistrationInputsWrapper>
  );
};

export default RegistrationInputs;
