import React from 'react'

import '../../../assets/css/sms/campaign/delete-campaign-popup.scss'

export default ({ name, cancel, remove }) => {

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            cancel()
        }
    }

    return (
        <div className='delete-campaign-popup' onClick={onClick}>
            <div className='delete-campaign-popup-content'>
                <div className='delete-campaign-top'>
                    <label>Delete Campaign</label>
                </div>
                <div className='delete-campaign-content'>
                    <label>Are you sure you want to delete <span>{name}</span>?</label>
                    <div className='delete-campaign-actions'>
                        <button onClick={cancel}>Cancel</button>
                        <button onClick={remove}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}