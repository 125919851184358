import styled from "@emotion/styled";

const EditBrandingWrapper = styled.div`
  .edit-landing-fade-in-true {
    animation: fade-in 1.2s;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  display: flex;
  flex-direction: column;

  .edit-landing-page-bottom-slider {
    width: 100%;
  }

  .edit-landing-page-top-slider {
    width: 48%;
  }

  .landing-page-edit-document-upload-container {
    display: flex;
    justify-content: space-evenly;
  }

  .landing-page-edit-document-upload {
    background: $grey-3;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    flex-basis: 47%;
    height: 250px;
    align-content: center;
    align-items: center;
    font-size: 10px;
  }

  .edit-landing-page-background-sliders {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    .percent-slider {
      margin-top: 30px;
    }
  }

  .edit-landing-page-background-slider {
    margin: auto;
  }

  .edit-landing-page-background-zoom {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 35px;
    width: 100%;
  }

  .landing-page-edit-document-upload-item {
    display: flex;
    flex-direction: column;
    flex-basis: 47%;
    height: 280px;

    label {
      margin-bottom: 30px;
    }
  }

  .landing-page-name-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    input {
      flex: 1;
      margin-left: 20px;
      margin-right: 10px;
      height: 15px;
      padding: 10px;
      border: 0.75px solid #dbdbdb;
      border-radius: 25px;
      background-color: #f5f5f5;
      color: #3c4858;
      outline: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    label {
      color: #3c4858;
      font-weight: 500;
    }

    .landing-page-text-input {
      width: 90px;
    }
  }

  .landing-page-edit-subtitle {
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    margin-top: 20px;
    color: #3c4858;
    margin-bottom: 30px;
  }

  .landing-page-edit-description {
    font-weight: 300;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: #3c4858;
  }

  .file-selector {
    margin-top: 10px;
    flex: 1;
    flex-basis: 45%;
  }

  .file-upload-container {
    display: flex;
  }

  .edit-landing-page-menu-line {
    margin-top: 15px;
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    background-color: #eeeeee;
    height: 1px;
  }

  .landing-page-edit-social-switch-container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .switch {
      margin-right: 65px;
    }
  }

  .color-picker {
    margin-top: 10px;
  }

  .text-switch {
    margin-top: 10px;
  }

  .bg-color-switch {
    margin-top: 35px;
    margin-left: 75px;

    label {
      color: #3c4858;
      font-weight: 500;
    }
    
    .disabled {
      opacity: 50%;
      pointer-events: none;
    }
  }
  
  .bg-color-switch-disabled {
    opacity: 50%;
    pointer-events: none;
  }

  .edit-landing-page-send {
    input {
      width: 160px;
      height: 15px;
      padding: 10px;
      border: 0.75px solid #dbdbdb;
      border-radius: 25px;
      background-color: #f5f5f5;
      color: #3c4858;
      outline: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    button {
      min-width: 90px;
      border-radius: 25px;
      color: white;
      align-self: center;
      background-color: $accent-color;
      border: none;
      padding: 10px;
      outline: 0;
      margin: 20px;
    }
  }
`;


export default EditBrandingWrapper