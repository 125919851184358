import React from 'react';
import {
  Backdrop,
  Fade,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '25px',
    zIndex: 1,
  },
}));

export default function SaveSegment() {

  const [value, setValue] = React.useState('female');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <div>

        <label onClick={handleOpen}>Save Segment</label>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={open}>
            <div className={classes.paper}>

              <h2 id="simple-modal-title">Save Segment</h2>

              <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value}
                            onChange={handleChange}>
                  <FormControlLabel value="female" control={<Radio/>}
                                    label="Overwrite Existing Segment 'Segment Name'"/>
                  <FormControlLabel value="male" control={<Radio/>}
                                    label="Add a New Segment"/>
                </RadioGroup>
              </FormControl>

              <input type="text" className={'inputSaveSegment'}/>
              <div className={'buttons'}>
                <button className={'yesDelete'} onClick={handleClose}>
                  Save
                </button>
              </div>

            </div>
          </Fade>
        </Modal>
      </div>
  );
}