import actions from "../actions";

const {
  ADD_FILTER_REQUEST,
  ADD_FILTER_FAILURE,
  ADD_FILTER_SUCCESS,
  REMOVE_FILTER_REQUEST,
  REMOVE_FILTER_FAILURE,
  REMOVE_FILTER_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  currentFilters: [],
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FILTER_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case ADD_FILTER_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case ADD_FILTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentFilters: [...state.currentFilters, action.payload],
      };
    }
      ///---

    case REMOVE_FILTER_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case REMOVE_FILTER_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case REMOVE_FILTER_SUCCESS: {
      // if there is no given index, remove all filters
      const filters = action.payload != null
          ? state.currentFilters.filter(
              (item, index) => action.payload !== index)
          : [];
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentFilters: filters
      };
    }

    default:
      return state;
  }
}
