import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import {isValidEmail} from 'utils/common'
import 'assets/css/forgot-password.scss'
import axios from "axios";
import {from} from "rxjs";

const ForgotPassword = () => {

  const history = useHistory()
  const [email, setEmail] = useState<string>("");
  const [response, setResponse] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    setDisabled(!email || email.length < 3 || !isValidEmail(email));
  }, [email]);

  const reset = async () => {
    from(axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/user/password/reset`,
        {email: email}))
    .subscribe({
      next: () => {
        setResponse("Password email sent");
        resetResponse();
      },
      error: (err) => {
        console.error("Error resetting password");
        console.error(err);
        setResponse("Error resetting password. Please contact support");
        resetResponse();
      }
    })
  }

  const resetResponse = () => setTimeout(() => {
    setResponse("");
    history.goBack();
  }, 3000);

  return (
      <div className='forgot-password-page'>
        <div className='forgot-password'>
          <div className='forgot-password-top'>
            <label>Forgot Password</label>
          </div>
          <hr/>
          <div className='forgot-password-input'>
            <label>Email</label>
            <input
                placeholder='Please enter your email'
                type='text'
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
          </div>
          <hr/>
          <div className='forgot-password-actions'>
            <button
                disabled={disabled}
                className={disabled ? 'disabled' : ''}
                onClick={reset}
            >
              Reset
            </button>
            <button
                onClick={() => history.goBack()}
                className='back-to-login-btn'
            >
              Back to login
            </button>
          </div>
          {response &&
              <div className='forgot-password-response'>
                <label>{response}</label>
              </div>
          }
        </div>
      </div>
  )
}

export default ForgotPassword