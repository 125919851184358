import React, {useState, useEffect, useRef} from "react";
import {randomColor, randomId} from "@mui/x-data-grid-generator";
import ContactListAvatar from "../../../../Avatar/ContactListAvatar";
import ContactListChip from "components/Chips/ContactListChip";
import {PAGE_SIZE} from "../../../../../containers/ContactsList/api";
import CustomDataGrid from "../../../../UI/Customer/ContactList";
import Divider from "@mui/material/Divider";
import _ from "lodash";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import uuid from "react-uuid";

const columns = [
  {
    field: "id",
    generateData: () => randomId,
    hide: true,
  },
  {
    field: "phones",
    hide: true,
  },
  {
    disableExport: true,
    field: "fullName",
    filterable: false,
    headerName: "Name",
    width: 300,
    flex: 1,
    renderCell: (params) => {
      return <ContactListAvatar name={params.row.fullName}/>;
    },
    generateData: () => randomColor,
    sortable: false,
  },
  {
    field: "noOfVisits",
    headerName: "Visits",
    type: "number",
    width: 250,
    editable: false,
    headerAlign: "Left",
    align: "left",
    flex: 1,
  },
  {
    field: "lastVisit",
    headerName: "Last Visit",
    type: "string",
    width: 250,
    editable: false,
    headerAlign: "Left",
    align: "left",
    flex: 1,
  },
  {
    field: "allowEmail",
    headerName: "Email Opt-In",
    width: 150,
    editable: false,
    headerAlign: "Left",
    align: "left",
    type: "singleSelect",
    selectable: false,
    flex: 1,
    renderCell: (params) => {
      return (
          <div>
            {params.row.allowEmail ? (
                <ContactListChip
                    label={"Yes"}
                    color={"success"}
                    style={{
                      backgroundColor: "#1DB250",
                      justifyContent: "right"
                    }}
                />
            ) : (
                <ContactListChip label={"No"} color={"default"}/>
            )}
          </div>
      );
    },
  },
];

const TableBody = (props) => {
  const {
    contactsList,
    currentListType,
    currentPagination,
    setPage,
    currentCampaign,
    toggleContactRequest,
    toggleAudienceContactRequest,
    toggleSegmentContactRequest,
    setAllContactSelected,
    setSelectedContactsIds,
    allContactsIds,
    setSelectedCustomers,
    setIsAllContactsSelected,
    isAllContactsSelected,
    updateExternalContacts
  } = props;

  let contactsRows = [];
  let contactsData = [];
  let initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  };

  contactsData.columns = columns;
  contactsData.initialState = initialState;
  contactsData.rows = contactsRows;

  const dispatch = useDispatch();

  const {page, total} = currentPagination;
  const [rowCountState, setRowCountState] = useState(total || 0);
  const [selectedRows, setSelectedRows] = useState([]); // For future use, holds all the selected rows of segments/all
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]); // to retain selected rows on page change

  const prevSelectionModel = useRef(selectionModel);

  const loadServerRows = (page, contactsData) => {
    return new Promise((resolve) => {
      resolve(
          contactsData.rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
      );
    });
  };

  useEffect(() => {
    if (currentCampaign?.contacts) {
      //currentCampaign.contacts = selectionModel;
      updateExternalContacts(selectionModel);
    }

    const selectedIDs = new Set(selectionModel);
    const selectedRowsData = rows.filter((row) =>
        selectedIDs.has(row.id)
    );

    // Update Checkbox selection
    let updateContact;
    const filteredData = [...new Set(contactsData.rows)];
    filteredData.forEach((row) => {
      let isSelected = selectedIDs.has(row.id);
      if (isSelected) {
        const index = _.findIndex(contactsData.rows, {id: row.id});
        updateContact = contactsData.rows[index];
        updateContact.isChecked = true;
        updateContact.selected = true;
        contactsData.rows[index] = updateContact;
      }
    });

    // setSelectionModel(newSelectionModel);
    setSelectedContactsIds(selectionModel)
    setSelectedRows(selectedRowsData);

  }, [selectionModel]);

  const initializeContactList = () => {
    contactsData.columns = columns;
    contactsData.initialState = initialState;
    contactsData.rows = [];
    //setSelectedContactsIds([]);
  };

  const formatContactData = () => {
    contactsList &&
    Object.values(contactsList).forEach((contact) => {
      const {
        forename,
        surname,
      } = contact;

      if (contactsData.rows.includes(contact) === false) {
        contact.isChecked = false;
        contact.fullName = forename + " " + surname;
        if (contact.noOfVisits <= 0) {
          contact.noOfVisits = '-';
          contact.lastVisit = "-";
        }
        contactsData.rows = [...contactsData.rows, contact];
      }

      if (currentCampaign && currentCampaign.contacts) {
        currentCampaign.contacts = [...currentCampaign.contacts, contact];
      }
    });
  };

  useEffect(() => {
    setSelectedCustomers(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    if (
        (currentListType === "segments" || currentListType === "all") &&
        page === 0
    ) {
      initializeContactList();
      setRowCountState(0); // start pagination from the begining of the list
    }
  }, [currentPagination]);

  useEffect(() => {
    setRowCountState(total);
    formatContactData();
  }, []);

  useEffect(() => {
    let active = true;
    (async () => {
      setLoading(true);
      formatContactData();
      if (!active) {
        return;
      }
      setRows(contactsData.rows)
      setLoading(false);
      setRowCountState(total);
      setSelectionModel(prevSelectionModel.current);
      const selectedId = new Set(prevSelectionModel.current);
      const selectedRowsData = contactsData.rows.filter((row) =>
          selectedId.has(row.id)
      );
      setSelectedRows(selectedRowsData);
    })();

    return () => {
      active = false;
    };
  }, [contactsList]);

  const handleSelectAllCustomers = (event) => {
    const isChecked = event.target.checked;
    setIsAllContactsSelected(isChecked);

    let allContact = [];

    if (isChecked) {
      if (allContactsIds) {
        Object.entries(allContactsIds).forEach(([key]) => {
          allContact = [...allContact, key];
        });

        if (currentCampaign && currentCampaign.contacts) {
          currentCampaign.contacts = [...allContact];
        }

        setSelectionModel(allContact);
        setSelectedContactsIds(allContact)
      }
    } else {
      setSelectionModel([]);
      setSelectedRows([]);
      setSelectedContactsIds([])
    }
  };

  const rowSelectionChanged = async (newSelection) => {
    let id = newSelection.rowIds[0];
    await dispatch({
      type: "FAT_SELECT_EDIT",
      payload: rows.filter((i) => i.id === +id)[0],
    });
  };

  return (
      <div style={{
        width: "100%",
        height: 550,
        // height: "100%",
        overflowX: "hidden",
        marginTop: "120px"
      }}>
        <div style={{display: "flex", alignItems: "center", margin: 5}}>
          <Checkbox
              value="all"
              onChange={handleSelectAllCustomers}
              checked={isAllContactsSelected}
              sx={{
                "&.Mui-checked": {
                  color: "#ff9933",
                },
              }}
          />
          <span>
          {" "}
            <Typography
                variant="inherit"
                style={{
                  fontFamily: "'Trebuchet MS', sans-serif' !important",
                  color: "#FF9933",
                }}
            >
            Select All Customers
          </Typography>{" "}
        </span>
        </div>

        <Divider variant="middle" sx={{marginBottom: "20px"}}/>
        <CustomDataGrid
            key={uuid}
            autoHeight
            rows={rows}
            getRowId={(row) => row.id}
            columns={contactsData.columns}
            pagination
            checkboxSelection
            disableSelectionOnClick
            pageSize={PAGE_SIZE}
            rowCount={rowCountState}
            paginationMode="server"
            onPageChange={(newPage) => {
              prevSelectionModel.current = selectionModel;
              setPage(newPage);
            }}

            onSelectionModelChange={(newSelectionModel) =>
                setSelectionModel(newSelectionModel)}

            selectionModel={selectionModel}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            onSelectionChange={rowSelectionChanged}
            localeText={{
              footerRowSelected: (count) =>
                  count !== 1
                      ? `${count} customers selected`
                      : `${count} customer selected`,
            }}
            loading={loading}
        />
      </div>
  );
};

export default TableBody;
