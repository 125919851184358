import {
  GET_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_FAILURE,
  GET_CAMPAIGNS_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  errorMessage: null,
  campaigns: null,
  currentPagination: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CAMPAIGNS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        campaigns: null,
      };
    }

    case GET_CAMPAIGNS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        campaigns: action.payload.content,
        currentPagination: {
          ...state.currentPagination,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.number,
        },
      };
    }

    default:
      return state;
  }
}
