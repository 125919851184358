export const GET_CAMPAIGNS_REQUEST = "GET_CAMPAIGNS_REQUEST";
export const GET_CAMPAIGNS_FAILURE = "GET_CAMPAIGNS_FAILURE";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";

// --- GET CAMPAIGNS

// Deprecated
export const getCampaignsRequest = (organisationId, page, searchString) => ({
  type: GET_CAMPAIGNS_REQUEST,
  payload: {
    organisationId,
    page,
    searchString,
  },
});

export const getCampaignsFailure = (errorMessage) => ({
  type: GET_CAMPAIGNS_FAILURE,
  payload: errorMessage,
});

export const getCampaignsSuccess = (campaigns) => ({
  type: GET_CAMPAIGNS_SUCCESS,
  payload: campaigns,
});
