export const REDUCER_NAME = "CONNECT";

export const defaultLandingPage =
    {
      // Step 1
      preferredSocialMedia: 'Default', // Should string instead of Symbol because JSON ignores Symbols
      textcolor: '#000000',
      greetingMessage: 'Hi',
      greetingName: 'First Name', // First Name, Full Name : dropdown, possible enum???
      welcomeText: 'Welcome back! Log into our WiFi using the button below',
      welcomeHeaderColor: '#000000',
      welcomeTextColor: '#000000',
      welcomeButtonsTextColor: '#ffffff',
      welcomeJoinColor: '#ea5454',
      welcomeCancelColor: '#ffcc33',
      logoExternalId: '',
      backgroundExternalId: '',

      //step 2
      loginMainTitle: 'Login to our guest WiFi through:',
      loginSubtitle: 'Or login through...',
      loginOptions: {
        Facebook: true,
        Email: true,
        Twitter: false,
        Linkedin: false,
      },

      // Step 3
      emailInputs: [
        {
          key: 'firstName',
          text: 'First Name',
          index: 0,
        },
        {
          key: 'lastName',
          text: 'Last Name',
          index: 1,
        },
        {
          key: 'email',
          text: 'Email',
          index: 2,
        }
      ],
      emailBackButtonColor: '#ffcc33',
      emailContinueButtonColor: '#ea5454',

      //step 4
      // loginFacebookLike: false,
      // loginFacebookUrl: null,
      // loginFacebookTitle: "Like us on Facebook!",

      //step 5
      redirectPage: {
        type: 'TEXT',
        backgroundColour: '#ffffff',
        titleText: 'You are now connected',
        titleTextColour: '#ffcc33',
        subTitleText: 'Continue to our website for the latest offers and deals!',
        subTitleTextColour: '#000000',
        buttonText: 'Visit Our Website',
        buttonTextColour: '#ffffff',
        buttonColour: '#a41c4a'
      },

      // QR
      // menuOpacity: 0,
      // boxColor: '#ffffff',
      // boxBorderVisibility: 'SHOW', // SHOW, HIDE
      // boxBorderColor: '#111111',
      // inputColor: '#8B8B8B',
      // linkColor: '#ffffff',
      // linkHoverColor: '#111111',
      // errorTextColor: '#ff0d23',
      // buttonColor: '#1269ff',
      // goButtonColor: '#000000',
      // waitIndicatorColor: '#111111',
    };

export const defaultPoster = {
  accentColor: "#F29739",
  backgroundColor: "#E73E1C",
  message:
      "We take pride in providing excellent customer service and would love to hear what you think and if we can improve on any of our services. Please let us know your opinions by clicking the link in the email you receive from us, or by leaving a Google or Trustpilot review.",
  name: "Poster",
};

export const mediaTypes = {
  logo: {
    type: "LOGO",
    folder: "logo",
  },
  background: {
    type: "BACKGROUND",
    folder: "bg",
  },
  welcome: {
    type: "WELCOME",
    folder: "wel",
  },
  custom: {
    type: "CUSTOM",
    folder: "custom",
  },
};

export const socialMediaOptions = [
  {value: 'Default', name: 'Default'},
  {value: 'Facebook', name: 'Prioritise Facebook logins'},
  {value: 'Email', name: 'Prioritise Email logins'},
  // {value: SocialMedia.Twitter.description, name: 'Prioritise Twitter logins'},
  // {value: SocialMedia.Linkedin.description, name: 'Prioritise Linkedin logins'},
];

export const greetingNameOptions = [
  {externalId: 0, name: 'First Name'},
  {externalId: 1, name: 'Full Name'},
];
