import React, {useRef, useState} from "react";
import FileDragAndDropWrapper from "./FileDragAndDropWrapper";
import documentIcon from "assets/images/documentIcon.svg";

const FileDragAndDrop = ({title, fileName, setFile}) => {
  // noinspection DuplicatedCode
  const inputFile = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const onDragTo = (e) => {
    e.preventDefault();
    setIsFocused(true);
  };

  const onDragExit = (e) => {
    e.preventDefault();
    setIsFocused(false);
  };

  const onFileDrop = (event) => {
    event.preventDefault();
    setFile(event.dataTransfer?.files?.item(0));
  };

  return (
      <FileDragAndDropWrapper
          onDragOver={(e) => onDragTo(e)}
          onDragLeave={(e) => onDragExit(e)}
          onDrop={(e) => onFileDrop(e)}
      >
        <label className="file-selector-title">{title}</label>
        <input
            type="file"
            ref={inputFile}
            onChange={(e) => setFile(e.target.files[0])}
        />
        <label
            className={`file-selector-drop-file-area  ${
                isFocused ? "file-selector-highlighted" : ""
            }`}
            onDrop={(e) => onFileDrop(e)}
            onClick={() => inputFile.current.click()}
        >
          {fileName && <img alt="file selector" className="file-selector-icon" src={documentIcon}/>}
          {fileName ? fileName : "Select a file or drag one here"}
        </label>
      </FileDragAndDropWrapper>
  );
};

export default FileDragAndDrop;
