import React from "react";
import styled from "@emotion/styled";

import { Info } from "phosphor-react";

const PreviewTextFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    color: red;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width:175px;

  .title {
    padding-left: 5px;
  }
`;

const TextInput = styled.input`
  margin: 30px 0 30px 30px;
  border: 1px solid #c4c4c4;
  border-radius: 21.75px;
  padding: 7px 14px;
  font-size: 12px;
  outline: none;
  width: 100%;
`;

const PreviewTextField = (props) => {
  const { input } = props;
  return (
    <PreviewTextFieldWrapper>
      <TitleWrapper>
        <Info size={18} color={"#5d6161"} />
        <h4 className="title">Preview Text</h4>
      </TitleWrapper>

      <TextInput
        {...input}
        type="text"
        placeholder="Type Preview text"
      ></TextInput>
    </PreviewTextFieldWrapper>
  );
};

export default PreviewTextField;
