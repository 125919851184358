import React from "react";
import styled from "@emotion/styled";

import Form from "./Form";
import Preview from "./Preview";

const SendPreviewWrapper = styled.div`
  height: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  padding: 20px;
  min-height: 50vh;
`;

const SendPreview = (props) => {
  const {
    activeLicenses,
    selectedCustomers
  } = props
  return (
    <SendPreviewWrapper>
      <Form 
        {...props}
        activeLicenses={activeLicenses}
        selectedCustomers={selectedCustomers}
      />
      <Preview {...props} />
    </SendPreviewWrapper>
  );
};

export default SendPreview;
