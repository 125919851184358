import React from "react";
import moment from "moment";
import styled from "@emotion/styled";

import CampaignsWidget from "./style.js";

const NoCampaignsMessageWrapper = styled.div`
  /* height: 100%; */
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Roboto Bold;
  font-size: 18px;
  color: #5b5b5b;
  text-align: center;
  /* min-height: ${(props) => props.vhPixels / 2}px; */
  /* max-height: ${(props) => props.vhPixels / 2}px; */
  /* min-height: ${(props) => props.vhPixels}px; */
  /* height:100%; */
`;

const Campaigns = (props) => {
  const {organisationCampaigns, vhPixels} = props;

  const [isHover, setIsHover] = React.useState(false);

  return (
      <CampaignsWidget
          vhPixels={vhPixels}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          isHover={isHover}
      >
        <div className="sdc-title">
          <label>
            Campaigns
            {organisationCampaigns?.length > 0 && (
                <span>{` (${organisationCampaigns?.length})`}</span>
            )}
          </label>
          <img alt="send campaigns" src={require("assets/images/send-campaigns-icon.svg").default}/>
        </div>

        <div className="sdc-header">
          <label>Campaign Title</label>
          <label>Send Date</label>
          <label>Send To</label>
        </div>

        <div className="sdc-campaigns">
          {organisationCampaigns && organisationCampaigns.length > 0 ? (
              organisationCampaigns.map((campaign) => {
                const sendDate = campaign.schedules ?
                    moment(
                        new Date(campaign.schedules[0].scheduleDate * 1000)
                    )
                    : null;
                return (
                    <div className="sdc-campaign" key={campaign.externalId}>
                      {/* <CheckBox className="sdc-campaign-checkbox" /> */}
                      <label>{campaign.name}</label>
                      <label>{sendDate != null ? sendDate.format("DD/MM/YY"): ''}</label>
                      <label>{campaign.sendTo ? campaign.sendTo : 0}</label>
                    </div>
                );
              })
          ) : (
              <NoCampaignsMessageWrapper>
                This organization has no Campaigns at the moment.
              </NoCampaignsMessageWrapper>
          )}
        </div>
      </CampaignsWidget>
  );
};

export default Campaigns;
