import React from "react";
import { ReactComponent as DwnloadIcon } from "assets/images/download-qr-icon.svg";
import QRCode from "qrcode.react";

import "assets/css/design/track/show-qr-popup.scss";

const ShowQrPopup = (props) => {
  const { landingPageId, close } = props;

  const onClick = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  const downloadQR = () => {
    const canvas = document.getElementById("qr");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="show-qr-popup" onClick={onClick}>
      <div className="show-qr-popup-content">
        <div className="left">
          <label>Your QR Code</label>
          <DwnloadIcon />
          <button onClick={downloadQR}>Download</button>
        </div>
        <div className="right">
          <QRCode
            id="qr"
            value={`https://${process.env.REACT_APP_TT_URL}/#/qr/register/${landingPageId}`}
            size={250}
          />
        </div>
      </div>
    </div>
  );
};

export default ShowQrPopup