import React from "react";
import {useHistory} from "react-router-dom";
import styled from "@emotion/styled";
import DefaultButton from "components/buttons/DefaultButton";

import PageTitle from "components/PageTitle";

const DashboardTopWrapper = styled.div`
  grid-row: 1/2;
  grid-column: 1/13;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding: 5px 0px;
  flex: 1;

  .dashboard-top-title {
    font-size: 36px;
    font-weight: 400;
    color: #a0a8b1;
  }
`;

const DashboardTop = (props) => {
  const history = useHistory();
  const { admin } = props;

  return (
    <DashboardTopWrapper>
      <PageTitle title={"Main Dashboard"} />
      {admin && (
        <DefaultButton
          onClick={() => history.push("/organisation")}
          backgroundColor="#EA5454"
          textcolor="white"
          size="medium"
          title="Add a new customer"
        />
      )}
    </DashboardTopWrapper>
  );
};

export default DashboardTop;
