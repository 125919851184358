import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  apiDeleteNetworkOrganisation,
  apiGetNetworkIndustries,
  apiGetNetworkOrganisations,
} from "./api";

import {
  DELETE_NETWORK_ORGANISATION_REQUEST,
  deleteNetworkOrganisationFailure,
  deleteNetworkOrganisationSuccess,
  GET_NETWORK_INDUSTRIES_REQUEST,
  GET_NETWORK_ORGANISATIONS_REQUEST,
  getNetworkIndustriesFailure,
  getNetworkIndustriesSuccess,
  getNetworkOrganisationsFailure,
  getNetworkOrganisationsRequest,
  getNetworkOrganisationsSuccess,
} from "./actions";

import { ampli } from '../Amplitude/ampli';

function* getNetworkOrganisationsWorker(action) {
  try {
    const apiResult = yield call(apiGetNetworkOrganisations, action.payload);

    yield put(getNetworkOrganisationsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getNetworkOrganisationsFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* getNetworkIndustriesWorker(action) {
  try {
    const apiResult = yield call(apiGetNetworkIndustries);

    yield put(getNetworkIndustriesSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(getNetworkIndustriesFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* deleteNetworkOrganisationWatcher(action) {
  try {
    const apiResult = yield call(apiDeleteNetworkOrganisation, action.payload);

    yield put(deleteNetworkOrganisationSuccess(apiResult));

    let userId = yield select(
      (state) => state["USER"].userOrganisation.externalId
    );

    yield delay(500);

    yield put(getNetworkOrganisationsRequest(userId, 0, ""));

    ampli.unaroPageVisitEventWithProperties({
      email: apiResult.data.email !== undefined ? apiResult.data.email : '',
      organisationName: apiResult.data.organisations[0] !== undefined ? apiResult.data.organisations[0].name : '',
      eventType: "page visit: Network",
    })
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(deleteNetworkOrganisationFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

export default function* watcher() {
  yield takeEvery(
    GET_NETWORK_ORGANISATIONS_REQUEST,
    getNetworkOrganisationsWorker
  );
  yield takeLatest(GET_NETWORK_INDUSTRIES_REQUEST, getNetworkIndustriesWorker);
  yield takeLatest(
    DELETE_NETWORK_ORGANISATION_REQUEST,
    deleteNetworkOrganisationWatcher
  );
}