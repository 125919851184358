import styled from "@emotion/styled";

const CardStatsWrapper = styled.div`
  background-color: white;
  border-radius: 10px;

  user-select: none;
  /* padding-bottom: 5px; */
  min-height: 22%;

  @media (max-width: 1200px) {
    min-height: 20%;
  }

  .statistics-card-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo {
      position: relative;
      top: -10px;
      left: 10px;
      display: flex;
      background: rgb(190, 76, 76);
      background: linear-gradient(
        118deg,
        rgba(190, 76, 76, 1) 0%,
        rgba(234, 84, 84, 1) 32%,
        rgba(241, 100, 100, 1) 50%,
        rgba(234, 84, 84, 1) 69%,
        rgba(210, 74, 74, 1) 100%
      );
      height: 55px;
      width: 55px;
      border-radius: 0 0 0 30px;
      justify-content: center;
      align-items: center;

      @media (max-width: 1200px) {
        top: -5px;
        left: 5px;
        height: 45px;
        width: 45px;
      }

      img {
        height: 26px;
        width: 26px;
        align-self: center;

        @media (max-width: 1200px) {
          height: 18px;
          width: 18px;
        }
      }
    }

    .card-numbers {
      display: flex;
      flex-direction: column;
      text-align: end;
      margin: 5% 5% 0 0;

      .card-title {
        color: #999999;
        font-size: 14px;
        font-weight: 300;

        @media (max-width: 1200px) {
          font-size: 12px;
        }
      }

      .card-number {
        margin-top: 5px;
        font-size: 22px;
        font-weight: 400;
        color: #3c4858;

        @media (max-width: 1200px) {
          font-size: 24px;
        }
      }
    }
  }

  hr {
    border: 0;
    clear: both;
    display: block;
    width: 90%;
    background-color: #eeeeee;
    height: 1px;
    opacity: 0.75;
    margin-top: 10px;
  }

  .statistics-card-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0 5%;

    @media (max-width: 1200px) {
      transform: translate(0, -30%);
    }

    img {
      width: 14px;
      height: 14px;

      @media (max-width: 1200px) {
        width: 12px;
        height: 12px;
      }
    }

    label {
      margin: 0 0 0 5%;
      color: #999999;
      font-size: 12px;
      font-weight: 300;
      align-self: center;

      @media (max-width: 1200px) {
        font-size: 10px;
      }
    }
  }

  /* .stats-card-columns {
    margin-bottom: 20px;

    hr {
      margin-top: -12px;
    }
  } */

  @media only screen and (max-width: 1200px) {
    .stats-card {
      flex: 1;
      margin-right: 15px;

      .card-numbers {
        .card-title {
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    .stats-card {
      flex: 1;
      margin-right: 15px;

      .card-numbers {
        .card-title {
          width: auto;
        }
      }
    }
  }
`;

export default CardStatsWrapper;
