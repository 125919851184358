import React, {useState, useContext, useEffect} from 'react'
import AddIntegrationsItem from './addIntegrationsItem'
import AddIntegrationPopup from './addIntegrationPopup'
import {VenuesContext} from '../../context/venueContext'
import 'assets/css/integrations/add-integrations.scss'
import api from 'utils/api'
import {from} from "rxjs";

const integrationList = [
  {
    id: 0,
    name: 'Mailchimp',
    icon: require('assets/images/mailchimp.png'),
    disabled: false,
    connector: 'MAILCHIMP',
    unsetVenues: []
  },
  {
    id: 1,
    name: 'Unifi',
    icon: require('assets/images/unifi-logo.png'),
    disabled: false,
    connector: 'UNIFI',
    unsetVenues: []
  }
];

const AddIntegrations = ({integrations, setIntegrations}) => {

  const [data, setData] = useState(integrationList);

  // @ts-ignore
  const {selected} = useContext(VenuesContext)
  const [showAddPopup, setShowAddPopup] = useState<any>()
  const [organisations, setOrganisations] = useState([])

  const getVenues = (organisations, integrations, connector) => {
    const organisationsWithIntegration = integrations
    .filter(integration => integration.connector === connector)
    .map(integration => integration.organisationId);
    return organisations.filter(organisation =>
        !organisationsWithIntegration.includes(organisation.externalId));
  }

  useEffect(() => {
    selected && fetchOrganisations();
  }, [selected, integrations]);

  const fetchOrganisations = () => {
    const url = `/organisations/${selected.externalId}/network?relationship=VENUE&page=0size=20`;
    from(api.get(url))
    .subscribe({
      next: (result) => {
        let venues = result?.data?.content ? result.data.content : [];
        if (venues.length === 0) {
          //venues = [selected, ...result.data.content];
          venues = [selected];
        }
        setOrganisations(venues)
      },
      error: (err) => {
        console.error("Error getting organisations");
        console.error(err);
      }
    })
  }

  useEffect(() => {
    setData(data.map(d => ({
      ...d,
      unsetVenues: getVenues(organisations, integrations, d.connector)
    })))
  }, [organisations, integrations])

  const addIntegration = (integration) => {
    const newIntegrations = [...integrations, integration]
    setIntegrations(newIntegrations)
    const venues = getVenues(organisations, newIntegrations, integration.connector)
    if (venues.length === 0) {
      setShowAddPopup(null)
    } else {
      setShowAddPopup({
        ...showAddPopup,
        unsetVenues: showAddPopup.unsetVenues.filter(
            venue => venue.externalId !== integration.organisationId)
      })
    }
  }

  return (
      <div className='add-integrations'>
        {data.map(d =>
            <AddIntegrationsItem
                {...d}
                onClick={() => setShowAddPopup(d)}
                key={d.id}
                allSet={d.unsetVenues.length === 0}
            />)}
        {showAddPopup &&
            <AddIntegrationPopup
                close={() => setShowAddPopup(null)}
                //integrations={integrations}
                addIntegration={addIntegration}
                data={showAddPopup}
            />}
      </div>
  )
}
export default AddIntegrations;