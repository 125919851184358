import styled from "@emotion/styled";

const DemographicsWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  border-radius: 10px;
  user-select: none;

  grid-column: 7/9;

  .demographics-top {
    display: flex;
    flex-direction: row;

    .demographics-logo {
      position: relative;
      top: -10px;
      left: 10px;
      display: flex;
      background-color: #111111;
      background: rgb(224, 126, 33);
      background: linear-gradient(
        118deg,
        rgba(224, 126, 33, 1) 0%,
        rgba(242, 128, 19, 1) 32%,
        rgba(255, 152, 53, 1) 50%,
        rgba(242, 128, 19, 1) 69%,
        rgba(224, 126, 33, 1) 100%
      );
      height: 60px;
      width: 60px;
      border-radius: 0 0 0 30px;
      justify-content: center;
      align-items: center;

      img {
        height: 25px;
        width: 25px;
        align-self: center;
      }
    }

    label {
      margin-top: 20px;
      margin-left: 40px;
    }
  }

  hr {
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    background-color: #eeeeee;
    height: 1px;
  }
  .demographics-stats-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;

    .demographics-stats {
      display: flex;
      flex-direction: row;
      align-items: center;
      /* margin-bottom: 20px; */

      .demographics-icon {
        height: 80px;
      }

      label {
        font-size: 30px;
        font-weight: 500;
      }

      .male-percent {
        color: #ffcc33;
      }

      .female-percent {
        color: #ea5454;
      }
    }
  }
`;

export default DemographicsWidgetWrapper;
