import React from "react";

import styled from "@emotion/styled";

const NoCustomersWrapper = styled.div`
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  font-size:32px;
  opacity:.65;
`;

const NoCustomers = (props) => {
  return (
    <NoCustomersWrapper>
      <p>You have no customers yet</p>
    </NoCustomersWrapper>
  );
};

export default NoCustomers;
