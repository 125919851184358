import React, {useEffect, useState} from "react";

import api from "utils/api";
import {useHistory} from "react-router-dom";
import DeletePopup from "./deleteCampaignPopup";
import PageNavigator from "components/PageNavigator";
import {PageNavigationContext} from "context/navigationContext";
import Dropdown from "../contact/contactDropdown";
import {BackButton} from '../../../components/widget/backButton'

import {
  ReactComponent as ManageCampaignIcon
} from "assets/images/manage-campaigns-icon.svg";

import "assets/css/sms/campaign/manage-campaigns.scss";
import {from, map} from "rxjs";

const ManageCampaigns = ({organisationId}) => {
  const history = useHistory();

  const [campaigns, setCampaigns] = useState([]);
  const [deleteCampaign, setDeleteCampaign] = useState();
  const [name, setName] = useState("");
  const [navigation, setNavigation] = useState({total: 1, current: 0});
  const [selectedFilter, setSelectedFilter] = useState();

  const fetchCampaigns = (page) => {
    let url = `/sms/organisation/${organisationId}/campaign/paged?size=12&page=${page}&name=${name}`;
    if (selectedFilter) {
      url += `&sort=${
          selectedFilter === "Name"
              ? "name"
              : selectedFilter === "Date Created"
                  ? "createdOn"
                  : ""
      }`;
    }
    from(api.get(url))
    .pipe(map((response) => response.data))
    .subscribe((data) => {
      setCampaigns(data.content);
      setNavigation({
        total: Math.ceil(data.totalElements / 12),
        current: data?.pageable.pageNumber,
      });
    })
  };

  useEffect(() => {
    organisationId && fetchCampaigns(0);
  }, [organisationId, name, selectedFilter]);

  const removeCampaign = async () => {
    from(api.delete(`/sms/campaign/${deleteCampaign.externalId}`))
    .subscribe(() => {
      setCampaigns(
          campaigns.filter(
              (campaign) => campaign.externalId !== deleteCampaign.externalId
          )
      );
      setDeleteCampaign(null);
      fetchCampaigns(0);
    });
  };

  const changePage = (page) => {
    fetchCampaigns(page);
  };

  return (
      <div className="manage-campaigns">
        <BackButton />
        <div className="mc-top">
          <label>Manage SMS Campaigns</label>
          <div className="mc-actions">
            <input
                type="search"
                placeholder="Search campaigns..."
                value={name || ""}
                onChange={(e) => setName(e.target.value.toLowerCase())}
            />
            <button onClick={() => history.push("/sms/campaigns/create")}>
              Create a new campaign
            </button>
          </div>
        </div>
        <div className="mc-content">
          <div className="mc-content-top">
            <label className="left">
              <div className="mc-icon-container">
                <ManageCampaignIcon className="mc-icon"/>
              </div>
              <label>Manage SMS Campaigns</label>
            </label>
            <div className="right">
              <PageNavigationContext.Provider
                  value={{navigation, setNavigation}}
              >
                <PageNavigator changePage={(page) => changePage(page)}/>
              </PageNavigationContext.Provider>
              <Dropdown
                  className="filter-dd"
                  placeholder="Filter by"
                  selected={selectedFilter}
                  options={[
                    {externalId: 0, name: "Name"},
                    {externalId: 1, name: "Date Created"},
                    {externalId: 2, name: "Currently Live"},
                  ]}
                  onSelect={(option) => setSelectedFilter(option.name)}
              />
            </div>
          </div>
          <div className="campaigns">
            {campaigns.map((campaign) => (
                <div className="mc-campaign">
                  <label>{campaign.name}</label>
                  <div className="actions">
                    <button
                        onClick={() =>
                            history.push(
                                `/sms/campaigns/${campaign.externalId}`)
                        }
                    >
                      Edit
                    </button>
                    <button onClick={() => setDeleteCampaign(campaign)}>
                      Delete
                    </button>
                  </div>
                </div>
            ))}
          </div>
        </div>
        {deleteCampaign && (
            <DeletePopup
                name={deleteCampaign.name}
                cancel={() => setDeleteCampaign(null)}
                remove={removeCampaign}
            />
        )}
      </div>
  );
};

export default ManageCampaigns;