// export const GET_SELECTED_ORGANISATION_REQUEST =
//   "GET_SELECTED_ORGANISATION_REQUEST";
// export const GET_SELECTED_ORGANISATION_FAILURE =
//   "GET_SELECTED_ORGANISATION_FAILURE";
// export const GET_SELECTED_ORGANISATION_SUCCESS =
//   "GET_SELECTED_ORGANISATION_SUCCESS";

export const SELECT_ORGANISATION_REQUEST = "SELECT_ORGANISATION_REQUEST";
export const SELECT_ORGANISATION_FAILURE = "SELECT_ORGANISATION_FAILURE";
export const SELECT_ORGANISATION_SUCCESS = "SELECT_ORGANISATION_SUCCESS";

export const EXIT_ORGANISATION_REQUEST = "EXIT_ORGANISATION_REQUEST";
export const EXIT_ORGANISATION_FAILURE = "EXIT_ORGANISATION_FAILURE";
export const EXIT_ORGANISATION_SUCCESS = "EXIT_ORGANISATION_SUCCESS";

export const GET_SELECTED_ORGANISATION_LICENSES_REQUEST =
  "GET_SELECTED_ORGANISATION_LICENSES_REQUEST";
export const GET_SELECTED_ORGANISATION_LICENSES_FAILURE =
  "GET_SELECTED_ORGANISATION_LICENSES_FAILURE";
export const GET_SELECTED_ORGANISATION_LICENSES_SUCCESS =
  "GET_SELECTED_ORGANISATION_LICENSES_SUCCESS";

export const GET_SELECTED_ORGANISATION_VENUES_REQUEST =
  "GET_SELECTED_ORGANISATION_VENUES_REQUEST";
export const GET_SELECTED_ORGANISATION_VENUES_FAILURE =
  "GET_SELECTED_ORGANISATION_VENUES_FAILURE";
export const GET_SELECTED_ORGANISATION_VENUES_SUCCESS =
  "GET_SELECTED_ORGANISATION_VENUES_SUCCESS";

export const GET_SELECTED_ORGANISATION_SUMMARY_REQUEST =
  "GET_SELECTED_ORGANISATION_SUMMARY_REQUEST";
export const GET_SELECTED_ORGANISATION_SUMMARY_FAILURE =
  "GET_SELECTED_ORGANISATION_SUMMARY_FAILURE";
export const GET_SELECTED_ORGANISATION_SUMMARY_SUCCESS =
  "GET_SELECTED_ORGANISATION_SUMMARY_SUCCESS";

export const GET_SELECTED_ORGANISATION_CAMPAIGNS_REQUEST =
  "GET_SELECTED_ORGANISATION_CAMPAIGNS_REQUEST";
export const GET_SELECTED_ORGANISATION_CAMPAIGNS_FAILURE =
  "GET_SELECTED_ORGANISATION_CAMPAIGNS_FAILURE";
export const GET_SELECTED_ORGANISATION_CAMPAIGNS_SUCCESS =
  "GET_SELECTED_ORGANISATION_CAMPAIGNS_SUCCESS";

// --- SELECT_ORGANISATION

export const selectOrganisationRequest = (selectedOrganisation) => ({
  type: SELECT_ORGANISATION_REQUEST,
  payload: selectedOrganisation,
});

export const selectOrganisationFailure = (errorMessage) => ({
  type: SELECT_ORGANISATION_FAILURE,
  payload: errorMessage,
});

export const selectOrganisationSuccess = (selectedOrganisation) => ({
  type: SELECT_ORGANISATION_SUCCESS,
  payload: selectedOrganisation,
});

// --- EXIT_ORGANISATION

export const exitOrganisationRequest = () => ({
  type: EXIT_ORGANISATION_REQUEST,
});

export const exitOrganisationFailure = (errorMessage) => ({
  type: EXIT_ORGANISATION_FAILURE,
  payload: errorMessage,
});

export const exitOrganisationSuccess = () => ({
  type: EXIT_ORGANISATION_SUCCESS,
});

// --- GET SELECT_ORGANISATION LICENSES

export const getSelectedOrganisationLicensesRequest = (organisationId) => ({
  type: GET_SELECTED_ORGANISATION_LICENSES_REQUEST,
  payload: organisationId,
});

export const getSelectedOrganisationLicensesFailure = (errorMessage) => ({
  type: GET_SELECTED_ORGANISATION_LICENSES_FAILURE,
  payload: errorMessage,
});

export const getSelectedOrganisationLicensesSuccess = (
  organisationLicenses
) => ({
  type: GET_SELECTED_ORGANISATION_LICENSES_SUCCESS,
  payload: organisationLicenses,
});

// --- GET SELECT_ORGANISATION VENUES

export const getSelectedOrganisationVenuesRequest = (organisationId) => ({
  type: GET_SELECTED_ORGANISATION_VENUES_REQUEST,
  payload: organisationId,
});

export const getSelectedOrganisationVenuesFailure = (errorMessage) => ({
  type: GET_SELECTED_ORGANISATION_VENUES_FAILURE,
  payload: errorMessage,
});

export const getSelectedOrganisationVenuesSuccess = (organisationVenues) => ({
  type: GET_SELECTED_ORGANISATION_VENUES_SUCCESS,
  payload: organisationVenues,
});

// --- GET SELECT_ORGANISATION SUMMARY

export const getSelectedOrganisationSummaryRequest = (organisationId) => ({
  type: GET_SELECTED_ORGANISATION_SUMMARY_REQUEST,
  payload: organisationId,
});

export const getSelectedOrganisationSummaryFailure = (errorMessage) => ({
  type: GET_SELECTED_ORGANISATION_SUMMARY_FAILURE,
  payload: errorMessage,
});

export const getSelectedOrganisationSummarySuccess = (organisationSummary) => ({
  type: GET_SELECTED_ORGANISATION_SUMMARY_SUCCESS,
  payload: organisationSummary,
});

// --- GET SELECT_ORGANISATION CAMPAIGNS

export const getSelectedOrganisationCampaignsRequest = (organisationId) => ({
  type: GET_SELECTED_ORGANISATION_CAMPAIGNS_REQUEST,
  payload: organisationId,
});

export const getSelectedOrganisationCampaignsFailure = (errorMessage) => ({
  type: GET_SELECTED_ORGANISATION_CAMPAIGNS_FAILURE,
  payload: errorMessage,
});

export const getSelectedOrganisationCampaignsSuccess = (
  organisationCampaigns
) => ({
  type: GET_SELECTED_ORGANISATION_CAMPAIGNS_SUCCESS,
  payload: organisationCampaigns,
});

// --- GET_ORGANISATION

// export const getSelectedOrganisationRequest = (selectedOrganisation) => ({
//   type: GET_SELECTED_ORGANISATION_REQUEST,
//   payload: selectedOrganisation,
// });

// export const getSelectedOrganisationFailure = (errorMessage) => ({
//   type: GET_SELECTED_ORGANISATION_FAILURE,
//   payload: errorMessage,
// });

// export const getSelectedOrganisationSuccess = (selectedOrganisation) => ({
//   type: GET_SELECTED_ORGANISATION_SUCCESS,
//   payload: selectedOrganisation,
// });
