import React, {useEffect, useState} from "react";
import {
  ReactComponent as AddLandingIcon
} from "assets/images/add-landing-icon.svg";
import DeletePopup from "./DeletePopup";
import WifiLandingPagesTop from "./WifiLandingPagesTop";
import LandingPageItem from "./LandingPageItem";
import Popup from "../components/Popup";
import uuid from "react-uuid";
import "assets/css/design/landing-pages.scss";

const ITEMS_PER_PAGE = 5;

interface LandingPage {
  externalId: string,
  name: string,
  active: boolean
}

const WifiLandingPages = (props) => {
  const {
    organisationId,
    setPopupType,
    type,
    startLandingPageRequest,
    deleteLandingPageRequest,
    getLandingPagesRequest,
    landingPages,
    activateLandingPageRequest,
    deletedLandingPage,
    disableCreate,
  } = props;

  const [showCreateNewPopup, setShowCreateNewPopup] = useState(false);
  const [landingPageToBeDeleted, setLandingPageToBeDeleted] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSelected, setPageSelected] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [page: LandingPage[], setPage] = useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getLandingPagesRequest(organisationId, type);
  }, [organisationId]);

  useEffect(() => {
    setDisabled(disableCreate);
  }, [disableCreate]);

  useEffect(() => {
    deletedLandingPage && getLandingPagesRequest(organisationId, type);
  }, [deletedLandingPage]);

  useEffect(() => {
    if (landingPages && landingPages.length > 0) {
      const pages = landingPages?.filter(
          (landing) => {
            return (
                !searchTerm ||
                (landing.name &&
                    landing.name.toLowerCase().includes(
                        searchTerm.toLowerCase()))
            );
          });

      setTotalPages(Math.ceil(pages.length / ITEMS_PER_PAGE));

      const indexOfLast = pageSelected * ITEMS_PER_PAGE;
      const indexOfFirst = indexOfLast - ITEMS_PER_PAGE;

      setPage(pages.slice(indexOfFirst, indexOfLast));
    } else {
      setPage([]);
      setTotalPages(0);
    }
  }, [landingPages, pageSelected, searchTerm]);

  const toggleActive = (landingPageId) =>
      activateLandingPageRequest(organisationId, landingPageId, type);

  return (
      <div>
        <WifiLandingPagesTop
            totalPages={totalPages}
            setPageSelected={setPageSelected}
            pageSelected={pageSelected}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
        />
        <div className="landing-pages">
          <div className="landing-pages-flex-container">
            <div
                style={disabled ? {
                  pointerEvents: 'none',
                  opacity: 0.4
                } : {}}
                className="landing-page-add-item"
                onClick={() => setPopupType("wifi")}
                tabIndex="0"
            >
              <div className="add-card-content">
                <div className="add-card-content-image">
                  <AddLandingIcon/>
                </div>
                <label>Create New WiFi Login page</label>
              </div>
            </div>
            {page && page.map((landingPage) => (
                <LandingPageItem
                    key={uuid()}
                    landingPage={landingPage}
                    type={type}
                    toggleActive={toggleActive}
                    onDelete={(landingPage) =>
                        setLandingPageToBeDeleted(landingPage)
                    }
                />
            ))}
          </div>
          {landingPageToBeDeleted && (
              <DeletePopup
                  onCancel={() => setLandingPageToBeDeleted(false)}
                  deleteLandingPageRequest={deleteLandingPageRequest}
                  landingPageToBeDeleted={landingPageToBeDeleted}
              />
          )}
        </div>
        {showCreateNewPopup && (
            <Popup
                setPopupType={setPopupType}
                startLandingPageRequest={startLandingPageRequest}
                organisationId={organisationId}
                type={type}
            />
        )}
      </div>
  );
};

export default WifiLandingPages;
