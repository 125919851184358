import React, { useState } from "react";
import "assets/css/main.scss";
import { Route } from "react-router-dom";
import UserSettings from "../../UserSettings";
import { GettingStarted } from "../../gettingStarted";
import Organisation from "../../Organisation";
import { InviteUser } from "../../inviteUser";
import Integrations from "../../integrations";
import { Account } from "../../account";
import Venues from "../../venues";
import Billing from "../../Billing";
import Suspend from "../../suspend";
import { InfoPopup } from "../../../components/widget/infoPopup";
// import Customers from "pages/Customers";
import Customers from "../../../pages/Send/Customers";
import SendDashboard from "../../Send/Dashboard";
import SendNewEmail from "../../Send/SendNewEmail";
import SendCustomers from "../../Send/Customers";
import SendManageCampaigns from "../../Send/ManageCampaigns";
import SendSettings from "../../Send/Settings";
import ConnectDashboard from "../../Connect/Dashboard";
import ConnectLandingPage from "../../Connect/LandingPage";
import Poster from "../../../components/poster/poster";
import TrackAndTrace from "../../Connect/TrackAndTrace";
import SmsDashboard from "../../Sms/Dashboard";
import QuickSend from "../../Sms/QuickSend";
import SmsContacts from "../../Sms/Contacts";
import SmsReports from "../../Sms/Reports";
import SmsReport from "../../../components/sms/reports/report";
import CreateCampaign from "../../../components/sms/campaign/createCampaign";
import ManageCampaigns from "../../../components/sms/campaign/manageCampaigns";
import SmsSettings from "../../../components/sms/SmsSettings";

const MainUser = (props) => {
  const {
    // organisationId,
    selectedEntity,
    selectedOrganisation,
    selectedVenue,
    activeLicenses,
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  // const [editMode, setEditMode] = useState(false);

  return (
    <>
      {["/dashboard", "/org-dashboard", "/connect/dashboard"].map(
        (path) => {
          return (
            <Route path={path}>
              {/*<UserMainDashboard*/}
              {/*    {...props}*/}
              {/*    organisationId={organisationId}*/}
              {/*    edit={editMode}*/}
              {/*    setEdit={setEditMode}*/}
              {/*/>*/}
              <ConnectDashboard
                organisationId={selectedOrganisation?.externalId}
                selectedVenue={selectedVenue}
                selectedOrganisation={selectedOrganisation}
                {...props}
              />
            </Route>
          );
        }
      )}

      <Route path="/customers">
        <Customers
          organisationId={selectedOrganisation?.externalId}
          {...props}
        />
      </Route>

      {/*CONNECT*/}

      {/*<Route path="/connect/dashboard">*/}
      {/*  <ConnectDashboard*/}
      {/*      organisationId={selectedOrganisation?.externalId}*/}
      {/*      selectedVenue={selectedVenue}*/}
      {/*      selectedOrganisation={selectedOrganisation}*/}
      {/*      {...props}*/}
      {/*  />*/}
      {/*</Route>*/}

      <Route path="/connect/design">
        <ConnectLandingPage
          selectedOrganisation={selectedOrganisation}
          selectedEntity={selectedEntity}
          {...props}
        />
      </Route>

      <Route path="/connect/poster">
        <Poster />
      </Route>

      {activeLicenses.includes("TT") && (
        <Route path="/connect/track">
          <TrackAndTrace
            organisationId={selectedOrganisation?.externalId}
            selectedEntity={selectedEntity}
          />
        </Route>
      )}

      {/*SMS */}

      <Route path="/sms/quick-sms">
        <QuickSend />
      </Route>

      <Route path="/sms/contacts">
        <SmsContacts organisationId={selectedOrganisation?.externalId} />
      </Route>

      <Route path="/sms/campaigns/create">
        <CreateCampaign organisationId={selectedOrganisation?.externalId} />
      </Route>

      {activeLicenses.includes("SMS") && (
        <>
          <Route path="/sms/dashboard">
            <SmsDashboard />
          </Route>

          <Route path="/sms/settings">
            <SmsSettings organisationId={selectedOrganisation?.externalId}/>
          </Route>

          <Route path="/sms/reports">
            <SmsReports organisationId={selectedOrganisation?.externalId} />
          </Route>

          <Route path="/sms/reports/:campaignId">
            <SmsReport />
          </Route>

          <Route path="/sms/campaigns/manage">
            <ManageCampaigns
              organisationId={selectedOrganisation?.externalId}
            />
          </Route>

          <Route path="/sms/campaigns/create/:campaignId">
            <CreateCampaign organisationId={selectedOrganisation?.externalId} />
          </Route>
        </>
      )}

      {/*Send*/}

      {activeLicenses.includes("SEND") && (
        <>
          <Route path="/email/dashboard">
            <SendDashboard selectedEntity={selectedEntity} />
          </Route>

          <Route path="/email/new">
            <SendNewEmail
              activeLicenses={activeLicenses}
              selectedEntity={selectedEntity}
            />
          </Route>

          <Route path="/email/customers">
            <SendCustomers selectedEntity={selectedEntity} />
          </Route>

          <Route path="/email/campaigns">
            <SendManageCampaigns selectedEntity={selectedEntity} />
          </Route>

          <Route path="/send/settings">
            <SendSettings selectedEntity={selectedEntity} />
          </Route>
        </>
      )}

      {/*Global*/}

      <Route path="/settings">
        <UserSettings selectedEntity={selectedEntity} />
      </Route>
      <Route path="/getting-started">
        <GettingStarted />
      </Route>
      <Route path="/organisation">
        <Organisation {...props} />
      </Route>
      <Route path="/invite">
        <InviteUser setShowPopup={setShowPopup} />
      </Route>
      <Route path="/integrations">
        <Integrations organisationId={selectedOrganisation?.externalId} />
      </Route>
      <Route path="/account">
        <Account />
      </Route>
      <Route path="/venues">
        <Venues />
      </Route>
      <Route path="/billing">
        <Billing {...props} />
      </Route>
      <Route path="/suspended">
        <Suspend />
      </Route>

      {showPopup && (
        <InfoPopup text={showPopup} onOk={() => setShowPopup(false)} />
      )}
    </>
  );
};

export default MainUser;
