import actions from "../actions";

const {
  SELECT_VENUE_REQUEST,
  SELECT_VENUE_FAILURE,
  SELECT_VENUE_SUCCESS,
  CLEAR_SELECTED_VENUE_REQUEST,
  CLEAR_SELECTED_VENUE_FAILURE,
  CLEAR_SELECTED_VENUE_SUCCESS,
  GET_SELECTED_VENUE_SUMMARY_REQUEST,
  GET_SELECTED_VENUE_SUMMARY_FAILURE,
  GET_SELECTED_VENUE_SUMMARY_SUCCESS,
  GET_SELECTED_VENUE_CAMPAIGNS_REQUEST,
  GET_SELECTED_VENUE_CAMPAIGNS_FAILURE,
  GET_SELECTED_VENUE_CAMPAIGNS_SUCCESS,
} = actions;

const initialState = {
  loading: false,
  errorMessage: null,
  selectedVenue: null,
  selectedVenueSummary: null,
  selectedVenueCampaigns: null,
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_VENUE_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case SELECT_VENUE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case SELECT_VENUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedVenue: action.payload,
      };
    }

    ///---

    case GET_SELECTED_VENUE_SUMMARY_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_SELECTED_VENUE_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_SELECTED_VENUE_SUMMARY_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedVenueSummary: action.payload,
      };
    }

    ///---

    case GET_SELECTED_VENUE_CAMPAIGNS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case GET_SELECTED_VENUE_CAMPAIGNS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case GET_SELECTED_VENUE_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedVenueCampaigns: action.payload,
      };
    }

    ///---

    case CLEAR_SELECTED_VENUE_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        selectedVenue: null,
        selectedVenueSummary: null,
        selectedVenueCampaigns: null,
      };
    }

    case CLEAR_SELECTED_VENUE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    case CLEAR_SELECTED_VENUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedVenueCampaigns: action.payload,
        selectedVenue: null,
        selectedVenueSummary: null,
        // selectedVenueCampaigns: null,
      };
    }

    default:
      return state;
  }
}
