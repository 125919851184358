const loaderConfigHtml = `<div style="width: 150px; height: 150px;">
<div class="loading loading--full-height"></div>
</div>`;

const loaderConfigCss = `.loading {
    display: flex;
    justify-content: center;
}

.loading::after {
    content: "";
    width: 50px;
    height: 50px;
    border: 10px solid #ffcc33;
    border-top-color: #ea5454;
    border-radius: 50%;
    animation: loading 1s ease infinite;
}

@keyframes loading {
    to {
        transform: rotate(1turn);
    }
}`;

export const customTools = {};

export const customOptions = {
  appearance: {
    loader: {
      html: loaderConfigHtml,
      css: loaderConfigCss,
    },
  },
};
