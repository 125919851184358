import React, {ChangeEvent, useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {getParamTypeMap, Param, ParamType} from "../../types";
import LinkParam from "./Params/LinkParam";
import uuid from "react-uuid";

type Item = {
  key: string,
  label: string
}

const paramMap: Map<any, string> = getParamTypeMap();

const items: Item[] = [
  {key: "None", label: "None"},
  {key: "CONDITION_EMAIL_OPENED", label: paramMap.get(ParamType.CONDITION_EMAIL_OPENED)},
  {key: "CONDITION_EMAIL_NOT_OPENED", label: paramMap.get(ParamType.CONDITION_EMAIL_NOT_OPENED)},
  {key: "CONDITION_LINK_CLICKED", label: paramMap.get(ParamType.CONDITION_LINK_CLICKED)},
  {key: "CONDITION_LINK_NOT_CLICKED", label: paramMap.get(ParamType.CONDITION_LINK_NOT_CLICKED)},
]

const isLinkParam = (type: string) => {
  return type === "CONDITION_LINK_CLICKED" || type === "CONDITION_LINK_NOT_CLICKED";
}

type Props = {
  params: Param[]
  setParam: React.Dispatch<React.SetStateAction<Param>>
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
  removeConditions: () => void
  templateIds: string[]
}

const Conditions = ({params, setParam, setDisabled, removeConditions, templateIds}: Props) => {

  const [type, setType] = useState<string>("None");
  const [value, setValue] = useState<string>("");

  const addParam = (type: string, value: string) => {
    const paramType: ParamType = ParamType[type];
    const param: Param = {
      id: uuid(),
      paramType: paramType,
      paramValue: value,
      label: paramMap.get(value)
    };

    removeConditions(); // only one condition at a time supported for now

    setParam(param);
  }

  useEffect(() => {
    const p = params.find((p: Param) => p.paramType.toString().startsWith("CONDITION"));
    if (p) {
      if (type !== p.paramType.toString()) {
        setType(p.paramType.toString())
      }
      if (value !== p.paramValue) {
        setValue(p.paramValue);
      }
    }
  }, [params]);

  useEffect(() => {
    if (value && type.startsWith("CONDITION_LINK")) {
      addParam(type, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = (val: string) => {
    setType(val);

    if (!isLinkParam(val)) {
      setDisabled(false)
    }

    if (val === 'None') {
      removeConditions();

    } else if (val.startsWith("CONDITION_EMAIL")) {
      addParam(val, '');
    }
  }

  return (
      <>
        <FormControl
            variant="standard"
            sx={{m: 1, minWidth: 200}}
        >
          <InputLabel
              id="conditions-label"
              sx={{marginLeft: "10px"}}
          >
            Conditions
          </InputLabel>
          <Select
              disableUnderline
              sx={{}}
              labelId='conditions-label'
              label="Conditions"
              fullWidth
              value={type}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
          >
            {items
            .map((i: Item) =>
                <MenuItem
                    key={i.key}
                    value={i.key}
                >
                  {i.label}
                </MenuItem>
            )}
          </Select>
        </FormControl>
        {isLinkParam(type) &&
            <LinkParam
                value={value}
                setDisabled={setDisabled}
                setValue={setValue}
                templateIds={templateIds}
            />
        }
      </>
  )
}

export default Conditions;
