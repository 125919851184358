import React, {useEffect, useState} from 'react';
import {from, map} from "rxjs";
import api from "../../../../../../utils/api";
import {AxiosResponse} from "axios";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import uuid from "react-uuid";
import {Param, ParamType} from "../../../../types";

type Template = {
  externalId: string | null
  name: string
  messageBody: string
  organisationExternalId: string
}

type Props = {
  organisationId: string
  setParam: React.Dispatch<React.SetStateAction<Param>>
}

const TemplateParam = ({organisationId, setParam}: Props) => {

  const [template, setTemplate] = useState<string>("");
  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    from(api.get(`/sms/organisation/${organisationId}/template`))
    .pipe(map((response: AxiosResponse) => response.data))
    .subscribe({
      next: (data: Template[]) => {
        if (data && data.length > 0) {
          setTemplates(data);
          setTemplate(data[0].externalId);
          setParam(getParam(data[0].externalId, data[0].name));
        } else {
          setTemplates([]);
        }
      },
      error: (err: any) => {
        console.error("Error getting sms senders");
        console.error(err);
      }
    });
  }, []);

  useEffect(() => {
    if (template) {
      const t: Template = templates.find(s => s.externalId === template);
      t && setParam(getParam(t.externalId, t.name));
    }
  }, [template]);

  const getParam = (value: string, label: string): Param => {
    return {
      id: uuid(),
      paramType: ParamType.TEMPLATE_ID,
      paramValue: value,
      label: label
    }
  }

  return (
      <Box sx={{minWidth: 120}}>
        <FormControl fullWidth>
          <InputLabel id="select-template-label">Template</InputLabel>
          <Select
              id={uuid()}
              labelId="select-template-label"
              value={template}
              label="Template"
              onChange={(e) => setTemplate(e.target.value)}
          >
            {templates && templates.map((s: any) =>
                <MenuItem key={uuid()} value={s.externalId}>{s.name}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
  );
}

export default TemplateParam;