import React from "react";

import {Route} from "react-router-dom";

//CONNECT
import ConnectDashboard from "pages/Connect/Dashboard";
import TrackAndTrace from "pages/Connect/TrackAndTrace";
// import { Sidebar } from "components/sidebar";
import SuperAdminDashboard from "pages/SuperAdminDashboard";
// import SendSms from "pages/quickSend";
import Network from "pages/Network";
// import Settings from "pages/settings";
import UserSettings from "pages/UserSettings";
import {GettingStarted} from "pages/gettingStarted";

// import Customers from "pages/Customers";
import Customers from "../../../pages/Send/Customers";

import {InviteUser} from "pages/inviteUser";
import Organisation from "pages/Organisation";

import {Account} from "pages/account";
import Integrations from "pages/integrations";
import Venues from "pages/venues";
import Billing from "pages/Billing";
import {Devices} from "pages/devices";
import CreateCampaign from "components/sms/campaign/createCampaign";

import ManageCampaigns from "components/sms/campaign/manageCampaigns";

import SmsReport from "components/sms/reports/report";

//SMS
import SmsDashboard from "pages/Sms/Dashboard";
import QuickSend from "pages/Sms/QuickSend";
import SmsReports from "pages/Sms/Reports";

//SEND
import SendDashboard from "pages/Send/Dashboard";
import SendNewEmail from "pages/Send/SendNewEmail";
import SendManageCampaigns from "pages/Send/ManageCampaigns";
import SendSettings from "pages/Send/Settings";

//CONNECT
import ConnectLandingPage from "pages/Connect/LandingPage";

//CONTAINERS
import Suspend from "pages/suspend";

import Poster from "components/poster/poster";

import "assets/css/main.scss";

import uuid from "react-uuid";
import NoSendEmailLicense from "../../Send/NoSendEmailLicense";
import NoSmsLicense from "../../Sms/NoSmsLicense";
// import Automation from "../../../components/Automation";
//import Templates from "../../../components/send/create/templates";
import Templates from "../../../pages/Send/Templates";
import Automation from "../../../components/Automation";
import SmsSettings from "../../../components/sms/SmsSettings";

const MainAdmin = (props) => {
  const {
    // organisationId,
    currentUserOrganisation,
    isSuperAdmin,
    selectedOrganisation,
    setShowPopup,
    activeLicenses,
    selectedVenue,
    selectedEntity,
    userOrganisation,
  } = props;

  return (
      <>
        <Route path="/dashboard">
          <SuperAdminDashboard
              {...props}
              organisationId={currentUserOrganisation?.externalId}
          />
        </Route>

        {["/org-dashboard", "/connect/dashboard"].map((path) => {
          return (
              <Route key={uuid()} path={path}>
                {/*<UserMainDashboard*/}
                {/*    {...props}*/}
                {/*    organisationId={organisationId}*/}
                {/*    edit={editMode}*/}
                {/*    setEdit={setEditMode}*/}
                {/*/>*/}
                <ConnectDashboard
                    organisationId={selectedOrganisation?.externalId}
                    selectedVenue={selectedVenue}
                    selectedOrganisation={selectedOrganisation}
                    {...props}
                />
              </Route>
          );
        })}

        {/*<Route path="/org-dashboard">*/}
        {/*  <UserMainDashboard*/}
        {/*      {...props}*/}
        {/*      organisationId={*/}
        {/*        selectedOrganisation*/}
        {/*            ? selectedOrganisation?.externalId*/}
        {/*            : currentUserOrganisation?.externalId*/}
        {/*      }*/}
        {/*      edit={editMode}*/}
        {/*      setEdit={setEditMode}*/}
        {/*  />*/}
        {/*</Route>*/}

        <Route path="/customers">
          <Customers
              organisationId={selectedOrganisation?.externalId}
              {...props}
          />
        </Route>

        <Route path="/network">
          <Network
              setShowPopup={setShowPopup}
              organisationId={userOrganisation.externalId}
          />
        </Route>

        {/*CONNECT*/}

        {/*<Route path="/connect/dashboard">*/}
        {/*  <ConnectDashboard*/}
        {/*      organisationId={selectedOrganisation?.externalId}*/}
        {/*      selectedVenue={selectedVenue}*/}
        {/*      selectedOrganisation={selectedOrganisation}*/}
        {/*      {...props}*/}
        {/*  />*/}
        {/*</Route>*/}

        <Route path="/connect/design">
          <ConnectLandingPage
              selectedOrganisation={selectedOrganisation}
              selectedEntity={selectedEntity}
              {...props}
          />
        </Route>

        <Route path="/connect/poster">
          <Poster/>
        </Route>

        {activeLicenses.includes("TT") ||
            (isSuperAdmin && (
                <Route path="/connect/track">
                  <TrackAndTrace
                      organisationId={selectedOrganisation?.externalId}
                      selectedEntity={selectedEntity}
                  />
                </Route>
            ))}

        {/*CONNECT END*/}

        {/*SMS */}

        {/*Dashboard*/}
        {(activeLicenses.includes("SMS") || isSuperAdmin) && (
            <Route path="/sms/dashboard">
              <SmsDashboard/>
            </Route>
        )}
        {!activeLicenses.includes("SMS") && !isSuperAdmin && (
            <Route path="/sms/dashboard">
              <NoSmsLicense {...props} />
            </Route>
        )}

        {/*Quick SMS*/}
        {(activeLicenses.includes("SMS") || isSuperAdmin) && (
            <Route path="/sms/quick-sms">
              <QuickSend {...props} activeLicenses={activeLicenses}/>
            </Route>
        )}
        {!activeLicenses.includes("SMS") && !isSuperAdmin && (
            <Route path="/sms/quick-sms">
              <NoSmsLicense {...props} />
            </Route>
        )}

        {/*Contacts*/}
        {/*<Route path="/sms/contacts">*/}
        {/*  <SmsContacts organisationId={selectedOrganisation?.externalId}/>*/}
        {/*</Route>*/}

        {/*Reports*/}
        {(activeLicenses.includes("SMS") || isSuperAdmin) && (
            <Route path="/sms/reports">
              <SmsReports organisationId={selectedOrganisation?.externalId}/>
            </Route>
        )}
        {!activeLicenses.includes("SMS") && !isSuperAdmin && (
            <Route path="/sms/reports">
              <NoSmsLicense {...props} />
            </Route>
        )}

        {(activeLicenses.includes("SMS") || isSuperAdmin) && (
            <Route path="/sms/reports/:campaignId">
              <SmsReport/>
            </Route>
        )}
        {!activeLicenses.includes("SMS") && !isSuperAdmin && (
            <Route path="/sms/reports/:campaignId">
              <NoSmsLicense {...props} />
            </Route>
        )}

        {/*Campaigns*/}
        {(activeLicenses.includes("SMS") || isSuperAdmin) && (
            <>
              <Route path="/sms/campaigns/create">
                <CreateCampaign
                    organisationId={selectedOrganisation?.externalId}/>
              </Route>
              <Route path="/sms/settings">
                <SmsSettings organisationId={selectedOrganisation?.externalId}/>
              </Route>
            </>
        )}
        {!activeLicenses.includes("SMS") && !isSuperAdmin && (
            <>
              <Route path="/sms/campaigns">
                <NoSmsLicense {...props} />
              </Route>
              <Route path="/sms/template">
                <NoSmsLicense {...props} />
              </Route>
            </>
        )}

        {(activeLicenses.includes("SMS") || isSuperAdmin) && (
            <Route path="/sms/campaigns/manage">
              <ManageCampaigns
                  organisationId={selectedOrganisation?.externalId}/>
            </Route>
        )}
        {!activeLicenses.includes("SMS") && !isSuperAdmin && (
            <Route path="/sms/campaigns/manage">
              <NoSmsLicense {...props} />
            </Route>
        )}

        {(activeLicenses.includes("SMS") || isSuperAdmin) && (
            <Route path="/sms/campaigns/create/:campaignId">
              <CreateCampaign
                  organisationId={selectedOrganisation?.externalId}/>
            </Route>
        )}
        {!activeLicenses.includes("SMS") && !isSuperAdmin && (
            <Route path="/sms/campaigns/:campaignId">
              <NoSmsLicense {...props} />
            </Route>
        )}

        {/*SMS END*/}

        {/*SEND*/}

        {/*Dashboard*/}
        {(activeLicenses.includes("SEND") || isSuperAdmin) && (
            <Route path="/email/dashboard">
              <SendDashboard selectedEntity={selectedEntity}/>
            </Route>
        )}
        {!activeLicenses.includes("SEND") && !isSuperAdmin && (
            <Route path="/email/dashboard">
              <NoSendEmailLicense {...props} />
            </Route>
        )}

        {/*New Email*/}
        {(activeLicenses.includes("SEND") || isSuperAdmin) && (
            <Route path="/email/new">
              <SendNewEmail
                  selectedEntity={selectedEntity}
                  activeLicenses={activeLicenses}
                  isSuperAdmin={isSuperAdmin}
              />
            </Route>
        )}
        {!activeLicenses.includes("SEND") && !isSuperAdmin && (
            <Route path="/email/new">
              <NoSendEmailLicense {...props} />
            </Route>
        )}
        {/*{(!activeLicenses.includes("SEND") && !isSuperAdmin) && (*/}
        {/*    <Route path="/email/new">*/}
        {/*      <SendNewEmail*/}
        {/*          selectedEntity={selectedEntity}*/}
        {/*          activeLicenses={activeLicenses}*/}
        {/*      />*/}
        {/*    </Route>*/}
        {/*)}*/}

        {/*Customers*/}
        {/*{(activeLicenses.includes("SEND") || isSuperAdmin) && (*/}
        {/*    <Route path="/email/customers">*/}
        {/*      <SendCustomers selectedEntity={selectedEntity}/>*/}
        {/*    </Route>*/}
        {/*)}*/}

        {/*Campaigns*/}
        {(activeLicenses.includes("SEND") || isSuperAdmin) && (
            <Route path="/email/campaigns">
              <SendManageCampaigns selectedEntity={selectedEntity}/>
            </Route>
        )}
        {!activeLicenses.includes("SEND") && !isSuperAdmin && (
            <Route path="/email/campaigns">
              <NoSendEmailLicense {...props} />
            </Route>
        )}

        {/*{!activeLicenses.includes("SEND") && !isSuperAdmin && (*/}
        <Route path="/send/templates">
          <Templates
              organisationId={selectedOrganisation?.externalId}
          />
        </Route>
        {/*)}*/}

        {/*Settings*/}
        {(activeLicenses.includes("SEND") || isSuperAdmin) && (
            <Route path="/send/settings">
              <SendSettings selectedEntity={selectedEntity}/>
            </Route>
        )}
        {!activeLicenses.includes("SEND") && !isSuperAdmin && (
            <Route path="/send/settings">
              <NoSendEmailLicense {...props} />
            </Route>
        )}

        {/*SEND END*/}

        {/*ROUTES=----*/}

        {isSuperAdmin &&
            <Route path="/automation">
              <Automation organisationId={selectedOrganisation?.externalId}/>
            </Route>
        }

        <Route path="/devices">
          <Devices/>
        </Route>

        <Route path="/settings">
          <UserSettings selectedEntity={selectedEntity}/>
        </Route>
        <Route path="/getting-started">
          <GettingStarted/>
        </Route>
        <Route path="/organisation">
          <Organisation {...props} />
        </Route>
        <Route path="/invite">
          <InviteUser setShowPopup={setShowPopup}/>
        </Route>
        <Route path="/integrations">
          <Integrations organisationId={selectedOrganisation?.externalId}/>
        </Route>
        <Route path="/account">
          <Account/>
        </Route>
        <Route path="/venues">
          <Venues/>
        </Route>
        <Route path="/billing">
          <Billing {...props} />
        </Route>
        <Route path="/suspended">
          <Suspend/>
        </Route>
      </>
  );
};

export default MainAdmin;
