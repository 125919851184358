import React, {useCallback, useContext, useEffect, useState} from "react";
import {compose} from "recompose";
import SendManageCampaignsContainer from "containers/Send/SendManage";
import CampaignsDisplay from "./CampaignsDisplay";
import {VenuesContext} from "context/venueContext";
import PageNavigator from "./PageNavigator";
import "assets/css/send/manage-campaigns.scss";
import ManageCampaignsWrapper from "./ManageCampaignsWrapper";
import SearchInput from "./SearchInput";
import debounce from "utils/debounce";
import {Link} from "react-router-dom";
import { ampli } from "containers/Amplitude/ampli";

const ManageCampaigns = (props) => {
  const {getCampaignsRequest, campaigns, currentPagination} = props;

  const {selected} = useContext(VenuesContext);
  const [currentSearchInput, setCurrentSearchInput] = useState("");

  //Initial Campaigns Fetch
  useEffect(() => {
    selected?.externalId && getCampaignsRequest(selected.externalId);
  }, [selected]);

  const handleChange = (event) =>
      getCampaignsRequest(selected.externalId, 0, event.target.value);

  const debouncedHandleChange = useCallback(debounce(handleChange));

  ampli.unaroPageVisitEventWithProperties({
    emailAddress: JSON.parse(localStorage.getItem("user")).email,
    organisationName:
      JSON.parse(localStorage.getItem("user")).organisations[0] != null
        ? JSON.parse(localStorage.getItem("user")).organisations[0].name
        : "",
        eventType: "page visit: Send -> Manage Campaigns",
  });

  return (
      campaigns ?
          (
              <ManageCampaignsWrapper>
                <div className="mec-top">
                  <label className="mec-title">Campaigns</label>
                  <div className="navigation">
                    {currentPagination && (
                        <PageNavigator
                            currentPagination={currentPagination}
                            currentSearchInput={currentSearchInput}
                            changePage={(page) =>
                                getCampaignsRequest(
                                    selected.externalId,
                                    page,
                                    currentSearchInput
                                )
                            }
                        />
                    )}
                  </div>
                  <div className="mec-right">
                    <SearchInput
                        organisationId={selected.externalId}
                        currentSearchInput={currentSearchInput}
                        setCurrentSearchInput={setCurrentSearchInput}
                        debouncedHandleChange={debouncedHandleChange}
                        getCampaignsRequest={getCampaignsRequest}
                    />
                  </div>
                </div>

                <CampaignsDisplay campaigns={campaigns}/>


              </ManageCampaignsWrapper>
          )
          :
          <div style={{margin: '25px'}}>
            <h2>No campaigns</h2>
            <p style={{marginTop: '25px'}}>
              Click here to{' '}
              <Link to="/email/new">create new email campaign</Link>
            </p>
          </div>
  )

};

export default compose(SendManageCampaignsContainer)(ManageCampaigns);
