import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {compose} from "recompose";
import MiscContainer from "containers/Misc";
import menuConfig from "./menuConfig";
import NavBarItem from "./NavBarItem";
import {ReactComponent as ArrowLeftIcon} from "assets/images/arrow-left.svg";
import "assets/css/sidebar.scss";
import {
  ReactComponent as DashboardIcon
} from 'assets/images/dashboard-icon.svg';
import {
  ReactComponent as InactiveDashboardLogo
} from 'assets/images/dashboard-icon-inactive.svg';

import {ReactComponent as CustomersLogo} from 'assets/images/customer-icon.svg';
import {
  ReactComponent as InactiveCustomersLogo
} from 'assets/images/customer-icon-inactive.svg';

// const mapSubItem = (activeLicenses, isSuperAdmin, subItem) =>
//     activeLicenses.includes(subItem.requireLicense)
//     || isSuperAdmin
//         ? {...subItem, active: true}
//         : subItem;

const NavBar = (props) => {
  const history = useHistory();
  const location = useLocation();

  const {
    isSuperAdmin,
    // locked,
    // changeLocked,
    selectedOrganisation,
    // licences,
    activeLicenses,
    // userOrganisation,
    isNavBarLocked,
    toggleNavBarRequest,
  } = props;

  const iconStyle = {
    width: "25px",
    height: "25px"
  };

  const adminItems = [
    {
      id: 0,
      logo: <DashboardIcon style={iconStyle}/>,
      inactiveLogo: <InactiveDashboardLogo style={iconStyle}/>,
      text: "Dashboard",
      redirect: "/dashboard",
      selected: useLocation().pathname === "/dashboard",
      active: true,
      subItems: [],
    },
    {
      id: 1,
      logo: <CustomersLogo style={iconStyle}/>,
      inactiveLogo: <InactiveCustomersLogo style={iconStyle}/>,
      text: "Customers",
      redirect: "/network",
      selected: useLocation().pathname === "/network",
      active: true,
      subItems: [],
    },
  ];

  const [menuItems, setMenuItems] = useState(menuConfig(location));
  const [selectedPath, setSelectedPath] = useState();
  const [smallSidebar, setSmallSidebar] = useState(!isNavBarLocked);

  React.useEffect(() => {
    if (activeLicenses) {
      setMenuItems(
          [
            ...menuItems.map((item) => {
              const active = item.requireLicense
                  ? isSuperAdmin || activeLicenses.includes(item.requireLicense)
                  : true;
              return {
                ...item,
                active: active,
                subItems: item.subItems.map(
                    (subItem) => {
                      return {
                        ...subItem,
                        active: active
                      }
                    }),
              }
            })
          ]
      );
    }
    toggleNavBarRequest(true);
    setSmallSidebar(false);
  }, [activeLicenses]);

  useEffect(() => {
    if (isSuperAdmin && !selectedOrganisation) {
      setMenuItems(adminItems)
    } else {
      setMenuItems(menuConfig(location))
    }
  }, [selectedOrganisation]);

  useEffect(() => {
    if (selectedPath) {
      toggleNavBarRequest(true);
      setSmallSidebar(false);
      setMenuItems([
        ...menuItems.map((x) =>
            selectedPath.startsWith(x.redirect)
                ? {
                  ...x,
                  subItems: x.subItems.map((subItem) => {
                    return {
                      ...subItem,
                      selected: subItem.redirect === selectedPath
                    }
                  }),
                  selected: true,
                  showSubItems: true,
                }
                : {
                  ...x,
                  subItems: x.subItems.map((subItem) => ({
                    ...subItem,
                    selected: false,
                  })),
                  selected: false,
                  showSubItems: false,
                }
        ),
      ]);
    }
  }, [selectedPath]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        setSelectedPath(location.pathname);
      }

      if (!location.pathname.startsWith("/customers")) {
        localStorage.removeItem("current-customers-page");
        localStorage.removeItem("l-filter");
        localStorage.removeItem("r-filter");
      }
    });
  }, []);

  const selectSubItem = (id, subId = "") => {
    setMenuItems([
      ...menuItems.map((x) =>
          x.id === id
              ? {
                ...x,
                subItems: x.subItems.map((subItem) =>
                    subItem.id === subId
                        ? {...subItem, selected: true}
                        : {...subItem, selected: false}
                ),
                selected: true,
                showSubItems: true,
              }
              : {
                ...x,
                subItems: x.subItems.map((subItem) => ({
                  ...subItem,
                  selected: false,
                })),
                selected: false,
                showSubItems: false,
              }
      ),
    ]);
  };

  const changeSidebar = (a) => {
    if (!isNavBarLocked) {
      setSmallSidebar(a);
    }
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
      <div
          className={`sidebar ${smallSidebar && "small-sidebar"}`}
          onMouseEnter={() => changeSidebar(false)}
          onMouseLeave={() => changeSidebar(true)}
      >
        <div className="menu">
          <div className="menu-icons">
            <div className="menu-logo">
              <img
                  alt="logo icon"
                  className="logo-icon"
                  src={require("assets/images/small-icon.svg").default}
              />
              <img
                  alt="logo"
                  className="logo"
                  src={require("assets/images/unaro-main.svg").default}
              />
            </div>
          </div>
          <div
              className={`menu-btn ${!isNavBarLocked
              && "menu-btn-small-sidebar"}`}
              onClick={() => toggleNavBarRequest(!isNavBarLocked)}
          >
            <ArrowLeftIcon className="icon"/>
          </div>
          {menuItems &&
              menuItems.map((item) => (
                  <NavBarItem
                      key={item.id}
                      item={item}
                      smallMenu={smallSidebar}
                      onClick={() => selectSubItem(item.id)}
                      onSubmenuClick={selectSubItem}
                      smallSidebar={smallSidebar}
                  />
              ))}
        </div>

        <div className="settings">
          <hr/>
          <div className="info">
            <label>
              <a target="_blank" rel="noopener noreferrer" href="https://unaro.co.uk/privacy-policy ">
                Privacy
              </a>
            </label>
            <div className="vertical"/>
            <label>
              <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.unaro.co.uk/wp-content/uploads/2021/01/Unaro-Terms-and-Conditions-.pdf"
              >
                Terms
              </a>
            </label>
            <div className="vertical"/>
            <label>
              <a target="_blank" rel="noopener noreferrer" href="https://www.unaro.co.uk/contact-us/">
                Help
              </a>
            </label>
          </div>
          <div className="copyright">
            <label>&copy;</label>
            <label>Copyright</label>
            <label>{getYear()}</label>
          </div>
        </div>
      </div>
  );
};

export default compose(MiscContainer)(NavBar);
