import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { hexToRGB } from 'utils/common'
import TitleDropdown from '../../widget/titleDropdown'
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import { ReactComponent as AddIcon } from 'assets/images/add.svg';

import 'assets/css/design/track/add-customer-popup.scss'

export default ({ organisationId, landingPage, close }) => {
    const titles = ['Mr.', 'Mrs.', 'Miss']
    const genders = ['male', 'female']

    const [logoUrl, setLogoUrl] = useState('')
    const [backgroundUrl, setBackgroundUrl] = useState()
    const [customer, setCustomer] = useState({ title: titles[0], gender: genders[0], registrationType: 'EMAIL' })
    const [errors, setErrors] = useState([])
    const [showError, setShowError] = useState(false)
    const [emailError, setEmailError] = useState()
    const [settings, setSettings] = useState({})

    const getUrl = (type) => {
        const creative = landingPage.creatives.find(creative => creative.type === type)
        return creative ? creative.url : null
    }
    useEffect(() => {
        if (landingPage.creatives) {
            setLogoUrl(getUrl('LOGO'))
            setBackgroundUrl(getUrl('BACKGROUND'))
            setSettings(landingPage.landingpageSettings)
        }
    }, [landingPage])

    const changeEmail = (value) => {
        setErrors(errors.filter(error => error !== 'email'))
        setShowError(false)
        setCustomer({ ...customer, email: value })
    }

    const changeForename = (value) => {
        setShowError(false)
        setErrors(errors.filter(error => error !== 'forename'))
        setCustomer({ ...customer, forename: capitalize(value) })
    }

    const changeSurname = (value) => {
        setShowError(false)
        setErrors(errors.filter(error => error !== 'surname'))
        setCustomer({ ...customer, surname: capitalize(value) })
    }

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const isValidEmail = email => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const register = async (onDone) => {
        var e = []
        if (!customer.email || customer.email.length < 3)
            e = [...e, 'email']
        if (!customer.forename || customer.forename.length < 3)
            e = [...e, 'forename']
        if (!customer.surname || customer.surname.length < 3)
            e = [...e, 'surname']
        if (!customer.phones || customer.phones.length === 0 || !customer.phones[0].phoneNumber || customer.phones[0].phoneNumber.length < 3 || !customer.phones[0].phoneNumber.match(/\d/))
            e = [...e, 'phone']

        setErrors(e)

        if (e.length > 0)
            setShowError(true)

        if (e.length === 0) {
            if (!isValidEmail(customer.email))
                setEmailError('Invalid email')
            else {
                setErrors([])
                customer.contactType = "PERSON"
                customer.organisationId = organisationId
                customer.trackAndTrace = true

                try {
                    await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/contact/register`, customer, { headers: { 'api_key': 'pk_live_FGHJYYTALAek2rwZ0ZHbrTDj00skByotel' } })
                    onDone()
                } catch (e) {
                    if (e.response && e.response.data && e.response.data.errorDescription)
                        setEmailError(e.response.data.errorDescription)
                    else
                        setEmailError('Registration error')
                }
            }
        }
    }


    const onRegister = async () => {
        await register(close)
    }

    const onAddMore = async () => {
        await register(() => setCustomer({ title: titles[0], gender: genders[0], registrationType: 'EMAIL' }))
    }

    const onBlurPopup = event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            close()
        }
    }

    const changePhone = (value) => {
        setShowError(false)
        setErrors(errors.filter(error => error !== 'phone'))
        setCustomer({ ...customer, phones: [{ phoneNumber: value }] })
    }

    return (
        <div className='add-customer-popup'>
            <div className="add-customer-popup-content" style={{ backgroundColor: backgroundUrl ? 'unset' : '#FECC33', backgroundImage: backgroundUrl ? `url(${backgroundUrl}` : 'assets/images/unaro-yellow-bg.svg' }} tabIndex="0" onBlur={onBlurPopup} >
                <div className="add-customer-popup-box" style={{ backgroundColor: landingPage.boxColor ? hexToRGB(landingPage.boxColor, landingPage.menuOpacity ? 1 - landingPage.menuOpacity : 1) : '#ffffff', border: landingPage.boxBorderVisibility === 'SHOW' ? `1px solid ${landingPage.boxBorderColor}` : 'none' }}>
                    <div className="add-customer-popup-top">
                        <style jsx>{`
                            input::placeholder {
                                color: ${hexToRGB(landingPage.inputColor, 0.6)};
                            }
                        `}</style>
                        <img alt="logo" className='logo' src={logoUrl ? logoUrl : require('assets/images/unaro-full-icon.svg').default} />
                        <label className='add-customer-popup-connect-with-email' style={{ color: landingPage.textcolor }}>Connect with email</label>
                        <input style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} placeholder="Forename:" value={customer.forename || ''} className={`${errors.length > 0 && errors.includes('forename') && 'input-error'}`} onChange={e => changeForename(e.target.value)} />
                        <input style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} placeholder="Surname:" value={customer.surname || ''} className={`${errors.length > 0 && errors.includes('surname') && 'input-error'}`} onChange={e => changeSurname(e.target.value)} />
                        <input style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} placeholder="Phone number:" value={customer.phones ? customer.phones[0].phoneNumber : ''} className={`${errors.length > 0 && errors.includes('phone') && 'input-error'}`} onChange={e => changePhone(e.target.value)} />
                        <input style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} autoFocus placeholder="Email:" value={customer.email || ''} className={`${errors.length > 0 && errors.includes('email') && 'input-error'}`} onChange={e => changeEmail(e.target.value)} />

                        {settings.requireDob &&
                            <div className='birthday-input' style={{ borderColor: landingPage.inputColor }}>
                                <label style={{ color: landingPage.inputColor }}>Date of birth</label>
                                <input style={{ color: landingPage.inputColor }} className='input-preview' type='date' value={customer.birthday ? new Date(customer.birthday).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)} onChange={e => setCustomer({ ...customer, birthday: new Date(e.target.value).getTime() })} />
                            </div>
                        }
                        <TitleDropdown color={landingPage.inputColor}  title='Title:' options={titles} selected={customer.title || titles[0]} onSelect={value => setCustomer({ ...customer, title: value })} />
                        <TitleDropdown color={landingPage.inputColor}  title='Gender:' options={genders} selected={customer.gender || genders[0]} onSelect={value => setCustomer({ ...customer, gender: value })} />

                        {showError && <label style={{ color: landingPage.errorTextColor }} className='error-label'>Please fill all fields</label>}
                        {emailError && <label style={{ color: landingPage.errorTextColor }} className='error-label'>{emailError}</label>}
                    </div>
                    <div className="add-customer-popup-bottom">
                        <div className='add-customer-popup-register-btn' style={{ backgroundColor: landingPage.buttonColor || '#FECC33' }} onClick={onRegister}>
                            <label>Register</label>
                            <ArrowIcon />
                        </div>
                        <div className='add-customer-popup-register-btn add-more' style={{ backgroundColor: landingPage.goButtonColor || '#FF9A22' }} onClick={onAddMore}>
                            <AddIcon />
                            <label>Add More Guests</label>
                        </div>
                        <label className='add-customer-popup-privacy'>Click here to read our <a href="#" style={{ color: landingPage.linkColor }}>Privacy Policy</a></label>
                    </div>
                </div>
            </div>
        </div >
    )
}