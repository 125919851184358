import React, {useState} from "react";
import {reduxForm} from "redux-form";
import {compose} from "recompose";
import {from} from 'rxjs';
import {
  apiCreateSegment, apiUpdateSegment
} from "../../../../../../containers/ContactsList/api";
import {toast} from "react-toastify";
import {
  Grid,
  Box,
  Backdrop,
  Button,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Modal,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FieldsRow from "../CreateContactModal/FieldsRow";
import TextField from '@mui/material/TextField';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import styled from "@emotion/styled";
import group41Png from "../../../../../../assets/images/Group41.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Picker, {SKIN_TONE_MEDIUM_DARK} from 'emoji-picker-react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InputAdornment from "@mui/material/InputAdornment";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Chip from '@mui/material/Chip';
import uuid from "react-uuid";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "25px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
  customTextField: {
    "& label.Mui-focused": {
      color: "orange"
    },
    "& .MuiOutlinedInput-root": {
      width: {sm: 250, md: 550},
      "&.Mui-focused fieldset": {
        borderColor: "orange"
      },
      "& > fieldset": {
        borderRadius: 25,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange"
      }
    },
  }
}));

const CreateSegementModalWrapper = styled.form`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 565px;
  height: 560px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const ConfirmSegmentModalWrapper = styled.form`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 540px;
  height: 313px;  
  background: #FFFFFF;
  border-radius: 16px;
`;

const AddFiltersModalWrapper = styled.form`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 540px;
  height: 241px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const TypographyText = styled.div`
  height: 32px;
  font-family: Trebuchet MS, sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const NewSegementContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  position: absolute;
  height: 266px;
  left: 36px;
  right: 36px;
  top: 60px;
`;

const ConfirmationSegmentCreated = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 565px;
  height: 231px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const ImageDiv = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
  top: 43.8px;
`;

const HeaderImage = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(46, 125, 50, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const HeaderErrorAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(234, 84, 84, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const TypographyTextSegmentCreated = styled.div`
  position: absolute;
  height: 32px;
  left: 44.5px;
  right: 44.5px;
  top: 170.81px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const CreateSegmentModal = (props) => {
  const {
    organisationId,
    currentFilters,
    removeFilterRequest,
    setCurrentSelectedSegmentId,
    getSegmentsRequest,
    isFromHamburgerMenu,
    selectedSegment,
    setOpenSaveNewSegment,
    openSaveNewSegment,
    setOpenUpdateSegment,
    openUpdateSegment
  } = props;

  const {id, name, iconName, criteria} = selectedSegment || "";

  const classes = useStyles();
  const [openAddFilters, setOpenAddFilters] = useState(
      openSaveNewSegment || openUpdateSegment);
  const [openConfirmSegment, setOpenConfirmSegment] = useState(false);
  const [openAddNewSegment, setOpenAddNewSegment] = useState(false);
  const [openSegmentCreated, setOpenSegmentCreated] = useState(false);
  const [openSegmentUpdated, setOpenSegmentUpdated] = useState(false);
  const [openSegmentAlreadyExist, setOpenSegmentAlreadyExist] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [currentEmoji, setCurrentEmoji] = useState(
      openUpdateSegment ? iconName : "");
  const [segmentName, setSegmentName] = useState(openUpdateSegment ? name : "");

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    const {emoji} = emojiObject;
    setCurrentEmoji(emoji)
  };

  const handleNameSegmentBackButtonClick = () => {
    setOpenAddNewSegment(false);
    setOpenConfirmSegment(false);
    setOpenAddFilters(true);
  }

  const handleConfirmSegmentBackButtonClick = () => {
    setOpenSegmentCreated(false);
    setOpenAddNewSegment(true);
  }

  const handleSegmentNameChange = (event) => {
    setSegmentName(event.target.value)
  }

  const handleOpenAddNewSegment = () => {
    setOpenAddNewSegment(true);
  };

  const handleOpenAddFilters = () => {
    setOpenAddFilters(true);
  };

  const handleCloseAddFilters = () => {
    setOpenAddFilters(false);
    setOpenSaveNewSegment(false);
    setOpenUpdateSegment(false);

    setSegmentName("");
    setCurrentEmoji("");
  };

  const handleCloseAddNewSegment = () => {
    setOpenAddNewSegment(false);
    setOpenSaveNewSegment(false);
    setOpenUpdateSegment(false);
    setSegmentName("");
    setCurrentEmoji("");
  };

  const handleOpenConfirmSegment = () => {
    setOpenConfirmSegment(true);
  };

  const handleCloseConfirmSegment = () => {
    setOpenConfirmSegment(false);
    setOpenSaveNewSegment(false);
    setOpenUpdateSegment(false);
    setSegmentName("");
    setCurrentEmoji("");
  };

  const handleCloseSegmentCreated = () => {
    setOpenSegmentCreated(false);
    setOpenSegmentUpdated(false);
  };

  const handleCloseSegmentAlreadyExist = () => {
    setOpenSegmentAlreadyExist(false);
    setSegmentName("");
    setCurrentEmoji("");
  };

  const handleOpenSegmentAlreadyExist = () => {
    handleCloseConfirmSegment();
    setOpenSegmentAlreadyExist(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
    setSegmentName("");
    setCurrentEmoji("");
  };

  const handleOpenError = () => {
    handleCloseConfirmSegment();
    setOpenError(true);
  };

  const handleNameSegmentNextClick = () => {
    setOpenAddNewSegment(false);
    handleOpenConfirmSegment();
  }

  const handleAddFilterNextButtonClick = () => {
    setOpenAddFilters(false);
    handleOpenAddNewSegment();
  };

  const handleFilterDelete = (index) => {
    removeFilterRequest(index);
    setCurrentSelectedSegmentId("segment-all-contacts");
  };

  const onCreateOrUpdateSegment = () => {
    let values = {
      segmentName: segmentName,
      segmentEmoji: currentEmoji || ""
    }
    if (openUpdateSegment) {
      const request = {
        ...values,
        organisationId: organisationId,
        criteria: currentFilters,
        segmentId: selectedSegment.id,
      };
      from(apiUpdateSegment(request))
      .subscribe({
        next: (segment) => {
          const {data, status} = segment
          if (status === 200) {
            setOpenAddFilters(false);
            setOpenConfirmSegment(false);
            setOpenSegmentUpdated(true);
            setTimeout(
                () => {
                  setOpenSegmentUpdated(false);
                  setSegmentName("");
                  setCurrentEmoji("");
                },
                2000
            );
            getSegmentsRequest(organisationId);
          }
              // else if (status === 200 && data.trim() === "") {
              //   setOpenSegmentCreated(false);
              //   handleOpenSegmentAlreadyExist();
              //   setTimeout(
              //       () => {
              //         handleCloseSegmentAlreadyExist();
              //         setSegmentName("");
              //         setCurrentEmoji("");
              //       },
              //       3000
              //   );
          // }
          else {
            handleOpenError();
          }

        },
        error: (err) => {
          console.error('Error creating segment')
          console.error(err);
          handleOpenError();
          toast.error('Error creating segment');
        },
        complete: () => {
          // handleCloseAddNewSegment();
        }
      });

    } else {

      const request = {
        ...values,
        organisationId: organisationId,
        filters: currentFilters
      };
      from(apiCreateSegment(request))
      .subscribe({
        next: (segment) => {
          const {data, status} = segment
          if (status === 200 && data !== "") {
            setOpenConfirmSegment(false);
            setOpenSegmentCreated(true);
            setTimeout(
                () => {
                  setOpenSegmentCreated(false);
                  setSegmentName("");
                  setCurrentEmoji("");
                },
                2000
            );
            getSegmentsRequest(organisationId);
          } else if (status === 200 && data.trim() === "") {
            setOpenSegmentCreated(false);
            handleOpenSegmentAlreadyExist();
            setTimeout(
                () => {
                  handleCloseSegmentAlreadyExist();
                  setSegmentName("");
                  setCurrentEmoji("");
                },
                3000
            );
          } else {
            handleOpenError();
          }
        },
        error: (err) => {
          console.error('Error creating segment')
          console.error(err);
          handleOpenError();
          toast.error('Error creating segment');
        },
        complete: () => {
          //handleCloseAddNewSegment();
        }
      });
    }
  }

  const opTextMap: Map<string, string> = new Map([
    ["LT", "Less than"],
    ["GT", "More than"],
    ["EQUALS", "Exactly"]
  ]);

  const filterText = (filter) => {
    const {key, operation, value} = filter;
    const plural = value && value > 1 ? 's' : '';
    const opText = opTextMap.get(operation);
    switch (key) {
      case "hasEmail":
        return 'Has email';
      case "hasPhone":
        return 'Has phone';
      case "visitCount":
        return `${opText} ${value} visit${plural}`;
      case "birthday":
        return `Birthday is within ${value} day${plural}`;
      case "gender":
        return `Is ${value}`;
      case "firstVisit":
        return `First visit ${opText} ${value} day${plural} ago`;
      case "lastVisit":
        return `Last visit ${opText} ${value} day${plural} ago`;
      default:
        throw new Error(`filter ${key} not supported`);
    }
  };

  return (
      <>
        {currentFilters.length > 0 &&
            <div>
              {((isFromHamburgerMenu === true)) && (
                  <div onClick={handleOpenAddFilters}>
                    <ListItemIcon>
                      <FilterAltIcon fontSize="small"/>
                    </ListItemIcon>
                    Create Segment
                  </div>
              )}

              <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={openAddFilters}
                  onClose={handleCloseAddFilters}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
              >
                <Fade in={openAddFilters}>
                  <div className={classes.paper}>
                    <AddFiltersModalWrapper>
                      <img
                          alt="close"
                          onClick={handleCloseAddFilters}
                          className={"closeImg"}
                          src={require("assets/images/Group41.png")}
                      />
                      <TypographyText>
                          Add filters to create segment
                      </TypographyText>
                      <Box sx={{
                        marginTop: 12,
                      }}>
                        <Box sx={{
                          marginLeft: '1ch',
                          display: 'inline',
                          marginTop: 20,
                          maxWidth: 360,
                        }}>

                          {currentFilters &&
                              Object.values(currentFilters).map(
                                  (filter, index) => {
                                    return (
                                        <Chip
                                            key={uuid()}
                                            label={filterText(filter)}
                                            onDelete={() => handleFilterDelete(
                                                index)}
                                            sx={{m: 1}}
                                        />
                                    );
                                  })}
                        </Box>
                      </Box>

                      <Box sx={{
                        display: 'inline',
                        marginTop: 2,
                        marginLeft: '18ch',
                        maxWidth: 360,
                      }}>
                        <Button
                            onClick={handleCloseAddFilters}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              zIndex: 1,
                              marginTop: 5,
                              marginRight: 5
                            }}
                            variant="text"
                        >
                          CANCEL
                        </Button>
                        <Button
                            onClick={handleAddFilterNextButtonClick}
                            endIcon={<ChevronRightRoundedIcon
                                sx={{fontSize: '80px', fontWeight: '400'}}/>}
                            sx={{
                              color: "#FF9933",
                              fontSize: 16,
                              fontWeight: "500",
                              zIndex: 1,
                              marginTop: 5,
                            }}
                            variant="text"
                        >
                          NEXT
                        </Button>
                      </Box>
                    </AddFiltersModalWrapper>
                  </div>
                </Fade>
              </Modal>

              {/*NAME SEGMENT*/}
              {((openAddNewSegment === true)) && (
                  <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={openAddNewSegment}
                      onClose={handleCloseAddNewSegment}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                      disableEnforceFocus={true}
                      disableAutoFocus={true}
                  >
                    <Fade in={openAddNewSegment}>
                      <div className={classes.paper}>
                        <CreateSegementModalWrapper>
                          <img
                              alt="close"
                              onClick={handleCloseAddNewSegment}
                              className={"closeImg"}
                              src={require("assets/images/Group41.png")}
                          />

                          {openUpdateSegment === true &&
                              <TypographyText>
                                <Typography sx={{fontSize: 24}}>
                                  Update Segment
                                </Typography>
                              </TypographyText>
                          }
                          {(openUpdateSegment === false || isFromHamburgerMenu
                                  === true || openSaveNewSegment) &&
                              <TypographyText>
                                <Typography sx={{fontSize: 24}}>
                                  Create New Segment
                                </Typography>
                              </TypographyText>
                          }
                          <NewSegementContainer>
                            <div className={"audienceBlock addContactBlock"}>
                              <div className={"successfulImport"}>
                                <div>
                                  <FieldsRow>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="segmentName"
                                        label="Segment Name"
                                        className={classes.customTextField}
                                        value={segmentName}
                                        onChange={handleSegmentNameChange}
                                        variant='outlined'
                                        InputProps={{
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                <>
                                                  {
                                                    segmentName !== ""
                                                        ? currentEmoji
                                                        : ""
                                                  }
                                                </>
                                              </InputAdornment>
                                          ),
                                        }}
                                    />
                                  </FieldsRow>
                                  <FieldsRow>
                                    <div>
                                      <Picker
                                          onEmojiClick={onEmojiClick}
                                          pickerStyle={{width: '100%'}}
                                          skinTone={SKIN_TONE_MEDIUM_DARK}
                                          groupNames={{smileys_people: 'PEOPLE'}}
                                          disableSearchBar={true}
                                          disableSkinTonePicker={true}
                                      />
                                    </div>
                                  </FieldsRow>
                                  <Button
                                      onClick={handleNameSegmentBackButtonClick}
                                      sx={{
                                        color: "#FF9933",
                                        fontSize: 16,
                                        fontWeight: "500",
                                        zIndex: 1,
                                        marginTop: 5,
                                        marginRight: 5
                                      }}
                                      variant="text"
                                  >
                                    BACK
                                  </Button>
                                  <Button
                                      onClick={handleNameSegmentNextClick}
                                      endIcon={<ChevronRightRoundedIcon
                                          sx={{
                                            fontSize: '80px',
                                            fontWeight: '400'
                                          }}/>}
                                      sx={{
                                        color: "#FF9933",
                                        fontSize: 16,
                                        fontWeight: "500",
                                        zIndex: 1,
                                        marginTop: 5,
                                      }}
                                      variant="text"
                                      disabled={segmentName === ''
                                          || currentEmoji === ''}
                                  >
                                    NEXT
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </NewSegementContainer>
                        </CreateSegementModalWrapper>
                      </div>
                    </Fade>
                  </Modal>
              )}

              {/*CONFIRM SEGMENT*/}
              {((openConfirmSegment === true)) && (
                  <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={openConfirmSegment}
                      onClose={handleCloseConfirmSegment}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                  >
                    <Fade in={openConfirmSegment}>
                      <div className={classes.paper}>
                        <ConfirmSegmentModalWrapper>
                          <img
                              alt="close"
                              onClick={handleCloseConfirmSegment}
                              className={"closeImg"}
                              src={require("assets/images/Group41.png")}
                          />
                          <TypographyText>
                            <Typography sx={{fontSize: 24}}>
                              Confirm Segment
                            </Typography>
                          </TypographyText>
                          <Box sx={{
                            marginTop: 10,
                            marginLeft: '15ch',
                            width: '100%',
                            maxWidth: 360,
                          }}>
                            <nav aria-label="main mailbox folders">
                              <List>
                                <ListItem disablePadding>
                                  <ListItemButton>
                                    <ListItemIcon>
                                      {chosenEmoji !== null ? `${currentEmoji}`
                                          : ''}
                                    </ListItemIcon>
                                    <ListItemText primary={segmentName !== null
                                        ? `${segmentName}` : ''}/>
                                  </ListItemButton>
                                </ListItem>
                              </List>
                            </nav>
                          </Box>
                          <Box sx={{
                            display: 'inline',
                            marginTop: 20,
                            maxWidth: 360,
                          }}>

                            {currentFilters &&
                                Object.values(currentFilters).map(
                                    (filter, index) => {
                                      return (
                                          <Chip
                                              key={uuid()}
                                              label={filterText(filter)}
                                              onDelete={() => handleFilterDelete(
                                                  index)}
                                              sx={{m: 1}}
                                          />
                                      );
                                    })}
                          </Box>
                          <Box sx={{
                            position: 'fixed',
                            display: 'block',
                            marginTop: 8,
                            marginLeft: '15ch',
                            maxWidth: 360,
                          }}>
                            <Grid item alignItems="stretch"
                                  style={{display: "flex"}}>
                              <Button
                                  onClick={handleConfirmSegmentBackButtonClick}
                                  startIcon={<ChevronLeftRoundedIcon
                                      sx={{
                                        fontSize: '80px',
                                        fontWeight: '400'
                                      }}/>}
                                  sx={{
                                    color: "#FF9933",
                                    fontSize: 16,
                                    fontWeight: "500",
                                    zIndex: 1,
                                    marginRight: 5
                                  }}
                                  variant="text"
                              >
                                BACK
                              </Button>
                              <Button
                                  onClick={onCreateOrUpdateSegment}
                                  endIcon={<CheckCircleOutlineIcon
                                      sx={{
                                        fontSize: '80px',
                                        fontWeight: '400'
                                      }}/>}
                                  sx={{
                                    color: "#FF9933",
                                    fontSize: 16,
                                    fontWeight: "500",
                                    zIndex: 1,
                                  }}
                                  variant="text"
                              >
                                CONFIRM
                              </Button>
                            </Grid>
                          </Box>
                        </ConfirmSegmentModalWrapper>
                      </div>
                    </Fade>
                  </Modal>
              )}

              {/*SEGMENT CREATED*/}
              {((openSegmentCreated === true) || (openSegmentUpdated === true))
                  && (
                      <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          className={classes.modal}
                          open={openSegmentCreated || openSegmentUpdated}
                          onClose={handleCloseSegmentCreated}
                          closeAfterTransition
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                            timeout: 500,
                          }}
                      >
                        <Fade in={openSegmentCreated || openSegmentUpdated}>
                          <div className={classes.paper}>
                            <ConfirmationSegmentCreated>
                              <img
                                  alt="group"
                                  onClick={handleCloseSegmentCreated}
                                  className={"closeImg"}
                                  src={group41Png}
                              />
                              <ImageDiv>
                                <HeaderImage>
                                  <CheckCircleOutlineIcon
                                      sx={{
                                        fontSize: 60,
                                        padding: 2.3,
                                        color: "#1DB350",
                                      }}
                                  />
                                </HeaderImage>
                              </ImageDiv>

                              {((openSegmentCreated === true) &&
                                  <>
                                    <TypographyTextSegmentCreated>
                                      Segment Created
                                    </TypographyTextSegmentCreated>
                                  </>

                              )}

                              {((openSegmentUpdated === true) &&
                                  <>
                                    <TypographyTextSegmentCreated>
                                      Segment Updated
                                    </TypographyTextSegmentCreated>
                                  </>

                              )}

                            </ConfirmationSegmentCreated>
                          </div>
                        </Fade>
                      </Modal>
                  )}

              {/*SEGMENT ALREADY EXIST*/}
              {((openSegmentAlreadyExist === true)) && (
                  <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={openSegmentAlreadyExist}
                      onClose={handleCloseSegmentAlreadyExist}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                  >
                    <Fade in={openSegmentAlreadyExist}>
                      <div className={classes.paper}>
                        <ConfirmationSegmentCreated>
                          <img
                              alt="group"
                              onClick={handleCloseSegmentAlreadyExist}
                              className={"closeImg"}
                              src={group41Png}
                          />
                          <ImageDiv>
                            <HeaderErrorAmber>
                              <WarningAmberRoundedIcon
                                  sx={{
                                    fontSize: 45,
                                    padding: 3,
                                    color: "#EA5454",
                                  }}
                              />
                            </HeaderErrorAmber>

                          </ImageDiv>
                          <TypographyTextSegmentCreated>
                            <Typography variant="h6" color="red">
                              {segmentName}
                            </Typography>
                            <br/>
                            Segment Already Exists!
                          </TypographyTextSegmentCreated>
                        </ConfirmationSegmentCreated>
                      </div>
                    </Fade>
                  </Modal>
              )}

              {/*SEGMENT CREATE ERROR*/}
              {((openError === true)) && (
                  <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={openError}
                      onClose={handleCloseError}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                  >
                    <Fade in={openError}>
                      <div className={classes.paper}>
                        <ConfirmationSegmentCreated>
                          <img
                              alt="group"
                              onClick={handleCloseError}
                              className={"closeImg"}
                              src={group41Png}
                          />
                          <ImageDiv>
                            <HeaderErrorAmber>
                              <WarningAmberRoundedIcon
                                  sx={{
                                    fontSize: 45,
                                    padding: 3,
                                    color: "#EA5454",
                                  }}
                              />
                            </HeaderErrorAmber>

                          </ImageDiv>
                          <TypographyTextSegmentCreated>
                            <Typography variant="h6" color="red">
                              Error Creating Segment
                            </Typography>
                          </TypographyTextSegmentCreated>
                        </ConfirmationSegmentCreated>
                      </div>
                    </Fade>
                  </Modal>
              )}

            </div>
        }
      </>
  );
};

export default compose(
    reduxForm({
      form: "CREATE_SEGMENT_MODAL_FORM",
    })
)(CreateSegmentModal);
