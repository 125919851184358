import React from "react";
import {Dialog, DialogContent, DialogContentText, DialogTitle,} from "@mui/material";
import {Action} from "components/Automation/types";
import ActionStepper from "./ActionStepper";

type Props = {
  organisationId: string
  addAction: (action: Action) => void
  updateAction: (action: Action) => void
  action?: Action
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  templateIds: string[]
}

const EditActionDialogStepper = ({
                                   organisationId,
                                   action,
                                   addAction,
                                   updateAction,
                                   open,
                                   setOpen,
                                   templateIds
                                 }: Props) => {

  const saveAction = (newAction: Action) => {
    action ? updateAction(newAction) : addAction(newAction);
    setOpen(false);
  }

  return (
      <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">
          {action ? 'Edit' : 'Add'} Actions
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ActionStepper
                organisationId={organisationId}
                action={action}
                saveAction={saveAction}
                cancel={() => setOpen(false)}
                templateIds={templateIds}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
  );
}

export default EditActionDialogStepper;
