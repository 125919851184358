import React from "react";
import styled from "@emotion/styled";
import {ReactComponent as FacebookIcon} from "assets/images/roundFacebook.svg";
import {ReactComponent as MailIcon} from "assets/images/roundMail.svg";

const OtherLoginButtonsWrapper = styled.div``;

const LoginButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  object-fit: fill;
  margin-top: 12px;

  .secondary-social-media-button {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 10px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      object-fit: contain;
      height: 28px;
      width: 28px;
    }
  }
`;

const OtherLoginButtons = (props) => {
  const {settings} = props;

  const defaultLoginSubTitle = settings?.loginSubtitle
      ? settings?.loginSubtitle
      : 'Or login through...'

  const isSelected = (socialMedia) => {
    return settings?.preferredSocialMedia !== socialMedia
  }

  return (
      settings?.preferredSocialMedia !== 'Default' && (
          <OtherLoginButtonsWrapper>
            <hr className="content-divider"/>
            <label
                style={{
                  color: settings?.textcolor ? settings?.textcolor : 'black',
                  marginTop: "10px",
                  textAlign: "center"
                }}
            >
              {
                settings?.loginSubtitle
                    ? settings?.loginSubtitle
                    : defaultLoginSubTitle
              }
            </label>
            <LoginButtonsWrapper>
              {/*{isSelected(SocialMedia.Linkedin) && (*/}
              {/*    <button*/}
              {/*        className="secondary-social-media-button__linkedin">*/}
              {/*      <LinkedInLogo*/}
              {/*          className="secondary-social-media-button__icon"/>*/}
              {/*    </button>*/}
              {/*)}*/}
              {/*{isSelected(SocialMedia.Twitter) && (*/}
              {/*    <button*/}
              {/*        className="secondary-social-media-button__twitter">*/}
              {/*      <TwitterLogo*/}
              {/*          className="secondary-social-media-button__icon"/>*/}
              {/*    </button>*/}
              {/*)}*/}
              {isSelected('Email') && (
                  <button className="secondary-social-media-button__mail">
                    <MailIcon
                        className="secondary-social-media-button__icon"/>
                  </button>
              )}
              {isSelected('Facebook') && (
                  <button
                      className="secondary-social-media-button__facebook">
                    <FacebookIcon
                        className="secondary-social-media-button__icon"/>
                  </button>
              )}
            </LoginButtonsWrapper>
          </OtherLoginButtonsWrapper>
      )
  );
};

export default OtherLoginButtons;
