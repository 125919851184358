import React from "react";

import moment from "moment";

import CampaignCardHeader from "./CampaignCardHeader";
import PreviewTemplate from "./PreviewTemplate";

const CampaignCard = (props) => {
  const { campaign } = props;

  // const [isItScheduled, setIsItScheduled] = React.useState(false);

  return (
    campaign && (
      <div className="mec-campaign" key={campaign?.externalId}>
        <div className="mecc-content">
          <CampaignCardHeader campaignName={campaign?.name}></CampaignCardHeader>

          <label className="mecc-info">
            <span>Subject: </span>
            {campaign?.subject}
          </label>

          <label className="mecc-info">
            <span>Schedules: </span>
            {campaign?.schedules?.length}
          </label>

          <label className="mecc-info">
            <span>Created on: </span>
            {moment.unix(campaign?.createdOn).format("DD MMMM YYYY")}
          </label>

          {/* <label className="mecc-info">
            <span>Status: </span>Sent on 15/11/2020
          </label> */}
          {/* <div className="mecc-actions">
            <div className="mecc-action">
              <label>Edit</label>
              <EditIcon className="icon" />
            </div>
          </div> */}
        </div>

        <PreviewTemplate
          templateContent={campaign?.messageBody}
        ></PreviewTemplate>
      </div>
    )
  );
};

export default CampaignCard;
