/**
 * Logging Amplitude Middleware
 *
 * Logs all Amplitude Events
 *
 * @param payload
 * @param next
 */
export const loggingMiddleware = (payload, next) => {
  next(payload);
};

export default loggingMiddleware;
