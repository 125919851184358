import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
:root{

  //COLOURS

--unaro-red:#EA5454;
--unaro-yellow:#FFCC33;
--unaro-orange:#FF9933;
--unaro-light-grey:#F2F2F2;
--unaro-med-grey:#C4C4C4;
--unaro-dark-grey:#5B5B5B;


  //DIMENSIONS
  --topbar-height:70px;
  --side-bar-width:17%;
  --side-cards-width:26%;
  --page-content-width:58%;


  /* --greet-user-height:70px;

  --page-height: calc(100vh - var(--topbar-height) );

  --pages-margin-top: calc(var( --greet-user-height) + 75px);

  --cards-padding-med: 30px; */

  




 
}
`;

export default GlobalStyles;
