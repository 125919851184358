import {
  SELECT_LIST_TYPE_REQUEST,
  SELECT_LIST_TYPE_FAILURE,
  SELECT_LIST_TYPE_SUCCESS,
  SET_CURRENT_PAGINATION_REQUEST,
  SET_CURRENT_PAGINATION_FAILURE,
  SET_CURRENT_PAGINATION_SUCCESS,
  selectListTypeRequest,
  selectListTypeFailure,
  selectListTypeSuccess,
  setCurrentPaginationRequest,
  setCurrentPaginationFailure,
  setCurrentPaginationSuccess,
} from "./miscActions";

// import * as miscActions from  './miscActions'
import * as importContactsListActions from "./importContactsList";

import {
  GET_ALL_CONTACTS_REQUEST,
  GET_ALL_CONTACTS_FAILURE,
  GET_ALL_CONTACTS_SUCCESS,
  TOGGLE_CONTACT_REQUEST,
  TOGGLE_CONTACT_FAILURE,
  TOGGLE_CONTACT_SUCCESS,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_FAILURE,
  TOGGLE_ALL_CONTACTS_SUCCESS,
  TOGGLE_ALL_CONTACTS_REQUEST,
  TOGGLE_ALL_CONTACTS_FAILURE,
  IMPORT_CONTACTS_SUCCESS,
  IMPORT_CONTACTS_REQUEST,
  IMPORT_CONTACTS_FAILURE,
  DELETE_CONTACTS_SUCCESS,
  DELETE_CONTACTS_REQUEST,
  DELETE_CONTACTS_FAILURE,
  getAllContactsRequest,
  getAllContactsFailure,
  getAllContactsSuccess,
  toggleContactRequest,
  toggleContactFailure,
  toggleContactSuccess,
  createContactRequest,
  createContactFailure,
  createContactSuccess,
  toggleAllContactsRequest,
  toggleAllContactsFailure,
  toggleAllContactsSuccess,
  importContactsRequest,
  importContactsFailure,
  importContactsSuccess,
  deleteContactsRequest,
  deleteContactsFailure,
  deleteContactsSuccess,
} from "./contactsActions";

import {
  GET_SEGMENTS_REQUEST,
  GET_SEGMENTS_FAILURE,
  GET_SEGMENTS_SUCCESS,
  SELECT_SEGMENT_REQUEST,
  SELECT_SEGMENT_FAILURE,
  SELECT_SEGMENT_SUCCESS,
  GET_SEGMENT_CONTACTS_REQUEST,
  GET_SEGMENT_CONTACTS_FAILURE,
  GET_SEGMENT_CONTACTS_SUCCESS,
  TOGGLE_SEGMENT_CONTACT_REQUEST,
  TOGGLE_SEGMENT_CONTACT_FAILURE,
  TOGGLE_SEGMENT_CONTACT_SUCCESS,
  TOGGLE_ALL_SEGMENT_CONTACTS_REQUEST,
  TOGGLE_ALL_SEGMENT_CONTACTS_FAILURE,
  TOGGLE_ALL_SEGMENT_CONTACTS_SUCCESS,
  CREATE_SEGMENT_REQUEST,
  CREATE_SEGMENT_FAILURE,
  CREATE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_REQUEST,
  DELETE_SEGMENT_FAILURE,
  DELETE_SEGMENT_SUCCESS,
  getSegmentsRequest,
  getSegmentsFailure,
  getSegmentsSuccess,
  selectSegmentRequest,
  selectSegmentFailure,
  selectSegmentSuccess,
  getSegmentContactsRequest,
  getSegmentContactsFailure,
  getSegmentContactsSuccess,
  toggleSegmentContactRequest,
  toggleSegmentContactFailure,
  toggleSegmentContactSuccess,
  createSegmentRequest,
  createSegmentFailure,
  createSegmentSuccess,
  deleteSegmentRequest,
  deleteSegmentFailure,
  deleteSegmentSuccess,
  toggleAllSegmentContactsRequest,
  toggleAllSegmentContactsFailure,
  toggleAllSegmentContactsSuccess,
} from "./segmentsActions";

import {
  GET_AUDIENCES_REQUEST,
  GET_AUDIENCES_FAILURE,
  GET_AUDIENCES_SUCCESS,
  SELECT_AUDIENCE_REQUEST,
  SELECT_AUDIENCE_FAILURE,
  SELECT_AUDIENCE_SUCCESS,
  GET_AUDIENCE_CONTACTS_REQUEST,
  GET_AUDIENCE_CONTACTS_FAILURE,
  GET_AUDIENCE_CONTACTS_SUCCESS,
  TOGGLE_AUDIENCE_CONTACT_REQUEST,
  TOGGLE_AUDIENCE_CONTACT_FAILURE,
  TOGGLE_AUDIENCE_CONTACT_SUCCESS,
  TOGGLE_ALL_AUDIENCE_CONTACTS_REQUEST,
  TOGGLE_ALL_AUDIENCE_CONTACTS_FAILURE,
  TOGGLE_ALL_AUDIENCE_CONTACTS_SUCCESS,
  CREATE_AUDIENCE_REQUEST,
  CREATE_AUDIENCE_FAILURE,
  CREATE_AUDIENCE_SUCCESS,
  getAudiencesRequest,
  getAudiencesFailure,
  getAudiencesSuccess,
  selectAudienceRequest,
  selectAudienceFailure,
  selectAudienceSuccess,
  getAudienceContactsRequest,
  getAudienceContactsFailure,
  getAudienceContactsSuccess,
  toggleAudienceContactRequest,
  toggleAudienceContactFailure,
  toggleAudienceContactSuccess,
  createAudienceRequest,
  createAudienceFailure,
  createAudienceSuccess,
  toggleAllAudienceContactsRequest,
  toggleAllAudienceContactsFailure,
  toggleAllAudienceContactsSuccess,
} from "./audiencesActions";

import {
  ADD_FILTER_REQUEST,
  ADD_FILTER_FAILURE,
  ADD_FILTER_SUCCESS,
  REMOVE_FILTER_REQUEST,
  REMOVE_FILTER_FAILURE,
  REMOVE_FILTER_SUCCESS,
  addFilterRequest,
  addFilterFailure,
  addFilterSuccess,
  removeFilterRequest,
  removeFilterFailure,
  removeFilterSuccess,
} from "./filtersActions";

const actions = {
  GET_ALL_CONTACTS_REQUEST,
  GET_ALL_CONTACTS_FAILURE,
  GET_ALL_CONTACTS_SUCCESS,
  TOGGLE_CONTACT_REQUEST,
  TOGGLE_CONTACT_FAILURE,
  TOGGLE_CONTACT_SUCCESS,
  getAllContactsRequest,
  getAllContactsFailure,
  getAllContactsSuccess,
  toggleContactRequest,
  toggleContactFailure,
  toggleContactSuccess,
  SELECT_LIST_TYPE_REQUEST,
  SELECT_LIST_TYPE_FAILURE,
  SELECT_LIST_TYPE_SUCCESS,
  SET_CURRENT_PAGINATION_REQUEST,
  SET_CURRENT_PAGINATION_FAILURE,
  SET_CURRENT_PAGINATION_SUCCESS,
  GET_SEGMENT_CONTACTS_REQUEST,
  GET_SEGMENT_CONTACTS_FAILURE,
  GET_SEGMENT_CONTACTS_SUCCESS,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_FAILURE,
  CREATE_SEGMENT_REQUEST,
  CREATE_SEGMENT_FAILURE,
  CREATE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_REQUEST,
  DELETE_SEGMENT_FAILURE,
  DELETE_SEGMENT_SUCCESS,
  selectListTypeRequest,
  selectListTypeFailure,
  selectListTypeSuccess,
  setCurrentPaginationRequest,
  setCurrentPaginationFailure,
  setCurrentPaginationSuccess,
  GET_SEGMENTS_REQUEST,
  GET_SEGMENTS_FAILURE,
  GET_SEGMENTS_SUCCESS,
  SELECT_SEGMENT_REQUEST,
  SELECT_SEGMENT_FAILURE,
  SELECT_SEGMENT_SUCCESS,
  TOGGLE_SEGMENT_CONTACT_REQUEST,
  TOGGLE_SEGMENT_CONTACT_FAILURE,
  TOGGLE_SEGMENT_CONTACT_SUCCESS,
  TOGGLE_ALL_CONTACTS_SUCCESS,
  TOGGLE_ALL_CONTACTS_REQUEST,
  TOGGLE_ALL_CONTACTS_FAILURE,
  TOGGLE_ALL_SEGMENT_CONTACTS_REQUEST,
  TOGGLE_ALL_SEGMENT_CONTACTS_FAILURE,
  TOGGLE_ALL_SEGMENT_CONTACTS_SUCCESS,
  getSegmentsRequest,
  getSegmentsFailure,
  getSegmentsSuccess,
  toggleSegmentContactRequest,
  toggleSegmentContactFailure,
  toggleSegmentContactSuccess,
  selectSegmentRequest,
  selectSegmentFailure,
  selectSegmentSuccess,
  getSegmentContactsRequest,
  getSegmentContactsFailure,
  getSegmentContactsSuccess,
  createContactRequest,
  createContactFailure,
  createContactSuccess,
  GET_AUDIENCES_REQUEST,
  GET_AUDIENCES_FAILURE,
  GET_AUDIENCES_SUCCESS,
  SELECT_AUDIENCE_REQUEST,
  SELECT_AUDIENCE_FAILURE,
  SELECT_AUDIENCE_SUCCESS,
  GET_AUDIENCE_CONTACTS_REQUEST,
  GET_AUDIENCE_CONTACTS_FAILURE,
  GET_AUDIENCE_CONTACTS_SUCCESS,
  TOGGLE_AUDIENCE_CONTACT_REQUEST,
  TOGGLE_AUDIENCE_CONTACT_FAILURE,
  TOGGLE_AUDIENCE_CONTACT_SUCCESS,
  CREATE_AUDIENCE_REQUEST,
  CREATE_AUDIENCE_FAILURE,
  CREATE_AUDIENCE_SUCCESS,
  getAudiencesRequest,
  getAudiencesFailure,
  getAudiencesSuccess,
  selectAudienceRequest,
  selectAudienceFailure,
  selectAudienceSuccess,
  getAudienceContactsRequest,
  getAudienceContactsFailure,
  getAudienceContactsSuccess,
  toggleAudienceContactRequest,
  toggleAudienceContactFailure,
  toggleAudienceContactSuccess,
  createAudienceRequest,
  createAudienceFailure,
  createAudienceSuccess,
  ADD_FILTER_REQUEST,
  ADD_FILTER_FAILURE,
  ADD_FILTER_SUCCESS,
  REMOVE_FILTER_REQUEST,
  REMOVE_FILTER_FAILURE,
  REMOVE_FILTER_SUCCESS,
  TOGGLE_ALL_AUDIENCE_CONTACTS_REQUEST,
  TOGGLE_ALL_AUDIENCE_CONTACTS_FAILURE,
  TOGGLE_ALL_AUDIENCE_CONTACTS_SUCCESS,
  IMPORT_CONTACTS_SUCCESS,
  IMPORT_CONTACTS_REQUEST,
  IMPORT_CONTACTS_FAILURE,
  DELETE_CONTACTS_SUCCESS,
  DELETE_CONTACTS_REQUEST,
  DELETE_CONTACTS_FAILURE,
  addFilterRequest,
  addFilterFailure,
  addFilterSuccess,
  removeFilterRequest,
  removeFilterFailure,
  removeFilterSuccess,
  createSegmentRequest,
  createSegmentFailure,
  createSegmentSuccess,
  deleteSegmentRequest,
  deleteSegmentFailure,
  deleteSegmentSuccess,
  toggleAllContactsRequest,
  toggleAllContactsFailure,
  toggleAllContactsSuccess,
  toggleAllAudienceContactsRequest,
  toggleAllAudienceContactsFailure,
  toggleAllAudienceContactsSuccess,
  toggleAllSegmentContactsRequest,
  toggleAllSegmentContactsFailure,
  toggleAllSegmentContactsSuccess,
  importContactsRequest,
  importContactsFailure,
  importContactsSuccess,
  deleteContactsRequest,
  deleteContactsFailure,
  deleteContactsSuccess,

  ...importContactsListActions,
};

export default actions;
