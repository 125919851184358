import React from "react";

import { ReactComponent as CloseIcon } from "assets/images/delete.svg";
import { ReactComponent as DoneIcon } from "assets/images/done.svg";

import DefaultButton from "components/buttons/DefaultButton";

import "assets/css/send/create/done-popup.scss";

export default (props) => {
  const { text, onClick } = props;
  return (
    <div className="done-popup" onClick={onClick}>
      <div className="done-popup-content">
        <>
          <CloseIcon className="close-btn" onClick={onClick} />
          <label className="title">{text}</label>
          <DoneIcon className="icon" />

          <DefaultButton
            size="medium"
            title="Go Back To Dashboard!"
            onClick={onClick}
          />
        </>
      </div>
    </div>
  );
};
