import React from "react";
import styled from "@emotion/styled";
import { formValues } from "redux-form";

import SendButton from "./SendButton";

import TestButton from "./TestButton";

const ButtonsPanelWrapper = styled.div`
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  margin: 0 auto;
`;

const ButtonsPanel = (props) => {
  const {
    campaignFrom,
    campaignPreviewText,
    campaignSchedule,
    campaignSubject,
    currentOrganisationEmails,
    activeLicenses
  } = props;

  const [isEnabled, setIsEnabled] = React.useState(false);

  React.useEffect(() => {
    if (!campaignFrom) {
      setIsEnabled(false);
      return;
    }

    if (!campaignSubject) {
      setIsEnabled(false);
      return;
    }

    if (!campaignPreviewText) {
      setIsEnabled(false);
      return;
    }

    if (
      (campaignSchedule.active === true && !campaignSchedule.date) ||
      (campaignSchedule.active === true && !campaignSchedule.time)
    ) {
      setIsEnabled(false);
      return;
    }

    setIsEnabled(true);
  }, [props]);

  return (
    <ButtonsPanelWrapper>
      <TestButton
        {...props}
        title={"Send Test Email"}
        isEnabled={isEnabled}
      ></TestButton>

      <SendButton
        {...props}
        title={"Send Email"}
        isEnabled={isEnabled}
        currentOrganisationEmails={currentOrganisationEmails}
        activeLicenses={activeLicenses}
      ></SendButton>
    </ButtonsPanelWrapper>
  );
};

export default formValues(
  "campaignFrom",
  "campaignSubject",
  "campaignPreviewText",
  "campaignSchedule",
  "campaignSendToTest"
)(ButtonsPanel);
