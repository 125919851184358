import React from 'react'

import 'assets/css/widget/switch-btn.scss'

export default ({ active, setActive }) => {
    return (
        <div className={`switch-btn ${active && 'active-switch'}`} onClick={() => setActive(!active)}>
            <div className='switch' />
        </div>
    )
}