import React from "react";
import styled from "@emotion/styled";

const TitleWrapper = styled.label`
  label {
    color: white;
    font-weight: 400;
    font-size: 24px;
    font-family: Roboto;
  }
`;

const Title = ({ title }) => {
  return (
    <TitleWrapper>
      <label>{title}</label>
    </TitleWrapper>
  );
};

export default Title;
