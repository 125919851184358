export const GET_VISITS_AND_SIGNUPS_REQUEST = "GET_VISITS_AND_SIGNUPS_REQUEST";
export const GET_VISITS_AND_SIGNUPS_FAILURE = "GET_VISITS_AND_SIGNUPS_FAILURE";
export const GET_VISITS_AND_SIGNUPS_SUCCESS = "GET_VISITS_AND_SIGNUPS_SUCCESS";

export const GET_YEAR_VISITS_AND_SIGNUPS_REQUEST =
  "GET_YEAR_VISITS_AND_SIGNUPS_REQUEST";
export const GET_YEAR_VISITS_AND_SIGNUPS_FAILURE =
  "GET_YEAR_VISITS_AND_SIGNUPS_FAILURE";
export const GET_YEAR_VISITS_AND_SIGNUPS_SUCCESS =
  "GET_YEAR_VISITS_AND_SIGNUPS_SUCCESS";

export const GET_MONTH_VISITS_AND_SIGNUPS_REQUEST =
  "GET_MONTH_VISITS_AND_SIGNUPS_REQUEST";
export const GET_MONTH_VISITS_AND_SIGNUPS_FAILURE =
  "GET_MONTH_VISITS_AND_SIGNUPS_FAILURE";
export const GET_MONTH_VISITS_AND_SIGNUPS_SUCCESS =
  "GET_MONTH_VISITS_AND_SIGNUPS_SUCCESS";

export const GET_WEEK_VISITS_AND_SIGNUPS_REQUEST =
  "GET_WEEK_VISITS_AND_SIGNUPS_REQUEST";
export const GET_WEEK_VISITS_AND_SIGNUPS_FAILURE =
  "GET_WEEK_VISITS_AND_SIGNUPS_FAILURE";
export const GET_WEEK_VISITS_AND_SIGNUPS_SUCCESS =
  "GET_WEEK_VISITS_AND_SIGNUPS_SUCCESS";

export const GET_DAY_VISITS_AND_SIGNUPS_REQUEST =
  "GET_DAY_VISITS_AND_SIGNUPS_REQUEST";
export const GET_DAY_VISITS_AND_SIGNUPS_FAILURE =
  "GET_DAY_VISITS_AND_SIGNUPS_FAILURE";
export const GET_DAY_VISITS_AND_SIGNUPS_SUCCESS =
  "GET_DAY_VISITS_AND_SIGNUPS_SUCCESS";

//   export const GET_HOURLY_VISITS_AND_SIGNUPS_REQUEST =
//   "GET_HOURLY_VISITS_AND_SIGNUPS_REQUEST";
// export const GET_HOURLY_VISITS_AND_SIGNUPS_FAILURE =
//   "GET_HOURLY_VISITS_AND_SIGNUPS_FAILURE";
// export const GET_HOURLY_VISITS_AND_SIGNUPS_SUCCESS =
//   "GET_HOURLY_VISITS_AND_SIGNUPS_SUCCESS";

export const getAllVisitsAndSignupsRequest = (organisationId, timeUnit) => ({
  type: GET_VISITS_AND_SIGNUPS_REQUEST,
  payload: {
    organisationId,
    timeUnit,
  },
});

export const getAllVisitsAndSignupsFailure = (errorMessage) => ({
  type: GET_VISITS_AND_SIGNUPS_FAILURE,
  payload: errorMessage,
});

export const getAllVisitsAndSignupsSuccess = (visitsAndSignups) => ({
  type: GET_VISITS_AND_SIGNUPS_SUCCESS,
  payload: visitsAndSignups,
});

//

export const getYearVisitsAndSignupsRequest = (organisationId) => ({
  type: GET_YEAR_VISITS_AND_SIGNUPS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getYearVisitsAndSignupsFailure = (errorMessage) => ({
  type: GET_YEAR_VISITS_AND_SIGNUPS_FAILURE,
  payload: errorMessage,
});

export const getYearVisitsAndSignupsSuccess = (yearVisitsAndSignups) => ({
  type: GET_YEAR_VISITS_AND_SIGNUPS_SUCCESS,
  payload: yearVisitsAndSignups,
});

//

export const getMonthVisitsAndSignupsRequest = (organisationId) => ({
  type: GET_MONTH_VISITS_AND_SIGNUPS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getMonthVisitsAndSignupsFailure = (errorMessage) => ({
  type: GET_MONTH_VISITS_AND_SIGNUPS_FAILURE,
  payload: errorMessage,
});

export const getMonthVisitsAndSignupsSuccess = (monthVisitsAndSignups) => ({
  type: GET_MONTH_VISITS_AND_SIGNUPS_SUCCESS,
  payload: monthVisitsAndSignups,
});

//

export const getWeekVisitsAndSignupsRequest = (organisationId) => ({
  type: GET_WEEK_VISITS_AND_SIGNUPS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getWeekVisitsAndSignupsFailure = (errorMessage) => ({
  type: GET_WEEK_VISITS_AND_SIGNUPS_FAILURE,
  payload: errorMessage,
});

export const getWeekVisitsAndSignupsSuccess = (weekVisitsAndSignups) => ({
  type: GET_WEEK_VISITS_AND_SIGNUPS_SUCCESS,
  payload: weekVisitsAndSignups,
});

//

export const getDayVisitsAndSignupsRequest = (organisationId) => ({
  type: GET_DAY_VISITS_AND_SIGNUPS_REQUEST,
  payload: {
    organisationId,
  },
});

export const getDayVisitsAndSignupsFailure = (errorMessage) => ({
  type: GET_DAY_VISITS_AND_SIGNUPS_FAILURE,
  payload: errorMessage,
});

export const getDayVisitsAndSignupsSuccess = (dayVisitsAndSignups) => ({
  type: GET_DAY_VISITS_AND_SIGNUPS_SUCCESS,
  payload: dayVisitsAndSignups,
});

// HOURLY

// export const getHourlyVisitsAndSignupsRequest = (organisationId) => ({
//   type: GET_HOURLY_VISITS_AND_SIGNUPS_REQUEST,
//   payload: {
//     organisationId,
//   },
// });

// export const getHourlyVisitsAndSignupsFailure = (errorMessage) => ({
//   type: GET_HOURLY_VISITS_AND_SIGNUPS_FAILURE,
//   payload: errorMessage,
// });

// export const getHourlyVisitsAndSignupsSuccess = (hourlyVisitsAndSignups) => ({
//   type: GET_HOURLY_VISITS_AND_SIGNUPS_SUCCESS,
//   payload: hourlyVisitsAndSignups,
// });
