import React from 'react'

import 'assets/css/sms/contact/remove-popup.scss'

export default ({ title, text, remove, close }) => {

    const save = async () => {
        await remove()
    }

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    return (
        <div className='remove-popup' onClick={onClick}>
            <div className='remove-popup-content'>
                <div className='remove-top'>
                    <label>{title}</label>
                    <img alt="delete" src={require('assets/images/delete.svg').default} onClick={close} />
                </div>
                <div className='remove-content'>
                    <div className='remove-text'>{text}</div>
                    <div className='remove-actions'>
                        <button onClick={close}>Cancel</button>
                        <button onClick={save}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    )
}