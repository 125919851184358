import React from "react";
import ContactsList from "components/ContactsList";
import "components/customers/customers.css";
import "assets/css/customers/customers-page.scss";

const SendAudience = (props) => {
  const {organisationId, setCurrentCampaignContacts} = props;

  return (
      <ContactsList
          {...props}
          updateCampaign={true}
          className="contacts-list"
          organisationId={organisationId}
          updateExternalContacts={setCurrentCampaignContacts}
      />
  );
};

export default SendAudience;
