import React, { useState, useEffect } from 'react'

import { useHistory, useLocation } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Loader from 'react-loader-spinner'

import api from '../utils/api'
import AddPaymentMethodForm from '../components/billing/addPaymentMethodForm'
import PaymentDone from '../components/billing/paymentDone'

import '../assets/css/price-plans.scss'

export default () => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
    const history = useHistory()
    const location = useLocation()

    const productId = new URLSearchParams(location.search).get('productId')

    const user = JSON.parse(localStorage.getItem('user'))
    const organisationId = user.organisations[0].externalId
    const [step, setStep] = useState(0)
    const [plan, setPlan] = useState()
    const [error, setError] = useState()
    const [organisationState, setOrganisationState] = useState('PENDING')
    const [secret, setSecret] = useState()
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            if (productId && organisationState === 'ACTIVE') {
                const { data } = await api.get(`/products/${productId}`)
                setPlan(data)
            }
        }
        fetchData()
    }, [productId, organisationState])

    useEffect(() => {
        const fetchData = async () => {
            if (productId) {
                const { data } = await api.get(`/organisations/${organisationId}/state`)
                setOrganisationState(data.lifecycle)
                if (data.lifecycle !== 'ACTIVE')
                    setTimeout(async () => {
                        if (counter < 100)
                            setCounter(counter + 1)
                    }, 1000)
            }
        }
        fetchData()
    }, [organisationId, counter])

    useEffect(() => {
        const fetchData = async () => {
            if (productId && organisationState === 'ACTIVE') {
                const { data } = await api.post(`/organisation/${organisationId}/paymentmethods`)
                setSecret(data.token)
            }
        }
        fetchData()
    }, [productId, organisationState])

    const sendOrder = async response => {
        if (!response.error) {
            api.put(`/organisation/${organisationId}/paymentmethod/${response.setupIntent.payment_method}/default`)
            await api.post(`/organisation/${organisationId}/order`,
                {
                    productId: plan.id,
                    productName: plan.name,
                    amount: plan.price,
                    currency: plan.currency,
                    term: plan.term,
                    productType: plan.productType,
                    paymentMethodId: response.setupIntent.payment_method
                })
        } else
            setError(response.error.code)

        setStep(1)
    }

    const retry = () => {
        setError(null)
        setStep(0)
    }

    const getFirstPaymentDay = () => {
        var date = new Date();
        date.setDate(date.getDate() + plan.trialDays);
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    }

    return (
        <div className='price-plans'>
            { plan ?
                <div className='price-plans-content' style={{ padding: step === 0 ? '30px 20px' : '50px' }}>
                    {

                        // step === 0 ?
                        //     <div className='price-plan-details'>
                        //         <div className='ppd-images'>
                        //             <div className='ppd-image'>
                        //                 <img className='device-icon' src={require('../assets/images/device-icon.png').default} />
                        //                 <img className='products-icon' src={require('../assets/images/unaro-products.png').default} />
                        //             </div>
                        //         </div>
                        //         <label className='ppd-title'>Sign up to <span>{plan.trialDays} days Free Trial</span></label>
                        //         <label className='ppd-text'>£{plan.price} a month after renewal. <span>Package Includes:</span></label>
                        //         <div className='pp-products'>
                        //             <div className='pp-product'>
                        //                 <img src={require('../assets/images/connect-icon.svg').default} />
                        //                 <label className='pp-product-name'>Connect</label>
                        //                 <div className='plan-features'>
                        //                     {
                        //                         Object.entries(plan.features).map(([key, feature]) =>
                        //                             <div className='plan-feature'>
                        //                                 <img src={require('../assets/images/plan-item-icon.svg').default} />
                        //                                 <label>{feature}</label>
                        //                             </div>
                        //                         )
                        //                     }
                        //                 </div>
                        //             </div>
                        //             <div className='pp-product'>
                        //                 <img src={require('../assets/images/sms-icon.svg').default} />
                        //                 <label className='pp-product-name'>SMS</label>
                        //                 <div className='plan-features'>
                        //                     {
                        //                         Object.entries(plan.smsFeatures).map(([key, feature]) =>
                        //                             <div className='plan-feature'>
                        //                                 <img src={require('../assets/images/plan-item-icon.svg').default} />
                        //                                 <label>{feature}</label>
                        //                             </div>
                        //                         )
                        //                     }
                        //                 </div>
                        //             </div>
                        //             <div className='pp-product'>
                        //                 <img src={require('../assets/images/send-icon.svg').default} />
                        //                 <label className='pp-product-name'>Send</label>
                        //                 <label className='cs-label'>Coming Soon...</label>
                        //             </div>
                        //         </div>
                        //         <div className='continue-btn' onClick={next}>
                        //             <label>Continue</label>
                        //             <img src={require('../assets/images/continue-icon.svg').default} />
                        //         </div>
                        //     </div>
                        //     :
                        step === 0 ?
                            <div className='add-payment-method'>
                                <div className='apm-top'>
                                    <label className='apm-title'>Please enter payment details below for <span>{plan.name}</span></label>
                                    <label className='apm-subtitle'>No payment will be taken until {getFirstPaymentDay()}</label>
                                </div>
                                <Elements stripe={stripePromise}>
                                    <AddPaymentMethodForm close={() => { }} save={sendOrder} secret={secret} hideCancel={true} />
                                </Elements>
                            </div>
                            :
                            <PaymentDone
                                email={user.email}
                                error={error}
                                type={plan.productType}
                                successTitle='Welcome to Unaro!'
                                errorTitle='Failed'
                                text={<label className='pd-subtitle'>Your 30 day free trial has now begun. Enjoy using your new<br />marketing solution! Your first payment will be taken 30 days from<br />today on <span>{getFirstPaymentDay()}</span> and will occur monthly after that.</label>}
                                close={() => { }}
                                retry={retry}
                                finishBtnText='Begin'
                                finish={() => history.replace('/dashboard')}
                            />
                    }
                </div>
                :
                <div className='plan-loading'>
                    <Loader
                        type="Oval"
                        color="#EA5459"
                        height={30}
                        width={30}
                    />
                </div>
            }
        </div>
    )
}