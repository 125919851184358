import React from "react";
import styled from "@emotion/styled";

const MonthSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;
  margin-bottom: 0px;

  .month {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    color: #5b5b5b;
  }

  img {
    cursor: pointer;
  }
`;

const MonthSelector = (props) => {
  const {setValue, value} = props;
  return (
      <MonthSelectorWrapper>
        <img
            alt="left"
            src={require("assets/images/left.svg").default}
            onClick={() => setValue(value.subtract(1, "month").clone())}
        />
        <label className="month">{value.format("MMMM")}</label>
        <img
            alt="right"
            src={require("assets/images/right.svg").default}
            onClick={() => setValue(value.add(1, "month").clone())}
        />
      </MonthSelectorWrapper>
  );
};

export default MonthSelector