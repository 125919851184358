import styled from "@emotion/styled";

const SendNewEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;


  .cec-content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    box-sizing: border-box;
  }
`;

export default SendNewEmailWrapper;
