import api from "utils/api";

export const apiGetVisitsAndSignups = (data) => {
  const { organisationId, timeUnit, startDate, endDate } = data;

  const route = `/organisation/${organisationId}/analytics?unit=${timeUnit}&startDate=${startDate}&endDate=${endDate}`;

  // const route = `/organisation/8daf3f88-00b4-4f83-b49c-01c7801df2c6/analytics?unit=${timeUnit}&startDate=${startDate}&endDate=${endDate}`;
  // const route = `/organisation/eadf1637-85e3-4cbc-a1e4-0fb86ef4b83c/analytics?unit=${timeUnit}&startDate=${startDate}&endDate=${endDate}`;

  return api.get(route);
};

