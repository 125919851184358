import React from "react";
import SwitchBtn from "components/widget/switchBtn";
import {ReactComponent as FacebookIcon} from "assets/images/roundFacebook.svg";
import {ReactComponent as MailIcon} from "assets/images/roundMail.svg";
import {SocialMedia} from "../../../../../../containers/Connect/types";
import "assets/css/design/edit-login.scss";

const EditLogin = (props) => {
  const {settings, updateSettings} = props;

  const toggle = (key: string) => {
    const anySelected = !settings?.loginOptions[key] ||
        Object.keys(settings?.loginOptions)
        .filter((k: string) => settings?.loginOptions[k])
            .length > 1;
    if (anySelected && settings.preferredSocialMedia !== SocialMedia[key]) {
      updateSettings(`loginOptions.${key}`, !settings?.loginOptions[key]);
    }
  }

  return (
      <div className="edit-login">
        <label className="edit-login__title">Text Fields</label>
        <div className="edit-login__text-fields">
          <div className="edit-login__input-container">
            <label>Main Title: </label>
            <input
                maxLength="65"
                defaultValue={settings?.loginMainTitle}
                onChange={(e) => {
                  updateSettings("loginMainTitle", e.target.value);
                }}
                className="edit-login__input"
            />
          </div>
          {(settings?.preferredSocialMedia == null
                  || settings?.preferredSocialMedia
                  !== SocialMedia.Default)
              && (
                  <div draggable={true} className="edit-login__input-container">
                    <label>SubTitle: </label>
                    <input
                        defaultValue={settings?.loginSubtitle}
                        onChange={(e) => {
                          updateSettings("loginSubtitle", e.target.value)
                        }}
                        className="edit-login__input"
                    />
                  </div>
              )}
        </div>
        <hr/>
        <label className="edit-login__title">Login Options</label>
        <div className="edit-login__social-switches">
          <div className="edit-login__media-switch">
            <div
                style={{
                  height: 35,
                  width: 35,
                  background: "#3b5998",
                  borderRadius: "50%",
                }}
            >
              <FacebookIcon className="secondary-social-media-button__icon"/>
            </div>
            <SwitchBtn
                active={settings?.loginOptions?.Facebook}
                setActive={() => toggle('Facebook')}
            />
          </div>
          <div className="edit-login__media-switch">
            <MailIcon className="secondary-social-media-button__icon"/>
            <SwitchBtn
                active={settings?.loginOptions?.Email}
                setActive={() => toggle('Email')}
            />
          </div>
          {/*<div className="edit-login__media-switch">*/}
          {/*  <TwitterLogo className="secondary-social-media-button__icon"/>*/}
          {/*  <SwitchBtn*/}
          {/*      active={settings?.loginOptions?.Twitter}*/}
          {/*      setActive={() => toggle('Twitter')}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="edit-login__media-switch">*/}
          {/*  <LinkedInLogo className="secondary-social-media-button__icon"/>*/}
          {/*  <SwitchBtn*/}
          {/*      active={settings?.loginOptions?.Linkedin}*/}
          {/*      setActive={() => toggle('Linkedin')}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>
  );
};

export default EditLogin;
