import React from "react";
import styled from "@emotion/styled";
import CalendarPopup from "./CalendarPopup";

const CalendarBodyWrapper = styled.div`
 
  .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 4px;

    .day {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      position: relative;
      outline: 0;

      label {
        font-family: Roboto;
        font-weight: 300;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .fade {
      color: #c4c4c4;
    }

    .today {
      color: white;
      background-color: #ea5454;
    }

    .campaign-day {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #ea5454;
      cursor: pointer;
    }
  }
`;

const CalendarBody = (props) => {
  const {calendar, campaigns, value, setSelectedDay, selectedDay, setValue} =
      props;

  const onDaySelect = (day, campaigns) => {
    setValue(day);

    if (campaigns && campaigns.length > 0) {
      setSelectedDay({date: day, campaigns: campaigns});
    }
  };

  // const [showPopup, setShowPopup] = useState(false);

  return (
      <CalendarBodyWrapper>
        {calendar.map((week) => {
          return (
              <div className="week">
                {week.map((day) => {
                  return (
                      <div
                          className={`day ${!value.isSame(day, "month")
                          && "fade"} ${
                              day.isSame(new Date(), "day") && "today"
                          } `}
                          onClick={() =>
                              onDaySelect(
                                  day,
                                  campaigns?.filter((c) =>
                                      c.formattedScheduleDate.isSame(day, "day")
                                  )
                              )
                          }
                          tabIndex="0"
                          onBlur={() => setSelectedDay(null)}
                      >
                        <label>{day.format("D")}</label>
                        {campaigns &&
                            campaigns
                            .filter((c) => c.formattedScheduleDate.isSame(day,
                                "day"))
                            .slice(0, 3)
                            .map((c, i, arr) => (
                                <div
                                    className="campaign-day"
                                    style={{
                                      borderColor: c.color,
                                      width: `${16 + (i + 1) * 4}px`,
                                      height: `${16 + (i + 1) * 4}px`,
                                    }}
                                />
                            ))}
                        {selectedDay && selectedDay.date.isSame(day, "day") && (
                            <CalendarPopup selectedDay={selectedDay}/>
                        )}
                      </div>
                  );
                })}
              </div>
          );
        })}
      </CalendarBodyWrapper>
  );
};

export default CalendarBody;
