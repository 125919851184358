import styled from "@emotion/styled";

const SendCreateBottomWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 26px;
  column-gap: 26px;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 40px;
  padding-right: 30px;
  margin-top: 20px;
  margin-right: 10px;

  .et-template * {
    cursor: pointer;
  }

  .et-template {
    height: 180px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    padding: 20px;
    cursor: pointer;

    .ett-name {
      font-family: Roboto;
      font-weight: bold;
      font-size: 16px;
      margin-top: 20px;
    }

    .subject {
      font-family: Roboto;
      font-weight: 300;
      font-size: 10px;
      margin-top: 40px;
    }

    .template-info {
      flex: 2;
      display: flex;
      flex-direction: column;
    }

    .template-preview {
      flex: 1.2;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      height: 100%;

      .dynamic-content-div {
        width: 100%;
        border: none;
      }
    }
  }

  .et-start-new {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ett-name {
      margin-top: 30px;
    }
  }
`;

export default SendCreateBottomWrapper