import React, {useEffect, useState} from "react";
import {ColorPicker} from "components/widget/colorPicker";
import {socialMediaOptions} from "containers/Connect/consts";
import {Dropdown} from "components/widget/dropdown";
import FileDragAndDrop from "components/FileDragAndDrop";
import EditLoginWelcomePage from "../EditLoginWelcomePage";
import EditBrandingWrapper from "./EditBrandingWrapper";
import "assets/css/design/edit-landing-page-design.scss";

//
// Step 1
//
const EditBranding = (props) => {
  const {
    settings,
    updateSettings,
    currentPreviewScreen,
    uploadImage,
  } = props;

  const [selected, setSelected] = useState(socialMediaOptions[0]);

  useEffect(() => {
    if (settings?.preferredSocialMedia) {
      setSelected(
          settings?.preferredSocialMedia
              ? socialMediaOptions.find(
                  opt => opt.value === settings?.preferredSocialMedia)
              : socialMediaOptions[0]
      );
    }
  }, []);

  const setSocialMedia = (option) => {
    const key = option.value;
    setSelected(option);
    updateSettings(`loginOptions.${key}`, true);
    updateSettings('preferredSocialMedia', key);
  }

  return (
      <div>
        {currentPreviewScreen === 0 && (
            <EditBrandingWrapper>
              <label className="landing-page-edit-subtitle">
                Select Starting Point
              </label>
              <Dropdown
                  options={socialMediaOptions}
                  selected={selected}
                  onSelect={(option) => setSocialMedia(option)}
              />
              <hr className="edit-landing-page-menu-line"/>
              <div className="file-upload-container" style={{width: '75%'}}>
                <FileDragAndDrop
                    title="Select Logo Image:"
                    className="file-selector"
                    fileName={settings?.logoImage?.filename}
                    setFile={(event) => uploadImage(event, "logo")}
                />
                <FileDragAndDrop
                    title="Select Background Image:"
                    className="file-selector"
                    fileName={settings?.backgroundImage?.filename}
                    setFile={(event) => uploadImage(event, "background")}
                />
              </div>
              <div className="edit-landing-page-background-zoom"/>
              <hr className="edit-landing-page-menu-line"/>
              <div className="file-upload-container" style={{width: '100%'}}>
                <div>
                  <label className="landing-page-edit-subtitle">
                    Text Options
                  </label>
                  <ColorPicker
                      title="Text Color:"
                      value={settings?.textcolor}
                      hideValue
                      popupPosition="top"
                      setValue={(value) => {
                        updateSettings('textcolor', value)
                      }}
                  />
                </div>
                <div className="slide-container">
                  <label style={{marginBottom: "200px"}}>
                    Background Opacity
                  </label>
                  <input
                      id="backgroundOpacity"
                      className="slider"
                      type="range"
                      min="0"
                      max="1"
                      step=".01"
                      value={settings?.opacity ? settings?.opacity : 1}
                      onChange={(e) => updateSettings(
                          'opacity', e.target.value)}
                  />
                </div>
              </div>
            </EditBrandingWrapper>
        )}
        {currentPreviewScreen === 1 && (
            <div>
              <EditLoginWelcomePage {...props} />
            </div>
        )}
      </div>
  );
};

export default EditBranding;
