export enum EventType {
  NEW_SUBSCRIBER = 'NEW_SUBSCRIBER',
  BIRTHDAY_OFFER = 'BIRTHDAY_OFFER',
  LOYAL_SUBSCRIBER = 'LOYAL_SUBSCRIBER',
  LOST_SUBSCRIBER = 'LOST_SUBSCRIBER'
}

export const getEventTypeMap = (): Map<any, string> => {
  return new Map<any, string>([
    [EventType.NEW_SUBSCRIBER, 'New Subscriber'],
    [EventType.BIRTHDAY_OFFER, 'Birthday Offer'],
    [EventType.LOYAL_SUBSCRIBER, 'Loyal Subscriber'],
    [EventType.LOST_SUBSCRIBER, 'Lost Subscriber']
  ]);
}

export enum ActionType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  WAIT = 'WAIT',
  AUDIENCE = 'AUDIENCE'
}

export const getActionTypeMap = (): Map<any, string> => {
  return new Map<any, string>([
    [ActionType.EMAIL, 'Send Email'],
    [ActionType.SMS, 'Send SMS'],
    [ActionType.WAIT, 'Wait'],
    [ActionType.AUDIENCE, 'Add to Audience']
  ]);
}

export enum ParamType {
  URL = "URL",
  // AUTH_NAME = "AUTH_NAME",
  // AUTH_PASSWORD = "AUTH_PASSWORD",
  TEMPLATE_ID = "TEMPLATE_ID", // done
  // TEMPLATE_NAME = "TEMPLATE_NAME",
  SENDER = "SENDER", // done
  SEGMENT_ID = "SEGMENT_ID", // done
  // SEGMENT_SEARCH = "SEGMENT_SEARCH", // done
  // AUDIENCE_SEARCH = "AUDIENCE_SEARCH", // done
  AUDIENCE_ID = "AUDIENCE_ID",
  WAIT_PERIOD = "WAIT_PERIOD", // done
  //CONDITION = "CONDITION",
  CONDITION_LINK_CLICKED = "CONDITION_LINK_CLICKED",
  CONDITION_LINK_NOT_CLICKED = "CONDITION_LINK_NOT_CLICKED",
  CONDITION_EMAIL_OPENED = "CONDITION_EMAIL_OPENED",
  CONDITION_EMAIL_NOT_OPENED = "CONDITION_EMAIL_NOT_OPENED"
}

export const getParamTypeMap = (): Map<any, string> => {
  return new Map<any, string>([
    // [ParamType.URL, 'URL'],
    // [ParamType.AUTH_NAME, 'Auth Name'],
    // [ParamType.AUTH_PASSWORD, 'Auth Password'],
    [ParamType.TEMPLATE_ID, 'Template'],
    // [ParamType.TEMPLATE_NAME, 'Template Name'],
    [ParamType.WAIT_PERIOD, 'Wait Period'],
    [ParamType.SENDER, 'Sender'],
    // [ParamType.SEGMENT_ID, 'Segment Search'],
    // [ParamType.SEGMENT_SEARCH, 'Segment Search'],
    // [ParamType.AUDIENCE_SEARCH, 'Audience Search'],
    [ParamType.AUDIENCE_ID, 'Audience'],
    [ParamType.CONDITION_LINK_CLICKED, 'Link Clicked'],
    [ParamType.CONDITION_LINK_NOT_CLICKED, 'Link Not Clicked'],
    [ParamType.CONDITION_EMAIL_OPENED, 'Email Opened'],
    [ParamType.CONDITION_EMAIL_NOT_OPENED, 'Email Not Opened'],
  ]);
}

export interface Param {
  id?: string,
  label?: string,
  paramType: ParamType,
  paramValue: string,
}

export interface Action {
  stepNumber: number,
  actionType: ActionType,
  name: string,
  params: Param[]
}

export interface AutomationRequest {
  id?: string,
  name: string,
  description: string,
  eventType: EventType,
  organisationId: string,
  cron: string,
  scheduleNow: boolean,
  actions: Action[],
}

export interface AutomationSummary {
  id: string,
  name: string,
  description: string,
  status: string,
  contacts: number
}

export type Template = {
  externalId: string
  name: string
}

export type TemplateLinks = {
  template: Template,
  links: string[]
}

export interface ValidateActionResponse {
  valid: boolean,
  errors: string[]
}

export enum DurationUnit {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK'
}

export interface Duration {
  amount: number
  unit: DurationUnit
}

export const getNewAutomation = (): AutomationRequest => {
  return {
    name: 'My Automation',
    description: '',
    eventType: EventType.NEW_SUBSCRIBER,
    organisationId: null,
    cron: '',
    scheduleNow: true,
    actions: []
  }
}
