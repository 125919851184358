import React from "react";
import styled from "@emotion/styled";
import {ColorPicker} from "components/widget/colorPicker";

const ColorPickersWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 75%;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`;

const ColorPickers = (props) => {
  const {settings, updateSettings} = props;
  return (
      <ColorPickersWrapper>
        <label>Back Button Colour: </label>
        <ColorPicker
            value={settings?.emailBackButtonColor}
            hideValue
            popupPosition="top"
            setValue={(value) =>
                updateSettings("emailBackButtonColor", value)
            }
        />
        <label>Continue Button Colour: </label>
        <ColorPicker
            value={settings?.emailContinueButtonColor}
            hideValue
            popupPosition="top"
            setValue={(value) =>
                updateSettings("emailContinueButtonColor", value)
            }
        />
      </ColorPickersWrapper>
  );
};

export default ColorPickers;
