import React from 'react';
import {Action, getActionTypeMap, getParamTypeMap, Param} from "../../types";
import Grid from '@mui/material/Unstable_Grid2';
import uuid from "react-uuid";

const actionTypeMap: Map<any, string> = getActionTypeMap();
const paramTypeMap: Map<any, string> = getParamTypeMap();

type Props = {
  actions: Action[]
}

const ActionDisplay = ({actions}: Props) => {
  return (
      <>
        {actions && actions.length > 0 &&
            actions.map((a: Action) => (
                <Grid
                    key={uuid()}
                    container
                    direction={'row'}
                >
                  <Grid key={uuid()} xs={4}>
                    {a.actionType && actionTypeMap.get(a.actionType)}
                  </Grid>
                  <Grid key={uuid()} xs={8}>
                    {a.params.map((p: Param) =>
                        <div>{paramTypeMap.get(p.paramType)} {p.label}</div>
                    )}
                  </Grid>
                </Grid>
            ))
        }
      </>
  )
}

export default ActionDisplay;
