import { createSelector } from "reselect";

import { networkSliceSelector } from "store/selectors";

export const selectIsLoading = createSelector(
  networkSliceSelector,
  (networkSliceSelector) => networkSliceSelector["loading"]
);

export const selectErrorMessage = createSelector(
  networkSliceSelector,
  (networkSliceSelector) => networkSliceSelector["errorMessage"]
);

export const selectNetworkOrganisations = createSelector(
  networkSliceSelector,
  (networkSliceSelector) => networkSliceSelector["networkOrganisations"]
);

export const selectNetworkIndustries = createSelector(
  networkSliceSelector,
  (networkSliceSelector) => networkSliceSelector["networkIndustries"]
);

export const selectNetworkPagination = createSelector(
  networkSliceSelector,
  (networkSliceSelector) => networkSliceSelector["pagination"]
);

export const selectIsNetworkDeletedSuccessfully = createSelector(
  networkSliceSelector,
  (networkSliceSelector) => networkSliceSelector["networkDeletedSuccesfully"]
);
