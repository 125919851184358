import React, { useState } from "react";

import "../../assets/css/devices/device-item.scss";

import { ReactComponent as DeviceItemIcon } from "../../assets/images/device-item-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { ReactComponent as UnselectedBox } from "../../assets/images/remember-me-box.svg";
import { ReactComponent as SelectedBox } from "../../assets/images/remember-me-box-selected.svg";

export const DeviceItem = ({ device, onDelete, updateDevice }) => {
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(device.name);

  const close = () => {
    setName(device.name);
    setEdit(false);
  };

  const keyUp = (e) => {
    if (e.key === "Enter") {
      if (name.length > 1) {
        device.name = name;
        device.ssid = null;
        updateDevice(device);
      } else {
        setName(device.name);
      }
      setEdit(false);
    } else if (e.key === "Escape") {
      close();
    }
  };

  return (
    <div className="device-item">
      <DeleteIcon
        className="devices-delete-icon"
        onClick={() => onDelete(device)}
      />
      <img alt="device icon" src={require("assets/images/device-icon.png")} />
      {edit ? (
        <input
          className="device-item-title-edit"
          autoFocus
          placeholder="Name"
          value={name || ""}
          onKeyUp={keyUp}
          onChange={(e) => setName(e.target.value)}
          onBlur={close}
        />
      ) : (
        <label
          className="device-item-title"
          onDoubleClick={() => setEdit(true)}
        >
          {name}
        </label>
      )}
      <label className="device-item-model">{device.model}</label>
      <hr />
      <label className="device-item-mac">{device.macAddress}</label>
    </div>
  );
};

export const ImportDeviceItem = ({ device, onSelect, setName, setSsid }) => {
  return (
    <div className="device-item">
      {device.selected ? (
        <SelectedBox
          className="devices-select-icon"
          onClick={() => onSelect(device.externalId)}
        />
      ) : (
        <UnselectedBox
          className="devices-select-icon"
          onClick={() => onSelect(device.externalId)}
        />
      )}
      <DeviceItemIcon onClick={() => onSelect(device.externalId)} />
      <input
        className="input-name"
        placeholder="Input name"
        onChange={(e) => setName(device.externalId, e.target.value)}
      />
      <input
        className="input-ssid"
        placeholder="Input SSID"
        value={device.ssid || ""}
        onChange={(e) => setSsid(device.externalId, e.target.value)}
      />
      <hr />
      <label
        className="device-item-mac"
        onClick={() => onSelect(device.externalId)}
      >
        {device.macAddress}
      </label>
    </div>
  );
};
