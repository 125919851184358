import React, {useEffect, useState} from "react";

import MobilePreviewOuterWrapper from "./MobilePreviewOuterWrapper";
import BulletNavigator from "./BulletNavigator";

import "assets/css/design/edit-landing-page.scss";

const MobilePreviewOuter = (props) => {
  const {
    currentLandingPageStep,
    settings,
    previewScreens,
    currentPreviewScreen,
    setCurrentPreviewScreen,
    children,
  } = props;

  const defaultColor = '#f9f9f9f9';

  return (
      <MobilePreviewOuterWrapper>
        <div className="preview-content">
          <div className="phone-preview">
            <div
                className="phone-preview-box"
                style={{
                  backgroundColor: "#F2F2F2",
                  backgroundImage: `url(${settings?.backgroundImage?.url}`,
                }}
            >
              {children}
            </div>
            <img
                alt="iphone frame"
                src={require("assets/images/iphone13_frame.png")}
            />
          </div>
          <div className="preview-navigator">
            {currentLandingPageStep === 1 && (
                <div className="preview-bullet-navigator">
                  <BulletNavigator
                      screens={previewScreens.map((ps) => ps.id)}
                      currentPreviewScreen={currentPreviewScreen}
                      setCurrentPreviewScreen={setCurrentPreviewScreen}
                  />
                </div>
            )}
          </div>
        </div>
      </MobilePreviewOuterWrapper>
  );
};

export default MobilePreviewOuter;
