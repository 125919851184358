import React from "react";
import styled from "@emotion/styled";
const ToTestFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    color: red;
  }
`;

const TextInput = styled.input`
  margin: 30px 0 30px 30px;
  border: 1px solid #c4c4c4;
  border-radius: 21.75px;
  padding: 7px 14px;
  font-size: 12px;
  outline: none;
  width: 100%;
`;

const ToTestField = (props) => {
  const { input } = props;

  return (
    <ToTestFieldWrapper>
      <TextInput {...input} type="text" placeholder="Type email"></TextInput>
    </ToTestFieldWrapper>
  );
};

export default ToTestField;
