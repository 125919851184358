import styled from "@emotion/styled";

const FileDragAndDropWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  flex-direction: column;
  margin: 20px;
  flex-basis: 50%;
  max-width: 42%;
  max-height: 180px;

  .file-selector-title {
    color: #3c4858;
    font-weight: 500;
    width: 100%;
    text-align: left;
    font-weight: bold;
    margin-bottom: 30px;
  }

  input {
    display: none;
  }

  .file-selector-file-name {
    margin-left: 20px;
    width: 160px;
    height: 15px;
    padding: 10px;
    border: 0.75px solid #dbdbdb;
    border-radius: 25px;
    background-color: #f5f5f5;
    color: #3c4858;
    outline: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .file-selector-drop-file-area {
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    height: 170px;
    width: 100%;
    padding: 10px;
    border: 0.75px solid #dbdbdb;
    border-radius: 25px;
    background-color: #f5f5f5;
    color: #3c4858;
    outline: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:hover {
      cursor: pointer;
    }
  }

  .file-selector-highlighted {
    border: 0.75px solid yellow;
  }

  .file-selector-btn {
    margin-left: 20px;
    color: #11befc;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .file-selector-icon {
    height: 50px;
    width: 50px;
  }
`;

export default FileDragAndDropWrapper;
