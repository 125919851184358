import React, {useState, useEffect} from 'react'

import {ReactComponent as CloseIcon} from '../../assets/images/delete.svg';
import api from '../../utils/api'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-google-places-autocomplete';

import '../../assets/css/venue/edit-venue-popup.scss'

export default ({close, venue, setVenue}) => {

  const [phone, setPhone] = useState()
  const [email, setEmail] = useState()
  const [name, setName] = useState()
  const [errors, setErrors] = useState([])
  const [errorMessage, setErrorMessage] = useState()
  const [location, setLocation] = useState({})

  useEffect(() => {
    if (venue?.organisationContactInfos) {
      const phoneInfo = venue.organisationContactInfos.find(
          info => info.contactType === 'MOBILE')
      setPhone(phoneInfo ? phoneInfo.contactValue : '')
      const emailInfo = venue.organisationContactInfos.find(
          info => info.contactType === 'EMAIL')
      setEmail(emailInfo ? emailInfo.contactValue : '')
      setName(venue.name)
      setLocation(venue.location)
    }
  }, [venue])

  const onClick = e => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close()
    }
  }

  const changePhone = value => {
    setPhone(value)
  }

  const changeEmail = value => {
    setEmail(value)
  }

  const changeName = value => {
    setErrors(errors.filter(error => error !== 'name'))
    setErrorMessage(null)
    setName(value)
  }

  const save = async () => {
    var e = []
    if (!name || name.length < 3) {
      e = [...e, 'name']
    }

    setErrors(e)

    if (e.length > 0) {
      setErrorMessage('Please fill all fields')
    } else {
      setErrorMessage(null)
      setErrors([])
      await api.put(`/organisations/${venue.externalId}`,
          {name: name, phone: phone, email: email, location: location})
      setVenue({
        ...venue,
        name: name,
        organisationContactInfos: [
          {contactType: "MOBILE", contactValue: phone},
          {contactType: "EMAIL", contactValue: email}
        ],
        location: location
      })
      close()
    }
  }

  const getComponent = (components, name) => {
    return components.find(c => c.types.includes(name)) || {}
  }

  const onSelectSuggestion = async (seggestion) => {
    const geocodes = await geocodeByPlaceId(seggestion.place_id)
    const geocode = geocodes[0]
    const latLng = await getLatLng(geocode)

    const address = {}
    const country = getComponent(geocode.address_components, 'country')
    address.country = country.long_name
    address.countryCode = country.short_name
    address.fullAddress = geocode.formatted_address
    address.fullAddressLine2 = ''
    address.googlePlaceId = geocode.place_id
    address.isBilling = true
    address.isPrimary = true
    address.lat = latLng.lat
    address.lon = latLng.lng
    address.name = geocode.address_components[0].short_name
    address.postcodeZipcode = getComponent(geocode.address_components,
        'postal_code').long_name
    address.city = getComponent(geocode.address_components,
        'postal_town').long_name

    setLocation(address)
  }

  return (
      <div className='edit-venue-popup' onClick={onClick}>
        <div className='edit-venue-popup-content'>
          <CloseIcon className='close-icon' onClick={close}/>
          <label className='edit-venue-popup-title'>Edit Venue</label>
          <div className='add-organisation-input'>
            <label className='subtitle'>Search Address</label>
            <GooglePlacesAutocomplete
                placeholder='Search...'
                autocompletionRequest={{

                  componentRestrictions: {
                    country: ['uk'],
                  }
                }}
                onSelect={onSelectSuggestion}
            />
          </div>
          <label className='subtitle'>Address</label>
          <input className={`${errors.length > 0 && errors.includes('line1')
          && 'input-error'}`} value={location.fullAddress || ''}
                 onChange={e => setLocation(
                     {...location, fullAddress: e.target.value})}/>
          <label className='subtitle'>Postcode</label>
          <input className={`${errors.length > 0 && errors.includes('zip')
          && 'input-error'}`} value={location.postcodeZipcode || ''}
                 onChange={e => setLocation(
                     {...location, postcodeZipcode: e.target.value})}/>
          <label className='subtitle'>Phone Number</label>
          <input className={`${errors.length > 0 && errors.includes('phone')
          && 'input-error'}`} value={phone || ''}
                 onChange={e => changePhone(e.target.value)}/>
          <label className='subtitle'>Email</label>
          <input className={`${errors.length > 0 && errors.includes('email')
          && 'input-error'}`} value={email || ''}
                 onChange={e => changeEmail(e.target.value)}/>
          <label className='subtitle'>Venue Name</label>
          <input className={`${errors.length > 0 && errors.includes('name')
          && 'input-error'}`} value={name || ''}
                 onChange={e => changeName(e.target.value)}/>
          {errorMessage && <label
              className='error-message'>{errorMessage}</label>}
          <button onClick={save}>Save</button>
        </div>
      </div>
  )
}