import {
  GET_NETWORK_ORGANISATIONS_REQUEST,
  GET_NETWORK_ORGANISATIONS_FAILURE,
  GET_NETWORK_ORGANISATIONS_SUCCESS,
  GET_NETWORK_INDUSTRIES_REQUEST,
  GET_NETWORK_INDUSTRIES_FAILURE,
  GET_NETWORK_INDUSTRIES_SUCCESS,
  DELETE_NETWORK_ORGANISATION_REQUEST,
  DELETE_NETWORK_ORGANISATION_FAILURE,
  DELETE_NETWORK_ORGANISATION_SUCCESS,
} from "./actions";

const initialState = {
  loading: false,
  errorMessage: null,
  networkOrganisations: null,
  totalNetworkOrganisations: null,
  networkIndustries: null,
  pagination: null,
  networkDeletedSuccesfully:false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_NETWORK_ORGANISATIONS_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        networkOrganisations: null,
      };
    }

    case GET_NETWORK_ORGANISATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case GET_NETWORK_ORGANISATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        networkOrganisations: action.payload.content,
        totalNetworkOrganisations: action.payload.total,
        pagination: {
          ...action.payload.pageable,
          total: action.payload.total,
          totalPages: Math.ceil(action.payload.total / 20),
        },
      };
    }

    //---

    case GET_NETWORK_INDUSTRIES_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        networkIndustries: null,
      };
    }

    case GET_NETWORK_INDUSTRIES_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case GET_NETWORK_INDUSTRIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        networkIndustries: action.payload,
      };
    }

    //---

    case DELETE_NETWORK_ORGANISATION_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        networkDeletedSuccesfully:false
      };
    }

    case DELETE_NETWORK_ORGANISATION_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        networkDeletedSuccesfully:false
      };
    }

    case DELETE_NETWORK_ORGANISATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        networkDeletedSuccesfully:true
      };
    }

    default:
      return state;
  }
}
