import React, { useState, useEffect } from 'react'

import Loader from 'react-loader-spinner'

import api from 'utils/api'

import 'assets/css/billing/invoices.scss'

export default ({ organisationId }) => {

    const [invoices, setInvoices] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const { data } = await api.get(`/organisation/${organisationId}/invoices?limit=10&startingAfter`)
            setInvoices(data.data.map(payment => ({ ...payment, created: new Date(payment.created * 1000) })))
            setLoading(false)
        }
        fetchData()
    }, [organisationId])

    const formatCreated = created => {
        return `${created.getDate() < 10 ? '0' + created.getDate() : created.getDate()}/${created.getMonth() < 10 ? '' + (created.getMonth() + 1) : created.getMonth() + 1}/${created.getFullYear()}`
    }

    const getIcon = productType => {
        if (productType === 'CONNECT')
            return require('assets/images/connect-icon.svg')
        else if (productType === 'SMS')
            return require('assets/images/sms-icon.svg')
        // else if (productType === 'CRM')
        //     return require('assets/images/crm-icon.svg')
        else if (productType === 'SEND')
            return require('assets/images/send-icon.svg')
        else if (productType === 'MARKETING')
            return require('assets/images/marketing-icon.svg')
        else
            return require('assets/images/connect-icon.svg')
    }

    const formatType = productType => {
        return productType ? productType.charAt(0).toUpperCase() + productType.slice(1).toLowerCase() : ''
    }

    return (
        <div className='invoices-page'>
            <div className='header'>
                <label>Date</label>
                <label>Invoice Number</label>
                <label>Type</label>
                <label>Amount</label>
                <label>Amount<span>(incl. Vat)</span></label>
            </div>
            {loading ?
                <div className='invoices-loader'>
                    <Loader
                        type="Oval"
                        color="#EA5459"
                        height={30}
                        width={30}
                    />
                </div>
                :
                <div className='invoices'>
                    {invoices.map(invoice =>
                        <div className='invoice'>
                            <label>{formatCreated(invoice.created)}</label>
                            <label>{invoice.number}</label>
                            <div className='invoice-type'>
                                {invoice.productType && <img src={getIcon(invoice.productType)} />}
                                <label>{formatType(invoice.productType)}</label>
                            </div>
                            <label>£{invoice.amount - (invoice && invoice.tax ? invoice.tax.amount : 0)}</label>
                            <label>£{invoice.amount}</label>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}