import React, { useRef, useState } from "react";

import documentIcon from "assets/images/documentIcon.svg";

import "assets/css/widget/file-drag-and-drop.scss";

export const FileDragAndDrop = ({ onClick, title, fileName, setFile }) => {
  const inputFile = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const onDragTo = (e) => {
    e.preventDefault();
    setIsFocused(true);
  };

  const onDragExit = (e) => {
    e.preventDefault();
    setIsFocused(false);
  };

  const onFileDrop = (e) => {
    e.preventDefault();
    setFile(e);
  };

  return (
    <div
      className={`file-selector-drag-and-drop`}
      onDragOver={(e) => onDragTo(e)}
      onDragLeave={(e) => onDragExit(e)}
      onDrop={(e) => onFileDrop(e)}
    >
      <label className="file-selector-title">{title}</label>
      <input
        type="file"
        ref={inputFile}
        onChange={(e) => setFile(e.target.files[0])}
      />
      <label
        className={`file-selector-drop-file-area  ${
          isFocused ? "file-selector-highlighted" : ""
        }`}
        onDrop={(e) => onFileDrop(e)}
        onClick={() => inputFile.current.click()}
      >
        {fileName && <img alt="file selector" className="file-selector-icon" src={documentIcon} />}
        {fileName ? fileName : "Select a file or drag one here"}
      </label>
    </div>
  );
};
