import React, { useState } from "react";

import "assets/css/widget/popup.scss";

const CreateNewPopup = (props) => {
  const { onCancel, onCreate, type } = props;

  const [name, setName] = useState();

  return (
    <div className="popup">
      <div className="popup-content">
        <h3>{`Create ${type === "poster" ? "poster" : "landing page"}`}</h3>
        <input
          placeholder={`${type === "poster" ? "Poster" : "Landing page"} name`}
          type="text"
          autoFocus
          onChange={(e) => setName(e.target.value)}
        />
        <div className="popup-actions">
          <button className="cancel" onClick={() => onCancel()}>
            Cancel
          </button>
          <button onClick={() => onCreate(name)}>Create</button>
        </div>
      </div>
    </div>
  );
};

export default CreateNewPopup;
