import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SaveTemplatePopup from "./saveTemplatePopup";

import "../../../../assets/css/sms/campaign/create/send-message.scss";

export default ({ saveTemplate }) => {
  const history = useHistory();
  const [showSaveTemplatePopup, setShowSaveTemplatePopup] = useState(false);

  return (
    <div className="send-message">
      <label className="send-message-title">Congratulations!</label>
      <label className="send-message-subtitle">
        Your campaign is now live!
      </label>
      <div className="actions">
        <button onClick={() => history.push("/sms/campaigns/manage")}>
          Track Campaign
        </button>
        {saveTemplate && (
          <button onClick={() => setShowSaveTemplatePopup(true)}>
            Save as Template
          </button>
        )}
        <button onClick={() => history.push("/sms/dashboard")}>Done</button>
      </div>
      {showSaveTemplatePopup && (
        <SaveTemplatePopup
          close={() => setShowSaveTemplatePopup(false)}
          save={saveTemplate}
        />
      )}
    </div>
  );
};
