import React from "react";
import styled from "@emotion/styled";

const AudiencesTextWrapper = styled.div``;

const DynamicTextLabel = styled.label`
  border-radius: 15px;
  color: #ea5454;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 16px;
  margin-left: ${(props) => (props.ml ? props.ml : 0)}px;
  margin-right: ${(props) => (props.mr ? props.mr : 0)}px;
`;

const AudiencesText = (props) => {
  const { currentCampaign, currentAudiences } = props;

  const { selectedAudience, selectedAudienceContacts } = currentAudiences;

  return (
    currentCampaign && (
      <AudiencesTextWrapper>
        <DynamicTextLabel mr={5}>
          {Object.keys(currentCampaign.contacts).length} contacts
        </DynamicTextLabel>
        in the audience
        <DynamicTextLabel ml={5}>{selectedAudience.name}</DynamicTextLabel>
      </AudiencesTextWrapper>
    )
  );
};

export default AudiencesText;
