import React from "react";
import UnsubscribeWrapper from "./UnsubscribeWrapper";
import { Repeat } from "phosphor-react";
import { compose } from "recompose";
import MiscContainer from "containers/Misc";
import { useLocation } from "react-router-dom";

const Unsubscribe = (props) => {
  const { subscribeRequest, unsubscribeRequest } = props;

  const [isUnsubscribed, setIsUnsubscribed] = React.useState(false);

  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const contactId = query.get("contactId");

  React.useEffect(() => {
    unsubscribeRequest(contactId);
    setIsUnsubscribed(true);
  }, []);

  return (
    <UnsubscribeWrapper>
      <div className="unsubscribe-card">
        <img
          id="logo"
          src="https://www.unaro.co.uk/wp-content/uploads/2020/10/Unaro-Logo.png"
        />
        <h4>{isUnsubscribed ? "Unsubscribed" : "Subscribed"}</h4>
        <p>
          {isUnsubscribed
            ? "You have been unsubscribed successfully. You won’t receive any further emails from us. Did you unsubscribe by accident?"
            : "You have been subscribed again successfully. You will keep receiving emails from us."}
        </p>

        {isUnsubscribed && (
          <button
            onClick={() => {
              subscribeRequest(contactId);
              setIsUnsubscribed(false);
            }}
          >
            <Repeat size={20} color=" #ea5454" weight="bold" />
            <label>Resubscribe</label>
          </button>
        )}
      </div>
    </UnsubscribeWrapper>
  );
};

export default compose(MiscContainer)(Unsubscribe);
