import styled from "@emotion/styled";

const MobilePreviewOuterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  justify-content: center;
  background-color: white;
  border-left: 1px solid rgba(232, 232, 232, 1);

  .preview-content {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 90px;
    margin-left: 50px;
    margin-top: 20px;

    .preview-navigator {
      display: flex;
      margin-bottom: 30px;
      margin-top: 10px;

      .preview-bullet-navigator {
        position: absolute;
        left: 105px;

        display: flex;
        flex-direction: row;
        align-self: center;
      }
    }

    .phone-preview {
      display: flex;

      img {
        z-index: 3;
        width: 300px;
        pointer-events: none;
      }

      .phone-preview-box {
        position: absolute;
        top: 5px;
        left: 14px;
        bottom: 15px;
        width: 272px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: 30px;
        border-radius: 23px;
        /* z-index: 4; */
      }
    }
  }
`;

export default MobilePreviewOuterWrapper;
