import React, {ChangeEvent, useEffect, useState} from 'react';
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import uuid from "react-uuid";
import {AutomationRequest} from "../types";
import Grid from '@mui/material/Unstable_Grid2';

type Props = {
  automation: AutomationRequest
  updateAutomation: (field: string, value: any) => void
}

const NameSelect = ({automation, updateAutomation}: Props) => {

  const [scheduleNow, setScheduleNow] = useState<boolean>(false);

  useEffect(() => {
    setScheduleNow(automation.scheduleNow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automation.scheduleNow]);

  const onChange = (checked: boolean) => {
    setScheduleNow(checked);
    updateAutomation('scheduleNow', checked);
  };

  return (
      <Grid container>
        <TextField
            id={uuid()}
            label="Automation Name"
            fullWidth
            sx={{marginY: 1}}
            value={automation?.name ? automation?.name : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                updateAutomation('name', e.target.value)}
        />
        <TextField
            id={uuid()}
            label="Description"
            fullWidth
            value={automation?.description ? automation?.description : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                updateAutomation('description', e.target.value)}
            helperText="optional"
        />
        <FormControlLabel
            label="Run Now"
            control={
              <Checkbox checked={scheduleNow}
                        onChange={(event, checked) => onChange(checked)}/>}
            sx={{marginTop: 1}}
        />
      </Grid>
  );
}

export default NameSelect;
