import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as DropdownLogo } from "assets/images/dropdown-icon.svg";

import { compose } from "recompose";
import AuthContainer from "containers/Auth";

import "assets/css/widget/user-dropdown.scss";

const UserDropdown = (props) => {
  const { logoutRequest } = props;

  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("user"));

  const signOut = async () => {
    logoutRequest();
  };

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div
      className="user-dropdown-container"
      tabIndex="0"
      onBlur={() => setOpen(false)}
    >
      <div
        className={`dropdown ${open && "dropdown-open"}`}
        onClick={() => toggleOpen()}
      >
        <div className="ud-name">
          {user.avatarUrl ? (
            <img className="profile-picture" src={user.avatarUrl} alt="" />
          ) : (
            <div className="profile-picture-initials">
              {`${user.forename ? user.forename[0] : ""}${
                user.surname ? user.surname[0] : ""
              }`}
            </div>
          )}
          <label className="profile-name">{`${user.forename} ${user.surname}`}</label>
        </div>
        <DropdownLogo className="logo" />
      </div>
      {open && (
        <div className="options">
          <label
            key={"option-0"}
            onClick={() => {
              history.push("/account");
              toggleOpen();
            }}
          >
            My account
          </label>
          <label
            key={"option-1"}
            onClick={() => {
              history.push("/getting-started");
              toggleOpen();
            }}
          >
            Getting Started
          </label>
          <label
            key={"option-2"}
            onClick={() => {
              history.push("/settings");
              toggleOpen();
            }}
          >
            Settings
          </label>
          {/* <label
            key={"option-3"}
            onClick={() => history.push("/send/settings")}
          >
            Unaro Send
          </label> */}
          <label key={"option-4"} onClick={signOut}>
            Sign out
          </label>
        </div>
      )}
    </div>
  );
};

export default compose(AuthContainer)(UserDropdown);
