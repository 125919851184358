import React, {useState, useEffect} from 'react'
import {useLocation, useHistory} from "react-router-dom";

import {
  ReactComponent as DeviceLogo
} from '../../assets/images/devices-icon.svg';
import {
  ReactComponent as NotFoundIcon
} from '../../assets/images/not-found.svg';

import {InfoPopup} from '../widget/infoPopup'
import {ImportDeviceItem} from './deviceItem'
import api from '../../utils/api'

import '../../assets/css/devices/import-devices.scss'
import {from} from "rxjs";

export const ImportDevices = ({setShowInfoPopup, addDevices}) => {
  const location = useLocation()
  const source = new URLSearchParams(location.search).get('source')
  const venue = new URLSearchParams(location.search).get('venue')

  const history = useHistory()

  const [devices, setDevices] = useState([])
  const [showError, setShowError] = useState(false)

  const fetchDevices = () => {
    from(api.get(
        `/organisations/${venue}/devices?source=${source.toUpperCase()}`))
    .subscribe({
      next: (result) => {
        if (result?.data?.content) {
          let devices = result.data.content.map(device => ({
            ...device,
            selected: true,
            ssid: device.ssid?.name,
            externalId: device.ssid?.id
          }));
          setDevices(devices);
        }
      },
      error: (err) => {
        console.error("Failed to fetch devices");
        console.error(err);
      }
    });
  }

  useEffect(() => fetchDevices(), [source])

  const importDevices = async (all) => {
    if (devices) {
      const selectedDevices = devices
      .filter(device => all || device.selected)
      .map(device => ({...device, selected: null}))

      if (selectedDevices.length > 0) {
        if (selectedDevices.find(
            device => device.name === null || device.name.length < 2
                || device.ssid === null || device.ssid.length < 2)) {
          setShowError(true)
          setTimeout(() => setShowError(false), 3000)
        } else {
          const imported = await Promise.all(selectedDevices.map(importDevice))
          addDevices(imported)
          setShowInfoPopup(true)
          setTimeout(() => setShowInfoPopup(false), 2000)
          setDevices(...[devices.filter(device => all || !device.selected)])
          history.goBack()
        }
      }
    }
  }

  const importDevice = async (device) => {
    const response = await api.post(`/organisations/${venue}/devices`, device)
    device.externalId = response.data.externalId
    device.organisationId = response.data.organisation.externalId
    return device
  }

  return (
      <div className='import-devices'>
        <div className='import-devices-top'>
          <div className='import-devices-logo'>
            <DeviceLogo className='import-devices-logo-svg'/>
          </div>

          <div className='actions'>
            <button onClick={() => importDevices(true)}>Import all</button>
            <button onClick={() => importDevices(false)}>Import</button>
          </div>
        </div>
        {devices && devices.length > 0 ?
            <div className='import-devices-grid'>
              {devices.map(device =>
                  <ImportDeviceItem
                      key={device.externalId}
                      device={device}
                      setName={(id, name) => setDevices(devices.map(
                          device => device.externalId === id ? {
                            ...device,
                            name: name
                          } : device))}
                      setSsid={(id, ssid) => setDevices(devices.map(
                          device => device.externalId === id ? {
                            ...device,
                            ssid: ssid
                          } : device))}
                      onSelect={id => setDevices(devices.map(
                          device => device.externalId === id ? {
                            ...device,
                            selected: !device.selected
                          } : device))}
                  />)}
            </div>
            :
            <div className='not-found'>
              <NotFoundIcon className='not-found-icon'/>
              <label>No Ap's found</label>
            </div>
        }
        {showError === true && <InfoPopup
            text='Please fill name and ssid for all selected devices'
            onOk={() => setShowError(false)}/>}

      </div>
  )
}
