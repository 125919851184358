import { combineReducers } from "redux";

import miscReducer from "./miscReducer";
import contactsReducer from "./contactsReducer";
import segmentsReducer from "./segmentsReducer";
import audiencesReducer from "./audiencesReducer";
import filtersReducer from "./filtersReducer";
import importContactsListReducer from "./importContactsListReducer";

const sendRootReducer = combineReducers({
  "misc": miscReducer,
  "contacts": contactsReducer,
  "segments": segmentsReducer,
  "audiences": audiencesReducer,
  "filters": filtersReducer,
  "importContactsList": importContactsListReducer,
});

export default sendRootReducer;
