import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import {
  getEmailsStatsRequest,
  getYearOpenRateStatsRequest,
  getMonthOpenRateStatsRequest,
  getWeekOpenRateStatsRequest,
  getDayOpenRateStatsRequest,
  // updateSendContactsRequest
} from "./actions";

import {
  // selectIsLoading,
  // selectErrorMessage,
  selectEmailsStats,
  selectSendDashboardTimeUnit,
  selectYearOpenRates,
  selectMonthOpenRates,
  selectWeekOpenRates,
  selectDayOpenRates,
  selectYearDataSet,
  selectMonthDataSet,
  selectWeekDataSet,
  selectDayDataSet,
  selectOpenRatesChartMaxVal,
  // selectUpdatedContacts,
} from "./selectors";

export const mapStateToProps = createStructuredSelector({
  // isLoading: selectIsLoading,
  // errorMessage: selectErrorMessage,
  emailsStats: selectEmailsStats,
  sendDashboardTimeUnit: selectSendDashboardTimeUnit,
  yearOpenRates: selectYearOpenRates,
  monthOpenRates: selectMonthOpenRates,
  weekOpenRates: selectWeekOpenRates,
  dayOpenRates:selectDayOpenRates,
  yearDataSet: selectYearDataSet,
  monthDataSet: selectMonthDataSet,
  weekDataSet: selectWeekDataSet,
  dayDataSet:selectDayDataSet,
  openRatesChartMaxVal: selectOpenRatesChartMaxVal,
  // updatedContacts: selectUpdatedContacts,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getYearOpenRateStatsRequest,
      getEmailsStatsRequest,
      getMonthOpenRateStatsRequest,
      getWeekOpenRateStatsRequest,
      getDayOpenRateStatsRequest,
      // updateSendContactsRequest
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps));
