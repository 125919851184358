import {createSelector} from "reselect";
import _get from "lodash/fp/get";

import {connectSliceSelector} from "store/selectors";

//Connect Dashboard

export const selectConnectDashboardSlice = createSelector(
  connectSliceSelector,
  (state) => state["dashboard"]
  //
);

export const selectYearVisits = createSelector(
  selectConnectDashboardSlice,
  _get("year")
);

export const selectMonthVisits = createSelector(
  selectConnectDashboardSlice,
  _get("month")
);

export const selectWeekVisits = createSelector(
  selectConnectDashboardSlice,
  _get("week")
);

export const selectDayVisits = createSelector(
  selectConnectDashboardSlice,
  _get("day")
);

export const selectChartMaxVal = createSelector(selectYearVisits, (data) => {
  if (data && "datasets" in data) {
    const maxVal = Math.max(...data.datasets.flat()[0].data);

    return maxVal;
  }
});

//Connect LandingPages

export const selectLandingPagesSlice = createSelector(
  connectSliceSelector,
  (state) => state["landingPage"]
  //
);

export const selectCurrentLandingPage = createSelector(
  selectLandingPagesSlice,
  (state) => state["currentLandingPage"]
);

export const selectLandingPages = createSelector(
  selectLandingPagesSlice,
  (state) => state["landingPages"]
);

export const selectNewLandingPage = createSelector(
  selectLandingPagesSlice,
  (state) => state["newLandingPage"]
);

export const selectDeletedLandingPage = createSelector(
  selectLandingPagesSlice,
  (state) => state["deletedLandingPage"]
);

export const selectSavedLandingPage = createSelector(
  selectLandingPagesSlice,
  (state) => state["savedLandingPage"]
);

export const selectUpdatedMediaData = createSelector(
  selectLandingPagesSlice,
  (state) => state["uploadedMediaData"]
);

export const selectCurrentLandingPageStep = createSelector(
  selectLandingPagesSlice,
  (state) => state["currentLandingPageStep"]
);
