export const GET_IDENTITIES_REQUEST = "GET_IDENTITIES_REQUEST";
export const GET_IDENTITIES_FAILURE = "GET_IDENTITIES_FAILURE";
export const GET_IDENTITIES_SUCCESS = "GET_IDENTITIES_SUCCESS";

export const CREATE_IDENTITY_REQUEST = "CREATE_IDENTITY_REQUEST";
export const CREATE_IDENTITY_FAILURE = "CREATE_IDENTITY_FAILURE";
export const CREATE_IDENTITY_SUCCESS = "CREATE_IDENTITY_SUCCESS";

export const SELECT_IDENTITY_REQUEST = "SELECT_IDENTITY_REQUEST";
export const SELECT_IDENTITY_FAILURE = "SELECT_IDENTITY_FAILURE";
export const SELECT_IDENTITY_SUCCESS = "SELECT_IDENTITY_SUCCESS";

export const DELETE_IDENTITY_REQUEST = "DELETE_IDENTITY_REQUEST";
export const DELETE_IDENTITY_FAILURE = "DELETE_IDENTITY_FAILURE";
export const DELETE_IDENTITY_SUCCESS = "DELETE_IDENTITY_SUCCESS";

export const GET_DOMAIN_RECORDS_REQUEST = "GET_DOMAIN_RECORDS_REQUEST";
export const GET_DOMAIN_RECORDS_FAILURE = "GET_DOMAIN_RECORDS_FAILURE";
export const GET_DOMAIN_RECORDS_SUCCESS = "GET_DOMAIN_RECORDS_SUCCESS";

export const RESEND_IDENTITY_REQUEST = "RESEND_IDENTITY_REQUEST";
export const RESEND_IDENTITY_FAILURE = "RESEND_IDENTITY_FAILURE";
export const RESEND_IDENTITY_SUCCESS = "RESEND_IDENTITY_SUCCESS";

// ---

export const getIdentitiesRequest = (organisationId) => ({
  type: GET_IDENTITIES_REQUEST,
  payload: {
    organisationId,
  },
});

export const getIdentitiesFailure = (errorMessage) => ({
  type: GET_IDENTITIES_FAILURE,
  payload: errorMessage,
});

export const getIdentitiesSuccess = (organisationIdentities) => ({
  type: GET_IDENTITIES_SUCCESS,
  payload: organisationIdentities,
});

// ---

export const createIdentityRequest = (organisationId, emailIdentityData) => ({
  type: CREATE_IDENTITY_REQUEST,
  payload: {
    organisationId,
    emailIdentityData,
  },
});

export const createIdentityFailure = (errorMessage) => ({
  type: CREATE_IDENTITY_FAILURE,
  payload: errorMessage,
});

export const createIdentitySuccess = (emailIdentityData) => ({
  type: CREATE_IDENTITY_SUCCESS,
  payload: emailIdentityData,
});

// ---

export const deleteIdentityRequest = (identityId) => ({
  type: DELETE_IDENTITY_REQUEST,
  payload: {
    identityId,
  },
});

export const deleteIdentityFailure = (errorMessage) => ({
  type: DELETE_IDENTITY_FAILURE,
  payload: errorMessage,
});

export const deleteIdentitySuccess = (deletedIdentity) => ({
  type: DELETE_IDENTITY_SUCCESS,
  payload: deletedIdentity,
});

// ---

export const getDomainRecordsRequest = (identityId) => ({
  type: GET_DOMAIN_RECORDS_REQUEST,
  payload: {
    identityId,
  },
});

export const getDomainRecordsFailure = (errorMessage) => ({
  type: GET_DOMAIN_RECORDS_FAILURE,
  payload: errorMessage,
});

export const getDomainRecordsSuccess = (domainData) => ({
  type: GET_DOMAIN_RECORDS_SUCCESS,
  payload: domainData,
});

// ---

export const resendIdentityRequest = (identityId) => ({
  type: RESEND_IDENTITY_REQUEST,
  payload: {
    identityId,
  },
});

export const resendIdentityFailure = (errorMessage) => ({
  type: RESEND_IDENTITY_FAILURE,
  payload: errorMessage,
});

export const resendIdentitySuccess = () => ({
  type: RESEND_IDENTITY_SUCCESS,
});

// ---

export const selectIdentityRequest = (identityId) => ({
  type: SELECT_IDENTITY_REQUEST,
  payload: identityId,
});

export const selectIdentityFailure = (errorMessage) => ({
  type: SELECT_IDENTITY_FAILURE,
  payload: errorMessage,
});

export const selectIdentitySuccess = (identityId) => ({
  type: SELECT_IDENTITY_SUCCESS,
  payload: identityId,
});
