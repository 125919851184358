import {call, put, takeLatest} from "redux-saga/effects";

import {
  SUBSCRIBE_REQUEST,
  subscribeFailure,
  subscribeSuccess,
  TOGGLE_NAV_BAR_REQUEST,
  toggleNavBarFailure,
  toggleNavBarSuccess,
  UNSUBSCRIBE_REQUEST,
  unsubscribeFailure,
  unsubscribeSuccess,
} from "./actions";

import {apiSubscribe, apiUnsubscribe} from "./api";

function* toggleNavBarWorker(action) {
  try {
    yield put(toggleNavBarSuccess(action.payload));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(toggleNavBarFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* subscribeWorker(action) {
  try {
    const apiResult = yield call(apiSubscribe, action.payload);
    yield put(subscribeSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(subscribeFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

function* unsubscribeWorker(action) {
  try {
    const apiResult = yield call(apiUnsubscribe, action.payload);
    yield put(unsubscribeSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    yield put(unsubscribeFailure(errorMessage));
    console.log("errorMessage");
    console.log(errorMessage);
  }
}

export default function* watcher() {
  yield takeLatest(TOGGLE_NAV_BAR_REQUEST, toggleNavBarWorker);
  yield takeLatest(SUBSCRIBE_REQUEST, subscribeWorker);
  yield takeLatest(UNSUBSCRIBE_REQUEST, unsubscribeWorker);
}
