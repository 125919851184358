import React, {useState} from "react";
import {
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from "@mui/material";
import {reduxForm} from "redux-form";
import {compose} from "recompose";
import Visits from "./Visits";
import Birthday from "./Birthday";
import Gender from "./Gender";
import FirstVisit from "./FirstVisit";
import LastVisit from "./LastVisit";

import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";

const ButtonTextWrapper = styled.label`
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  color: ${(props) =>
    props.disabled ? "#C4C4C4 !important" : "#FF9933 !important"};
`;

const ModalMenuAddFilter = (props) => {
  const {
    change,
    destroy,
    handleSubmit,
    addFilterRequest,
    currentFilters,
    removeFilterRequest
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterSelection = (filterType) => {
    change("key", filterType);
    setAnchorEl(null);
  };

  const getIndex = (filter) => {
    return currentFilters.findIndex(obj => obj.key === filter);
  }

  const handleChange = (event) => {
    if (event.target.value === 'hasEmail') {
      // if(getIndex('hasEmail')){
      //   //removeFilterRequest(getIndex('hasEmail'));
      //    currentFilters.splice(getIndex('hasEmail'), 1);
      //   //currentFilters.filter(f => f.key === "hasEmail")
      // }
      addFilterRequest({
        key: 'hasEmail',
        operation: 'EQUALS',
        value: 'hasEmail'
      });
      handleClose();
    }

    if (event.target.value === 'hasPhone') {
      // if(getIndex('hasPhone')){
      //   // removeFilterRequest(getIndex('hasPhone'));
      //   // //currentFilters.splice(getIndex('hasPhone'), 1);
      //   // currentFilters.filter(f => f.key === "hasPhone")
      // }
      addFilterRequest({
        key: 'hasPhone',
        operation: 'EQUALS',
        value: 'hasPhone'
      });
      handleClose();
    }
  };

  return (
      <form onSubmit={handleSubmit}>
        <ButtonTextWrapper onClick={handleFilterClick}>
          Add Filter
        </ButtonTextWrapper>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '225px',
                height: '305px',
                justifyContent: 'center',
              },
            }}
        >
          <MenuItem onClick={() => handleFilterSelection("visitCount")}>
            <Visits
                change={change}
                destroy={destroy}
                handleSubmit={handleSubmit}
                addFilterRequest={addFilterRequest}
            />
          </MenuItem>

          <MenuItem onClick={() => handleFilterSelection("birthday")}>
            <Birthday
                change={change}
                destroy={destroy}
                handleSubmit={handleSubmit}
                addFilterRequest={addFilterRequest}
            />
          </MenuItem>

          <MenuItem onClick={() => handleFilterSelection("gender")}>
            <Gender
                change={change}
                destroy={destroy}
                handleSubmit={handleSubmit}
                addFilterRequest={addFilterRequest}
            />
          </MenuItem>

          <MenuItem onClick={() => handleFilterSelection("firstVisit")}>
            <FirstVisit
                change={change}
                destroy={destroy}
                handleSubmit={handleSubmit}
                addFilterRequest={addFilterRequest}
            />
          </MenuItem>

          <MenuItem onClick={() => handleFilterSelection("lastVisit")}>
            <LastVisit
                change={change}
                destroy={destroy}
                handleSubmit={handleSubmit}
                addFilterRequest={addFilterRequest}
            />
          </MenuItem>

          <MenuItem>
            <FormControl>
              <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  // value={value}
                  onChange={handleChange}
              >
                <FormControlLabel value="hasEmail" control={<Radio sx={{
                  '&.Mui-checked': {
                    color: "#FF9933",
                  },
                }}/>} label="Has Email"/>
                <FormControlLabel value="hasPhone" control={<Radio sx={{
                  '&.Mui-checked': {
                    color: "#FF9933",
                  },
                }}/>} label="Has Phone Number"/>
              </RadioGroup>
            </FormControl>
          </MenuItem>
        </Menu>
      </form>
  );
};

export default compose(
    reduxForm({
      form: "ADD_FILTER_MODAL_FORM",
    })
)(ModalMenuAddFilter);
