import styled from "@emotion/styled";

import DIMENSIONS from "consts/dimensions";

const NewWifiLandingPageWrapper = styled.div`
  max-height: calc(100vh - ${DIMENSIONS.topBarHeight}px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .inner-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 10px;

    .edit {
      display: flex;
      flex-direction: column;
      flex: 1.5;
      background-color: white;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
      margin-left: 30px;
      border-right: 1px solid $grey-2;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 20px 20px 20px 20px;

      .landing-page-edit-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .landing-page-edit-title {
          display: flex;
          flex-direction: row;
          align-items: center;

          .landing-page-edit-title-text {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
              sans-serif;
            font-weight: 300;
            margin-left: 20px;
          }

          .landing-page-edit-logo {
            position: relative;
            top: -20px;
            display: flex;
            background-color: $accent-color;
            height: 60px;
            width: 60px;
            border-radius: 0 0 0 30px;
            justify-content: center;
            align-items: center;

            .landing-page-edit-logo-svg {
              width: 30px;
              height: 30px;

              path {
                fill: white;
              }
            }
          }
        }

        .landing-page-edit-actions {
          .landing-page-edit-top-select {
            margin-left: 10px;
            font-size: 12px;
            background-color: #e9e9e9;
            padding: 5px 10px 5px 10px;
            border-radius: 25px;
            color: #717171;
            cursor: pointer;
          }

          .landing-page-edit-top-select-selected {
            background-color: $accent-color;
            color: white;
          }
        }
      }

      .edit-landing-page-top-line {
        border: 0;
        clear: both;
        display: block;
        width: 100%;
        background-color: #eeeeee;
        height: 1px;
        margin-top: 0px;
      }

      .edit-landing-page-save-btn {
        min-width: 140px;
        border-radius: 25px;
        color: white;
        align-self: center;
        background-color: $accent-color;
        border: none;
        padding: 10px;
        outline: 0;
        margin: 20px;
        cursor: pointer;
      }
    }
  }
`;

export default NewWifiLandingPageWrapper;
