import {
  SET_CHART_TIME_UNIT_REQUEST,
  SET_CHART_TIME_UNIT_FAILURE,
  SET_CHART_TIME_UNIT_SUCCESS,
  UPDATE_CHART_DATA_REQUEST,
  UPDATE_CHART_DATA_FAILURE,
  UPDATE_CHART_DATA_SUCCESS,
} from "./actions";

// const chartConfig = {
//   backgroundColor: "rgba(254, 203, 69, 0.3)",
//   borderWidth: 3,
//   borderColor: "rgba(254, 203, 69, 1)",
//   tension: 0.5,
// }; //TRY TO SET AS GLOBAL

const initialState = {
  loading: false,
  errorMessage: null,
  chartTimeUnit: null,
  chartData: {
    year: null,
    month: null,
    week: null,
    day: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    //---

    case SET_CHART_TIME_UNIT_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case SET_CHART_TIME_UNIT_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        chartTimeUnit: null,
      };
    }

    case SET_CHART_TIME_UNIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        chartTimeUnit: action.payload,
      };
    }

    //---

    case UPDATE_CHART_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case UPDATE_CHART_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        chartTimeUnit: null,
      };
    }

    case UPDATE_CHART_DATA_SUCCESS: {
      const { timeUnit, mappedChartData } = action.payload;
      return {
        ...state,
        loading: false,
        errorMessage: null,
        chartTimeUnit: timeUnit,
        chartData: {
          ...state.chartData,
          [timeUnit]: mappedChartData,
        },
      };
    }

    default:
      return state;
  }
}
