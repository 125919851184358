import React, { useState } from "react";

import CreateCampaignPopup from "./CreateCampaignPopup";
import DeleteTemplatePopup from "./DeleteTemplatePopup.js";

import SendCreateWrapper from "./SendCreateWrapper";

import SendCreateTop from "./SendCreateTop";
import SendCreateBottom from "./SendCreateBottom";

import useUser from "utils/hooks/useUser";

const SendCreate = (props) => {
  const {
    getFeaturedTemplatesRequest,
    getSavedTemplatesRequest,
    // savedTemplates,
    // featuredTemplates,
    // createTemplate,
    organisationId,
    updateCurrentCampaignRequest,
    savedTemplatesPagination,
    featuredTemplatesPagination,
    goToNextStep,
    deleteTemplateRequest
  } = props;

  const [templateType, setTemplateType] = useState("featured");

  React.useEffect(() => {
    organisationId && getSavedTemplatesRequest(organisationId);
    organisationId && getFeaturedTemplatesRequest();
  }, [organisationId]);

  const [showAddCampaign, setShowAddCampaign] = useState(false);
  const [showDeleteTemplatePopup, setShowDeleteTemplatePopup] = useState(false);
  // const [selectedTemplate, setSelectedTemplate] = useState();
  const [currentPagination, setCurrentPagination] = useState(null);

  const user = useUser();

  React.useEffect(() => {
    if (templateType === "featured") {
      getFeaturedTemplatesRequest();
    }

    if (templateType === "saved") {
      getSavedTemplatesRequest(organisationId);
    }
  }, [templateType]);

  React.useEffect(() => {
    if (templateType === "featured") {
      setCurrentPagination(featuredTemplatesPagination);
    }

    if (templateType === "saved") {
      setCurrentPagination(savedTemplatesPagination);
    }
  }, [featuredTemplatesPagination, savedTemplatesPagination]);


  return (
    <SendCreateWrapper>
      <SendCreateTop
        organisationId={organisationId}
        templateType={templateType}
        setTemplateType={setTemplateType}
        currentPagination={currentPagination}
        getFeaturedTemplatesRequest={getFeaturedTemplatesRequest}
        getSavedTemplatesRequest={getSavedTemplatesRequest}
      />

      <SendCreateBottom
        {...props}
        templateType={templateType}
        setTemplateType={setTemplateType}
        setShowAddCampaign={setShowAddCampaign}
        isSuperAdmin={user?.isSuperAdmin}
        deleteTemplateRequest={deleteTemplateRequest}
      />

      {showAddCampaign && (
        <CreateCampaignPopup
          close={() => {
            updateCurrentCampaignRequest(organisationId, "template", null);
            setShowAddCampaign(false);
          }}
          save={(name) => {
            updateCurrentCampaignRequest(organisationId, "name", name);
            goToNextStep();
          }}
        />
      )}

      {showDeleteTemplatePopup && (
        <DeleteTemplatePopup organisationId={organisationId} />
      )}
    </SendCreateWrapper>
  );
};

export default SendCreate;
