import React from "react";
import styled from "@emotion/styled";
import {PAGE_SIZE} from "../../../../containers/ContactsList/api";
import {Box, ListItemIcon} from "@mui/material";

const AudienceNameItemWrapper = styled.a`
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 1px;
  
  background: ${(props) =>
          props.isThisItemSelected
                  ? "linear-gradient(90deg, rgba(253,237,216,1) 0%, rgba(253,237,216,1) 55%, rgba(253,237,216,0) 95%)"
                  : "white"};

  &:hover {
    background: linear-gradient(90deg, rgba(253, 237, 216, 1) 0%, rgba(253, 237, 216, 1) 55%, rgba(253, 237, 216, 0) 95%);
    cursor: pointer;
  }

  &:active {
    background: linear-gradient(90deg, rgba(253, 237, 216, 1) 0%, rgba(253, 237, 216, 1) 55%, rgba(253, 237, 216, 0) 95%);
  }
`;

const ListItemName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  margin-left: 12px;

  .title {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    opacity: 0.75;
  }
`;

const ListItemColor = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 10px;
  background-color: ${(props) => props.color};
`;

const AudienceNameItem = (props) => {
  const {
    organisationId,
    id,
    name,
    color,
    selectAudienceRequest,
    getAudienceContactsRequest,
    selectListTypeRequest,
    listTypes,
    selectedAudience,
    searchQuery,
    setSelectedContactsIds,
    setIsAllContactsSelected,
    currentListType,
    removeFilterRequest,
    contactsList,
    setContactsList,
    setPage
  } = props;

  const [isThisItemSelected, setIsThisItemSelected] = React.useState(false);

  React.useEffect(() => {
    if (selectedAudience && currentListType === "audiences") {
      setIsThisItemSelected(selectedAudience.id === id);
    } else {
      setIsThisItemSelected(false);
    }
  }, [selectedAudience]);

  const handleClick = (id, organisationId) => {
     removeFilterRequest();
     setSelectedContactsIds([]);
     setIsAllContactsSelected(false);
     selectListTypeRequest(listTypes.AUDIENCES);
     selectAudienceRequest(id);
     getAudienceContactsRequest(organisationId, id, 0, PAGE_SIZE, searchQuery);
     setPage(0);
  };

  return (
      <AudienceNameItemWrapper
          isThisItemSelected={isThisItemSelected}
          onClick={() => handleClick(id, organisationId)}
      >
        {name &&
            <Box sx={{margin: 2, fontFamily: "'Roboto',sans-serif !important", fontSize:"16px"}}>
              <ListItemIcon>
                <span role="emoji" aria-label="emoji">{color}</span>
              </ListItemIcon>
              {name}
            </Box>
        }

      </AudienceNameItemWrapper>
  );
};

export default AudienceNameItem;
