import {call, put, takeLatest} from "redux-saga/effects";

import {apiGetEmailsStats, apiGetOpenRateStats} from "./api";

import setOneDayAgo from "utils/time/setOneDayAgo";
import setOneWeekAgo from "utils/time/setOneWeekAgo";
import setOneMonthAgo from "utils/time/setOneMonthAgo";
import setOneYearAgo from "utils/time/setOneYearAgo";

import {
  GET_DAY_OPEN_RATE_STATS_REQUEST,
  GET_EMAILS_STATS_REQUEST,
  GET_MONTH_OPEN_RATE_STATS_REQUEST,
  GET_WEEK_OPEN_RATE_STATS_REQUEST,
  GET_YEAR_OPEN_RATE_STATS_REQUEST,
  getDayOpenRateStatsFailure,
  getDayOpenRateStatsSuccess,
  getEmailsStatsFailure,
  getEmailsStatsSuccess,
  getMonthOpenRateStatsFailure,
  getMonthOpenRateStatsSuccess,
  getWeekOpenRateStatsFailure,
  getWeekOpenRateStatsSuccess,
  getYearOpenRateStatsFailure,
  getYearOpenRateStatsSuccess,
  UPDATE_SEND_CONTACTS_REQUEST,
  updateSendContactsFailure,
  updateSendContactsSuccess,
} from "./actions";

function* getEmailsStatsWorker(action) {
  try {
    const apiResult = yield call(apiGetEmailsStats, action.payload);

    yield put(getEmailsStatsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getEmailsStatsFailure(errorMessage));
  }
}

function* getYearOpenRateStatsWorker(action) {
  try {
    const oneYearAgo = setOneYearAgo();
    action.payload.startDate = oneYearAgo;
    action.payload.endDate = Math.ceil(new Date() / 1000);
    action.payload.timeUnit = "monthly";
    const apiResult = yield call(apiGetOpenRateStats, action.payload);

    yield put(getYearOpenRateStatsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getYearOpenRateStatsFailure(errorMessage));
  }
}

function* getMonthOpenRateStatsWorker(action) {
  try {
    const oneMonthAgo = setOneMonthAgo();
    action.payload.startDate = oneMonthAgo;
    action.payload.endDate = Math.ceil(new Date() / 1000);
    action.payload.timeUnit = "daily";
    const apiResult = yield call(apiGetOpenRateStats, action.payload);

    yield put(getMonthOpenRateStatsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getMonthOpenRateStatsFailure(errorMessage));
  }
}

function* getWeekOpenRateStatsWorker(action) {
  try {
    const oneWeekAgo = setOneWeekAgo();
    action.payload.startDate = oneWeekAgo;
    action.payload.endDate = Math.ceil(new Date() / 1000);
    action.payload.timeUnit = "daily";
    const apiResult = yield call(apiGetOpenRateStats, action.payload);

    yield put(getWeekOpenRateStatsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getWeekOpenRateStatsFailure(errorMessage));
  }
}

function* getDayOpenRateStatsWorker(action) {
  const oneDayAgo = setOneDayAgo();
  action.payload.startDate = oneDayAgo;
  action.payload.endDate = Math.ceil(new Date() / 1000);
  action.payload.timeUnit = "hourly";
  try {
    const apiResult = yield call(apiGetOpenRateStats, action.payload);

    yield put(getDayOpenRateStatsSuccess(apiResult.data));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(getDayOpenRateStatsFailure(errorMessage));
  }
}

function* updateSendContactsWorker(action) {
  const { updatedContacts } = action.payload;
  try {
    yield put(updateSendContactsSuccess(updatedContacts));
  } catch (error) {
    const errorMessage = yield error.message;
    console.log(errorMessage);
    yield put(updateSendContactsFailure(errorMessage));
  }
}

export default function* watcher() {
  yield takeLatest(GET_EMAILS_STATS_REQUEST, getEmailsStatsWorker);
  yield takeLatest(
    GET_YEAR_OPEN_RATE_STATS_REQUEST,
    getYearOpenRateStatsWorker
  );
  yield takeLatest(
    GET_MONTH_OPEN_RATE_STATS_REQUEST,
    getMonthOpenRateStatsWorker
  );
  yield takeLatest(
    GET_WEEK_OPEN_RATE_STATS_REQUEST,
    getWeekOpenRateStatsWorker
  );
  yield takeLatest(GET_DAY_OPEN_RATE_STATS_REQUEST, getDayOpenRateStatsWorker);
  yield takeLatest(UPDATE_SEND_CONTACTS_REQUEST, updateSendContactsWorker);
}
