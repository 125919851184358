import React from "react";
import styled from "@emotion/styled";

const InputButtonWrapper = styled.button`
  border: 0;
  border-radius: 0.25rem;
  background: #1e88e5;
  color: white;
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  cursor: pointer;

  label {
    font-family: 16px;
  }
`;

const InputButton = (props) => {
  const { onClick, currentDate } = props;

  return (
    <InputButtonWrapper onClick={onClick}>
      <label>{currentDate}</label>
    </InputButtonWrapper>
  );
};

export default InputButton;
