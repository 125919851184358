import React, { useState, useEffect, useContext, useRef } from "react";

import Loader from "react-loader-spinner";
import Chart from "components/main-dashboard/widgets/chart";
import { useHistory } from "react-router-dom";
import { ReactComponent as MenuIcon } from "assets/images/menu-btn.svg";
import api from "utils/api";
import { VenuesContext } from "context/venueContext";

import "assets/css/sms/dashboard/dashboard.scss";

import DashboardTop from "./components/DashboardTop";
import { ampli } from "containers/Amplitude/ampli";

const SmsDashboard = (props) => {
  const history = useHistory();
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [analytics, setAnalytics] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [cost, setCost] = useState({});
  const menuRef = useRef(null);

  const { selected } = useContext(VenuesContext);

  const [selectors, setSelectors] = useState([
    {
      externalId: 0,
      name: "Day",
      unit: "day",
      selected: false,
      apiUnit: "hourly",
    },
    {
      externalId: 1,
      name: "Week",
      unit: "week",
      selected: false,
      apiUnit: "daily",
    },
    {
      externalId: 2,
      name: "Month",
      unit: "month",
      selected: true,
      apiUnit: "daily",
    },
    {
      externalId: 3,
      name: "Year",
      unit: "year",
      selected: false,
      apiUnit: "monthly",
    },
  ]);

  const [campaigns, setCampaigns] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [dueDate, setDueDate] = useState(new Date());
  const [percent, setPercent] = useState(0);

  const onBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setSelectedCampaign(null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const selectedUnit = selectors.find((s) => s.selected);
      const { data } = await api.get(
        `/sms/organisation/${selected.externalId}/messages/statistics?unit=${
          selectedUnit.apiUnit
        }&startDate=${getStartDate(selectedUnit.unit)}`
      );
      setAnalytics(data);
    };

    if (selected) fetchData();
    ampli.unaroPageVisitEventWithProperties({
      emailAddress: JSON.parse(localStorage.getItem("user")).email,
      organisationName:
        JSON.parse(localStorage.getItem("user")).organisations[0] != null
          ? JSON.parse(localStorage.getItem("user")).organisations[0].name
          : "",
          eventType: "page visit: SMS -> Dashboard",
    });
  }, [selected, JSON.stringify(selectors)]);

  const getDueDate = () => {
    var counter = 0;
    const currentDate = new Date();
    while (currentDate.getDate() !== 15 && counter < 100) {
      currentDate.setDate(currentDate.getDate() + 1);
      counter++;
    }
    return currentDate;
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(
        `/sms/organisation/${selected.externalId}/costs`
      );
      setCost(data);
      const due = getDueDate();
      setDueDate(due);
      setPercent(
        ((data.messagesLeft + data.totalMessages) * data.messagesLeft) / 100
      );
    };
    if (selected) fetchData();
  }, [selected]);

  useEffect(() => {
    if (campaigns && campaigns.length > 0) {
      const schedules = campaigns
        .map((campaign) => {
          const firstSchedule = campaign.schedules.reduce((a, b) =>
            a.nextFireTime < b.nextFireTime ? a : b
          );
          return {
            externalId: firstSchedule.externalId,
            campaignExternalId: campaign.externalId,
            campaignName: campaign.name,
            state: firstSchedule.state,
            stateOrder:
              firstSchedule.state === "NONE"
                ? 0
                : firstSchedule.state === "NORMAL"
                ? 1
                : 2,
            nextFireTime: new Date(firstSchedule.nextFireTime * 1000),
          };
        })
        .sort((a, b) => a.nextFireTime.getTime() - b.nextFireTime.getTime())
        .sort((a, b) => b.stateOrder - a.stateOrder);

      setJobs(schedules);
    }
  }, [campaigns]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(
        `/sms/organisation/${selected.externalId}/messages/status/statistics`
      );
      setStatistics(data);
    };
    if (selected) fetchData();
  }, [selected]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingCampaigns(true);
      const { data } = await api.get(
        `/sms/organisation/${selected.externalId}/campaign`
      );
      setCampaigns(data);
      setLoadingCampaigns(false);
    };
    if (selected) fetchData();
  }, [selected]);

  const pauseCampaign = async () => {
    if (selectedCampaign.state === "PAUSED") {
      await api.put(
        `/sms/campaign/${selectedCampaign.campaignExternalId}/resume`
      );
      setJobs(
        jobs.map((job) =>
          job.externalId === selectedCampaign.externalId
            ? { ...job, state: "NORMAL" }
            : job
        )
      );
    } else {
      await api.put(
        `/sms/campaign/${selectedCampaign.campaignExternalId}/pause`
      );
      setJobs(
        jobs.map((job) =>
          job.externalId === selectedCampaign.externalId
            ? { ...job, state: "PAUSED" }
            : job
        )
      );
    }
    setSelectedCampaign(null);
  };

  const endCampaign = async () => {
    await api.delete(
      `/sms/campaign/${selectedCampaign.campaignExternalId}/end`
    );
    setJobs(
      jobs.map((job) =>
        job.externalId === selectedCampaign.externalId
          ? { ...job, state: "NONE" }
          : job
      )
    );
    setSelectedCampaign(null);
  };

  const showMenu = (e, job) => {
    e.stopPropagation();
    setSelectedCampaign(job);
  };

  useEffect(() => {
    if (selectedCampaign) menuRef.current.focus();
  }, [selectedCampaign]);

  const changeSelected = (selected) => {
    setSelectors(
      selectors.map((s) => ({
        ...s,
        selected: s.externalId === selected.externalId,
      }))
    );
  };

  const getStartDate = (unit) => {
    if (unit === "day") {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 1);
      startDate.setHours(startDate.getHours(), 0, 0, 0);
      return startDate.getTime() / 1000;
    } else if (unit === "week") {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      startDate.setHours(0, 0, 0, 0);
      return startDate.getTime() / 1000;
    } else if (unit === "month") {
      const now = new Date();
      const startDate = new Date();
      startDate.setDate(
        startDate.getDate() -
          new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()
      );
      startDate.setHours(0, 0, 0, 0);
      return startDate.getTime() / 1000;
    } else if (unit === "year") {
      const startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      startDate.setMonth(startDate.getMonth() - 12, 1);
      return startDate.getTime() / 1000;
    }
  };

  return (
    <div className="campaign-dashboard">
      <DashboardTop selectors={selectors} changeSelected={changeSelected} />

      <div className="cd-stats">
        <div className="cd-sms-stats">
          <div className="cd-stat" onClick={() => history.push("/sms/reports")}>
            <label>{statistics.success}</label>
            <label>Total Texts Sent</label>
          </div>
          <div className="cd-stat" onClick={() => history.push("/sms/reports")}>
            <label className="failed">{statistics.error}</label>
            <label>Texts Failed</label>
          </div>
          <div
            className="cd-stat"
            onClick={() => history.push("/sms/contacts")}
          >
            <label className="total">{statistics.contacts}</label>
            <label>Total SMS Contacts</label>
          </div>
          <div
            className="cd-stat"
            onClick={() => history.push("/sms/campaigns/manage")}
          >
            <label>{jobs.filter((job) => job.state !== "NONE").length}</label>
            <label>Campaigns Running</label>
          </div>
        </div>
        <div className="cd-chart">
          <Chart
            style={{ flex: 1 }}
            analytics={analytics}
            showSelectors={false}
            title="Texts Sent"
            yTitle="Texts Sent"
            field="messages"
            selectors={selectors}
            setSelectors={setSelectors}
          />
        </div>
      </div>
      <div className="cd-campaigns">
        {jobs && jobs.length > 0 ? (
          <div className="campaigns-container">
            <div className="campaigns">
              {jobs.map((job) => (
                <div
                  className="cd-campaign"
                  key={job.campaignExternalId}
                  onClick={() =>
                    history.push(`/sms/reports/${job.campaignExternalId}`)
                  }
                >
                  <div className="campaign-info">
                    <div className="dot" />
                    <label>Next campaign scheduled:</label>
                    <label>{job.campaignName}</label>
                    {job.state !== "NONE" && (
                      <label>
                        {job.nextFireTime.getHours() < 10
                          ? `0${job.nextFireTime.getHours()}`
                          : job.nextFireTime.getHours()}
                        :
                        {job.nextFireTime.getMinutes() < 10
                          ? `0${job.nextFireTime.getMinutes()}`
                          : job.nextFireTime.getMinutes()}{" "}
                        {job.nextFireTime.getDate()}/
                        {job.nextFireTime.getMonth() + 1}/
                        {job.nextFireTime.getFullYear()}
                      </label>
                    )}
                  </div>
                  <div className="campaign-actions">
                    <label
                      className="campaign-state"
                      style={{
                        marginRight: job.state === "NONE" ? "70px" : "0",
                        background:
                          job.state === "NONE"
                            ? "#FFCC33"
                            : job.state === "NORMAL"
                            ? "#E85558"
                            : job.state === "PAUSED"
                            ? "#FF9933"
                            : "#ffffff",
                      }}
                    >
                      {job.state === "NONE"
                        ? "Sent"
                        : job.state === "NORMAL"
                        ? "Pending"
                        : job.state === "PAUSED"
                        ? "Paused"
                        : ""}
                    </label>
                    {job.state !== "NONE" && (
                      <MenuIcon
                        className="menu-icon"
                        onClick={(e) => showMenu(e, job)}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            {selectedCampaign && (
              <div
                className="campaign-menu"
                ref={menuRef}
                tabIndex="0"
                onBlur={onBlur}
              >
                <label onClick={pauseCampaign}>
                  {selectedCampaign.state === "PAUSED" ? "Resume" : "Pause"}{" "}
                  Campaign
                </label>
                <hr />
                <label
                  onClick={() =>
                    history.push(
                      `/sms/campaigns/${selectedCampaign.campaignExternalId}`
                    )
                  }
                >
                  Edit Campaign
                </label>
                <hr />
                <label onClick={endCampaign}>End Campaign</label>
              </div>
            )}
          </div>
        ) : (
          <div className="no-campaigns-container">
            {loadingCampaigns ? (
              <Loader type="Oval" color="#EA5459" height={30} width={30} />
            ) : (
              <div className="no-campaigns">
                <label>You have no campaigns yet</label>
                <label>Click the button below to start</label>
                <button
                  className="create-btn"
                  onClick={() => history.push("/sms/campaigns/new")}
                >
                  Create Campaign
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SmsDashboard;
