import React from "react";

import "assets/css/number-navigator.scss";

export const NavigationNumber = (props) => {
  const { navigator, onClick, currentPage } = props;

  const isSelected = navigator.id === currentPage;

  return (
    <div
      className={`number-navigator ${
        isSelected && "number-navigator-selected"
      }`}
      onClick={() => onClick(currentPage, navigator.id)}
    >
      <label>{navigator.text}</label>
    </div>
  );
};
