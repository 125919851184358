import React, {useEffect, useState} from 'react';
import {
  Backdrop,
  Fade,
  ListItemIcon,
  Modal,
  Button,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {from, map} from 'rxjs';
import api from "../../../utils/api";
import uuid from "react-uuid";
import {saveAs} from 'file-saver';
import {AxiosResponse} from "axios";
import moment from "moment";
import downArrowPng from "../../../assets/images/DownArrow.png";
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import styled from "@emotion/styled";
import group41Png from "../../../assets/images/Group41.png";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '25px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1,
  },
}));

const ConfirmationPlaceholder = styled.div`
  position: relative;
  width: 565px;
  height: 280px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const ConfirmationExportComplete = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 32px;
  position: relative;
  width: 565px;
  height: 231px;
  background: #FFFFFF;
  border-radius: 16px;
`;

const ImageDiv = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
  top: 43.8px;
`;

const HeaderImage = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(46, 125, 50, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const WarningWrapper = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  left: 234.5px;
  top: 20px;
`;

const HeaderWarningAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(255, 153, 51, 0.12);
  border-radius: 50%;
  display: inline-block;
`;

const HeaderErrorAmber = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(234, 84, 84, 0.3);
  border-radius: 50%;
  display: inline-block;
`;

const TypographyText = styled.div`
  position: absolute;
  height: 64px;
  left: 36px;
  right: 36px;
  top: 150.81px;
  font-family: 'Trebuchet MS', sans-serif' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const TypographyTextExportCompleted = styled.div`
  position: absolute;
  height: 32px;
  left: 44.5px;
  right: 44.5px;
  top: 170.81px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: rgba(15, 15, 15, 0.87);
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-left: 15%;
  gap: 24px;
  color: #ff9933;
  width: 120px;
  height: 42px;
`;

const options = [
  {
    label: "Export Selected Contacts",
    value: "selected",
  },
  {
    label: "Export My Customers",
    value: "all",
  }
];

const getTimestamp = () => moment().format("YYYY-MM-DD-HH:mm");

const Export = (props) => {
  const {
    organisationId,
    selectedContactsIds,
    allContactsIds,
    selectedCustomers,
    setSelectedContactsIds,
    setSelectedCustomers
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openExportComplete, setOpenExportComplete] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [exportType, setExportType] = useState('selected');
  const [contactCount, setContactCount] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenExportComplete = () => {
    setOpenExportComplete(true);
  };

  const handleCloseExportComplete = () => {
    setOpenExportComplete(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleOpenError = () => {
    setOpenError(true);
  };

  useEffect(() => {
    setContactCount(
        exportType === 'all'
            ? allContactsIds ? Object.keys(allContactsIds).length : 0
            : selectedContactsIds ? selectedContactsIds.length : 0
    )
  }, [exportType, allContactsIds, selectedContactsIds]);

  const exportContacts = () => {
    const contactIds = exportType === 'all'
        ? Object.keys(allContactsIds)
        : selectedContactsIds;
    const request = {
      organisationId: organisationId,
      contactIds: contactIds
    };
    from(api.post('/contacts/export', request))
    .pipe(map((response: AxiosResponse<any>) => response.data))
    .subscribe({
      next: (data) => {
        if (data.trim() !== "") {
          saveAs(new Blob([data], {type: "text/plain;charset=utf-8"}),
              `contact-export-${getTimestamp()}.csv`);
          setOpen(false);
          setOpenExportComplete(true);
          setTimeout(
              () => {
                setOpenExportComplete(false);
                setSelectedContactsIds([]);
                setSelectedCustomers([]);
              },
              2000
          );
        } else {
          handleOpenError();
        }
      },
      error: (err) => {
        console.error('Error exporting contacts')
        console.error(err);
        toast.error('Error exporting contacts');
      },
      complete: () => {
        handleClose();
      }
    });
  }

  return (
      <div>
        <div onClick={handleOpen}>
          <ListItemIcon>
            <FileDownloadRoundedIcon fontSize="small"/>
          </ListItemIcon>
          Export
        </div>


        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open && selectedContactsIds.length > 0}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={open && selectedContactsIds.length > 0}>
            <div className={classes.paper}>
              <ConfirmationPlaceholder>
                <img
                    alt="group"
                    onClick={handleClose}
                    className={"closeImg"}
                    src={group41Png}
                />
                <WarningWrapper>
                  <HeaderWarningAmber>
                    <DownloadRoundedIcon
                        sx={{
                          fontSize: 45,
                          padding: 3,
                          color: "#FF9933",
                        }}
                    />
                  </HeaderWarningAmber>
                </WarningWrapper>
                <TypographyText>
                  Are you sure you want to export ({selectedContactsIds
                    && selectedContactsIds.length}) {" "} contacts?
                </TypographyText>
                <ButtonSection>
                  <Button
                      onClick={exportContacts}
                      sx={{
                        color: "#FF9933",
                        fontSize: 16,
                        fontWeight: "500",
                        zIndex: 1,
                        marginTop: 55,
                        marginLeft: '32ch',
                        marginRight: 4
                      }}
                      variant="text"
                      disabled={!selectedContactsIds.length > 0}
                  >
                    YES
                  </Button>
                  <Button
                      onClick={handleClose}
                      sx={{
                        color: "#FF9933",
                        fontSize: 16,
                        fontWeight: "500",
                        zIndex: 1,
                        marginTop: 55,
                        // marginLeft: '30ch',
                        marginRight: 4
                      }}
                      variant="text"
                  >
                    CANCEL
                  </Button>
                </ButtonSection>
              </ConfirmationPlaceholder>
            </div>
          </Fade>
        </Modal>


        {/* EXPORT COMPLETE */}
        {((openExportComplete === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openExportComplete}
                onClose={handleCloseExportComplete}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openExportComplete}>
                <div className={classes.paper}>
                  <ConfirmationExportComplete>
                    <img
                        alt="group"
                        onClick={handleCloseExportComplete}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <ImageDiv>
                      <HeaderImage>
                        <CheckCircleOutlineIcon
                            sx={{
                              fontSize: 60,
                              padding: 2.3,
                              color: "#1DB350",
                            }}
                        />
                      </HeaderImage>
                    </ImageDiv>
                    <>
                      <TypographyTextExportCompleted>
                        Export complete!
                        <br/>
                        ({selectedContactsIds
                          && selectedContactsIds.length}) {" "} Contacts
                        successfully exported
                      </TypographyTextExportCompleted>
                    </>
                  </ConfirmationExportComplete>
                </div>
              </Fade>
            </Modal>
        )}


        {/*CONTACT EXPORT ERROR*/}
        {((openError === true)) && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openError}
                onClose={handleCloseError}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
              <Fade in={openError}>
                <div className={classes.paper}>
                  <ConfirmationExportComplete>
                    <img
                        alt="group"
                        onClick={handleCloseError}
                        className={"closeImg"}
                        src={group41Png}
                    />
                    <ImageDiv>
                      <HeaderErrorAmber>
                        <WarningAmberRoundedIcon
                            sx={{
                              fontSize: 45,
                              padding: 3,
                              color: "#EA5454",
                            }}
                        />
                      </HeaderErrorAmber>
                    </ImageDiv>
                    <TypographyTextExportCompleted>
                      <Typography variant="h6" color="red">
                        Error Exporting Contacts
                      </Typography>
                      <Typography fontSize={'large'}>
                        Please try again later?
                      </Typography>
                    </TypographyTextExportCompleted>
                  </ConfirmationExportComplete>
                </div>
              </Fade>
            </Modal>
        )}


        {/*<Modal*/}
        {/*    aria-labelledby="transition-modal-title"*/}
        {/*    aria-describedby="transition-modal-description"*/}
        {/*    className={classes.modal}*/}
        {/*    open={open}*/}
        {/*    onClose={handleClose}*/}
        {/*    closeAfterTransition*/}
        {/*    BackdropComponent={Backdrop}*/}
        {/*    BackdropProps={{*/}
        {/*      timeout: 500,*/}
        {/*    }}*/}
        {/*>*/}
        {/*  <Fade in={open}>*/}
        {/*    <div className={classes.paper}>*/}
        {/*      <img alt="close" onClick={handleClose} className={'closeImg'}*/}
        {/*           src={require("../../../assets/images/Group41.png").default}/>*/}
        {/*      <div*/}
        {/*          className={'audienceBlock addContactBlock addContactBlockSuccess exportContact'}>*/}
        {/*        <div className={'createContact'}>*/}
        {/*          <div className="select-contact">*/}
        {/*            <select*/}
        {/*                defaultValue={exportType}*/}
        {/*                onChange={(e) => setExportType(e.target.value)}*/}
        {/*            >*/}
        {/*              {options.map((option) => (*/}
        {/*                  <option*/}
        {/*                      key={uuid()}*/}
        {/*                      value={option.value}*/}
        {/*                  >*/}
        {/*                    {option.label}*/}
        {/*                  </option>*/}
        {/*              ))}*/}
        {/*            </select>*/}
        {/*          </div>*/}
        {/*          <h2 id="simple-modal-title">Export Contacts</h2>*/}
        {/*          <p>Export {contactCount} Selected Contacts as CSV file</p>*/}
        {/*          <div className={'exportButton'}>*/}
        {/*            <div className={'exportCSV'} onClick={exportContacts}>*/}
        {/*              Export CSV*/}
        {/*            </div>*/}
        {/*            <div className={'cancelCSV'} onClick={handleClose}>*/}
        {/*              Cancel*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Fade>*/}
        {/*</Modal>*/}


      </div>
  );
}

export default Export;
