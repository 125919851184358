import styled from "@emotion/styled";

const JoinScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  border: 1px solid grey;
  padding: 20px;

  .content-divider {
    border: 0;
    clear: both;
    display: block;
    width: 90%;
    background-color: $grey-2;
    height: 1px;
  }

  .join-screen__main-content {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 25px;
    height: 100%;
    justify-content: space-evenly;
    align-items:center;
    background: rgba(255, 255, 255,
      ${props => props.opacity ? props.opacity : 1});

    border-radius: 10px;
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
  }

  &__main-content {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 25px;
    height: 100%;
    justify-content: space-evenly;
    border-radius: 10px;
  }

  &__logo {
    width: 100%;
    height: 120px;
  }

  &__greeting {
    font-weight: bold;
    font-size: 12px;
  }

  &__description {
    font-size: 12px;
    padding: 12px;
  }

  &__logo-container {
    height: 130px;
    width: 100%;
  }

  &__button-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }

  &__button {
    border: 0;
    width: 90px;
    height: 30px;
    color: white;
    border-radius: 30px;
  }
  .join {
    background: $unaro-red;
  }

  .cancel {
    background: $unaro-yellow;
  }

  .join-screen-top {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      font-family: Futura PT Book;
    }

    .join-screen-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;

      button {
        margin: 2vh;
        width: 50px;
        height: 50px;
        outline: none;
        border-radius: 50px;
        border: none;
      }
    }

    .join-screen-small-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      margin-bottom: 5px;

      .phone-preview-logo {
        height: 150px;
        width: 150px;
        object-fit: contain;
      }

      .logo-placeholder {
        display: flex;
        height: 120px;
        width: 120px;
        border-radius: 50%;
        background-color: white;
      }

      .phone-preview-social {
        display: flex;
        flex-direction: row;

        margin-top: 20px;

        .social-preview {
          margin-top: 10px;
        }
      }
    }

    .phone-preview-text {
      margin-bottom: 20px;
    }

    .phone-preview-register-by-email-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .phone-preview-register-by-email {
        display: flex;
        flex-direction: row;
        background-color: white;
        align-items: center;
        opacity: 0.9;

        input {
          font-size: 18px;
          outline: 0;
          border: none;
          padding: 5px;
          max-width: 250px;
        }

        .phone-preview-go-btn {
          padding: 10px;
          border: none;
          color: white;
        }
      }
    }
  }

  .powered-by {
    display: flex;
    flex-direction: column;
    align-items: center;

    .powered-by-unaro-icon {
      display: flex;
      flex-direction: column;
      height: 10px;
    }
  }
`;

export default JoinScreenWrapper;
