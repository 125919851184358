import React from "react";
import styled from "@emotion/styled";

import "assets/css/number-navigator.scss";

const NavigatorNumberWrapper = styled.div`
  align-content: center;
  justify-content: center;
  display: flex;
  border-radius: 25px;
  width: 8px;
  height: 8px;
  color: grey;
  padding: 8px;
  outline: 0;
  text-align: center;
  margin: 2px;
  cursor: pointer;

  label {
    align-self: center;
    text-align: center;
    cursor: pointer;
  }

  .number-navigator-selected {
    background-color: $accent-color;
    color: white;
  }
`;

const NavigatorNumber = (props) => {
  const { navigator, onClick, currentPage } = props;

  const isSelected = navigator.id === currentPage;

  return (
    <NavigatorNumberWrapper
      className={`number-navigator ${
        isSelected && "number-navigator-selected"
      }`}
      // onClick={() => onClick(currentPage, navigator.id)}
      onClick={onClick}
    >
      <label>{navigator.text}</label>
    </NavigatorNumberWrapper>
  );
};

export default NavigatorNumber;
