import { all, fork } from "redux-saga/effects";

import dashboardSagas from "./dashboardSagas";

import landingPageSagas from "./landingPageSagas";

import trackAndTraceSagas from "./trackAndTraceSagas";

export default function* connectSaga() {
  yield all([fork(dashboardSagas)]);
  yield all([fork(landingPageSagas)]);
  yield all([fork(trackAndTraceSagas)]);
}
