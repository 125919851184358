import styled from "@emotion/styled";

const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  outline: 0;
  user-select: none;
  cursor: pointer;
  position: relative;
  margin-right: 55px;


  .dropdown {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 220px;
    background: #f7f7f7;
    border-radius: 32px;
    padding: 8px 16px;
    outline: 0;
    /* border: 1px solid #ea5454; */
    border:1px solid #C4C4C4;
    cursor: pointer;
    padding:12px 20px;

    label {
      color: #5b5b5b;
      font-size: 14px;
      font-weight: 400;
      font-family: Roboto;
      cursor: pointer;
    }

    .logo {
      transition-duration: 0.5s;
      transition-property: transform;
    }
  }

  .dropdown-open {
    .logo {
      transform: rotate(180deg);
    }
  }

  .dropdown--secondary-background-true {
    background: white;
  }

  .options {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    min-width: 270px;
    padding: 10px 0px 10px 0px;
    border-radius: 10px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);
    z-index: 2;
    cursor: pointer;

    label {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 10px;
      font-size: 15px;
      color: #3c4858;
      cursor: pointer;
    }

    label:hover {
      background-color: $accent-color;
      color: white;
    }
  }

  .dropdown-position {
    &--top {
      bottom: 45px;
    }
    &--bottom {
      top: 45px;
    }
  }
`;

export default DropDownWrapper;
