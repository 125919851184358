import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {Duration, DurationUnit} from "../types";
import {titleCase} from "../AutomationService";
import Grid from '@mui/material/Unstable_Grid2';

type Props = {
  duration?: Duration
  setDuration: Dispatch<SetStateAction<Duration>>
}

const DurationSelect = ({duration, setDuration}: Props) => {
  const [amount, setAmount] = useState<number>(1);
  const [unit, setUnit] = useState<DurationUnit>(DurationUnit.DAY);

  useEffect(() => {
    if (duration) {
      setAmount(duration.amount);
      setUnit(duration.unit);
    }
  }, [duration]);

  useEffect(() => {
    if (amount && amount > 0 && unit) {
      setDuration({
        amount: amount,
        unit: unit
      })
    }
  }, [amount, unit, setDuration]);

  const onChangeAmount = (amount: number) => {
    if (amount > 0) {
      setAmount(amount);
    }
  }

  return (
      <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
      >
        <Grid xs={5}>
          <TextField
              size="small"
              sx={{width: 100, marginTop: 1.3}}
              key='add-param-value'
              label="Value"
              type='number'
              value={amount ? amount : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChangeAmount(parseFloat(e.target.value))}
          />
        </Grid>
        <Grid xs={5}>
          <FormControl
              variant="standard"
              sx={{m: 1, width: 100}}
          >
            <InputLabel id="event-type-label">Duration</InputLabel>
            <Select
                disableUnderline
                labelId='event-type-label'
                label="Duration"
                value={unit}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setUnit(DurationUnit[e.target.value])}
            >
              {Object.keys(DurationUnit)
              .map((key: any) =>
                  <MenuItem
                      key={key}
                      value={key}
                  >
                    {titleCase(key)}
                  </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
  );
}
export default DurationSelect;
