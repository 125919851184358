import { combineReducers } from "redux";

import dashboardReducer from "./dashboardReducer";
import landingPageReducer from "./landingPageReducer";
import trackAndTraceReducer from "./trackAndTraceReducer";

const sendRootReducer = combineReducers({
  "dashboard": dashboardReducer,
  "landingPage": landingPageReducer,
  "trackAndTrace": trackAndTraceReducer,
});

export default sendRootReducer;
