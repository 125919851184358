import React from "react";

import DomainsListWrapper from "./DomainsListWrapper";
import StatusButton from "./StatusButton";
import DomainControls from "./DomainControls";

const DomainsList = (props) => {
  const {
    organisationIdentities,
    deleteIdentityRequest,
    resendIdentityRequest,
    setShowAddDomainPopup,
    setCurrentVerificationType,
    getDomainRecordsRequest,
    selectIdentityRequest,
  
  } = props;

  return (
    <DomainsListWrapper>
      {organisationIdentities?.length > 0 &&
        organisationIdentities.map((domain) => {
          return (
            <div className="ss-domain" key={domain.externalId}>
              <div className="ssd-left">
                <label className="ssd-name">{domain.identity}</label>
              </div>

              <StatusButton domain={domain} />

              <DomainControls
                domain={domain}
                resendIdentityRequest={resendIdentityRequest}
                deleteIdentityRequest={deleteIdentityRequest}
                setShowAddDomainPopup={setShowAddDomainPopup}
                setCurrentVerificationType={setCurrentVerificationType}
                getDomainRecordsRequest={getDomainRecordsRequest}
                selectIdentityRequest={selectIdentityRequest}
              />
            </div>
          );
        })}
    </DomainsListWrapper>
  );
};

export default DomainsList;
