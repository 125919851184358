import {connect} from "react-redux";
import {compose} from "recompose";
import {bindActionCreators} from "redux";
import {createStructuredSelector} from "reselect";
import {mediaTypes} from "./consts";

import actions from "./actions";

import {
  selectChartMaxVal,
  selectCurrentLandingPage,
  selectCurrentLandingPageStep,
  selectDayVisits,
  selectDeletedLandingPage,
  selectLandingPages,
  selectMonthVisits,
  selectNewLandingPage,
  selectSavedLandingPage,
  selectUpdatedMediaData,
  selectWeekVisits,
  selectYearVisits,
} from "./selectors";

const {
  getYearVisitsRequest,
  getMonthVisitsRequest,
  getWeekVisitsRequest,
  getDayVisitsRequest,
  startLandingPageRequest,
  getContactsRequest,
  //
  updateLandingPageRequest,
  getLandingPageRequest,
  getLandingPagesRequest,
  deleteLandingPageRequest,
  activateLandingPageRequest,
  updateLandingPageStepRequest,
  saveLandingPageRequest,
  uploadMediaToHostRequest,
} = actions;

export const mapStateToProps = createStructuredSelector({
  yearVisits: selectYearVisits,
  monthVisits: selectMonthVisits,
  weekVisits: selectWeekVisits,
  dayVisits: selectDayVisits,
  chartMaxVal: selectChartMaxVal,
  currentLandingPage: selectCurrentLandingPage,
  landingPages: selectLandingPages,
  newLandingPage: selectNewLandingPage,
  deletedLandingPage: selectDeletedLandingPage,
  currentLandingPageStep: selectCurrentLandingPageStep,
  savedLandingPage: selectSavedLandingPage,
  selectUpdatedMediaData: selectUpdatedMediaData,
  mediaTypes: () => mediaTypes,
});

export const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
          getYearVisitsRequest,
          getMonthVisitsRequest,
          getWeekVisitsRequest,
          getDayVisitsRequest,
          startLandingPageRequest,
          getContactsRequest,
          //
          updateLandingPageRequest,
          getLandingPageRequest,
          getLandingPagesRequest,
          deleteLandingPageRequest,
          activateLandingPageRequest,
          updateLandingPageStepRequest,
          saveLandingPageRequest,
          uploadMediaToHostRequest,
        },
        dispatch
    );

export default compose(connect(mapStateToProps, mapDispatchToProps));
