import React, {ChangeEvent} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {EventType, getEventTypeMap} from "../types";
import uuid from 'react-uuid';

const eventTypeMap: Map<any, string> = getEventTypeMap();

type Props = {
  type: EventType
  updateAutomation: (field: string, value: any) => void
}

const EventTypeSelect = ({type, updateAutomation}: Props) => {
  return (
      <FormControl variant="standard" sx={{m: 1, minWidth: 250}}>
        <InputLabel
            id="event-type-label"
            sx={{marginLeft: "10px"}}
        >
          Event Type
        </InputLabel>
        <Select
            disableUnderline
            labelId='event-type-label'
            label="Event Type"
            fullWidth
            value={type ? type : EventType.NEW_SUBSCRIBER}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                updateAutomation('eventType', EventType[e.target.value])}
        >
          {Array.from(eventTypeMap.keys())
          .map((key: any) =>
              <MenuItem
                  key={uuid()}
                  value={key}
              >
                {eventTypeMap.get(key)}
              </MenuItem>
          )}
        </Select>
      </FormControl>
  );
}

export default EventTypeSelect;
