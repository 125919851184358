import React from "react";

import styled from "@emotion/styled";
import {ReactComponent as SearchIcon} from "assets/images/search-icon.svg";

import {debounce} from "lodash";

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  min-width: 200px;

  border: 1px solid #c4c4c4;
  border-radius: 30px;
  padding: 7px 15px;
  margin-right: 30px;

  min-width: 180px;

  input {
    outline: none;
    border: none;
  }

  .mec-icon {
    line {
      stroke: #c4c4c4;
    }

    circle {
      stroke: #c4c4c4;
    }
  }
`;

class Search extends React.Component {
  state = { text: "" };

  debounceEvent(...args) {
    this.debouncedEvent = debounce(...args);
    return (e) => {
      e.persist();
      return this.debouncedEvent(e);
    };
  }
  handleChange = (e) => {
    this.setState({ text: e.target.value });

    const { getCampaignsRequest, organisationId } = this.props;

    getCampaignsRequest(organisationId, 0, e.target.value);
  };
  componentWillUmount() {
    this.debouncedEvent.cancel();
  }
  render() {
    return (
      <SearchInputWrapper>
        <input
          placeholder="Search contact"
          onChange={this.debounceEvent(this.handleChange, 500)}
        />
        <SearchIcon className="mec-icon" />
      </SearchInputWrapper>
    );
  }
}

export default Search;
