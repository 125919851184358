import React, {useEffect, useState} from 'react';
import api from "../../../../../../utils/api";
import {from, map} from "rxjs";
import {AxiosResponse} from "axios";
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import uuid from "react-uuid";
import {Param, ParamType} from "../../../../types";

type Sender = {
  externalId: string
  name: string
}

type Props = {
  organisationId: string
  // sender: string
  // setSender: (s: string) => void
  setParam: React.Dispatch<React.SetStateAction<Param>>
}

const SenderParam = ({organisationId, setParam}: Props) => {

  const [sender, setSender] = useState<string>("");
  const [senders, setSenders] = useState<Sender[]>([]);

  useEffect(() => {
    from(api.get(`/sms/organisation/${organisationId}/sender`))
    .pipe(map((response: AxiosResponse) => response.data))
    .subscribe({
      next: (data: Sender[]) => {
        if (data && data.length > 0) {
          setSenders(data);
          setSender(data[0].externalId);
          setParam(getParam(data[0].externalId, data[0].name));
        }
      },
      error: (err: any) => {
        console.error("Error getting sms senders");
        console.error(err);
      }
    });
  }, []);

  useEffect(() => {
    if (sender) {
      const s: Sender = senders.find(s => s.externalId === sender);
      s && setParam(getParam(s.externalId, s.name));
    }
  }, [sender]);

  const getParam = (value: string, label: string): Param => {
    return {
      id: uuid(),
      paramType: ParamType.SENDER,
      paramValue: value,
      label: label
    }
  }

  return (
      <Box sx={{minWidth: 120}}>
        <FormControl fullWidth>
          <InputLabel id="select-label">Sender</InputLabel>
          <Select
              id={uuid()}
              labelId="select-label"
              value={sender}
              label="Sender"
              onChange={(e) => setSender(e.target.value)}
          >
            {senders && senders.map((s: Sender) =>
                <MenuItem key={uuid()} value={s.externalId}>{s.name}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
  );
}

export default SenderParam;