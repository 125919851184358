import React from "react";

import "assets/css/design/edit-landing-redirect.scss";
import {ColorPicker} from "components/widget/colorPicker";
import {FileDragAndDrop} from "components/widget/fileDragAndDrop";
import {RadioButton} from "components/widget/radioButton";
import {RedirectPageType} from "../../../../../containers/Connect/types";

const EditLandingRedirect = (props) => {
  const {settings, updateSettings, uploadImage} = props;

  const update = (key, value) => {
    updateSettings(`redirectPage.${key}`, value);
  };

  return (
      <div className="edit-landing-redirect">
        <label className="edit-landing-redirect__title">
          Select how you want your customer to be redirected
        </label>
        <div className="edit-landing-redirect__switches">
          <div
              className="edit-landing-redirect__switch"
              onClick={(e) => {
                update("type", RedirectPageType.TEXT);
              }}
          >
            <RadioButton selected={settings?.redirectPage?.type
                === RedirectPageType.TEXT}/>
            <label className="edit-landing-redirect__switch-label">
              Text Page
            </label>
          </div>
          <div
              className="edit-landing-redirect__switch"
              onClick={(e) => {
                update("type", RedirectPageType.CUSTOM_URL);
              }}
          >
            <RadioButton selected={settings?.redirectPage?.type
                === RedirectPageType.CUSTOM_URL}/>
            <label className="edit-landing-redirect__switch-label">
              Custom URL Forward
            </label>
          </div>
          <div
              className="edit-landing-redirect__switch"
              onClick={(e) => {
                update("type", RedirectPageType.CUSTOM_IMAGE);
              }}
          >
            <RadioButton selected={settings?.redirectPage?.type
                === RedirectPageType.CUSTOM_IMAGE}/>
            <label className="edit-landing-redirect__switch-label">
              Custom Image
            </label>
          </div>
          <div
              className="edit-landing-redirect__switch"
              onClick={(e) => {
                update("type", RedirectPageType.INSTAGRAM);
              }}
          >
            <RadioButton selected={settings?.redirectPage?.type
                === RedirectPageType.INSTAGRAM}/>
            <label className="edit-landing-redirect__switch-label">
              Instagram Post
            </label>
          </div>

          <hr className="edit-landing-redirect__content-divider"/>
          {settings?.redirectPage?.type === RedirectPageType.CUSTOM_URL && (
              <textarea
                  className="edit-landing-redirect__text-area"
                  style={{height: "100px"}}
                  placeholder="Enter your custom URL you wish customers to be taken to."
                  defaultValue={settings?.redirectPage?.redirectUrl}
                  onChange={(e) => {
                    update("redirectUrl", e.target.value);
                  }}
              />
          )}

          {settings?.redirectPage?.type === RedirectPageType.CUSTOM_IMAGE && (
              <div>
                <div>
                  <FileDragAndDrop
                      title="Select Custom Image:"
                      className="file-selector"
                      fileName={settings?.customImage?.filename}
                      setFile={(event) => uploadImage(event, "custom")}
                  />
                </div>
                <div className="edit-landing-redirect__input-container">
                  <label className="edit-landing-redirect__label">
                    Button Text:
                  </label>
                  <div
                      className="edit-landing-redirect__custom-image-input-container">
                    <input
                        placeholder="Enter Title Text"
                        className="edit-landing-redirect__input"
                        defaultValue={settings?.redirectPage?.customImageButtonText}
                        onChange={(e) => {
                          update("customImageButtonText", e.target.value);
                        }}
                    />
                    <ColorPicker
                        value={settings?.redirectPage?.customImageButtonTextColor}
                        hideValue
                        popupPosition="top"
                        className="edit-landing-redirect__button-colour-picker"
                        setValue={(value) =>
                            update("customImageButtonTextColor", value)}
                    />
                    <ColorPicker
                        value={settings?.redirectPage?.customImageButtonColor}
                        hideValue
                        popupPosition="top"
                        title="Button Colour: "
                        setValue={(value) =>
                            update("customImageButtonColor", value)}
                    />
                  </div>
                </div>
                <div
                    className="edit-landing-redirect__custom-image-button-url-container">
                  <label className="edit-landing-redirect__label">
                    Button URL:{" "}
                  </label>
                  <input
                      placeholder="Enter Button URL"
                      className="edit-landing-redirect__input--full-width"
                      defaultValue={settings?.redirectPage?.customImageUrl}
                      onChange={(e) => {
                        update("customImageUrl", e.target.value);
                      }}
                  />
                </div>
              </div>
          )}
          {settings?.redirectPage?.type === RedirectPageType.INSTAGRAM && (
              <>
                <textarea
                    className="edit-landing-redirect__embed-code-input"
                    placeholder={`To use Instagram photos, all you need to do is go to your desired photo on a web browser. Copy the link in the URL bar and paste it into this box and click next!`}
                    defaultValue={settings?.redirectPage?.embedCode}
                    onChange={(e) => update("embedCode", e.target.value)}
                />
                <div className="edit-landing-redirect__input-container">
                  <div
                      className="edit-landing-redirect__instagram-input-container">
                    <label className="edit-landing-redirect__label">
                      Button Text:
                    </label>
                    <input
                        placeholder="Enter Button Text"
                        className="edit-landing-redirect__input"
                        defaultValue={settings?.redirectPage?.instagramButtonText}
                        onChange={(e) =>
                            update("instagramButtonText", e.target.value)}
                    />
                    <ColorPicker
                        value={settings?.redirectPage?.instagramButtonTextColor}
                        hideValue
                        popupPosition="top"
                        className="edit-landing-redirect__button-colour-picker"
                        setValue={(value) =>
                            update("instagramButtonTextColor", value)}
                    />
                    <ColorPicker
                        value={settings?.redirectPage?.instagramButtonColor}
                        hideValue
                        popupPosition="top"
                        title="Button Colour: "
                        setValue={(value) =>
                            update("instagramButtonColor", value)}
                    />
                  </div>
                </div>
              </>
          )}
          {settings?.redirectPage?.type === RedirectPageType.TEXT && (
              <div className="edit-landing-redirect__bottom-content">
                <div className="edit-landing-redirect__input-container">
                  <label className="edit-landing-redirect__label">
                    Title Text:{" "}
                  </label>
                  <div className="edit-landing-redirect__input-with-picker">
                    <input
                        placeholder="Enter Title Text"
                        className="edit-landing-redirect__input"
                        defaultValue={settings?.redirectPage?.titleText}
                        onChange={(e) => update("titleText", e.target.value)}
                    />
                    <ColorPicker
                        value={settings?.redirectPage?.titleTextColour}
                        hideValue
                        setValue={(value) => update("titleTextColour", value)}
                    />
                  </div>
                </div>
                <div className="edit-landing-redirect__input-container">
                  <label className="edit-landing-redirect__label">
                    Title Sub Text:
                  </label>
                  <div className="edit-landing-redirect__input-with-picker">
                    <input
                        placeholder="Enter Sub Text"
                        className="edit-landing-redirect__input"
                        defaultValue={settings?.redirectPage?.subTitleText}
                        onChange={(e) => update("subTitleText", e.target.value)}
                    />
                    <ColorPicker
                        value={settings?.redirectPage?.subTitleTextColour}
                        hideValue
                        setValue={(value) => update("subTitleTextColour",
                            value)}
                    />
                  </div>
                </div>
                <div className="edit-landing-redirect__input-container">
                  <label className="edit-landing-redirect__label">
                    Button URL:{" "}
                  </label>
                  <input
                      placeholder="Enter Button URL"
                      className="edit-landing-redirect__input--no-picker"
                      defaultValue={settings?.redirectPage?.buttonUrl}
                      onChange={(e) => update("buttonUrl", e.target.value)}
                  />
                </div>
                <div className="edit-landing-redirect__input-container">
                  <label className="edit-landing-redirect__label">
                    Button Text:{" "}
                  </label>
                  <div className="edit-landing-redirect__input-with-picker">
                    <input
                        placeholder="Enter Button Text"
                        className="edit-landing-redirect__input"
                        defaultValue={settings?.redirectPage?.buttonText}
                        onChange={(e) => update("buttonText", e.target.value)}
                    />
                    <ColorPicker
                        value={settings?.redirectPage?.buttonTextColour}
                        hideValue
                        setValue={(value) => update("buttonTextColour", value)}
                    />
                  </div>
                </div>
                <div className="edit-landing-redirect__colour-pickers">
                  <div className="edit-landing-redirect__bottom-picker">
                    <label className="edit-landing-redirect__label">
                      Button Colour:
                    </label>
                    <ColorPicker
                        value={settings?.redirectPage?.buttonColour}
                        hideValue
                        popupPosition="top"
                        setValue={(value) => update("buttonColour", value)}
                    />
                  </div>
                  <div className="edit-landing-redirect__bottom-picker">
                    <label className="edit-landing-redirect__label">
                      Background Color:
                    </label>
                    <ColorPicker
                        value={settings?.redirectPage?.backgroundColour}
                        hideValue
                        popupPosition="top"
                        setValue={(value) => update("backgroundColour", value)}
                    />
                  </div>
                </div>
              </div>
          )}
        </div>
      </div>
  );
};

export default EditLandingRedirect;
