export const REDUCER_NAME = 'SEND_SETTINGS';


// ### Email-Service
// # @name Create email identity
// POST http://localhost:8080/v3/api/email/organisation/{{organisationId}}/identity
// Authorization: Bearer {{auth_token}}
// Content-Type: application/json

// {
//   "type": "email",
//   "identity": "anthony@unaro.co.uk",
//   "email": "anthony@unaro.co.uk",
//   "name": "Anthony Merlo"
// }

// ### Email-Service
// # @name Create domain identity
// POST http://localhost:8080/v3/api/email/organisation/{{organisationId}}/identity
// Authorization: Bearer {{auth_token}}
// Content-Type: application/json

// {
//   "type": "domain",
//   "identity": "unaro.co.uk",
//   "email": "anthony@unaro.co.uk",
//   "name": "Anthony Merlo"
// }

// ### Email-Service
// # @name List identities for organisation
// GET http://localhost:8080/v3/api/email/organisation/{{organisationId}}/identity
// Authorization: Bearer {{auth_token}}

// <> 2021-12-02T162115.200.json
// <> 2021-12-02T161735.200.json

// ### Email-Service
// # @name Get domain dkim dns records
// GET http://localhost:8080/v3/api/email/identity/{{identityId}}/dkim
// Authorization: Bearer {{auth_token}}

// ### Email-Service
// # @name List Delete identity
// DELETE http://localhost:8080/v3/api/email/identity/{{identityId}}
// Authorization: Bearer {{auth_token}}


// public enum VerificationStatus {
//     PENDING("Pending"),

//     SUCCESS("Success"),

//     FAILED("Failed"),

//     TEMPORARY_FAILURE("TemporaryFailure"),

//     NOT_STARTED("NotStarted"),

//     UNKNOWN_TO_SDK_VERSION(null);