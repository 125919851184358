import {SocialMedia} from "../containers/Connect/types";

const getSocialMediaColor = (socialNetwork) => {
  const value = typeof socialNetwork === 'symbol'
      ? socialNetwork
      : SocialMedia[socialNetwork];

  switch (value) {
    case SocialMedia.Facebook:
      return "#3B5998";
    case SocialMedia.Linkedin:
      return "#0E76A8";
    case SocialMedia.Twitter:
      return "#1da1f2";
    case SocialMedia.Email:
      return "#ea5454";
    default:
      return "#3B5998";
  }
};

export default getSocialMediaColor;
