import React from "react";
import styled from "@emotion/styled";

const Description = styled.div`
  width: 35%;
  margin-top: ${(props) => props.marginTop}px;
  padding: 5px;

  h3 {
    font-family: Roboto;
    font-size: 16px;
  }
`;

export default Description;
