import React, {ChangeEvent, useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import uuid from "react-uuid";
import Grid from "@mui/material/Unstable_Grid2";
import {getTemplate, getTemplates, removeTemplate, saveTemplate, Template} from "./TemplateService";
import Editor from "./Editor";
import NewTemplateButton from "./NewTemplateButton";
import DeleteTemplateButton from "./DeleteTemplateButton";
import {map, Observable, Subject, debounceTime, takeUntil} from "rxjs";

type Props = {
  organisationId: string
}

const Templates = ({organisationId}: Props) => {

  const [template, setTemplate] = useState<Template | null>();
  const [templateId, setTemplateId] = useState<string>('');
  const [templates, setTemplates] = useState<Template[]>([]);
  const [name, setName] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [savedIndicator, setSavedIndicator] = useState<boolean>(false);
  const [destroy, setDestroy] = useState<Subject<void>>(new Subject());
  const [debouncer, setDebouncer] = useState<Subject<any>>(new Subject());

  const changeTemplate = (t?: Template) => {
    if (t) {
      setTemplate(t);
      setTemplateId(t.externalId);
      setName(t.name ? t.name : '');
      setSubject(t.subject ? t.subject : '');
      setDisabled(false);
    } else {
      setTemplate(null);
      setTemplateId('');
      setName('');
      setSubject('');
      setDisabled(true);
    }
  }

  const fetchTemplates = (): Observable<Template[]> => {
    return getTemplates(organisationId)
    .pipe(map((t: Template[]) => {
      setTemplates(t);
      return t;
    }));
  }
  
  useEffect(() => {
    debouncer
    .pipe(
      debounceTime(400),
      takeUntil(destroy),
      )
    .subscribe(saveEvent => {
      saveTemplate(saveEvent)
      .subscribe({
        next: () => {
          if (!savedIndicator) {
            setSavedIndicator(true);
            setTimeout(() => setSavedIndicator(false), 2000);
          }
  
          fetchTemplates()
          .subscribe({error: (err) => console.error(err)})
        },
        error: (err) => console.error(err)
      });
    })
    
    return () => {
      destroy.next();
      destroy.complete();
    };
  }, []); 

  useEffect(() => {
    fetchTemplates()
    .subscribe({
      next: (t: Template[]) => {
        if (t && t.length > 0) {
          changeTemplate(t[0]);
        } else {
          changeTemplate();
        }
      },
      error: (err: any) => console.error(err)
    });
  }, [organisationId]);
  useEffect(() => {
    if (template) {
      debouncer.next(template);
    }
  }, [template]);


  useEffect(() => {
    if (template) {
      const t = {...template, name: name};
      changeTemplate(t);
      debouncer.next(t);
    }
  }, [name]);

  useEffect(() => {
    template && changeTemplate({...template, subject: subject});
  }, [subject]);

  const onChangeTemplate = (id: string) => {
    if (id) {
      getTemplate(id)
      .subscribe({
        next: (t: Template) => changeTemplate(t),
        error: (err) => console.error(err)
      });
    }
  }

  const addTemplate = (name: string) => {
    const newTemplate = {
      name: name,
      externalId: uuid(),
      orgId: organisationId,
      unlayerDesign: '',
      messageBody: '<html lang="en"></html>'
    };
    setTemplates([newTemplate, ...templates]);
    changeTemplate(newTemplate);
  }

  const deleteTemplate = () => {
    removeTemplate(organisationId, templateId)
    .subscribe({
      next: () => fetchTemplates()
      .subscribe({
        next: (t: Template[]) => {
          if (t && t.length > 0) {
            changeTemplate(t[0]);
          } else {
            changeTemplate();
          }
        },
        error: (err: any) => console.error(err)
      }),
      error: (err: any) => console.error(err)
    });
  }

  return (
      <Grid
          container
          spacing={2}
          direction="column"
          bgcolor={'white'}
          marginTop={2}
          marginLeft={2}
          sx={{minWidth: '1000px'}}
      >
        <Grid xs={12} marginX={2}>
          <Typography variant={"h6"}>Email Template Editor</Typography>
        </Grid>
        <Grid xs={12}>
          <Grid
              container
              direction="row"
              alignItems="center"
          >
            <Grid xs={4} marginX={2}>
              <FormControl fullWidth>
                <InputLabel id="select-label">Template</InputLabel>
                <Select
                    labelId="select-label"
                    id="template-select"
                    value={templateId}
                    label="Template"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeTemplate(e.target.value)}
                    disabled={disabled}
                >
                  {templates.map((t: Template) =>
                      <MenuItem key={uuid()} value={t.externalId}>
                        {t.name}
                      </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <Grid container direction={'row'}>
                <NewTemplateButton addTemplate={addTemplate}/>
                <DeleteTemplateButton deleteTemplate={deleteTemplate} disabled={disabled}/>
                {savedIndicator &&
                    <Typography
                        color={'green'}
                        marginTop={.7}
                        marginLeft={3}
                    >
                      Saved
                    </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={11} container marginX={3} direction="row">
          <Grid xs={4}>
            <TextField
                id="template-name"
                value={name}
                label="Name"
                variant="outlined"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                disabled={disabled}
                fullWidth
            />
          </Grid>
          <Grid xs={4}>
            <TextField
                id="template-subject"
                value={subject}
                label="Email Subject"
                variant="outlined"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSubject(e.target.value)}
                sx={{marginLeft: 3}}
                disabled={disabled}
                fullWidth
            />
          </Grid>
        </Grid>
        <Grid xs={11} marginX={3}>
          {!disabled &&
              <Editor
                  template={template}
                  setTemplate={(t: Template) => setTemplate(t)}
                  setDisabled={setDisabled}
              />
          }
        </Grid>
      </Grid>
  );
}

export default Templates;