import React from "react";

import styled from "@emotion/styled";

import {Check, Info, PaperPlaneRight, X} from "phosphor-react";

const FromFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  select {
    border: 1px solid #c4c4c4;
    border-radius: 21.75px;
    padding: 7px 14px;
    font-size: 12px;
    outline: none;
    /* width: 100%; */
    transform: translate(-5px, 0);

    option {
      font-size: 11px;
      opacity: 0.75;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 175px;

  .title {
    padding-left: 5px;
  }
`;

const TextInput = styled.input`
  margin: 30px 0 30px 30px;
  border: 1px solid #c4c4c4;
  border-radius: 21.75px;
  padding: 7px 14px;
  font-size: 12px;
  outline: none;
  width: 40%;
  margin-right: 25px;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const DropDownWrapper = styled.div`
  position: relative;
`;

const ValidationMessageWrapper = styled.div`
  position: absolute;
  bottom: -30px;
  left: 5px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 200;
  color: ${(props) => (props.isValidated ? "#1cb82b" : "#ff6633")};

  .email-validation-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      margin-right: 4px;
      white-space: nowrap;
    }
  }
`;

const ValidationMessage = ({ isValidated }) => {
  return (
    <ValidationMessageWrapper isValidated={isValidated}>
      {isValidated ? (
        <div class="email-validation-inner">
          <p>This email is Validated</p>
          <Check size={16} color={"#1cb82b"} />
        </div>
      ) : (
        <div class="email-validation-inner">
          <p>This email is not Validated!</p>
          <X size={16} color={"#ff6633"} />
        </div>
      )}
    </ValidationMessageWrapper>
  );
};

const VerifyDomainButtonWrapper = styled.div`
  background-color: #ea5454;
  padding: 8px 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  cursor: pointer;

  label {
    color: white;
    margin-right: 4px;
    font-size: 10px;
    cursor: pointer;
  }
`;

const VerifyDomainButton = (props) => {
  return (
    <VerifyDomainButtonWrapper>
      <label>Verify Domain</label>
      <PaperPlaneRight size={12} color={"white"} />
    </VerifyDomainButtonWrapper>
  );
};

const FromField = (props) => {
  const { currentOrganisationEmails, change, input } = props;

  const { value } = input;

  const [isSelectedEmailVerified, setIsSelectedEmailVerified] =
    React.useState(false);

  React.useEffect(() => {
    if (currentOrganisationEmails && value && value.email) {
      const isCurrentEmailValidated = currentOrganisationEmails.filter(
        (email) => {
          return email.email === value.email;
        }
      )[0].confirmed;

      setIsSelectedEmailVerified(isCurrentEmailValidated);
      // setIsSelectedEmailVerified(false); //TEST HERE

      const senderDomainId = currentOrganisationEmails.filter((email) => {
        return email.email === value.email;
      })[0].externalId;

      change("campaignFrom", {
        ...value,
        isValidated: isCurrentEmailValidated,
        senderDomainId: senderDomainId,
      });
    }
  }, [value]);

  const handleInputChange = (event) => {
    change("campaignFrom", {
      ...value,
      name: event.target.value,
    });
  };

  return (
    <FromFieldWrapper>
      <TitleWrapper>
        <Info size={18} color={"#5d6161"} />
        <h4 className="title">From</h4>
      </TitleWrapper>

      <FieldsWrapper>
        <TextInput
          onChange={handleInputChange}
          type="text"
          placeholder="Type Name"
        ></TextInput>

        <DropDownWrapper>
          <select
            name="from"
            // value={genderField}
            onChange={(event) => {
              change("campaignFrom", { ...value, email: event.target.value });
            }}
          >
            <option value="" disabled selected>
              Select email
            </option>
            {currentOrganisationEmails &&
              currentOrganisationEmails.map((email) => {
                return <option>{email.email}</option>;
              })}
          </select>

          {/* {value?.email && (
            <ValidationMessage isValidated={isSelectedEmailVerified} />
          )} */}
        </DropDownWrapper>

        {/* {value?.email && !isSelectedEmailVerified && <VerifyDomainButton />} */}
      </FieldsWrapper>

      {/* <TextWrapper>nathan@kjeke.com</TextWrapper> */}
    </FromFieldWrapper>
  );
};

export default FromField;
