import React, {useEffect, useState} from "react";
import {ReactComponent as DropdownLogo} from "assets/images/dropdown-icon.svg";
import "assets/css/widget/dropdown.scss";

const VenuesDropDown = (props) => {
  const {
    options = [],
    onSelect,
    dropdownPosition = "bottom",
    className,
    selectedEntity,
    hasSecondaryBackground = false,
    selectedOrganisationHasVenues,
  } = props;

  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState();

  const toggleOpen = () => {
    if (options.length > 0) {
      setOpen(!open);
    }
  };

  const changeSelected = (option) => {
    setOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    if (selectedEntity || (options && options.length > 0)) {
      const org = selectedEntity ? selectedEntity : options[0];
      setLabel(org.name);
    }
  }, [options, selectedEntity]);

  return (
      <div
          className={`dropdown-container ${className}`}
          tabIndex="0"
          onBlur={() => setOpen(false)}
      >
        <div
            className={`dropdown ${
                open && "dropdown-open"
            } dropdown--secondary-background-${hasSecondaryBackground}`}
            onClick={() => toggleOpen()}
        >
          <label>{label}</label>
          {selectedOrganisationHasVenues && <DropdownLogo className="logo"/>}
        </div>
        {open && (
            <div className={`options dropdown-position--${dropdownPosition}`}>
              {options.map((option, i) => {
                return (
                    <label
                        key={option.externalId}
                        onClick={() => changeSelected(option)}
                    >
                      {option.name}
                    </label>
                );
              })}
            </div>
        )}
      </div>
  );
};

export default VenuesDropDown;
