export const GET_AUDIENCES_REQUEST = "GET_AUDIENCES_REQUEST";
export const GET_AUDIENCES_FAILURE = "GET_AUDIENCES_FAILURE";
export const GET_AUDIENCES_SUCCESS = "GET_AUDIENCES_SUCCESS";

export const CREATE_AUDIENCE_REQUEST = "CREATE_AUDIENCE_REQUEST";
export const CREATE_AUDIENCE_FAILURE = "CREATE_AUDIENCE_FAILURE";
export const CREATE_AUDIENCE_SUCCESS = "CREATE_AUDIENCE_SUCCESS";

export const SELECT_AUDIENCE_REQUEST = "SELECT_AUDIENCE_REQUEST";
export const SELECT_AUDIENCE_FAILURE = "SELECT_AUDIENCE_FAILURE";
export const SELECT_AUDIENCE_SUCCESS = "SELECT_AUDIENCE_SUCCESS";

export const GET_AUDIENCE_CONTACTS_REQUEST = "GET_AUDIENCE_CONTACTS_REQUEST";
export const GET_AUDIENCE_CONTACTS_FAILURE = "GET_AUDIENCE_CONTACTS_FAILURE";
export const GET_AUDIENCE_CONTACTS_SUCCESS = "GET_AUDIENCE_CONTACTS_SUCCESS";

export const TOGGLE_AUDIENCE_CONTACT_REQUEST =
    "TOGGLE_AUDIENCE_CONTACT_REQUEST";
export const TOGGLE_AUDIENCE_CONTACT_FAILURE =
    "TOGGLE_AUDIENCE_CONTACT_FAILURE";
export const TOGGLE_AUDIENCE_CONTACT_SUCCESS =
    "TOGGLE_AUDIENCE_CONTACT_SUCCESS";

export const TOGGLE_ALL_AUDIENCE_CONTACTS_REQUEST = "TOGGLE_ALL_AUDIENCE_CONTACTS_REQUEST";
export const TOGGLE_ALL_AUDIENCE_CONTACTS_FAILURE = "TOGGLE_ALL_AUDIENCE_CONTACTS_FAILURE";
export const TOGGLE_ALL_AUDIENCE_CONTACTS_SUCCESS = "TOGGLE_ALL_AUDIENCE_CONTACTS_SUCCESS";

export const LINK_CONTACT_TO_AUDIENCE_REQUEST =
    "LINK_CONTACT_TO_AUDIENCE_REQUEST";
export const LINK_CONTACT_TO_AUDIENCE_FAILURE =
    "LINK_CONTACT_TO_AUDIENCE_FAILURE";
export const LINK_CONTACT_TO_AUDIENCE_SUCCESS =
    "LINK_CONTACT_TO_AUDIENCE_SUCCESS";

// --- GET AUDIENCE CONTACTS

export const getAudiencesRequest = (organisationId) => ({
  type: GET_AUDIENCES_REQUEST,
  payload: {
    organisationId,
  },
});

export const getAudiencesFailure = (errorMessage) => ({
  type: GET_AUDIENCES_FAILURE,
  payload: errorMessage,
});

export const getAudiencesSuccess = (audiences) => ({
  type: GET_AUDIENCES_SUCCESS,
  payload: audiences,
});

// --- SELECT AUDIENCE

export const selectAudienceRequest = (segmentId) => ({
  type: SELECT_AUDIENCE_REQUEST,
  payload: {
    segmentId,
  },
});

export const selectAudienceFailure = (errorMessage) => ({
  type: SELECT_AUDIENCE_FAILURE,
  payload: errorMessage,
});

export const selectAudienceSuccess = (selectedAudience) => ({
  type: SELECT_AUDIENCE_SUCCESS,
  payload: {
    selectedAudience,
  },
});

// --- GET AUDIENCE CONTACTS

export const getAudienceContactsRequest = (
    organisationId,
    audienceId,
    pageNumber,
    pageSize,
    searchString
) => ({
  type: GET_AUDIENCE_CONTACTS_REQUEST,
  payload: {
    organisationId,
    audienceId,
    pageNumber,
    pageSize,
    searchString,
  },
});

export const getAudienceContactsFailure = (errorMessage) => ({
  type: GET_AUDIENCE_CONTACTS_FAILURE,
  payload: errorMessage,
});

export const getAudienceContactsSuccess = (
    audienceContacts,
    currentPagination
) => ({
  type: GET_AUDIENCE_CONTACTS_SUCCESS,
  payload: {
    audienceContacts,
    currentPagination,
  },
});

// --- TOGGLE AUDIENCE CONTACT

export const toggleAudienceContactRequest = (contactId) => ({
  type: TOGGLE_AUDIENCE_CONTACT_REQUEST,
  payload: {
    contactId,
  },
});

export const toggleAudienceContactFailure = (errorMessage) => ({
  type: TOGGLE_AUDIENCE_CONTACT_FAILURE,
  payload: errorMessage,
});

export const toggleAudienceContactSuccess = (contactId) => ({
  type: TOGGLE_AUDIENCE_CONTACT_SUCCESS,
  payload: {
    contactId,
  },
});

// --- CREATE AUDIENCE

export const createAudienceRequest = (audienceData) => ({
  type: CREATE_AUDIENCE_REQUEST,
  payload: {
    audienceData,
  },
});

export const createAudienceFailure = (errorMessage) => ({
  type: CREATE_AUDIENCE_FAILURE,
  payload: errorMessage,
});

export const createAudienceSuccess = (createdAudience) => ({
  type: CREATE_AUDIENCE_SUCCESS,
  payload: {
    createdAudience,
  },
});

// --- TOGGLE ALL AUDIENCE CONTACTS

export const toggleAllAudienceContactsRequest = (
    audienceContacts,
    shouldToggleOn
) => ({
  type: TOGGLE_ALL_AUDIENCE_CONTACTS_REQUEST,
  payload: {
    audienceContacts,
    shouldToggleOn,
  },
});

export const toggleAllAudienceContactsFailure = (errorMessage) => ({
  type: TOGGLE_ALL_AUDIENCE_CONTACTS_FAILURE,
  payload: errorMessage,
});

export const toggleAllAudienceContactsSuccess = (updatedAudienceSelectedContacts) => ({
  type: TOGGLE_ALL_AUDIENCE_CONTACTS_SUCCESS,
  payload: {
    updatedAudienceSelectedContacts,
  },
});
